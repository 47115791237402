import React, { useMemo } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisVertical } from '@fortawesome/pro-solid-svg-icons';

import { mfColors } from 'vars';
import { EReportType } from 'models/report';
import Dropdown, { EPosition, EToggleType } from 'atomicComponents/Dropdown';
import { useTranslation } from 'react-i18next';

export enum ETitleType {
  Bulletin,
  Hotspot
}

const Title = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 0 32px;
`;

const TitleBadge = styled.div`
  height: 42px;
  flex: 0 0 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin: 0 14px 0 0;
  color: ${({ type }: { type: ETitleType }) => (type === ETitleType.Bulletin ? mfColors.red : mfColors.darkGrey)};

  &:before {
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    background: ${({ type }: { type: ETitleType }) => (type === ETitleType.Bulletin ? mfColors.red : mfColors.darkGrey)};
    border-radius: 50%;
    opacity: 0.1;
  }
`;

const ControlWrapper = styled.div`
  display: flex;
  flex: 0 0 28px;
  height: 28px;
  border-radius: 50%;
  position: relative;

  &:before {
    content: '';
    display: flex;
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }

  &:hover {
    &:before {
      background: ${mfColors.grey};
      opacity: 0.2;
    }
  }
`;

const TitleControl = styled(FontAwesomeIcon)`
  cursor: pointer;
  position: absolute;
  opacity: 1;
  color: ${mfColors.darkGrey};
  margin: auto;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
`;

const Arrow = styled.div`
  position: absolute;
  bottom: -24px;
  left: 12px;
  width: 0;
  height: 0;
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
  border-bottom: 12px solid transparent;
  border-top: 12px solid ${mfColors.white};
`;

const BulletinDescription = styled.span`
  font-size: 14px;
`;

const DROPDOWN_OFFSET = { x: -32, y: 16 };
const DROPDOWN_STYLE = {
  background: mfColors.white,
  boxShadow: `-5px -5px 10px ${mfColors.black40}, 5px 5px 10px ${mfColors.black40}`
};

const WRAPPER_STYLE = {
  display: 'flex',
  flex: '0 0 28px'
};

interface IProps {
  content: JSX.Element;
  icon: JSX.Element;
  onExpandedChange: (event: React.MouseEvent) => void;
  type: ETitleType;
  reportType?: EReportType;
}

const SidebarBlockTitle = ({ content, icon, onExpandedChange, type, reportType }: IProps): JSX.Element => {
  const { t } = useTranslation();
  const isControlVisible = type === ETitleType.Bulletin && reportType !== EReportType.TargetedScouting;

  const bulletinInfo = useMemo(() => {
    switch (reportType) {
      case EReportType.TargetedScouting:
        return t('main.bulletin.area_in_decline_detected');
      default:
        return '';
    }
  }, [reportType, t]);

  return (
    <Title>
      <TitleBadge type={type}>{icon}</TitleBadge>
      {content}
      {isControlVisible ? (
        <ControlWrapper onClick={onExpandedChange}>
          <TitleControl icon={faEllipsisVertical} size="lg" />
        </ControlWrapper>
      ) : (
        <Dropdown
          arrow={<Arrow />}
          style={DROPDOWN_STYLE}
          toggleType={EToggleType.Click}
          control={
            <ControlWrapper>
              <TitleControl icon={faEllipsisVertical} size="lg" />
            </ControlWrapper>
          }
          offset={DROPDOWN_OFFSET}
          content={<BulletinDescription>{bulletinInfo}</BulletinDescription>}
          position={EPosition.TopRight}
          wrapperStyle={WRAPPER_STYLE}
        />
      )}
    </Title>
  );
};

export default SidebarBlockTitle;
