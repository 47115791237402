import { Map, GeoJSONSource, AnyLayer, Expression } from 'mapbox-gl';
import * as turf from '@turf/turf';

import { IUnifiedGeojson } from 'hooks/farms.hooks';
import { ERichItemFeatureType, IChartLabelConfig, IRichItemMapboxFeature } from 'models/richItem';
import { getDarkerColor } from 'utils/colors';
import { getColorProperty, getPaintPropertyName, TColors } from 'utils/helpers';

import {
  getMapSourceById,
  addLayerToMap,
  removeMapLayerById,
  reorderLayers,
  loadImage,
  CUSTOM_GEOJSON_TREE_AREA,
  CUSTOM_GEOJSON_TREE_HOVER_AREA,
  CUSTOM_GEOJSON_TREE_HOVERED,
  CUSTOM_GEOJSON_TREE_SELECTED,
  CUSTOM_GEOJSON_TREE_SELECTED_ICON,
  CUSTOM_GEOJSON_LINE_HOVERED,
  CUSTOM_GEOJSON_LINE_SELECTED,
  LAYERS_PRIORITY,
  TREE_PAINT_CONFIG,
  TREE_MIN_TILESET_ZOOM,
  GALLERY_ICON,
  TMapMouseEvent,
  isGeometryType,
  CUSTOM_GEOJSON_TREE_AREA_SOURCE
} from 'services/util/mapHelpers';

const SELECTED_ICON_PATH = '/images/plus-icon.png';

const lazyCreateSource = (map: Map, sourceName: string): void => {
  if (!getMapSourceById(map, sourceName)) {
    map.addSource(sourceName, {
      data: turf.featureCollection([]),
      type: 'geojson'
    });
  }
};

const createGeojsonLayer = (map: Map, richItemsGeoJSON: IUnifiedGeojson): void => {
  lazyCreateSource(map, CUSTOM_GEOJSON_TREE_AREA_SOURCE);

  removeMapLayerById(map, CUSTOM_GEOJSON_TREE_AREA);

  addLayerToMap(map, CUSTOM_GEOJSON_TREE_AREA, {
    id: CUSTOM_GEOJSON_TREE_AREA,
    source: CUSTOM_GEOJSON_TREE_AREA_SOURCE,
    ...richItemsGeoJSON.mapbox
  } as AnyLayer);

  if (isGeometryType(richItemsGeoJSON, 'Point')) {
    map.setPaintProperty(CUSTOM_GEOJSON_TREE_AREA, 'circle-radius', TREE_PAINT_CONFIG['circle-radius']);
  }
  (getMapSourceById(map, CUSTOM_GEOJSON_TREE_AREA_SOURCE) as GeoJSONSource).setData(richItemsGeoJSON.geojson as any);
};

export const SELECTED_RICH_ITEMS_LAYERS = [CUSTOM_GEOJSON_LINE_SELECTED, CUSTOM_GEOJSON_TREE_SELECTED];

const addCustomLayersListeners = (
  map: Map,
  richItemsGeoJSON: IUnifiedGeojson,
  treeClickHandler: (feature: TMapMouseEvent) => void,
  selectedTreesHandler: (event: TMapMouseEvent) => void
): void => {
  map.on('click', CUSTOM_GEOJSON_TREE_HOVER_AREA, treeClickHandler);
  SELECTED_RICH_ITEMS_LAYERS.forEach((layer) => {
    map.on('click', layer, selectedTreesHandler);
  });
};

const detachTreeClickListeners = (map: Map, treeClickHandler: (feature: TMapMouseEvent) => void, selectedTreeClickHandler: (feature: TMapMouseEvent) => void) => {
  map.off('click', CUSTOM_GEOJSON_TREE_HOVER_AREA, treeClickHandler);
  SELECTED_RICH_ITEMS_LAYERS.forEach((layer) => {
    map.off('click', layer, selectedTreeClickHandler);
  });
};

const createCustomGeojsonPointHoveredLayer = (map: Map, richItemsGeoJSON: IUnifiedGeojson, paintProperty: TColors, hoverColors: TColors): void => {
  lazyCreateSource(map, CUSTOM_GEOJSON_TREE_HOVERED);
  removeMapLayerById(map, CUSTOM_GEOJSON_TREE_HOVERED);
  removeMapLayerById(map, CUSTOM_GEOJSON_TREE_HOVER_AREA);

  addLayerToMap(map, CUSTOM_GEOJSON_TREE_HOVER_AREA, {
    paint: {
      'circle-radius': 14,
      'circle-opacity': 0
    },
    id: CUSTOM_GEOJSON_TREE_HOVER_AREA,
    type: 'circle',
    minzoom: TREE_MIN_TILESET_ZOOM,
    source: CUSTOM_GEOJSON_TREE_AREA_SOURCE
  });

  addLayerToMap(map, CUSTOM_GEOJSON_TREE_HOVERED, {
    id: CUSTOM_GEOJSON_TREE_HOVERED,
    source: CUSTOM_GEOJSON_TREE_HOVERED,
    type: 'circle',
    paint: {
      ...richItemsGeoJSON.mapbox?.paint,
      'circle-radius': 10,
      'circle-stroke-width': 10,
      'circle-stroke-color': [...paintProperty, ...hoverColors] as Expression
    }
  });
};

const createCustomGeojsonPointSelectedLayer = (map: Map, richItemsGeoJSON: IUnifiedGeojson, paintProperty: TColors, hoverColors: TColors): void => {
  lazyCreateSource(map, CUSTOM_GEOJSON_TREE_SELECTED);
  removeMapLayerById(map, CUSTOM_GEOJSON_TREE_SELECTED);

  addLayerToMap(map, CUSTOM_GEOJSON_TREE_SELECTED, {
    id: CUSTOM_GEOJSON_TREE_SELECTED,
    source: CUSTOM_GEOJSON_TREE_SELECTED,
    type: 'circle',
    paint: {
      'circle-stroke-width': {
        base: 1,
        stops: [
          [17, 2.8],
          [21, 10]
        ]
      },
      'circle-stroke-opacity': 1,
      'circle-color': 'white',
      'circle-radius': {
        base: 1,
        stops: [
          [17, 8],
          [22, 14]
        ]
      },
      'circle-stroke-color': [...paintProperty, ...hoverColors] as Expression
    }
  });

  loadImage(map, SELECTED_ICON_PATH, GALLERY_ICON).then(() => {
    addLayerToMap(map, CUSTOM_GEOJSON_TREE_SELECTED_ICON, {
      id: CUSTOM_GEOJSON_TREE_SELECTED_ICON,
      type: 'symbol',
      source: CUSTOM_GEOJSON_TREE_SELECTED,
      layout: {
        'icon-image': GALLERY_ICON,
        'icon-size': {
          base: 0.5,
          stops: [
            [17, 0.35],
            [22, 0.7]
          ]
        },
        'icon-rotation-alignment': 'viewport',
        'icon-allow-overlap': true
      },
      paint: { 'icon-color': 'black' }
    });
  });
};

const createCustomGeojsonLineHoveredLayer = (map: Map, richItemsGeoJSON: IUnifiedGeojson): void => {
  lazyCreateSource(map, CUSTOM_GEOJSON_LINE_HOVERED);
  removeMapLayerById(map, CUSTOM_GEOJSON_LINE_HOVERED);
  removeMapLayerById(map, CUSTOM_GEOJSON_TREE_HOVER_AREA);

  addLayerToMap(map, CUSTOM_GEOJSON_TREE_HOVER_AREA, {
    id: CUSTOM_GEOJSON_TREE_HOVER_AREA,
    source: CUSTOM_GEOJSON_TREE_AREA_SOURCE,
    type: 'line',
    paint: {
      ...richItemsGeoJSON.mapbox?.paint,
      'line-width': 24,
      'line-color': 'transparent'
    }
  });

  addLayerToMap(map, CUSTOM_GEOJSON_LINE_HOVERED, {
    id: CUSTOM_GEOJSON_LINE_HOVERED,
    source: CUSTOM_GEOJSON_LINE_HOVERED,
    type: 'line',
    paint: {
      ...richItemsGeoJSON.mapbox?.paint,
      'line-width': 14
    },
    layout: {
      'line-cap': 'round',
      'line-join': 'round'
    }
  });
};

const createCustomGeojsonLineSelectedLayer = (map: Map, richItemsGeoJSON: IUnifiedGeojson): void => {
  lazyCreateSource(map, CUSTOM_GEOJSON_LINE_SELECTED);
  removeMapLayerById(map, CUSTOM_GEOJSON_LINE_SELECTED);

  addLayerToMap(map, CUSTOM_GEOJSON_LINE_SELECTED, {
    id: CUSTOM_GEOJSON_LINE_SELECTED,
    source: CUSTOM_GEOJSON_LINE_SELECTED,
    type: 'line',
    paint: {
      ...richItemsGeoJSON.mapbox?.paint,
      'line-width': {
        base: 12,
        stops: [
          [17, 16],
          [22, 20]
        ]
      }
    },
    layout: {
      'line-cap': 'round',
      'line-join': 'round'
    }
  });
};

const addRichItemsLayers = (
  map: Map,
  richItemsGeoJSON: IUnifiedGeojson,
  treeClickHandler: (feature: TMapMouseEvent) => void,
  selectedTreesHandler: (event: TMapMouseEvent) => void
): void => {
  const colorPaint = getColorProperty(richItemsGeoJSON.mapbox);
  if (!colorPaint) {
    return;
  }

  const dataExpression = colorPaint[0];
  const isMatch = dataExpression === 'match';
  const paintProperty = colorPaint.slice(0, isMatch ? 2 : 3);
  const colors = colorPaint.slice(isMatch ? 2 : 3);
  const hoverColors = colors.map((color, i) => {
    if (i % 2 === 1) {
      return getDarkerColor(color as string, 20);
    }
    return color;
  });

  createGeojsonLayer(map, richItemsGeoJSON);

  if (isGeometryType(richItemsGeoJSON, 'Point')) {
    createCustomGeojsonPointHoveredLayer(map, richItemsGeoJSON, paintProperty, hoverColors);
    createCustomGeojsonPointSelectedLayer(map, richItemsGeoJSON, paintProperty, hoverColors);
  } else {
    removeMapLayerById(map, CUSTOM_GEOJSON_TREE_HOVERED);
    removeMapLayerById(map, CUSTOM_GEOJSON_TREE_SELECTED);
  }

  if (isGeometryType(richItemsGeoJSON, 'LineString')) {
    createCustomGeojsonLineHoveredLayer(map, richItemsGeoJSON);
    createCustomGeojsonLineSelectedLayer(map, richItemsGeoJSON);
  } else {
    removeMapLayerById(map, CUSTOM_GEOJSON_LINE_HOVERED);
    removeMapLayerById(map, CUSTOM_GEOJSON_LINE_SELECTED);
  }

  addCustomLayersListeners(map, richItemsGeoJSON, treeClickHandler, selectedTreesHandler);

  reorderLayers(
    map,
    LAYERS_PRIORITY.map((layer, index) => ({
      name: layer,
      zIndex: index
    }))
  );
};

const drawSelectedRichItems = (map: Map, richItem: IRichItemMapboxFeature | null) => {
  const treeSource = getMapSourceById(map, CUSTOM_GEOJSON_TREE_SELECTED) as GeoJSONSource;
  const lineSource = getMapSourceById(map, CUSTOM_GEOJSON_LINE_SELECTED) as GeoJSONSource;

  if (!richItem) {
    treeSource?.setData(turf.featureCollection([]));
    lineSource?.setData(turf.featureCollection([]));
    return;
  }
  if (richItem.layer.type === 'line' && lineSource) {
    lineSource.setData(turf.featureCollection([richItem]));
  } else if (treeSource) {
    treeSource.setData(turf.featureCollection([richItem]));
  }
};

const getMapboxStyleType = (featureType: string): string | null => {
  const featureStyleRelation = {
    Point: 'circle',
    Polygon: 'fill',
    LineString: 'line'
  };
  return featureStyleRelation[featureType] || null;
};

const highlightChartRanges = (map: Map, chartLabels: IChartLabelConfig[], selectedRanges: (number | string)[], richItemsGeoJSON: IUnifiedGeojson, activeRichItem) => {
  const { geojson, mapbox } = richItemsGeoJSON;
  const featureType = geojson.features[0]?.geometry?.type;
  const colorPaint = getColorProperty(mapbox);
  const styleType = getMapboxStyleType(featureType);
  const paintProperty = getPaintPropertyName(richItemsGeoJSON);
  if (!map.getLayer(CUSTOM_GEOJSON_TREE_AREA) || !styleType || !colorPaint || !paintProperty) return;

  if ((!selectedRanges || !selectedRanges.length) && ERichItemFeatureType[activeRichItem] === styleType) {
    map.setPaintProperty(CUSTOM_GEOJSON_TREE_AREA, `${styleType}-opacity`, 1);
    return;
  }

  const isMatch = colorPaint[0] === 'match';
  const settings = [isMatch ? 'match' : 'step', ['get', paintProperty]] as (string | number)[];
  const getOpacity = (selected, range) => (selected.includes(range.mapbox_value) ? 1 : 0.1);
  const getMaxRange = (nextLabel) => (nextLabel ? nextLabel.mapbox_value : Infinity);

  if (!isMatch) {
    settings.push(...chartLabels.map((v, i) => [getOpacity(selectedRanges, v), getMaxRange(chartLabels[i + 1])]).flat());
  } else {
    settings.push(...chartLabels.map((v) => [v.mapbox_value, getOpacity(selectedRanges, v)]).flat());
  }
  settings.push(0);

  if (ERichItemFeatureType[activeRichItem] === styleType) {
    map.setPaintProperty(CUSTOM_GEOJSON_TREE_AREA, `${styleType}-opacity`, settings);
  }
};

const removeRichItemsLayers = (map: Map): void => {
  removeMapLayerById(map, CUSTOM_GEOJSON_TREE_AREA);
  removeMapLayerById(map, CUSTOM_GEOJSON_LINE_HOVERED);
  removeMapLayerById(map, CUSTOM_GEOJSON_LINE_SELECTED);
  removeMapLayerById(map, CUSTOM_GEOJSON_TREE_HOVERED);
  removeMapLayerById(map, CUSTOM_GEOJSON_TREE_SELECTED);
  removeMapLayerById(map, CUSTOM_GEOJSON_TREE_HOVER_AREA);
  removeMapLayerById(map, CUSTOM_GEOJSON_TREE_SELECTED_ICON);
};

const mapGroveRichItemsService = {
  addRichItemsLayers,
  removeRichItemsLayers,
  highlightChartRanges,
  detachTreeClickListeners,
  drawSelectedRichItems
};

export default mapGroveRichItemsService;
