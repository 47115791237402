import React, { useState, useEffect, useMemo, useRef, useCallback, Dispatch, SetStateAction, useContext } from 'react';
import styled from 'styled-components';
import { Map as Mapbox } from 'mapbox-gl';

import GlobalLoader from 'atomicComponents/GlobalLoader';
import Map, { ELegendPosition, MapContext } from 'components/shared/Map';
import MapSurveyControls from 'components/main/MapControls/MapSurveyControls';
import RichItemsLegend from 'components/main/MapControls/RichItemsLegend';
import farmsHooks, { IUnifiedGeojson } from 'hooks/farms.hooks';
import regionsHooks from 'hooks/regions.hooks';
import mapHooks, { IPopupFeature } from 'hooks/map.hooks';
import {
  getTileset,
  TAnyWrappedTileType,
  CUSTOM_GEOJSON_TREE_AREA,
  CUSTOM_GEOJSON_TREE_HOVER_AREA,
  TMapMouseEvent,
  SELECTED_TREES,
  TREE_AREA_SOURCE,
  CUSTOM_GEOJSON_TREE_AREA_SOURCE
} from 'services/util/mapHelpers';
import mapTilesetService from 'services/util/mapTileset';
import mapGroveRichItemsService, { SELECTED_RICH_ITEMS_LAYERS } from 'services/util/mapGroveRichItems';
import MapLoader from 'atomicComponents/MapLoader';

import { ERichItemType, ERichItemSubType, IRichItemMapboxFeature, EVIDENCE_IGNORED_RICH_ITEMS, RICH_ITEM_TYPES_GEO_ANALYTICS, RICH_ITEM_FRUIT_TYPES } from 'models/richItem';
import { ISurveyWithTimeRange } from 'models/survey';
import { IFarm } from 'models/farm';
import { IGrove } from 'models/grove';
import { IZone } from 'models/zone';
import { ITree, ITreeMapboxFeature } from 'models/tree';
import { InitialLayersState } from 'models/map';
import addRastersLayers from 'services/util/mapRasters';
import { IGroveLayersFlags } from 'components/main/MapControls/SidebarControls';
import TreeGallery from 'screens/TreeGallery';
import { IEvidenceParams } from 'redux/appStateSlice';
import TilesHooks from 'hooks/tiles.hooks';
import treeHooks from 'hooks/trees.hooks';
import { IRichItemMeasurementRanges, IRichItemRangeConfig } from 'services/data/appConfig';
import { regionMeasurementSystemSelector } from 'redux/region/regionSlice';
import ZoomControls from 'components/main/MapControls/ZoomControls';
import { useSelector, useDispatch } from 'react-redux';

const Container = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;

interface IProps {
  selectedFarm: IFarm;
  selectedGrove: IGrove;
  selectedZone: IZone;
  selectedSurvey: ISurveyWithTimeRange;
  surveys: ISurveyWithTimeRange[];
  groves: IGrove[];
  zones: IZone[];
  filteredTrees: string[];
  legendPosition?: ELegendPosition;
  onRichItemSelected: (richItem: ERichItemType | null) => void;
  onSurveySelected: (survey: ISurveyWithTimeRange) => void;
  onRichItemGeoJSONLoaded: (geojson: IUnifiedGeojson | null) => void;
  treeForPopup: IPopupFeature | null;
  onSelectPopupTree: Dispatch<SetStateAction<IPopupFeature | null>>;
  onMapSet: (map: Mapbox) => void;
  selectedTree: ITreeMapboxFeature | null;
  onSelectTree: (tree: ITreeMapboxFeature | null) => void;
  richItemMeasurementRanges?: IRichItemMeasurementRanges;
}

const CompareScreenColumn = ({
  selectedFarm,
  selectedGrove,
  selectedZone,
  zones,
  filteredTrees,
  selectedSurvey,
  surveys,
  groves,
  legendPosition,
  onSurveySelected,
  onRichItemSelected,
  onRichItemGeoJSONLoaded,
  treeForPopup,
  onSelectPopupTree,
  onMapSet,
  selectedTree,
  onSelectTree,
  richItemMeasurementRanges
}: IProps): JSX.Element => {
  const dispatch = useDispatch();
  const popupContainer = useRef<HTMLDivElement>(null);
  const [survey, setSurvey] = useState<ISurveyWithTimeRange>(selectedSurvey);
  const [map, setMap] = useState<Mapbox | null>(null);
  const [evidenceParams, setEvidenceParams] = useState<IEvidenceParams | null>(null);
  const [layers, setLayers] = useState<IGroveLayersFlags>(InitialLayersState);
  const [activeRichItem, setActiveRichItem] = useState<ERichItemType | null>(ERichItemType.Health);
  const [richItemSubType, setRichItemSubType] = useState<ERichItemSubType | null>(null);
  const mapOptions = mapHooks.useGetMapOptions(zones, selectedZone, selectedGrove, null);
  const { richItems } = farmsHooks.useRichItems(selectedFarm?.id, selectedGrove?.id, survey?.id, selectedFarm?.config?.features);
  const { region } = regionsHooks.useGetFarmRegion();
  const { showPopup } = mapHooks.useTreePopup(map, region?.measurementSystem, popupContainer?.current);
  const { getGroveTrees, trees } = farmsHooks.useGroveTrees();
  const { getFarmVectiles, vectiles, loading: vectilesLoading } = farmsHooks.useGetFarmVectiles();
  const { rasterLayers } = farmsHooks.useGroveRastersTiles(selectedFarm, selectedGrove, survey);
  const groveTilePath = TilesHooks.useGetGroveTiles(vectiles, selectedGrove, selectedSurvey);
  const { sourceTrees } = treeHooks.useGetGroveSurveyTrees(selectedGrove, groveTilePath);
  const { treeClickHandler } = mapHooks.useTreeClickHandler(map, trees, sourceTrees, onSelectPopupTree, onSelectTree);
  const { mapOptions: options, setMapOptions } = useContext(MapContext);
  const activeRichItemRef = useRef(activeRichItem);
  const regionMeasurementSystem = useSelector(regionMeasurementSystemSelector);

  useEffect(() => {
    if (options?.center && options?.zoom) {
      setMapOptions(options);
    } else if (mapOptions.bounds) {
      setMapOptions(mapOptions);
    }
  }, [mapOptions, options, setMapOptions]);

  useEffect(() => {
    setRichItemSubType(activeRichItem === ERichItemType.FruitAggregation ? ERichItemSubType.PostThinning : null);
  }, [activeRichItem]);

  const richItemsRangesByRegion = useMemo((): IRichItemRangeConfig | null => {
    if (!activeRichItem) {
      return null;
    }

    if (regionMeasurementSystem && richItemMeasurementRanges && [...RICH_ITEM_TYPES_GEO_ANALYTICS, ERichItemType.FreeArea].includes(activeRichItem)) {
      const richItemMetric = richItemMeasurementRanges[regionMeasurementSystem][activeRichItem] as IRichItemRangeConfig;

      return richItemMetric;
    }

    if (selectedGrove && RICH_ITEM_FRUIT_TYPES.includes(activeRichItem) && selectedGrove.richItemRanges) {
      const groveRichItemRanges = selectedGrove.richItemRanges[ERichItemType.FruitAggregation];
      const { mapbox, ranges } = groveRichItemRanges;

      return {
        mapbox: JSON.parse(mapbox),
        ranges
      };
    }

    return null;
  }, [activeRichItem, richItemMeasurementRanges, regionMeasurementSystem, selectedGrove]);

  const selectedRichItem = farmsHooks.useSelectedRichItem(richItems, activeRichItem, richItemSubType, richItemsRangesByRegion?.mapbox);

  const { geoJSON: richItemGeoJSON, loading: richItemsLoading } = farmsHooks.useRichItemGeoJSON(
    selectedRichItem,
    trees,
    selectedFarm?.id,
    selectedGrove?.id,
    survey?.id,
    region?.measurementSystem
  );

  const scoresLoading = useMemo(() => richItemsLoading || vectilesLoading, [vectilesLoading, richItemsLoading]);

  const groveTiles = useMemo((): TAnyWrappedTileType | null => {
    if (vectiles) {
      return getTileset(vectiles[survey.id]?.[selectedGrove.id]?.['current-tiles'] || null, survey);
    }
    return null;
  }, [vectiles, selectedGrove, survey]);

  useEffect(() => {
    onSelectPopupTree(null);
  }, [selectedGrove, activeRichItem, onSelectPopupTree]);

  useEffect(() => {
    if (!map) return;
    onMapSet(map);
  }, [map, onMapSet]);

  useEffect(() => {
    if (!map) return;
    if (rasterLayers) {
      addRastersLayers(map, rasterLayers?.urls || [], rasterLayers?.bbox);
    }
  }, [map, rasterLayers]);

  useEffect(() => {
    activeRichItemRef.current = activeRichItem;
  }, [activeRichItem]);

  const selectedTreeClickHandler = useCallback(
    (event: TMapMouseEvent): void => {
      if (!map) return;
      const features = map.queryRenderedFeatures(event.point);
      if (!features) {
        return;
      }
      const treeFeature = features.find((feature) => [...SELECTED_RICH_ITEMS_LAYERS, SELECTED_TREES].includes(feature.layer.id)) as IRichItemMapboxFeature;

      if (treeFeature && selectedFarm && selectedGrove && selectedZone && activeRichItemRef.current && !EVIDENCE_IGNORED_RICH_ITEMS.includes(activeRichItemRef.current)) {
        setEvidenceParams({
          farmID: selectedFarm.id,
          zoneID: selectedZone.id,
          groveID: selectedGrove.id,
          treeID: (treeFeature as any).properties.id,
          richItemType: activeRichItemRef.current
        });
      }
    },
    [map, selectedFarm, selectedZone, selectedGrove]
  );

  useEffect(() => {
    setSurvey(selectedSurvey);
  }, [selectedSurvey]);

  useEffect(() => {
    onSurveySelected(survey);
  }, [survey, onSurveySelected]);

  useEffect(() => {
    onRichItemSelected(activeRichItem);
  }, [activeRichItem, onRichItemSelected]);

  useEffect(() => {
    onRichItemGeoJSONLoaded(richItemGeoJSON);
  }, [richItemGeoJSON, onRichItemGeoJSONLoaded]);

  useEffect(() => {
    if (map) {
      mapGroveRichItemsService.removeRichItemsLayers(map);
      mapTilesetService.removeTilesetLayers(map);
      const isActiveHealth = activeRichItem === ERichItemType.Health;

      if (richItemGeoJSON && !isActiveHealth && layers.scores && !scoresLoading) {
        mapGroveRichItemsService.addRichItemsLayers(map, richItemGeoJSON, treeClickHandler, selectedTreeClickHandler);
        mapTilesetService.removeTilesetLayers(map);
      } else if (groveTiles && layers.scores && !scoresLoading) {
        mapTilesetService.addTilesetLayers(map, groveTiles, false, treeClickHandler, selectedTreeClickHandler);
        mapGroveRichItemsService.removeRichItemsLayers(map);
      } else {
        mapGroveRichItemsService.removeRichItemsLayers(map);
        mapTilesetService.removeTilesetLayers(map);
      }
    }

    return () => {
      if (map) {
        mapTilesetService.detachTreeClickListeners(map, treeClickHandler, selectedTreeClickHandler);
        mapGroveRichItemsService.detachTreeClickListeners(map, treeClickHandler, selectedTreeClickHandler);
      }
    };
  }, [map, groveTiles, richItemGeoJSON, layers.scores, treeClickHandler, selectedTreeClickHandler, scoresLoading, activeRichItem]);

  useEffect(() => {
    if (!map || !map.getLayer(CUSTOM_GEOJSON_TREE_AREA)) {
      return;
    }
    const getTilesetFilter = (ids: string[], propName = 'id') => ['in', propName, ...ids];

    if (filteredTrees.length) {
      map.setFilter(CUSTOM_GEOJSON_TREE_AREA, getTilesetFilter(filteredTrees));
      map.setFilter(CUSTOM_GEOJSON_TREE_HOVER_AREA, getTilesetFilter(filteredTrees));
    } else {
      map.setFilter(CUSTOM_GEOJSON_TREE_AREA, null);
      map.setFilter(CUSTOM_GEOJSON_TREE_HOVER_AREA, null);
    }
  }, [map, filteredTrees]);

  useEffect(() => {
    if (richItems.length) {
      setActiveRichItem((prev: ERichItemType | null) => {
        const activeRichItemModel = richItems.find((richItem) => richItem.richItemTypeName === prev);
        return activeRichItemModel ? prev : richItems[0].richItemTypeName;
      });
    } else {
      setActiveRichItem(null);
    }
  }, [richItems]);

  useEffect(() => {
    if (selectedFarm?.id) {
      getFarmVectiles(selectedFarm?.id);
    }
  }, [selectedFarm?.id, getFarmVectiles]);

  useEffect(() => {
    if (!treeForPopup || !trees) {
      showPopup(null);
      return;
    }

    const { treeID } = treeForPopup as ITree;
    const tree = trees[treeID];
    const coordinates = getPopupCoordinates(map, treeID, activeRichItem);
    const upToDateTree = { ...treeForPopup, ...tree };

    if (coordinates) {
      upToDateTree.coordinates = coordinates;
    }

    showPopup(upToDateTree as IPopupFeature);
  }, [map, activeRichItem, treeForPopup, trees, showPopup]);

  const getPopupCoordinates = (map, treeID, richItemType) => {
    if (!map) {
      return null;
    }
    const source = richItemType === ERichItemType.Health ? TREE_AREA_SOURCE : CUSTOM_GEOJSON_TREE_AREA_SOURCE;
    const sourceTrees = map.querySourceFeatures(source, { sourceLayer: 'original' });
    const tree = sourceTrees.find((t) => t.properties.id === treeID);

    return tree?.geometry.type === 'Point' ? tree.geometry.coordinates : null;
  };

  useEffect(() => {
    if (selectedFarm?.id && survey.id && selectedGrove?.id) {
      getGroveTrees(selectedFarm.id, selectedGrove.id, survey.id);
    }
  }, [selectedFarm, selectedGrove, survey, getGroveTrees]);

  useEffect(() => {
    if (!map) return;

    if (activeRichItem === ERichItemType.Health) {
      mapTilesetService.drawSelectedTrees(map, selectedTree as ITreeMapboxFeature);
      mapGroveRichItemsService.drawSelectedRichItems(map, null);
    } else {
      mapGroveRichItemsService.drawSelectedRichItems(map, selectedTree as IRichItemMapboxFeature);
      mapTilesetService.drawSelectedTrees(map, null);
    }
  }, [map, selectedTree, activeRichItem]);

  useEffect(() => {
    setLayers(InitialLayersState);
  }, [selectedFarm]);

  const onGalleryClose = () => {
    dispatch(setEvidenceParams(null));
  };

  if (!selectedGrove || !survey) {
    return <GlobalLoader />;
  }

  return (
    <Container ref={popupContainer}>
      {activeRichItem !== ERichItemType.Health && !richItemGeoJSON && <MapLoader />}
      {evidenceParams && <TreeGallery evidenceParams={evidenceParams} onClose={onGalleryClose} />}
      <Map
        onMapInit={setMap}
        isFullScreen={false}
        legend={
          <RichItemsLegend
            onRichItemSelected={setActiveRichItem}
            onRichItemSubtypeSelected={setRichItemSubType}
            richItems={richItems}
            activeRichItemSubType={richItemSubType}
            activeRichItem={selectedRichItem}
            onLayersChanged={setLayers}
            legendPosition={legendPosition}
          />
        }
        legendPosition={legendPosition}
        controls={<MapSurveyControls surveys={surveys} selectedSurvey={survey} onSelectSurvey={setSurvey} />}
        zoomControls={<ZoomControls map={map} />}
      />
    </Container>
  );
};

export default CompareScreenColumn;
