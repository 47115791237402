import { IUser } from 'models/user';

const mapSignedInUser = (user: IUser): IUser => ({
  accessToken: user.accessToken,
  displayName: user.displayName,
  email: user.email,
  emailVerified: user.emailVerified,
  isAnonymous: user.isAnonymous,
  phoneNumber: user.phoneNumber,
  photoURL: user.photoURL,
  uid: user.uid,
  refreshToken: user.refreshToken,
  idToken: user.idToken
});

const userMapperService = {
  mapSignedInUser
};

export default userMapperService;
