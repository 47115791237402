import React, { useMemo } from 'react';

import numberUtils from 'utils/numbers';

import { IGrove } from 'models/grove';
import { IMission, EMissionStatus } from 'models/mission';
import { EMeasurementSystem } from 'models/region';
import MissionStats from 'components/missions/MissionStats';
import { ICapacityStatistic, TMappedStatistic } from 'models/statistic';

// prettier-ignore
const getReplantsSum = (groves: IGrove[], statistic: TMappedStatistic | null): number => groves.reduce((acc, entry) => {
  const groveStats = statistic?.[entry.id] as ICapacityStatistic;

  return acc + (groveStats?.statistic || 0);
}, 0);

interface IProps {
  groves: IGrove[];
  mission?: Pick<IMission, 'type' | 'groves' | 'timestamp' | 'createdBy'>;
  metricType?: EMeasurementSystem;
  isCreated?: boolean;
  showBackground?: boolean;
  statistic: TMappedStatistic | null;
}

const ReplantMetricMissionStats = ({ groves, mission, metricType = EMeasurementSystem.Metric, isCreated = true, showBackground = false, statistic }: IProps): JSX.Element => {
  const resolvedGroves = useMemo(() => {
    if (!mission?.groves) return [];
    return groves.filter((grove) => mission.groves[grove.id] === EMissionStatus.Done);
  }, [groves, mission?.groves]);
  const affectedGroves = useMemo(() => (mission?.groves ? groves.filter((grove) => mission.groves[grove.id]) : groves), [groves, mission?.groves]);

  const resolvedReplantsCount = useMemo(() => getReplantsSum(resolvedGroves, statistic), [resolvedGroves, statistic]);

  const affectedReplantsCount = useMemo(() => getReplantsSum(affectedGroves, statistic), [affectedGroves, statistic]);

  const resolvedArea = useMemo(() => numberUtils.formatArea(numberUtils.getGrovesArea(resolvedGroves, metricType), 1, metricType), [resolvedGroves, metricType]);

  const affectedArea = useMemo(() => numberUtils.formatArea(numberUtils.getGrovesArea(affectedGroves, metricType), 1, metricType), [metricType, affectedGroves]);

  return (
    <MissionStats
      groves={groves}
      mission={mission}
      isCreated={isCreated}
      showBackground={showBackground}
      resolvedArea={resolvedArea}
      affectedArea={affectedArea}
      resolvedTreesCount={resolvedReplantsCount}
      affectedTreesCount={affectedReplantsCount}
      resolvedTitle={'replant.replants'}
    />
  );
};

export default ReplantMetricMissionStats;
