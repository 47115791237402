import React, { useCallback, useMemo } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { useUpdateFarmMission } from 'services/data/farms';
import signInHooks from 'hooks/signIn.hooks';
import missionHooks from 'hooks/mission.hooks';

import Button from 'atomicComponents/Button';
import MissionModal from 'components/missions/MissionModal';

import { IMission, EMissionStatus, IMissionGroveLogEntry } from 'models/mission';
import { IUserInfo } from 'models/user';
import { EEventType } from 'models/analytics';

import { DoneIcon, CongratulationsIcon } from 'assets/images';

import { mfColors } from 'vars';

const Icon = styled.div`
  display: inline;
`;

const Title = styled.div`
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: center;
  font-family: MontserratBold;
  font-size: 20px;
  text-align: center;
  color: ${mfColors.darkGrey};

  ${Icon} {
    margin: 0 8px 0 0;
  }
`;

const Content = styled.div`
  margin: 16px 0 0;
  font-size: 16px;
  text-align: center;

  > div {
    margin: 16px 0;

    &:last-child {
      margin-bottom: 8px;
    }
  }
`;

interface ISecondaryButtonProps {
  isDisabled: boolean;
}

const SecondaryButton = styled.div`
  color: ${({ isDisabled }: ISecondaryButtonProps) => (isDisabled ? mfColors.grey : mfColors.blue)};
  text-decoration: underline;
  cursor: ${({ isDisabled }: ISecondaryButtonProps) => (isDisabled ? 'default' : 'pointer')};
`;

interface IProps {
  mission: IMission;
  onCancel: () => void;
}

const ResolveMissionGrovesModal = ({ mission, onCancel }: IProps) => {
  const { t } = useTranslation();
  const updateFarmMission = useUpdateFarmMission();
  const user = signInHooks.useSignedInUser();
  const { logMissionEvent } = missionHooks.useMissionLogger();

  const handleSave = useCallback(
    async (resolveGroves: boolean) => {
      // prettier-ignore
      const groves = resolveGroves ? Object.keys(mission.groves).reduce((acc: {[key: string]: EMissionStatus}, groveID) => {
        acc[groveID] = EMissionStatus.Done;
        return acc;
      }, {}) : Object.keys(mission.groves).filter((groveID) => mission.groves[groveID] === EMissionStatus.Done).reduce((acc: {[key: string]: EMissionStatus}, groveID) => {
        acc[groveID] = mission.groves[groveID];
        return acc;
      }, {});
      // prettier-ignore
      const logs = resolveGroves ? Object.keys(mission.groves).reduce((acc: {[key: string]: IMissionGroveLogEntry}, groveID) => {
        acc[groveID] = {
          userID: (user as IUserInfo).id,
          timestamp: +new Date(),
          user: (user as IUserInfo).displayName
        };
        return acc;
      }, {}) : (mission.logs || {});
      await updateFarmMission(mission.id, {
        ...mission,
        groves,
        approved: true,
        logs
      });

      // prettier-ignore
      toast(<MissionModal
        icon={<CongratulationsIcon />}
        title={t('shared.hooray')}
        content={t(resolveGroves ? 'missions.all_groves_are_completed_and_moved_to_done' : 'missions.mission_moved_to_done')}
      />, { toastId: 'done' });
      logMissionEvent(mission, EEventType.MissionResolve);
      onCancel();
    },
    [mission, logMissionEvent, t, onCancel, updateFarmMission, user]
  );

  const hasDoneGroves = useMemo(() => !!mission?.groves && !!Object.keys(mission.groves).find((groveID) => mission.groves[groveID] === EMissionStatus.Done), [mission]);

  return (
    <>
      <Title>
        {t('missions.done_q')}
        <Icon>
          <DoneIcon />
        </Icon>
      </Title>
      <Content>
        <div>{t('missions.the_entire_mission_will_be_moved_to_done')}</div>
        <div>{t('missions.what_to_do_with_remaining_blocks')}</div>
        <Button text={t('missions.mark_all_remaining_blocks_as_done')} onClick={() => handleSave(true)} />
        <SecondaryButton isDisabled={!hasDoneGroves} onClick={() => (hasDoneGroves ? handleSave(false) : undefined)}>
          {t('missions.move_to_done_without_remaining_blocks')}
        </SecondaryButton>
      </Content>
    </>
  );
};

export default ResolveMissionGrovesModal;
