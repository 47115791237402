import React from 'react';
import styled from 'styled-components';

import Button from 'atomicComponents/Button';

import { mfColors } from 'vars';

interface ITitleProps {
  iconFirst: boolean;
}

const Icon = styled.div`
  display: inline;
`;

const Title = styled.div`
  display: flex;
  flex-direction: ${({ iconFirst }: ITitleProps) => (iconFirst ? 'row-reverse' : 'row')};
  align-items: center;
  justify-content: center;
  font-family: MontserratBold;
  font-size: 20px;
  text-align: center;
  color: ${mfColors.darkGrey};

  ${Icon} {
    margin: ${({ iconFirst }: ITitleProps) => (iconFirst ? '0 16px 0 0' : '0 0 0 16px')};
  }
`;

const Content = styled.div`
  margin: 16px 0 0;
  font-size: 16px;
  text-align: center;
`;

const Footer = styled.div`
  margin: 20px 0 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
`;

export interface IEditFeedParams {
  title: string;
}

interface IProps {
  title: string;
  content?: string;
  okText?: string;
  icon?: JSX.Element | null;
  iconFirst?: boolean;
  cancelText?: string;
  onOk?: () => void;
  onCancel?: () => void;
}

const BUTTON_STYLE = {
  borderRadius: '16px',
  height: '32px',
  fontFamily: 'MontserratBold',
  backgroundColor: mfColors.lightBlue,
  padding: '8px 24px'
};

const CANCEL_BUTTON_TYLE = {
  ...BUTTON_STYLE,
  backgroundColor: mfColors.lightGrey,
  color: mfColors.lightBlue
};

const MissionModal = ({ onOk, onCancel, title, icon, content, okText, cancelText, iconFirst = false }: IProps) => (
  <>
    <Title iconFirst={iconFirst}>
      {title}
      {icon && <Icon>{icon}</Icon>}
    </Title>
    {content && <Content>{content}</Content>}
    {(onOk || onCancel) && (
      <Footer>
        {onCancel && cancelText && <Button style={CANCEL_BUTTON_TYLE} isSecondary onClick={onCancel} text={cancelText} />}
        {onOk && okText && <Button style={BUTTON_STYLE} onClick={onOk} text={okText} />}
      </Footer>
    )}
  </>
);

export default MissionModal;
