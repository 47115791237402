import React, { useMemo, useCallback, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/pro-solid-svg-icons';

import dateUtils from 'utils/date';
import dateFormats from 'models/date';
import farmsHooks from 'hooks/farms.hooks';
import missionHooks from 'hooks/mission.hooks';
import { missionsListLink } from 'utils/routes';

import MissionModal from 'components/missions/MissionModal';
import MissionType from 'components/missions/MissionType';
import UpdateGroveControl from 'containers/update-mission/UpdateGroveControl';
import DataTable, { ESortingDirection } from 'atomicComponents/DataTable';
import FormInput from 'atomicComponents/FormInput';
import { IGrove } from 'models/grove';
import { IMission, EMissionStatus, NO_REPORT_MISSION_TYPES } from 'models/mission';
import { TMappedStatistic } from 'models/statistic';

import { mfSizes } from 'vars';

const Wrapper = styled.div`
  height: 100%;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 16px;
  padding: ${mfSizes.missionsPadding};
`;

const BackIcon = styled(FontAwesomeIcon)`
  margin: 0 4px 0 0;
`;

const Back = styled.div`
  cursor: pointer;
  text-transform: uppercase;
  font-size: 16px;
  display: flex;
  flex-direction: row;
  font-family: MontserratBold;
`;

const MissionTitle = styled.div`
  margin: 0 0 16px;
  padding: ${mfSizes.missionsPadding};
`;

const RowPrimaryText = styled.div`
  font-family: MontserratBold;
`;

const TableWrapper = styled.div`
  height: calc(100% - 112px);
  padding: ${mfSizes.missionsPadding};
  overflow: auto;
`;

interface IGroveEntry extends IGrove {
  metric: string | number;
  status: EMissionStatus;
  user?: string;
  timestamp?: number;
}

interface IProps {
  mission: IMission;
  checkedGroves: string[];
  statistic: TMappedStatistic | null;
  setCheckedGroves: (groves: string[] | ((groves: string[]) => string[])) => void;
}

const MissionResolveMenu = ({ mission, statistic, checkedGroves, setCheckedGroves }: IProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const missionRef = useRef<IMission | null>(null);
  const { groves } = farmsHooks.useFarmEntities();
  const { logMissionResolveGroveEvent } = missionHooks.useMissionLogger();

  const isDone = useMemo(() => mission.approved || !Object.keys(mission.groves).find((groveID) => mission.groves[groveID] !== EMissionStatus.Done), [mission]);

  useEffect(() => {
    missionRef.current = mission;
  }, [mission]);

  const grovesToShow = useMemo(() => {
    if (!NO_REPORT_MISSION_TYPES.includes(mission.type) && !statistic) return [];

    return groves
      .filter((grove) => !!mission.groves[grove.id])
      .map((grove) => ({
        ...grove,
        ...mission.logs?.[grove.id],
        status: mission.groves[grove.id]
      }))
      .filter((entry) => !!entry) as IGroveEntry[];
  }, [mission.groves, mission.logs, groves, statistic, mission.type]);

  const toggleCheckedGrove = useCallback(
    (groveID: string, isChecked: boolean) => {
      if (isChecked) {
        setCheckedGroves((prev) => [...prev, groveID]);
      } else {
        setCheckedGroves((prev) => prev.filter((grove) => grove !== groveID));
      }
    },
    [setCheckedGroves]
  );

  const handleCheckedChange = useCallback(
    (groveID: string, isChecked: boolean) => {
      if (!isChecked) {
        toast(<MissionModal title={t('shared.canceled')} content={''} />);
      } else {
        logMissionResolveGroveEvent(mission, groveID);
      }
      toggleCheckedGrove(groveID, isChecked);
    },
    [mission, toggleCheckedGrove, logMissionResolveGroveEvent, t]
  );

  const navigateToList = useCallback(() => {
    navigate(missionsListLink(mission.farmID, isDone ? EMissionStatus.Done : EMissionStatus.InProgress));
  }, [navigate, mission.farmID, isDone]);

  const tableConfig = useMemo(
    () => [
      {
        title: '',
        render: (grove: IGrove) => (
          <UpdateGroveControl
            mission={missionRef.current as IMission}
            isDone={isDone}
            isChecked={checkedGroves.includes(grove.id)}
            onCheckedChange={(isChecked: boolean) => handleCheckedChange(grove.id, isChecked)}
          />
        ),
        width: '52px',
        style: {
          fontSize: '12px',
          minHeight: '52px'
        }
      },
      {
        title: t('missions.grove'),
        render: (grove: IGroveEntry) => <RowPrimaryText>{grove.name}</RowPrimaryText>,
        key: 'name',
        sortable: false,
        width: '80px',
        style: {
          fontSize: '12px',
          justifyContent: 'flex-start'
        }
      },
      {
        title: t('missions.completed_on'),
        render: (grove: IGroveEntry) => {
          const hasTimestamp = grove.status === EMissionStatus.Done && grove.timestamp;
          return hasTimestamp ? dateUtils.formatDate(grove.timestamp as number, dateFormats.DATE_FORMAT_DD_MM_YYYY) : '';
        },
        key: 'completed_on',
        sortable: false,
        width: '80px',
        style: {
          justifyContent: 'center',
          fontSize: '12px'
        }
      },
      {
        title: t('missions.reported_by'),
        render: (grove: IGroveEntry) => (grove.status === EMissionStatus.Done && grove.user ? grove.user : ''),
        key: 'user',
        width: '96px',
        sortable: false,
        style: {
          textAlign: 'left',
          fontSize: '12px'
        }
      }
    ],
    [t, handleCheckedChange, checkedGroves, isDone]
  );

  const tableProps = useMemo(
    () => ({
      config: tableConfig,
      data: grovesToShow,
      defaultSorting: {
        key: 'timestamp',
        direction: ESortingDirection.DESC
      },
      showFooter: false,
      rowsPerPage: 0
    }),
    [tableConfig, grovesToShow]
  );

  return (
    <Wrapper>
      <Header>
        <Back onClick={navigateToList}>
          <BackIcon icon={faChevronLeft} />
          {t('missions.missions')}
        </Back>
        <MissionType type={mission.subType || mission.type} />
      </Header>
      <MissionTitle>
        <FormInput isDisabled background="transparent" inputWidth="100%" value={mission.title} name="title" placeholder={t('missions.mission_name')} />
      </MissionTitle>
      <TableWrapper>
        <DataTable<IGroveEntry> {...tableProps} />
      </TableWrapper>
    </Wrapper>
  );
};

export default MissionResolveMenu;
