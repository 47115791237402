import React, { Fragment, useMemo, useState } from 'react';
import styled from 'styled-components';
import dateUtils from 'utils/date';
import { IUserAlerts } from 'models/user';
import dateFormats from 'models/date';
import { mfColors, mfShadows } from 'vars';
import { useTranslation } from 'react-i18next';
import { indexBy } from 'utils/helpers';

const Container = styled.div`
  padding-top: 10px;
  overflow: auto;
`;

const LastNotification = styled.div`
  font-size: 14px;
  text-align: left;
`;

const NotificationsWrapper = styled.div`
  text-align: left;
`;

interface INotificationItem {
  read: boolean;
}

const NotificationItem = styled.div`
  padding: 16px 16px;
  margin: 0 8px 8px 0;
  color: ${(props: INotificationItem) => (props.read ? mfColors.grey : 'inherit')};
  cursor: pointer;

  border: 1px solid ${mfColors.white};
  border-radius: 8px;
  background: ${mfColors.white};
  box-shadow: ${mfShadows.timelineShadow};

  &:first-child {
    border-top: none;
  }
`;
const DateSeparator = styled.div`
  color: ${mfColors.lightBlue};
  padding: 10px 16px;
  font-family: 'MontserratBold';
`;

const NotificationTitle = styled.div`
  margin-bottom: 8px;
`;
const NotificationAt = styled.div`
  font-family: 'MontserratBold';
  margin-bottom: 5px;
`;
const NotificationContent = styled.div``;
const ShowMore = styled.div`
  cursor: pointer;
  font-size: 12px;
  line-height: 15px;
  color: ${mfColors.lightBlue};
  text-align: center;
  padding: 0 0 24px;
`;

const formatAlert = (alert) => {
  const { type, dateRange = [] } = alert;
  let { message = '', title = '' } = alert;
  const dateFormat = dateFormats.DATE_FORMAT_DD_MMMM_YYYY;
  const indexDate = dateUtils.formatDate(alert.createdAt, dateFormat);

  let entityName;
  let alertDuration;

  if (type === 'weather' && dateRange.length) {
    const startDate = dateUtils.splitDate(dateRange[0]);
    const endDate = dateUtils.splitDate(dateRange.slice(-1).pop());
    alertDuration = dateUtils.formatDate(startDate, dateFormat);

    if (dateRange.length > 1) {
      alertDuration = `${dateUtils.formatDate(startDate, dateFormat)} - ${dateUtils.formatDate(endDate, dateFormat)}`;
    }

    title = title.replaceAll('\\n', '');
    [entityName] = title.split(' - ');
    title = title.replace(entityName, '').replace(' - ', '');
    message += ' forecasted for:';
  }

  return { ...alert, entityName, title, alertDuration, message, indexDate };
};

interface Props {
  farmName: string | undefined;
  notifications: IUserAlerts[];
  handleClickNotification: (notification: IUserAlerts) => void;
}

const NotificationsView = ({ farmName, notifications, handleClickNotification }: Props) => {
  const { t } = useTranslation();
  const [showAllAlerts, setShowAllAlerts] = useState(false);
  const lastDate = useMemo(() => notifications && notifications.length && notifications[0].createdAt, [notifications]);
  const notificationList = useMemo(() => {
    if (!notifications.length) return {};

    const items = notifications.map(formatAlert);
    const visibleItems = showAllAlerts ? items : [items[0]];

    return indexBy('indexDate', visibleItems);
  }, [notifications, showAllAlerts]);

  if (!notifications.length) {
    return <div>{t('notifications.no_data', { count: 14 })}</div>;
  }

  return (
    <Container>
      <LastNotification>
        <NotificationAt>{`${t('notifications.notification_at')} ${farmName}`}</NotificationAt>
        {t('notifications.last_update')}
        <span> </span>
        {dateUtils.dateFromNow(lastDate)}
      </LastNotification>
      <NotificationsWrapper>
        {Object.keys(notificationList).map((date) => (
          <Fragment key={date}>
            <DateSeparator>{date}</DateSeparator>
            <>
              {notificationList[date].map((item) => (
                <NotificationItem key={item.id} read={item.read} onClick={() => handleClickNotification(item)}>
                  <NotificationTitle>
                    <b>{item.title}</b>
                    {item.message}
                  </NotificationTitle>
                  <NotificationContent>{item.alertDuration && <b>{item.alertDuration}</b>}</NotificationContent>
                </NotificationItem>
              ))}
            </>
          </Fragment>
        ))}
      </NotificationsWrapper>
      {!showAllAlerts && <ShowMore onClick={() => setShowAllAlerts(true)}>{t('notifications.previous_alerts')}</ShowMore>}
    </Container>
  );
};

export default NotificationsView;
