import React, { useState, useEffect, useRef, useCallback } from 'react';
import styled, { css, keyframes } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as icons from '@fortawesome/pro-solid-svg-icons';
import numberUtils from 'utils/numbers';
import dateUtils from 'utils/date';
import { useAnalytics } from 'hooks/analytics.hooks';
import signInHooks from 'hooks/signIn.hooks';
import { IWeather } from 'models/weather';
import { IFarm } from 'models/farm';
import { EEventType } from 'models/analytics';
import { EMeasurementSystem } from 'models/region';
import { WindIcon, HumidityIcon, PrecipationIcon, ETOIcon } from 'assets/images';
import { mfColors } from 'vars';
import farmsHooks from 'hooks/farms.hooks';

interface IWrapperProps {
  isExpanded: boolean;
}

const TemperatureExpanding = keyframes`
  0% {
    font-size: 18px;
    transform: translate(0px,-120px);
  }
  100% {
    font-size: 34px;
    transform: translate(0px,0px);
  }
`;

const TemperatureCollapsing = keyframes`
  0% {
    font-size: 50px;
    transform: translate(-10px,110px);
  }
  100% {
    font-size: 18px;
    transform: translate(0px,0px);
  }
`;

const IconExpansion = keyframes`
  0% {
    font-size: 26px;
    transform: translate(-45px,-50px);
  }
  100% {
    font-size: 72px;
    transform: translate(0px,0px);
  }
`;

const IconCollapsing = keyframes`
0% {
  font-size: 72px;
  transform: translate(120px,60px);
}
100% {
  font-size: 26px;
  transform: translate(0px,0px);
}
`;

const CloseIconCard = styled.div`
  display: ${({ isExpanded }: IWrapperProps) => (isExpanded ? 'flex' : 'none')};
  position: absolute;
  padding: 16px;
  cursor: pointer;
  font-size: 16px;
  top: 0;
  right: 0;
  color: ${mfColors.grey};
  &:hover {
    color: ${mfColors.black};
  }
`;

const IconExpandAnimation = () => css`
  ${IconExpansion} 700ms ease-in-out forwards;
`;
const IconCollapseAnimation = () => css`
  ${IconCollapsing} 700ms ease-in-out forwards;
`;
const TemperatureExpandAnimation = () => css`
  ${TemperatureExpanding} 700ms ease-in-out forwards;
`;
const TempratureCollapseAnimation = () => css`
  ${TemperatureCollapsing} 700ms ease-in-out forwards;
`;

const Wrapper = styled.div`
  background: ${({ isExpanded }: IWrapperProps) => (isExpanded ? mfColors.superLightBlue : mfColors.white)};
  opacity: ${({ isExpanded }: IWrapperProps) => (isExpanded ? '1' : '0.6')};
  border-radius: 8px;
  transition: width 0.7s, height 0.7s;
  width: ${({ isExpanded }: IWrapperProps) => (isExpanded ? '395px' : '150px')};
  height: ${({ isExpanded }: IWrapperProps) => (isExpanded ? '720px' : '60px')};
  overflow: hidden;
  &: hover {
    opacity: 1;
  }
`;

const WeatherPreview = styled.div`
  display: flex;
  flex-direction: ${({ isExpanded }: IWrapperProps) => (isExpanded ? 'column' : 'row')};
  min-height: 64px;
  margin-left: ${({ isExpanded }: IWrapperProps) => (isExpanded ? '20px' : '0px')};
  align-items: center;
  justify-content: center;
  cursor: pointer;
  span {
    margin: 0 0 0 20px;
  }
`;

const WeatherDetails = styled.div`
  padding: 9px 16px 24px;
  display: flex;
  margin-top: 80px;
  width: 420px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const WeatherDetailsTemperature = styled.div`
  position: ${({ isExpanded }: IWrapperProps) => (isExpanded ? 'absolute' : 'relative')};
  margin-right: ${({ isExpanded }: IWrapperProps) => (isExpanded ? '10px' : '0')};
  margin-top: ${({ isExpanded }: IWrapperProps) => (isExpanded ? '205px' : '0')};
  animation: ${({ isExpanded }: IWrapperProps) => (isExpanded ? TemperatureExpandAnimation : TempratureCollapseAnimation)};
`;

const WeatherDetailsInfo = styled.div`
  width: 292px;
  font-size: 16px;
  line-height: 24px;
`;

const WeatherDetailsRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 24px 0 0;
`;

const WeatherDetailsRowLabel = styled.div`
  display: flex;
  flex-direction: row;
  align-items: left;
  justify-content: center;

  span {
    margin: 0 0 0 10px;
  }
`;

const WeatherDetailsRowValue = styled.span`
  font-family: MontserratBold;
`;

const WeatherDetailsSelector = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-basis: 33.333333%;
  width: 100%;
  margin: 20px 0 0 -16px;
  padding: 24px 0px 12px 24px;
  position: relative;
  overflow-x: auto;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: ${mfColors.darkBlue};
    opacity: 0.1;
  }
`;

interface IWeatherDetailsCardProps {
  selected: boolean;
}

const WeatherDetailsCard = styled.div`
  min-width: 60px;
  height: 85px;
  border: 3px solid ${({ selected }: IWeatherDetailsCardProps) => (selected ? mfColors.lightBlue : mfColors.grey)};
  box-sizing: border-box;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px 0;
  font-size: 11px;
  line-height: 20px;
  margin: 7px 8px 0 0;
  font-family: MontserratBold;
  cursor: pointer;
`;

const WeatherDetailsIconWrapper = styled.div`
  position: ${({ isExpanded }: IWrapperProps) => (isExpanded ? 'absolute' : 'relative')};
  font-size: ${({ isExpanded }: IWrapperProps) => (isExpanded ? '72px' : '26px')};
  margin-top: ${({ isExpanded }: IWrapperProps) => (isExpanded ? '90px' : '0')};
  animation: ${({ isExpanded }: IWrapperProps) => (isExpanded ? IconExpandAnimation : IconCollapseAnimation)};
`;

const WeatherDetailsCardIcon = styled(FontAwesomeIcon)`
  font-size: 20px;
  margin: -8px 0 4px;
`;
const WeatherDetailsCardTemperature = styled.div`
  font-size: 11px;
`;

const WeatherDetailsCardLegend = styled.span`
  position: relative;
  font-size: 11px;
  top: -13px;
  font-family: Montserrat;
`;

const WeatherPoweredBy = styled.div`
  font-size: 12px;
  color: ${mfColors.grey};
  font-family: Montserrat;
  margin-right: 25px;
`;

interface IProps {
  farm: IFarm | null;
  metricType?: EMeasurementSystem;
}

const getIcon = (iconCode: number) => {
  const iconMap = {
    0: icons.faTornado,
    3: icons.faCloudBolt,
    4: icons.faCloudBolt,
    5: icons.faCloudHailMixed,
    6: icons.faCloudSleet,
    7: icons.faCloudHailMixed,
    8: icons.faCloudHailMixed,
    9: icons.faCloudRain,
    10: icons.faCloudHailMixed,
    11: icons.faCloudRain,
    12: icons.faCloudRain,
    13: icons.faCloudSnow,
    14: icons.faCloudSnow,
    15: icons.faSnowBlowing,
    16: icons.faCloudSnow,
    17: icons.faCloudHail,
    18: icons.faCloudSleet,
    19: icons.faSmog,
    20: icons.faSmog,
    21: icons.faSmog,
    22: icons.faSmog,
    23: icons.faWind,
    24: icons.faWind,
    25: icons.faSnowBlowing,
    26: icons.faCloud,
    27: icons.faClouds,
    28: icons.faCloudSun,
    29: icons.faCloudMoon,
    30: icons.faCloudSun,
    31: icons.faMoon,
    32: icons.faSun,
    33: icons.faMoonCloud,
    34: icons.faSunCloud,
    35: icons.faCloudHailMixed,
    36: icons.faSun,
    37: icons.faCloudBoltSun,
    38: icons.faCloudBoltSun,
    39: icons.faCloudRain,
    40: icons.faCloudShowersHeavy,
    41: icons.faCloudSnow,
    42: icons.faSnowflakes,
    43: icons.faSnowflakes,
    44: icons.faTemperatureHalf,
    45: icons.faCloudMoonRain,
    46: icons.faCloudSnow,
    47: icons.faCloudBoltMoon
  };

  return iconMap[iconCode] || icons.faTemperatureHalf;
};

const WeatherPopup = ({ farm, metricType }: IProps): JSX.Element => {
  const { t } = useTranslation();
  const { logEvent } = useAnalytics();
  const user = signInHooks.useSignedInUser();
  const weatherRef = useRef<HTMLDivElement>(null);
  const [currentWeather, setCurrentWeather] = useState<IWeather | null>(null);
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const { selectedFarm, selectedGrove } = farmsHooks.useSelectedFarmEntities();
  const { weather } = farmsHooks.useGetFarmWeather(selectedFarm?.id, selectedGrove?.id);

  useEffect(() => {
    setCurrentWeather(weather.length ? weather[0] : null);
  }, [weather]);

  useEffect(() => {
    if (isExpanded && farm && user) {
      logEvent({
        type: EEventType.OpenWeatherWidget,
        params: {
          email: user.email,
          farmName: farm.name
        }
      });
    }
  }, [user, farm, isExpanded, logEvent]);

  const handleClickOutside = useCallback(
    (e: MouseEvent) => {
      if (weatherRef.current && e.target && !weatherRef.current.contains(e.target as HTMLElement)) {
        setIsExpanded(false);
      }
    },
    [setIsExpanded, weatherRef]
  );

  useEffect(() => {
    if (isExpanded) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isExpanded, handleClickOutside]);

  if (!currentWeather) {
    return <></>;
  }
  return (
    <Wrapper isExpanded={isExpanded} ref={weatherRef}>
      <WeatherPreview isExpanded={isExpanded} onClick={() => setIsExpanded(true)}>
        <WeatherDetailsIconWrapper isExpanded={isExpanded}>
          <FontAwesomeIcon icon={getIcon(currentWeather.iconCode)} />
        </WeatherDetailsIconWrapper>
        <WeatherDetailsTemperature isExpanded={isExpanded}>
          <span>{`${numberUtils.normalizeDecimal(currentWeather.temperatureMax, 0)}° / ${numberUtils.normalizeDecimal(currentWeather.temperatureMin, 0)}°`}</span>
        </WeatherDetailsTemperature>
      </WeatherPreview>
      <WeatherDetails>
        <CloseIconCard isExpanded={isExpanded}>
          <FontAwesomeIcon icon={icons.faArrowUpRight} size="lg" onClick={() => setIsExpanded(false)} />
        </CloseIconCard>
        <WeatherDetailsInfo>
          <WeatherDetailsRow>
            <WeatherDetailsRowLabel>
              <WindIcon />
              <span>{t('main.weather.wind_speed')}</span>
            </WeatherDetailsRowLabel>
            <WeatherDetailsRowValue>{numberUtils.formatSpeed(currentWeather.windSpeed, metricType)}</WeatherDetailsRowValue>
          </WeatherDetailsRow>
          <WeatherDetailsRow>
            <WeatherDetailsRowLabel>
              <PrecipationIcon />
              <span>{t('main.weather.24hr_precipitation')}</span>
            </WeatherDetailsRowLabel>
            <WeatherDetailsRowValue>{`${numberUtils.normalizeDecimal(currentWeather.precip24Hour, 0)}%`}</WeatherDetailsRowValue>
          </WeatherDetailsRow>
          <WeatherDetailsRow>
            <WeatherDetailsRowLabel>
              <HumidityIcon />
              <span>{t('main.weather.relative_humidity')}</span>
            </WeatherDetailsRowLabel>
            <WeatherDetailsRowValue>{`${numberUtils.normalizeDecimal(currentWeather.relativeHumidity, 0)}%`}</WeatherDetailsRowValue>
          </WeatherDetailsRow>
          <WeatherDetailsRow>
            <WeatherDetailsRowLabel>
              <ETOIcon />
              <span>{t('main.weather.daily_et0')}</span>
            </WeatherDetailsRowLabel>
            <WeatherDetailsRowValue>{numberUtils.getETByRegion(currentWeather.evapotranspirationRef, metricType)}</WeatherDetailsRowValue>
          </WeatherDetailsRow>
          <WeatherDetailsRow>
            <WeatherDetailsRowLabel>
              <FontAwesomeIcon icon={icons.faHeat} height={20} width={20} />
              <span>{t('main.weather.soil_moisture')}</span>
            </WeatherDetailsRowLabel>
            <WeatherDetailsRowValue>{`${numberUtils.normalizeDecimal(numberUtils.convertToPercentage(currentWeather.soilMoisture), 2)}%`}</WeatherDetailsRowValue>
          </WeatherDetailsRow>
          <WeatherDetailsRow>
            <WeatherDetailsRowLabel>
              <FontAwesomeIcon icon={icons.faTemperatureList} height={20} width={20} />
              <span>{t('main.weather.soil_temperature')}</span>
            </WeatherDetailsRowLabel>
            <WeatherDetailsRowValue>{`${numberUtils.normalizeDecimal(currentWeather.soilTemperature, 2)}°`}</WeatherDetailsRowValue>
          </WeatherDetailsRow>
        </WeatherDetailsInfo>
        <WeatherDetailsSelector>
          {weather.map((weatherEntry) => (
            <WeatherDetailsCard
              key={weatherEntry.date}
              selected={weatherEntry === currentWeather}
              onClick={(e) => {
                e.stopPropagation();
                setCurrentWeather(weatherEntry);
              }}
            >
              <WeatherDetailsCardLegend>{dateUtils.formatDayOfWeek(weatherEntry.date)}</WeatherDetailsCardLegend>
              <WeatherDetailsCardIcon icon={getIcon(weatherEntry.iconCode)} />
              <WeatherDetailsCardTemperature>
                {`${numberUtils.normalizeDecimal(weatherEntry.temperatureMin, 0)}° / ${numberUtils.normalizeDecimal(weatherEntry.temperatureMax, 0)}°`}
              </WeatherDetailsCardTemperature>
            </WeatherDetailsCard>
          ))}
        </WeatherDetailsSelector>
        <WeatherPoweredBy>{t('Powered by IBM')}</WeatherPoweredBy>
      </WeatherDetails>
    </Wrapper>
  );
};

export default WeatherPopup;
