import appConfig from 'config/config.json';
import { pointToTile } from '@mapbox/tilebelt';
import { VectorTile } from '@mapbox/vector-tile';

import Protobuf from 'pbf';
import { Position } from '@turf/turf';
import api from 'services/data/providers/api';

const tilesAPIService = appConfig.ImageTilesURL || 'https://image-tiles.seetree.ai';

const getImageTilesAccessLink = ({ farmID, missionID, imageID }) => `${tilesAPIService}/images/${farmID}/${missionID}/${imageID}`;
const prepareImageTiles = ({ farmID, missionID, imageID }: { farmID: string; missionID: string; imageID: string }) => {
  if (!farmID || !missionID || !imageID) {
    return Promise.resolve();
  }
  const url = `${tilesAPIService}/prepare/${farmID}/${missionID}/${imageID}`;

  return fetch(url, { method: 'POST', credentials: 'include' }).catch(() => Promise.resolve());
};

const convertCoordsToTilePos = (lngLat: Position, zoom: number) => {
  const [x, y, z] = pointToTile(lngLat[0], lngLat[1], zoom);
  return { x, y, z };
};

const getAdjacentTiles = (tile) => {
  const { x, y, z } = tile; // center tile
  return [
    { x: x - 1, y: y - 1, z }, // top-left
    { x: x - 1, y: y - 2, z }, // top-left
    { x: x - 2, y: y - 1, z }, // top-left
    { x, y: y - 1, z }, // top
    { x: x + 1, y: y - 1, z }, // top-right
    { x: x + 1, y, z }, // right
    { x: x + 2, y, z }, // right
    { x: x + 1, y: y + 1, z }, // right-bottom
    { x: x + 1, y: y + 2, z }, // right-bottom
    { x, y: y + 1, z }, // bottom
    { x, y: y + 2, z }, // bottom
    { x: x - 1, y: y + 1, z }, // bottom-left
    { x: x - 1, y, z }, // left
    { x: x - 2, y, z } // left
  ];
};
const loadTileData = async (tileAccessLink: string, tile, layer = 'original') => {
  const { x, y, z } = tile;
  const resp = await fetch(`${tileAccessLink}/${z}/${x}/${y}.pbf`);
  if (!resp.ok) return { type: 'FeatureCollection', features: [] };

  const tileBuffer = await resp.arrayBuffer();
  return readTile({ z, x, y, layer }, tileBuffer);
};

const readTile = (args: { layer: string; x: number; y: number; z: number }, buffer) => {
  const tile = new VectorTile(new Protobuf(buffer));
  const layers = [args.layer] || Object.keys(tile.layers);

  const collection = { type: 'FeatureCollection', features: [] as any[] };

  layers.forEach((layerID) => {
    const layer = tile.layers[layerID];
    if (!layer) return;
    for (let i = 0; i < layer.length; i += 1) {
      const feature = layer.feature(i).toGeoJSON(args.x, args.y, args.z);
      if (layers.length > 1 && feature.properties) feature.properties.vt_layer = layerID;
      collection.features.push(feature);
    }
  });

  return collection;
};

const triggerArchiveMission = async (farmID: string, surveyID: string, groveIDs: string[]) => {
  const body = {
    farmID,
    surveyID,
    groveIDs
  };

  return api.post('/triggerArchiveCreation', body).catch((err) => err);
};

const vectilesService = {
  convertCoordsToTilePos,
  getAdjacentTiles,
  loadTileData,
  getImageTilesAccessLink,
  prepareImageTiles,
  triggerArchiveMission
};

export default vectilesService;
