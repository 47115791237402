import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from 'redux/store';
import { EMeasurementSystem, IRegion } from 'models/region';
import { getSelectedFarm } from 'redux/farm/farmSlice';

interface IRegionState {
  regions: IRegion[];
}

const slice = createSlice({
  name: 'region',
  initialState: {
    regions: []
  } as IRegionState,
  reducers: {
    setRegions: (state, action: PayloadAction<IRegion[]>) => ({ ...state, regions: action.payload })
  }
});

export const regionReducer = slice.reducer;
export const { setRegions } = slice.actions;

export const regionsSelector = (state: RootState) => state.region.regions;
export const regionSelector = createSelector([regionsSelector, getSelectedFarm], (regions, farm): IRegion | null => {
  if (!farm) {
    return null;
  }

  return regions?.find((region) => region.id === farm.region) || null;
});
export const regionMeasurementSystemSelector = createSelector(regionSelector, (region) => region?.measurementSystem);
export const regionMeasurementNameSelector = createSelector(regionMeasurementSystemSelector, (metricType) => (metricType === EMeasurementSystem.Metric ? 'm' : 'ft'));
