import React, { useMemo, useCallback } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import DataTable from 'atomicComponents/DataTable';
import MissionIcon from 'components/missions/MissionIcon';

import missionUtils from 'utils/mission';
import numberUtils from 'utils/numbers';
import missionHooks from 'hooks/mission.hooks';
import farmsHooks from 'hooks/farms.hooks';

import { IMission } from 'models/mission';
import { IGrove } from 'models/grove';
import { IFeed, EHLBStatus, HLBSeverityMap, HLB_INFECTED_STATUSES } from 'models/feed';

import { mfColors } from 'vars';

const Wrapper = styled.div``;

const TableWrapper = styled.div`
  max-height: 320px;
  overflow: auto;
  width: 780px;
`;

const TableCell = styled.div`
  background: ${mfColors.white};
  padding: 4px;
  min-height: 32px;
  font-size: 12px;
  line-height: 24px;
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;

  &.left {
    text-align: left;
  }
`;

interface ITotalProps {
  width: string;
}

const Total = styled.div`
  font-family: MontserratBold;
  font-size: 14px;
  padding: 8px 0;
  margin: 8px 0 0 36px;
  width: ${({ width }: ITotalProps) => width};
  border-bottom: 2px solid ${mfColors.darkGrey};
  display: flex;
  flex-direction: row;

  .column {
    width: 176px;
    margin: 0 4px 0 0;
    flex-shrink: 0;
    text-align: center;
  }

  .title {
    padding: 0 4px;
    text-align: left;
    text-transform: uppercase;
  }
`;

const ROW_STYLE = {
  border: 'none',
  background: 'none',
  margin: '0 0 4px'
};

const HEADER_STYLE = {
  border: 'none',
  background: 'none',
  fontSize: '12px',
  margin: '0 0 8px'
};

const HEADER_COLUMN_STYLE = {
  textTransform: 'none',
  minHeight: '0px',
  padding: '0 2px'
};

interface IGroveWithTrees extends IGrove {
  total: number;
  infected: IFeed[];
}

interface IProps {
  missions: IMission[];
  feed: IFeed[];
}

const HLBHeaderDetails = ({ missions, feed }: IProps) => {
  const { t } = useTranslation();
  const feedByGrove = missionHooks.useFeedByGrove(feed);
  const { groves, selectedSurveyStats: surveyStats, grovesSurveysStats } = farmsHooks.useFarmEntities();

  const getTotalTreesCount = useCallback(
    (grove: IGrove): number => {
      if (missions.length > 1) return missionUtils.getTreesSum([grove], surveyStats, grovesSurveysStats);
      return missions.reduce((acc: number, mission) => {
        const missionFeed = feedByGrove[grove.id]?.filter((entry) => entry.missionID === mission.id) || [];
        const result = mission.isPreassigned ? missionFeed.length : missionUtils.getTreesSum([grove], surveyStats, grovesSurveysStats);
        return acc + result;
      }, 0);
    },
    [missions, surveyStats, grovesSurveysStats, feedByGrove]
  );

  const getInfectedTrees = useCallback(
    (grove: IGrove) => {
      const missionFeed = feedByGrove[grove.id]?.filter((entry) => HLB_INFECTED_STATUSES.includes((entry.data?.hlbStatus || entry.hlbStatus) as EHLBStatus)) || [];
      return missionFeed;
    },
    [feedByGrove]
  );

  const grovesToShow = useMemo(() => {
    const groveIDs = missions.reduce((acc: string[], mission) => {
      const missionGroveIDs = Object.keys(mission.groves);
      return [...acc, ...missionGroveIDs.filter((groveID) => !acc.includes(groveID))];
    }, []);
    return groveIDs
      .map((groveID) => {
        const grove = groves.find((grove) => grove.id === groveID);
        if (!grove) return null;

        return {
          ...grove,
          infected: getInfectedTrees(grove),
          total: getTotalTreesCount(grove)
        };
      })
      .filter((grove) => !!grove) as IGroveWithTrees[];
  }, [groves, missions, getInfectedTrees, getTotalTreesCount]);

  const totalTreesCount = useMemo(() => grovesToShow.reduce((acc: number, grove: IGroveWithTrees) => acc + grove.total, 0), [grovesToShow]);

  const totalInfectedTreesCount = useMemo(() => grovesToShow.reduce((acc: number, grove: IGroveWithTrees) => acc + grove.infected.length, 0), [grovesToShow]);

  const totalTreesCounts = useMemo(() => `${totalInfectedTreesCount.toLocaleString()} / ${totalTreesCount.toLocaleString()}`, [totalInfectedTreesCount, totalTreesCount]);

  const averageInfectionRate = useMemo(
    () => `${numberUtils.normalizeDecimal(numberUtils.convertToPercentage(totalInfectedTreesCount / totalTreesCount), 2)}%`,
    [totalInfectedTreesCount, totalTreesCount]
  );

  const averageSeverity = useMemo(() => {
    const infectedTrees = grovesToShow.reduce((acc: IFeed[], grove: IGrove) => [...acc, ...getInfectedTrees(grove)], []);
    const totalSeverity = missionUtils.getTotalSeverity(infectedTrees);

    return numberUtils.normalizeDecimal(totalSeverity / infectedTrees.length, 1);
  }, [getInfectedTrees, grovesToShow]);

  const tableConfig = useMemo(() => {
    const iconColumn = {
      title: '',
      render: () => (
        <TableCell>
          <MissionIcon type={missions[0]?.type} size="24px" showBackground={false} />
        </TableCell>
      ),
      key: 'icon',
      sortable: false,
      width: '32px',
      style: {
        ...HEADER_COLUMN_STYLE,
        padding: '0px',
        margin: '0 2px 0 0'
      }
    };

    const titleColumn = {
      title: t('missions.block'),
      render: (grove: IGrove) => <TableCell className="left">{grove.name}</TableCell>,
      key: 'name',
      sortable: false,
      width: '180px',
      style: HEADER_COLUMN_STYLE
    };

    const infectedTreesColumn = {
      title: t('missions.infected_trees_scouted_trees'),
      render: (grove: IGroveWithTrees) => <TableCell>{`${grove.infected.length.toLocaleString()} / ${grove.total.toLocaleString()}`}</TableCell>,
      key: 'infected_trees',
      sortable: false,
      width: '180px',
      style: {
        ...HEADER_COLUMN_STYLE,
        textAlign: 'center'
      }
    };

    const infectionRateColumn = {
      title: t('missions.infection_rate'),
      render: (grove: IGroveWithTrees) => <TableCell>{`${numberUtils.normalizeDecimal(numberUtils.convertToPercentage(grove.infected.length / grove.total), 2)}%`}</TableCell>,
      key: 'infection_rate',
      sortable: false,
      width: '180px',
      style: HEADER_COLUMN_STYLE
    };

    const avgSeverityColumn = {
      title: t('missions.avg_severity_levels'),
      render: (grove: IGroveWithTrees) => {
        const totalSeverity = grove.infected.reduce((acc: number, entry: IFeed) => {
          if (!entry.data?.hlbStatus || !HLB_INFECTED_STATUSES.includes(entry.data?.hlbStatus)) return acc;
          return acc + (HLBSeverityMap.get(entry.data?.hlbStatus) || 0);
        }, 0);

        return <TableCell>{numberUtils.normalizeDecimal(totalSeverity / grove.infected.length, 1)}</TableCell>;
      },
      key: 'severity',
      sortable: false,
      width: '180px',
      style: HEADER_COLUMN_STYLE
    };

    return [iconColumn, titleColumn, infectedTreesColumn, infectionRateColumn, avgSeverityColumn];
  }, [t, missions]);

  const tableProps = useMemo(() => {
    const props = {
      config: tableConfig,
      data: grovesToShow,
      showHeader: true,
      showFooter: false,
      rowsPerPage: 0,
      headerStyle: HEADER_STYLE,
      rowStyle: ROW_STYLE
    };
    return props;
  }, [tableConfig, grovesToShow]);

  return (
    <Wrapper>
      <TableWrapper>
        <DataTable<IGroveWithTrees> {...tableProps} />
      </TableWrapper>
      <Total width="712px">
        <div className="column title">{t('shared.total')}</div>
        <div className="column">{totalTreesCounts}</div>
        <div className="column">{averageInfectionRate}</div>
        <div className="column">{averageSeverity}</div>
      </Total>
    </Wrapper>
  );
};

export default HLBHeaderDetails;
