import { IUnifiedHotspot } from 'models/hotspot';
import { ERichItemType } from 'models/richItem';

export enum EReportType {
  FreeArea = 'freeArea',
  Height = 'height',
  Cipo = 'cipo',
  Weeds = 'weeds',
  DedicatedVisit = 'dedicatedVisit',
  TargetedScouting = 'targetedScouting',
  Replant = 'replant'
}

export const DEFAULT_REPORT_TRESHOLDS = {
  [EReportType.Cipo]: 0.07,
  [EReportType.FreeArea]: 2,
  [EReportType.Height]: 5,
  [EReportType.Weeds]: 0.15,
  [EReportType.DedicatedVisit]: 0.1,
  [EReportType.Replant]: [10, 0.1]
};

export const REPORT_PRIORITY = [
  EReportType.TargetedScouting,
  EReportType.DedicatedVisit,
  EReportType.Cipo,
  EReportType.Weeds,
  EReportType.Replant,
  EReportType.Height,
  EReportType.FreeArea
];

export const REPORT_IMPORTANCE_TRESHOLDS: { [key: string]: number } = {
  [EReportType.Cipo]: 0.05,
  [EReportType.FreeArea]: 0.2,
  [EReportType.Height]: 0.2,
  [EReportType.Weeds]: 0.2,
  [EReportType.Replant]: 0.05
};

export const supportedReportsTypes = [EReportType.DedicatedVisit, EReportType.TargetedScouting, EReportType.FreeArea, EReportType.Height, EReportType.Weeds, EReportType.Cipo];

/* eslint-disable camelcase */

export interface IScoreDifferenceReport {
  farmID: string;
  groveID: string;
  groveName: string;
  underperforming_difference: number;
  zoneID: string;
  status?: string;
}

export interface IFreeAreaReport {
  farmID: string;
  freeArea_avg: string;
  groveID: string;
  surveyID: string;
  status?: string;
}

export interface IHeightReport {
  Height: string;
  Volume: string;
  canopyArea: string;
  farmID: string;
  groveID: string;
  surveyID: string;
  status?: string;
}

export interface ICipoReport {
  Cipo_no_cnt: number;
  Cipo_yes_cnt: number;
  cipo_rate: number;
  farmID: string;
  groveID: string;
  surveyID: string;
  status?: string;
}

export interface IWeedsReport {
  farmID: string;
  groveID: string;
  surveyID: string;
  timestamp: {
    value: string;
  };
  _0_30cm_count: string;
  _0_30cm_percentage: string;
  _30_60cm_count: string;
  _30_60cm_percentage: string;
  _60_90cm_count: string;
  _60_90cm_percentage: string;
  _90cm_above_count: string;
  _90cm_above_percentage: string;
  status?: string;
}
/* eslint-enable camelcase */

export interface IReplantReport {
  groveID: string;
  replants: number;
  status?: string;
}

export type TAnyReport = IScoreDifferenceReport | IUnifiedHotspot | IFreeAreaReport | IHeightReport | ICipoReport | IWeedsReport | IReplantReport;

export const reportRichItemsMap: Map<EReportType, ERichItemType> = new Map()
  .set(EReportType.Cipo, ERichItemType.Cipo)
  .set(EReportType.Weeds, ERichItemType.Weeds)
  .set(EReportType.Height, ERichItemType.Height)
  .set(EReportType.FreeArea, ERichItemType.FreeArea)
  .set(EReportType.DedicatedVisit, ERichItemType.Health)
  .set(EReportType.TargetedScouting, ERichItemType.Health);
