import React from 'react';
import styled from 'styled-components';
import { IGrove } from 'models/grove';
import { mfColors } from 'vars';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLocationArrow } from '@fortawesome/pro-solid-svg-icons';

const ContentItemTitle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 16px;
`;

interface IGroveTitleProps {
  isClickable: boolean;
}

const GroveTitle = styled.div`
  min-height: 32px;
  padding: 0 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-radius: 4px;
  position: relative;
  overflow: hidden;
  color: ${mfColors.lightBlue};
  font-size: 14px;
  line-height: 17px;
  font-family: MontserratBold;
  cursor: ${({ isClickable }: IGroveTitleProps) => (isClickable ? 'pointer' : 'default')};

  &:before {
    position: absolute;
    background: ${mfColors.lightBlue};
    opacity: 0.1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: '';
  }
  &:hover {
    opacity: 0.75;
  }
`;

const GroveIcon = styled(FontAwesomeIcon)`
  margin: 0 0 0 10px;
`;

interface IProps {
  isClickable: boolean;
  grove: IGrove;
  onGroveClicked: () => void;
}

const BulletinGrove = ({ isClickable, grove, onGroveClicked }: IProps) => (
  <ContentItemTitle>
    <GroveTitle isClickable={isClickable} onClick={onGroveClicked}>
      <span>{grove?.name}</span>
      {!!onGroveClicked && <GroveIcon icon={faLocationArrow} />}
    </GroveTitle>
  </ContentItemTitle>
);

export default BulletinGrove;
