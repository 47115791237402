import React from 'react';
import ReactDOM from 'react-dom';
import { store } from 'redux/store';
import { Provider as RollbarProvider, ErrorBoundary } from '@rollbar/react';
import { Normalize } from 'styled-normalize';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { ModalProvider } from 'react-modal-hook';
import App from 'App';
import GlobalStyle from 'GlobalStyle';

import appConfig from 'config/config.json';
import reportWebVitals from './reportWebVitals';

ReactDOM.render(
  <React.StrictMode>
    <RollbarProvider config={appConfig.rollbar}>
      <ErrorBoundary>
        <BrowserRouter>
          <Provider store={store}>
            <Normalize />
            <GlobalStyle />
            <ModalProvider>
              <App />
            </ModalProvider>
          </Provider>
        </BrowserRouter>
      </ErrorBoundary>
    </RollbarProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
