import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';
import { mfSizes } from 'vars';

const Wrapper = styled.div<{ fullSize: boolean }>`
  position: fixed;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: 5;
  ${({ fullSize }) => `left: ${fullSize ? mfSizes.staticMenuWidth : 0};`}
`;

type TProps = PropsWithChildren<{
  fullSize: boolean;
}>;

const Overlay = ({ children, fullSize }: TProps) => (
  <>
    <Wrapper fullSize={fullSize}>{children}</Wrapper>
  </>
);

export default Overlay;
