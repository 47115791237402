/* eslint-disable camelcase */
import React, { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import DataTable from 'atomicComponents/DataTable';
import { mfColors } from 'vars';
import { useTranslation } from 'react-i18next';

import { TMappedStatistic } from 'models/statistic';
import replantUtils from 'utils/replant';

import { ReactComponent as NoReplants } from 'assets/images/trees_0.svg';
import { ReactComponent as OneReplant } from 'assets/images/trees_1.svg';
import { ReactComponent as TwoReplants } from 'assets/images/trees_2.svg';
import { ReactComponent as ThreeReplants } from 'assets/images/trees_3.svg';
import { ReactComponent as SlotReplant } from 'assets/images/trees_slot.svg';
import FormInput from 'atomicComponents/FormInput';
import { IReplantRules } from 'models/replant';
import { useSelector } from 'react-redux';
import { regionMeasurementNameSelector } from 'redux/region/regionSlice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleMinus } from '@fortawesome/pro-light-svg-icons';
import SlotAddForm from './SlotAddForm';

const TableWrapper = styled.div`
  max-height: 350px;
  overflow: auto;
`;

const TableCell = styled.div`
  padding: 4px;
  min-height: 32px;
  font-size: 12px;
  line-height: 24px;
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;

  &.left {
    text-align: left;
  }

  &.right {
    text-align: right;
  }
`;

const InputCell = styled.div`
  padding: 4px;
  min-height: 32px;
  font-size: 12px;
  line-height: 24px;
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;

  &.left {
    text-align: left;
  }

  &.right {
    text-align: right;
  }
`;

const ErrorMessage = styled.span`
  color: ${mfColors.red};
`;

const MeasurementType = styled.span`
  margin-left: 5px;
`;

const ROW_STYLE = {
  border: 'none',
  background: `${mfColors.superLightGrey}`,
  borderBottom: `2px solid ${mfColors.superLightBlueBg}`
};

const HEADER_STYLE = {
  borderTop: 'none',
  background: 'none',
  fontSize: '12px'
};

const HEADER_COLUMN_STYLE = {
  textTransform: 'none',
  minHeight: '0px',
  padding: '0 2px'
};

const EditHeadline = styled.div`
  font-family: MontserratBold;
  font-size: 16px;
  text-align: right;
  margin-top: 30px;
  margin-bottom: 30px;
  margin-right: 16px;
  color: ${mfColors.lightBlue};
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

interface IReplantRuleOption {
  id: string;
  icon: ReactElement;
  label: string | JSX.Element;
  isRequired: boolean;
  index: number;
  value: number;
  slots?: number;
  replants?: number;
  error?: string;
}

const ReplantLabelWrapper = styled.div`
  font-size: 14px;

  .slotCount {
    margin-left: 4px;
    text-decoration: underline;
  }
  .treesCount {
    font-family: MontserratBold;
    margin-right: 4px;
  }
`;

const REPLANT_RULES = [
  '_0_replants_in_segment_up_to_length',
  '_1_replant_in_segment_up_to_length',
  '_2_replants_in_segment_up_to_length',
  '_3_replants_in_segment_up_to_length',
  '_5_or_more_replants_spacing_num'
];

interface IReplantLabelProps {
  treesCount?: string;
  slotText: string;
  value?: number;
  postText?: string;
}

const ReplantLabel = ({ treesCount, slotText, value, postText }: IReplantLabelProps) => (
  <ReplantLabelWrapper>
    {treesCount && <span className="treesCount">{treesCount}</span>}
    {slotText}
    {value && (
      <FormInput
        name="spacing"
        value={`${value || ''}`}
        inputWidth="49px"
        textPosition="center"
        wrapperStyle={{ display: 'inline-block', marginLeft: 8 }}
        isDisabled
        background={mfColors.lightGrey}
      />
    )}
    {postText && <span style={{ marginLeft: 6 }}>{postText}</span>}
  </ReplantLabelWrapper>
);

const getSelectedIndexes = (rules: IReplantRules) => {
  const indexes: number[] = [];
  REPLANT_RULES.forEach((rule, idx) => {
    if (rules[rule]) {
      indexes.push(idx);
    }
  });

  return indexes;
};

interface IProps {
  statistic: TMappedStatistic | null;
  replantRules: IReplantRules;
  onUpdateReplantRules: (changedRules: IReplantRules) => void;
  controls: {
    saveControl: React.ReactNode;
    resetControl?: React.ReactNode;
  };
}

const EditCalculator = ({ statistic, replantRules, onUpdateReplantRules, controls }: IProps) => {
  const { t } = useTranslation();
  const [stateRules, setStateRules] = useState({} as IReplantRules);
  const measureName = useSelector(regionMeasurementNameSelector);
  const [optionsIndexes, setOptionsIndexes] = useState(getSelectedIndexes(replantRules));

  const lastIndex = useMemo(() => 4, []);
  const isLastOptionVisible = useMemo(() => optionsIndexes.includes(lastIndex), [optionsIndexes, lastIndex]);
  const indexedReplants = useMemo(() => {
    const { _0_replants_in_segment_up_to_length, _1_replant_in_segment_up_to_length, _2_replants_in_segment_up_to_length, _3_replants_in_segment_up_to_length } = stateRules;

    return [_0_replants_in_segment_up_to_length, _1_replant_in_segment_up_to_length, _2_replants_in_segment_up_to_length, _3_replants_in_segment_up_to_length];
  }, [stateRules]);
  const lastDefinedRule = useMemo(() => {
    const indexToReplants = optionsIndexes.filter((item) => item !== lastIndex).length - 1;
    const valueByIndex = indexedReplants[indexToReplants];

    if (valueByIndex) {
      return valueByIndex;
    }

    const validItems = indexedReplants.filter((item) => item);

    return validItems[validItems.length - 1];
  }, [indexedReplants, optionsIndexes, lastIndex]);

  useEffect(() => {
    if (Object.keys(stateRules).length) return;

    setStateRules(replantRules);
  }, [replantRules, stateRules]);

  const totalSegments = useMemo(() => {
    if (!statistic) return null;

    return replantUtils.getReplantSegments(statistic);
  }, [statistic]);

  const replantsCount = useMemo(() => {
    if (!statistic) return null;

    return replantUtils.getReplantsCount(statistic);
  }, [statistic]);

  const isValidRule = (val: number | string, prevVal: number | string) => Number(val) > Number(prevVal);

  const replantRulesList: IReplantRuleOption[] = useMemo(() => {
    const {
      _0_replants_in_segment_up_to_length,
      _1_replant_in_segment_up_to_length,
      _2_replants_in_segment_up_to_length,
      _3_replants_in_segment_up_to_length,
      _5_or_more_replants_spacing_num
    } = stateRules;

    const rulesItems = [
      {
        id: '_0_replants_in_segment_up_to_length',
        icon: <NoReplants width={55} />,
        label: <ReplantLabel treesCount={t('shared.no')} slotText={t('replant.calculator.empty_slot')} />,
        isRequired: false,
        index: 0,
        value: _0_replants_in_segment_up_to_length,
        slots: totalSegments?._0_segments,
        replants: replantsCount?._0_replants
      },
      {
        id: '_1_replant_in_segment_up_to_length',
        icon: <OneReplant width={76} />,
        label: <ReplantLabel treesCount={'1'} slotText={t('replant.calculator.slot_tree')} value={_0_replants_in_segment_up_to_length} postText={t('shared.to')} />,
        isRequired: false,
        index: 1,
        value: _1_replant_in_segment_up_to_length,
        slots: totalSegments?._1_segment,
        replants: replantsCount?._1_replant,
        error: isValidRule(_1_replant_in_segment_up_to_length, _0_replants_in_segment_up_to_length) ? '' : t('shared.wrong_value')
      },
      {
        id: '_2_replants_in_segment_up_to_length',
        icon: <TwoReplants width={98} />,
        label: <ReplantLabel treesCount={'2'} slotText={t('replant.calculator.slot_trees')} value={_1_replant_in_segment_up_to_length} postText={t('shared.to')} />,
        isRequired: false,
        index: 2,
        value: _2_replants_in_segment_up_to_length,
        slots: totalSegments?._2_segments,
        replants: replantsCount?._2_replants,
        error: isValidRule(_2_replants_in_segment_up_to_length, _1_replant_in_segment_up_to_length) ? '' : t('shared.wrong_value')
      },
      {
        id: '_3_replants_in_segment_up_to_length',
        icon: <ThreeReplants width={120} />,
        label: <ReplantLabel treesCount={'3'} slotText={t('replant.calculator.slot_trees')} value={_2_replants_in_segment_up_to_length} postText={t('shared.to')} />,
        isRequired: false,
        index: 3,
        value: _3_replants_in_segment_up_to_length,
        slots: totalSegments?._3_segments,
        replants: replantsCount?._3_replants,
        error: isValidRule(_3_replants_in_segment_up_to_length, _2_replants_in_segment_up_to_length) ? '' : t('shared.wrong_value')
      },
      {
        id: '_5_or_more_replants_spacing_num',
        icon: <SlotReplant width={164} />,
        label: <ReplantLabel slotText={t('replant.calculator.slot_max')} value={lastDefinedRule} postText={`${measureName}, ${t('replant.calculator.plant_every')}`} />,
        isRequired: false,
        index: 4,
        value: _5_or_more_replants_spacing_num,
        slots: totalSegments?._5_or_more,
        replants: replantsCount?._5_or_more
      }
    ];

    return rulesItems;
  }, [t, measureName, stateRules, totalSegments, replantsCount, lastDefinedRule]);

  const handleChangeReplantRule = useCallback(
    (id, val: string) => {
      const num = Number(val);
      const maxNum = measureName === 'm' ? 100 : 330;

      if (num >= 0 && num <= maxNum) {
        setStateRules((prev) => ({ ...prev, [id]: val }));
      }
    },
    [measureName]
  );

  const hanleBack = useCallback(() => {
    setOptionsIndexes((prev) => {
      if (prev.length === 1) {
        return prev;
      }

      return prev.slice(0, -1);
    });
  }, []);

  const tableConfig = useMemo(
    () => [
      {
        title: '',
        render: (replantRule: IReplantRuleOption) => <InputCell className="left">{replantRule.icon}</InputCell>,
        key: 'icon',
        sortable: false,
        width: '180px',
        style: {
          ...HEADER_COLUMN_STYLE,
          textAlign: 'right'
        }
      },
      {
        title: '',
        render: (replantRule: IReplantRuleOption) => <TableCell className="right">{replantRule.label}</TableCell>,
        key: 'label',
        sortable: false,
        width: '320px',
        style: {
          ...HEADER_COLUMN_STYLE,
          textAlign: 'right'
        }
      },
      {
        title: '',
        render: (replantRule: IReplantRuleOption) => (
          <InputCell>
            <FormInput
              onChange={(val) => handleChangeReplantRule(replantRule.id, val)}
              name="spacing"
              value={`${replantRule.value || ''}`}
              inputWidth="68px"
              textPosition="center"
              autocomplete="off"
            />
            <MeasurementType>{measureName}</MeasurementType>
          </InputCell>
        ),
        key: 'id',
        sortable: false,
        width: '80px',
        style: {
          ...HEADER_COLUMN_STYLE,
          textAlign: 'center'
        }
      },
      {
        title: '',
        render: (replantRule: IReplantRuleOption) => {
          const lastOptionIndex = optionsIndexes[optionsIndexes.length - 1];
          const isVisible = replantRule.index && lastOptionIndex === replantRule.index;

          return <TableCell>{!!isVisible && <FontAwesomeIcon onClick={hanleBack} icon={faCircleMinus} size={'2x'} color={mfColors.lightBlue} />}</TableCell>;
        },
        key: 'id',
        sortable: false,
        width: '100px',
        style: HEADER_COLUMN_STYLE
      },
      {
        title: '',
        render: (replantRule: IReplantRuleOption) => <ErrorMessage>{replantRule.error}</ErrorMessage>,
        key: 'id',
        sortable: false,
        width: '80px',
        style: {
          ...HEADER_COLUMN_STYLE,
          textAlign: 'center'
        }
      }
    ],
    [measureName, hanleBack, handleChangeReplantRule, optionsIndexes]
  );

  const tableProps = useMemo(() => {
    const props = {
      config: tableConfig,
      data: optionsIndexes.map((idx) => replantRulesList[idx]),
      showHeader: true,
      showFooter: false,
      rowsPerPage: 0,
      headerStyle: HEADER_STYLE,
      rowStyle: ROW_STYLE
    };
    return props;
  }, [tableConfig, replantRulesList, optionsIndexes]);

  const handleUpdateReplantRules = () => {
    const replantRulesKeys = REPLANT_RULES.filter((_, idx) => optionsIndexes.includes(idx));
    const changedRules = replantRulesKeys.reduce((acc, next) => {
      if (!stateRules[next]) {
        return acc;
      }

      return { ...acc, [next]: Number(stateRules[next]) };
    }, {} as IReplantRules);
    const isError = replantRulesList.some((item) => item.error && replantRulesKeys.includes(item.id));

    if (isError) return;

    onUpdateReplantRules(changedRules);
  };

  return (
    <>
      <TableWrapper>
        <DataTable<IReplantRuleOption> {...tableProps} />
      </TableWrapper>
      {!isLastOptionVisible && (
        <SlotAddForm onAdd={(slotIndex) => setOptionsIndexes((prev) => prev.concat(slotIndex))} optionsLength={optionsIndexes.length} lastSlotCount={lastDefinedRule} />
      )}
      {isLastOptionVisible && (
        <EditHeadline>
          {controls.resetControl}
          <div style={{ marginLeft: 'auto' }} onClick={handleUpdateReplantRules}>
            {controls.saveControl}
          </div>
        </EditHeadline>
      )}
    </>
  );
};

export default EditCalculator;
