import { ISurveyWithTimeRange } from 'models/survey';
import { IUnifiedHotspot, EHotspotType } from 'models/hotspot';

import dateUtils from 'utils/date';

const filterSurveyHotspots = (surveys: ISurveyWithTimeRange[], satelliteHotspots: IUnifiedHotspot[], surveyID?: string) => {
  const selectedSurvey = surveys.find((survey) => survey.id === surveyID);
  if (!surveys.length || !selectedSurvey) return [];

  const surveyIndex = surveys.findIndex((survey) => survey.id === surveyID);
  const nextSurvey = surveyIndex > 0 ? surveys[surveyIndex - 1] : null;
  const startTimestamp = dateUtils.getMilliseconds(selectedSurvey.from);
  const endTimestamp = nextSurvey ? dateUtils.getMilliseconds(nextSurvey.from) : null;

  return satelliteHotspots.filter((anomaly) => {
    if (anomaly.type !== EHotspotType.Satellite || !anomaly.date) return false;
    const lastAcquiredDate = dateUtils.getMilliseconds(anomaly.date);
    return startTimestamp <= lastAcquiredDate && (!endTimestamp || endTimestamp >= lastAcquiredDate);
  });
};

const filterGroveHotspots = (satelliteHotspots: IUnifiedHotspot[], groveID?: string) => {
  if (!groveID) return satelliteHotspots;

  return satelliteHotspots.filter((hotspot) => hotspot.groveID === groveID);
};

const hotspotsUtils = {
  filterSurveyHotspots,
  filterGroveHotspots
};

export default hotspotsUtils;
