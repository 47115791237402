import React from 'react';
import styled from 'styled-components';
import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';

import Dropdown, { EToggleType, EPosition } from 'atomicComponents/Dropdown';
import Button from 'atomicComponents/Button';

import { mfColors } from 'vars';

interface ITitleProps {
  iconFirst: boolean;
}

const Icon = styled.div`
  display: inline;
`;

const Title = styled.div`
  display: flex;
  flex-direction: ${({ iconFirst }: ITitleProps) => (iconFirst ? 'row-reverse' : 'row')};
  align-items: center;
  justify-content: center;
  font-family: MontserratBold;
  font-size: 20px;
  text-align: center;
  color: ${mfColors.darkGrey};

  ${Icon} {
    margin: ${({ iconFirst }: ITitleProps) => (iconFirst ? '0 8px 0 0' : '0 0 0 8px')};
  }
`;

const Content = styled.div`
  margin: 16px 0 0;
  line-height: 40px;
  font-size: 16px;
  text-align: center;
`;

const Footer = styled.div`
  margin: 20px 0 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Cancel = styled.div`
  color: ${mfColors.lightBlue};
  margin: 8px 0 0;
  padding: 8px 0;
  font-family: MontserratBold;
  text-decoration: underline;
  cursor: pointer;
  font-size: 16px;
`;

const Arrow = styled.div`
  position: absolute;
  bottom: -24px;
  left: 12px;
  width: 0;
  height: 0;
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
  border-bottom: 12px solid transparent;
  border-top: 12px solid ${mfColors.white};
`;

const InfoDescription = styled.div`
  font-size: 14px;
  white-space: pre-line;
  color: ${mfColors.darkGrey};
`;

const InfoIcon = styled(FontAwesomeIcon)`
  font-size: 18px;
  margin: 0 0 0 4px;
  color: ${mfColors.grey};
  opacity: 0.7;
`;

const DROPDOWN_OFFSET = { x: -32, y: 16 };
const DROPDOWN_STYLE = {
  background: mfColors.white,
  boxShadow: `-5px -5px 10px ${mfColors.black40}, 5px 5px 10px ${mfColors.black40}`
};

export interface IEditFeedParams {
  title: string;
}

interface IProps {
  title: string;
  content?: string[];
  okText?: string;
  icon?: JSX.Element | null;
  iconFirst?: boolean;
  cancelText?: string;
  onOk?: () => void;
  onCancel?: () => void;
}

const BUTTON_STYLE = {
  borderRadius: '16px',
  height: '32px',
  fontFamily: 'MontserratBold',
  backgroundColor: mfColors.lightBlue,
  padding: '8px 24px'
};

const ResolveMissionModal = ({ onOk, onCancel, title, icon, content, okText, cancelText, iconFirst = false }: IProps) => {
  const { t } = useTranslation();

  return (
    <>
      <Title iconFirst={iconFirst}>
        {title}
        {icon && <Icon>{icon}</Icon>}
      </Title>
      {content && (
        <Content>
          {content.map((entry) => (
            <div>{entry}</div>
          ))}
        </Content>
      )}
      {(onOk || onCancel) && (
        <Footer>
          {onOk && okText && <Button style={BUTTON_STYLE} onClick={onOk} text={okText} />}
          {onCancel && cancelText && (
            <Cancel onClick={onCancel}>
              {cancelText}
              <Dropdown
                arrow={<Arrow />}
                style={DROPDOWN_STYLE}
                toggleType={EToggleType.Mouseover}
                control={<InfoIcon icon={faInfoCircle} />}
                offset={DROPDOWN_OFFSET}
                content={<InfoDescription>{t('missions.mark_all_groves_as_in_progress')}</InfoDescription>}
                position={EPosition.TopRight}
              />
            </Cancel>
          )}
        </Footer>
      )}
    </>
  );
};

export default ResolveMissionModal;
