import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/pro-solid-svg-icons';
import { mfColors } from '../vars';

const Wrapper = styled.div`
  position: fixed;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Icon = styled(FontAwesomeIcon)`
  color: ${mfColors.darkBlue};
`;

const GlobalLoader = () => (
  <Wrapper>
    <Icon icon={faSpinner} className="fa-spin" size="3x" />
  </Wrapper>
);

export default GlobalLoader;
