import db from 'services/data/providers/firebase';
import appConfig from 'config/config.json';

const getToken = (): string | null => (db.user ? db.user.idToken : null);

type THeaders = { [key: string]: string };

const getHeaders = (): THeaders => {
  const token = getToken();
  const headers = {
    'Content-Type': 'application/json'
  } as { [key: string]: string };
  if (token) {
    headers.Authorization = `Bearer ${token}`;
  }

  return headers;
};

const getApiUrl = (path: string, isApiUrl: boolean): string => (isApiUrl ? `${appConfig.backendUrl}${path}` : path);

const post = async <T>(path: string, body: { [key: string]: any }, isApiUrl = true, headers?: THeaders): Promise<T> => {
  const requestOptions = {
    method: 'POST',
    headers: headers || getHeaders(),
    body: JSON.stringify(body)
  };
  const response = await fetch(getApiUrl(path, isApiUrl), requestOptions);

  return response.ok ? response.json() : Promise.reject(response);
};

const put = async <T>(path: string, body: { [key: string]: any }, isApiUrl = true, headers?: THeaders): Promise<T> => {
  const requestOptions = {
    method: 'PUT',
    headers: headers || getHeaders(),
    body: JSON.stringify(body)
  };
  const response = await fetch(getApiUrl(path, isApiUrl), requestOptions);
  return response.json();
};

const get = async <T>(path: string, isApiUrl = true, headers?: THeaders): Promise<T> => {
  const requestOptions = {
    method: 'GET',
    headers: headers || getHeaders()
  };
  const response = await fetch(getApiUrl(path, isApiUrl), requestOptions);
  return response.json();
};

const httpDelete = async <T>(path: string, isApiUrl = true, headers?: THeaders): Promise<T> => {
  const requestOptions = {
    method: 'DELETE',
    headers: headers || getHeaders()
  };
  const response = await fetch(getApiUrl(path, isApiUrl), requestOptions);
  return response.json();
};

const api = {
  post,
  put,
  get,
  delete: httpDelete
};

export default api;
