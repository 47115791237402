import React, { useState, useRef, useEffect, useLayoutEffect, useCallback } from 'react';
import styled from 'styled-components';

import { ExpandAnimation } from 'atomicComponents/animations';
import { mfColors } from '../vars';

const DROPDOWN_CONTROL_OFFSET = 12;

const ControlWrapper = styled.span`
  cursor: pointer;
`;

interface IDropdownOffset {
  x?: number;
  y?: number;
}

interface IContentWrapperProps {
  isExpanded: boolean;
  position: EPosition;
  offset: IDropdownOffset;
}

export enum EToggleType {
  Click = 'click',
  Mouseover = 'mouseover'
}

export enum EPosition {
  Top = 'top',
  BottomLeft = 'bottomLeft',
  TopLeft = 'topLeft',
  BottomRight = 'bottomRight',
  Right = 'right',
  TopRight = 'topRight'
}

const ContentWrapper = styled.div`
  position: fixed;
  animation: ${ExpandAnimation} 300ms ease-in-out forwards;
  transform-origin: top center;
  background: ${mfColors.navBarBackground};
  box-shadow: -5px -5px 10px ${mfColors.cardShadowGrey}, 5px 5px 10px ${mfColors.cardShadowGrey};
  border-radius: 8px;
  padding: 16px 24px;
  display: ${(props: IContentWrapperProps) => (props.isExpanded ? 'block' : 'none')};
  z-index: 7;
  cursor: default;

  &:before {
    position: absolute;
    top: ${({ position, offset }: IContentWrapperProps) => ([EPosition.BottomLeft, EPosition.BottomRight].includes(position) ? `-${offset.y}px` : '0px')};
    left: ${({ position, offset }: IContentWrapperProps) => (position === EPosition.Right ? `-${offset.x}px` : '0px')};
    height: ${({ position, offset }: IContentWrapperProps) => ([EPosition.BottomLeft, EPosition.BottomRight].includes(position) ? `${offset.y}px` : '100%')};
    width: ${({ position, offset }: IContentWrapperProps) => (position === EPosition.Right ? `${offset.x}px` : '100%')};
    content: '';
  }
`;

interface IDropdownPosition {
  top?: string;
  bottom?: string;
  left?: string;
  right?: string;
}

interface IProps {
  control: JSX.Element;
  content: JSX.Element;
  isVisible?: boolean;
  arrow?: JSX.Element;
  onVisibilityChange?: (isExpanded: boolean) => void;
  toggleType?: EToggleType;
  position?: EPosition;
  style?: { [key: string]: string };
  offset?: IDropdownOffset;
  wrapperStyle?: { [key: string]: string };
}

const Dropdown = ({
  control,
  content,
  onVisibilityChange,
  isVisible,
  arrow,
  position = EPosition.BottomLeft,
  toggleType = EToggleType.Click,
  offset = { x: 0, y: DROPDOWN_CONTROL_OFFSET },
  style,
  wrapperStyle
}: IProps): JSX.Element => {
  const offsetX = offset.x || 0;
  const offsetY = offset.y || 0;
  const controlRef = useRef<HTMLDivElement>(null);
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const [dropdownPosition, setDropdownPosition] = useState<IDropdownPosition | null>(null);
  const prevVisible = useRef(false);

  const trackDropdownPosition = useCallback(() => {
    if (controlRef && controlRef.current) {
      const controlRect = controlRef.current.getBoundingClientRect();
      let positionStyle: IDropdownPosition | null = null;

      switch (position) {
        case EPosition.BottomLeft:
          positionStyle = {
            left: `${controlRect.left + offsetX}px`,
            top: `${controlRect.bottom + offsetY}px`
          };
          break;
        case EPosition.TopLeft:
          positionStyle = {
            right: `${window.innerWidth + offsetX - controlRect.right}px`,
            bottom: `${window.innerHeight - controlRect.top + offsetY}px`
          };
          break;
        case EPosition.BottomRight:
          positionStyle = {
            right: `${window.innerWidth + offsetX - controlRect.right}px`,
            top: `${controlRect.bottom + offsetY}px`
          };
          break;
        case EPosition.Right:
          positionStyle = {
            left: `${controlRect.width + offsetX}px`,
            bottom: `${0 + offsetY}px`
          };
          break;
        case EPosition.Top:
          positionStyle = {
            left: `${controlRect.left + offsetX}px`,
            bottom: `${window.innerHeight - controlRect.top + offsetY}px`
          };
          break;
        case EPosition.TopRight:
          positionStyle = {
            left: `${controlRect.right + offsetX}px`,
            bottom: `${window.innerHeight - controlRect.top + offsetY}px`
          };
          break;
        default:
          break;
      }

      setDropdownPosition(positionStyle);
    }
  }, [position, offsetX, offsetY]);

  const handleClickOutside = useCallback(
    (e: MouseEvent) => {
      if (controlRef.current && e.target && !controlRef.current.contains(e.target as HTMLElement)) {
        setIsExpanded(false);
      }
    },
    [setIsExpanded, controlRef]
  );

  useEffect(() => {
    if (isVisible !== undefined && isVisible !== prevVisible.current) {
      setIsExpanded(isVisible);
    }
  }, [isVisible, isExpanded]);

  useEffect(() => {
    prevVisible.current = isExpanded;
    if (onVisibilityChange) {
      onVisibilityChange(isExpanded);
    }
  }, [isExpanded, onVisibilityChange]);

  useLayoutEffect(() => {
    trackDropdownPosition();
    window.addEventListener('scroll', trackDropdownPosition);
    window.addEventListener('click', handleClickOutside, true);
    return () => {
      window.removeEventListener('scroll', trackDropdownPosition);
      window.removeEventListener('click', handleClickOutside, true);
    };
  }, [trackDropdownPosition, handleClickOutside]);

  const handleControlClick = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation();
      if (toggleType !== EToggleType.Click) {
        return;
      }
      trackDropdownPosition();
      setIsExpanded((expanded) => !expanded);
    },
    [toggleType, setIsExpanded, trackDropdownPosition]
  );

  const handleMouseOver = useCallback(() => {
    if (toggleType !== EToggleType.Mouseover) {
      return;
    }

    trackDropdownPosition();
    setIsExpanded(true);
  }, [toggleType, trackDropdownPosition]);

  const handleMouseLeave = useCallback(() => {
    if (toggleType !== EToggleType.Mouseover) {
      return;
    }
    setIsExpanded(false);
  }, [toggleType]);

  return (
    <ControlWrapper onMouseOver={handleMouseOver} onMouseLeave={handleMouseLeave} ref={controlRef} onClick={handleControlClick} style={wrapperStyle}>
      {control}
      {dropdownPosition && (
        <ContentWrapper
          position={position}
          isExpanded={isExpanded}
          offset={offset}
          onClick={(e) => {
            e.stopPropagation();
          }}
          style={{
            ...style,
            ...dropdownPosition
          }}
        >
          {content}
          {arrow}
        </ContentWrapper>
      )}
    </ControlWrapper>
  );
};

export default Dropdown;
