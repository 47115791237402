import React, { useState, useEffect, useMemo, useRef, useCallback } from 'react';
import { Chart } from 'chart.js';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import debounce from 'lodash.debounce';

import { ICompany } from 'models/company';
import { IFarmName } from 'models/farm';
import { ICustomerSurveyWithTimeRange, ISurvey } from 'models/survey';

import { useFetchFarmsTreesData, useFetchFarmsCipoData, TFarmsTreesData, TFarmsCipoData } from 'services/data/farms';
import numberUtils from 'utils/numbers';
import { SCORE_COLORS, COLORS } from 'models/colors';
import { hexToRgb } from 'utils/helpers';

import LocalLoader from 'atomicComponents/LocalLoader';

import { mfColors } from 'vars';

const Wrapper = styled.div`
  width: 100%;
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`;

const Row = styled.div`
  width: 100%;
  display: flex;
  flex-cirection: row;
  align-items: flex-start;
  justify-content: space-between;
  margin: 24px 0 0;
`;

const OverallTreesCountWidget = styled.div`
  width: 240px;

  .header {
    margin: 0 0 8px;
  }

  .body {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 240px;
  }

  .label {
    color: ${mfColors.grey};
  }

  .value {
    color: ${COLORS.replant.fill};
    font-size: 36px;
  }
`;

const GraphFarmWidget = styled.div`
  width: calc(100% - 240px);

  &.fullsize {
    width: 100%;
  }

  .header {
    margin: 0 0 8px;
  }

  .body {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 240px;
  }
`;

const SCORES_TO_SUM = [6, 5, 4, 3, 2, 1, 0, -1];

const GRAPH_SCORE_COLORS = {
  ...SCORE_COLORS,
  [-1]: {
    fill: mfColors.missingTreeColor,
    border: SCORE_COLORS[-1].border
  }
};

const CIPO_GRAPH_COLORS = ['#45D0E9', '#A38CDA', '#73E94A', '#F25E6A', '#FDBC46', '#338E8D', '#F25E6A', '#FDBC46', '#BBA1FA', '#F37981', '#45D0E9', '#BBA1FA'];

interface IProps {
  company: ICompany;
  farms: IFarmName[];
  farmsSurveys: ISurvey[][];
  customerSurveys: ICustomerSurveyWithTimeRange[];
  selectedSurvey: ICustomerSurveyWithTimeRange | null;
  selectedFarm: string | null;
  onSelectFarm: (farmID: string) => void;
}

const TreeAnalytics = ({ company, farms, farmsSurveys, customerSurveys, selectedSurvey, selectedFarm, onSelectFarm }: IProps): JSX.Element => {
  const { t } = useTranslation();
  const fetchFarmsTreesData = useFetchFarmsTreesData();
  const fetchFarmsCipoData = useFetchFarmsCipoData();
  const [farmsTreeData, setFarmsTreesData] = useState<TFarmsTreesData | null>(null);
  const [farmsCipoData, setFarmsCipoData] = useState<TFarmsCipoData | null>(null);
  const farmsChartCanvasRef = useRef<HTMLCanvasElement>(null);
  const absoluteScoresChartCanvasRef = useRef<HTMLCanvasElement>(null);
  const normalizedScoresChartCanvasRef = useRef<HTMLCanvasElement>(null);
  const cipoChartCanvasRef = useRef<HTMLCanvasElement>(null);
  const farmsChartRef = useRef<Chart | null>(null);
  const absoluteScoresChartRef = useRef<Chart | null>(null);
  const normalizedScoresChartRef = useRef<Chart | null>(null);
  const cipoChartRef = useRef<Chart | null>(null);
  const [chartFarm, setChartFarm] = useState<IFarmName | null>(null);

  useEffect(() => {
    setChartFarm(() => {
      if (!selectedFarm) return null;
      return farms.find((farm) => farm.id === selectedFarm) || null;
    });
  }, [farms, selectedFarm]);

  const getTreesData = useMemo(() => {
    const fetchData = () => {
      if (!selectedSurvey || !farmsSurveys.length) return;
      const params = farms.reduce((acc: { farmID: string; surveyID: string }[], farm: IFarmName, ind: number) => {
        const farmSurveys = farmsSurveys.find((surveys) => !!surveys.find((entry) => entry.farmID === farm.id));
        if (!farmSurveys || !farmSurveys.length) return acc;

        const surveyWithTitle = farmSurveys.find((survey) => survey.customerSurveyID === selectedSurvey.id);
        if (!surveyWithTitle) return acc;

        return [
          ...acc,
          {
            farmID: farm.id,
            surveyID: surveyWithTitle.id
          }
        ];
      }, []);

      setFarmsTreesData(null);
      fetchFarmsTreesData(params).then(setFarmsTreesData);
    };

    return debounce(fetchData, 600);
  }, [farms, farmsSurveys, selectedSurvey, fetchFarmsTreesData]);

  useEffect(() => {
    getTreesData();

    return () => {
      getTreesData.cancel();
    };
  }, [getTreesData]);

  useEffect(() => {
    setFarmsCipoData(null);
    if (farms.length) {
      fetchFarmsCipoData(farms.map((farm) => farm.id)).then(setFarmsCipoData);
    }
  }, [farms, fetchFarmsCipoData]);

  const totalTrees = useMemo(() => {
    if (!farmsTreeData) return 0;
    let treesScores;

    if (chartFarm) {
      treesScores = farmsTreeData[chartFarm.id];
    } else {
      treesScores = Object.values(farmsTreeData).flat() || [];
    }

    if (!treesScores) return 0;

    const totalTrees = treesScores.reduce((acc: number, stat: { score: number; count: number }) => {
      if (!SCORES_TO_SUM.includes(stat.score)) return acc;
      return acc + stat.count;
    }, 0);

    return numberUtils.formatShortFormNumber(totalTrees);
  }, [farmsTreeData, chartFarm]);

  const scoresFarms = useMemo(() => {
    if (!farmsTreeData) return [];
    return farms.filter((farm) => !!farmsTreeData[farm.id]);
  }, [farmsTreeData, farms]);

  const cipoFarms = useMemo(() => {
    if (!farmsCipoData) return [];
    return farms.filter((farm) => {
      const farmSurveys = farmsSurveys.find((surveys) => surveys[0].farmID === farm.id)?.filter((survey) => survey.status === 'published');
      return farmsCipoData[farm.id] && farmSurveys?.length;
    });
  }, [farmsCipoData, farms, farmsSurveys]);

  useEffect(() => {
    if (!farmsChartCanvasRef.current || !farmsTreeData) return;
    const labels: string[] = [];
    const data: number[] = [];

    scoresFarms.forEach((farm: IFarmName) => {
      if (farmsTreeData[farm.id]) {
        labels.push(farm.name);
        data.push(farmsTreeData[farm.id].reduce((acc, score) => acc + score.count, 0));
      }
    });
    const barColor = COLORS.replant.fill;

    const config = {
      type: 'bar',
      data: {
        labels,
        datasets: [
          {
            label: t('dashboard.number_of_trees'),
            data,
            backgroundColor: labels.map((entry) => (!chartFarm?.name || entry === chartFarm.name ? barColor : hexToRgb(barColor, 0.2))),
            maxBarThickness: 80
          }
        ]
      },
      options: {
        tooltips: {
          callbacks: {
            label: (context) => numberUtils.formatShortFormNumber(context.value)
          }
        },
        legend: {
          display: true,
          labels: {
            generateLabels(chart) {
              return Chart.defaults.global.legend.labels.generateLabels.apply(this, [chart]).map((item) => ({
                ...item,
                fillStyle: item.fillStyle.replace(',0.2', '')
              }));
            }
          }
        },
        plugins: {
          title: {
            display: false
          }
        },
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          yAxes: [
            {
              stacked: true,
              ticks: {
                callback: numberUtils.formatShortFormNumber
              }
            }
          ]
        }
      }
    };

    if (farmsChartRef.current) {
      farmsChartRef.current.destroy();
    }

    farmsChartRef.current = new Chart(farmsChartCanvasRef.current, config);
  }, [chartFarm, farmsTreeData, scoresFarms, t]);

  useEffect(() => {
    if (!absoluteScoresChartCanvasRef.current || !farmsTreeData) return;
    const labels: string[] = [];
    const data: { [key: string]: number[] } = {};

    scoresFarms.forEach((farm: IFarmName) => {
      if (farmsTreeData[farm.id]) {
        labels.push(farm.name);
        SCORES_TO_SUM.forEach((score) => {
          if (!data[score]) {
            data[score] = [];
          }
          const farmScore = farmsTreeData[farm.id].find((row) => row.score === score);
          data[score].push(farmScore?.count || 0);
        });
      }
    });

    const config = {
      type: 'bar',
      data: {
        labels,
        datasets: SCORES_TO_SUM.map((score) => {
          const barColor = GRAPH_SCORE_COLORS[score].fill;
          return {
            label: score,
            stack: 'Stack 0',
            data: data[score],
            backgroundColor: labels.map((entry) => (!chartFarm?.name || entry === chartFarm.name ? barColor : hexToRgb(barColor, 0.2))),
            maxBarThickness: 80
          };
        })
      },
      options: {
        tooltips: {
          callbacks: {
            label: (context) => {
              const value = numberUtils.formatShortFormNumber(context.value);
              const totalScores = SCORES_TO_SUM.reduce((acc, score) => acc + (data[score][context.index] || 0), 0);
              const percentage = numberUtils.normalizeDecimal((+context.value / totalScores) * 100, 2);
              return `Score ${SCORES_TO_SUM[context.datasetIndex]}: ${value} / ${percentage}%`;
            }
          }
        },
        legend: {
          display: true,
          labels: {
            generateLabels(chart) {
              return Chart.defaults.global.legend.labels.generateLabels.apply(this, [chart]).map((item) => ({
                ...item,
                fillStyle: item.fillStyle.replace(',0.2', '')
              }));
            }
          }
        },
        plugins: {
          title: {
            display: false
          }
        },
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          yAxes: [
            {
              stacked: true,
              ticks: {
                callback: numberUtils.formatShortFormNumber
              }
            }
          ]
        }
      }
    };

    if (absoluteScoresChartRef.current) {
      absoluteScoresChartRef.current.destroy();
    }

    absoluteScoresChartRef.current = new Chart(absoluteScoresChartCanvasRef.current, config);
  }, [chartFarm, farmsTreeData, scoresFarms, t]);

  useEffect(() => {
    if (!normalizedScoresChartCanvasRef.current || !farmsTreeData) return;
    const labels: string[] = [];
    const data: { [key: string]: number[] } = {};

    scoresFarms.forEach((farm: IFarmName) => {
      if (farmsTreeData[farm.id]) {
        labels.push(farm.name);
        const totalScores = farmsTreeData[farm.id].reduce((acc, score) => acc + score.count, 0);
        SCORES_TO_SUM.forEach((score) => {
          if (!data[score]) {
            data[score] = [];
          }
          const farmScore = farmsTreeData[farm.id].find((row) => row.score === score);
          data[score].push(((farmScore?.count || 0) / totalScores) * 100);
        });
      }
    });

    const config = {
      type: 'bar',
      data: {
        labels,
        datasets: SCORES_TO_SUM.map((score) => {
          const barColor = GRAPH_SCORE_COLORS[score].fill;
          return {
            label: score,
            stack: 'Stack 0',
            data: data[score],
            backgroundColor: labels.map((entry) => (!chartFarm?.name || entry === chartFarm.name ? barColor : hexToRgb(barColor, 0.2))),
            maxBarThickness: 80
          };
        })
      },
      options: {
        tooltips: {
          callbacks: {
            label: (context) => {
              const score = SCORES_TO_SUM[context.datasetIndex];
              const farm = scoresFarms.find((entry) => entry.name === context.label);

              if (!farm) return '';

              const scoreCount = farmsTreeData[farm.id].find((row) => row.score === score)?.count || 0;
              return `Score ${score}: ${numberUtils.formatShortFormNumber(scoreCount)} / ${numberUtils.normalizeDecimal(+context.value, 2)}%`;
            }
          }
        },
        legend: {
          display: true,
          labels: {
            generateLabels(chart) {
              return Chart.defaults.global.legend.labels.generateLabels.apply(this, [chart]).map((item) => ({
                ...item,
                fillStyle: item.fillStyle.replace(',0.2', '')
              }));
            }
          }
        },
        plugins: {
          title: {
            display: false
          }
        },
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          yAxes: [
            {
              stacked: true,
              ticks: {
                callback: (value) => `${value} %`,
                max: 100
              }
            }
          ]
        }
      }
    };

    if (normalizedScoresChartRef.current) {
      normalizedScoresChartRef.current.destroy();
    }

    normalizedScoresChartRef.current = new Chart(normalizedScoresChartCanvasRef.current, config);
  }, [chartFarm, farmsTreeData, scoresFarms, t]);

  useEffect(() => {
    if (!cipoChartCanvasRef.current || !farmsTreeData || !farmsSurveys.length) return;
    const labels: string[] = [];
    let data: number[][] = [];

    data = customerSurveys.map(() => []);

    cipoFarms.forEach((farm: IFarmName) => {
      const farmSurveys = farmsSurveys.find((surveys) => surveys[0].farmID === farm.id)?.filter((survey) => survey.status === 'published');

      labels.push(farm.name);
      customerSurveys.forEach((survey, index) => {
        const farmSurvey = farmSurveys?.find((entry) => entry.customerSurveyID === survey.id);
        if (farmSurvey) {
          data[index].push(farmsCipoData?.[farm.id]?.[farmSurvey.id] || 0);
        } else {
          data[index].push(0);
        }
      });
    });

    data = data.slice(0, customerSurveys.length).map((values) => {
      if (values.length < cipoFarms.length) {
        return [...values, ...Array(cipoFarms.length - values.length).fill(0)];
      }
      return values;
    });

    const config = {
      type: 'bar',
      data: {
        labels,
        datasets: data.map((dataEntries, index) => {
          const barColor = CIPO_GRAPH_COLORS[index];
          return {
            label: customerSurveys[index]?.name || '',
            data: dataEntries,
            backgroundColor: labels.map((entry) => (!chartFarm?.name || entry === chartFarm.name ? barColor : hexToRgb(barColor, 0.2))),
            maxBarThickness: 80
          };
        })
      },
      options: {
        tooltips: {
          callbacks: {
            label: (context) => `${customerSurveys[context.datasetIndex].name}: ${numberUtils.formatShortFormNumber(+context.value)}`
          }
        },
        legend: {
          display: true,
          labels: {
            generateLabels(chart) {
              return Chart.defaults.global.legend.labels.generateLabels.apply(this, [chart]).map((item) => ({
                ...item,
                fillStyle: item.fillStyle.replace(',0.2', '')
              }));
            }
          }
        },
        plugins: {
          title: {
            display: false
          }
        },
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          yAxes: [
            {
              ticks: {
                callback: numberUtils.formatShortFormNumber,
                min: 0
              }
            }
          ]
        }
      }
    };

    if (cipoChartRef.current) {
      cipoChartRef.current.destroy();
    }

    cipoChartRef.current = new Chart(cipoChartCanvasRef.current, config);
  }, [chartFarm, farmsTreeData, farmsCipoData, cipoFarms, customerSurveys, farmsSurveys, t]);

  const handleChartClick = useCallback(
    (chart: Chart | null, e: React.MouseEvent<any>, isScore = false) => {
      const activePoints = chart.getElementsAtEvent(e);
      const farmIndex = activePoints?.[0]?._index;

      if (farmIndex !== undefined) {
        const newFarm = (isScore ? scoresFarms : cipoFarms)[farmIndex];
        onSelectFarm(newFarm.id);
      }
    },
    [scoresFarms, cipoFarms, onSelectFarm]
  );

  return (
    <Wrapper>
      {!farmsTreeData && (
        <Row>
          <LocalLoader />
        </Row>
      )}
      {farmsTreeData && (
        <>
          <Row>
            <OverallTreesCountWidget>
              <div className="header">{t('dashboard.overall_number_of_trees')}</div>
              <div className="body">
                <div className="label">{t('shared.trees')}</div>
                <div className="value">{totalTrees}</div>
              </div>
            </OverallTreesCountWidget>
            <GraphFarmWidget>
              <div className="header">{t('dashboard.trees_per_farm')}</div>
              <div className="body">
                <canvas onClick={(e) => handleChartClick(farmsChartRef.current, e, true)} ref={farmsChartCanvasRef} />
              </div>
            </GraphFarmWidget>
          </Row>
          <Row>
            <GraphFarmWidget className="fullsize">
              <div className="header">{t('dashboard.scores_distribution_per_farm_absolute')}</div>
              <div className="body">
                <canvas onClick={(e) => handleChartClick(absoluteScoresChartRef.current, e, true)} ref={absoluteScoresChartCanvasRef} />
              </div>
            </GraphFarmWidget>
          </Row>
          <Row>
            <GraphFarmWidget className="fullsize">
              <div className="header">{t('dashboard.scores_distribution_per_farm_normalized')}</div>
              <div className="body">
                <canvas onClick={(e) => handleChartClick(normalizedScoresChartRef.current, e, true)} ref={normalizedScoresChartCanvasRef} />
              </div>
            </GraphFarmWidget>
          </Row>
          <Row>
            <GraphFarmWidget className="fullsize">
              <div className="header">{t('dashboard.cipo_covered_trees_over_time_per_farm')}</div>
              <div className="body">
                <canvas onClick={(e) => handleChartClick(cipoChartRef.current, e)} ref={cipoChartCanvasRef} />
              </div>
            </GraphFarmWidget>
          </Row>
        </>
      )}
    </Wrapper>
  );
};

export default TreeAnalytics;
