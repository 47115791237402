import { Map, GeoJSONSource, AnyLayer, Marker } from 'mapbox-gl';
import * as turf from '@turf/turf';

import { IZone } from 'models/zone';
import { IGrove } from 'models/grove';

import {
  getMapSourceById,
  addLayerToMap,
  reorderLayers,
  LAYERS_PRIORITY,
  ZONES_LAYERS,
  GROVES_LAYERS,
  GROVES_TEXT_LAYERS,
  GROVES_HOVERED_LAYERS,
  GROVES_SELECTED_LAYERS,
  addMapSource,
  getPolygonFeature
} from 'services/util/mapHelpers';
import { TEventHandler } from 'models/map';
import { ZoneHTMLMarker } from 'components/shared/markers/ZoneMarker';

const SELECTED_GROVES_SOURCE = 'selected-groves';
const HOVERED_GROVE_SOURCE = 'hovered-grove';

const borderWidth = {
  base: 1,
  stops: [
    [13, 1.4],
    [16, 4]
  ]
};

const selectedGrovePaint = {
  'line-color': '#ffffbb',
  'line-width': 6
};
const nonActiveExpressions = ['==', ['get', 'active'], false];

export const addZonesLayers = (map: Map): void => {
  const sources = ['zones', 'zones-center', 'hovered-zone', 'selected-zone'];

  sources.forEach((source) => {
    const mapSource = getMapSourceById(map, source);

    if (!mapSource) {
      map.addSource(source, {
        data: turf.featureCollection([]),
        type: 'geojson'
      });
    }
  });

  addLayerToMap(map, ZONES_LAYERS[0], {
    id: ZONES_LAYERS[0],
    type: 'fill',
    source: 'zones',
    paint: { 'fill-opacity': 0 }
  });

  addLayerToMap(map, ZONES_LAYERS[1], {
    id: ZONES_LAYERS[1],
    type: 'line',
    source: 'zones',
    paint: {
      'line-color': '#ffffe1',
      'line-width': 4
    }
  });

  addLayerToMap(map, ZONES_LAYERS[2], {
    id: ZONES_LAYERS[2],
    type: 'fill',
    source: 'hovered-zone',
    paint: { 'fill-color': 'rgba(255, 241, 146, 0.2)' }
  });

  addLayerToMap(map, ZONES_LAYERS[3], {
    id: ZONES_LAYERS[3],
    type: 'line',
    source: 'hovered-zone',
    paint: selectedGrovePaint
  });

  addLayerToMap(map, ZONES_LAYERS[4], {
    id: ZONES_LAYERS[4],
    type: 'fill',
    source: 'selected-zone',
    paint: { 'fill-color': 'rgba(255, 241, 146, 0.15)' }
  });

  addLayerToMap(map, ZONES_LAYERS[5], {
    id: ZONES_LAYERS[5],
    type: 'line',
    source: 'selected-zone',
    paint: {
      'line-color': '#ffff79',
      'line-width': 6
    }
  });

  addLayerToMap(map, ZONES_LAYERS[6], {
    id: ZONES_LAYERS[6],
    type: 'symbol',
    source: 'zones-center',
    paint: {
      'text-opacity': 1,
      'text-color': '#ffffff',
      'text-halo-width': 2
    },
    layout: {
      'text-font': ['Roboto Bold'],
      'text-size': 30,
      'text-field': '{name}'
    }
  });

  reorderLayers(
    map,
    LAYERS_PRIORITY.map((layerName, index) => ({
      zIndex: index,
      name: layerName
    }))
  );
};

const getGroveColor = () => ({
  default: '#B0AE80',
  stops: [
    ['published', '#ffffe0'],
    ['unpublished', '#B0AE80']
  ],
  property: 'status',
  type: 'categorical'
});

export const addGrovesLayers = (map: Map): void => {
  const sources = ['groves', 'groves-center', HOVERED_GROVE_SOURCE, SELECTED_GROVES_SOURCE];

  sources.forEach((source) => {
    const mapSource = getMapSourceById(map, source);

    if (!mapSource) {
      map.addSource(source, {
        data: turf.featureCollection([]),
        type: 'geojson'
      });
    }
  });

  addLayerToMap(map, GROVES_LAYERS[0], {
    id: 'groves-line',
    type: 'line',
    source: 'groves',
    paint: {
      'line-color': getGroveColor(),
      'line-width': borderWidth
    }
  } as AnyLayer);

  addLayerToMap(map, GROVES_LAYERS[1], {
    id: 'groves-fill',
    type: 'fill',
    source: 'groves',
    paint: {
      'fill-opacity': ['case', nonActiveExpressions, 0.4, 0],
      'fill-color': ['case', nonActiveExpressions, 'gray', 'transparent']
    }
  });

  addLayerToMap(map, GROVES_HOVERED_LAYERS[0], {
    id: GROVES_HOVERED_LAYERS[0],
    type: 'fill',
    source: HOVERED_GROVE_SOURCE,
    paint: { 'fill-color': ['case', nonActiveExpressions, 'transparent', 'rgba(255, 241, 146, 0.2)'] }
  });

  addLayerToMap(map, GROVES_HOVERED_LAYERS[1], {
    id: GROVES_HOVERED_LAYERS[1],
    type: 'line',
    source: HOVERED_GROVE_SOURCE,
    paint: {
      'line-color': ['case', nonActiveExpressions, 'transparent', '#ffffbb'],
      'line-width': ['case', nonActiveExpressions, 0, 6]
    }
  });

  addLayerToMap(map, GROVES_TEXT_LAYERS[0], {
    id: GROVES_TEXT_LAYERS[0],
    type: 'symbol',
    source: 'groves-center',
    paint: {
      'text-opacity': {
        base: 1,
        stops: [
          [11.4, 0.2],
          [12.2, 1]
        ]
      },
      'text-color': '#fff',
      'text-halo-width': 2
    },
    layout: {
      'text-allow-overlap': true,
      'text-font': ['Roboto Regular'],
      'text-size': {
        stops: [
          [11.4, 2],
          [14, 21]
        ]
      },
      'text-field': '{name}'
    }
  });

  addLayerToMap(map, GROVES_SELECTED_LAYERS[0], {
    id: GROVES_SELECTED_LAYERS[0],
    type: 'line',
    source: SELECTED_GROVES_SOURCE,
    paint: selectedGrovePaint
  });

  reorderLayers(
    map,
    LAYERS_PRIORITY.map((layerName, index) => ({
      zIndex: index,
      name: layerName
    }))
  );
};

export const drawZones = (map: Map, zones: IZone[]): void => {
  if (zones.length === 1) {
    return;
  }

  const zonesFeatures = zones.map((zone) => {
    const zoneProps = {
      name: zone.name,
      id: zone.id
    };
    return turf.feature(zone.geometry, zoneProps);
  });

  addMapSource(map, 'zones', zonesFeatures);
};

const MARKER_CLASSNAME = 'zone-label';

export const addZoneLabelMarkers = (map: Map, zones: IZone[], zoneClickHandler?: TEventHandler) => {
  const zoneFeatures = zones.map((zone) => turf.centerOfMass(zone.geometry, { properties: { id: zone.id, name: zone.name } }));

  zoneFeatures.forEach((feature) => {
    const { name } = feature.properties;
    const el = document.createElement('div');
    el.innerHTML = ZoneHTMLMarker({ title: name, className: MARKER_CLASSNAME });

    const marker = new Marker(el);

    marker.setLngLat(feature.geometry.coordinates as [number, number]).addTo(map);

    if (zoneClickHandler) {
      el.addEventListener('click', (event) => {
        zoneClickHandler({ features: [feature] });
        event.stopPropagation();
      });
    }

    return marker;
  });
};

export const removeAllZoneMarkers = (map: Map): void => {
  const container = map.getContainer();
  const markers = container.querySelectorAll(`.${MARKER_CLASSNAME}`);

  markers.forEach((marker) => {
    marker.remove();
  });
};

export const setZoneEventHandlers = (map: Map, zoneClickHandler: TEventHandler, zoneMousemoveHandler: TEventHandler, zoneMouseleaveHandler: TEventHandler): void => {
  map.on('click', ZONES_LAYERS[0], zoneClickHandler);
  map.on('mousemove', ZONES_LAYERS[0], zoneMousemoveHandler);
  map.on('mouseleave', ZONES_LAYERS[0], zoneMouseleaveHandler);
};

export const removeZoneEventHandlers = (map: Map, zoneClickHandler: TEventHandler, zoneMousemoveHandler: TEventHandler, zoneMouseleaveHandler: TEventHandler): void => {
  map.off('click', ZONES_LAYERS[0], zoneClickHandler);
  map.off('mousemove', ZONES_LAYERS[0], zoneMousemoveHandler);
  map.off('mouseleave', ZONES_LAYERS[0], zoneMouseleaveHandler);
};

export const drawSelectedGroves = (map: Map, groves: IGrove[], selectedGroves: string[] = []) => {
  const selectedGrovesFeatures = groves
    .filter((grove: IGrove) => selectedGroves.includes(grove.id))
    .map((grove: IGrove) => getPolygonFeature<IGrove>(grove.geometry.coordinates, grove))
    .filter((feature) => !!feature) as turf.Feature<turf.Polygon, IGrove>[];
  const selectedGrovesSource = map.getSource(SELECTED_GROVES_SOURCE) as GeoJSONSource;
  selectedGrovesSource.setData(turf.featureCollection(selectedGrovesFeatures));
};

export const drawGroves = (map: Map, groves: IGrove[], selectedGrove?: IGrove | null, recentlyPublishedGroves: string[] = []) => {
  const grovesFeatures = groves.map((grove: IGrove) => getPolygonFeature<IGrove>(grove.geometry.coordinates, grove)).filter((feature) => !!feature) as turf.Feature<
    turf.Polygon,
    IGrove
  >[];
  const centerPoints = grovesFeatures
    .filter((feature) => !recentlyPublishedGroves.includes(feature.properties.id))
    .map((grove) => {
      const center = turf.centerOfMass(grove, { properties: grove.properties });

      if (turf.inside(center, grove)) {
        return center;
      }

      const point = turf.pointOnFeature(grove);
      point.properties = grove.properties;

      return point;
    });
  const grovesCenterPoints = selectedGrove ? centerPoints.filter((item) => item?.properties?.id !== selectedGrove.id) : centerPoints;

  const grovesSource = map.getSource('groves') as GeoJSONSource;
  const grovesCenterSource = map.getSource('groves-center') as GeoJSONSource;
  grovesSource.setData(turf.featureCollection(grovesFeatures));
  grovesCenterSource.setData(turf.featureCollection(grovesCenterPoints));
};

export const setGroveEventHandlers = (map: Map, groveClickHandler: TEventHandler, groveMousemoveHandler?: TEventHandler, groveMouseleaveHandler?: TEventHandler): void => {
  map.on('click', GROVES_LAYERS[1], groveClickHandler);
  if (groveMousemoveHandler) {
    map.on('mousemove', GROVES_LAYERS[1], groveMousemoveHandler);
  }
  if (groveMouseleaveHandler) {
    map.on('mouseleave', GROVES_LAYERS[1], groveMouseleaveHandler);
  }
};

export const removeGroveEventHandlers = (map: Map, groveClickHandler: TEventHandler, groveMousemoveHandler?: TEventHandler, groveMouseleaveHandler?: TEventHandler): void => {
  map.off('click', GROVES_LAYERS[1], groveClickHandler);

  if (groveMousemoveHandler) {
    map.off('mousemove', GROVES_LAYERS[1], groveMousemoveHandler);
  }
  if (groveMouseleaveHandler) {
    map.off('mouseleave', GROVES_LAYERS[1], groveMouseleaveHandler);
  }
};

export const drawHoveredGrove = (map: Map, grove: IGrove, groveProperties: object) => {
  const polygon = turf.feature(grove.geometry, groveProperties);
  (map.getSource(HOVERED_GROVE_SOURCE) as GeoJSONSource).setData(turf.featureCollection([polygon]) as any);
  map.getCanvas().style.cursor = 'pointer';
};

export const clearHoveredGrove = (map: Map) => {
  (map.getSource(HOVERED_GROVE_SOURCE) as GeoJSONSource).setData(turf.featureCollection([]));
  map.getCanvas().style.cursor = '';
};
