import { ISurveyWithTimeRange, ISurvey, ICustomerSurvey, ICustomerSurveyWithTimeRange } from 'models/survey';
import { IGroveSurveyStats } from 'models/stats';

const getSurveyWithTimeRange = (survey: ISurvey | ICustomerSurvey, selectedAreaStats?: IGroveSurveyStats[]): ISurveyWithTimeRange | ICustomerSurveyWithTimeRange => {
  const surveyStats = selectedAreaStats
    ?.filter((stat) => stat.surveyID === survey.id)
    ?.map((stat) => ({ ...stat, shotAt: stat.shotAt || survey.date || '' }))
    ?.sort((a, b) => (b.shotAt < a.shotAt ? 1 : -1));
  return {
    ...survey,
    from: surveyStats?.[0]?.shotAt || survey.date || '',
    to: surveyStats?.[surveyStats.length - 1]?.shotAt || survey.date || ''
  };
};

const surveyUtils = {
  getSurveyWithTimeRange
};

export default surveyUtils;
