import React from 'react';
import styled from 'styled-components';
import { faCircleCheck } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { mfColors } from 'vars';
import { Button } from 'atomicComponents';

const Wrapper = styled.div`
  text-align: center;
  color: ${mfColors.darkGrey};
`;

const Title = styled.span`
  font-family: MontserratBold;
  font-size: 18px;
  margin-left: 8px;
  text-transform: uppercase;
`;

const PublishNotification = ({ onClose }: { onClose: () => void }) => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <div>
        <FontAwesomeIcon icon={faCircleCheck} color={mfColors.green} />
        <Title>{`${t('shared.updated')}!`}</Title>
      </div>
      <div style={{ marginTop: 12 }}>{t('main.farm_survey_published')}</div>
      <Button style={{ width: '105px', margin: '12px auto', borderRadius: '50px', height: '20px' }} onClick={onClose} text={t('shared.ok')} />
    </Wrapper>
  );
};

export default PublishNotification;
