import React from 'react';
import styled from 'styled-components';
import { mfColors, mfShadows } from '../vars';

interface IWrapperProps {
  background: string;
  shadow: string;
  padding: string;
  width: string;
  height: string;
}

const DEFAULT_PADDING = '40px';

const Wrapper = styled.div<IWrapperProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  padding: ${({ padding }) => padding};
  border-radius: 8px;
  background-color: ${({ background }: IWrapperProps) => background};
  box-shadow: ${({ shadow }: IWrapperProps) => shadow};
  margin: 12px 0;
`;

const Header = styled.div`
  font-size: 32px;
  line-height: 39px;
`;

const Content = styled.div`
  font-size: 14px;
  line-height: 17px;
  width: 100%;
  height: 100%;
`;

interface IProps {
  width?: string;
  height?: string;
  header?: JSX.Element | string;
  content: JSX.Element | string;
  padding?: string;
  shadow?: string;
  background?: string;
}

const StaticCard = ({
  width = '200px',
  height = 'auto',
  header,
  content,
  shadow = mfShadows.staticCardDefaultShadow,
  padding = DEFAULT_PADDING,
  background = mfColors.lightBlue
}: IProps) => (
  <Wrapper shadow={shadow} background={background} width={width} padding={padding} height={height}>
    {header && <Header>{header}</Header>}
    <Content>{content}</Content>
  </Wrapper>
);

export default StaticCard;
