import React, { useRef, useEffect, useState, useMemo } from 'react';
import { Chart } from 'chart.js';
import styled from 'styled-components';

const Wrapper = styled.div`
  width: 100%;
`;

interface IProps {
  values: [string, string[]][];
}

const CompareReportsChart = ({ values }: IProps): JSX.Element => {
  const [chart, setChart] = useState<Chart | null>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);

  const config = useMemo(
    () => ({
      type: 'bar',
      options: {
        tooltips: { enabled: false },
        legend: { display: false },
        scales: {
          xAxes: [{ display: false, categoryPercentage: 1.0, barPercentage: 1.0 }],
          yAxes: [{ display: false }]
        }
      },
      data: {
        labels: values.map((value) => value[0]),
        datasets: [
          {
            backgroundColor: '#000',
            borderColor: '#000',
            borderWidth: 2,
            data: values.map((value) => value[value.length - 1].length)
          }
        ]
      }
    }),
    [values]
  );

  useEffect(() => {
    if (canvasRef.current) {
      if (chart) {
        chart.destroy();
      }
      setChart(new Chart(canvasRef.current, config));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [config]);

  return (
    <Wrapper>
      <canvas ref={canvasRef} />
    </Wrapper>
  );
};

export default CompareReportsChart;
