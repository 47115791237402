import React, { useMemo, useCallback, useState, useEffect } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import signInHooks from 'hooks/signIn.hooks';
import { ESupportedLanguage } from 'hooks/translations.hooks';
import { useAnalytics } from 'hooks/analytics.hooks';
import usersHooks from 'hooks/users.hooks';
import { useUpdateUserSettings } from 'services/data/users';
import Avatar, { EAvatarSize } from 'components/shared/Avatar';
import Terms from 'components/shared/Terms';
import { faBellOn, faLanguage, faToggleOff, faToggleOn, faSparkles } from '@fortawesome/pro-solid-svg-icons';
import { ReactComponent as LogoIcon } from 'assets/images/seetree_logo.svg';
import InlineSelect from 'atomicComponents/InlineSelect';
import { toggleMode, getMode } from 'redux/modeSlice';
import { mfColors } from 'vars';
import { EEventType } from 'models/analytics';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NavLink } from 'react-router-dom';

const Container = styled.div`
  z-index: 3;
  width: 100%;
  overflow: auto;
  font-size: 12px;
  font-family: Montserrat;
`;

interface IPopoverEntryProps {
  clickable?: boolean;
}

const PopoverEntry = styled.div`
  padding: 6px 0;
  font-weight: normal;
  display: block;
  color: ${mfColors.darkGrey};
  white-space: nowrap;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: ${({ clickable }: IPopoverEntryProps) => (clickable ? 'pointer' : 'default')};
`;

const Mode = styled.span`
  margin-left: 10px;
  font-family: 'MontserratBold';
  cursor: pointer;
`;

const Logo = styled.div`
  margin-bottom: 30px;
`;

const UserData = styled.div`
  margin-top: 12px;
`;

const NotificationEntry = styled(NavLink)`
  text-decoration: none;
  color: ${mfColors.darkGrey};
`;

const TopWrapper = styled.div`
  padding: 16px 0 16px 16px;
  border-bottom: 1px solid rgba(49, 69, 106, 0.1);

  .sign-out {
    color: ${mfColors.lightBlue};
    font-family: MontserratBold;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
`;

const BottomWrapper = styled.div`
  padding: 28px 0 16px 16px;
  font-size: 16px;

  ${PopoverEntry} {
    padding: 8px 0;

    &:first-child {
      padding: 0;
    }
  }
`;

const TermsWrapper = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 16px;
  width: 100%;
  border-top: 1px solid rgba(49, 69, 106, 0.1);
`;

const Icon = styled(FontAwesomeIcon)`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
  color: ${mfColors.darkBlue};
`;

const WhatsNewEntry = styled.div`
  color: ${mfColors.darkGrey};
  cursor: pointer;
`;

const UserMenu = () => {
  const user = signInHooks.useSignedInUser();
  const userIsAdmin = signInHooks.useIsAdmin();
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const updateUserSettings = useUpdateUserSettings();
  const signOut = signInHooks.useSignOut();
  const mode = useSelector(getMode);
  const { logEvent } = useAnalytics();
  const [isUpdating, setIsUpdating] = useState<boolean>(false);
  const { showWhatsNewModal } = usersHooks.useWhatsNewModal();

  const toggleHandler = () => {
    dispatch(toggleMode());
  };

  useEffect(() => {
    if (mode) {
      localStorage.setItem('mode', 'anonymous');
    } else {
      localStorage.removeItem('mode');
    }
  }, [mode]);

  const modeIcon = useMemo(() => (mode ? faToggleOn : faToggleOff), [mode]);

  const languagesOptions = useMemo(
    () => [
      {
        value: ESupportedLanguage.EN,
        text: t('shared.languages.english'),
        key: ESupportedLanguage.EN
      },
      {
        value: ESupportedLanguage.PT,
        text: t('shared.languages.portuguese'),
        key: ESupportedLanguage.PT
      },
      {
        value: ESupportedLanguage.ES,
        text: t('shared.languages.spanish'),
        key: ESupportedLanguage.ES
      },
      {
        value: ESupportedLanguage.EL,
        text: t('shared.languages.greek'),
        key: ESupportedLanguage.EL
      }
    ],
    [t]
  );

  const signOutFromSisense = () => {
    const iframe = document.createElement('iframe');

    iframe.src = 'https://seetree.sisense.com/api/auth/logout';
    iframe.style.position = 'absolute';
    iframe.style.top = '0';
    iframe.style.left = '-1000px';
    iframe.style.visibility = 'hidden';

    document.body.appendChild(iframe);

    setTimeout(() => {
      iframe && iframe.remove && iframe.remove();
    }, 5000);
  };

  const handleSignOut = useCallback(() => {
    if (!user) return;
    logEvent({
      type: EEventType.SignOut,
      params: {
        email: user.email
      }
    });
    signOut();
    signOutFromSisense();
  }, [signOut, logEvent, user]);

  const changeLanguage = useCallback(
    async (language: ESupportedLanguage) => {
      if (isUpdating) return;
      setIsUpdating(true);
      i18n.changeLanguage(language);
      await updateUserSettings({
        ...(user?.settings || {}),
        language
      });
      setIsUpdating(false);
    },
    [i18n, isUpdating, updateUserSettings, user?.settings]
  );

  if (!user) {
    return null;
  }

  return (
    <Container>
      <TopWrapper>
        <Logo>
          <LogoIcon />
        </Logo>
        <Avatar size={EAvatarSize.LG} name={user.displayName} />
        {user && (
          <UserData>
            <PopoverEntry>{user.displayName}</PopoverEntry>
            <PopoverEntry>{user.email}</PopoverEntry>
            <PopoverEntry className="sign-out" onClick={handleSignOut}>
              {t('shared.nav_bar.sign_out')}
            </PopoverEntry>
          </UserData>
        )}
      </TopWrapper>
      <BottomWrapper>
        <PopoverEntry>
          <Icon icon={faLanguage} size="lg" />
          <InlineSelect<ESupportedLanguage>
            value={i18n.language as ESupportedLanguage}
            background={mfColors.sideBarBackground}
            onChange={changeLanguage}
            options={languagesOptions}
          />
        </PopoverEntry>
        <PopoverEntry>
          <Icon icon={faBellOn} size="lg" />
          <NotificationEntry to={'/notification-manager'}>{t('notification_manager.settings')}</NotificationEntry>
        </PopoverEntry>
        {userIsAdmin && (
          <PopoverEntry clickable onClick={toggleHandler}>
            <Icon icon={modeIcon} size="lg" />
            {'Mode:'}
            <Mode>{mode ? t('shared.nav_bar.anonymous') : t('shared.nav_bar.regular')}</Mode>
          </PopoverEntry>
        )}
        <PopoverEntry>
          <Icon icon={faSparkles} size="lg" />
          <WhatsNewEntry onClick={showWhatsNewModal}>{t('shared.nav_bar.whats_new')}</WhatsNewEntry>
        </PopoverEntry>
      </BottomWrapper>
      <TermsWrapper>
        <Terms multiline />
      </TermsWrapper>
    </Container>
  );
};

export default UserMenu;
