import styled from 'styled-components';
import React from 'react';

import { Map } from 'mapbox-gl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinus, faPlus } from '@fortawesome/pro-solid-svg-icons';
import { mfColors } from 'vars';

const Button = styled.div`
  background: ${mfColors.white};
  width: 25px;
  height: 25px;
  background-color: rgba(236, 236, 236, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &:hover {
    background-color: rgba(236, 236, 236, 1);
  }
`;

const ZoomInButtom = styled(Button)`
  border-radius: 4px 4px 0 0;
`;

const ZoomOutButton = styled(Button)`
  border-radius: 0 0 4px 4px;
`;
interface IProps {
  map: Map | null;
}

const ZoomControls = ({ map }: IProps): JSX.Element => {
  const zoomIn = () => {
    if (!map) {
      return;
    }
    map.easeTo({ zoom: map.getZoom() + 1 });
  };

  const zoomOut = () => {
    if (!map) {
      return;
    }
    map.easeTo({ zoom: map.getZoom() - 1 });
  };

  return (
    <>
      <ZoomInButtom onClick={zoomIn}>
        <FontAwesomeIcon icon={faPlus} />
      </ZoomInButtom>
      <ZoomOutButton onClick={zoomOut}>
        <FontAwesomeIcon icon={faMinus} />
      </ZoomOutButton>
    </>
  );
};

export default ZoomControls;
