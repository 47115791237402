import { MapboxGeoJSONFeature } from 'mapbox-gl';
import { EMeasurementSystem } from 'models/region';

export const TREE_GROWTH_FARM_ID = '-Ll6ew3ugQKSPZDr90IF';

export enum ERichItemType {
  Cipo = 'cipo',
  Fruit = 'fruit',
  Height = 'height',
  Volume = 'volume',
  Density = 'density',
  Weeds = 'weeds',
  CanopyArea = 'canopy area',
  Health = 'health',
  Ndvi = 'ndvi',
  TreeGrowth = 'tree growth',
  FreeArea = 'free area',
  Polygons = 'polygons',
  FruitAggregation = 'fruit aggregation',
  FruitYield = 'fruit yield',
  Capacity = 'capacity',
  Replant = 'replant'
}

export enum ERichItemFeatureType {
  Cipo = 'circle',
  Fruit = 'circle',
  Height = 'circle',
  Volume = 'circle',
  Density = 'circle',
  Weeds = 'line',
  CanopyArea = 'circle',
  Health = 'circle',
  Ndvi = 'circle',
  TreeGrowth = 'circle',
  FreeArea = 'line',
  Polygons = 'fill',
  FruitAggregation = 'fill',
  FruitYield = 'fill'
}

export enum ERichItemSubType {
  PreThinning = 'pre thinning',
  PostThinning = 'post thinning'
}

export const EVIDENCE_IGNORED_RICH_ITEMS = [ERichItemType.FreeArea, ERichItemType.Weeds];

export const RICH_ITEMS_TO_COMPARE = [ERichItemType.Volume, ERichItemType.Height, ERichItemType.Density, ERichItemType.CanopyArea, ERichItemType.Ndvi];

export const RICH_ITEM_PRIORITY = [
  ERichItemType.Health,
  ERichItemType.Ndvi,
  ERichItemType.TreeGrowth,
  ERichItemType.CanopyArea,
  ERichItemType.Cipo,
  ERichItemType.FreeArea,
  ERichItemType.Height,
  ERichItemType.Volume,
  ERichItemType.Weeds,
  ERichItemType.Capacity
];

export const BIG_QUERY_STATISTIC_TYPES = [
  ERichItemType.Cipo,
  ERichItemType.Health,
  ERichItemType.Height,
  ERichItemType.Ndvi,
  ERichItemType.TreeGrowth,
  ERichItemType.Volume,
  ERichItemType.FreeArea,
  ERichItemType.CanopyArea,
  ERichItemType.FruitYield,
  ERichItemType.Weeds,
  ERichItemType.Capacity
];

export const RICH_ITEM_TYPES_GEO_ANALYTICS = [ERichItemType.Height, ERichItemType.CanopyArea, ERichItemType.Volume];
export const RICH_ITEM_FRUIT_TYPES = [ERichItemType.FruitAggregation, ERichItemType.FruitYield];

export const RICH_ITEM_TYPES_WITH_MEASUREMENT_DEPENDENCE = [ERichItemType.Height, ERichItemType.Volume, ERichItemType.FreeArea, ERichItemType.CanopyArea];

export const RICH_ITEM_TYPES_ARE_AREA_WITH_MEASUREMENT_DEPENDENCE = [ERichItemType.CanopyArea];

export const RICH_ITEM_TYPES_ARE_VOLUME_WITH_MEASUREMENT_DEPENDENCE = [ERichItemType.Volume];

export const MAP_BIG_QUERY_TO_RICH_ITEMS = {
  [ERichItemType.Cipo]: ERichItemType.Cipo,
  [ERichItemType.Fruit]: ERichItemType.Fruit,
  [ERichItemType.Height]: ERichItemType.Height,
  [ERichItemType.Volume]: ERichItemType.Volume,
  [ERichItemType.Density]: ERichItemType.Density,
  [ERichItemType.CanopyArea]: 'canopyArea',
  [ERichItemType.Weeds]: ERichItemType.Weeds,
  [ERichItemType.Health]: ERichItemType.Health,
  [ERichItemType.Ndvi]: ERichItemType.Ndvi,
  [ERichItemType.TreeGrowth]: ERichItemType.TreeGrowth,
  [ERichItemType.FreeArea]: 'freeArea',
  [ERichItemType.Polygons]: ERichItemType.Polygons,
  [ERichItemType.FruitAggregation]: ERichItemType.FruitAggregation,
  [ERichItemType.FruitYield]: ERichItemType.FruitYield,
  [ERichItemType.Capacity]: ERichItemType.Replant
};

export interface IChartLabelConfig {
  /* eslint-disable camelcase */
  mapbox_value: number;
  color?: string;
  chart_label?: string;
  /* eslint-enable camelcase */
}

export interface IRichItem {
  compressedGeojsonPath: string;
  createdAt: number;
  farmID: string;
  geoObjectID: string;
  geoObjectType: string;
  geojsonPath: string;
  groveID: string;
  internal: boolean | null;
  isReport: boolean;
  isRichItem: boolean;
  labels: string[];
  levelVisibility: {
    grove: boolean | null;
    map: boolean | null;
    farm: true;
  };
  chartLabelConfigs: {
    en: IChartLabelConfig[];
    es: IChartLabelConfig[];
    pt: IChartLabelConfig[];
  };
  mapbox: string;
  reportDate: number;
  richItemTypeName: ERichItemType;
  richItemTypeVersion: number;
  status: string;
  surveyID: string;
  title: string;
  type: ERichItemType;
  updatedAt: number;
  valid: boolean;
  zoneID: string;
  axis?: any;
  extraMetadata?: {
    fruitsPerTree: number;
  };
}

export type TRichItemGeometryType = 'Point' | 'Polygon' | 'LineString';

interface IRichItemConfigEntry {
  axis?: { x: string; y: string };
  html?: string;
  mapbox?: string;
}

export interface IRichItemDefaultConfig {
  id: string;
  version: {
    [key: number]: {
      [EMeasurementSystem.Metric]: {
        en: IRichItemConfigEntry;
        pt: IRichItemConfigEntry;
      };
      [EMeasurementSystem.Imperial]: {
        en: IRichItemConfigEntry;
        pt: IRichItemConfigEntry;
      };
    };
  };
}

type TRichItemFeatureProperties = {
  id: string;
  [key: string]: string | number;
};

export interface IRichItemFeature {
  geometry: {
    type: TRichItemGeometryType;
    coordinates: number[];
  };
  properties: TRichItemFeatureProperties;
  type: 'Feature';
}

export interface IRichItemMapboxFeature extends MapboxGeoJSONFeature {
  properties: TRichItemFeatureProperties;
}

export interface IRichItemsGeojson {
  geojson: {
    crs?: {
      type: string;
      properties: { [key: string]: string | number };
    };
  };
  features: IRichItemFeature[];
  type: 'FeatureCollection';
}

export interface ICIPORichItemGeojson {
  [key: string]: {
    commodityID: string;
    coordinates: number[];
    detected: 'Yes' | 'No';
  };
}

export interface IRichItemGeojson {
  [key: string]: {
    commodityID: string;
    coordinates: number[];
    detected: string | null;
    index: string;
    area: number;
    height: number;
    volume: number;
    score: number;
  };
}

export interface IFreeAreaRichItemGeojson {
  [key: string]: {
    'free area': number;
    geometry: {
      type: TRichItemGeometryType;
      coordinates: number[];
    };
  };
}

export interface ICapacityRichItemGeojson {
  [key: string]: {
    capacity: number;
    geometry: {
      type: TRichItemGeometryType;
      coordinates: number[];
    };
  };
}

export const NDVI_TRESHOLD_LOWEST = 0.2;
export const NDVI_TRESHOLD_LOW = 0.25;
export const NDVI_TRESHOLD_LOWER = 0.3;
export const NDVI_TRESHOLD_MEDIUM = 0.35;
export const NDVI_TRESHOLD_HIGH = 0.4;

export interface INDVIRichItemGeojson {
  [key: string]: {
    commodityID: string;
    coordinates: number[];
    ndvi: number;
    ndviCategory?: ENdviCategory;
  };
}

export enum ENdviCategory {
  Lowest = '0-0.2',
  Low = '0.2-0.25',
  Lower = '0.25-0.3',
  Medium = '0.3-0.35',
  High = '0.35-0.4',
  Highest = '0.4-max'
}
