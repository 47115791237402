import React from 'react';
import styled from 'styled-components';
import LocalLoader from './LocalLoader';

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.4);
  position: absolute;
  z-index: 1;
`;

const MapLoader = () => (
  <Wrapper>
    <LocalLoader />
  </Wrapper>
);

export default MapLoader;
