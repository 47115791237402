import { useCallback } from 'react';
import db from 'services/data/providers/firebase';
import { collection, getDocs } from 'firebase/firestore';

import useLogger, { EStorageType } from 'hooks/logger.hooks';
import { IRegion } from 'models/region';
import { firestoreToArray } from 'utils/helpers';

const useFetchRegions = (): (() => Promise<IRegion[]>) => {
  const { logError } = useLogger();

  return useCallback((): Promise<IRegion[]> => {
    const path = '/regions';

    const regionsCollection = collection(db.firestore, path);
    return getDocs(regionsCollection)
      .then((snap) => firestoreToArray(snap) as IRegion[])
      .catch((e) => {
        logError(e as Error, path, EStorageType.Firestore);
        return Promise.resolve([]);
      });
  }, [logError]);
};

export default useFetchRegions;
