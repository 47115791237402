import { useCallback } from 'react';
import { collection, query, limit, where, getDocs } from 'firebase/firestore';
import { getDownloadURL, ref } from 'firebase/storage';
import db from 'services/data/providers/firebase';
import api from 'services/data/providers/api';

import useLogger, { EStorageType } from 'hooks/logger.hooks';
import { useFetchBucketGeojson } from 'services/data/bucketFiles';

import {
  IRichItem,
  ERichItemType,
  IRichItemsGeojson,
  ICIPORichItemGeojson,
  INDVIRichItemGeojson,
  IRichItemDefaultConfig,
  MAP_BIG_QUERY_TO_RICH_ITEMS,
  IFreeAreaRichItemGeojson,
  ICapacityRichItemGeojson
} from 'models/richItem';
import { IFarmCapacityStatistic, ISimpleStatistic, TAnyStatistic, TFruitYieldStatistic, THealthStatistic } from 'models/statistic';

export interface IRichItemsFilters {
  limit?: number;
  farmID: string;
  groveID?: string;
  surveyID: string;
  richItemTypeName: string;
}

type TFetchByParams = (params: IRichItemsFilters) => Promise<IRichItem[] | null>;

export const useFetchByParams = (): TFetchByParams => {
  const { logError } = useLogger();

  return useCallback(
    async (params: IRichItemsFilters): Promise<IRichItem[] | null> => {
      const path = `/farms/${params.farmID}/rich-items`;
      const feedCollection = collection(db.firestore, path);
      const queryConditions = [
        where('richItemTypeName', '==', params.richItemTypeName),
        ...(params.groveID ? [where('groveID', '==', params.groveID)] : []),
        ...(params.surveyID ? [where('surveyID', '==', params.surveyID)] : [])
      ];

      if (params.limit) {
        queryConditions.push(limit(params.limit));
      }

      try {
        const q = query(feedCollection, ...queryConditions);
        const querySnapshot = await getDocs(q);
        const result: IRichItem[] = [];
        querySnapshot.forEach((doc) => {
          result.push(doc.data() as IRichItem);
        });

        return result;
      } catch (e) {
        logError(e as Error, path, EStorageType.Firestore);
        return Promise.resolve(null);
      }
    },
    [logError]
  );
};

type TFetchStatistic = (farmID: string, surveyID: string, richItemType: ERichItemType, extraParams?: object) => Promise<TAnyStatistic>;

export const useFetchStatistic = (): TFetchStatistic => {
  const { logError } = useLogger();

  return useCallback(
    async (
      farmID: string,
      surveyID: string,
      richItemType: ERichItemType,
      extraParams?: object
    ): Promise<ISimpleStatistic[] | TFruitYieldStatistic | THealthStatistic | IFarmCapacityStatistic | null> => {
      const body = {
        farmID,
        surveyID,
        richItemType: richItemType.replace(/\s/g, '_'),
        isPublished: true,
        ...(extraParams ? { params: extraParams } : {})
      };
      const path = '/getBigQueryStatistic';

      try {
        const response = await api.post<{ data: string | object }>(path, body);
        return typeof response.data === 'string' ? JSON.parse(response.data) : response.data;
      } catch (e) {
        logError(e as Error, path, EStorageType.Api);
        return Promise.resolve(null);
      }
    },
    [logError]
  );
};

type TAnyRichItemGeojson = ICIPORichItemGeojson | INDVIRichItemGeojson | IFreeAreaRichItemGeojson | ICapacityRichItemGeojson;
type TFetchBigQueryGeojson = (farmID: string, groveID: string, surveyID: string, richItemTypeName: ERichItemType) => Promise<TAnyRichItemGeojson | null>;

export const useFetchBigQueryGeojson = (): TFetchBigQueryGeojson => {
  const { logError } = useLogger();

  return useCallback(
    async (farmID: string, groveID: string, surveyID: string, richItemTypeName: ERichItemType): Promise<ICIPORichItemGeojson | null> => {
      const path = '/getBigQueryGroveTreesStatistic';
      const richItemType = MAP_BIG_QUERY_TO_RICH_ITEMS[richItemTypeName];
      const body = { farmID, surveyID, groveID, richItemType };

      try {
        const response = await api.post<{ data: string }>(path, body);
        return JSON.parse(response.data);
      } catch (e) {
        logError(e as Error, path, EStorageType.Api);
        return Promise.resolve(null);
      }
    },
    [logError]
  );
};

type TFetchGeojson = (richItem: IRichItem) => Promise<IRichItemsGeojson | null>;

export const useFetchGeojson = (): TFetchGeojson => {
  const { logError } = useLogger();
  const fetchBucketGeojson = useFetchBucketGeojson();

  return useCallback(
    async (richItem: IRichItem): Promise<IRichItemsGeojson | null> => {
      if (!richItem.compressedGeojsonPath) {
        if (richItem.geojsonPath) {
          return fetchBucketGeojson(richItem.geojsonPath);
        } else {
          return Promise.resolve(null);
        }
      } else {
        const downloadUrl = await getDownloadURL(ref(db.storage, richItem.compressedGeojsonPath));
        try {
          const res = await api.get<IRichItemsGeojson>(downloadUrl, false, {});
          return res;
        } catch (e) {
          logError(e as Error, downloadUrl, EStorageType.Api);
          return Promise.resolve(null);
        }
      }
    },
    [logError, fetchBucketGeojson]
  );
};

export const useFetchRichItemDefaultConfigs = (): (() => Promise<IRichItemDefaultConfig[]>) => {
  const { logError } = useLogger();

  return useCallback(async (): Promise<IRichItemDefaultConfig[]> => {
    const path = '/rich-items-config';
    const richItemsConfigsCollection = collection(db.firestore, path);

    try {
      const res = await getDocs(richItemsConfigsCollection).then((snap) => snap.docs.map((doc) => ({ id: doc.id, ...doc.data() } as IRichItemDefaultConfig)));
      return res;
    } catch (e) {
      logError(e as Error, path, EStorageType.Firestore);
      return [];
    }
  }, [logError]);
};

export const useFetchRichItemsLayers = () => {
  const { logError } = useLogger();

  return useCallback(
    async (farmID: string, surveyID: string, groveID?: string): Promise<IRichItem[]> => {
      const path = '/getRichItemLayersHTTP';
      const body = { farmID, surveyID, groveID };

      try {
        const response = await api.post<IRichItem[]>(path, body);

        return response;
      } catch (e) {
        logError(e as Error, path, EStorageType.Api);

        return Promise.resolve([]);
      }
    },
    [logError]
  );
};
