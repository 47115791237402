import { useCallback, useState, useEffect, useMemo } from 'react';

import customerSurveyService from 'services/data/customerSurvey';
import signInHooks from 'hooks/signIn.hooks';
import appConfig from 'config/config.json';
import { ICompany } from 'models/company';
import { useDispatch, useSelector } from 'react-redux';
import { getCompanies, setCompanies } from 'redux/company/companySlice';

const useGetCompanies = () => {
  const dispatch = useDispatch();

  const fetchCompanies = useCallback(async (): Promise<void> => {
    try {
      dispatch(setCompanies(await customerSurveyService.getCustomers()));
    } catch {
      dispatch(setCompanies([]));
    }
  }, [dispatch]);

  useEffect(() => {
    fetchCompanies();
  }, [fetchCompanies]);
};

const useGetUserCompanies = (): ICompany[] => {
  const companies = useSelector(getCompanies);
  const user = signInHooks.useSignedInUser();

  const userCompanies = useMemo(() => {
    if (!user || !companies.length) {
      return [];
    }
    const userFarmsIDs = Object.keys(user.farms);
    return companies.filter((customer) => userFarmsIDs.find((farmID) => customer.farms[farmID]));
  }, [user, companies]);

  return userCompanies;
};

export interface IMappedDashboard {
  name: string;
  dashboardID: string;
  filters: string[];
}

interface IUserDashboards {
  dashboards: IMappedDashboard[];
  fetchUserDashboards: () => void;
}

export const TREE_ANALYTICS = 'Tree Analytics';
export const TREE_ANALYTICS_DASHBOARD_ID = '60c7199b0b2a20002db2822b';
const defaultDashboards = [
  {
    name: TREE_ANALYTICS,
    dashboardID: TREE_ANALYTICS_DASHBOARD_ID,
    filters: ['customerName', 'farmName', 'customerSurveyName', 'isPublished_int']
  }
];

const useGetDashboards = (): IUserDashboards => {
  const [userDashboards, setUserDashboards] = useState<IMappedDashboard[]>(defaultDashboards);

  const fetchUserDashboards = useCallback(async (): Promise<void> => {
    try {
      const fields = 'title,filters,type';
      const result = await fetch(`${appConfig.SisenseAPI}/dashboards?fields=${fields}`, { credentials: 'include' }).then((response) => response.json());
      const dashboards = result.map((item) => {
        const filters = item.filters.map((filter) => filter?.jaql?.title).filter((filter) => filter);

        return {
          name: item.title,
          dashboardID: item.oid,
          filters
        };
      });

      setUserDashboards(dashboards);
    } catch {
      setUserDashboards([]);
    }
  }, []);

  return { dashboards: userDashboards, fetchUserDashboards };
};

const dashboardHooks = {
  useGetCompanies,
  useGetUserCompanies,
  useGetDashboards
};

export default dashboardHooks;
