import React, { useEffect, useRef, useMemo, useState, useCallback } from 'react';
import { Chart } from 'chart.js';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { IMission } from 'models/mission';
import { IFeed, EHLBStatus, severityBackgroundMap } from 'models/feed';
import { IGrove } from 'models/grove';

import numberUtils from 'utils/numbers';
import farmsHooks from 'hooks/farms.hooks';

import { mfColors } from 'vars';

const Wrapper = styled.div``;

const Title = styled.div`
  font-size: 24px;
  margin: 16px 0;
`;

const SubTitle = styled.div`
  font-size: 18px;
  padding: 4px 0;
  margin: 0 0 8px;
  border-bottom: 2px solid ${mfColors.grey};
  width: 900px;
`;

const ChartWrapper = styled.div`
  margin: 16px 0;
  height: 220px;
  max-width: 900px;

  canvas {
    height: 100%;
  }
`;

const Controls = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;
`;

interface IControlProps {
  isDisabled: boolean;
}

const Control = styled.span`
  margin: 0 16px;
  cursor: ${({ isDisabled }: IControlProps) => (isDisabled ? 'default' : 'pointer')};
  opacity: ${({ isDisabled }: IControlProps) => (isDisabled ? 0.6 : 1)};
`;

const chartSeverities = [EHLBStatus.NoSymptoms, EHLBStatus.Sampled, EHLBStatus.F1, EHLBStatus.F2, EHLBStatus.F3, EHLBStatus.F4];

const HLB_YES_STATUSES = [EHLBStatus.F1, EHLBStatus.F2, EHLBStatus.F3, EHLBStatus.F4];
const MIN_GROVES_TO_SHOW = 64;
const GROVE_TO_SHOW_STEP = 10;

interface IGroveWithFeed extends IGrove {
  feed: IFeed[];
  hlbFeed: IFeed[];
}

interface IProps {
  missions: IMission[];
  feed: IFeed[];
}

const HLBDashboard = ({ missions, feed }: IProps) => {
  const farmChartCanvasRef = useRef<HTMLCanvasElement>(null);
  const grovesChartCanvasRef = useRef<HTMLCanvasElement>(null);
  const farmChartRef = useRef<Chart | null>(null);
  const grovesChartRef = useRef<Chart | null>(null);
  const { groves } = farmsHooks.useFarmEntities();
  const [grovesToShow, setGrovesToShow] = useState(MIN_GROVES_TO_SHOW);
  const { t } = useTranslation();

  const missionsGroves = useMemo(() => {
    const groveIDs = missions.reduce((acc: string[], mission) => {
      const missionGroveIDs = Object.keys(mission.groves);
      return [...acc, ...missionGroveIDs.filter((groveID) => !acc.includes(groveID))];
    }, []);
    return groveIDs
      .map((groveID) => {
        const grove = groves.find((grove) => grove.id === groveID);
        if (!grove) return null;

        const groveFeed = feed.filter((entry) => entry.groveID === grove.id);
        const hlbFeed = groveFeed.filter((entry) => HLB_YES_STATUSES.includes(entry?.data?.hlbStatus as EHLBStatus));

        return {
          ...grove,
          feed: groveFeed,
          hlbFeed
        };
      })
      .filter((grove) => !!grove) as IGroveWithFeed[];
  }, [groves, missions, feed]);

  useEffect(() => {
    setGrovesToShow(MIN_GROVES_TO_SHOW);
  }, [missionsGroves]);

  const missionsGrovesToShow = useMemo(() => {
    const result = missionsGroves
      .filter((entry) => !!entry.feed.length)
      .sort((a, b) => b.hlbFeed.length / b.feed.length - a.hlbFeed.length / a.feed.length)
      .slice(0, grovesToShow);
    return result;
  }, [missionsGroves, grovesToShow]);

  const changeGrovesToShow = useCallback(
    (increment: number) => {
      setGrovesToShow((prev) => {
        const newValue = prev + increment;
        if (newValue < MIN_GROVES_TO_SHOW) {
          return MIN_GROVES_TO_SHOW;
        } else if (newValue > missionsGroves.length) {
          return missionsGroves.length;
        }
        return newValue;
      });
    },
    [missionsGroves.length]
  );

  useEffect(() => {
    if (!farmChartCanvasRef.current) return;
    const labels: string[] = [];
    const data: number[] = [];
    const backgrounds: string[] = [];
    const allReportedItems = feed.filter((entry) => chartSeverities.includes(entry?.data?.hlbStatus as EHLBStatus));

    chartSeverities.forEach((severityLevel) => {
      const severityCount = feed.filter((entry) => entry?.data?.hlbStatus === severityLevel).length;
      const percentage = numberUtils.normalizeDecimal(numberUtils.convertToPercentage(severityCount / allReportedItems.length), 2);
      if (severityLevel === EHLBStatus.NoSymptoms) {
        labels.push(t('missions.no_hlb_percentage', { percentage: `${percentage}%` }));
      } else if (severityLevel === EHLBStatus.Sampled) {
        labels.push(t('missions.sampled', { percentage: `${percentage}%` }));
      } else {
        labels.push(t('missions.severity_level_percentage', { level: severityLevel, percentage: `${percentage}%` }));
      }
      data.push(percentage);
      backgrounds.push(severityBackgroundMap.get(severityLevel) || '');
    });

    const config = {
      type: 'pie',
      data: {
        labels,
        datasets: [
          {
            data,
            backgroundColor: backgrounds
          }
        ]
      },
      options: {
        responsive: true,
        tooltips: {
          callbacks: {
            label: (context) => {
              const value = data[context.index];
              return ` ${value}%`;
            }
          }
        },
        plugins: {
          legend: {
            position: 'top'
          },
          title: {
            display: false
          }
        },
        maintainAspectRatio: false
      }
    };

    if (farmChartRef.current) {
      farmChartRef.current.destroy();
    }

    farmChartRef.current = new Chart(farmChartCanvasRef.current, config);
  }, [feed, t]);

  useEffect(() => {
    if (!grovesChartCanvasRef.current) return;
    const labels: string[] = [];
    const noHlbData: number[] = [];
    const hlbData: number[] = [];

    missionsGrovesToShow.forEach((grove: IGroveWithFeed) => {
      const hlbRatio = !grove.feed.length ? 0 : grove.hlbFeed.length / grove.feed.length;

      labels.push(grove.name);
      noHlbData.push(numberUtils.normalizeDecimal(numberUtils.convertToPercentage(1 - hlbRatio), 2));
      hlbData.push(numberUtils.normalizeDecimal(numberUtils.convertToPercentage(hlbRatio), 2));
    });

    const config = {
      type: 'bar',
      data: {
        labels,
        datasets: [
          {
            label: t('missions.hlb_plus'),
            data: hlbData,
            backgroundColor: severityBackgroundMap.get(EHLBStatus.F4),
            stack: 'Stack 0',
            maxBarThickness: 80
          },
          {
            label: t('missions.no_hlb'),
            data: noHlbData,
            backgroundColor: severityBackgroundMap.get(EHLBStatus.NoSymptoms),
            stack: 'Stack 0',
            maxBarThickness: 80
          }
        ]
      },
      options: {
        tooltips: {
          callbacks: {
            label: (context) => `${t(context.datasetIndex === 0 ? 'missions.no_hlb' : 'missions.hlb_plus')}: ${context.yLabel} %`
          }
        },
        plugins: {
          title: {
            display: false
          }
        },
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          yAxes: [
            {
              stacked: true,
              ticks: {
                callback: (value: number) => `${value}%`
              }
            }
          ],
          xAxes: [
            {
              ticks: {
                autoSkip: false,
                callback: (value: string) => {
                  if (missionsGrovesToShow.length > MIN_GROVES_TO_SHOW) return '';
                  return value;
                }
              }
            }
          ]
        }
      }
    };

    if (grovesChartRef.current) {
      grovesChartRef.current.destroy();
    }

    grovesChartRef.current = new Chart(grovesChartCanvasRef.current, config);
  }, [missionsGrovesToShow, feed, t]);

  return (
    <Wrapper>
      <Title>{t('missions.percentage_of_hlb_contamination_levels')}</Title>
      <SubTitle>{t('missions.in_the_entire_farm')}</SubTitle>
      <ChartWrapper>
        <canvas ref={farmChartCanvasRef} />
      </ChartWrapper>
      <SubTitle>{t('missions.per_grove')}</SubTitle>
      <ChartWrapper>
        <canvas ref={grovesChartCanvasRef} />
        {missionsGroves.length > MIN_GROVES_TO_SHOW && (
          <Controls>
            <Control onClick={() => grovesToShow > MIN_GROVES_TO_SHOW && changeGrovesToShow(-GROVE_TO_SHOW_STEP)} isDisabled={grovesToShow <= MIN_GROVES_TO_SHOW}>
              {t('shared.see_less')}
            </Control>
            <Control
              onClick={() => grovesToShow < missionsGroves.length && changeGrovesToShow(GROVE_TO_SHOW_STEP)}
              color="primary"
              isDisabled={grovesToShow >= missionsGroves.length}
            >
              {t('shared.see_more')}
            </Control>
          </Controls>
        )}
      </ChartWrapper>
    </Wrapper>
  );
};

export default HLBDashboard;
