import { Map, AnyLayer } from 'mapbox-gl';
import {
  addLayerToMap,
  removeMapLayerById,
  removeMapSourceById,
  reorderLayers,
  LAYERS_PRIORITY,
  IRRIGATION_POINTS,
  IRRIGATION_LINES,
  IRRIGATION_POLYGONS,
  IRRIGATION_LAYERS
} from 'services/util/mapHelpers';
import { TIrrigationSourceType } from 'models/irrigation';
import { ITerrainGeojson } from 'models/terrain';

export const IRRIGATION_POINTS_SOURCE = 'irrigation_points';
export const IRRIGATION_LINES_SOURCE = 'irrigation_lines';
export const IRRIGATION_POLYGONS_SOURCE = 'irrigation_polygons';

const defaultIrrigation = [
  {
    id: IRRIGATION_POLYGONS,
    source: IRRIGATION_POLYGONS_SOURCE,
    type: 'fill',
    paint: {
      'fill-color': ['match', ['get', 'Layer'], 'BOREHOLE DETAILS', 'red', 'ARROWS', 'red', '#0080ff'], // blue color fill
      'fill-opacity': 0.5
    }
  },
  {
    id: IRRIGATION_LINES,
    source: IRRIGATION_LINES_SOURCE,
    type: 'line',
    paint: {
      'line-color': [
        'match',
        ['get', 'Layer'],
        'CONTOURS',
        'black',
        'EXISTING 160mm PIPE',
        'blue',
        'EXISTING 250mm PIPE',
        'blue',
        'EXISTING 200mm PIPE',
        'blue',
        'EXISTING 110mm PIPE',
        'blue',
        'EXISTING MAIN LINE 150mm AC',
        'blue',
        'EXISTING MAIN LINE 125mm AC',
        'blue',
        'EXISTING MAIN LINE 250mm AC',
        'blue',
        'EXISTING MAIN LINE 300mm AC',
        'blue',
        'EXISTING MAIN LINE 450mm PVC',
        'blue',
        'EXISTING 400mm STEEL PIPE',
        'blue',
        'EXSITING 300mm PIPE',
        'blue',
        'BLOCKS',
        'brown',
        'BOREHOLE DETAILS',
        'red',
        'black'
      ],
      'line-width': 3
    }
  },
  {
    id: IRRIGATION_POINTS,
    source: IRRIGATION_POINTS_SOURCE,
    type: 'symbol',
    layout: {
      'text-field': ['get', 'Text']
    }
  }
];

const geojsonMap: { [key: string]: TIrrigationSourceType } = {
  [IRRIGATION_POLYGONS]: 'polygons',
  [IRRIGATION_LINES]: 'lines',
  [IRRIGATION_POINTS]: 'points'
};

export const addIrrigationLayers = (map: Map, geoJSON: { [key: string]: ITerrainGeojson }): void => {
  IRRIGATION_LAYERS.forEach((layer) => {
    removeMapLayerById(map, layer);
  });
  removeMapSourceById(map, IRRIGATION_POINTS_SOURCE);
  removeMapSourceById(map, IRRIGATION_LINES_SOURCE);
  removeMapSourceById(map, IRRIGATION_POLYGONS_SOURCE);

  defaultIrrigation.forEach((layer) => {
    const geojson = geoJSON[geojsonMap[layer.id]];
    map.addSource(layer.source, {
      data: geojson,
      type: 'geojson'
    });

    addLayerToMap(map, layer.id, layer as AnyLayer);
  });

  reorderLayers(
    map,
    LAYERS_PRIORITY.map((layer, index) => ({
      name: layer,
      zIndex: index
    }))
  );
};

export const removeIrrigationLayers = (map: Map): void => {
  IRRIGATION_LAYERS.forEach((layer) => {
    removeMapLayerById(map, layer);
  });
  removeMapSourceById(map, IRRIGATION_POINTS_SOURCE);
  removeMapSourceById(map, IRRIGATION_LINES_SOURCE);
  removeMapSourceById(map, IRRIGATION_POLYGONS_SOURCE);
};
