import React, { useEffect, useState, useCallback, useMemo } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { mfColors } from 'vars';

import CompareReportsChart from 'components/compare/CompareReportsChart';
import CompareReportsRange from 'components/compare/CompareReportsRange';
import { getPaintPropertyName } from 'utils/helpers';
import { IUnifiedGeojson } from 'hooks/farms.hooks';
import { ISurveyWithTimeRange } from 'models/survey';
import { IRichItemFeature, ERichItemType } from 'models/richItem';

const Wrapper = styled.div`
  display: flex;
  background: ${mfColors.superLightBlue};
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.5);
  padding: 24px;
  border-radius: 8px 8px 0 0;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Title = styled.div`
  font-family: MontserratBold;
  font-size: 14px;
`;

const Description = styled.div`
  font-size: 14px;
`;

interface IProps {
  leftRichItemGeoJSON: IUnifiedGeojson;
  rightRichItemGeoJSON: IUnifiedGeojson;
  leftSurvey: ISurveyWithTimeRange;
  rightSurvey: ISurveyWithTimeRange;
  onFilteredTreesChange: (treeIDs: string[]) => void;
  richItem: ERichItemType | null;
}

const REPORT_PERCENTAGE_TRESHOLD = 1;

const CompareGraph = ({ leftRichItemGeoJSON, rightRichItemGeoJSON, leftSurvey, rightSurvey, onFilteredTreesChange, richItem }: IProps): JSX.Element => {
  const { t } = useTranslation();
  const [values, setValues] = useState<[string, string[]][]>([]);

  useEffect(() => {
    const leftPropName = richItem !== ERichItemType.Ndvi ? getPaintPropertyName(leftRichItemGeoJSON) : 'ndvi';
    const rightPropName = richItem !== ERichItemType.Ndvi ? getPaintPropertyName(rightRichItemGeoJSON) : ERichItemType.Ndvi;

    if (!leftPropName || !rightPropName || leftPropName !== rightPropName) return;

    const leftTrees = leftRichItemGeoJSON.geojson.features.reduce((acc: { [key: string]: IRichItemFeature }, tree: IRichItemFeature) => {
      acc[tree.properties.id] = tree;
      return acc;
    }, {});
    const rightTrees = rightRichItemGeoJSON.geojson.features.reduce((acc: { [key: string]: IRichItemFeature }, tree: IRichItemFeature) => {
      acc[tree.properties.id] = tree;
      return acc;
    }, {});

    const leftSurveyDate = leftSurvey.createdAt;
    const rightSurveyDate = rightSurvey.createdAt;
    const rightTreesIDs = Object.keys(rightTrees);
    const leftTreesIDs = Object.keys(leftTrees);

    const featureIds = leftTreesIDs.filter((key) => rightTreesIDs.includes(key));

    const values = featureIds.reduce((acc: { [key: string]: string[] }, id) => {
      const left = leftTrees[id].properties[leftPropName] as number;
      const right = rightTrees[id].properties[rightPropName] as number;

      if (!left || !right) return acc;

      let diff = 0;
      let percentage = 0;

      if (rightSurveyDate > leftSurveyDate) {
        diff = right - left;
        percentage = left !== 0 ? diff / left : diff;
      } else {
        diff = left - right;
        percentage = right !== 0 ? diff / right : diff;
      }

      percentage = Math.ceil(percentage * 100);
      acc[percentage] = [...(acc[percentage] || []), id];

      return acc;
    }, {});

    const entries = Object.entries(values);

    if (!entries.length) {
      setValues([]);
      return;
    }

    const amounts = entries.map(([percentage, values]) => values.length);
    const maxAmount = Math.max(...amounts);

    setValues(
      entries
        .filter(([perc, values]) => {
          const percentage = Math.ceil((values.length / maxAmount) * 100);

          return Number(percentage) > REPORT_PERCENTAGE_TRESHOLD;
        })
        .sort(([a], [b]) => Number(a) - Number(b))
    );
  }, [leftSurvey, rightSurvey, leftRichItemGeoJSON, rightRichItemGeoJSON, richItem]);

  const range = useMemo(() => {
    if (!values.length) return { min: 0, max: 0 };

    const min = parseInt(values[0][0]);
    const max = parseInt(values[values.length - 1][0]);

    return {
      min,
      max
    };
  }, [values]);

  const onValuesChanged = useCallback(
    (range: number[]) => {
      const [from, to] = range;
      const fromIndex = values.findIndex((value) => value[0] === from.toString());
      const toIndex = values.findIndex((value) => value[0] === to.toString());
      const filteredTrees = values
        .map((value) => value[value.length - 1])
        .slice(fromIndex, toIndex + 1)
        .flat();
      onFilteredTreesChange(filteredTrees);
    },
    [values, onFilteredTreesChange]
  );

  return (
    <Wrapper>
      <Title>{t('compare.delta')}</Title>
      <Description>{t('compare.distributes_the_trees_in_the_groves_over_the_%_of_change')}</Description>
      <CompareReportsChart values={values} />
      {!!range.min && !!range.max && <CompareReportsRange min={range.min} max={range.max} onValuesChanged={onValuesChanged} />}
    </Wrapper>
  );
};

export default CompareGraph;
