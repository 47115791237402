import React, { useMemo, useCallback } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck, faXmark } from '@fortawesome/pro-regular-svg-icons';

import DataTable from 'atomicComponents/DataTable';
import MissionIcon from 'components/missions/MissionIcon';

import missionUtils from 'utils/mission';
import missionHooks from 'hooks/mission.hooks';
import farmsHooks from 'hooks/farms.hooks';

import { IMission, EMissionSubtype } from 'models/mission';
import { IGrove } from 'models/grove';
import { IFeed, IDecoratedFeed, EFeedStatus, NOT_APPLICABLE_STATUSES } from 'models/feed';

import { mfColors } from 'vars';
import feedUtils from 'utils/feed';

const Wrapper = styled.div``;

const TableWrapper = styled.div`
  max-height: 320px;
  overflow: auto;
  width: 960px;
`;

const TableCell = styled.div`
  background: ${mfColors.white};
  padding: 4px;
  min-height: 32px;
  font-size: 12px;
  line-height: 24px;
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;

  &.left {
    text-align: left;
  }
`;

interface ITotalProps {
  width: string;
}

const Total = styled.div`
  font-family: MontserratBold;
  font-size: 14px;
  padding: 8px 0;
  margin: 8px 0 0 36px;
  width: ${({ width }: ITotalProps) => width};
  border-bottom: 2px solid ${mfColors.darkGrey};
  display: flex;
  flex-direction: row;

  .column {
    width: 176px;
    margin: 0 4px 0 0;
    flex-shrink: 0;
    text-align: center;

    &.large {
      width: 206px;
    }
  }

  .title {
    padding: 0 4px;
    text-align: left;
    text-transform: uppercase;
  }
`;

const ROW_STYLE = {
  border: 'none',
  background: 'none',
  margin: '0 0 4px'
};

const HEADER_STYLE = {
  border: 'none',
  background: 'none',
  fontSize: '12px',
  margin: '0 0 8px'
};

const HEADER_COLUMN_STYLE = {
  textTransform: 'none',
  minHeight: '0px',
  padding: '0 2px'
};

interface IGroveWithTrees extends IGrove {
  total: number;
  resolved: number;
  counted: number;
  crownTrees: number;
  feed: IFeed[];
}

interface IProps {
  missions: IMission[];
  feed: IDecoratedFeed[];
}

const FruitCountHeaderDetails = ({ missions, feed }: IProps) => {
  const { t } = useTranslation();
  const feedByGrove = missionHooks.useFeedByGrove<IDecoratedFeed>(feed);
  const { groves, selectedSurveyStats: surveyStats, grovesSurveysStats } = farmsHooks.useFarmEntities();

  const getTotalTreesCount = useCallback(
    (grove: IGrove): number => {
      if (missions.length > 1) return missionUtils.getTreesSum([grove], surveyStats, grovesSurveysStats);
      return missions.reduce((acc: number, mission) => {
        const missionFeed = feedByGrove[grove.id]?.filter((entry) => entry.missionID === mission.id) || [];
        const result = mission.isPreassigned ? missionFeed.filter((entry) => entry.isPreassigned).length : missionUtils.getTreesSum([grove], surveyStats, grovesSurveysStats);
        return acc + result;
      }, 0);
    },
    [missions, surveyStats, grovesSurveysStats, feedByGrove]
  );

  const grovesToShow = useMemo(() => {
    const groveIDs = missions.reduce((acc: string[], mission) => {
      const missionGroveIDs = Object.keys(mission.groves);
      return [...acc, ...missionGroveIDs.filter((groveID) => !acc.includes(groveID))];
    }, []);
    return groveIDs
      .map((groveID) => {
        const grove = groves.find((grove) => grove.id === groveID);
        if (!grove) return null;

        return {
          ...grove,
          total: getTotalTreesCount(grove),
          resolved: feedByGrove[grove.id]?.filter((entry) => entry.status === EFeedStatus.Done)?.length || 0,
          counted: feedByGrove[grove.id]?.filter((entry) => entry.fruitCountStatus === 'isCounted')?.length || 0,
          crownTrees: feedByGrove[grove.id]?.filter((entry) => entry.fruitCountStatus === 'crownTree')?.length || 0,
          feed: feedByGrove[grove.id] || []
        };
      })
      .filter((grove) => !!grove) as IGroveWithTrees[];
  }, [groves, missions, getTotalTreesCount, feedByGrove]);

  const totalResolvedTrees = useMemo(
    () => grovesToShow.reduce((acc, grove) => acc + feedByGrove[grove.id]?.filter((entry) => entry.status === EFeedStatus.Done).length || 0, 0),
    [grovesToShow, feedByGrove]
  );

  const totalCountedTrees = useMemo(() => grovesToShow.reduce((acc, grove) => acc + grove.counted, 0), [grovesToShow]);

  const totalCrownTrees = useMemo(() => grovesToShow.reduce((acc, grove) => acc + grove.crownTrees, 0), [grovesToShow]);

  const totalTreesCount = useMemo(() => grovesToShow.reduce((acc: number, grove: IGroveWithTrees) => acc + grove.total, 0), [grovesToShow]);

  const totalNonApplicableTrees = useMemo(() => {
    const result = grovesToShow.reduce((acc, grove) => {
      const nonApplicableTrees = grove.feed.filter((entry) => entry.data?.fruitCountStatus && NOT_APPLICABLE_STATUSES.includes(entry.data?.fruitCountStatus)).length;
      return acc + nonApplicableTrees;
    }, 0);
    return result;
  }, [grovesToShow]);

  const totalFruitCount = useMemo(() => {
    const result = grovesToShow.reduce((acc: number, grove) => acc + feedUtils.sumFruitCountNumber(grove.feed), 0);
    return result;
  }, [grovesToShow]);

  const avgFruitPerGroves = useMemo(() => {
    const fruitsPerGroves = grovesToShow
      .map((grove) => {
        const totalFruits = feedUtils.sumFruitCountNumber(grove.feed);
        const totalCountTrees = feedUtils.filterNotApplicable(grove.feed).length;
        return totalCountTrees > 0 ? Math.floor(totalFruits / totalCountTrees) : 0;
      })
      .filter((fruitCount) => !!fruitCount);
    const totalFruits = fruitsPerGroves.reduce((acc, fruitCount) => acc + fruitCount, 0);

    return fruitsPerGroves.length > 0 ? Math.floor(totalFruits / fruitsPerGroves.length) : 0;
  }, [grovesToShow]);

  const avgFruitPerTrees = useMemo(() => {
    const totalFruits = missionUtils.getTotalFruitCount(feed);
    return feed.length > 0 ? Math.floor(totalFruits / feed.length) : 0;
  }, [feed]);

  const tableConfig = useMemo(() => {
    const mission = missions[0];

    const iconColumn = {
      title: '',
      render: () => (
        <TableCell>
          <MissionIcon type={mission.type} size="24px" showBackground={false} />
        </TableCell>
      ),
      key: 'icon',
      sortable: false,
      width: '32px',
      style: {
        ...HEADER_COLUMN_STYLE,
        padding: '0px',
        margin: '0 2px 0 0'
      }
    };

    const titleColumn = {
      title: t('shared.grove'),
      render: (grove: IGrove) => <TableCell className="left">{grove.name}</TableCell>,
      key: 'name',
      sortable: false,
      width: '180px',
      style: {
        ...HEADER_COLUMN_STYLE,
        justifyContent: 'flex-start'
      }
    };

    const treesNumberColumn = {
      title: t('missions.number_of_trees'),
      render: (grove: IGroveWithTrees) => <TableCell>{`${grove.resolved.toLocaleString()} / ${grove.total.toLocaleString()}`}</TableCell>,
      key: 'trees',
      sortable: false,
      width: '180px',
      style: {
        ...HEADER_COLUMN_STYLE,
        textAlign: 'center'
      }
    };

    const countedTreesNumberColumn = {
      title: t('missions.counted'),
      render: (grove: IGroveWithTrees) => <TableCell>{`${grove.counted.toLocaleString()} / ${grove.total.toLocaleString()}`}</TableCell>,
      key: 'countedTrees',
      sortable: false,
      width: '180px',
      style: {
        ...HEADER_COLUMN_STYLE,
        textAlign: 'center'
      }
    };

    const crownTreesNumberColumn = {
      title: t('missions.crown_trees'),
      render: (grove: IGroveWithTrees) => <TableCell>{grove.crownTrees.toLocaleString()}</TableCell>,
      key: 'countedTrees',
      sortable: false,
      width: '180px',
      style: {
        ...HEADER_COLUMN_STYLE,
        textAlign: 'center'
      }
    };

    const fruitPerGroveColumn = {
      title: t('missions.total_fruits_counted'),
      render: (grove: IGroveWithTrees) => <TableCell>{feedUtils.sumFruitCountNumber(grove.feed).toLocaleString()}</TableCell>,
      key: 'fruits',
      sortable: false,
      width: '180px',
      style: HEADER_COLUMN_STYLE
    };

    const fruitPerTreeColumn = {
      title: t('missions.fruits_counted_trees_counted'),
      render: (grove: IGroveWithTrees) => {
        const totalFruits = feedUtils.sumFruitCountNumber(grove.feed);
        const totalCountTrees = feedUtils.filterNotApplicable(grove.feed).length;
        return <TableCell>{grove.feed.length > 0 ? Math.floor(totalFruits / totalCountTrees).toLocaleString() : 0}</TableCell>;
      },
      key: 'avg_fruits',
      sortable: false,
      width: '210px',
      style: HEADER_COLUMN_STYLE
    };

    const notApplicableTreesColumn = {
      title: t('missions.not_applicable_trees'),
      render: (grove: IGroveWithTrees) => {
        const groveEntries = grove.feed.filter((entry) => entry.data?.fruitCountStatus && NOT_APPLICABLE_STATUSES.includes(entry.data?.fruitCountStatus));
        return <TableCell>{groveEntries.length}</TableCell>;
      },
      key: 'not_applicable_trees',
      sortable: false,
      width: '180px',
      style: HEADER_COLUMN_STYLE
    };

    const allReportedNextToTreeColumn = {
      title: t('missions.all_reported_next_to_tree'),
      render: (grove: IGroveWithTrees) => {
        const hasReportedFarFromTree = !!grove.feed.find((entry) => entry.isFarFromReport);

        return <TableCell>{<FontAwesomeIcon icon={!hasReportedFarFromTree ? faCircleCheck : faXmark} />}</TableCell>;
      },
      key: 'all_reported_next_to_tree',
      sortable: false,
      width: '180px',
      style: HEADER_COLUMN_STYLE
    };

    if (mission.subType === EMissionSubtype.CountingFruits) {
      return [iconColumn, titleColumn, treesNumberColumn, fruitPerGroveColumn, fruitPerTreeColumn];
    } else if (mission.subType === EMissionSubtype.MarkAsCounted) {
      return [iconColumn, titleColumn, countedTreesNumberColumn, crownTreesNumberColumn, notApplicableTreesColumn, allReportedNextToTreeColumn];
    } else {
      return [iconColumn, titleColumn, treesNumberColumn, fruitPerTreeColumn];
    }
  }, [t, missions]);

  const tableProps = useMemo(() => {
    const props = {
      config: tableConfig,
      data: grovesToShow,
      showHeader: true,
      showFooter: false,
      rowsPerPage: 0,
      headerStyle: HEADER_STYLE,
      rowStyle: ROW_STYLE
    };
    return props;
  }, [tableConfig, grovesToShow]);

  const totalWidth = useMemo(() => {
    if (missions[0]?.subType === EMissionSubtype.MarkAsCounted) return '892px';
    return missions[0]?.subType === EMissionSubtype.BloomMonth ? '564px' : '744px';
  }, [missions]);

  return (
    <Wrapper>
      <TableWrapper>
        <DataTable<IGroveWithTrees> {...tableProps} />
      </TableWrapper>
      <Total width={totalWidth}>
        <div className="column title">{t('shared.total')}</div>
        {missions[0]?.subType === EMissionSubtype.MarkAsCounted && <div className="column">{`${totalCountedTrees.toLocaleString()} / ${totalTreesCount.toLocaleString()}`}</div>}
        {missions[0]?.subType === EMissionSubtype.MarkAsCounted && <div className="column">{totalCrownTrees.toLocaleString()}</div>}
        {missions[0]?.subType !== EMissionSubtype.MarkAsCounted && <div className="column">{`${totalResolvedTrees.toLocaleString()} / ${totalTreesCount.toLocaleString()}`}</div>}
        {missions[0]?.subType === EMissionSubtype.BloomMonth && <div className="column large">{avgFruitPerTrees.toLocaleString()}</div>}
        {missions[0]?.subType === EMissionSubtype.CountingFruits && <div className="column">{totalFruitCount.toLocaleString()}</div>}
        {missions[0]?.subType === EMissionSubtype.CountingFruits && <div className="column large">{avgFruitPerGroves.toLocaleString()}</div>}
        {missions[0]?.subType === EMissionSubtype.MarkAsCounted && <div className="column">{totalNonApplicableTrees.toLocaleString()}</div>}
      </Total>
    </Wrapper>
  );
};

export default FruitCountHeaderDetails;
