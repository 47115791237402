import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import StaticCard from 'atomicComponents/StaticCard';
import { IGrove } from 'models/grove';
import { ISurvey } from 'models/survey';
import { IGroveSurveyStats } from 'models/stats';
import { HEALTHY, NOT_BEARING, NOT_PRODUCING, UNDER_PERFORMING, SCORES } from 'models/scores';
import { SCORE_BLIND_COLORS, SCORE_COLORS } from 'models/colors';
import { mfColors, mfShadows } from 'vars';
import { TMappedStatistic } from 'models/statistic';
import TreeCapacity from './TreeCapacity';

const Main = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  margin: 0 0 16px;
`;

interface IMainColumnProps {
  active: boolean;
}

const MainColumn = styled.div`
  width: 98px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  padding: 8px 4px;
  cursor: pointer;
  background-color: ${({ active }: IMainColumnProps) => (active ? 'rgba(49, 69, 106, 0.1)' : 'transparent')};

  &:hover {
    background: rgba(49, 69, 106, 0.1);
  }
`;

const Footer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: relative;
`;

const Separator = styled.div`
  position: absolute;
  width: 2px;
  height: 100%;
  top: 0;
  left: 50%;
  margin-left: -1px;
  background: ${mfColors.grey};
`;

interface IFooterColumnProps {
  active?: boolean;
}

const FooterColumn = styled.div`
  font-size: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  max-width: calc(50% - 4px);
  border-radius: 4px;
  cursor: pointer;
  background-color: ${({ active }: IFooterColumnProps) => (active ? 'rgba(49, 69, 106, 0.1)' : 'transparent')};

  &:first-child {
    padding: 0 4px 0 2px;
  }
  &:last-child {
    padding: 2px 6px;
  }
  &:hover {
    background: rgba(49, 69, 106, 0.1);
  }
`;

const FooterLabel = styled.span`
  margin: 0 0 0 4px;
  color: ${mfColors.grey};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: capitalize;
`;

const Flex = styled.div`
  display: flex;
`;

const Label = styled.div`
  text-align: center;
  font-size: 10px;
  text-transform: capitalize;
`;

const Title = styled.div`
  font-size: 16px;
  font-family: MontserratBold;
`;

const ScoreBadge = styled.div`
  width: 9px;
  height: 9px;
  right: 7px;
  border-radius: 100%;
  background: ${(props) => props.color};
  margin-right: 4px;
`;

interface IProps {
  selectedSurvey: ISurvey;
  selectedGrove: IGrove | null;
  onHighlightedScores: (scores: number[]) => void;
  colorBlind: boolean;
  dataCardOpened: boolean;
  selectedSurveyStats: IGroveSurveyStats[];
  onCardOpened: (dataCardOpened: boolean) => void;
  highlightedScores: number[];
  statistic: TMappedStatistic | null;
  capacity?: number | null;
}

const TreeStats = ({
  statistic,
  selectedSurvey,
  selectedSurveyStats,
  selectedGrove,
  onHighlightedScores,
  colorBlind,
  dataCardOpened,
  onCardOpened,
  highlightedScores,
  capacity
}: IProps): JSX.Element => {
  const { t } = useTranslation();
  const areaStats = useMemo(() => {
    if (!statistic) {
      return null;
    }

    const groveIDs = Object.keys(statistic).filter((groveID) => !!selectedSurveyStats.find((entry) => entry.groveID === groveID)?.isPublished);

    const sumScores = (scores: number[], stat) => scores.reduce((val: number, score) => (stat[score] ? val + stat[score] : val), 0);
    const trees = groveIDs.reduce((acc: number, groveID) => acc + sumScores(SCORES, statistic[groveID]), 0);
    const bearingTrees = groveIDs.reduce((acc: number, groveID) => acc + sumScores(HEALTHY, statistic[groveID]), 0);
    const nonBearingTrees = groveIDs.reduce((acc: number, groveID) => acc + sumScores(NOT_BEARING, statistic[groveID]), 0);
    const underperformingTrees = groveIDs.reduce((acc: number, groveID) => acc + sumScores(UNDER_PERFORMING, statistic[groveID]), 0);
    const nonProducing = groveIDs.reduce((acc: number, groveID) => acc + sumScores(NOT_PRODUCING, statistic[groveID]), 0);

    return {
      trees,
      bearingTrees,
      nonBearingTrees,
      underperformingTrees,
      nonProducing
    };
  }, [statistic, selectedSurveyStats]);
  const scoreColors = useMemo(() => (colorBlind ? SCORE_BLIND_COLORS : SCORE_COLORS), [colorBlind]);
  const isHighlighted = (scores: number[]) => scores.every((s) => highlightedScores.includes(s));

  const handleMouseLeave = () => {
    if (dataCardOpened || !selectedGrove) return;

    onHighlightedScores([]);
  };

  const handleMouseEnter = (scores: number[]) => {
    if (dataCardOpened || !selectedGrove) return;

    onHighlightedScores(scores);
  };

  const handleClick = (scores: number[]) => {
    const isAllHighlighted = scores.every((s: number) => highlightedScores.includes(s));
    let scoresToHighlight;

    if (isAllHighlighted) {
      scoresToHighlight = highlightedScores.filter((s: number) => !scores.includes(s));
    } else {
      scoresToHighlight = [...highlightedScores, ...scores];
    }

    if (!dataCardOpened) {
      scoresToHighlight = highlightedScores;
    }

    if (!scoresToHighlight.length && !highlightedScores.length) {
      scoresToHighlight = scores;
    }

    if (!scoresToHighlight.length) {
      onCardOpened(false);
    } else {
      onCardOpened(true);
    }

    onHighlightedScores(scoresToHighlight);
  };

  if (!areaStats) {
    return <></>;
  }

  return (
    <StaticCard
      padding="24px 12px 12px"
      width="100%"
      shadow={mfShadows.timelineShadow}
      background={mfColors.white}
      content={
        <>
          <Main>
            <MainColumn onClick={() => handleClick(HEALTHY)} onMouseEnter={() => handleMouseEnter(HEALTHY)} onMouseLeave={handleMouseLeave} active={isHighlighted(HEALTHY)}>
              <Title>{areaStats.bearingTrees.toLocaleString()}</Title>
              <Label>{t('shared.healthy')}</Label>
              <Flex>
                {HEALTHY.map((score) => (
                  <ScoreBadge key={score} color={scoreColors[score].fill} />
                ))}
              </Flex>
            </MainColumn>
            <MainColumn
              onClick={() => handleClick(UNDER_PERFORMING)}
              onMouseEnter={() => handleMouseEnter(UNDER_PERFORMING)}
              onMouseLeave={handleMouseLeave}
              active={isHighlighted(UNDER_PERFORMING)}
            >
              <Title>{areaStats.underperformingTrees.toLocaleString()}</Title>
              <Label>{t('main.info.underperforming')}</Label>
              <Flex>
                {UNDER_PERFORMING.map((score) => (
                  <ScoreBadge key={score} color={scoreColors[score].fill} />
                ))}
              </Flex>
            </MainColumn>
            <MainColumn
              onClick={() => handleClick(NOT_PRODUCING)}
              onMouseEnter={() => handleMouseEnter(NOT_PRODUCING)}
              onMouseLeave={handleMouseLeave}
              active={isHighlighted(NOT_PRODUCING)}
            >
              <Title>{areaStats.nonProducing.toLocaleString()}</Title>
              <Label>{t('main.info.non_producing')}</Label>
              <Flex>
                {NOT_PRODUCING.map((score) => (
                  <ScoreBadge key={score} color={scoreColors[score].fill} />
                ))}
              </Flex>
            </MainColumn>
          </Main>
          <Footer>
            <FooterColumn onClick={() => onHighlightedScores(SCORES)}>
              <span>{areaStats.trees.toLocaleString()}</span>
              <FooterLabel>{t('main.info.total_scores')}</FooterLabel>
            </FooterColumn>
            <Separator />
            <FooterColumn
              onClick={() => handleClick(NOT_BEARING)}
              onMouseEnter={() => handleMouseEnter(NOT_BEARING)}
              onMouseLeave={handleMouseLeave}
              active={isHighlighted(NOT_BEARING)}
            >
              {NOT_BEARING.map((score) => (
                <ScoreBadge key={score} color={scoreColors[score].fill} />
              ))}
              <span>{areaStats.nonBearingTrees.toLocaleString()}</span>
              <FooterLabel>{t('main.info.non_bearing')}</FooterLabel>
            </FooterColumn>
          </Footer>
          {typeof capacity === 'number' && <TreeCapacity capacity={capacity} />}
        </>
      }
    />
  );
};

export default TreeStats;
