import React from 'react';
import styled from 'styled-components';

import Dropdown, { EToggleType, EPosition } from 'atomicComponents/Dropdown';

import { mfColors } from 'vars';

type TPosition = EPosition.TopRight | EPosition.BottomRight | EPosition.TopLeft;

interface IArrowProps {
  position: TPosition;
}

const Arrow = styled.div`
  position: absolute;
  bottom: ${({ position }: IArrowProps) => (position === EPosition.TopRight || position === EPosition.TopLeft ? '-24px' : 'auto')};
  top: ${({ position }: IArrowProps) => (position === EPosition.TopRight || position === EPosition.TopLeft ? 'auto' : '-24px')};
  left: ${({ position }: IArrowProps) => (position === EPosition.TopRight ? '12px' : 'auto')};
  right: ${({ position }: IArrowProps) => (position === EPosition.TopRight ? 'auto' : '12px')};
  width: 0;
  height: 0;
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
  border-bottom: 12px solid transparent;
  border-top: 12px solid ${mfColors.white};
  transform: ${({ position }: IArrowProps) => (position === EPosition.TopRight || position === EPosition.TopLeft ? 'none' : 'rotate(180deg)')};
`;

const ContentWrapper = styled.span`
  font-size: 14px;
  max-width: 240px;
`;

const DROPDOWN_OFFSET = { x: -32, y: 16 };
const DROPDOWN_STYLE = {
  background: mfColors.white,
  boxShadow: `-5px -5px 10px ${mfColors.black40}, 5px 5px 10px ${mfColors.black40}`
};

interface IProps {
  control: JSX.Element;
  content: JSX.Element | string;
  offset?: { x: number; y: number };
  position?: TPosition;
}

const Popover = ({ control, content, offset = DROPDOWN_OFFSET, position = EPosition.TopRight }: IProps): JSX.Element => (
  <Dropdown
    arrow={<Arrow position={position} />}
    style={DROPDOWN_STYLE}
    toggleType={EToggleType.Mouseover}
    control={control}
    offset={offset}
    content={<ContentWrapper>{content}</ContentWrapper>}
    position={position}
  />
);

export default Popover;
