import { IFarm } from 'models/farm';
import { useMemo } from 'react';
import { matchPath, useLocation } from 'react-router-dom';
import { farmLink, Pages } from 'utils/routes';

const useActiveURLs = (selectedFarm: IFarm | null) => {
  const location = useLocation();

  const isMapUrl = useMemo(() => {
    const mapLink = farmLink(Pages.Map, selectedFarm?.id);
    const activePath = matchPath({ path: mapLink, end: false }, location.pathname);

    return activePath;
  }, [selectedFarm, location.pathname]);

  return {
    isMapUrl
  };
};

export default useActiveURLs;
