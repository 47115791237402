import React, { useCallback } from 'react';
import { faCircleInfo } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { mfColors } from 'vars';
import { useNavigate, useParams } from 'react-router-dom';
import { EMissionType } from 'models/mission';
import { setIsShowReplantInventory } from 'redux/appStateSlice';
import { useDispatch } from 'react-redux';

const Wrapper = styled.div`
  border-radius: 5px;
  background: ${mfColors.white};
  margin-top: 10px;
`;
const ActiveText = styled.span`
  color: ${mfColors.lightBlue};
  cursor: pointer;
  text-decoration: underline;
  font-size: 14px;
  font-family: MontserratBold;
`;

const ReplantInfo = () => {
  const { t } = useTranslation();
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const navigateToMissions = useCallback(() => {
    navigate(`/missions/${params.farmID}/all/create/${EMissionType.Replant}`);
  }, [navigate, params]);

  const openReplantInventoryDialog = useCallback(() => {
    dispatch(setIsShowReplantInventory(true));
  }, [dispatch]);

  return (
    <Wrapper>
      <FontAwesomeIcon icon={faCircleInfo} style={{ marginRight: 8 }} />
      <Trans
        i18nKey="replant.info"
        t={t}
        values={{ mission: t('replant.replant_mission'), calculator: t('replant.replant_calculator') }}
        components={[<ActiveText onClick={navigateToMissions} />, <ActiveText onClick={openReplantInventoryDialog} />]}
      />
    </Wrapper>
  );
};

export default ReplantInfo;
