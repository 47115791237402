import React from 'react';
import styled from 'styled-components';

import { mfColors, mfSizes } from 'vars';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  min-height: 100%;
  position: relative;
  width: calc(100% - ${mfSizes.staticMenuWidth});
  height: 100%;
`;

const Column = styled.div`
  width: 50%;
  height: 100%;
  position: relative;

  &:last-child {
    &::before {
      content: '';
      position: absolute;
      left: -1px;
      z-index: 1;
      top: 0;
      width: 2px;
      height: 100%;
      background: ${mfColors.grey};
    }
  }
`;

const GrovesSelectWrapper = styled.div`
  position: absolute;
  top: 12px;
  left: 12px;
  z-index: 1;
`;

const DeltaWrapper = styled.div`
  position: absolute;
  bottom: 0px;
  left: 50%;
  transform: translateX(-50%);
  margin: 0px auto;
  z-index: 3;
`;

interface IProps {
  leftMap: JSX.Element;
  rightMap: JSX.Element;
  grovesSelect: JSX.Element;
  delta?: JSX.Element;
}

const CompareLayout = ({ leftMap, rightMap, delta, grovesSelect }: IProps) => (
  <Wrapper>
    {delta && <DeltaWrapper>{delta}</DeltaWrapper>}
    <GrovesSelectWrapper>{grovesSelect}</GrovesSelectWrapper>
    <Column>{leftMap}</Column>
    <Column>{rightMap}</Column>
  </Wrapper>
);

export default CompareLayout;
