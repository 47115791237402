type TCoordinate = [number, number];

export const SATELLITE_HOTSPOTS_FEATURE_KEY = 'satelliteHotspots';

export enum EHotspotType {
  Drone = 'drone',
  Satellite = 'satellite',
  Manual = 'manual',
  Cutline = 'cutline'
}

export interface IHotspots {
  satellites: IUnifiedHotspot[];
  anomalies: IUnifiedHotspot[];
}

export interface IUnifiedHotspot {
  farmID: string;
  groveID: string;
  clusterID: string;
  geometry: TCoordinate[];
  surveyID: string | null;
  isSpatial: boolean;
  population: number;
  type: EHotspotType;
  date: string;
  status?: string;
  area?: number;
}
