import {
  ICIPORichItemGeojson,
  INDVIRichItemGeojson,
  IRichItemFeature,
  TRichItemGeometryType,
  IChartLabelConfig,
  IFreeAreaRichItemGeojson,
  ICapacityRichItemGeojson,
  IRichItemGeojson
} from 'models/richItem';
import { MAPBOX_SUPPORTED_COLORS } from 'models/colors';
import { IUnifiedGeojson } from 'hooks/farms.hooks';
import { booleanContains, Feature, feature, Polygon } from '@turf/turf';

export type TColors = (string | number | (string | number)[])[];

export const delay = (ms: number) =>
  // eslint-disable-next-line implicit-arrow-linebreak
  new Promise<void>((resolve) => {
    setTimeout(() => resolve(), ms);
  });

export const isDescendant = (child: HTMLElement | null, checkFunction: (parent: HTMLElement | null) => boolean): boolean => {
  if (checkFunction(child)) {
    return true;
  }
  let node = child?.parentNode;
  while (node != null) {
    if (checkFunction(node as HTMLElement)) {
      return true;
    }
    node = node.parentNode;
  }
  return false;
};

interface IFeatureCollection {
  type: 'FeatureCollection';
  features: IRichItemFeature[];
}

export const parseBqToGeojson = (data: ICIPORichItemGeojson | INDVIRichItemGeojson | IRichItemGeojson, filters?: { scores: number[] }): IFeatureCollection => {
  const mappedFeatures = Object.keys(data).map((id) => {
    const { coordinates, ...rest } = data[id];
    const feature = {
      type: 'Feature',
      properties: { ...rest, id },
      geometry: { type: 'Point' as TRichItemGeometryType, coordinates }
    } as IRichItemFeature;
    return feature;
  });

  return {
    type: 'FeatureCollection',
    features: mappedFeatures
  };
};

export const parseLineBqToGeojson = (data: IFreeAreaRichItemGeojson | ICapacityRichItemGeojson): IFeatureCollection => {
  const mappedFeatures = Object.keys(data).map((id) => {
    const { geometry, ...rest } = data[id];
    const feature = {
      type: 'Feature',
      properties: { ...rest, id },
      geometry
    } as IRichItemFeature;
    return feature;
  });

  return {
    type: 'FeatureCollection',
    features: mappedFeatures
  };
};

export const filterFeatureCollection = (featureCollection: IFeatureCollection, filters?: { scores: number[] }) => {
  if (!filters) return featureCollection;

  const { type, features } = featureCollection;
  const filteredFeatures = features.filter((feature) => {
    let isPassed = true;

    if (filters.scores) {
      isPassed = filters.scores.includes(feature.properties.score as number);
    }

    return isPassed;
  });

  return {
    type,
    features: filteredFeatures
  };
};

export const getStepTypeFeatureColor = (circleColors: (number | string)[], value: number): string => {
  const defaultColor = circleColors[0];
  let color = defaultColor;

  for (let i = 0; i < circleColors.length; i += 1) {
    if (i % 2) {
      color = value <= circleColors[i] ? circleColors[i - 1] : defaultColor;
      if (value <= circleColors[i]) {
        break;
      }
    }
  }

  return color as string;
};

export const getMatchTypeFeatureColor = (circleColors: (number | string)[], value: number): string => {
  const defaultColor = circleColors[circleColors.length - 1];
  let color = defaultColor;

  for (let i = 0; i < circleColors.length; i += 1) {
    if (i % 2 === 0) {
      color = value === circleColors[i] ? circleColors[i + 1] : defaultColor;
      if (value === circleColors[i]) {
        break;
      }
    }
  }

  return color as string;
};

export const hexToRgb = (hex: string, alpha = 1): string => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result ? `rgba(${parseInt(result[1], 16)},${parseInt(result[2], 16)},${parseInt(result[3], 16)},${alpha})` : '';
};

export const getColorProperty = (mapbox: IUnifiedGeojson['mapbox']): TColors | null => {
  if (!mapbox) {
    return null;
  }

  const possibleColors = MAPBOX_SUPPORTED_COLORS.map((key) => mapbox.paint?.[key]);
  const colorPaint = possibleColors.find((color) => color);
  return Array.isArray(colorPaint) ? colorPaint : null;
};

export const getPaintPropertyName = (geoJSON: IUnifiedGeojson): string | null => {
  const colorPaint = getColorProperty(geoJSON.mapbox);

  if (!colorPaint) return null;

  const dataExpression = colorPaint[0];
  const isMatch = dataExpression === 'match';
  const isStep = dataExpression === 'step';

  if (isMatch || isStep) {
    return colorPaint[1][1];
  }

  return colorPaint[2][1];
};

export const getCategoricalData = (geojson: IUnifiedGeojson['geojson'], chartLabels: IChartLabelConfig[], property: string): { [key: string]: number } => {
  const defaultLabelValues = (chartLabels || []).reduce((obj: { [key: string]: number }, item) => ({ ...obj, [`${item.mapbox_value}`]: 0 }), {});
  const labelValues = geojson.features.reduce((acc: { [key: string]: number }, val) => {
    const propertyValue = val.properties[property];
    if (!acc[propertyValue]) {
      acc[propertyValue] = 0;
    }
    acc[propertyValue] += 1;
    return acc;
  }, {});

  return { ...defaultLabelValues, ...labelValues };
};

export const getLinearData = (geojson: IUnifiedGeojson['geojson'], chartLabels: IChartLabelConfig[], property: string): { [key: string]: number } => {
  const values = geojson.features.map((feature) => feature.properties[property]);
  const labelValuesPairs = (chartLabels || []).reduce((object: { [key: string]: number }, label, index) => {
    let nextLabel = chartLabels[index + 1];

    if (!nextLabel) {
      nextLabel = { mapbox_value: Infinity };
    }

    object[label.mapbox_value] = values.filter((value) => label.mapbox_value <= value && value < nextLabel.mapbox_value).length;

    return object;
  }, {});

  return labelValuesPairs;
};

export const getPercentageFromLabel = (item: { value: number; datasetIndex: number }, data: { datasets: { data: number[] }[] }): string => {
  const totalTrees = data.datasets[item.datasetIndex].data.reduce((sum: number, elem: number) => sum + elem, 0);
  const percentage = ((item.value / totalTrees) * 100).toFixed(2);
  return `${percentage}%`;
};

export const firestoreToArray = (snap: any) => {
  const array: any = [];

  snap.forEach((ref: any) => array.push(Object.assign(ref.data(), { id: ref.id })));

  return array;
};

export const collectionToArray = (snap: any) => {
  const array: any = [];

  snap.forEach((ref: any) => {
    array.push({ ...ref.val(), id: ref.key });
  });

  return array;
};

export const uniq = (arr: any[]) => [...new Set(arr)];
export const uniqBy = (prop: string, arr: any[]) => [...new Map(arr.map((item) => [item[prop], item])).values()];
export const sortBy = (prop: string, arr: any[]) => arr.sort((a, b) => a[prop] !== undefined && a[prop].localeCompare(b[prop]));

export const indexBy = (prop: string, arr?: any[]) => {
  const indexes = {};
  if (!arr) return indexes;

  arr.forEach((item) => {
    const key = item[prop];

    if (!indexes[key]) {
      indexes[key] = [item];
    } else {
      indexes[key] = indexes[key].concat(item);
    }
  });

  return indexes;
};

export const arrayToCsv = (data: any[][]): string => data.map((row: any[]) => row.map((entry) => `"${String(entry).replaceAll('"', '""')}"`).join(',')).join('\r\n');

export const head = <T>(arr: T[]): T => (arr ? arr[0] : arr);

export const downloadBlob = (content: string, filename: string, contentType: string): void => {
  const blob = new Blob([content], { type: contentType });
  const url = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', filename);
  link.click();
};

export const sumObjectKeys = (...objs: any) => {
  const result = objs.reduce((a: any, b: any) => {
    if (!b) return a;

    Object.keys(b).forEach((k) => {
      if (b[k]) {
        a[k] = (a[k] || 0) + b[k];
      }
    });

    return a;
  }, {});

  return result;
};

export const pick = <T>(obj: T, paths: string[]) => {
  const objCopy = {} as T;

  paths.forEach((key) => {
    if (obj[key] !== undefined) {
      objCopy[key] = obj[key];
    }
  });

  return objCopy;
};

export const isInsidePolygon = (polygon: Feature<Polygon>, feature: any) => {
  if (!polygon || !feature) return false;

  return booleanContains(polygon, feature);
};

export const findFeaturesInsidePolygon = <T>(polygon: Feature<Polygon>, items: T[]) => {
  if (!polygon) return items;

  return items.filter((item: any) => {
    const geometry = {
      type: 'Point',
      coordinates: item.coordinates
    };

    return isInsidePolygon(polygon, feature(geometry));
  });
};

export const splitEvery = <T>(n: number, arr: T[]) => {
  const result = [] as T[][];
  let index = 0;

  while (index < arr.length) {
    result.push(arr.slice(index, (index += n)));
  }

  return result;
};

export const groupBy = <T>(fn: (item: T) => string, arr: T[]) => {
  const result = {} as { [key: string]: T[] };

  arr.forEach((item) => {
    const key = fn(item);

    if (!result[key]) {
      result[key] = [];
    }

    result[key].push(item);
  });

  return result;
};

export const sumObjectKeysBy = <T>(fn: (item: T) => object, arr: T[]): T => {
  const result = arr.reduce((acc: any, next: any) => {
    const obj = fn(next);
    const keys = Object.keys(obj);

    keys.forEach((key) => {
      if (!acc[key]) {
        acc[key] = 0;
      }

      acc[key] += Number(obj[key]);
    });

    return acc;
  }, {});

  return result;
};
