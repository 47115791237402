import { Map, GeoJSONSource, SymbolLayer } from 'mapbox-gl';
import * as turf from '@turf/turf';
import moment from 'moment';

import { TMapMouseEvent, HOTSPOT_LAYERS, HOTSPOT_POLYGON_LAYERS, LAYERS_PRIORITY, addLayerToMap, reorderLayers, addMapSource, loadImage } from 'services/util/mapHelpers';

import { IUnifiedHotspot, EHotspotType } from 'models/hotspot';
import { Feature } from '@turf/turf';

import dateFormats from 'models/date';

import appConfig from 'config/config.json';

const HOTSPOT_SOURCE = 'hotspots';
const HOTSPOT_POLYGON_SOURCE = 'hotspots-polygon';
const HOTSPOT_MARKER_SOURCE = 'hotspot-marker-source';
const SATELLITE_HOTSPOT_MARKER_SOURCE = 'satellite-hotspot-marker-source';
const SELECTED_SATELLITE_HOTSPOT_MARKER_SOURCE = 'selected-satellite-hotspot-marker-source';

const HOTSPOT_SHADOW_1_SOURCE = 'hotspot_shadow_1_source';
const HOTSPOT_SHADOW_2_SOURCE = 'hotspot_shadow_2_source';
const hotspotLayers = [
  {
    layerName: HOTSPOT_LAYERS[0],
    sourceName: HOTSPOT_SOURCE
  },
  {
    layerName: HOTSPOT_LAYERS[1],
    sourceName: HOTSPOT_SHADOW_1_SOURCE
  },
  {
    layerName: HOTSPOT_LAYERS[2],
    sourceName: HOTSPOT_SHADOW_2_SOURCE
  }
];

export const getHotspotTilesetUrl = (hotspot: IUnifiedHotspot) => {
  const startDate = moment(hotspot.date).startOf('week').add(1, 'day').format(dateFormats.DATE_FORMAT_YYYY_M_DD);
  const endDate = moment(startDate).add(7, 'days').format(dateFormats.DATE_FORMAT_YYYY_M_DD);
  return `https://tiles2.planet.com/basemaps/v1/planet-tiles/ps_weekly_normalized_analytic_subscription_${startDate}_${endDate}_mosaic/gmap/{z}/{x}/{y}.png?api_key=${appConfig.planetToken}&proc=ndvi&color=ndvi`;
};

export const addHotspotPolygonLayers = async (map: Map) => {
  addMapSource(map, HOTSPOT_POLYGON_SOURCE, []);
  HOTSPOT_POLYGON_LAYERS.forEach((layerName) => {
    addLayerToMap(map, layerName, {
      id: layerName,
      type: 'line',
      layout: { visibility: 'visible' },
      paint: {
        'line-color': 'rgba(255,0,0,1)',
        'line-width': 2
      },
      source: HOTSPOT_POLYGON_SOURCE
    });
  });
  reorderLayers(
    map,
    LAYERS_PRIORITY.map((layer, index) => ({
      zIndex: index,
      name: layer
    }))
  );
};

const HOTSPOT_MARKER_LAYER_CONFIG = {
  id: HOTSPOT_LAYERS[3],
  type: 'symbol',
  source: HOTSPOT_MARKER_SOURCE,
  layout: {
    'icon-image': 'hotspot',
    'icon-allow-overlap': true,
    'icon-size': {
      base: 2,
      stops: [
        [16, 0.5],
        [19, 1],
        [24, 2]
      ]
    },
    'icon-offset': {
      stops: [
        [16, [0, -25]],
        [17, [0, -40]],
        [20, [0, -50]]
      ]
    }
  }
};

const SATELLITE_MARKER_LAYOUT = {
  ...HOTSPOT_MARKER_LAYER_CONFIG.layout,
  'icon-image': 'satellite-hotspot',
  'icon-size': {
    base: 3,
    stops: [
      [16, 0.75],
      [19, 1.5],
      [24, 3]
    ]
  }
};

export const addHotspotLayers = async (map: Map) => {
  hotspotLayers.forEach((layer, index) => {
    addMapSource(map, layer.sourceName, []);
    addLayerToMap(map, layer.layerName, {
      id: layer.layerName,
      type: 'fill',
      source: layer.sourceName,
      layout: { visibility: 'visible' },
      paint: {
        'fill-opacity': index === 2 ? 0.25 : ['case', ['==', ['get', 'isInSelected'], true], 0.3, 0.1],
        'fill-outline-color': 'rgba(255,0,0,1)',
        'fill-color': 'rgb(255,0,0)'
      }
    });
  });

  await loadImage(map, '/images/hotspot_map_icon.png', 'hotspot');
  await loadImage(map, '/images/satellite_hotspot_map_icon.png', 'satellite-hotspot');
  await loadImage(map, '/images/selected_satellite_hotspot_map_icon.png', 'selected-satellite-hotspot');

  addMapSource(map, HOTSPOT_MARKER_SOURCE, []);
  addMapSource(map, SATELLITE_HOTSPOT_MARKER_SOURCE, []);
  addMapSource(map, SELECTED_SATELLITE_HOTSPOT_MARKER_SOURCE, []);
  addLayerToMap(map, HOTSPOT_LAYERS[3], HOTSPOT_MARKER_LAYER_CONFIG as SymbolLayer);
  addLayerToMap(map, HOTSPOT_LAYERS[4], {
    ...HOTSPOT_MARKER_LAYER_CONFIG,
    id: HOTSPOT_LAYERS[4],
    source: SATELLITE_HOTSPOT_MARKER_SOURCE,
    layout: SATELLITE_MARKER_LAYOUT
  } as SymbolLayer);
  addLayerToMap(map, HOTSPOT_LAYERS[5], {
    ...HOTSPOT_MARKER_LAYER_CONFIG,
    id: HOTSPOT_LAYERS[5],
    source: SELECTED_SATELLITE_HOTSPOT_MARKER_SOURCE,
    layout: {
      ...SATELLITE_MARKER_LAYOUT,
      'icon-image': 'selected-satellite-hotspot'
    }
  } as SymbolLayer);

  reorderLayers(
    map,
    LAYERS_PRIORITY.map((layer, index) => ({
      zIndex: index,
      name: layer
    }))
  );
};

const addClickHandlers = (map: Map, clickHandler: (event) => void): void => {
  HOTSPOT_LAYERS.forEach((layer: string) => {
    map.on('click', layer, clickHandler);
  });
};

const removeClickHandlers = (map: Map, clickHandler: (event) => void): void => {
  HOTSPOT_LAYERS.forEach((layer: string) => {
    map.off('click', layer, clickHandler);
  });
};

const isHotspotSpatialOnly = (hotspot) => !!hotspot.isSpatial;

const getHotspotClusters = (hotspots, selectedHotspots?: IUnifiedHotspot[]) => {
  const clusters = hotspots.map((hotspot) => {
    const isInSelected = selectedHotspots && selectedHotspots.find((entry) => entry.clusterID === hotspot.clusterID);
    const polygon = turf.polygon([hotspot.geometry], { id: hotspot.clusterID, type: hotspot.type, spatialOnly: isHotspotSpatialOnly(hotspot), isInSelected: !!isInSelected });
    return polygon;
  });

  return clusters;
};

export const drawHotspotPolygons = (map: Map, hotspots: IUnifiedHotspot[]): void => {
  const clusters = getHotspotClusters(hotspots);
  const hotspotsPolygons = clusters.map((cluster) => turf.polygonSmooth(cluster, { iterations: 3 })).reduce((acc, next) => acc.concat(next.features), [] as Feature[]);

  const baseSource = map.getSource(HOTSPOT_POLYGON_SOURCE) as GeoJSONSource;
  baseSource.setData(turf.featureCollection(hotspotsPolygons) as any);
};

const getHotspotCenter = (cluster) => {
  const center = turf.centroid(cluster);

  if (cluster.properties.id && center.properties) {
    center.properties.id = cluster.properties.id;
  }

  return center;
};

export const drawHotspots = (
  map: Map,
  hotspots: IUnifiedHotspot[],
  selectedHotspots: IUnifiedHotspot[],
  clickHandler?: (event) => void,
  hoverHandler?: (event: TMapMouseEvent) => void,
  isGroveLevel = true
): void => {
  const clusters = getHotspotClusters(hotspots, selectedHotspots);
  const hotspotsPolygons = clusters.map((cluster) => turf.polygonSmooth(cluster, { iterations: 3 })).reduce((acc, next) => acc.concat(next.features), [] as Feature[]);
  const baseSource = map.getSource(HOTSPOT_SOURCE) as GeoJSONSource;
  const nonSatelliteMarkers = clusters.filter((cluster) => !cluster.properties.spatialOnly && cluster.properties.type !== EHotspotType.Satellite).map(getHotspotCenter);
  const satelliteHotspotMarkers = clusters
    .filter(({ properties }) => !properties.spatialOnly && properties.type === EHotspotType.Satellite && !properties.isInSelected)
    .map(getHotspotCenter);

  const selectedSatelliteHotspotMarkers = clusters.filter(({ properties }) => !properties.spatialOnly && properties.isInSelected).map(getHotspotCenter);

  addMapSource(map, HOTSPOT_MARKER_SOURCE, nonSatelliteMarkers);
  addMapSource(map, SATELLITE_HOTSPOT_MARKER_SOURCE, satelliteHotspotMarkers);
  addMapSource(map, SELECTED_SATELLITE_HOTSPOT_MARKER_SOURCE, selectedSatelliteHotspotMarkers);

  if (isGroveLevel) {
    baseSource.setData(turf.featureCollection(hotspotsPolygons) as any);
    [HOTSPOT_SHADOW_1_SOURCE, HOTSPOT_SHADOW_2_SOURCE].forEach((item, idx) => {
      const source = map.getSource(item) as GeoJSONSource;
      const bufferHotspots = clusters
        .filter((cluster) => !cluster.properties.spatialOnly)
        .map((clusterPolygon) => turf.buffer(clusterPolygon, 0.005 * (idx + 1), { units: 'kilometers' }));

      source.setData(turf.featureCollection(bufferHotspots) as any);
    });
    if (clickHandler) {
      addClickHandlers(map, clickHandler);
    }
  }
  if (hoverHandler) {
    map.on('mousemove', hoverHandler);
  }
};

export const clearHotspots = (map: Map, clickHandler?: () => void, hoverHandler?: (event?: any) => void) => {
  [HOTSPOT_SOURCE, HOTSPOT_SHADOW_1_SOURCE, HOTSPOT_SHADOW_2_SOURCE, HOTSPOT_MARKER_SOURCE, SATELLITE_HOTSPOT_MARKER_SOURCE, SELECTED_SATELLITE_HOTSPOT_MARKER_SOURCE].forEach(
    (item) => {
      addMapSource(map, item, []);
    }
  );

  if (clickHandler) {
    removeClickHandlers(map, clickHandler);
  }
  if (hoverHandler) {
    map.off('mousemove', hoverHandler);
  }
};

export const clearHotspotsIcons = (map: Map) => {
  [HOTSPOT_MARKER_SOURCE, SATELLITE_HOTSPOT_MARKER_SOURCE, SELECTED_SATELLITE_HOTSPOT_MARKER_SOURCE].forEach((item) => {
    addMapSource(map, item, []);
  });
};
