import React, { useCallback } from 'react';
import { useLocation, NavLink } from 'react-router-dom';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

import IconButton, { EIconType } from 'atomicComponents/IconButton';

interface IProps {
  icon: IconProp | JSX.Element;
  tooltip: string;
  url: string;
  disabled?: boolean;
  active?: boolean;
  iconSize?: string | number;
  iconType?: EIconType;
}

const NavIcon = ({ icon, iconSize, tooltip, url, active = false, disabled = false, iconType = EIconType.FontAwesome }: IProps) => {
  const location = useLocation();

  const handleClick = useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      if (disabled) {
        e.preventDefault();
      }
    },
    [disabled]
  );

  return (
    <NavLink onClick={handleClick} to={url} state={{ from: location }}>
      <IconButton active={active} disabled={disabled} iconSize={iconSize} tooltip={tooltip} iconType={iconType} icon={icon} />
    </NavLink>
  );
};

export default NavIcon;
