import React, { useMemo, useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Chart } from 'chart.js';

import { IUnifiedGeojson } from 'hooks/farms.hooks';
import { ERichItemType, IChartLabelConfig } from 'models/richItem';
import RichItemsChartLabel from 'components/main/MapControls/RichItemsChartLabel';
import { IChartData, IChartOptions } from 'models/charts';

import { mfColors } from 'vars';
import { EMeasurementSystem } from 'models/region';

const Wrapper = styled.div`
  padding: 10px 10px 10px 20px;
  border-radius: 8px;
  margin: 12px 0;
  background: ${mfColors.white};
`;

const ChartWrapper = styled.div`
  position: relative;
`;

const Labels = styled.div`
  position: absolute;
  left: -16px;
  top: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-size: 10px;
`;

const ChartTitle = styled.div`
  color: ${mfColors.timebarSurveyColor};
  line-height: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const ChartIcon = styled.div`
  margin-right: 8px;
`;

const ChartLabel = styled.div`
  font-size: 14px;
  font-family: MontserratBold;
`;

const ChartName = styled.div`
  font-size: 14px;
  font-family: MontserratBold;
  text-transform: capitalize;
`;

const MeasurementType = styled.span`
  font-size: 10px;
  font-family: Montserrat;
  margin-left: 2px;
`;

const LabelsWrapper = styled.div`
  position: relative;
`;

const ChartContainer = styled.div``;

export interface IHighlightedRichItemsEvent {
  chartLabels: IChartLabelConfig[];
  selectedRanges: (number | string)[];
  richItemGeoJSON: IUnifiedGeojson;
}

interface IProps {
  chartTitle: string;
  chartIcon?: JSX.Element;
  chartLabel?: string | number;
  chartOptions: IChartOptions | null;
  chartData: IChartData | null;
  toggleHighlightedChartRange?: (rangeValue: number) => void;
  highLightedChartRanges?: number[];
  measurementSystem: EMeasurementSystem;
  chartTitleStyles?: {
    [style: string]: string | number;
  };
}

const RichItemsChart = ({
  chartTitle,
  chartLabel,
  chartIcon,
  chartOptions,
  chartData,
  toggleHighlightedChartRange,
  highLightedChartRanges,
  measurementSystem,
  chartTitleStyles = {}
}: IProps): JSX.Element | null => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [chart, setChart] = useState<Chart | null>(null);
  const { t } = useTranslation();

  const richItemLabels = useMemo(() => {
    const unit = measurementSystem === EMeasurementSystem.Metric ? 'meters' : 'feet';

    return {
      [ERichItemType.Cipo]: t('main.data_card.percentage_infected'),
      [ERichItemType.Height]: t(`main.data_card.${unit}`),
      [ERichItemType.Volume]: t(`main.data_card.cubic_${unit}`),
      [ERichItemType.CanopyArea]: t(`main.data_card.square_${unit}`),
      [ERichItemType.FreeArea]: t(`main.data_card.${unit}`),
      [ERichItemType.Weeds]: t(`main.data_card.weeds_${unit}`),
      [ERichItemType.FruitYield]: t('fruit_tree'),
      [ERichItemType.TreeGrowth]: t('percentage')
    };
  }, [t, measurementSystem]);

  const chartRanges = chartData?.data?.mapbox_value_labels;

  useEffect(() => {
    setChart((prev) => {
      if (prev) {
        prev.destroy();
      }
      if (!chartData || !chartOptions || !canvasRef.current) return null;
      const options = {
        ...chartData,
        options: chartOptions
      };
      return new Chart(canvasRef.current, options);
    });
  }, [chartData, chartOptions]);

  if (!chartData) return null;

  const width = chartRanges && chart ? `${chart.scales['y-axis-0'].width + 16}px` : '';
  return (
    <Wrapper>
      <ChartTitle>
        {chartIcon ? <ChartIcon>{chartIcon}</ChartIcon> : null}
        <div>
          <ChartName>
            <span style={chartTitleStyles}>{chartTitle}</span>
            {chartTitle && richItemLabels[chartTitle] && <MeasurementType>{`(${richItemLabels[chartTitle]})`}</MeasurementType>}
          </ChartName>
          {chartLabel ? <ChartLabel>{chartLabel}</ChartLabel> : null}
        </div>
      </ChartTitle>
      <ChartWrapper>
        {chartRanges && chart && (
          <LabelsWrapper>
            <Labels style={{ width }}>
              {chartRanges.map((range, index) => (
                <RichItemsChartLabel
                  key={index}
                  chart={chart}
                  range={range}
                  index={index}
                  onToggleHighchartRange={toggleHighlightedChartRange}
                  highLightedChartRanges={highLightedChartRanges}
                />
              ))}
            </Labels>
          </LabelsWrapper>
        )}
        <ChartContainer>
          <canvas ref={canvasRef} />
        </ChartContainer>
      </ChartWrapper>
    </Wrapper>
  );
};

export default RichItemsChart;
