import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from 'redux/store';
import { ERichItemType, ERichItemSubType, IRichItem } from 'models/richItem';

interface IRichItemsState {
  richItemType: ERichItemType;
  richItemSubType: ERichItemSubType | null;
  activeRichItem: IRichItem | null;
  isMissingRichItem: boolean;
}

const slice = createSlice({
  name: 'richItems',
  initialState: {
    richItemType: ERichItemType.Health,
    richItemSubType: null,
    activeRichItem: null,
    isMissingRichItem: false
  } as IRichItemsState,
  reducers: {
    setRichItemType: (state, action: PayloadAction<ERichItemType>) => ({
      ...state,
      richItemType: action.payload,
      richItemSubType: action.payload === ERichItemType.FruitAggregation ? ERichItemSubType.PostThinning : null
    }),
    setRichItemSubType: (state, action: PayloadAction<ERichItemSubType | null>) => ({ ...state, richItemSubType: action.payload }),
    setActiveRichItem: (state, action: PayloadAction<IRichItem>) => ({ ...state, activeRichItem: action.payload }),
    setIsMissingRichItem: (state, action: PayloadAction<boolean>) => ({ ...state, isMissingRichItem: action.payload })
  }
});

export const richItemsReducer = slice.reducer;
export const { setRichItemType, setRichItemSubType, setIsMissingRichItem, setActiveRichItem } = slice.actions;

export const richItemTypeSelector = (state: RootState) => state.richItems.richItemType;
export const richItemSubTypeSelector = (state: RootState) => state.richItems.richItemSubType;
export const activeRichItemSelector = (state: RootState) => state.richItems.activeRichItem;
export const isMissingRichItemSelector = (state: RootState) => state.richItems.isMissingRichItem;
