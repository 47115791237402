import { LngLatBounds, LngLatBoundsLike } from 'mapbox-gl';

export interface IColorStops {
  [name: string]: string;
}

export interface IMapLayer {
  id: string;
  type: string;
  name: string;
  source: string;
  paint: {
    'fill-opacity': number;
    'fill-color': string[];
  };
}

export type TEventHandler = (event?: any) => void;

export interface IMapState {
  bounds: LngLatBounds | null;
  zoom?: number;
}

export interface IMapOptions {
  bounds?: LngLatBoundsLike;
  zoom?: number;
  zoomDuration?: number;
}

export const InitialLayersState = {
  irrigation: false,
  colorBlind: false,
  terrain: false,
  scores: true,
  clusters: true,
  flags: true,
  poi: true
};
