import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import ReactDOMServer from 'react-dom/server';
import styled from 'styled-components';

import L from 'leaflet';
import 'leaflet-draw';
import 'helpers/leaflet-zoomify';

import TreePopupContent from 'components/main/TreePopupContent/TreePopupContent';
import Image from 'models/image';
import { IRegion } from 'models/region';
import { ERichItemType, EVIDENCE_IGNORED_RICH_ITEMS, IRichItemGeojson } from 'models/richItem';
import { IUnifiedGeojson } from 'hooks/farms.hooks';
import vectilesService from 'services/data/vectiles';
import db from 'services/data/providers/firebase';
import { getDownloadURL, ref } from 'firebase/storage';
import api from 'services/data/providers/api';
import { ITreeMapboxFeature } from 'models/tree';
import { COLORS, SCORE_COLORS } from 'models/colors';
import * as turf from '@turf/turf';
import imageService from 'services/data/images';
import { getColorProperty, getPaintPropertyName, getStepTypeFeatureColor, getMatchTypeFeatureColor } from 'utils/helpers';

const Photo = styled.div`
  background-color: black;
  width: 100%;
  height: 100%;

  .leaflet-popup-content {
    margin: 0;
  }

  .leaflet-popup-close-button {
    display: none;
  }

  .leaflet-popup-tip {
    width: 32px;
    height: 32px;
    margin-top: -18px;
  }
`;

interface IProps {
  initialImage: Image | null;
  selectedImage: Image | null;
  selectedTreeID: string;
  trees: IRichItemGeojson;
  region: IRegion | null;
  activeRichItem: ERichItemType | null;
  richItemGeoJSON: IUnifiedGeojson | null;
  setSelectedFeature: React.Dispatch<React.SetStateAction<ITreeMapboxFeature | null>>;
  sourceTrees: any;
}

const TreeGalleryMap = ({ initialImage, selectedImage, richItemGeoJSON, trees, region, selectedTreeID, setSelectedFeature, activeRichItem, sourceTrees }: IProps) => {
  const mapContainer = useRef<HTMLDivElement>(null);
  const [map, setMap] = useState<any>(null);
  const [coveredTrees, setCoveredTrees] = useState<any>([]);
  const [_baseLayer, setBaseLayer] = useState<any>(null);
  const [selectedTreeLayer, setSelectedTreeLayer] = useState<any>(null);
  const [treesLayer, setTreesLayer] = useState<any>(null);
  const [geoviews, setGeoviews] = useState<any>(null);
  const isSelected = useMemo(() => selectedImage?.id === initialImage?.id, [selectedImage, initialImage]);

  const getFillColor = useCallback((feature, richItemGeoJSON: IUnifiedGeojson | null) => {
    let color = SCORE_COLORS[feature.properties.score];
    color = color ? color.fill : COLORS.default.border;

    if (!richItemGeoJSON || !richItemGeoJSON.mapbox) {
      return color;
    }

    const { mapbox } = richItemGeoJSON;
    const colorPaint = getColorProperty(mapbox);

    if (!colorPaint) {
      return color;
    }

    const paintPropertyName = getPaintPropertyName(richItemGeoJSON);
    const isStep = colorPaint[0] === 'step';

    if (mapbox.type !== 'circle' || !paintPropertyName) {
      return color;
    }

    const circleColors = colorPaint.slice(2);
    const getColor = isStep ? getStepTypeFeatureColor : getMatchTypeFeatureColor;
    return getColor(circleColors as (string | number)[], feature.properties[paintPropertyName]);
  }, []);

  const initTreesLayers = useCallback(
    (newMap, richItemGeoJSON: IUnifiedGeojson | null) => {
      const getTreeCircleStyle = (feature, params) => ({
        ...params,
        fillColor: getFillColor(feature, richItemGeoJSON),
        color: COLORS.default.border
      });

      // @ts-ignore
      const newSelectedLayer = L.geoJson([] as any, {
        pointToLayer: (feature, latlng) => {
          const layer = L.circleMarker(
            latlng,
            getTreeCircleStyle(feature, {
              radius: 14,
              weight: 7,
              fillOpacity: 1,
              opacity: 1
            })
          );
          return layer;
        }
      }).addTo(newMap);

      setSelectedTreeLayer(newSelectedLayer);

      // @ts-ignore
      const newTreeLayer = L.geoJson([] as any, {
        pointToLayer: (feature, latlng) => {
          const layer = L.circleMarker(
            latlng,
            getTreeCircleStyle(feature, {
              weight: 1,
              radius: 7,
              fillOpacity: 1,
              opacity: 1,
              color: COLORS.default.border
            })
          );
          return layer;
        }
      }).addTo(newMap);

      let layerPopup;
      /* eslint-disable */
      [newSelectedLayer, newTreeLayer].forEach((layer) => layer.on('mouseover', (e) => {
        const { coordinates } = e.layer.feature.geometry;
        const swappedCoordinates = [coordinates[1] + 1, coordinates[0]] as [number, number];
        if (newMap) {
          const treeID = e.layer.feature.properties.id;
          const selectedTree = trees[treeID];
          const { subGrove, row, treeIndex } = e.layer.feature.properties;
          const treeName = `${subGrove}/${row}/${treeIndex}`;

          if (!selectedTree) return;

          const treeModel = {
            treeID,
            ...selectedTree,
            treeName
          };

          layerPopup = L.popup({
            maxWidth: 360,
            minWidth: 20,
            className: 'custom'
          })
            .setLatLng(swappedCoordinates)
            .setContent(ReactDOMServer.renderToString(<TreePopupContent tree={treeModel} metricType={region?.measurementSystem} />))
            .openOn(newMap);
        }
      }));

      [newSelectedLayer, newTreeLayer].forEach((layer) => layer.on('mouseout', (e) => {
        if (layerPopup && newMap) {
          newMap.closePopup(layerPopup);
          layerPopup = null;
        }
      }));
      /* eslint-enable */

      setTreesLayer(newTreeLayer);
    },
    [getFillColor, region?.measurementSystem, trees]
  );

  useEffect(() => {
    setMap((prev) => {
      if (!mapContainer.current) return prev;
      return prev || L.map(mapContainer.current, { zoomControl: false, minZoom: 2 }).setView(new L.LatLng(0, 0), 0);
    });
  }, [mapContainer]);

  useEffect(() => {
    if (!map) {
      return;
    }

    setTimeout(() => {
      const drawControl = new L.Control.Zoom({ position: 'topright' });
      map.addControl(drawControl);
    });
  }, [map]);

  useEffect(() => {
    if (!map || EVIDENCE_IGNORED_RICH_ITEMS.includes(activeRichItem as ERichItemType)) return;
    initTreesLayers(map, richItemGeoJSON);
  }, [map, region, trees, activeRichItem, richItemGeoJSON, initTreesLayers]);

  useEffect(() => {
    const _getTilesUrlsTable = (src) => {
      if (src.cdn) return Promise.resolve(null);

      const gsRef = ref(db.storage, src.tiles);
      return getDownloadURL(gsRef).then((url) => api.get(url));
    };

    const _getCoveredTrees = async (ids = [] as string[]) => {
      if (!ids.length) return [];

      if (sourceTrees && sourceTrees.length) {
        return sourceTrees.filter((tree) => ids.includes(tree.properties.id));
      }

      return [];
    };

    const _callPrepareTilesImage = (imgSrc) => {
      if (!imgSrc) return Promise.resolve();

      const { farmID, missionID, id: imageID } = imgSrc;

      // eslint-disable-next-line no-console
      return vectilesService.prepareImageTiles({ farmID, missionID, imageID }).catch((err) => console.error('error:', err));
    };

    const func = async () => {
      if (!initialImage || !map) {
        return;
      }
      const { farmID, missionID, id: imageID } = initialImage;
      const newCoveredTrees = await imageService.getImageCoveredTrees({ farmID, missionID, imageID });
      setCoveredTrees(newCoveredTrees);
      await _callPrepareTilesImage(initialImage);
      Promise.all([_getTilesUrlsTable(initialImage), _getCoveredTrees(Object.keys(newCoveredTrees))]).then(([urlTable, geoviews]) => {
        setGeoviews(geoviews);
        const tilesAccessLink = vectilesService.getImageTilesAccessLink({ farmID, missionID, imageID });
        const newBaseLayer = L.tileLayer
          .zoomify(tilesAccessLink, {
            width: initialImage.size.width,
            height: initialImage.size.height,
            attribution: initialImage.name,
            realTileSize: initialImage.tilesSize || 256,
            tileSize: 256,
            urlTable,
            tilesAccessLink
          })
          .addTo(map);
        setBaseLayer((prev) => {
          if (prev) {
            map.removeLayer(prev);
          }
          return newBaseLayer;
        });
        map._onResize();
        map.setZoom(2);
      });
    };
    if (initialImage && sourceTrees.length && isSelected) {
      func();
    }
  }, [initialImage, map, sourceTrees, isSelected]);

  useEffect(() => {
    if (!map || !initialImage || !_baseLayer) return;
    const _getTreePointInImage = (treeID) => (coveredTrees && coveredTrees[treeID] ? coveredTrees[treeID].imagePosition : null);
    const _adaptPoint = (pixelPoint, img: Image) => L.point(img.size.width / 2 + pixelPoint[0], img.size.height / 2 - pixelPoint[1]);
    const _pointToCoordinates = (pixelPoint) => map.unproject(pixelPoint, _baseLayer.getMaxNativeZoom());

    const _centerToTheSelectedTree = (source) => {
      const selectedTreeCoords = source.length && source[0] && source[0].geometry.coordinates;
      if (!selectedTreeCoords) return;
      const [lng, lat] = selectedTreeCoords;
      map.setView([lat, lng], 4, true);
    };
    const _drawTreesPolygons = () => {
      if (!initialImage || !geoviews) return;

      const selectedCircleSource = [] as any;
      const circleSource = [] as any;

      geoviews.forEach((tree) => {
        const imagePosition = _getTreePointInImage(tree.properties.id);

        if (!imagePosition) return;

        const latLng = _pointToCoordinates(_adaptPoint(imagePosition, initialImage));

        const properties = {
          ...tree.properties,
          ...(richItemGeoJSON?.geojson?.features?.find((feature) => feature.properties.id === tree.properties.id)?.properties || {})
        };

        const point = turf.point([latLng.lng, latLng.lat], properties);

        if (selectedTreeID === tree.properties.id) {
          selectedCircleSource.push(point);
          setSelectedFeature(point as ITreeMapboxFeature);
        } else {
          circleSource.push(point);
        }
      });

      if (treesLayer) {
        treesLayer.clearLayers();
        treesLayer.addData(circleSource);
      }

      if (selectedTreeLayer) {
        selectedTreeLayer.clearLayers();
        selectedTreeLayer.addData(selectedCircleSource);
        _centerToTheSelectedTree(selectedCircleSource);
      }
    };
    _drawTreesPolygons();
  }, [initialImage, coveredTrees, selectedTreeID, selectedTreeLayer, richItemGeoJSON, treesLayer, map, geoviews, _baseLayer, setSelectedFeature]);

  return <Photo ref={mapContainer} />;
};

export default TreeGalleryMap;
