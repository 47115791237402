import React from 'react';
import { renderToString } from 'react-dom/server';
import styled from 'styled-components';

import { mfColors } from 'vars';

interface IMarkerLabelProps {
  color: string;
  size: string;
}

const MarkerLabel = styled.div`
  border: ${({ color }: IMarkerLabelProps) => `2px solid ${color}`};
  color: ${({ color }: IMarkerLabelProps) => color};
  background: ${mfColors.white};
  width: ${({ size }: IMarkerLabelProps) => size};
  height: ${({ size }: IMarkerLabelProps) => size};
  font-size: 10px;
  padding: 0 4px;
  border-radius: 50%;
  line-height: ${({ size }: IMarkerLabelProps) => `${parseInt(size, 10) - 2}px`};
  text-align: center;
  font-family: MontserratBold;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

interface IProps {
  value: string | number;
  className: string;
  color?: string;
  size?: string;
}

export const MissionHeatmapMarker = ({ value, className, color = mfColors.darkGrey, size = '40px' }: IProps): JSX.Element => (
  <MarkerLabel size={size} color={color} className={className}>
    {value}
  </MarkerLabel>
);
export const MissionHeatmapHTMLMarker = ({ value, className, color, size }: IProps) => {
  const renderedComponent = renderToString(<MissionHeatmapMarker color={color} size={size} value={value} className={className} />);
  return renderedComponent;
};

export default MissionHeatmapHTMLMarker;
