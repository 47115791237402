import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

import { IFarm, IFarmName } from 'models/farm';
import { IZone } from 'models/zone';
import { IGrove } from 'models/grove';

import BreadcrumbDropdownContent, { ESuggestionModelType } from 'components/main/MainPageBreadcrumbs/BreadcrumbDropdownContent';

import Dropdown, { EToggleType } from 'atomicComponents/Dropdown';

import { mfColors } from 'vars';

const Wrapper = styled.div`
  font-size: 12px;
  line-height: 15px;
  display: inline-flex;
`;

const BreadcrumbLink = styled(NavLink)`
  text-decoration: none;
`;

const BreadcrumbEntry = styled.span`
  color: ${mfColors.lightBlue};
`;

const BreadcrumbSeparator = styled.span`
  color: ${mfColors.grey};
  margin: 0 6px;
`;

interface IProps {
  farm: IFarm;
  zone: IZone | null;
  grove: IGrove | null;
  farms: IFarmName[];
  zones: IZone[];
  groves: IGrove[];
}

const MainPageBreadcrumbs = ({ farm, zone, grove, farms, zones, groves }: IProps): JSX.Element => {
  const { t } = useTranslation();
  const farmsForSelect = useMemo(() => farms.filter((entry) => entry.id !== farm.id), [farms, farm]);
  const zonesForSelect = useMemo(() => (zone ? zones.filter((entry) => entry.id !== zone.id) : zones), [zones, zone]);
  const grovesForSelect = useMemo(() => {
    const zoneGroves = zone ? groves.filter((entry) => entry.zoneID === zone.id) : groves;

    return grove ? zoneGroves.filter((entry) => entry.id !== grove.id) : zoneGroves;
  }, [groves, grove, zone]);

  return (
    <Wrapper>
      <Dropdown
        toggleType={EToggleType.Mouseover}
        control={
          <BreadcrumbLink to={`/map/${farm.id}`}>
            <BreadcrumbEntry>{farm.name}</BreadcrumbEntry>
          </BreadcrumbLink>
        }
        content={<BreadcrumbDropdownContent models={farmsForSelect} modelType={ESuggestionModelType.Farm} linkPrefix="/map/" />}
      />
      {zonesForSelect.length > 0 && (
        <>
          <BreadcrumbSeparator>{'>'}</BreadcrumbSeparator>
          <Dropdown
            toggleType={EToggleType.Mouseover}
            control={
              zone ? (
                <BreadcrumbLink to={`/map/${farm.id}/${zone.id}`}>
                  <BreadcrumbEntry>{zone.name}</BreadcrumbEntry>
                </BreadcrumbLink>
              ) : (
                <BreadcrumbEntry>{t('shared.zone')}</BreadcrumbEntry>
              )
            }
            content={<BreadcrumbDropdownContent modelType={ESuggestionModelType.Zone} models={zonesForSelect} linkPrefix={`/map/${farm.id}/`} />}
          />
        </>
      )}
      {zone && grovesForSelect.length > 0 && (
        <>
          <BreadcrumbSeparator>{'>'}</BreadcrumbSeparator>
          <Dropdown
            toggleType={EToggleType.Mouseover}
            control={grove ? <BreadcrumbEntry>{grove.name}</BreadcrumbEntry> : <BreadcrumbEntry>{t('shared.grove')}</BreadcrumbEntry>}
            content={<BreadcrumbDropdownContent modelType={ESuggestionModelType.Grove} models={grovesForSelect} linkPrefix={`/map/${farm.id}/${zone.id}/`} />}
          />
        </>
      )}
    </Wrapper>
  );
};

export default MainPageBreadcrumbs;
