import React, { useMemo } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import missionUtils, { EDateRange } from 'utils/mission';
import numberUtils from 'utils/numbers';

import MissionIcon from 'components/missions/MissionIcon';
import MissionProgress from 'components/missions/MissionProgress';
import DataTable from 'atomicComponents/DataTable';

import { IGrove } from 'models/grove';
import { EMeasurementSystem } from 'models/region';
import { IMission, EMissionStatus, EMissionType, TREE_LEVEL_MISSION_TYPES } from 'models/mission';
import { IGroveSurveyStats } from 'models/stats';
import { IFeed, EFeedStatus } from 'models/feed';

import { mfColors } from 'vars';
import { ICapacityStatistic, TMappedStatistic } from 'models/statistic';
import UpdatedAtCell from 'components/missions/Table/UpdatedAtCell';

const TableWrapper = styled.div`
  max-height: 200px;
  overflow: auto;
`;

const TablePlaceholder = styled.div`
  margin: 36px 0 36px 36px;
  border-bottom: 1px solid ${mfColors.grey};
  padding: 8px 4px;
  width: 100%;
`;

const TableCell = styled.div`
  background: ${mfColors.white};
  padding: 4px;
  min-height: 32px;
  font-size: 12px;
  line-height: 24px;
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;

  &.left {
    text-align: left;
  }

  &.no-bg {
    background: none;
  }
`;

const ROW_STYLE = {
  border: 'none',
  background: 'none',
  margin: '0 0 4px'
};

const HEADER_STYLE = {
  border: 'none',
  background: 'none',
  fontSize: '12px',
  margin: '0 0 8px'
};

const HEADER_COLUMN_STYLE = {
  textTransform: 'none',
  minHeight: '0px',
  padding: '0 2px'
};

interface IProps {
  selectedDateRange: EDateRange | null;
  groves: IGrove[];
  feed: IFeed[];
  missions: IMission[];
  surveyStats: IGroveSurveyStats[];
  metricType: EMeasurementSystem;
  statistic: TMappedStatistic | null;
}

const MissionDetailsTable = ({ groves, feed, surveyStats, selectedDateRange, missions, metricType, statistic }: IProps): JSX.Element => {
  const { t } = useTranslation();
  const isReplant = useMemo(() => missions.every((mission) => mission.type === EMissionType.Replant), [missions]);

  const tableConfig = useMemo(
    () => [
      {
        title: '',
        render: (mission: IMission) => (
          <TableCell>
            <MissionIcon type={mission.type} size="24px" showBackground={false} />
          </TableCell>
        ),
        key: 'icon',
        sortable: false,
        width: '32px',
        style: {
          ...HEADER_COLUMN_STYLE,
          padding: '0px',
          margin: '0 2px 0 0'
        }
      },
      {
        title: t('missions.blocks'),
        render: (mission: IMission) => {
          const resolvedGroves = Object.keys(mission.groves).filter((groveID) => mission.groves[groveID] === EMissionStatus.Done).length;
          const affectedGroves = Object.keys(mission.groves).length;
          return <TableCell>{`${resolvedGroves} / ${affectedGroves}`}</TableCell>;
        },
        key: 'groves',
        sortable: false,
        width: '160px',
        style: HEADER_COLUMN_STYLE
      },
      {
        title: t(isReplant ? 'replant.replants' : 'shared.trees'),
        render: (mission: IMission) => {
          const isTreeLevelMission = TREE_LEVEL_MISSION_TYPES.includes(mission.type as EMissionType);
          let resolvedTrees: number = 0;
          let affectedTrees: number = 0;
          const affectedGroves = groves.filter((grove) => !!mission.groves[grove.id]);

          if (isReplant) {
            const resolvedGroves = groves.filter((grove) => mission.groves[grove.id] === EMissionStatus.Done);
            resolvedTrees = resolvedGroves.reduce((acc, next) => acc + ((statistic?.[next?.id] as ICapacityStatistic)?.statistic || 0), 0);
            affectedTrees = affectedGroves.reduce((acc, next) => acc + ((statistic?.[next?.id] as ICapacityStatistic)?.statistic || 0), 0);
          } else if (!isTreeLevelMission) {
            const resolvedGroves = groves.filter((grove) => mission.groves[grove.id] === EMissionStatus.Done);
            resolvedTrees = missionUtils.getTreesSum(resolvedGroves, surveyStats);
            affectedTrees = missionUtils.getTreesSum(affectedGroves, surveyStats);
          } else {
            const missionFeed = feed.filter((entry) => entry.missionID === mission.id);
            resolvedTrees = missionFeed.filter((entry) => entry.status === EFeedStatus.Done).length;
            affectedTrees = mission.isPreassigned ? missionFeed.length : missionUtils.getTreesSum(affectedGroves, surveyStats);
          }

          return <TableCell>{`${resolvedTrees.toLocaleString()} / ${affectedTrees.toLocaleString()}`}</TableCell>;
        },
        key: 'trees',
        sortable: false,
        width: '160px',
        style: HEADER_COLUMN_STYLE
      },
      {
        title: t('missions.area'),
        render: (mission: IMission) => {
          const resolvedGroves = groves.filter((grove) => mission.groves[grove.id] === EMissionStatus.Done);
          const affectedGroves = groves.filter((grove) => mission.groves[grove.id]);
          const resolvedArea = numberUtils.formatArea(numberUtils.getGrovesArea(resolvedGroves, metricType), 1, metricType);
          const affectedArea = numberUtils.formatArea(numberUtils.getGrovesArea(affectedGroves, metricType), 1, metricType);
          return <TableCell>{`${resolvedArea} / ${affectedArea}`}</TableCell>;
        },
        key: 'area',
        sortable: false,
        width: '160px',
        style: HEADER_COLUMN_STYLE
      },
      {
        title: t('missions.progress'),
        render: (mission: IMission) => (
          <TableCell>
            <MissionProgress mission={mission} groves={groves} showValue={false} />
          </TableCell>
        ),
        key: 'progress',
        sortable: false,
        width: '180px',
        style: HEADER_COLUMN_STYLE
      },
      {
        title: t('missions.completed'),
        render: (mission: IMission) => {
          const resolvedArea = missionUtils.getResolvedAreaForPeriod(mission, selectedDateRange, groves, metricType);
          return <TableCell>{numberUtils.formatArea(resolvedArea, 1, metricType)}</TableCell>;
        },
        key: 'area_this_week',
        sortable: false,
        width: '160px',
        style: HEADER_COLUMN_STYLE
      },
      {
        title: '',
        render: (mission: IMission) => (
          <TableCell className="no-bg">
            <UpdatedAtCell mission={mission} />
          </TableCell>
        ),
        key: 'info',
        sortable: false,
        width: '24px',
        style: HEADER_COLUMN_STYLE
      }
    ],
    [t, groves, selectedDateRange, metricType, surveyStats, feed, statistic, isReplant]
  );

  const tableProps = useMemo(() => {
    const props = {
      config: tableConfig,
      data: missions,
      placeholder: <TablePlaceholder>{t('missions.no_missions_found')}</TablePlaceholder>,
      showHeader: true,
      showFooter: false,
      rowsPerPage: 0,
      headerStyle: HEADER_STYLE,
      rowStyle: ROW_STYLE
    };
    return props;
  }, [tableConfig, missions, t]);

  return (
    <TableWrapper>
      <DataTable<IMission> {...tableProps} />
    </TableWrapper>
  );
};

export default MissionDetailsTable;
