import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { EMapSelectionMode } from 'hooks/mission.hooks';

import { TMissionType, EMissionType } from 'models/mission';

import { mfColors, mfShadows } from 'vars';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  background: ${mfColors.white};
  box-shadow: ${mfShadows.missionBlockShadow};

  > div {
    &:first-child {
      margin: 0 !important;
    }
  }
`;

interface IOptionsProps {
  isActive: boolean;
}

const Option = styled.div`
  width: 220px;
  text-align: center;
  line-height: 40px;
  color: ${({ isActive }: IOptionsProps) => (isActive ? mfColors.blue : mfColors.grey)};
  position: relative;
  cursor: pointer;

  &::before {
    content: '';
    display: ${({ isActive }: IOptionsProps) => (isActive ? 'block' : 'none')};
    position: absolute;
    height: 2px;
    bottom: 0;
    left: 0;
    width: 100%;
    background: ${mfColors.blue};
  }
`;

interface IProps {
  type: TMissionType;
  value: EMapSelectionMode;
  onChange: (value: EMapSelectionMode) => void;
}

const MissionSelectionSwitch = ({ type, value, onChange }: IProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <Option onClick={() => onChange(EMapSelectionMode.Tree)} isActive={value === EMapSelectionMode.Tree}>
        {t(type === EMissionType.Replant ? 'missions.edit_replant_slots' : 'missions.selecting_trees')}
      </Option>
      <Option onClick={() => onChange(EMapSelectionMode.Block)} isActive={value === EMapSelectionMode.Block}>
        {t('missions.selecting_blocks')}
      </Option>
    </Wrapper>
  );
};

export default MissionSelectionSwitch;
