import React, { Fragment, useRef } from 'react';
import styled from 'styled-components';
import Image from 'models/image';
import { mfColors, mfShadows } from 'vars';
import dateUtils from 'utils/date';
import dateFormats from 'models/date';

export const PREVIEW_SIZES = {
  width: 30,
  margin: 8
};

const SurveyDivider = styled.div`
  position: relative;
`;

const SurveyName = styled.span`
  position: absolute;
  color: ${mfColors.white};
  font-size: 15px;
  letter-spacing: 0;
  left: 42px;
  top: -50px;
  text-shadow: 0 2px 0 rgba(0, 0, 0, 0.5);
`;

const Divider = styled.div`
  width: 1px;
  height: 113px;
  margin: 0 42px;
  background-color: ${mfColors.lightGrey};
  position: relative;
  top: -21px;
`;

const ImagePreviewContainer = styled.div`
  height: ${PREVIEW_SIZES.width}px;
  position: relative;
  margin: 0;
`;

const ImagePreview = styled.div<{ isActive: boolean }>`
  border-radius: 8px;
  box-sizing: border-box;
  width: ${PREVIEW_SIZES.width}px;
  height: 100%;
  overflow: hidden;
  border: solid 2px ${mfColors.white};
  transition: border-color 0.3s ease-in-out;
  cursor: pointer;
  margin: 0 ${PREVIEW_SIZES.margin}px;
  background: ${({ isActive }) => (isActive ? mfColors.white : 'none')};
  box-shadow: ${({ isActive }) => (isActive ? mfShadows.imagePreviewShadow : 'none')};
`;

const Wrapper = styled.div<{ offset: number }>`
  z-index: 5;
  height: 113px;
  position: absolute;
  left: ${({ offset }) => `${offset}px`};
  right: 0;
  bottom: -${PREVIEW_SIZES.width}px;
  display: flex;
  transition: left 0.3s ease-in-out;

  & > div:nth-of-type(1) > div:nth-of-type(1) {
    display: none;
  }
  & > div:nth-of-type(1) > span:nth-of-type(1) {
    left: -12px;
  }
`;

interface IProps {
  images: Image[];
  selectedImage: Image | null;
  handleClick: (image: Image) => void;
  offset: number;
}

const GalleryPreview = ({ images, selectedImage, handleClick, offset }: IProps) => {
  const previewRef = useRef<HTMLDivElement>(null);
  const imageDate = (image: Image) => {
    const date = image.shotAt;

    return dateUtils.formatDate(date, dateFormats.DATE_FORMAT_DD_MM_YYYY);
  };

  const showNextDate = (images: Image[], image: Image, index: number) => {
    if (!images.length || !image) {
      return false;
    }

    const previousImage = images[index - 1];

    if (!previousImage) {
      return true;
    }

    return imageDate(previousImage) !== imageDate(image);
  };

  const checkIsActive = (item: Image) => !!selectedImage && item.id === selectedImage.id;

  return (
    <Wrapper offset={offset} ref={previewRef}>
      {images.map((image, idx) => (
        <Fragment key={image.id}>
          {showNextDate(images, image, idx) && (
            <SurveyDivider>
              <Divider className="divider" />
              <SurveyName className="survey-name">{imageDate(image)}</SurveyName>
            </SurveyDivider>
          )}
          <ImagePreviewContainer>
            <ImagePreview onClick={() => handleClick(image)} isActive={checkIsActive(image)} />
          </ImagePreviewContainer>
        </Fragment>
      ))}
    </Wrapper>
  );
};

export default GalleryPreview;
