import React, { Suspense, useEffect, useState, useMemo } from 'react';
import { Routes, Route } from 'react-router-dom';

import RequireAuth from 'components/signIn/RequireAuth';
import GlobalLoader from 'atomicComponents/GlobalLoader';

import signInHooks from 'hooks/signIn.hooks';
import usersHooks from 'hooks/users.hooks';
import tranlationsHooks from 'hooks/translations.hooks';
import useLogger from 'hooks/logger.hooks';
import { useAnalytics, useStoreUtmParams } from 'hooks/analytics.hooks';
import { IMapState, IMapOptions } from 'models/map';

import 'mapbox-gl/dist/mapbox-gl.css';
import styled from 'styled-components';
import { Map } from 'mapbox-gl';
import { MapContext } from 'components/shared/Map';
import MapLayout from 'layouts/MapLayout';
import MapScreen from 'screens/Map';
import MissionsScreen from 'screens/Missions';
import SignOut from 'screens/SignOut';
import appConfigHooks from 'hooks/appConfig.hooks';

const SignInScreen = React.lazy(() => import(/* webpackChunkName: "SingInScreen" */ 'screens/SingIn'));
const ForgotPasswordScreen = React.lazy(() => import(/* webpackChunkName: "ForgotPasswordScreen" */ 'screens/ForgotPassword'));
const ResetPasswordScreen = React.lazy(() => import('screens/ResetPassword'));
const CompleteRegistrationScreen = React.lazy(() => import('screens/CompleteRegistration'));
const NotFoundScreen = React.lazy(() => import(/* webpackChunkName: "NotFoundScreen" */ 'screens/NotFound'));
const NotificationManagerScreen = React.lazy(() => import(/* webpackChunkName: "CompareScreen" */ 'screens/NotificationManager'));

const Layout = styled.div`
  display: flex;
  width: 100%;
`;

function App(): JSX.Element {
  const user = signInHooks.useSignedInUser();
  const { setUser: setAnalyticsUser } = useAnalytics();
  const { setUser: setLoggerUser } = useLogger();
  const [activeMap, setActiveMap] = useState<Map | null>(null);
  const [mapState, setMapState] = useState<IMapState | null>(null);
  const [mapOptions, setMapOptions] = useState<IMapOptions | null>(null);
  const { timestamp: whatsNewTimestamp } = appConfigHooks.useGetWhatsNewTimestamp();
  const mapContext = useMemo(
    () => ({ activeMap, setActiveMap, mapState, setMapState, mapOptions, setMapOptions }),
    [activeMap, setActiveMap, mapState, setMapState, mapOptions, setMapOptions]
  );

  useStoreUtmParams();
  signInHooks.useFetchSignedInUser();
  tranlationsHooks.useTranslationsInit();
  appConfigHooks.useGetBulletinConfigs();

  const { showWhatsNewModal } = usersHooks.useWhatsNewModal();

  useEffect(() => {
    if (user) {
      setAnalyticsUser(user);
    }
  }, [user, setAnalyticsUser]);

  useEffect(() => {
    if (user && (user.settings?.whatsNewLastVisit || 0) < whatsNewTimestamp) {
      showWhatsNewModal();
    }
  }, [user, whatsNewTimestamp, showWhatsNewModal]);

  useEffect(() => {
    if (user) {
      setLoggerUser(user);
    }
  }, [user, setLoggerUser]);

  return (
    <div className="App">
      <MapContext.Provider value={mapContext}>
        <Suspense fallback={<GlobalLoader />}>
          <Routes>
            <Route path="/sign-in" element={<SignInScreen />} />
            <Route path="/forgot-password" element={<ForgotPasswordScreen />} />
            <Route path="/reset-password" element={<ResetPasswordScreen />} />
            <Route path="/complete-registration" element={<CompleteRegistrationScreen />} />
            <Route
              path="/"
              element={
                <RequireAuth>
                  <Layout>
                    <MapLayout />
                  </Layout>
                </RequireAuth>
              }
            >
              <Route path="/map" element={<MapScreen />} />
              <Route path="/map/:farmID" element={<MapScreen />} />
              <Route path="/missions/:farmID/status/:status" element={<MissionsScreen />} />
              <Route path="/map/:farmID/:zoneID" element={<MapScreen />} />
              <Route path="/map/:farmID/:zoneID/:groveID" element={<MapScreen />} />
              <Route path="/missions" element={<MissionsScreen />} />
              <Route path="/missions/:farmID" element={<MissionsScreen />} />
              <Route path="/missions/:farmID/:missionID" element={<MissionsScreen />} />
              <Route path="/missions/:farmID/:zoneID/:action/:type" element={<MissionsScreen />} />
            </Route>
            <Route
              path="/notification-manager"
              element={
                <RequireAuth>
                  <NotificationManagerScreen />
                </RequireAuth>
              }
            />
            <Route path="/404" element={<NotFoundScreen />} />
            <Route
              path="/sign-out"
              element={
                <RequireAuth>
                  <SignOut />
                </RequireAuth>
              }
            />
            <Route path="*" element={<NotFoundScreen />} />
          </Routes>
        </Suspense>
      </MapContext.Provider>
    </div>
  );
}

export default App;
