import React, { useMemo, useState, useEffect } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { useDispatch } from 'react-redux';

import dateUtils from 'utils/date';
import dateFormats from 'models/date';
import missionUtils from 'utils/mission';
import { setHeatmapFeed } from 'redux/missions/missionsSlice';

import SlideSwitch from 'atomicComponents/SlideSwitch';

import { IFeed } from 'models/feed';

const Wrapper = styled.div`
  padding: 24px 24px 24px 48px;
  border-radius: 0 0 16px 16px;
`;

const Header = styled.div`
  margin: 16px 0 0;
  width: 916px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const Filter = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

const SLIDER_STYLE = {
  width: '320px'
};

interface IProps {
  feed: IFeed[];
}

const WeeklyTrapMonitoringHeaderHeatmap = ({ feed }: IProps) => {
  const [selectedTimestamp, setSelectedTimestamp] = useState<number | null>(null);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const sortedFeed = useMemo(() => feed.slice().sort((a, b) => a.updatedAt - b.updatedAt), [feed]);

  const dateOptions = useMemo(() => {
    const sortedFeedDates = sortedFeed
      .map((entry) => dateUtils.getStartOfWeek(entry.updatedAt))
      .filter((value: number, index: number, self: number[]) => self.indexOf(value) === index);

    return sortedFeedDates.map((date) => ({
      value: date,
      text: t('missions.week_x_y', {
        x: dateUtils.formatDate(date, dateFormats.DATE_FORMAT_DD_MMM),
        y: dateUtils.formatDate(moment(date).add(7, 'days').valueOf(), dateFormats.DATE_FORMAT_DD_MMM)
      })
    }));
  }, [sortedFeed, t]);

  useEffect(() => {
    const lastFeedDate = sortedFeed.length > 0 ? sortedFeed.reverse()[0]?.updatedAt : null;
    setSelectedTimestamp(lastFeedDate ? dateUtils.getStartOfWeek(lastFeedDate) : null);
  }, [sortedFeed]);

  useEffect(() => {
    const endDate = moment(selectedTimestamp).add(7, 'days').valueOf();
    const feedToShow = selectedTimestamp ? missionUtils.getGroupedWeeklyTrapFeedForPeriod(sortedFeed, selectedTimestamp, endDate) : [];
    dispatch(setHeatmapFeed(feedToShow));

    return () => {
      dispatch(setHeatmapFeed([]));
    };
  }, [sortedFeed, selectedTimestamp, dispatch]);

  return (
    <Wrapper>
      <Header>
        <Filter>{!!selectedTimestamp && <SlideSwitch<number> style={SLIDER_STYLE} value={selectedTimestamp} options={dateOptions} onChange={setSelectedTimestamp} />}</Filter>
      </Header>
    </Wrapper>
  );
};

export default WeeklyTrapMonitoringHeaderHeatmap;
