import React, { useMemo } from 'react';
import styled from 'styled-components';
import { useTranslation, Trans } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlassLocation } from '@fortawesome/pro-solid-svg-icons';

import dateUtils from 'utils/date';
import dateFormats from 'models/date';

import { IUnifiedHotspot, EHotspotType } from 'models/hotspot';
import { ISurveyWithTimeRange } from 'models/survey';

import { SatelliteIcon, DroneIcon } from 'assets/images';

interface IProps {
  hotspot: IUnifiedHotspot;
  survey: ISurveyWithTimeRange;
}

const Wrapper = styled.div`
  padding: 32px;
  font-size: 16px;
`;

const Title = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 16px;
  font-family: MontserratBold;
  margin: 0 0 16px;
  line-height: 20px;
`;

const Icon = styled.div`
  width: 20px;
  height: 20px;
  margin: 0 16px 0 0;

  svg {
    width: 100%;
    height: 100%;
  }
`;

const Subtitle = styled.div`
  font-size: 18px;
`;

const Count = styled.div`
  font-wize: 20px;
  font-family: MontserratBold;
  display: inline-block;
`;

const HotspotPopupContent = ({ hotspot, survey }: IProps): JSX.Element => {
  const { t } = useTranslation();

  const dateToShow = useMemo(() => {
    if (hotspot.type === EHotspotType.Satellite && hotspot.date) {
      return hotspot.date;
    }

    return hotspot.date || survey.to;
  }, [hotspot, survey]);

  return (
    <Wrapper
      onMouseMove={(e: React.MouseEvent) => {
        e.stopPropagation();
        e.preventDefault();
      }}
    >
      <Title>
        <Icon>
          {hotspot.type === EHotspotType.Satellite && <SatelliteIcon />}
          {hotspot.type === EHotspotType.Drone && <DroneIcon />}
          {hotspot.type === EHotspotType.Manual && <FontAwesomeIcon icon={faMagnifyingGlassLocation} size="lg" />}
        </Icon>
        {dateUtils.formatDate(dateToShow, dateFormats.DATE_FORMAT_DD_MMM_YYYY)}
      </Title>
      {!!hotspot.population && (
        <Subtitle>
          <Trans i18nKey="main.map.count_trees" t={t} values={{ count: hotspot.population }} components={[<Count />]} />
        </Subtitle>
      )}
      {t('main.map.health_declined_scouting_required')}
    </Wrapper>
  );
};

export default HotspotPopupContent;
