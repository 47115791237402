import { EReportType } from 'models/report';

export enum EMissionStatus {
  InProgress = 'in_progress',
  Done = 'done'
}

export enum EMissionSubtype {
  Pests = 'pests',
  PestsP3 = 'pestsP3',
  Disease = 'disease',
  CountingFruits = 'countingFruits',
  MarkAsCounted = 'markAsCounted',
  BloomMonth = 'bloomMonth'
}

export enum EBudgetType {
  PerBlock = 'PerBlock',
  PerArea = 'PerArea'
}

export enum EMissionKpi {
  GroveArea = 'GroveArea',
  NumberOfTrees = 'NumberOfTrees',
  HealthScore = 'AvgHealthScore',
  NumberOfScores = 'NumberOfScores',
  WeakScores = 'WeakScores',
  WeakPercentage = 'WeakPercentage',
  CanopyArea = 'CanopyArea',
  TreeVolume = 'TreeVolume',
  Variety = 'Variety',
  PlantYear = 'PlantYear',
  IrrigationMethod = 'IrrigationMethod',
  CipoYesCount = 'CipoYesCount',
  CipoPercentage = 'CipoPercentage',
  WeedsPercentage = 'WeedsPercentage',
  WeedsArea = 'WeedsArea',
  HotspotsPopulation = 'HotspotsPopulation',
  HotspotsArea = 'HotspotsArea',
  Height = 'Height',
  FreeArea = 'FreeArea',
  UnderperformingDifference = 'UnderperformingDifference',
  UnderperformingGroveArea = 'UnderperformingGroveArea',
  GroveAge = 'GroveAge',
  TotalReplants = 'TotalReplants'
}

export interface ICreateMissionIncompleteParams {
  title: string;
  zoneID: string | null;
  farmID: string;
  type: TMissionType;
  subType: EMissionSubtype | null;
  kpis: EMissionKpi[];
  kpiScores: number[];
  isDeleted: boolean;
  treesCount: number | null;
  timestamp: number;
  createdBy: string;
  approved: boolean;
  isPreassigned: boolean;
}

export interface IMissionGroveLogEntry {
  userID: string;
  user: string;
  timestamp: number;
}

export interface ICreateMissionParams extends ICreateMissionIncompleteParams {
  groves: { [key: string]: EMissionStatus };
}

export interface IWeeklyTrapMonitoringConfigUpdateProps {
  [key: string]: {
    colors: {
      min: number | null;
      max: number | null;
      color: string;
    }[];
  };
}

export interface IWeeklyTrapColorsConfig {
  colors: {
    min: number | null;
    max: number | null;
    color: string;
  }[];
}

export interface IDefaultWeeklyTrapColorsConfig {
  id: string;
  colors: {
    min: number | null;
    max: number | null;
    color: string;
  }[];
}

/* eslint-disable camelcase */
export interface IWeeklyTrapMonitoringConfig {
  id: string;
  fruit_fly: IWeeklyTrapColorsConfig;
  red_scale: IWeeklyTrapColorsConfig;
  mealy_bug: IWeeklyTrapColorsConfig;
  bollworm: IWeeklyTrapColorsConfig;
  leaf_hopper: IWeeklyTrapColorsConfig;
  lemon_moth: IWeeklyTrapColorsConfig;
  false_codling_moth_sterile: IWeeklyTrapColorsConfig;
  false_codling_moth_wild: IWeeklyTrapColorsConfig;
}
/* eslint-enable camelcase */

export type TMissionConfigEntry = IWeeklyTrapMonitoringConfig; // TODO add every next mission config here

export interface IMission {
  id: string;
  zoneID: string | null;
  farmID: string;
  groves: { [key: string]: EMissionStatus };
  type: TMissionType;
  subType: EMissionSubtype | null;
  logs?: { [key: string]: IMissionGroveLogEntry };
  kpis: EMissionKpi[];
  kpiScores: number[];
  isDeleted: boolean;
  title: string;
  timestamp: number;
  createdBy: string;
  approved: boolean;
  isPreassigned: boolean;
  treesCount: number | null;
  updatedAt?: number;
}

export enum EMissionType {
  HLBScouting = 'HLBScouting',
  PestsDesease = 'Pests&Desease',
  FruitCount = 'FruitCount',
  Sampling = 'Sampling',
  Clipping = 'Clipping',
  WeeklyTrapMonitoring = 'WeeklyTrapMonitoring',
  IrrigationScouting = 'IrrigationScouting',
  General = 'General',
  SmartPestScouting = 'SmartPestScouting',
  GeneralScouting = 'GeneralScouting',
  Replant = 'replant',
  FieldReports = 'FieldReports'
}

export type TMissionType = EReportType | EMissionType;

export const REPLANT_MISSION_TYPE = EReportType.Replant;

export const GROVE_LEVEL_MISSION_TYPES = [
  EReportType.TargetedScouting,
  EReportType.DedicatedVisit,
  EReportType.Cipo,
  EReportType.Height,
  EReportType.FreeArea,
  EReportType.Weeds,
  EReportType.Replant,
  EMissionType.General
];

export const TREE_LEVEL_MISSION_TYPES = [
  EMissionType.HLBScouting,
  EMissionType.PestsDesease,
  EMissionType.FruitCount,
  EMissionType.Sampling,
  EMissionType.Clipping,
  EMissionType.IrrigationScouting,
  EMissionType.WeeklyTrapMonitoring,
  EMissionType.GeneralScouting,
  EMissionType.FieldReports
];

export enum EMissionTypesGroup {
  Scouting = 'Scouting',
  WeedControl = 'WeedControl',
  TrimmingAndClipping = 'TrimmingAndClipping',
  FruitForecasting = 'FruitForecasting',
  Replant = 'Replant',
  General = 'General'
}

export const groupedMissionTypes = new Map<EMissionTypesGroup, { type: TMissionType; subType: EMissionSubtype | null }[]>()
  .set(EMissionTypesGroup.Scouting, [
    { type: EReportType.TargetedScouting, subType: null },
    { type: EReportType.DedicatedVisit, subType: null },
    { type: EMissionType.HLBScouting, subType: null },
    { type: EMissionType.PestsDesease, subType: EMissionSubtype.PestsP3 },
    { type: EMissionType.PestsDesease, subType: EMissionSubtype.Pests },
    { type: EMissionType.PestsDesease, subType: EMissionSubtype.Disease },
    { type: EMissionType.Sampling, subType: null },
    { type: EMissionType.IrrigationScouting, subType: null },
    { type: EMissionType.WeeklyTrapMonitoring, subType: null },
    { type: EMissionType.GeneralScouting, subType: null }
  ])
  .set(EMissionTypesGroup.WeedControl, [
    { type: EReportType.Weeds, subType: null },
    { type: EReportType.Cipo, subType: null }
  ])
  .set(EMissionTypesGroup.TrimmingAndClipping, [
    { type: EReportType.Height, subType: null },
    { type: EReportType.FreeArea, subType: null },
    { type: EMissionType.Clipping, subType: null }
  ])
  .set(EMissionTypesGroup.FruitForecasting, [
    { type: EMissionType.FruitCount, subType: EMissionSubtype.CountingFruits },
    { type: EMissionType.FruitCount, subType: EMissionSubtype.MarkAsCounted },
    { type: EMissionType.FruitCount, subType: EMissionSubtype.BloomMonth }
  ])
  .set(EMissionTypesGroup.General, [{ type: EMissionType.General, subType: null }]);

export const groupedMissionTitles = new Map<EMissionTypesGroup, string>()
  .set(EMissionTypesGroup.Scouting, 'missions.scouting')
  .set(EMissionTypesGroup.FruitForecasting, 'missions.fruit_forecasting')
  .set(EMissionTypesGroup.WeedControl, 'missions.weed_control')
  .set(EMissionTypesGroup.TrimmingAndClipping, 'missions.trimming_clipping')
  .set(EMissionTypesGroup.Replant, 'missions.replant')
  .set(EMissionTypesGroup.General, 'missions.general');

export const groupedMissionSubtitles = new Map<EMissionTypesGroup, string>()
  .set(EMissionTypesGroup.Scouting, 'missions.scouting_mission_gallery')
  .set(EMissionTypesGroup.FruitForecasting, 'missions.fruit_forecasting_mission_gallery')
  .set(EMissionTypesGroup.WeedControl, 'missions.weed_control_mission_gallery')
  .set(EMissionTypesGroup.TrimmingAndClipping, 'missions.trimming_clipping_mission_gallery')
  .set(EMissionTypesGroup.General, 'missions.general_mission_gallery');

export const SMART_SAMPLING_MISSION_TYPES = [EMissionType.SmartPestScouting];

export const ALL_MISSION_TYPES = [
  ...GROVE_LEVEL_MISSION_TYPES,
  ...TREE_LEVEL_MISSION_TYPES.filter((type) => type !== EMissionType.FruitCount && type !== EMissionType.PestsDesease),
  EMissionSubtype.Pests,
  EMissionSubtype.PestsP3,
  EMissionSubtype.Disease,
  EMissionSubtype.CountingFruits,
  EMissionSubtype.MarkAsCounted,
  EMissionSubtype.BloomMonth,
  ...SMART_SAMPLING_MISSION_TYPES
];

export const NO_REPORT_MISSION_TYPES = [
  ...TREE_LEVEL_MISSION_TYPES,
  EMissionType.SmartPestScouting,
  EMissionType.GeneralScouting,
  EReportType.TargetedScouting,
  EReportType.Weeds,
  EMissionType.General
];
