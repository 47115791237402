import { Map, GeoJSONSource, StyleFunction, Marker } from 'mapbox-gl';
import * as turf from '@turf/turf';

import { reorderLayers, getMapSourceById, GROVES_RICH_ITEMS_LAYERS, LAYERS_PRIORITY, TPaintValue } from 'services/util/mapHelpers';
import numberUtils from 'utils/numbers';
import scoreUtils from 'utils/score';
import { RICH_ITEM_TYPES_COLORS, SCORE_COLORS } from 'models/colors';
import { ERichItemType, BIG_QUERY_STATISTIC_TYPES, RICH_ITEM_TYPES_WITH_MEASUREMENT_DEPENDENCE, RICH_ITEM_TYPES_GEO_ANALYTICS } from 'models/richItem';
import { EMeasurementSystem } from 'models/region';
import { IGroveSurveyStats } from 'models/stats';
import { IGrove } from 'models/grove';
import { TMappedStatistic, IParsedFruitYieldStatistic, ISimpleStatistic, IWeedsStatistic } from 'models/statistic';
import { ICommodityCombination } from 'models/commodity';
import GroveHTMLMarker from 'components/shared/markers/GroveMarker';

const MARKER_CLASSNAME = 'recently-published-marker';
const SOURCE_NAME = 'groves-score';

const getPaintValue = (richItemType: ERichItemType, measurement: EMeasurementSystem, dynamicPaintValue: TPaintValue | undefined): TPaintValue => {
  if ([...RICH_ITEM_TYPES_GEO_ANALYTICS, ERichItemType.FreeArea].includes(richItemType) && dynamicPaintValue) {
    return dynamicPaintValue;
  }

  if (BIG_QUERY_STATISTIC_TYPES.includes(richItemType) && richItemType !== ERichItemType.Health && richItemType !== ERichItemType.FruitYield) {
    const colorPairs = Object.keys(RICH_ITEM_TYPES_COLORS[richItemType])
      .sort((key, nextKey) => Number(key) - Number(nextKey))
      .reduce((result: (number | string)[], score) => {
        const shouldConvertScore = measurement === EMeasurementSystem.Imperial && RICH_ITEM_TYPES_WITH_MEASUREMENT_DEPENDENCE.includes(richItemType);
        const scoreMeasurement = shouldConvertScore ? numberUtils.convertMeterToFeet(+score, measurement) : score;
        result.push(Number(scoreMeasurement), RICH_ITEM_TYPES_COLORS[richItemType][score]);

        return result;
      }, []);
    return ['step', ['get', richItemType], RICH_ITEM_TYPES_COLORS[richItemType][0], ...colorPairs];
  }

  if (richItemType === ERichItemType.FruitYield && dynamicPaintValue) {
    return dynamicPaintValue;
  }

  const colorPairs = Object.keys(SCORE_COLORS).reduce((result: (number | string)[], score) => {
    const scoreValue = Number(score);
    result.push(scoreValue, scoreValue === 0 ? 'rgba(0, 0, 0, 0)' : SCORE_COLORS[score].fill);
    return result;
  }, []);

  return ['match', ['get', 'score'], ...colorPairs, SCORE_COLORS[-1].fill];
};

const getGroveFeature = (grove: IGrove, grovesScores: IGroveSurveyStats[]) => {
  const statistic = grovesScores.find((gs) => gs.groveID === grove.id);
  const isAlmonds = grove.attributes?.commodity === 'Almonds';

  return turf.polygon(grove.geometry.coordinates, {
    ...grove,
    isPublished: statistic?.isPublished || false,
    score: statistic ? scoreUtils.calculateScore(statistic, isAlmonds) : null
  });
};

const setGrovesScore = (
  map: Map,
  richItemType: ERichItemType,
  data: IGrove[],
  statistics: TMappedStatistic,
  grovesScores: IGroveSurveyStats[],
  measurement: EMeasurementSystem,
  selectedCommodity?: ICommodityCombination
): void => {
  const addBigQueryStatisticProp = (grove: IGrove) => {
    const feature = getGroveFeature(grove, grovesScores);
    const isPercentage = richItemType === ERichItemType.Cipo;
    let value: number | null = null;
    if (richItemType === ERichItemType.FruitYield) {
      value = (statistics?.[grove.id] as IParsedFruitYieldStatistic)?.statistic?.countPerTree;
    } else if (richItemType === ERichItemType.Health) {
      const marksStatistics = statistics?.[grove.id] || {};
      const marks = Object.keys(marksStatistics);
      const total = marks.reduce((acc: number, mark: string) => acc + (marksStatistics[mark]?.total || 0), 0);
      const averageMark = Math.round(marks.reduce((acc: number, mark: string) => acc + (+mark * marksStatistics[mark].total) / total, 0));
      value = averageMark;
    } else if (richItemType === ERichItemType.Weeds) {
      const statisticData = (statistics?.[grove.id] as IWeedsStatistic)?.statistic;

      if (statisticData && statisticData.categories && statisticData.values) {
        const { categories, values } = statisticData;
        const max = Math.max(...values);
        const lastIndexOfMax = values.lastIndexOf(max);

        value = categories[lastIndexOfMax];
      }
    } else {
      const statisticsValue = (statistics?.[grove.id] as ISimpleStatistic)?.statistic || 0;
      value = isPercentage ? statisticsValue * 100 : statisticsValue;
    }

    return {
      ...feature,
      properties: {
        ...feature.properties,
        [richItemType]: value
      }
    };
  };

  const source = getMapSourceById(map, SOURCE_NAME);

  if (source) {
    const features = data.filter((grove) => statistics[grove.id]).map(addBigQueryStatisticProp);

    if (selectedCommodity) {
      const commodityFeatures = features.filter((item) => item.properties.attributes.commodityCombinations?.includes(selectedCommodity.id));
      (source as GeoJSONSource).setData(turf.featureCollection(commodityFeatures));
    } else {
      (source as GeoJSONSource).setData(turf.featureCollection(features));
    }
  }
};

export const addRecentryPublishedGrovesMarkers = (map: Map, groves: IGrove[], grovesScores: IGroveSurveyStats[]) => {
  const groveFeatures = groves.map((grove) => {
    const statistic = grovesScores.find((gs) => gs.groveID === grove.id);
    const isAlmonds = grove.attributes?.commodity === 'Almonds';

    return turf.centerOfMass(grove.geometry, { properties: { id: grove.id, name: grove.name, score: statistic ? scoreUtils.calculateScore(statistic, isAlmonds) : null } });
  });
  groveFeatures.forEach((feature) => {
    const { name } = feature.properties;
    const el = document.createElement('div');
    el.classList.add(MARKER_CLASSNAME);
    el.innerHTML = GroveHTMLMarker({ title: name, color: SCORE_COLORS[feature.properties.score || 0].fill });

    const marker = new Marker(el);

    marker.setLngLat(feature.geometry.coordinates as [number, number]).addTo(map);

    return marker;
  });
};

export const removeRecentlyPublishedGrovesMarkers = (map: Map): void => {
  const container = map.getContainer();
  const markers = container.querySelectorAll(`.${MARKER_CLASSNAME}`);

  markers.forEach((marker) => {
    marker.remove();
  });
};

const addRichItemsLayers = (
  map: Map,
  richItemType: ERichItemType,
  groves: IGrove[],
  statistics: TMappedStatistic,
  grovesScores: IGroveSurveyStats[],
  measurement = EMeasurementSystem.Metric,
  dynamicPaintValue?: TPaintValue,
  selectedCommodity?: ICommodityCombination,
  recentlyPublishedGroves: string[] = []
): void => {
  removeRecentlyPublishedGrovesMarkers(map);
  if (!map.getSource(SOURCE_NAME)) {
    map.addSource(SOURCE_NAME, {
      data: turf.featureCollection([]),
      type: 'geojson'
    });
  }

  setGrovesScore(map, richItemType, groves, statistics, grovesScores, measurement, selectedCommodity);

  const color = getPaintValue(richItemType, measurement, dynamicPaintValue);
  const opacity = {
    default: 0,
    stops: [
      [false, 0],
      [true, 1]
    ],
    property: 'isPublished',
    type: 'categorical'
  };

  const borderWidth = {
    base: 1,
    stops: [
      [13, 1.4],
      [16, 4]
    ]
  };

  if (map.getLayer(GROVES_RICH_ITEMS_LAYERS[0])) {
    map.removeLayer(GROVES_RICH_ITEMS_LAYERS[0]);
  }

  if (map.getLayer(GROVES_RICH_ITEMS_LAYERS[1])) {
    map.removeLayer(GROVES_RICH_ITEMS_LAYERS[1]);
  }

  map.addLayer({
    id: GROVES_RICH_ITEMS_LAYERS[0],
    type: 'fill',
    source: SOURCE_NAME,
    paint: {
      'fill-color': color,
      'fill-opacity': opacity as StyleFunction
    }
  });

  map.addLayer({
    id: GROVES_RICH_ITEMS_LAYERS[1],
    type: 'line',
    source: SOURCE_NAME,
    paint: {
      'line-color': '#ffffe0',
      'line-width': borderWidth
    }
  });

  addRecentryPublishedGrovesMarkers(
    map,
    groves.filter((grove) => recentlyPublishedGroves.includes(grove.id)),
    grovesScores
  );

  reorderLayers(
    map,
    LAYERS_PRIORITY.map((layer, index) => ({
      zIndex: index,
      name: layer
    }))
  );
};

const removeRichItemsLayers = (map: Map): void => {
  if (map.getLayer(GROVES_RICH_ITEMS_LAYERS[0])) {
    map.removeLayer(GROVES_RICH_ITEMS_LAYERS[0]);
  }
  if (map.getLayer(GROVES_RICH_ITEMS_LAYERS[1])) {
    map.removeLayer(GROVES_RICH_ITEMS_LAYERS[1]);
  }
  if (map.getSource(SOURCE_NAME)) {
    map.removeSource(SOURCE_NAME);
  }
};

const mapRichItemsService = {
  addRichItemsLayers,
  removeRichItemsLayers
};

export default mapRichItemsService;
