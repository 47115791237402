import React, { useMemo, useCallback } from 'react';
import { useModal } from 'react-modal-hook';
import styled from 'styled-components';
import { Trans, useTranslation } from 'react-i18next';

import { EReportType } from 'models/report';
import { TMissionType, EMissionSubtype, EMissionType, TREE_LEVEL_MISSION_TYPES, GROVE_LEVEL_MISSION_TYPES, SMART_SAMPLING_MISSION_TYPES } from 'models/mission';
import { ISurveyWithTimeRange } from 'models/survey';
import MissionType from 'components/missions/MissionType';
import Modal from 'atomicComponents/Modal';
import Button from 'atomicComponents/Button';
import missionUtils from 'utils/mission';

import { mfColors, mfShadows } from 'vars';

const Wrapper = styled.div`
  width: 100%;
  border: 4px solid rgba(74, 74, 74, 0.3);
  box-shadow: ${mfShadows.missionTypeShadow};
  border-radius: 16px;
  padding: 24px;
  margin: 0 0 24px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const DescriptionColumn = styled.div`
  width: 400px;
  min-height: 160px;
`;

const MissionDescription = styled.div`
  margin: 24px 0 0;
  padding: 0 0 0 34px;
  color: ${mfColors.grey};

  .bold {
    font-family: 'MontserratBold';
    color: ${mfColors.darkGrey};
    margin: 0 0 4px;
  }
  .inactive {
    color: ${mfColors.grey};
  }
  .blue {
    color: ${mfColors.blue};
    margin: 0 0 4px;
  }
  ul {
    margin: 0;
    padding: 0 0 0 24px;
    list-style-type: disc;
    li {
      margin: 4px 0 0;
    }
  }
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  margin: 0 0 24px;
`;

const MissionTypeWrapper = styled.div`
  max-width: 264px;

  span {
    font-size: 16px;
  }
`;

const ActionsColumn = styled.div`
  width: 480px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;

  > div {
    flex-grow: 1;
    &:nth-child(2) {
      margin: 0 0 0 12px;
    }
  }
`;

const AdvancedOptionsRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin: 0 0 8px;

  div {
    text-decoration: underline;
    color: ${mfColors.blue};
    cursor: pointer;
  }
`;

const PreviewImg = styled.img`
  max-height: 720px;
  border-radius: 16px;

  &.shadowed {
    box-shadow: ${mfShadows.missionBlockShadow};
  }
`;

const SECONDARY_BUTTON_STYLE = { padding: '0 12px', height: '40px' };

const BUTTON_STYLE = { ...SECONDARY_BUTTON_STYLE, width: '400px', textAlign: 'center' };

interface IProps {
  type: TMissionType;
  subType: EMissionSubtype | null;
  selectedSurvey: ISurveyWithTimeRange;
  onMissionSelected: (type: TMissionType, subType: EMissionSubtype | null, checkedGroves: string[], isConfigured: boolean, isPreassigned: boolean) => void;
}

const CreateMissionTypeOption = ({ type, subType, selectedSurvey, onMissionSelected }: IProps): JSX.Element => {
  const { t } = useTranslation();
  const isTreeLevelType = TREE_LEVEL_MISSION_TYPES.includes(type as EMissionType);
  const isGroveLevelType = GROVE_LEVEL_MISSION_TYPES.includes(type as EReportType);
  const isSmartSamplingType = SMART_SAMPLING_MISSION_TYPES.includes(type as EMissionType);

  const [showPreviewModal, hidePreviewModal] = useModal(() => {
    const image = missionUtils.getMissionPreview(type, subType);
    const shadowedTypes = [EMissionSubtype.Pests, EMissionSubtype.Disease, EMissionType.HLBScouting, EMissionSubtype.MarkAsCounted];
    return (
      <Modal showBox={false} showClose={false} width="320px" closeOnBackdrop onClose={hidePreviewModal}>
        {image && <PreviewImg className={shadowedTypes.includes((subType || type) as EMissionType) ? 'shadowed' : undefined} alt={type as string} src={image} />}
      </Modal>
    );
  }, [type, subType]);

  const missionDescription = useMemo((): JSX.Element => {
    const checkedType = subType || type;
    let entries: string[] = [];
    let description: string | JSX.Element = '';

    switch (checkedType) {
      case EReportType.TargetedScouting:
      case EMissionType.GeneralScouting:
        entries = [t('missions.affected_trees_approx'), t('missions.affected_area')];
        break;
      case EReportType.DedicatedVisit:
        entries = [t('missions.the_increase_in_weak_trees'), t('missions.grove_area')];
        break;
      case EReportType.Height:
        entries = [t('missions.avg_tree_height_in_the_grove'), t('missions.grove_area')];
        break;
      case EReportType.FreeArea:
        entries = [t('missions.avg_free_area_in_the_grove'), t('missions.grove_area')];
        break;
      case EMissionSubtype.Pests:
        entries = [t('missions.pests_type'), t('missions.location_of_the_pest_on_the_tree'), t('missions.life_cycle_of_the_pest'), t('missions.severity')];
        break;
      case EMissionSubtype.Disease:
        entries = [t('missions.disease_type'), t('missions.severity')];
        break;
      case EReportType.Cipo:
      case EReportType.Weeds:
        entries = [t('missions.percentage_of_bindweed_infection_in_the_grove'), t('missions.amount_of_infected_trees_in_the_grove')];
        break;
      case EReportType.Replant:
        entries = [t('missions.number_of_replants'), t('missions.grove_age')];
        break;
      default:
        entries = [];
    }

    switch (checkedType) {
      case EMissionType.HLBScouting:
        description = t('missions.hlb_mission_description');
        break;
      case EMissionSubtype.CountingFruits:
        description = t('missions.counting_fruits_description');
        break;
      case EMissionSubtype.MarkAsCounted:
        description = t('missions.mark_as_counted_description');
        break;
      case EMissionSubtype.BloomMonth:
        description = t('missions.bloom_month_description');
        break;
      case EMissionType.Sampling:
        description = t('missions.sampling_mission_description');
        break;
      case EMissionType.Clipping:
        description = t('missions.clipping_mission_description');
        break;
      case EMissionType.IrrigationScouting:
        description = t('missions.irrigation_scouting_mission_description');
        break;
      case EMissionType.General:
        description = t('missions.general_mission_description');
        break;
      case EReportType.TargetedScouting:
      case EMissionType.GeneralScouting:
        description = (
          <>
            <div>{t('missions.selecting_groves_according_to')}</div>
            <ul>
              {entries.map((entry, index) => (
                <li key={index}>{entry}</li>
              ))}
            </ul>
          </>
        );
        break;
      case EMissionType.WeeklyTrapMonitoring:
        description = (
          <Trans
            i18nKey="missions.weekly_trap_monitoring_description"
            t={t}
            components={[
              <>
                <br />
                <br />
              </>
            ]}
          />
        );
        break;
      case EMissionType.SmartPestScouting:
        description = (
          <>
            <div className="bold">{t('missions.smart_pest_scouting')}</div>
            <div>{t('missions.smart_pest_scouting_mission_description')}</div>
            <ul>
              {entries.map((entry, index) => (
                <li key={index}>{entry}</li>
              ))}
            </ul>
          </>
        );
        break;
      case EMissionSubtype.Pests:
      case EMissionSubtype.Disease:
        description = (
          <>
            <div>{t('missions.pests_disease_mission_description')}</div>
            <ul>
              {entries.map((entry, index) => (
                <li key={index}>{entry}</li>
              ))}
            </ul>
          </>
        );
        break;
      case EMissionSubtype.PestsP3:
        description = (
          <>
            <div>{t('missions.pests_scouting_p3_mission_description')}</div>
          </>
        );
        break;
      default:
        break;
    }

    return (
      <>
        {(isGroveLevelType || isTreeLevelType) && <div className="bold">{t(isGroveLevelType ? 'missions.mission_properties' : 'missions.mission_description')}</div>}
        {isGroveLevelType ? (
          <>
            <div>{t('missions.selecting_groves_according_to')}</div>
            <ul>
              {entries.map((entry, index) => (
                <li key={index}>{entry}</li>
              ))}
            </ul>
          </>
        ) : (
          description
        )}
      </>
    );
  }, [t, type, subType, isGroveLevelType, isTreeLevelType]);

  const handleCreateMission = useCallback(
    (isConfigured = true) => {
      onMissionSelected(type, subType, [], isConfigured, isTreeLevelType);
    },
    [isTreeLevelType, type, subType, onMissionSelected]
  );

  const missionName = useMemo(() => t(missionUtils.getMissionTypeKey(subType || type)), [t, subType, type]);

  return (
    <Wrapper>
      <Header>
        <MissionTypeWrapper>
          <MissionType type={subType || type} />
        </MissionTypeWrapper>
      </Header>
      <Content>
        <DescriptionColumn>
          <MissionDescription>{missionDescription}</MissionDescription>
        </DescriptionColumn>
        <ActionsColumn>
          <ButtonsWrapper>
            {isTreeLevelType && subType !== EMissionSubtype.PestsP3 && <Button style={SECONDARY_BUTTON_STYLE} isSecondary onClick={showPreviewModal} text={t('shared.preview')} />}
            <>
              {type === EMissionType.General && (
                <AdvancedOptionsRow>
                  <div onClick={() => handleCreateMission(false)}>{t('missions.advanced_options')}</div>
                </AdvancedOptionsRow>
              )}
              <Button
                style={BUTTON_STYLE}
                onClick={() => handleCreateMission(!isSmartSamplingType)}
                text={t(isSmartSamplingType ? 'missions.setup_type' : 'missions.create_type_mission', { missionName })}
              />
            </>
          </ButtonsWrapper>
        </ActionsColumn>
      </Content>
    </Wrapper>
  );
};

export default CreateMissionTypeOption;
