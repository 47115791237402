import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import numberUtils from 'utils/numbers';
import { EMeasurementSystem } from 'models/region';
import { mfColors } from 'vars';
import { area, convertArea, Feature, Polygon } from '@turf/turf';
import { HEALTHY, NOT_BEARING, NOT_PRODUCING, UNDER_PERFORMING } from 'models/scores';

const Wrapper = styled.div`
  padding: 16px 32px 32px;
  background: ${mfColors.white};
  border-radius: 8px;
`;

const ContentRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 0 16px;
  font-size: 16px;

  &:last-child {
    margin: 0;
  }
`;

const MetricTitle = styled.div`
  min-width: 140px;
`;

const MetricValue = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const ScoreValue = styled.span`
  margin: 0 0 0 20px;
`;

const Header = styled.div`
  font-family: MontserratBold;
  display: flex;
  justify-content: center;
  font-size: 16px;
  margin: 0 0 28px;
`;

const sumMetrics = (trees, metric: string) => trees.reduce((acc, next) => acc + next[metric], 0);

interface IProps {
  trees: any;
  cluster: Feature<Polygon>;
  metricType?: EMeasurementSystem;
  handleClose: () => void;
}

const ClusterMetrics = ({ trees, cluster, metricType, handleClose }: IProps): JSX.Element => {
  const { t } = useTranslation();
  const metricsRef = useRef<HTMLDivElement>(null);

  const clusterArea = useMemo(() => {
    const areaValue = numberUtils.convertSqMeterToSqFt(area(cluster), metricType);

    return numberUtils.formatSquare(areaValue, 1, metricType);
  }, [cluster, metricType]);
  const clusterAreaTitle = useMemo(() => {
    const unit = metricType === EMeasurementSystem.Metric ? 'hectares' : 'acres';
    const areaValue = numberUtils.convertSqMeterToSqFt(area(cluster), metricType);
    const unit2 = metricType === EMeasurementSystem.Metric ? 'meters' : 'feet';
    const area2 = convertArea(areaValue, unit2, unit);

    return numberUtils.normalizeDecimal(area2, 2);
  }, [cluster, metricType]);
  const treesLength = useMemo(() => trees.length, [trees]);

  const getScoresPercentage = useCallback(
    (scores: number[]) => {
      const selectedTress = trees.filter((item) => scores.includes(item.score));
      const scorePercantage = (selectedTress.length / treesLength) * 100;

      return numberUtils.normalizeDecimal(scorePercantage, 1);
    },
    [trees, treesLength]
  );

  const avgHeight = useMemo(() => {
    const heightSum = sumMetrics(trees, 'height');

    return numberUtils.formatHeight(heightSum / treesLength, 2, metricType);
  }, [trees, treesLength, metricType]);

  const avgVolume = useMemo(() => {
    const volumeSum = sumMetrics(trees, 'volume');

    return numberUtils.formatVolume(volumeSum / treesLength, 2, metricType);
  }, [trees, treesLength, metricType]);

  const avgCanopyArea = useMemo(() => {
    const canopyAreaSum = sumMetrics(trees, 'canopy area');

    return numberUtils.formatSquare(canopyAreaSum / treesLength, 2, metricType);
  }, [trees, treesLength, metricType]);

  const RichItemRenderer = (richItem: string) => {
    const richItemValue = useMemo(
      () => (parseInt(richItem.split(' ')[0]) !== 0 ? <MetricValue dangerouslySetInnerHTML={{ __html: richItem }} /> : <MetricValue> N/A </MetricValue>),
      [richItem]
    );

    return richItemValue;
  };

  const handleClickOutside = useCallback(
    (e: MouseEvent) => {
      if (metricsRef.current && e.target && !metricsRef.current.contains(e.target as HTMLElement)) {
        handleClose();
      }
    },
    [metricsRef, handleClose]
  );

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [handleClickOutside]);

  return (
    <Wrapper ref={metricsRef}>
      <Header>{t('main.avg_card.cluster_metrics')}</Header>
      <ContentRow>
        <MetricTitle>{t('main.info.area')}</MetricTitle>
        <MetricValue>
          <ScoreValue>{RichItemRenderer(clusterArea)}</ScoreValue>
        </MetricValue>
      </ContentRow>
      <ContentRow>
        <MetricTitle>
          {t('main.info.area')}
          {metricType === EMeasurementSystem.Metric ? '(Ha)' : '(Ac)'}
        </MetricTitle>
        <MetricValue>
          <ScoreValue>{clusterAreaTitle}</ScoreValue>
        </MetricValue>
      </ContentRow>
      <ContentRow>
        <MetricTitle>{t('shared.trees')}</MetricTitle>
        <MetricValue>
          <ScoreValue>{treesLength}</ScoreValue>
        </MetricValue>
      </ContentRow>
      <ContentRow>
        <MetricTitle>{`${t('shared.healthy')} ${t('shared.trees')}`}</MetricTitle>
        <MetricValue>
          <ScoreValue>
            {getScoresPercentage(HEALTHY)}
            {'%'}
          </ScoreValue>
        </MetricValue>
      </ContentRow>
      <ContentRow>
        <MetricTitle>{`${t('shared.underperforming')} ${t('shared.trees')}`}</MetricTitle>
        <MetricValue>
          <ScoreValue>
            {getScoresPercentage(UNDER_PERFORMING)}
            {'%'}
          </ScoreValue>
        </MetricValue>
      </ContentRow>
      <ContentRow>
        <MetricTitle>{`${t('shared.non_producing')} ${t('shared.trees')}`}</MetricTitle>
        <MetricValue>
          <ScoreValue>
            {getScoresPercentage(NOT_PRODUCING)}
            {'%'}
          </ScoreValue>
        </MetricValue>
      </ContentRow>
      <ContentRow>
        <MetricTitle>{`${t('main.info.non_bearing')} ${t('shared.trees')}`}</MetricTitle>
        <MetricValue>
          <ScoreValue>
            {getScoresPercentage(NOT_BEARING)}
            {'%'}
          </ScoreValue>
        </MetricValue>
      </ContentRow>
      <ContentRow>
        <MetricTitle>{`${t('shared.avg')}. ${t('main.map.tree_height')}`}</MetricTitle>
        {RichItemRenderer(avgHeight)}
      </ContentRow>
      <ContentRow>
        <MetricTitle>{`${t('shared.avg')}. ${t('main.map.canopy_area')}`}</MetricTitle>
        {RichItemRenderer(avgCanopyArea)}
      </ContentRow>
      <ContentRow>
        <MetricTitle>{`${t('shared.avg')}. ${t('main.map.volume')}`}</MetricTitle>
        {RichItemRenderer(avgVolume)}
      </ContentRow>
    </Wrapper>
  );
};

export default ClusterMetrics;
