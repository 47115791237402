export interface IUserSearches {
  farms: string[];
  zones: string[];
  groves: string[];
}

export interface IUser {
  accessToken: string;
  displayName: string;
  email: string;
  emailVerified: boolean;
  isAnonymous: boolean;
  phoneNumber: string | null;
  photoURL: string | null;
  uid: string;
  refreshToken: string;
  idToken: string;
}

export type TUserSettings = { [key: string]: boolean | number | string };

export interface IUserInfo {
  id: string;
  createdAt: number;
  displayName: string;
  email: string;
  farms: { [key: string]: boolean };
  roles: { admin: boolean } | null;
  settings: TUserSettings | null;
  status: string;
  unreadAlertsCount: number;
  unsubscribe: boolean;
  subscriptions?: {
    [key: string]: boolean;
  };
}

export interface IUserAlerts {
  createdAt: number;
  message: string;
  dateRange: string[];
  params: {
    [key: string]: string;
  };
  read: boolean;
  title: string;
  type: string;
  weatherAlerts: string[];
  id: string;
}

export interface IUserAssociatedFarms {
  [key: string]: {
    name: string;
    permitted: boolean;
    visitedAt?: number;
  };
}

export const ADMIN_ROLES = ['admin', 'label-admin'];
