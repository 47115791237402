import React, { useCallback, useMemo, useEffect, useState, Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { useModal } from 'react-modal-hook';

import { useAddFarmMission, useUpdateFarmMission, useCreateMissionReports, useDeleteMissionReport, useUpdateMissionReports } from 'services/data/farms';
import regionsHooks from 'hooks/regions.hooks';
import missionHooks from 'hooks/mission.hooks';

import AbsoluteMetricMissionStats from 'components/missions/AbsoluteMetricMissionStats';
import SmartPestScoutingMissionStats from 'components/missions/SmartPestScoutingMissionStats';
import TreeLevelMissionStats from 'components/missions/TreeLevelMissionStats';
import CreateMissionAdvancedOptionsModal from 'containers/CreateMissionAdvancedOptionsModal';
import Button from 'atomicComponents/Button';
import LocalLoader from 'atomicComponents/LocalLoader';
import MissionModal from 'components/missions/MissionModal';
import { IGrove } from 'models/grove';
import { EEventType } from 'models/analytics';
import { ICreateMissionIncompleteParams, EMissionStatus, IMission, EMissionType, TREE_LEVEL_MISSION_TYPES } from 'models/mission';
import { IGroveSurveyStats } from 'models/stats';
import { ICreateFeedIncompleteParams, IFeed, ICreateFeedParams } from 'models/feed';
import { mfColors } from 'vars';

import { CongratulationsIcon } from 'assets/images';
import ReplantMetricMissionStats from 'components/missions/ReplantMetricStats';
import ReplantInventoryDialog from 'containers/ReplantInventoryDialog';
import { useSelector } from 'react-redux';
import { capacityStatisticByRulesSelector, stateReplantRulesSelector } from 'redux/replant/replantSlice';
import replantHooks from 'hooks/replant.hooks';
import vectilesService from 'services/data/vectiles';
import { ISurveyWithTimeRange } from 'models/survey';
import Modal from 'atomicComponents/Modal';

const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 24px 24px 24px 48px;
  background: ${mfColors.white};
  border-radius: 0 0 16px 0;
`;

const Controls = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  .loader {
    > div {
      margin: 0 16px 0 0;
    }
  }
`;

const AdvancedControl = styled.div`
  margin: 0 16px 0 0;
  cursor: pointer;
  text-decoration: underline;
  color: blue;

  > div {
    margin: 0;
  }
`;

const ReplantControlWrapper = styled.div`
  margin: 0 8px 0 0;
`;

interface IProps {
  mission: ICreateMissionIncompleteParams | IMission;
  groves: IGrove[];
  checkedGroves: string[];
  onSetCheckedGroves: Dispatch<SetStateAction<string[]>>;
  onUploadTrees: (trees: ICreateFeedIncompleteParams[]) => void;
  selectedTreeReports: ICreateFeedIncompleteParams[];
  originalTreeReports: ICreateFeedIncompleteParams[];
  surveyStats: IGroveSurveyStats[];
  onGoToOverview: (missionCreated?: boolean) => void;
  isEdit: boolean;
  selectedSurvey: ISurveyWithTimeRange;
}

const CreateMissionHeader = ({
  mission,
  groves,
  onUploadTrees,
  originalTreeReports,
  selectedTreeReports,
  surveyStats,
  checkedGroves,
  onGoToOverview,
  onSetCheckedGroves,
  isEdit,
  selectedSurvey
}: IProps) => {
  const { t } = useTranslation();
  const addFarmMission = useAddFarmMission();
  const updateFarmMission = useUpdateFarmMission();
  const addMissionReports = useCreateMissionReports();
  const deleteMissionReport = useDeleteMissionReport();
  const updateMissionReports = useUpdateMissionReports();
  const missionGroves = (mission as IMission).groves;
  const metricType = regionsHooks.useGetMeasurementSystem();
  const [loading, setLoading] = useState(false);
  const { logMissionEvent } = missionHooks.useMissionLogger();
  const isTreeLevelType = TREE_LEVEL_MISSION_TYPES.includes(mission.type as EMissionType);
  const isAllowedToEdit = missionHooks.useIsAllowedToEdit();
  const addMissionReplantRules = replantHooks.useAddReplantRules();
  const capacityStatisticByRules = useSelector(capacityStatisticByRulesSelector);
  const stateReplantRules = useSelector(stateReplantRulesSelector);

  const [showAdvancedOptionsModal, hideAdvancedOptionsModal] = useModal(
    () => (
      <Modal width="1032px" showClose onClose={hideAdvancedOptionsModal}>
        <CreateMissionAdvancedOptionsModal
          onClose={hideAdvancedOptionsModal}
          missionParams={mission}
          onUploadTrees={onUploadTrees}
          selectedSurvey={selectedSurvey}
          onSetCheckedGroves={onSetCheckedGroves}
        />
      </Modal>
    ),
    [mission, onSetCheckedGroves, selectedSurvey, onUploadTrees]
  );

  const missionGrovesToSave = useMemo(() => {
    const result: { [key: string]: EMissionStatus } = {};
    checkedGroves.forEach((groveID: string) => {
      result[groveID] = missionGroves?.[groveID] || EMissionStatus.InProgress;
    });

    return result;
  }, [missionGroves, checkedGroves]);

  const missionToSave = useMemo(
    () => ({
      ...mission,
      groves: missionGrovesToSave
    }),
    [mission, missionGrovesToSave]
  );

  useEffect(() => {
    if (!selectedTreeReports.length) {
      toast(<MissionModal title={t('missions.almost_there')} content={t('missions.select_groves')} />);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [t]);

  const handleSaveMission = useCallback(async () => {
    setLoading(true);
    if (isEdit) {
      const approved = !missionToSave.approved
        ? missionToSave.approved
        : !Object.keys(missionToSave.groves).find((groveID) => missionToSave.groves[groveID] === EMissionStatus.InProgress);
      const feedToAdd = selectedTreeReports.filter((entry) => !originalTreeReports.find((report) => report.geoObjectID === entry.geoObjectID));
      const feedToRemove = originalTreeReports.filter((entry) => !selectedTreeReports.find((report) => report.geoObjectID === entry.geoObjectID));
      const feedToUpdate = originalTreeReports
        .filter((entry) => !!selectedTreeReports.find((report) => report.geoObjectID === entry.geoObjectID))
        .map((entry) => {
          const newReportParams = selectedTreeReports.find((report) => report.geoObjectID === entry.geoObjectID);
          if (!newReportParams) return entry;
          return {
            ...entry,
            serialNumber: newReportParams.serialNumber
          };
        });
      let { isPreassigned } = missionToSave;
      const missionID = (missionToSave as IMission).id;

      if (isTreeLevelType) {
        if (originalTreeReports.length === feedToRemove.length && !feedToAdd.length) {
          isPreassigned = false;
        } else if (feedToAdd.length) {
          isPreassigned = true;
        }
      }

      await updateFarmMission(missionID, {
        title: missionToSave.title,
        approved,
        isPreassigned,
        groves: missionToSave.groves
      });

      await Promise.all(feedToAdd.map((treeReport) => addMissionReports([{ ...treeReport, missionID }])));
      await Promise.all(feedToRemove.map((treeReport) => deleteMissionReport((treeReport as IFeed).id)));
      await updateMissionReports(
        feedToUpdate.map((entry) => {
          const serialNumber = entry.serialNumber || '';
          return {
            id: (entry as IFeed).id,
            serialNumber
          };
        })
      );

      if (missionID && missionToSave.type === EMissionType.Replant && stateReplantRules) {
        await addMissionReplantRules(missionID, stateReplantRules, { missionID });
      }

      logMissionEvent(missionToSave, EEventType.MissionEdit);
    } else {
      vectilesService.triggerArchiveMission(missionToSave.farmID, selectedSurvey.id, Object.keys(missionToSave.groves));

      const missionID = await addFarmMission({
        ...missionToSave,
        isPreassigned: isTreeLevelType && selectedTreeReports.length > 0
      });
      if (missionID) {
        const chunkedTreeReports = selectedTreeReports.reduce((acc: ICreateFeedParams[][], report: ICreateFeedIncompleteParams, index: number) => {
          const chunkIndex = Math.floor(index / 500);
          if (!acc[chunkIndex]) {
            acc[chunkIndex] = [];
          }
          acc[chunkIndex].push({
            ...report,
            missionID
          } as ICreateFeedParams);
          return acc;
        }, []);
        await Promise.all(chunkedTreeReports.map((treeReports) => addMissionReports(treeReports)));
      }
      if (missionID && missionToSave.type === EMissionType.Replant && stateReplantRules) {
        await addMissionReplantRules(missionID, stateReplantRules, { missionID });
      }

      logMissionEvent(missionToSave, EEventType.MissionCreate);
      toast(<MissionModal icon={<CongratulationsIcon />} title={t('shared.congratulations')} content={t('missions.youve_created_a_new_mission')} />);
    }
    setLoading(false);
    onGoToOverview(true);
  }, [
    isEdit,
    missionToSave,
    addFarmMission,
    updateFarmMission,
    originalTreeReports,
    deleteMissionReport,
    updateMissionReports,
    onGoToOverview,
    logMissionEvent,
    addMissionReports,
    selectedTreeReports,
    isTreeLevelType,
    stateReplantRules,
    addMissionReplantRules,
    selectedSurvey,
    t
  ]);

  const { showConfirmEditModal } = missionHooks.useConfirmEditModal(handleSaveMission, onGoToOverview, missionToSave as IMission, 'missions.discard_changes_and_exit');

  const stats = useMemo(() => {
    switch (mission.type) {
      case EMissionType.SmartPestScouting:
        return <SmartPestScoutingMissionStats mission={missionToSave} isCreated={isEdit} showBackground groves={groves} metricType={metricType} surveyStats={surveyStats} />;
      case EMissionType.FruitCount:
      case EMissionType.Sampling:
      case EMissionType.PestsDesease:
      case EMissionType.HLBScouting:
      case EMissionType.Clipping:
      case EMissionType.IrrigationScouting:
      case EMissionType.GeneralScouting:
        return (
          <TreeLevelMissionStats
            mission={missionToSave}
            feed={selectedTreeReports}
            groves={groves}
            metricType={metricType}
            surveyStats={surveyStats}
            isCreated={!!(missionToSave as IMission).id}
          />
        );
      case EMissionType.Replant:
        return (
          <>
            <ReplantMetricMissionStats mission={missionToSave} isCreated={isEdit} showBackground groves={groves} metricType={metricType} statistic={capacityStatisticByRules} />
          </>
        );
      default:
        return <AbsoluteMetricMissionStats mission={missionToSave} isCreated={isEdit} showBackground groves={groves} metricType={metricType} surveyStats={surveyStats} />;
    }
  }, [missionToSave, selectedTreeReports, isEdit, groves, surveyStats, mission.type, metricType, capacityStatisticByRules]);

  return (
    <Header>
      {stats}
      <Controls>
        {isTreeLevelType && <AdvancedControl onClick={showAdvancedOptionsModal}>{t('missions.advanced_options')}</AdvancedControl>}
        {missionToSave.type === EMissionType.Replant && (
          <ReplantControlWrapper>
            <ReplantInventoryDialog showText />
          </ReplantControlWrapper>
        )}
        {loading && (
          <div className="loader">
            <LocalLoader />
          </div>
        )}
        <Button
          isDisabled={!checkedGroves.length || loading}
          onClick={isEdit && !isAllowedToEdit(missionToSave as IMission) ? showConfirmEditModal : handleSaveMission}
          text={t('shared.save')}
        />
      </Controls>
    </Header>
  );
};

export default CreateMissionHeader;
