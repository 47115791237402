import React, { useCallback, useMemo } from 'react';
import styled from 'styled-components';

import i18n from 'i18next';
import { FruitsInGrove, AvgSizeGrove, FruitsPerSquare, AvgFruitsTree } from 'assets/images';
import RichItemsChart from 'components/main/MapControls/RichItemsChart';
import { EMeasurementSystem } from 'models/region';
import { TCardStatistic, TMappedStatistic, IParsedFruitYieldStatistic } from 'models/statistic';
import { useTranslation } from 'react-i18next';
import numberUtils from 'utils/numbers';
import { getChartOptions } from 'utils/charts';
import { getPercentageFromLabel } from 'utils/helpers';
import { IGrove } from 'models/grove';

const FruitSizeLabels = styled.div`
  padding: 0.25em 0px;
  flex-direction: row;
  display: flex;
  justify-content: space-between;

  & > div {
    padding: 0.5em;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    flex-basis: 0px;
    align-items: center;
  }
`;

const Label = styled.div`
  min-height: 36px;
  font-size: 12px;
`;

const Value = styled.h4`
  margin: 0;
  font-family: 'MontserratBold';
  text-align: center;
  font-size: 20px;
`;

export const MEASUREMENT_SYSTEMS_LENGTH = {
  metric: {
    length: {
      name: 'Metres',
      chartLabel: 'meters',
      fruitSize: 'mm'
    }
  },
  imperial: {
    length: {
      name: 'Feet',
      chartLabel: 'Ft',
      fruitSize: '″'
    }
  }
};

export const FRUIT_YIELD_SCORE_COLORS = {
  0: '#2C82C9',
  50: '#246b2b',
  100: '#fe9b3d',
  200: '#ff2020'
};
export const FRUIT_YIELD_COUNT_COLORS = FRUIT_YIELD_SCORE_COLORS;
export const FRUIT_YIELD_SIZES_COLORS = ['#A47DCB', '#88BB76', '#4385F3', '#66B0C0', '#CB7DA2'];
export const TYPE_COLORS = {
  countPerTree: FRUIT_YIELD_COUNT_COLORS
};
export const FRUIT_TYPE_RANGES = {
  countPerTree: [0, 50, 100, 200]
};

export const getFruitYieldCardStatistic = (values: number[], type: string): TCardStatistic[] => {
  const colors = TYPE_COLORS[type];
  const ranges: number[] = FRUIT_TYPE_RANGES[type];

  return ranges.map((range, index) => {
    const label = ranges[index + 1] ? `${range} - ${ranges[index + 1]}` : `${range} - Max`;

    return {
      label,
      value: values.filter((val: number) => {
        if (!ranges[index + 1]) {
          return val >= range;
        }

        return val >= range && val <= ranges[index + 1];
      }).length,
      color: colors[Object.keys(colors)[index]],
      type: range
    };
  });
};

export const getSizeChartLabelValues = (values: number[] | null, labels: number[] | null, measurement: EMeasurementSystem) => {
  if (!values || !labels || !measurement) {
    return {};
  }

  return values.reduce((result, value, index) => {
    if (!values[index + 1] && values.length - 1 === index) {
      return result;
    }
    const shouldConvert = measurement === EMeasurementSystem.Imperial;
    const start = shouldConvert ? numberUtils.convertMillimeterToInch(labels[index]) : labels[index];
    const end = shouldConvert ? numberUtils.convertMillimeterToInch(labels[index + 1]) : labels[index + 1];

    result[`${start} - ${end}`] = value;
    return result;
  }, {});
};

export const getSizeChartLabels = (labelValues, categories) => {
  if (!labelValues) {
    return [];
  }

  const colors = FRUIT_YIELD_SIZES_COLORS;

  const ranges = Object.keys(labelValues).map((e) => e);
  return ranges.map((label, index) => ({
    chart_label: label,
    mapbox_value: categories[index],
    color: colors[Object.keys(colors)[index]]
  }));
};

interface IProps {
  totalFruits: number;
  averageSize: number;
  fruitsPerArea: number;
  averageFruits: number;
  measurementSystem: EMeasurementSystem;
  statistic: TMappedStatistic | null;
  fruitStatistic: TMappedStatistic | null;
  fruitYieldCardStatistic: TCardStatistic[];
  commodityGroves: IGrove[];
}

const FruitYieldDataCard = ({
  totalFruits,
  averageSize,
  fruitsPerArea,
  averageFruits,
  measurementSystem,
  statistic,
  fruitStatistic,
  fruitYieldCardStatistic,
  commodityGroves
}: IProps) => {
  const { t } = useTranslation();
  const languageID = i18n.language;

  const toggleHighlightedChartRange = useCallback(() => {}, []);
  const fruitTreeOptions = useMemo(
    () => getChartOptions({ toggleHighlightedChartRange, activeRichItem: null, languageID, chartProperty: 'fruitSize', chartData: null }),
    [toggleHighlightedChartRange, languageID]
  );
  const fruitTreeData = useMemo(
    () => ({
      type: 'horizontalBar',
      data: {
        labels: fruitYieldCardStatistic.reduce((acc, next: any) => acc.concat(next.label), []),
        mapbox_value_labels: fruitYieldCardStatistic.map((item) => ({ chart_label: item.label, mapbox_value: item.value, color: item.color })),
        datasets: [{ backgroundColor: Object.values(FRUIT_YIELD_SCORE_COLORS), borderWidth: 0, data: fruitYieldCardStatistic.map((item) => item.value) }]
      }
    }),
    [fruitYieldCardStatistic]
  );

  const fruitSizeOptions = useMemo(() => {
    const options = getChartOptions({ toggleHighlightedChartRange, activeRichItem: null, languageID, chartProperty: 'fruitSize', chartData: null });

    if (options) {
      options.scales.xAxes[0].ticks.stepSize = 25;
      options.scales.xAxes[0].ticks.callback = (value) => `${numberUtils.getLocalizedNumber(value, languageID)}%`;
      options.tooltips.callbacks.label = (item, data) => `${getPercentageFromLabel(item, data)} of fruit`;
      options.tooltips.callbacks.afterLabel = () => null;
    }

    return options;
  }, [toggleHighlightedChartRange, languageID]);

  const fruitSizeData = useMemo(() => {
    if (!fruitStatistic || !totalFruits || !statistic) return null;

    const groveIDs = commodityGroves.map((item) => item.id);
    const values = Object.keys(fruitStatistic)
      .filter((groveID) => groveIDs.includes(groveID))
      .reduce((result: number[], groveID) => {
        const stat = statistic[groveID];

        if (!stat) {
          return [];
        }

        const numberOfTrees = Object.keys(stat).reduce((acc, score) => acc + stat[score].total, 0);
        const bqStatistic = (fruitStatistic[groveID] as IParsedFruitYieldStatistic).statistic;

        if (!bqStatistic) return result;

        const sizes = bqStatistic.sizes?.values;
        const count = bqStatistic?.count;

        if (!sizes || !count) {
          return result;
        }

        const fruits = sizes.map((size) => size * numberOfTrees * count);
        // eslint-disable-next-line no-return-assign
        fruits.forEach((e, index) => (!result[index] ? (result[index] = e) : (result[index] += e)));
        return result;
      }, [])
      .map((e: number) => (Math.round((e / totalFruits) * 10000) / 10000) * 100);

    if (!values.length) {
      return null;
    }

    const { categories } = (fruitStatistic[groveIDs[0]] as IParsedFruitYieldStatistic)?.statistic?.sizes;
    const labelValuesPairs = getSizeChartLabelValues(values, categories, measurementSystem);
    const chartLabels = getSizeChartLabels(labelValuesPairs, categories);

    return {
      type: 'horizontalBar',
      data: {
        labels: chartLabels.map((label) => label.chart_label),
        mapbox_value_labels: chartLabels,
        datasets: [
          {
            borderWidth: 0,
            data: chartLabels.map((label) => labelValuesPairs[label.chart_label]),
            backgroundColor: chartLabels.map((label) => label.color),
            hoverBackgroundColor: chartLabels.map((label) => label.color)
          }
        ]
      }
    };
  }, [fruitStatistic, statistic, commodityGroves, totalFruits, measurementSystem]);

  const sizeDistribution = useMemo(() => {
    if (!fruitStatistic) {
      return '';
    }

    const groveIDs = commodityGroves.map((item) => item.id);
    const { categories } = (fruitStatistic[groveIDs[0]] as IParsedFruitYieldStatistic)?.statistic?.sizes || {};

    let minDeviation: number = 0;
    let maxDeviation: number = 0;
    let lengthType: string = '';

    if (categories) {
      minDeviation = Math.min(...categories);
      maxDeviation = Math.max(...categories);
      lengthType = MEASUREMENT_SYSTEMS_LENGTH[measurementSystem].length.fruitSize;
    }

    return `${numberUtils.convertMillimeterToInch(minDeviation)}${lengthType} - ${numberUtils.convertMillimeterToInch(maxDeviation)}${lengthType}`;
  }, [fruitStatistic, commodityGroves, measurementSystem]);

  return (
    <>
      <FruitSizeLabels>
        <div>
          <div>
            <FruitsInGrove width={24} height={24} />
          </div>
          <div>
            <Label>{t('total_fruit', { count: totalFruits })}</Label>
            <Value>{!!totalFruits && numberUtils.getLocalizedNumber(totalFruits, languageID)}</Value>
          </div>
        </div>
        {averageSize && averageSize > 0 ? (
          <div>
            <div>
              <AvgSizeGrove width={24} height={24} />
            </div>
            <div>
              <Label>{t('avg_size')}</Label>
              <Value>
                {numberUtils.convertMillimeterToInch(averageSize)}
                {MEASUREMENT_SYSTEMS_LENGTH[measurementSystem].length.fruitSize}
              </Value>
            </div>
          </div>
        ) : null}
        <div>
          <div>
            <FruitsPerSquare width={24} height={24} />
          </div>
          <div>
            <Label>{t(`avg_fruit_per_${measurementSystem === EMeasurementSystem.Metric ? 'hectare' : 'acre'}`)}</Label>
            <Value>{!!fruitsPerArea && numberUtils.getLocalizedNumber(fruitsPerArea, languageID)}</Value>
          </div>
        </div>
      </FruitSizeLabels>
      <RichItemsChart
        chartTitle={t('avg_fruit_per_tree')}
        chartTitleStyles={{ fontFamily: 'Montserrat', fontSize: 12 }}
        chartLabel={averageFruits}
        chartIcon={<AvgFruitsTree width={24} height={24} />}
        chartOptions={fruitTreeOptions}
        chartData={fruitTreeData}
        toggleHighlightedChartRange={toggleHighlightedChartRange}
        measurementSystem={measurementSystem}
      />
      {/* @TODO temporary remove */}
      {fruitSizeData && false && (
        <RichItemsChart
          chartTitle={t('fruit_size_distribution')}
          chartTitleStyles={{ fontFamily: 'Montserrat', fontSize: 12 }}
          chartLabel={sizeDistribution}
          chartOptions={fruitSizeOptions}
          chartIcon={<AvgSizeGrove width={24} height={24} />}
          chartData={fruitSizeData}
          toggleHighlightedChartRange={toggleHighlightedChartRange}
          measurementSystem={measurementSystem}
        />
      )}
    </>
  );
};

export default FruitYieldDataCard;
