import React, { useCallback, useMemo, useState, useEffect } from 'react';
import styled from 'styled-components';
import { useTranslation, Trans } from 'react-i18next';
import { useSelector } from 'react-redux';
import { faCheck } from '@fortawesome/pro-solid-svg-icons';

import farmsHooks from 'hooks/farms.hooks';
import { latestSurveyGrovesSelector } from 'redux/farm/farmSlice';
import { getFarmBulletinThresholds } from 'redux/bulletin/bulletinSlice';
import numberUtils from 'utils/numbers';
import replantHooks from 'hooks/replant.hooks';

import Button from 'atomicComponents/Button';
import LocalLoader from 'atomicComponents/LocalLoader';
import Checkbox, { ESizes } from 'atomicComponents/Checkbox';

import { ERichItemType } from 'models/richItem';
import { ICreateMissionIncompleteParams } from 'models/mission';
import { ISurveyWithTimeRange } from 'models/survey';
import { IFarm } from 'models/farm';
import { DEFAULT_REPORT_TRESHOLDS, EReportType, IHeightReport, IFreeAreaReport, ICipoReport, IWeedsReport, IScoreDifferenceReport } from 'models/report';

import { mfColors } from 'vars';

const Wrapper = styled.div`
  width: 100%;
`;

const Subtitle = styled.div`
  padding: 4px 0;
  margin: 0 0 24px;
  border-bottom: 2px solid ${mfColors.grey};
  font-size: 18px;
  width: 100%;
`;

const Content = styled.div`
  width: 790px;
  margin: 0 auto;
  padding: 0 0 32px;
  font-size: 16px;

  > div {
    margin: 0;
  }

  label {
    display: inline-block;
    margin: 0 12px 0 0;
  }
`;

const Input = styled.input`
  display: flex;
  background: #d9d9d9;
  border-radius: 4px;
  border: none;
  width: 60px;
  height: 29px;
  text-align: center;
  display: inline-block;

  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &:focus {
    outline: none;
  }
`;

const Controls = styled.div`
  display: flex;
  justify-content: flex-end;

  > * {
    margin: 0 0 0 12px;
  }
`;

interface IProps {
  missionParams: ICreateMissionIncompleteParams;
  zoneID: string | null | undefined;
  selectedSurvey: ISurveyWithTimeRange;
  onSetCheckedGroves: (groves: string[]) => void;
  onCreateMission: (params: ICreateMissionIncompleteParams) => void;
}

const SelectBlocksByParameters = ({ missionParams, selectedSurvey, zoneID, onSetCheckedGroves, onCreateMission }: IProps) => {
  const { t } = useTranslation();
  const { selectedFarm } = farmsHooks.useSelectedFarmEntities();
  const [preselectEnabled, setPreselectEnabled] = useState(false);
  const [precheckedGroves, setPrecheckedGroves] = useState<string[]>([]);
  const [tresholdValue, setTresholdValue] = useState(0);
  const [replantTresholdValue, setReplantTresholdValue] = useState(0);
  const groves = useSelector(latestSurveyGrovesSelector);
  const farmBulletinThresholds = useSelector(getFarmBulletinThresholds);
  const { healthStatistic, isLoading: statisticLoading } = farmsHooks.useRichItemsStatistic(ERichItemType.Health, selectedFarm?.id, selectedSurvey.id);

  const replantResholds = useMemo(
    () => ({
      [EReportType.Replant]: {
        value: [replantTresholdValue, tresholdValue / 100]
      }
    }),
    [tresholdValue, replantTresholdValue]
  );

  const replantReports = replantHooks.useReplantBulletinGroves(groves, healthStatistic, replantResholds);
  const { reports, isPending: bulletinsLoading } = farmsHooks.useBulletinsEntity((selectedFarm as IFarm).id, selectedSurvey.id, groves, zoneID || undefined, replantReports, true);

  useEffect(() => {
    if (missionParams.type === EReportType.Replant) {
      const tresholds = (farmBulletinThresholds[missionParams.type]?.value || DEFAULT_REPORT_TRESHOLDS[missionParams.type]) as number[];
      setTresholdValue(tresholds[1] * 100);
      setReplantTresholdValue(tresholds[0]);
    }
  }, [missionParams.type, farmBulletinThresholds]);

  const notDoneMissionReports = useMemo(() => {
    const { type } = missionParams;
    const missionReports = reports[type] || [];
    return missionReports.filter((report) => report.status !== 'done');
  }, [missionParams, reports]);

  useEffect(() => {
    setPreselectEnabled(!!notDoneMissionReports.length);
  }, [notDoneMissionReports]);

  const filteredDoneMissionsReports = useMemo(() => {
    const result = notDoneMissionReports.filter((report) => {
      switch (missionParams.type) {
        case EReportType.DedicatedVisit:
          return (report as IScoreDifferenceReport).underperforming_difference >= tresholdValue;
        case EReportType.Height:
          return +(report as IHeightReport).Height >= tresholdValue;
        case EReportType.FreeArea:
          return +(report as IFreeAreaReport).freeArea_avg <= tresholdValue;
        case EReportType.Weeds:
          // eslint-disable-next-line no-case-declarations
          const sum = +(report as IWeedsReport)._60_90cm_percentage + +(report as IWeedsReport)._30_60cm_percentage + +(report as IWeedsReport)._90cm_above_percentage;
          return sum > tresholdValue / 100;
        case EReportType.Cipo:
          return (report as ICipoReport).cipo_rate >= tresholdValue / 100;
        case EReportType.TargetedScouting:
        case EReportType.Replant:
          return true;
        default:
          return false;
      }
    });
    return result;
  }, [notDoneMissionReports, missionParams.type, tresholdValue]);

  useEffect(() => {
    setPrecheckedGroves(
      filteredDoneMissionsReports.reduce((acc: string[], report) => {
        const reportGrove = groves.find((grove) => grove.id === report.groveID && (!zoneID || grove.zoneID === zoneID));
        if (reportGrove && reportGrove.active && !acc.includes(reportGrove.id)) {
          acc.push(reportGrove.id);
        }
        return acc;
      }, [])
    );
  }, [filteredDoneMissionsReports, tresholdValue, groves, zoneID]);

  useEffect(() => {
    if (!farmBulletinThresholds) return;
    setTresholdValue((prev) => {
      switch (missionParams.type) {
        case EReportType.Height:
          return (farmBulletinThresholds.height?.value as number) || 5;
        case EReportType.Weeds:
          return numberUtils.convertToPercentage((farmBulletinThresholds.weeds?.value as number) || 0.15);
        case EReportType.Cipo:
          return +numberUtils.convertToPercentage((farmBulletinThresholds.cipo?.value as number) || 0.07).toFixed(2);
        case EReportType.DedicatedVisit:
          return numberUtils.convertToPercentage((farmBulletinThresholds.dedicatedVisit?.value as number) || 0.1);
        case EReportType.FreeArea:
          return (farmBulletinThresholds.freeArea?.value as number) || 2;
        case EReportType.Replant:
          return prev;
        default:
          return 0;
      }
    });
  }, [missionParams.type, farmBulletinThresholds]);

  const missionMetric = useMemo((): JSX.Element | string => {
    const handleInputChange = (e) => {
      const isReplantTreshold = e.target.name === 'replant_treshold';
      const { value } = e.target;
      if (value.length > e.target.maxLength) {
        e.target.value = value.slice(0, e.target.maxLength);
      }
      if (isReplantTreshold) {
        setReplantTresholdValue(parseInt(value));
      } else {
        setTresholdValue(parseFloat(value));
      }
    };
    const tresholdInput = <Input value={tresholdValue} name="treshold" type="number" onChange={handleInputChange} maxLength={3} />;
    const replantYearsInput = <Input value={replantTresholdValue} name="replant_treshold" type="number" onChange={handleInputChange} maxLength={3} />;
    switch (missionParams.type) {
      case EReportType.Height:
        return <Trans values={{ groves: precheckedGroves.length }} i18nKey="missions.blocks_with_avg_tree_height_greater_than" t={t} components={[<span />, tresholdInput]} />;
      case EReportType.Weeds:
        return <Trans values={{ groves: precheckedGroves.length }} i18nKey="missions.blocks_with_weed_segments_greater_than" t={t} components={[<span />, tresholdInput]} />;
      case EReportType.Cipo:
        return <Trans values={{ groves: precheckedGroves.length }} i18nKey="missions.blocks_with_bindweeds_infection_greater_than" t={t} components={[<span />, tresholdInput]} />;
      case EReportType.TargetedScouting:
        return t('missions.x_blocks_has_areas_with_new_weak_trees', { x: precheckedGroves.length });
      case EReportType.DedicatedVisit:
        return (
          <Trans values={{ groves: precheckedGroves.length }} i18nKey="missions.blocks_with_increase_in_weak_trees_of_more_than" t={t} components={[<span />, tresholdInput]} />
        );
      case EReportType.FreeArea:
        return <Trans values={{ groves: precheckedGroves.length }} i18nKey="missions.blocks_with_avg_free_area_less_than" t={t} components={[<span />, tresholdInput]} />;
      case EReportType.Replant:
        return (
          <Trans
            values={{ groves: precheckedGroves.length }}
            i18nKey="missions.blocks_younger_than_and_have_replant_potential_of_more_than"
            t={t}
            components={[<span />, replantYearsInput, tresholdInput]}
          />
        );
      default:
        return '';
    }
  }, [tresholdValue, precheckedGroves.length, replantTresholdValue, t, missionParams.type]);

  const handleSubmit = useCallback(() => {
    if (zoneID === undefined) return;
    if (preselectEnabled) {
      onSetCheckedGroves(precheckedGroves);
    }
    onCreateMission({
      ...missionParams,
      zoneID
    });
  }, [onCreateMission, preselectEnabled, precheckedGroves, onSetCheckedGroves, missionParams, zoneID]);

  const loading = statisticLoading || bulletinsLoading;

  return (
    <Wrapper>
      <Subtitle>{t('missions.select_blocks_by_parameters')}</Subtitle>
      {loading && (
        <Content>
          <LocalLoader />
        </Content>
      )}
      {!loading && (
        <Content>
          <Checkbox size={ESizes.small} checked={preselectEnabled} onCheckedChange={setPreselectEnabled} icon={faCheck} />
          {missionMetric}
        </Content>
      )}
      <Controls>
        <Button
          isDisabled={zoneID === undefined || loading}
          onClick={handleSubmit}
          text={t(preselectEnabled && precheckedGroves.length > 0 ? `missions.select_blocks${precheckedGroves.length > 1 ? '_plural' : ''}` : 'missions.select_blocks_from_map', {
            count: precheckedGroves.length
          })}
        />
      </Controls>
    </Wrapper>
  );
};

export default SelectBlocksByParameters;
