import React, { useState, useMemo, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faChevronLeft, faArrowDownWideShort, faArrowDownShortWide } from '@fortawesome/pro-solid-svg-icons';

import { mfColors } from 'vars';

const Table = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

interface IHeaderColumnProps {
  hasSorting: boolean;
}

const Sorter = styled(FontAwesomeIcon)`
  position: absolute;
  right: 0px;
  top: 50%;
  margin-top: -8px;
  font-size: 16px;
  width: 16px;
  color: ${mfColors.darkGrey};
`;

const HeaderColumn = styled.div<IHeaderColumnProps>`
  text-transform: uppercase;
  padding: 0 20px 0 8px;
  min-height: 54px;
  position: relative;
  cursor: ${({ hasSorting }) => (hasSorting ? 'pointer' : 'default')};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  border-top: 1px solid ${mfColors.grey};
  border-bottom: 1px solid ${mfColors.grey};
  background: ${mfColors.superLightBlue};
`;

const Body = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100% - 54px);
  overflow: auto;
`;

interface IRowProps {
  isSelectable?: boolean;
  isSelected?: boolean;
}

const Row = styled.div`
  display: flex;
  cursor: ${({ isSelectable }: IRowProps) => (isSelectable ? 'pointer' : 'default')};
  border-bottom: 1px solid ${mfColors.grey};
  background-color: ${({ isSelected }: IRowProps) => (isSelected ? mfColors.superLightBlue : mfColors.white)};

  &:last-child {
    border-bottom: 0px;
  }
`;

const Column = styled.div`
  padding: 12px 20px 12px 8px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Footer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: right;
  padding: 16px 24px;
  background: ${mfColors.superLightBlue};
`;

const FooterInfo = styled.div`
  margin: 0 48px 0 0;
`;

const FooterControls = styled.div`
  width: 80px;
  display: flex;
  justify-content: space-between;
`;

interface IFooterControlProps {
  isDisabled: boolean;
}

const FooterControl = styled.div`
  position: relative;
  cursor: ${({ isDisabled }: IFooterControlProps) => (isDisabled ? 'default' : 'pointer')};

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    display: ${({ isDisabled }) => (isDisabled ? 'block' : 'none')};
  }
`;

export interface IColumnConfig<T> {
  width: string;
  title?: JSX.Element | string;
  render: (model: T) => JSX.Element | string;
  key?: string | number;
  sortable?: boolean;
  style?: { [key: string]: string | undefined };
}

export enum ESortingDirection {
  ASC,
  DESC
}

interface IDefaultSortingConfig {
  key: string | number;
  direction: ESortingDirection;
}

interface IProps<T> {
  config: IColumnConfig<T>[];
  data: T[];
  placeholder?: JSX.Element;
  rowsPerPage?: number;
  selectedRow?: T | null;
  defaultSorting?: IDefaultSortingConfig | null;
  sortingFunction?: (a: T, b: T, key: string | number, sorting: ESortingDirection) => number;
  onRowSelect?: (model: T | null) => void;
  showFooter?: boolean;
  showHeader?: boolean;
  headerStyle?: { [key: string]: string | undefined };
  rowStyle?: { [key: string]: string | undefined };
  tableStyle?: { [key: string]: string | undefined };
}

const DataTable = <T extends { id: string }>({
  config,
  data,
  selectedRow,
  onRowSelect,
  placeholder,
  sortingFunction,
  defaultSorting = null,
  rowsPerPage = 5,
  showHeader = true,
  showFooter = true,
  headerStyle,
  rowStyle,
  tableStyle
}: IProps<T>) => {
  const { t } = useTranslation();
  const [page, setPage] = useState(0);
  const [rowsToDisplay, setRowsToDisplay] = useState(rowsPerPage);
  const [sorting, setSorting] = useState<IDefaultSortingConfig | null>(null);

  const toggleSorting = useCallback((key?: string | number) => {
    if (key === undefined) return;
    setSorting((prev) => {
      if (prev?.key !== key) return { key, direction: ESortingDirection.DESC };
      return {
        ...prev,
        direction: prev.direction === ESortingDirection.DESC ? ESortingDirection.ASC : ESortingDirection.DESC
      };
    });
  }, []);

  useEffect(() => {
    setSorting((prev) => prev || defaultSorting);
  }, [defaultSorting]);

  useEffect(() => {
    setRowsToDisplay(rowsPerPage);
  }, [rowsPerPage]);

  useEffect(() => {
    setPage(0);
  }, [data, config]);

  const dataToDisplay = useMemo(() => {
    // prettier-ignore
    const sortedData = sorting ? data.sort((a, b) => {
      if (sortingFunction) {
        return sortingFunction(a, b, sorting.key, sorting.direction);
      }
      if (sorting.direction === ESortingDirection.DESC) {
        if (b[sorting.key] === undefined) return -1;
        return a[sorting.key] > b[sorting.key] ? -1 : 1;
      }
      return a[sorting.key] > b[sorting.key] ? 1 : -1;
    }) : data;

    return rowsToDisplay ? sortedData.slice(rowsToDisplay * page, rowsToDisplay * (page + 1)) : sortedData;
  }, [page, rowsToDisplay, data, sortingFunction, sorting]);

  const displayedItemsStats = useMemo(() => {
    const currentRangeEnd = rowsToDisplay * (page + 1);
    return t('shared.table.items_range', { count1: rowsToDisplay * page + 1, count2: data.length > currentRangeEnd ? currentRangeEnd : data.length, count3: data.length });
  }, [page, rowsToDisplay, data, t]);

  const isLastPage = useMemo(() => page === Math.ceil(data.length / rowsToDisplay) - 1, [page, data, rowsToDisplay]);

  return (
    <Table>
      {showHeader && (
        <Header style={headerStyle}>
          {config.map((configEntry, index) => (
            <HeaderColumn
              onClick={() => configEntry.sortable && toggleSorting(configEntry.key)}
              hasSorting={!!configEntry.sortable}
              key={index}
              style={{ ...configEntry.style, width: configEntry.width }}
            >
              <div>{configEntry.title}</div>
              {configEntry.sortable && sorting && sorting.key === configEntry.key && (
                <Sorter icon={sorting.direction === ESortingDirection.DESC ? faArrowDownWideShort : faArrowDownShortWide} />
              )}
            </HeaderColumn>
          ))}
        </Header>
      )}
      <Body style={tableStyle}>
        {dataToDisplay.length === 0 ? (
          <Row style={rowStyle}>{placeholder}</Row>
        ) : (
          dataToDisplay.map((dataEntry) => (
            <Row
              style={rowStyle}
              isSelectable={!!onRowSelect}
              isSelected={dataEntry.id === selectedRow?.id}
              onClick={() => onRowSelect && onRowSelect(dataEntry.id === selectedRow?.id ? null : dataEntry)}
              key={dataEntry.id}
            >
              {config.map((configEntry, index) => (
                <Column key={index} style={{ ...configEntry.style, width: configEntry.width }}>
                  {configEntry.render(dataEntry)}
                </Column>
              ))}
            </Row>
          ))
        )}
      </Body>
      {dataToDisplay.length > 0 && showFooter && (
        <Footer>
          <FooterInfo>{t('shared.table.rows_per_page', { count: rowsToDisplay })}</FooterInfo>
          <FooterInfo>{displayedItemsStats}</FooterInfo>
          <FooterControls>
            <FooterControl isDisabled={page === 0} onClick={page > 0 ? () => setPage((prev) => prev - 1) : undefined}>
              <FontAwesomeIcon icon={faChevronLeft} />
            </FooterControl>
            <FooterControl isDisabled={isLastPage} onClick={!isLastPage ? () => setPage((prev) => prev + 1) : undefined}>
              <FontAwesomeIcon icon={faChevronRight} />
            </FooterControl>
          </FooterControls>
        </Footer>
      )}
    </Table>
  );
};

export default DataTable;
