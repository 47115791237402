import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from 'redux/store';
import { IGrove } from 'models/grove';
import { IZone } from 'models/zone';
import { IFarm, IFarmName } from 'models/farm';
import { ISurvey, ISurveyWithTimeRange } from 'models/survey';
import { IGroveSurveyStats } from 'models/stats';
import { TMappedStatistic } from 'models/statistic';
import { getUser, getUserFarms } from 'redux/auth/authSlice';
import { indexBy, head } from 'utils/helpers';

interface IFarmState {
  selectedGrove: IGrove | null;
  selectedZone: IZone | null;
  selectedFarm: IFarm | null;
  selectedSurvey: ISurveyWithTimeRange | null;
  farms: IFarm[];
  zones: IZone[];
  groves: IGrove[];
  surveys: ISurvey[];
  allSurveys: ISurveyWithTimeRange[];
  grovesSurveysStats: IGroveSurveyStats[];
  grovesBqStatistic: TMappedStatistic | null;
}

const initialState = {
  selectedGrove: null,
  selectedSurvey: null,
  farms: [] as IFarm[],
  zones: [] as IZone[],
  groves: [] as IGrove[],
  surveys: [] as ISurvey[],
  grovesSurveysStats: [] as IGroveSurveyStats[],
  grovesBqStatistic: null
} as IFarmState;

const slice = createSlice({
  name: 'farm',
  initialState,
  reducers: {
    setSelectedGrove: (state, action: PayloadAction<IGrove | null>) => ({ ...state, selectedGrove: action.payload }),
    setSelectedZone: (state, action: PayloadAction<IZone | null>) => ({ ...state, selectedZone: action.payload }),
    setSelectedFarm: (state, action: PayloadAction<IFarm | null>) => ({ ...state, selectedFarm: action.payload }),
    setSelectedSurvey: (state, action: PayloadAction<ISurveyWithTimeRange | null>) => ({ ...state, selectedSurvey: action.payload }),
    setFarms: (state, action: PayloadAction<IFarm[]>) => ({ ...state, farms: action.payload }),
    setZones: (state, action: PayloadAction<IZone[]>) => ({ ...state, zones: action.payload }),
    setGroves: (state, action: PayloadAction<IGrove[]>) => ({ ...state, groves: action.payload }),
    setSurveys: (state, action: PayloadAction<ISurvey[]>) => ({ ...state, surveys: action.payload }),
    setGrovesSurveysStats: (state, action: PayloadAction<IGroveSurveyStats[]>) => ({ ...state, grovesSurveysStats: action.payload }),
    setGrovesBqStatistic: (state, action: PayloadAction<TMappedStatistic | null>) => ({
      ...state,
      grovesBqStatistic: action.payload
    })
  }
});

export const farmReducer = slice.reducer;

export const getSelectedGrove = (state: RootState) => state.farm.selectedGrove;
export const getSelectedSurvey = (state: RootState) => state.farm.selectedSurvey;
export const getSelectedZone = (state: RootState) => state.farm.selectedZone;
export const getSelectedFarm = (state: RootState) => state.farm.selectedFarm;
export const farmsSelector = createSelector([getUserFarms], (userFarms) => {
  if (!userFarms) return [];

  const farms = Object.keys(userFarms).reduce((acc, next) => {
    if (!userFarms[next].permitted) return acc;

    return acc.concat({ name: userFarms[next].name, id: next });
  }, [] as IFarmName[]);

  return farms;
});
export const zonesSelector = (state: RootState) => state.farm.zones;
export const grovesSelector = (state: RootState) => state.farm.groves;
export const surveysSelector = (state: RootState) => state.farm.surveys;
export const grovesSurveysStatsSelector = (state: RootState) => state.farm.grovesSurveysStats;
export const grovesBqStatisticSelector = (state: RootState) => state.farm.grovesBqStatistic;

export const getSelectedFarmSurvey = createSelector([getSelectedFarm, getSelectedSurvey], (selectedFarm, selectedSurvey) => {
  const isSameFarmID = selectedFarm?.id === selectedSurvey?.farmID;

  return isSameFarmID ? selectedSurvey : null;
});

export const selectedAreaStatsSelector = createSelector([grovesSurveysStatsSelector, getSelectedGrove, getSelectedZone], (grovesSurveysStats, selectedGrove, selectedZone) => {
  const stats = grovesSurveysStats.filter((stat) => {
    if (selectedGrove) {
      return stat.groveID === selectedGrove.id;
    } else if (selectedZone) {
      return stat.zoneID === selectedZone.id;
    }

    return stat;
  });
  return stats;
});

export const selectedSurveyStatsSelector = createSelector([grovesSurveysStatsSelector, getSelectedSurvey], (grovesSurveysStats, selectedSurvey) => {
  if (!selectedSurvey) return [];

  return grovesSurveysStats.filter((stats) => stats.surveyID === selectedSurvey.id);
});

export const allSurveysSelector = createSelector([surveysSelector, selectedAreaStatsSelector], (surveys, selectedAreaStats): ISurveyWithTimeRange[] => {
  const surveysWithTimeRange = surveys
    .map((survey) => {
      const surveyStats = selectedAreaStats
        .filter((stat) => stat.surveyID === survey.id)
        .map((stat) => ({ ...stat, shotAt: stat.shotAt || survey.date || '' }))
        .sort((a, b) => (b.shotAt < a.shotAt ? 1 : -1));
      return {
        ...survey,
        from: surveyStats?.[0]?.shotAt || survey.date || '',
        to: surveyStats?.[surveyStats.length - 1]?.shotAt || survey.date || ''
      };
    })
    .filter((survey) => !!survey) as ISurveyWithTimeRange[];

  return surveysWithTimeRange.sort((a, b) => (b.from < a.from ? -1 : 1));
});

export const surveysWithDatesSelector = createSelector([surveysSelector, grovesSurveysStatsSelector, getUser], (surveys, grovesSurveysStats, user): ISurveyWithTimeRange[] => {
  const publishedSurveys = surveys.filter((survey) => survey.status === 'published');

  return (
    publishedSurveys
      .map((survey) => {
        const surveyStats = grovesSurveysStats
          .filter((stat) => stat.surveyID === survey.id)
          .map((stat) => ({ ...stat, shotAt: stat.shotAt || survey.date || '' }))
          .sort((a, b) => (b.shotAt < a.shotAt ? 1 : -1));
        return {
          ...survey,
          from: surveyStats?.[0]?.shotAt || survey.date || '',
          to: surveyStats?.[surveyStats.length - 1]?.shotAt || survey.date || ''
        };
      })
      .filter((survey) => !!survey && survey.date) as ISurveyWithTimeRange[]
  ).sort((a, b) => (b.from < a.from ? -1 : 1));
});

export const latestSurveyStatsSelector = createSelector([grovesSurveysStatsSelector, surveysWithDatesSelector], (grovesSurveysStats, surveys) => {
  const latestSurvey = surveys[0];

  if (!latestSurvey) return [];

  const data = grovesSurveysStats.filter((stats) => stats.surveyID === latestSurvey.id);

  return data;
});

export const previousSurveySelector = createSelector([surveysWithDatesSelector, getSelectedSurvey], (surveys, selectedSurvey) => {
  if (!selectedSurvey) return null;

  const surveyIndex = surveys.findIndex((survey) => survey.id === selectedSurvey.id);
  return surveyIndex < surveys.length - 1 ? surveys[surveyIndex + 1] : null;
});

export const nextSurveySelector = createSelector([surveysWithDatesSelector, getSelectedSurvey], (surveys, selectedSurvey) => {
  if (!selectedSurvey) return null;

  const surveyIndex = surveys.findIndex((survey) => survey.id === selectedSurvey.id);

  return surveyIndex <= surveys.length - 1 ? surveys[surveyIndex - 1] : null;
});

export const isOldSurveySelector = createSelector([surveysWithDatesSelector, getSelectedSurvey], (surveys, selectedSurvey) => {
  const surveyIndex = surveys.findIndex((survey) => survey.id === selectedSurvey?.id);

  return surveyIndex >= 2;
});

export const feedViewBqStatsSelector = createSelector([grovesBqStatisticSelector, getSelectedGrove, getSelectedZone, grovesSelector], (grovesStats, grove, zone, groves) => {
  if (!grovesStats) {
    return null;
  }

  const selectedGroves = Object.keys(grovesStats).filter((id) => {
    if (grove) {
      return id === grove.id;
    } else if (zone) {
      const groveIDs = groves.filter((grove) => grove.zoneID === zone.id).map((grove) => grove.id);
      return groveIDs.includes(id);
    }

    return id;
  });
  return selectedGroves.reduce((acc, id) => ({ ...acc, [id]: grovesStats[id] }), {});
});

export const isLastSurveySelectedSelector = createSelector([allSurveysSelector, getSelectedSurvey], (allSurveys, selectedSurvey) => {
  const lastSurvey = allSurveys[0];

  return selectedSurvey?.id === lastSurvey?.id;
});

export const publishedGrovesInLastSurveysSelector = createSelector(
  [allSurveysSelector, getSelectedSurvey, grovesSurveysStatsSelector],
  (allSurveys, selectedSurvey, grovesSurveysStats) => {
    const lastSurvey = allSurveys.filter((survey) => survey.status === 'published')[0];

    if (selectedSurvey?.id === lastSurvey?.id || !selectedSurvey) {
      return [];
    }

    const grovesSurveysStatsBySurvey = grovesSurveysStats.filter((survey) => survey.surveyID === lastSurvey?.id && survey.isPublished);

    return grovesSurveysStatsBySurvey;
  }
);

export const surveyGrovesSelector = createSelector([grovesSelector, selectedSurveyStatsSelector], (groves, grovesSurveysStats) => {
  const indexedStats: { [groveID: string]: IGroveSurveyStats[] } = indexBy('groveID', grovesSurveysStats);

  if (!grovesSurveysStats.length) return [];

  return groves.map((grove) => {
    const { isIncluded } = head(indexedStats[grove.id]) || {};

    return {
      ...grove,
      active: !!isIncluded
    };
  });
});

export const latestSurveyGrovesSelector = createSelector([grovesSelector, latestSurveyStatsSelector], (groves, grovesSurveysStats) => {
  const indexedStats: { [groveID: string]: IGroveSurveyStats[] } = indexBy('groveID', grovesSurveysStats);

  if (!grovesSurveysStats.length) return [];

  return groves.map((grove) => {
    const { isIncluded } = head(indexedStats[grove.id]) || {};

    return {
      ...grove,
      active: !!isIncluded
    };
  });
});

export const getFarmBulletinRelevandMonths = createSelector([getSelectedFarm], (farm) => {
  if (!farm) {
    return {};
  }

  return farm.bulletinRelevantMonths || {};
});

/* eslint-disable */
export const { setSelectedGrove, setSelectedSurvey, setSelectedZone, setSelectedFarm, setFarms, setZones, setGroves, setSurveys, setGrovesSurveysStats, setGrovesBqStatistic } = slice.actions;
/* eslint-enable */
