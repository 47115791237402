import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Unsubscribe } from 'firebase/firestore';
import * as turf from '@turf/turf';
import { EMeasurementSystem } from 'models/region';
import { IReplantRules, IReplantRulesOptions } from 'models/replant';
import { ERichItemType, ICapacityRichItemGeojson } from 'models/richItem';
import { IFarmCapacityStatistic, IHealthStatistic, TMappedStatistic } from 'models/statistic';
import {
  setFarmReplantRules,
  setReplantCapacity,
  setReplantsIsLoaded,
  setStateReplantRules,
  replantRulesSelector,
  capacityReplantReportsSelector,
  updateHiddenSegments,
  resetNewSegments
} from 'redux/replant/replantSlice';
import {
  listenFarmReplantRules,
  useUpdateReplantRules,
  useResetCalculationReplantRules,
  useAddReplantRules,
  getReplantRulesBy,
  hideReplantSegment,
  getDrawReplantSegments
} from 'services/data/replant';
import { useFetchBigQueryGeojson, useFetchStatistic } from 'services/data/richItems';
import replantUtils from 'utils/replant';
import { IReplantReport, EReportType, DEFAULT_REPORT_TRESHOLDS } from 'models/report';
import { IGrove } from 'models/grove';
import { IFarmBulletinThreshold } from 'models/company';
import dateUtils from 'utils/date';
import scoreUtils from 'utils/score';
import { NON_REPLANT_TREES } from 'models/scores';
import { ICapacity } from 'models/tree';
import { IPopupFeature } from 'hooks/map.hooks';
import useLogger from 'hooks/logger.hooks';
import farmsHooks, { IUnifiedGeojson } from 'hooks/farms.hooks';
import { IRichItemMeasurementRanges } from 'services/data/appConfig';
import { parseLineBqToGeojson } from 'utils/helpers';

const DEFAULT_REPLANT_RULES = {
  [EMeasurementSystem.Metric]: {
    _0_replants_in_segment_up_to_length: 2,
    _1_replant_in_segment_up_to_length: 3,
    _2_replants_in_segment_up_to_length: 5,
    _3_replants_in_segment_up_to_length: 7.5,
    _5_or_more_replants_spacing_num: 2.5
  } as IReplantRulesOptions,
  [EMeasurementSystem.Imperial]: {
    _0_replants_in_segment_up_to_length: 6.6,
    _1_replant_in_segment_up_to_length: 9.8,
    _2_replants_in_segment_up_to_length: 16.4,
    _3_replants_in_segment_up_to_length: 24.6,
    _5_or_more_replants_spacing_num: 8.2
  } as IReplantRulesOptions
};
export const NON_SURVEYID = 'noSurveyID';

const useFarmReplantRules = (farmID?: string, metricType = EMeasurementSystem.Metric) => {
  const dispatch = useDispatch();
  const { logError } = useLogger();

  useEffect(() => {
    let replantRulesListener: Unsubscribe;

    if (farmID) {
      const onSuccess = async (data: IReplantRules) => {
        if (!data) {
          dispatch(setFarmReplantRules({} as IReplantRules));

          return;
        }

        const { id, calculationSettings } = data;
        // NOTE: turned off predictions
        const originalSettings = DEFAULT_REPLANT_RULES[metricType];

        if (calculationSettings) {
          dispatch(setFarmReplantRules({ id, ...calculationSettings }));
        } else {
          dispatch(setFarmReplantRules({ id, ...originalSettings }));
        }
      };
      const onError = (e, level, storageType) => {
        logError(e as Error, level, storageType);
        dispatch(setFarmReplantRules({} as IReplantRules));
      };
      replantRulesListener = listenFarmReplantRules(farmID, onSuccess, onError);
    }

    return () => {
      replantRulesListener && replantRulesListener();
    };
  }, [farmID, metricType, dispatch, logError]);
};

const useResetFarmRules = () => {
  const resetReplantRules = useResetCalculationReplantRules();

  return useCallback(
    async (docID: string) => {
      await resetReplantRules(docID);
    },
    [resetReplantRules]
  );
};

const useGetMissionReplantRules = (missionID?: string) => {
  const dispatch = useDispatch();
  const { logError } = useLogger();

  useEffect(() => {
    if (missionID) {
      getReplantRulesBy({ missionID }).then((data) => {
        if (data) {
          dispatch(setStateReplantRules(data));
        }
      });
    }

    return () => {
      dispatch(setStateReplantRules(null));
    };
  }, [missionID, dispatch, logError]);
};

const useReplantCapacityStatistic = (richItemType: ERichItemType | null, farmID?: string, surveyID?: string, measurementSystem?: EMeasurementSystem) => {
  const fetchStatistic = useFetchStatistic();
  const dispatch = useDispatch();
  const replantRules = useSelector(replantRulesSelector);
  const { selectedSurvey } = farmsHooks.useSelectedFarmEntities();
  const zeroReplantRule = useMemo(() => replantRules?._0_replants_in_segment_up_to_length, [replantRules]);
  useFarmReplantRules(farmID, measurementSystem);

  const getStatistic = useCallback(
    async (farmID: string, surveyID: string, richItemType: ERichItemType, zeroReplantRule: number, measurementSystem?: EMeasurementSystem, segmentsSurveyID?: string) => {
      dispatch(setReplantsIsLoaded(true));
      const params = {
        length_key: measurementSystem === EMeasurementSystem.Metric ? 'length_m' : 'length_ft',
        length_value: zeroReplantRule
      };
      const [data, drawSegments] = await Promise.all([fetchStatistic(farmID, surveyID, richItemType, params), getDrawReplantSegments({ farmID, surveyID: segmentsSurveyID })]);
      const replantStatistic = data ? replantUtils.formatToReplantStatistic(data as IFarmCapacityStatistic, drawSegments) : null;

      dispatch(resetNewSegments());
      dispatch(setReplantCapacity(replantStatistic));
      dispatch(setReplantsIsLoaded(false));
    },
    [fetchStatistic, dispatch]
  );

  useEffect(() => {
    if (farmID && surveyID && richItemType && zeroReplantRule) {
      getStatistic(farmID, surveyID, richItemType, zeroReplantRule, measurementSystem, selectedSurvey?.id);
    } else {
      dispatch(setReplantCapacity(null));
    }
  }, [farmID, surveyID, richItemType, getStatistic, dispatch, zeroReplantRule, measurementSystem, selectedSurvey]);

  useEffect(
    () => () => {
      if (surveyID && surveyID !== NON_SURVEYID) {
        dispatch(setReplantCapacity(null));
      }
    },
    [surveyID, dispatch]
  );
};

const useHideSegment = (farmID?: string, groveID?: string, surveyID?: string) => {
  const dispatch = useDispatch();

  return useCallback(
    async (segment: ICapacity) => {
      if (farmID && groveID && surveyID) {
        await hideReplantSegment({ farmID, segmentID: segment.segmentID, groveID, surveyID });
        dispatch(updateHiddenSegments(segment));
      }
    },
    [dispatch, farmID, groveID, surveyID]
  );
};

const useSegmentClickHandler = (onSegmentClick: (feature: IPopupFeature | null) => void) => {
  const segmentClickHandler = useCallback(
    (e) => {
      const segmentFeature = e.features[0];
      if (!segmentFeature) {
        onSegmentClick(null);

        return;
      }

      const center = turf.center(segmentFeature.geometry);
      const popupFeature = {
        coordinates: center.geometry.coordinates as number[],
        capacity: segmentFeature.properties.capacity,
        length: segmentFeature.properties.length,
        segmentID: segmentFeature.properties.id,
        type: 'line'
      } as IPopupFeature;

      onSegmentClick(popupFeature);
    },
    [onSegmentClick]
  );

  return segmentClickHandler;
};

const useClearReplantRules = (farmID?: string) => {
  const dispatch = useDispatch();

  useEffect(
    () => () => {
      if (farmID) {
        dispatch(setStateReplantRules(null));
      }
    },
    [farmID, dispatch]
  );
};

const useReplantBulletinGroves = (groves: IGrove[], healthStatistic: TMappedStatistic | null, farmBulletinThresholds: IFarmBulletinThreshold) => {
  const replantReports = useSelector(capacityReplantReportsSelector);

  const getBulletinGroves = useMemo(() => {
    if (!healthStatistic) return [];

    const thresholds = farmBulletinThresholds[EReportType.Replant]?.value || DEFAULT_REPORT_TRESHOLDS[EReportType.Replant];

    return replantReports.reduce((acc, next) => {
      const { groveID } = next;
      const grove = groves.find((item) => item.id === groveID);
      const groveAge = dateUtils.getYearDifference(grove?.attributes?.originalPlantYear || '');
      const scores = healthStatistic[groveID] as IHealthStatistic;

      if (!scores) return acc;

      const healthyScores = scoreUtils.getScoresSum(scores, NON_REPLANT_TREES);
      const replantsPercantage = next.replants / healthyScores;

      if (groveAge < thresholds[0] && replantsPercantage > thresholds[1]) {
        return acc.concat(next);
      } else {
        return acc;
      }
    }, [] as IReplantReport[]);
  }, [replantReports, groves, healthStatistic, farmBulletinThresholds]);

  return getBulletinGroves;
};

const useFetchDrawSegments = (
  activeRichItem: ERichItemType,
  farmID?: string,
  groveID?: string,
  surveyID?: string,
  richItemMeasurementRanges?: IRichItemMeasurementRanges,
  measurementSystem = EMeasurementSystem.Metric
) => {
  const [loading, setLoading] = useState(false);
  const [geoJSON, setGeoJSON] = useState<IUnifiedGeojson | null>(null);
  const fetchBigQueryGeojson = useFetchBigQueryGeojson();

  const fetchRichItemGeojson = useCallback(
    async (farmID: string, groveID: string, surveyID: string, richItemType: ERichItemType, mapbox): Promise<void> => {
      setLoading(true);
      const geoData = await fetchBigQueryGeojson(farmID, groveID, surveyID, richItemType);
      const drawSegments = await getDrawReplantSegments({ farmID, groveID });

      drawSegments.forEach((segment) => {
        const { lineString, id } = segment;
        const length = lineString.properties?.length;

        if (length && id) {
          Object.assign(geoData as ICapacityRichItemGeojson, { [id]: { geometry: lineString.geometry, length } });
        }
      });

      if (geoData) {
        const parsedGeoData = parseLineBqToGeojson(geoData as ICapacityRichItemGeojson);
        setGeoJSON({ geojson: parsedGeoData, mapbox });
        setLoading(false);
      }
    },
    [fetchBigQueryGeojson]
  );

  useEffect(() => {
    setGeoJSON(null);
    if (farmID && groveID && surveyID && activeRichItem && richItemMeasurementRanges) {
      const richItemMetric = richItemMeasurementRanges[measurementSystem][activeRichItem].mapbox as IUnifiedGeojson['mapbox'];
      fetchRichItemGeojson(farmID, groveID, surveyID, activeRichItem, richItemMetric);
    }
  }, [farmID, groveID, surveyID, activeRichItem, fetchRichItemGeojson, richItemMeasurementRanges, measurementSystem]);

  return {
    geoJSON,
    loading
  };
};

const replantHooks = {
  useFarmReplantRules,
  useUpdateReplantRules,
  useAddReplantRules,
  useReplantCapacityStatistic,
  useResetCalculationReplantRules,
  useResetFarmRules,
  useHideSegment,
  useGetMissionReplantRules,
  useSegmentClickHandler,
  useClearReplantRules,
  useReplantBulletinGroves,
  useFetchDrawSegments
};

export default replantHooks;
