import React from 'react';
import styled from 'styled-components';
import { mfColors } from 'vars';

interface ProgressStyled {
  width: number;
}

const Container = styled.div`
  display: flex;
`;
const ProgressContainer = styled.div`
  width: 100%;
  height: 20px;
  border-radius: 10px;
  border: 3px solid ${mfColors.grey50};
  align-self: center;
`;

const ProgressBar = styled.div<ProgressStyled>`
  background-color: ${mfColors.lightBlue};
  width: ${(props) => props.width}%;
  height: -webkit-fill-available;
  border-radius: ${(props) => (props.width > 98 ? '10px' : '10px 0px 0px 10px')};
  transition: width 2s ease-out;
`;

const ProgressValue = styled.div`
  min-width: 55px;
  margin-left: 12px;
  color: ${mfColors.lightBlue};
`;

interface Irops {
  value: number;
}

const Progress = ({ value }: Irops) => (
  <Container>
    <ProgressContainer>
      <ProgressBar width={value} />
    </ProgressContainer>
    <ProgressValue>{`${value}%`}</ProgressValue>
  </Container>
);

export default Progress;
