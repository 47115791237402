import React, { useState, useEffect, useMemo, useCallback } from 'react';
import styled from 'styled-components';
import { useModal } from 'react-modal-hook';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/pro-solid-svg-icons';
import { faCheckCircle, faTriangleExclamation } from '@fortawesome/pro-regular-svg-icons';
import { useSelector } from 'react-redux';

import missionUtils, { IMetricGroveEntry, IMissionGrove, EGroveMissionStatus } from 'utils/mission';
import regionsHooks from 'hooks/regions.hooks';
import missionHooks from 'hooks/mission.hooks';
import { useFetchGrovesStats } from 'services/data/farms';

import MissionType from 'components/missions/MissionType';
import DataTable, { ESortingDirection } from 'atomicComponents/DataTable';
import Modal from 'atomicComponents/Modal';
import MissionModal from 'components/missions/MissionModal';
import Checkbox, { ESizes } from 'atomicComponents/Checkbox';
import FormInput from 'atomicComponents/FormInput';
import Popover from 'atomicComponents/Popover';
import { ISurveyWithTimeRange } from 'models/survey';
import { ICreateMissionIncompleteParams, IMission, EMissionKpi } from 'models/mission';
import { EMeasurementSystem } from 'models/region';
import { EEventType } from 'models/analytics';
import { IGroveSurveyStats } from 'models/stats';
import { getFarmBulletinThresholds } from 'redux/bulletin/bulletinSlice';

import { AlertIcon } from 'assets/images';

import { mfSizes, mfColors } from 'vars';
import { latestSurveyGrovesSelector } from 'redux/farm/farmSlice';

const Wrapper = styled.div`
  height: 100%;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 16px;
  padding: ${mfSizes.missionsPadding};
`;

const BackIcon = styled(FontAwesomeIcon)`
  margin: 0 4px 0 0;
`;

const Back = styled.div`
  cursor: pointer;
  text-transform: uppercase;
  font-size: 16px;
  display: flex;
  flex-direction: row;
  font-family: MontserratBold;
`;

const MissionTitle = styled.div`
  margin: 0 0 16px;
  padding: ${mfSizes.missionsPadding};
`;

const TableWrapper = styled.div`
  height: calc(100% - 112px);
  padding: ${mfSizes.missionsPadding};
  overflow: auto;

  .success {
    color: ${mfColors.green};
  }

  .warning {
    color: ${mfColors.warningYellow};
  }
`;

const RowPrimaryText = styled.div`
  font-family: MontserratBold;

  &.inactive {
    color: ${mfColors.grey};
  }
`;

interface IProps {
  mission: ICreateMissionIncompleteParams | IMission;
  survey: ISurveyWithTimeRange;
  previousSurvey: ISurveyWithTimeRange | null;
  missions: IMission[];
  checkedGroves: string[];
  setCheckedGroves: (groves: string[] | ((groves: string[]) => string[])) => void;
  setTitle: (title: string) => void;
  onGoToOverview: (missionCreated?: boolean) => void;
}

const MissionGroveSelectionMenu = ({ mission, missions, survey, previousSurvey, checkedGroves, setCheckedGroves, onGoToOverview, setTitle }: IProps) => {
  const { t } = useTranslation();
  const [surveyStats, setSurveyStats] = useState<IGroveSurveyStats[]>([]);
  const groves = useSelector(latestSurveyGrovesSelector);
  const fetchGrovesStats = useFetchGrovesStats();
  const { logMissionEvent } = missionHooks.useMissionLogger();

  useEffect(() => {
    fetchGrovesStats(mission.farmID).then(setSurveyStats);
  }, [mission.farmID, fetchGrovesStats]);

  const grovesWithStatuses = useMemo(() => {
    const currentSurveyStats = surveyStats.filter((stat) => stat.surveyID === survey.id);
    const previousSurveyStats = previousSurvey?.id ? surveyStats.filter((stat) => stat.surveyID === previousSurvey.id) : [];

    return groves.map((grove): IMissionGrove => {
      const lastSurveyStat = currentSurveyStats.find((stat) => stat.groveID === grove.id && stat.isPublished);
      const previousSurveyStat = previousSurveyStats.find((stat) => stat.groveID === grove.id && stat.isPublished);
      let listStatus = EGroveMissionStatus.Published;

      if (grove.active === false && !lastSurveyStat) {
        listStatus = EGroveMissionStatus.Inactive;
      } else if (!lastSurveyStat && !!previousSurveyStats) {
        listStatus = EGroveMissionStatus.Outdated;
      } else {
        const lastPublishDate = lastSurveyStat?.publishedAt || previousSurveyStat?.publishedAt || null;
        const lastUpdatedInMission = missions.find((entry) => entry.type === mission.type && !!entry.logs?.[grove.id])?.timestamp;

        if (lastPublishDate && lastUpdatedInMission && lastUpdatedInMission > lastPublishDate) {
          listStatus = EGroveMissionStatus.Treated;
        }
      }

      return { ...grove, listStatus };
    });
  }, [mission.type, surveyStats, groves, survey.id, previousSurvey?.id, missions]);

  const grovesToShow = missionHooks.useMissionGroves(mission, grovesWithStatuses, survey);

  const [allGrovesChecked, setAllGrovesChecked] = useState<boolean>(!grovesToShow.find((grove) => !checkedGroves.includes(grove.id)));

  const farmBulletinThresholds = useSelector(getFarmBulletinThresholds);

  const missionGroves = (mission as IMission).groves;
  const missionID = (mission as IMission).id;
  const metricType = regionsHooks.useGetMeasurementSystem() || EMeasurementSystem.Metric;
  const [showConfirmLeaveModal, hideConfirmLeaveModal] = useModal(() => {
    const handleClose = () => {
      hideConfirmLeaveModal();
      onGoToOverview(false);
      logMissionEvent(mission, EEventType.MissionCreateExit);
    };
    return (
      <Modal showClose={false} width="520px" onClose={hideConfirmLeaveModal}>
        <MissionModal
          onOk={handleClose}
          onCancel={hideConfirmLeaveModal}
          icon={<AlertIcon />}
          iconFirst
          title={t('shared.leaving')}
          content={t(missionID ? 'missions.this_edit_will_not_be_saved' : 'missions.this_mission_will_not_be_created')}
          okText={t(missionID ? 'missions.yes_cancel_edit' : 'missions.yes_cancel_mission')}
          cancelText={t('missions.no_go_back')}
        />
      </Modal>
    );
  }, [t, onGoToOverview, logMissionEvent, mission, missionID]);

  useEffect(() => {
    setTitle(mission.title);
  }, [mission.title, setTitle]);

  useEffect(() => {
    if (allGrovesChecked) {
      setCheckedGroves((prev) => [...new Set([...prev, ...grovesToShow.map((grove) => grove.id)])]);
    } else {
      setCheckedGroves((prev) => {
        const isAllChecked = !!grovesToShow.length && !grovesToShow.find((grove) => !prev.includes(grove.id));
        return isAllChecked ? [] : prev;
      });
    }
  }, [allGrovesChecked, grovesToShow, setCheckedGroves]);

  const toggleCheckedGrove = useCallback(
    (groveID: string, isChecked: boolean) => {
      if (isChecked) {
        setCheckedGroves((prev) => [...prev, groveID]);
      } else {
        setCheckedGroves((prev) => prev.filter((grove) => grove !== groveID));
        setAllGrovesChecked(false);
      }
    },
    [setCheckedGroves]
  );

  const tableConfig = useMemo(() => {
    const kpis = mission.kpis || missionUtils.getDefaultKpis(mission.type);
    const kpiColumns = kpis.map((kpi, index) => missionUtils.getMissionColumnDescriptor(kpi, index, metricType, farmBulletinThresholds, t));

    return [
      {
        title: t('missions.grove'),
        render: (grove: Pick<IMissionGrove, 'name' | 'listStatus'>) => (
          <RowPrimaryText className={grove.listStatus === EGroveMissionStatus.Inactive ? 'inactive' : undefined}>{grove.name}</RowPrimaryText>
        ),
        key: 'name',
        sortable: true,
        width: '70px',
        style: {
          fontSize: '11.5px',
          justifyContent: 'flex-start'
        }
      },
      {
        title: '',
        render: (grove: Pick<IMissionGrove, 'listStatus'>) => {
          switch (grove.listStatus) {
            case EGroveMissionStatus.Outdated:
            case EGroveMissionStatus.Treated:
              return (
                <Popover
                  control={
                    <span className={grove.listStatus === EGroveMissionStatus.Treated ? 'success' : 'warning'}>
                      <FontAwesomeIcon icon={grove.listStatus === EGroveMissionStatus.Treated ? faCheckCircle : faTriangleExclamation} />
                    </span>
                  }
                  content={<span>{t(grove.listStatus === EGroveMissionStatus.Treated ? 'missions.grove_was_treated' : 'missions.data_was_not_updated')}</span>}
                />
              );
            default:
              return '';
          }
        },
        key: 'listStatus',
        sortable: true,
        width: '36px'
      },
      ...kpiColumns,
      {
        title: <Checkbox checked={allGrovesChecked} onCheckedChange={setAllGrovesChecked} size={ESizes.small} style={{ marginRight: 12 }} />,
        render: (grove: IMissionGrove) => {
          const disabled = grove.listStatus === EGroveMissionStatus.Inactive;
          return (
            <Checkbox
              isDisabled={disabled}
              checked={checkedGroves.includes(grove.id)}
              onCheckedChange={(isChecked: boolean) => toggleCheckedGrove(grove.id, isChecked)}
              size={ESizes.small}
            />
          );
        },
        width: '36px',
        style: {
          fontSize: '11.5px',
          padding: '0'
        }
      }
    ];
  }, [t, toggleCheckedGrove, checkedGroves, allGrovesChecked, mission.type, mission.kpis, metricType, farmBulletinThresholds]);

  const sortingFunction = useCallback((a: IMetricGroveEntry, b: IMetricGroveEntry, key: string | number, sorting: ESortingDirection) => {
    if (key !== 'name' && b.listStatus === EGroveMissionStatus.Inactive) return -1;
    const value1 = a[key] || a.kpi[key];
    const value2 = b[key] || b.kpi[key];
    if (value1 === value2) return 0;
    if (sorting === ESortingDirection.ASC) {
      if (!value1) return 1;
      if (!value2) return -1;
      return value2 > value1 ? -1 : 1;
    }
    return value2 > value1 ? 1 : -1;
  }, []);

  const tableProps = useMemo(() => {
    const kpis = mission.kpis || missionUtils.getDefaultKpis(mission.type);
    const props = {
      config: tableConfig,
      data: grovesToShow,
      defaultSorting: {
        key: 0,
        direction: kpis[0] === EMissionKpi.FreeArea ? ESortingDirection.ASC : ESortingDirection.DESC
      },
      sortingFunction,
      showFooter: false,
      rowsPerPage: 0
    };
    return props;
  }, [tableConfig, grovesToShow, sortingFunction, mission.kpis, mission.type]);

  const hasModifiedGroves = useMemo(() => {
    if (missionID) {
      return !!checkedGroves.find((groveID) => !missionGroves[groveID]) || !!Object.keys(missionGroves).find((groveID) => !checkedGroves.includes(groveID));
    } else {
      return !!checkedGroves.length;
    }
  }, [checkedGroves, missionGroves, missionID]);

  return (
    <Wrapper>
      <Header>
        <Back onClick={() => (hasModifiedGroves ? showConfirmLeaveModal() : onGoToOverview(false))}>
          <BackIcon icon={faChevronLeft} />
          {t('missions.missions')}
        </Back>
        <MissionType type={mission.subType || mission.type} />
      </Header>
      <MissionTitle>
        <FormInput inputWidth="100%" value={mission.title} onChange={setTitle} name="title" placeholder={t('missions.mission_name')} />
      </MissionTitle>
      <TableWrapper>
        <DataTable<IMetricGroveEntry> {...tableProps} />
      </TableWrapper>
    </Wrapper>
  );
};

export default MissionGroveSelectionMenu;
