import React, { useState, useEffect, useCallback, useMemo } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faSeedling } from '@fortawesome/pro-solid-svg-icons';

import signInHooks from 'hooks/signIn.hooks';
import missionHooks from 'hooks/mission.hooks';
import regionsHooks from 'hooks/regions.hooks';
import commoditiesHooks from 'hooks/commodities.hooks';
import Button from 'atomicComponents/Button';
import ConfigureGeneralMission from 'containers/ConfigureGeneralMission';
import GeneralSmartSampling from 'containers/create-mission/GeneralSmartSampling';
import SelectBlocksByParameters from 'containers/create-mission/SelectBlocksByParameters';
import MissionTypesGallery from 'containers/MissionTypesGallery';
import { ISurveyWithTimeRange } from 'models/survey';
import { IUserInfo } from 'models/user';
import { EReportType } from 'models/report';
import { CALIFORNIA_REGION_NAME, SOUTH_AFRICA_REGION_NAME } from 'models/region';
import { ICreateFeedIncompleteParams } from 'models/feed';
import {
  ICreateMissionIncompleteParams,
  EMissionSubtype,
  TMissionType,
  EMissionType,
  EMissionKpi,
  groupedMissionTypes,
  EMissionTypesGroup,
  groupedMissionTitles,
  groupedMissionSubtitles
} from 'models/mission';
import { CITRUS_COMMODITIES, ECommodity } from 'models/farm';
import farmsHooks from 'hooks/farms.hooks';
import missionUtils from 'utils/mission';

import { mfColors } from 'vars';

import { FruitForcastingGroupIcon, ScoutingGroupIcon, TrimmingGroupIcon, WeedControlGroupIcon, GeneralIcon } from 'assets/images';
import { isReplantAvailableSelector } from 'redux/replant/replantSlice';
import { useSelector } from 'react-redux';

export const ALL_ZONES_PARAM = 'all';

const Wrapper = styled.div`
  width: 984px;
  max-height: 750px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Title = styled.div`
  font-family: MontserratBold;
  font-size: 20px;
  line-height: 24px;
  margin: 0 0 48px;
  text-align: left;
  color: ${mfColors.darkGrey};
  width: 100%;
`;

const Back = styled.div`
  display: inline-block;
  cursor: pointer;
  margin: 0 12px 0 0;
`;

const Subtitle = styled.div`
  padding: 4px 0;
  margin: 0 0 24px;
  border-bottom: 2px solid ${mfColors.grey};
  font-size: 18px;
  width: 100%;
`;

const ZonesWrapper = styled.div`
  width: 790px;
  margin: 0 0 -8px;

  &.zones-list {
    margin: 0 0 32px;
  }
`;

const ZoneEntry = styled.div`
  display: inline-block;
  margin: 0 4px 12px;
`;

const MissionsGroupWrapper = styled.div`
  margin: 0 -16px;
`;

interface IMissionsGroupEntryProps {
  isHighlighted?: boolean;
}

const MissionsGroupEntry = styled.div`
  float: left;
  margin: 0 16px 32px 16px;
  width: 306px;
  border-radius: 6px;
  background: ${({ isHighlighted }: IMissionsGroupEntryProps) => (isHighlighted ? mfColors.blue : mfColors.missionsGroupBackground)};
  opacity: ${({ isHighlighted }: IMissionsGroupEntryProps) => (isHighlighted ? 0.8 : 1)};
  color: ${({ isHighlighted }: IMissionsGroupEntryProps) => (isHighlighted ? mfColors.white : mfColors.darkGrey)};
  min-height: 102px;
  font-size: 18px;
  line-height: 24px;
  padding: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;

  &.disabled {
    cursor: default;
    opacity: 0.5;

    &:hover {
      background: ${mfColors.missionsGroupBackground};
      color: ${mfColors.darkGrey};
      opacity: 0.5;
    }
  }

  .icon {
    margin: 0 16px 0 0;
    padding: 11px 0 0 11px;
    flex-shrink: 0;
    border-radius: 50%;
    height: 50px;
    width: 50px;
    border: 2px solid ${mfColors.darkGrey};

    svg {
      width: 24px;
      height: 24px;
      fill: currentColor;

      path {
        fill: currentColor;
      }
    }
  }

  .hint {
    font-size: 14px;
    line-height: 16px;
  }

  .loader {
    margin: 0 auto;
    > div {
      margin: 0;
      color: ${mfColors.white};
      * {
        color: ${mfColors.white};
      }
    }
  }

  &:hover {
    background: ${mfColors.blue};
    color: ${mfColors.white};
    opacity: 1;

    .icon {
      border: 2px solid ${mfColors.white};
    }
  }

  &:hover {
    background: ${mfColors.blue};
    color: ${mfColors.white};

    .icon {
      border: 2px solid ${mfColors.white};
    }
  }
`;

const LevelsWrapper = styled.div`
  overflow: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 16px 0 0;
`;

interface IProps {
  onUploadTrees: (trees: ICreateFeedIncompleteParams[]) => void;
  selectedSurvey: ISurveyWithTimeRange;
  previousSurvey: ISurveyWithTimeRange | null;
  onSetCheckedGroves: (groves: string[]) => void;
  onCreateMission: (params: ICreateMissionIncompleteParams) => void;
}

const BUTTON_STYLE = {
  width: '150px',
  padding: '4px',
  height: '40px'
};

const SELECTED_BUTTON_STYLE = {
  ...BUTTON_STYLE,
  fontFamily: 'MontserratBold'
};

export const groupedMissionIcons = new Map<EMissionTypesGroup, JSX.Element>()
  .set(EMissionTypesGroup.Scouting, <ScoutingGroupIcon />)
  .set(EMissionTypesGroup.FruitForecasting, <FruitForcastingGroupIcon />)
  .set(EMissionTypesGroup.WeedControl, <WeedControlGroupIcon />)
  .set(EMissionTypesGroup.TrimmingAndClipping, <TrimmingGroupIcon />)
  .set(EMissionTypesGroup.Replant, <FontAwesomeIcon icon={faSeedling} />)
  .set(EMissionTypesGroup.General, <GeneralIcon />);

const MISSION_WITH_REPORTS = [
  EReportType.Cipo,
  EReportType.Weeds,
  EReportType.Height,
  EReportType.FreeArea,
  EReportType.DedicatedVisit,
  EReportType.Replant,
  EReportType.TargetedScouting
];

const CreateMissionModal = ({ onSetCheckedGroves, onCreateMission, selectedSurvey, previousSurvey, onUploadTrees }: IProps) => {
  const { t } = useTranslation();
  const { selectedFarm } = farmsHooks.useSelectedFarmEntities();
  const { region } = regionsHooks.useGetFarmRegion();
  const { zones, getZones } = farmsHooks.useGetFarmZones();
  const [typesGroup, setTypesGroup] = useState<EMissionTypesGroup | null>(null);
  const [zoneID, setZoneID] = useState<string | null | undefined>(undefined);
  const [hasCitrus, setHasCitrus] = useState(false);
  const [missionParams, setMissionParams] = useState<ICreateMissionIncompleteParams | null>(null);
  const { groves } = farmsHooks.useFarmEntities();
  const { commodityCombinations } = commoditiesHooks.useGetAllCommodityCombinations();
  const isReplantActive = useSelector(isReplantAvailableSelector);

  const [configureScreenType, setConfigureScreenType] = useState<TMissionType | null>(null);
  const user = signInHooks.useSignedInUser();
  const { logMissionSelectZoneEvent, logMissionSelectTypeEvent } = missionHooks.useMissionLogger();

  const isMissionWithReports = useMemo(() => MISSION_WITH_REPORTS.includes(missionParams?.type as EReportType), [missionParams]);
  const isSamplingFlow = useMemo(
    () => [EMissionType.HLBScouting, EMissionType.PestsDesease, EMissionType.Sampling, EMissionType.FruitCount].includes(missionParams?.type as EMissionType),
    [missionParams?.type]
  );

  useEffect(() => {
    const hasGroveWithCitrus = !!groves.find((grove) => {
      const commodityWithCitrus = grove.attributes?.commodityCombinations?.find(
        (id: string) => !!commodityCombinations.find((combination) => combination.id === id && CITRUS_COMMODITIES.includes(combination.commodity as ECommodity))
      );
      return !!commodityWithCitrus;
    });
    setHasCitrus(hasGroveWithCitrus);
  }, [commodityCombinations, groves]);

  useEffect(() => {
    const farmID = selectedFarm?.id as string;
    if (farmID) {
      getZones(farmID);
    }
  }, [selectedFarm?.id, getZones]);

  useEffect(() => {
    if (zones.length < 2) {
      setZoneID(null);
    }
  }, [zones]);

  const addImportedFeed = useCallback(
    (missionFeedParams: ICreateFeedIncompleteParams[], missionGroves?: string[]) => {
      if (!missionParams) return;

      onUploadTrees(missionFeedParams);
      onCreateMission({
        ...missionParams,
        zoneID: zoneID || null
      });
    },
    [onCreateMission, onUploadTrees, missionParams, zoneID]
  );

  const zonesOptions = useMemo(() => {
    const entries = zones.map((zone) => {
      const entry = {
        value: zone.id,
        text: zone.name,
        key: zone.id
      };
      return entry;
    });
    return [{ value: null, text: t('missions.all_zones'), key: t('missions.all_zones') }, ...entries];
  }, [zones, t]);

  const handleSelectType = useCallback(
    (
      type: TMissionType,
      subType: EMissionSubtype | null,
      checkedGroves: string[],
      isConfigured: boolean,
      isPreassigned: boolean,
      kpis: EMissionKpi[] = [],
      kpiScores: number[] = [],
      treesCount: number | null = null
    ) => {
      if (!onCreateMission) {
        return;
      }

      logMissionSelectTypeEvent(type);

      if (!isConfigured) {
        setConfigureScreenType(type);
      } else {
        const missionParams = missionUtils.getMissionParams(type as EReportType, subType, selectedFarm, null, user as IUserInfo, kpis, kpiScores, treesCount, isPreassigned, t);
        const isSamplingFlow = [EMissionType.HLBScouting, EMissionType.PestsDesease, EMissionType.Sampling, EMissionType.FruitCount].includes(missionParams.type as EMissionType);
        const isMissionWithReports = MISSION_WITH_REPORTS.includes(missionParams?.type as EReportType);
        onSetCheckedGroves(checkedGroves);
        if (isSamplingFlow || isMissionWithReports || zonesOptions.length > 2) {
          setMissionParams(missionParams);
        } else {
          onCreateMission(missionParams);
        }
      }
    },
    [selectedFarm, onCreateMission, logMissionSelectTypeEvent, onSetCheckedGroves, zonesOptions.length, user, t]
  );

  const handleManualSelection = useCallback(() => {
    if (!missionParams) return;
    onCreateMission({
      ...missionParams,
      zoneID: zoneID || null
    });
  }, [onCreateMission, missionParams, zoneID]);

  const handleBack = useCallback(
    (skipStep = false) => {
      if (zoneID !== undefined) {
        setZoneID(undefined);
        setMissionParams(null);
      } else if (missionParams) {
        setMissionParams(null);
      } else if (typesGroup) {
        setTypesGroup(null);
      }
    },
    [typesGroup, zoneID, missionParams]
  );

  const handleConfigureMission = useCallback(
    (kpis: EMissionKpi[], kpiScores?: number[]) => {
      handleSelectType(EMissionType.General, null, [], true, false, kpis, kpiScores);
    },
    [handleSelectType]
  );

  const handleSelectZone = useCallback(
    (zone: string | null) => {
      const isSamplingFlow = [EMissionType.HLBScouting, EMissionType.PestsDesease, EMissionType.Sampling, EMissionType.FruitCount].includes(missionParams?.type as EMissionType);
      logMissionSelectZoneEvent(zone);
      if (!isSamplingFlow && !isMissionWithReports && missionParams) {
        onCreateMission({
          ...missionParams,
          zoneID: zone
        });
      } else {
        setZoneID(zone);
      }
    },
    [isMissionWithReports, missionParams, onCreateMission, logMissionSelectZoneEvent]
  );

  useEffect(() => {
    if (missionParams && isMissionWithReports) {
      setZoneID(null);
    }
  }, [missionParams, isMissionWithReports]);

  const title = useMemo(() => {
    if (missionParams?.type) return t('missions.create_new_mission', { name: t(missionUtils.getMissionTypeKey(missionParams.subType || missionParams.type)) });
    const farmName = selectedFarm?.name || '';
    return t('missions.сreate_new_mission_at', { farmName });
  }, [t, selectedFarm, missionParams?.type, missionParams?.subType]);

  const getTypes = useCallback(
    (typesGroup: EMissionTypesGroup) => {
      // prettier-ignore
      const result = groupedMissionTypes.get(typesGroup)?.filter((entry) => {
        const nonHLBRegions = [SOUTH_AFRICA_REGION_NAME, CALIFORNIA_REGION_NAME];
        if (entry.type === EMissionType.HLBScouting) return hasCitrus && region && !nonHLBRegions.includes(region.name);
        if (entry.type === EReportType.Cipo) return selectedFarm?.config?.features?.cipo === true;
        return true;
      }) || [];
      return result;
    },
    [hasCitrus, selectedFarm, region]
  );

  if (configureScreenType) {
    return (
      <Wrapper>
        <Title>{title}</Title>
        {configureScreenType === EMissionType.General && <ConfigureGeneralMission onCreateMission={handleConfigureMission} onBack={() => setConfigureScreenType(null)} />}
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <Title>
        {(!!typesGroup || !!missionParams) && (
          <Back onClick={handleBack}>
            <FontAwesomeIcon icon={faChevronLeft} />
          </Back>
        )}
        {title}
      </Title>
      {!typesGroup && !missionParams && (
        <>
          <Subtitle>{t('missions.mission_type')}</Subtitle>
          <MissionsGroupWrapper>
            {[...groupedMissionTypes.keys()].map((group) => (
              <MissionsGroupEntry key={group} onClick={() => setTypesGroup(group)}>
                <div className="icon">{groupedMissionIcons.get(group)}</div>
                <div className="title">{t(groupedMissionTitles.get(group) || '')}</div>
              </MissionsGroupEntry>
            ))}
            {isReplantActive && (
              <MissionsGroupEntry onClick={() => handleSelectType(EMissionType.Replant, null, [], true, false)}>
                <div className="icon">{groupedMissionIcons.get(EMissionTypesGroup.Replant)}</div>
                <div className="title">{t(groupedMissionTitles.get(EMissionTypesGroup.Replant) || '')}</div>
              </MissionsGroupEntry>
            )}
          </MissionsGroupWrapper>
        </>
      )}
      {!!typesGroup && !missionParams && (
        <LevelsWrapper>
          <Subtitle>{t(groupedMissionSubtitles.get(typesGroup) || '')}</Subtitle>
          <MissionTypesGallery
            types={getTypes(typesGroup as EMissionTypesGroup)}
            onSelectType={handleSelectType}
            selectedSurvey={selectedSurvey}
            zoneID={zoneID as string | null}
          />
        </LevelsWrapper>
      )}
      {(zoneID === undefined || isMissionWithReports) && !!missionParams && (
        <>
          {zonesOptions.length > 2 && (
            <>
              <Subtitle>{t('missions.select_zone')}</Subtitle>
              <ZonesWrapper className="zones-list">
                {zonesOptions.map((option) => (
                  <ZoneEntry key={option.key}>
                    <Button
                      hoverBackground={mfColors.blue}
                      hoverColor={mfColors.white}
                      style={option.value !== zoneID ? BUTTON_STYLE : SELECTED_BUTTON_STYLE}
                      isSecondary={option.value !== zoneID}
                      onClick={() => handleSelectZone(option.value)}
                      text={option.text}
                    />
                  </ZoneEntry>
                ))}
              </ZonesWrapper>
            </>
          )}
          {isMissionWithReports && (
            <SelectBlocksByParameters
              onSetCheckedGroves={onSetCheckedGroves}
              selectedSurvey={selectedSurvey}
              zoneID={zoneID}
              missionParams={missionParams}
              onCreateMission={onCreateMission}
            />
          )}
        </>
      )}
      {!!missionParams && isSamplingFlow && (zoneID !== undefined || !zonesOptions.length) && (
        <>
          <Subtitle>{t('missions.sampling_random_trees_evenly_distributed')}</Subtitle>
          <GeneralSmartSampling
            selectedSurvey={selectedSurvey}
            missionParams={missionParams}
            farmID={missionParams.farmID}
            zoneID={missionParams.zoneID}
            onSelectFeed={addImportedFeed}
            onCancel={handleManualSelection}
            zones={zones}
          />
        </>
      )}
    </Wrapper>
  );
};

export default CreateMissionModal;
