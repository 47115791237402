import { initializeApp, FirebaseApp } from 'firebase/app';
import { getDatabase, Database } from 'firebase/database';
import {
  getAuth,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  Auth,
  signOut,
  verifyPasswordResetCode,
  confirmPasswordReset,
  createUserWithEmailAndPassword,
  onIdTokenChanged,
  User
} from 'firebase/auth';
import { getStorage, FirebaseStorage } from 'firebase/storage';
import { getFirestore, Firestore } from 'firebase/firestore';

import { IUser } from 'models/user';

import appConfig from 'config/config.json';

type TAuthListener = (isAuthorized: boolean) => void;

interface IFirebaseApi {
  database: Database;
  firestore: Firestore;
  firestoreDev: Firestore | null;
  storage: FirebaseStorage;
  firebase: FirebaseApp;
  signIn: (email: string, password: string) => ReturnType<typeof signInWithEmailAndPassword>;
  signOut: () => ReturnType<typeof signOut>;
  user: IUser | null;
  sendPasswordResetEmail: (email: string) => ReturnType<typeof sendPasswordResetEmail>;
  subscribeAuthStateChanged: (subscriber: TAuthListener) => void;
  unsubscribeAuthStateChanged: (subscriber: TAuthListener) => void;
  verifyPasswordResetCode: (code: string) => ReturnType<typeof verifyPasswordResetCode>;
  confirmPasswordReset: (code: string, newPassword: string) => ReturnType<typeof confirmPasswordReset>;
  createUserWithEmailAndPassword: (email: string, password: string) => ReturnType<typeof createUserWithEmailAndPassword>;
}

const firebaseApp = initializeApp(appConfig);
const auth = getAuth();
let authListeners: TAuthListener[] = [];

const db: IFirebaseApi = {
  firebase: firebaseApp,
  database: getDatabase(),
  firestore: getFirestore(),
  firestoreDev: null,
  storage: getStorage(),
  signIn: ((authDomain: Auth, email: string, password: string) => signInWithEmailAndPassword(authDomain, email, password)).bind(null, auth),
  signOut: () => signOut(auth),
  user: null,
  sendPasswordResetEmail: ((authDomain: Auth, email: string) => sendPasswordResetEmail(authDomain, email)).bind(null, auth),
  subscribeAuthStateChanged: (subscriber: TAuthListener) => {
    authListeners.push(subscriber);
  },
  unsubscribeAuthStateChanged: (subscriber: TAuthListener) => {
    authListeners = authListeners.filter((listener) => listener !== subscriber);
  },
  verifyPasswordResetCode: ((authDomain: Auth, code: string) => verifyPasswordResetCode(authDomain, code)).bind(null, auth),
  confirmPasswordReset: ((authDomain: Auth, code: string, newPassword: string) => confirmPasswordReset(authDomain, code, newPassword)).bind(null, auth),
  createUserWithEmailAndPassword: ((authDomain: Auth, email: string, password: string) => createUserWithEmailAndPassword(authDomain, email, password)).bind(null, auth)
};

if ((appConfig as any).slaveDB) {
  const firebaseAppDev = initializeApp((appConfig as any).slaveDB, (appConfig as any).slaveDB.projectId);
  db.firestoreDev = getFirestore(firebaseAppDev);
}

const updateUserToken = async (user: User | null) => {
  if (user) {
    const idToken = await user.getIdToken();

    db.user = { ...user, idToken } as any as IUser;
  }
};

onAuthStateChanged(auth, async (user) => {
  if (user) {
    await updateUserToken(user);
  } else {
    db.user = null;
  }
  authListeners.forEach((listener) => listener(!!db.user));
});

onIdTokenChanged(auth, updateUserToken);

export default db;
