import { useCallback, useEffect } from 'react';

import useFetchRegions from 'services/data/regions';

import { IRegion, EMeasurementSystem } from 'models/region';
import { useDispatch, useSelector } from 'react-redux';
import { regionsSelector, regionMeasurementSystemSelector, regionSelector, setRegions } from 'redux/region/regionSlice';

interface IGetFarmRegionResult {
  region: IRegion | null;
}

const useGetFarmRegion = (): IGetFarmRegionResult => {
  useGetRegions();

  const region = useSelector(regionSelector);

  return {
    region
  };
};

const useGetMeasurementSystem = (): EMeasurementSystem | undefined => useSelector(regionMeasurementSystemSelector);

interface IGetRegionsResult {
  regions: IRegion[];
}

const useGetRegions = (): IGetRegionsResult => {
  const dispatch = useDispatch();
  const fetchRegions = useFetchRegions();
  const regions = useSelector(regionsSelector);

  const getRegions = useCallback(async () => {
    dispatch(setRegions(await fetchRegions()));
  }, [dispatch, fetchRegions]);

  useEffect(() => {
    getRegions();
  }, [getRegions]);

  return {
    regions
  };
};

const regionsHooks = {
  useGetFarmRegion,
  useGetRegions,
  useGetMeasurementSystem
};

export default regionsHooks;
