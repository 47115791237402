import React, { useMemo } from 'react';
import { Chart } from 'chart.js';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-solid-svg-icons';

import { mfColors } from 'vars';

export interface IChartRange {
  // eslint-disable-next-line camelcase
  mapbox_value: number;
}

const Control = styled.div`
  display: none;
  font-size: 12px;
`;

const Label = styled.div`
  border-radius: 50%;
  height: 8px;
  width: 8px;
`;

interface IWrapperProps {
  isSelected: boolean;
}

const Wrapper = styled.div`
  position: absolute;
  width: 100%;
  display: flex;
  flex-direction: row;
  border-radius: 8px 0 0 8px;
  background-color: ${({ isSelected }: IWrapperProps) => (isSelected ? mfColors.greyBlue50 : 'transparent')};
  align-items: center;
  cursor: pointer;
  font-size: 12px;
  color: ${mfColors.grey};
  padding: 0 0 0 4px;

  &:first-child {
    margin: 0;
  }

  &:hover {
    ${Control} {
      display: ${({ isSelected }: IWrapperProps) => (isSelected ? 'block' : 'none')};
    }
    ${Label} {
      display: ${({ isSelected }: IWrapperProps) => (isSelected ? 'none' : 'block')};
    }
  }
`;

interface IProps {
  chart: Chart;
  range: IChartRange;
  index: number;
  highLightedChartRanges?: number[];
  onToggleHighchartRange?: (value: number) => void;
}

const RichItemsChartLabel = ({ chart, range, index, highLightedChartRanges = [], onToggleHighchartRange }: IProps): JSX.Element => {
  const model = useMemo(() => {
    const data = chart.getDatasetMeta(0).data || [];
    if (index > data.length) return null;

    return data[index]?._model || null;
  }, [chart, index]);
  const colorLabelStyles = useMemo(
    () => ({
      backgroundColor: model?.backgroundColor
    }),
    [model]
  );
  const style = useMemo(() => {
    if (!model) return {};
    return {
      // @ts-ignore
      top: `${model.y - model.height / 2}px`,
      // @ts-ignore
      height: `${model.height}px`
    };
  }, [model]);

  return (
    <Wrapper style={style} onClick={() => onToggleHighchartRange && onToggleHighchartRange(range.mapbox_value)} isSelected={highLightedChartRanges.includes(range.mapbox_value)}>
      {highLightedChartRanges.includes(range.mapbox_value) && (
        <Control>
          <FontAwesomeIcon icon={faTimes} />
        </Control>
      )}
      <Label style={colorLabelStyles} />
    </Wrapper>
  );
};

export default RichItemsChartLabel;
