import React, { useMemo, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/pro-solid-svg-icons';

import { mfColors } from 'vars';

import Dropdown, { EToggleType } from 'atomicComponents/Dropdown';
import { IFarm } from 'models/farm';
import { IGrove } from 'models/grove';
import { useDispatch } from 'react-redux';
import { setCompareIsOpened, setIsSideBarExpanded } from 'redux/appStateSlice';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background: ${mfColors.superLightBlue};
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.5);
  border-radius: 8px;
`;

const BackControl = styled(FontAwesomeIcon)`
  font-size: 24px;
  margin: 0 32px;
  cursor: pointer;
`;

const GroveInfo = styled.div`
  padding: 8px 32px 8px 0;
`;

const Title = styled.div`
  font-size: 24px;
  color: ${mfColors.grey};
`;

const DropdownEntries = styled.div`
  max-height: 150px;
  overflow: auto;
`;

const DropdownEntry = styled.div`
  min-width: 120px;
  padding: 6px 0;
  font-size: 12px;
  font-weight: normal;
  display: block;
  color: ${mfColors.darkGrey};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const DropdownControl = styled.span`
  color: ${mfColors.lightBlue};
`;

interface IProps {
  selectedFarm: IFarm;
  selectedGrove: IGrove;
  groves: IGrove[];
  onGoBack?: () => void;
  onGroveChanged: (grove: IGrove) => void;
}

const GrovesSelect = ({ selectedFarm, selectedGrove, groves, onGroveChanged, onGoBack }: IProps): JSX.Element => {
  const grovesForSelect = useMemo(() => groves.filter((entry) => entry.id !== selectedGrove.id), [groves, selectedGrove]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const navigateToGrove = useCallback(() => {
    navigate(`/map/${selectedFarm.id}/${selectedGrove.zoneID}/${selectedGrove.id}`);
    dispatch(setCompareIsOpened(false));
    dispatch(setIsSideBarExpanded(true));
  }, [dispatch, navigate, selectedFarm, selectedGrove]);

  return (
    <Wrapper>
      <BackControl onClick={onGoBack || navigateToGrove} icon={faArrowLeft} />
      <GroveInfo>
        <Title>{selectedFarm.name}</Title>
        {grovesForSelect.length > 1 && (
          <Dropdown
            toggleType={EToggleType.Click}
            control={<DropdownControl>{selectedGrove.name}</DropdownControl>}
            content={
              <DropdownEntries>
                {grovesForSelect.map((model) => (
                  <DropdownEntry key={model.id} onClick={() => onGroveChanged(model)}>
                    {model.name}
                  </DropdownEntry>
                ))}
              </DropdownEntries>
            }
          />
        )}
      </GroveInfo>
    </Wrapper>
  );
};

export default GrovesSelect;
