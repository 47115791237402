import React, { useState, useEffect } from 'react';
import { Range, getTrackBackground } from 'react-range';
import styled from 'styled-components';

import { mfColors } from 'vars';

const STEP = 1;

interface IProps {
  min: number;
  max: number;
  onValuesChanged: (values: number[]) => void;
}

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
`;

interface IDraggerProps {
  isDragged: boolean;
}

const Dragger = styled.div`
  height: 16px;
  width: 16px;
  border-radius: 8px;
  background-color: ${({ isDragged }: IDraggerProps) => (isDragged ? mfColors.lightBlue : mfColors.darkBlue)};
`;

const Track = styled.div`
  height: 4px;
  display: flex;
  width: 100%;
`;

const Output = styled.div`
  color: ${mfColors.blue};
  margin: 16px 0 0;
  font-family: MontserratBold;
`;

interface ITrackBarProps {
  values: number[];
  min: number;
  max: number;
}

const getTrackBarBackground = ({ values, min, max }: ITrackBarProps) => {
  const params = { values, colors: [mfColors.grey, mfColors.lightBlue, mfColors.grey], min, max, rtl: false };
  return getTrackBackground(params);
};

const TrackBar = styled.div`
  height: 4px;
  width: 100%;
  border-radius: 4px;
  background: ${getTrackBarBackground};
  align-self: center;
`;

const CompareReportsRange = ({ min, max, onValuesChanged }: IProps): JSX.Element => {
  const [values, setValues] = useState<number[]>([0, 0]);

  useEffect(() => {
    setValues([min, max]);
  }, [min, max]);

  useEffect(() => {
    onValuesChanged(values);
  }, [values, onValuesChanged]);

  return (
    <Wrapper>
      <Range
        values={values}
        step={STEP}
        min={min}
        max={max}
        rtl={false}
        disabled={false}
        onChange={setValues}
        onFinalChange={setValues}
        renderTrack={({ props, children }) => (
          <Track onMouseDown={props.onMouseDown} onTouchStart={props.onTouchStart} style={props.style}>
            <TrackBar ref={props.ref} min={min} max={max} values={values}>
              {children}
            </TrackBar>
          </Track>
        )}
        renderThumb={({ props, isDragged }) => <Dragger {...props} isDragged={isDragged} />}
      />
      <Output>{`${values[0].toFixed(1)}% - ${values[1].toFixed(1)}%`}</Output>
    </Wrapper>
  );
};

export default CompareReportsRange;
