import React, { useCallback, useMemo, useState, useEffect } from 'react';
import { useParams, useSearchParams, useNavigate } from 'react-router-dom';
import { Map as Mapbox } from 'mapbox-gl';
// @ts-ignore
import mapboxSync from '@mapbox/mapbox-gl-sync-move';
import CompareLayout from 'layouts/CompareLayout';
import GlobalLoader from 'atomicComponents/GlobalLoader';
import GrovesSelect from 'components/compare/GrovesSelect';
import CompareGraph from 'components/compare/CompareGraph';

import farmsHooks, { IUnifiedGeojson } from 'hooks/farms.hooks';
import { IPopupFeature } from 'hooks/map.hooks';
import { useAnalytics } from 'hooks/analytics.hooks';
import signInHooks from 'hooks/signIn.hooks';
import CompareScreenColumn from 'containers/CompareScreenColumn';
import { ELegendPosition } from 'components/shared/Map';

import { IGrove } from 'models/grove';
import { EEventType } from 'models/analytics';
import { ISurveyWithTimeRange } from 'models/survey';
import { ERichItemType, RICH_ITEMS_TO_COMPARE } from 'models/richItem';
import { ITreeMapboxFeature } from 'models/tree';
import EKeyCode from 'models/keyboard';
import { useDispatch } from 'react-redux';
import { setCompareIsOpened, setIsSideBarExpanded } from 'redux/appStateSlice';
import appConfigHooks from 'hooks/appConfig.hooks';

const CompareScreen = (): JSX.Element => {
  const params = useParams();
  const { logEvent } = useAnalytics();
  const user = signInHooks.useSignedInUser();
  const [searchParams] = useSearchParams();
  const surveyID = searchParams.get('surveyID');
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { selectedZone, selectedGrove, selectedSurvey, surveys, groves, zones } = farmsHooks.useFarmMapInfo(params.farmID, params.zoneID, params.groveID, surveyID || '');
  const { selectedFarm } = farmsHooks.useSelectedFarmEntities();
  const richItemMeasurementRanges = appConfigHooks.useGetRichItemRanges();
  const [leftRichItem, setLeftRichItem] = useState<ERichItemType | null>(null);
  const [leftSurvey, setLeftSurvey] = useState<ISurveyWithTimeRange | null>(null);
  const [leftRichItemGeoJSON, setLeftRichItemGeoJSON] = useState<IUnifiedGeojson | null>(null);
  const [rightRichItem, setRightRichItem] = useState<ERichItemType | null>(null);
  const [rightSurvey, setRightSurvey] = useState<ISurveyWithTimeRange | null>();
  const [rightRichItemGeoJSON, setRightRichItemGeoJSON] = useState<IUnifiedGeojson | null>(null);
  const [filteredTrees, setFilteredTrees] = useState<string[]>([]);
  const [treeForPopup, setTreeForPopup] = useState<IPopupFeature | null>(null);
  const [leftMap, setLeftMap] = useState<Mapbox | null>(null);
  const [rightMap, setRightMap] = useState<Mapbox | null>(null);
  const [selectedTree, setSelectedTree] = useState<ITreeMapboxFeature | null>(null);

  signInHooks.useIsAllowedFarm(params.farmID);

  useEffect(() => {
    if (leftMap && rightMap) {
      mapboxSync(leftMap, rightMap);
    }
  }, [leftMap, rightMap]);

  useEffect(() => {
    const handleEsc = (event: KeyboardEvent) => {
      if (event.keyCode === EKeyCode.Esc) {
        dispatch(setCompareIsOpened(false));
        dispatch(setIsSideBarExpanded(true));
      }
    };

    window.addEventListener('keydown', handleEsc);

    return () => {
      window.removeEventListener('keydown', handleEsc);
    };
  }, [dispatch]);

  const onGroveChanged = useCallback(
    (grove: IGrove) => {
      navigate(`/map/${grove.farmID}/${grove.zoneID}/${grove.id}?surveyID=${surveyID}`);
    },
    [navigate, surveyID]
  );

  const isGraphVisible = useMemo(() => {
    // prettier-ignore
    const isVisible = !!leftRichItem
      && !!leftRichItemGeoJSON
      && !!rightRichItemGeoJSON
      && RICH_ITEMS_TO_COMPARE.includes(leftRichItem)
      && leftRichItem === rightRichItem
      && leftSurvey?.id !== rightSurvey?.id;
    return isVisible;
  }, [leftRichItem, rightRichItem, leftSurvey, rightSurvey, leftRichItemGeoJSON, rightRichItemGeoJSON]);

  useEffect(() => {
    if (!selectedFarm || !selectedGrove || !user) return;
    logEvent({
      type: EEventType.OpenSplitScreen,
      params: {
        email: user.email,
        farmName: selectedFarm.name,
        groveName: selectedGrove.name
      }
    });
  }, [logEvent, selectedFarm, selectedGrove, user]);

  if (!selectedFarm || !selectedZone || !selectedGrove || !selectedSurvey) {
    return <GlobalLoader />;
  }

  return (
    <CompareLayout
      delta={
        isGraphVisible ? (
          <CompareGraph
            leftSurvey={leftSurvey as ISurveyWithTimeRange}
            rightSurvey={rightSurvey as ISurveyWithTimeRange}
            leftRichItemGeoJSON={leftRichItemGeoJSON as IUnifiedGeojson}
            rightRichItemGeoJSON={rightRichItemGeoJSON as IUnifiedGeojson}
            onFilteredTreesChange={setFilteredTrees}
            richItem={leftRichItem}
          />
        ) : undefined
      }
      grovesSelect={<GrovesSelect selectedFarm={selectedFarm} selectedGrove={selectedGrove} groves={groves} onGroveChanged={onGroveChanged} />}
      leftMap={
        <CompareScreenColumn
          onMapSet={setLeftMap}
          onSurveySelected={setLeftSurvey}
          onRichItemSelected={setLeftRichItem}
          onRichItemGeoJSONLoaded={setLeftRichItemGeoJSON}
          legendPosition={ELegendPosition.TopRight}
          selectedFarm={selectedFarm}
          zones={zones}
          groves={groves}
          selectedGrove={selectedGrove}
          selectedSurvey={selectedSurvey}
          surveys={surveys}
          filteredTrees={filteredTrees}
          selectedZone={selectedZone}
          treeForPopup={treeForPopup}
          onSelectPopupTree={setTreeForPopup}
          onSelectTree={setSelectedTree}
          selectedTree={selectedTree}
          richItemMeasurementRanges={richItemMeasurementRanges}
        />
      }
      rightMap={
        <CompareScreenColumn
          onMapSet={setRightMap}
          onSurveySelected={setRightSurvey}
          onRichItemSelected={setRightRichItem}
          onRichItemGeoJSONLoaded={setRightRichItemGeoJSON}
          legendPosition={ELegendPosition.TopLeft}
          selectedFarm={selectedFarm}
          zones={zones}
          groves={groves}
          selectedGrove={selectedGrove}
          selectedSurvey={selectedSurvey}
          surveys={surveys}
          filteredTrees={filteredTrees}
          selectedZone={selectedZone}
          treeForPopup={treeForPopup}
          onSelectPopupTree={setTreeForPopup}
          onSelectTree={setSelectedTree}
          selectedTree={selectedTree}
          richItemMeasurementRanges={richItemMeasurementRanges}
        />
      }
    />
  );
};

export default CompareScreen;
