import React, { useEffect, useState, useMemo, useCallback, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import SideBar from 'containers/SideBar';
import MainPageMenu from 'containers/MainPageMenu';

import MainPageBreadcrumbs from 'components/main/MainPageBreadcrumbs/MainPageBreadcrumbs';
import TreeGalleryMap from 'components/treeGallery/TreeGalleryMap';
import GrovesSelect from 'components/compare/GrovesSelect';

import GlobalLoader from 'atomicComponents/GlobalLoader';
import LocalLoader from 'atomicComponents/LocalLoader';

import farmsHooks from 'hooks/farms.hooks';
import regionsHooks from 'hooks/regions.hooks';
import Image from 'models/image';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import styled from 'styled-components';
import TilesHooks from 'hooks/tiles.hooks';
import { ITreeMapboxFeature } from 'models/tree';
import { ISurveyWithTimeRange } from 'models/survey';
import { ERichItemType } from 'models/richItem';
import { isSideBarExpandedSelector, setEvidenceParams, IEvidenceParams, getIsCompareOpened } from 'redux/appStateSlice';
import { setSelectedSurvey, grovesSurveysStatsSelector } from 'redux/farm/farmSlice';

import imageService from 'services/data/images';
import { mfColors } from 'vars';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/pro-solid-svg-icons';
import GalleryPreview, { PREVIEW_SIZES } from 'components/treeGallery/GalleryPreview';
import treeHooks from 'hooks/trees.hooks';
import EKeyCode from 'models/keyboard';

const Layout = styled.div`
  height: 100%;
  width: 100%;
  min-height: 100%;
  display: flex;
  background-color: ${mfColors.superLightBlue};
  overflow: auto;
`;

const Wrapper = styled.div`
  height: 100vh;
  width: 100%;
`;

const StaticContent = styled.div`
  margin: 0 16px 0 0;
`;

interface IContentProps {
  isCompareOpened?: boolean;
}

const Content = styled.div`
  width: ${({ isCompareOpened }: IContentProps) => (isCompareOpened ? '100%' : 'calc(100% - 422px)')};
  height: 100%;
  position: relative;
  overflow: hidden;

  .alice-carousel {
    height: 100%;
  }

  .alice-carousel__wrapper {
    position: absolute;
    left: 0;
    right: 0;
  }

  ul.alice-carousel__dots {
    position: absolute;
    bottom: 16px;
    left: 0px;
    right: 0px;
  }
  .alice-carousel__prev-btn {
    display: none !important;
  }
  .alice-carousel__next-btn {
    display: none !important;
  }
  .alice-carousel__dots-item {
    width: 10px;
    height: 10px;
  }
`;

const GoBackWrapper = styled.div`
  position: absolute;
  top: 12px;
  left: 36px;
  z-index: 1;
`;

const LoaderWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${mfColors.white50};
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const CloseGalleryButton = styled(FontAwesomeIcon)`
  position: absolute;
  top: 12px;
  right: 12px;
  background-color: ${mfColors.superLightBlue};
  padding: 8px 10px;
  border-radius: 8px;
  opacity: 0.7;
  cursor: pointer;
  z-index: 5;

  &:hover {
    opacity: 1;
  }
`;

const PreviewWrapper = styled.div`
  width: calc(100% - 10px);
  position: absolute;
  right: 0;
  bottom: -30px;
  height: 133px;
  overflow: hidden;
`;

interface IProps {
  evidenceParams: IEvidenceParams;
  onClose?: () => void;
}

const TreeGallery = ({ evidenceParams, onClose }: IProps): JSX.Element => {
  const params = useParams();
  const isSideBarExpanded = useSelector(isSideBarExpandedSelector);
  const isCompareOpened = useSelector(getIsCompareOpened);
  const grovesSurveysStats = useSelector(grovesSurveysStatsSelector);
  const dispatch = useDispatch();
  const activeRichItem = evidenceParams.richItemType;
  const { selectedFarm, selectedSurvey, selectedGrove, selectedZone } = farmsHooks.useSelectedFarmEntities();
  const { farms, zones, groves, surveys, allSurveys } = farmsHooks.useFarmEntities();
  const setSurvey = useCallback((survey: ISurveyWithTimeRange | null) => dispatch(setSelectedSurvey(survey)), [dispatch]);

  const getTreeAerialImages = imageService.useGetTreeAerialImages();
  const { region } = regionsHooks.useGetFarmRegion();
  const { getGroveTrees, trees } = farmsHooks.useGroveTrees();

  const { richItems } = farmsHooks.useRichItems(selectedFarm?.id, selectedGrove?.id, selectedSurvey?.id, selectedFarm?.config?.features);
  const selectedRichItem = useMemo(() => {
    if (richItems.length && activeRichItem) {
      return richItems.find((item) => item.richItemTypeName === activeRichItem) || null;
    }
    return null;
  }, [richItems, activeRichItem]);
  const { geoJSON: richItemGeoJSON } = farmsHooks.useRichItemGeoJSON(selectedRichItem, trees, selectedFarm?.id, selectedGrove?.id, selectedSurvey?.id, region?.measurementSystem);

  const [images, setImages] = useState<Image[]>([]);
  const [imagesLoading, setImagesLoading] = useState(false);
  const [selectedImage, setSelectedImage] = useState<Image | null>(null);
  const [selectedFeature, setSeletedFeature] = useState<ITreeMapboxFeature | null>(null);
  const [previewOffset, setPreviewOffSet] = useState(0);
  const galleryPreviewRef = useRef<HTMLDivElement>(null);

  const { getFarmVectiles, vectiles } = farmsHooks.useGetFarmVectiles();

  const groveTilePath = TilesHooks.useGetGroveTiles(vectiles, selectedGrove, selectedSurvey);
  const { sourceTrees, isLoading: isTreesLoading } = treeHooks.useGetSourceTrees(selectedImage, groveTilePath);

  const shotAtImages = useMemo(() => {
    const result = images.map((image) => {
      const survey = allSurveys.find((sur) => sur.id === image.surveyID);
      const stats = grovesSurveysStats.find((stat) => stat.groveID === image.groveID && stat.surveyID === survey?.id);
      const shotAt = stats?.shotAt || survey?.date;
      if (shotAt) {
        image.shotAt = new Date(shotAt).getTime();
      }
      return image;
    });

    return result.sort((a, b) => b.shotAt - a.shotAt);
  }, [allSurveys, images, grovesSurveysStats]);

  const selectedImageIndex = useMemo(() => (selectedImage ? shotAtImages.indexOf(selectedImage) : -1), [shotAtImages, selectedImage]);

  useEffect(() => {
    if (evidenceParams.farmID) {
      getFarmVectiles(evidenceParams.farmID);
    }
  }, [evidenceParams.farmID, getFarmVectiles]);

  useEffect(() => {
    if (selectedFarm?.id && selectedSurvey?.id && selectedGrove?.id) {
      getGroveTrees(selectedFarm.id, selectedGrove.id, selectedSurvey.id);
    }
  }, [selectedFarm, selectedGrove, selectedSurvey, getGroveTrees]);

  useEffect(() => {
    async function getData(farmID, treeID) {
      setImagesLoading(true);
      try {
        const res = await getTreeAerialImages(farmID, treeID);
        setImages(res || []);
        setImagesLoading(false);
      } catch {
        setImagesLoading(false);
      }
    }
    if (!selectedFarm || !evidenceParams.treeID) {
      return;
    }
    getData(selectedFarm.id, evidenceParams.treeID);
  }, [selectedFarm, evidenceParams.treeID, getTreeAerialImages]);

  useEffect(() => {
    if (shotAtImages.length && selectedSurvey) {
      if (selectedImage?.surveyID === selectedSurvey.id) {
        return;
      }
      const defaultImage = shotAtImages.find((image) => image.surveyID === selectedSurvey.id) || null;

      setSelectedImage(defaultImage);
    }
  }, [shotAtImages, selectedImage, selectedSurvey]);

  const closeGallery = useCallback(() => {
    dispatch(setEvidenceParams(null));
  }, [dispatch]);

  useEffect(() => {
    if (evidenceParams.farmID !== params.farmID || evidenceParams.zoneID !== params.zoneID || evidenceParams.groveID !== params.groveID) {
      closeGallery();
    }
  }, [evidenceParams, params.farmID, params.zoneID, params.groveID, closeGallery]);

  useEffect(() => {
    if (galleryPreviewRef.current) {
      const refWidth = galleryPreviewRef.current.offsetWidth;
      const margins = PREVIEW_SIZES.margin * 2;
      const imageWidth = PREVIEW_SIZES.width + margins;
      const dividerWidth = 85;
      const imageOffset = imageWidth / 2;
      const surveyIndex = allSurveys.findIndex((item) => item.id === selectedImage?.surveyID);

      const offset = refWidth / 2 - (selectedImageIndex * imageWidth + imageOffset + dividerWidth * surveyIndex);
      setPreviewOffSet(offset);
    }
  }, [galleryPreviewRef, selectedImageIndex, selectedImage, allSurveys]);

  useEffect(() => {
    const handleEsc = (event: KeyboardEvent) => {
      if (event.keyCode === EKeyCode.Esc) {
        closeGallery();
      }
    };

    window.addEventListener('keydown', handleEsc);

    return () => {
      window.removeEventListener('keydown', handleEsc);
    };
  }, [closeGallery]);

  const handlePreviewClick = useCallback(
    (image: Image) => {
      if (!image || !selectedSurvey || isTreesLoading) return;
      const { surveyID } = image;
      const survey = surveys.find((e) => e.id === surveyID);

      if (survey && survey.id !== selectedSurvey.id) {
        setSurvey(survey);
      }
      setSelectedImage(image);
    },
    [surveys, setSurvey, selectedSurvey, isTreesLoading]
  );

  if (!selectedFarm || !selectedSurvey || !selectedGrove) {
    return <GlobalLoader />;
  }

  return (
    <Layout>
      {isSideBarExpanded && !isCompareOpened && (
        <SideBar>
          <StaticContent>
            {selectedGrove && selectedZone && <MainPageBreadcrumbs groves={groves} zones={zones} farms={farms} grove={selectedGrove} zone={selectedZone} farm={selectedFarm} />}
            <MainPageMenu
              selectedSurvey={selectedSurvey.id}
              surveys={surveys}
              onSelectSurvey={setSurvey}
              farms={farms}
              zones={zones}
              groves={groves}
              farm={selectedFarm}
              zone={selectedZone}
              grove={selectedGrove}
              prefix="/map"
              tree={activeRichItem ? selectedFeature : null}
            />
          </StaticContent>
        </SideBar>
      )}
      <Content isCompareOpened={isCompareOpened} ref={galleryPreviewRef}>
        {!isCompareOpened && (
          <GoBackWrapper>
            <GrovesSelect onGoBack={closeGallery} selectedFarm={selectedFarm} selectedGrove={selectedGrove} groves={[]} onGroveChanged={() => {}} />
          </GoBackWrapper>
        )}
        {!imagesLoading && <CloseGalleryButton icon={faXmark} onClick={() => onClose && onClose()} />}
        {shotAtImages.length ? (
          <>
            <AliceCarousel
              disableDotsControls
              items={shotAtImages.map((image) => (
                <Wrapper key={image.id}>
                  {!isTreesLoading && (
                    <TreeGalleryMap
                      selectedTreeID={evidenceParams.treeID as string}
                      initialImage={image}
                      selectedImage={selectedImage}
                      trees={trees}
                      region={region}
                      richItemGeoJSON={richItemGeoJSON}
                      activeRichItem={activeRichItem as ERichItemType}
                      setSelectedFeature={setSeletedFeature}
                      sourceTrees={sourceTrees}
                    />
                  )}
                </Wrapper>
              ))}
              activeIndex={selectedImageIndex}
            />
            <PreviewWrapper>
              <GalleryPreview images={shotAtImages} selectedImage={selectedImage} handleClick={handlePreviewClick} offset={previewOffset} />
            </PreviewWrapper>
          </>
        ) : null}
        {(imagesLoading || isTreesLoading) && (
          <LoaderWrapper>
            <LocalLoader />
          </LoaderWrapper>
        )}
      </Content>
    </Layout>
  );
};
export default TreeGallery;
