import { GeoJSONSource, Map as MapboxMap } from 'mapbox-gl';
import * as turf from '@turf/turf';

import { IFeed } from 'models/feed';
import { IWeeklyTrapMonitoringConfig } from 'models/mission';

import { IPestsP3Config } from 'models/missions-config';
import feedUtils from 'utils/feed';
import { addLayerToMap, getMapSourceById, PESTSP3_CLUSTERS_LAYERS, PESTS_CLUSTERS_LAYERS, reorderLayers, setMapSourceById } from 'services/util/mapHelpers';
import { mfColors } from 'vars';
import { indexBy } from 'utils/helpers';

const pestsAcronymMap = new Map<string, string>()
  .set('fruit_fly', 'FF')
  .set('red_scale', 'RS')
  .set('mealy_bug', 'MB')
  .set('bollworm', 'Bo')
  .set('leaf_hopper', 'LH')
  .set('lemon_moth', 'LM')
  .set('false_codling_moth_sterile', 'CMs')
  .set('false_codling_moth_wild', 'CMw');

const PEST_SOURCE_NAME = 'pests-cluster';
const PEST_POINT_SOURCE_NAME = 'pests-cluster-point';

export const setPestsPointClusterSource = (map: MapboxMap, feed: IFeed[]): void => {
  if (!feed.length) {
    setMapSourceById(map, PEST_POINT_SOURCE_NAME, []);
  }

  if (feed.length) {
    const features = map.querySourceFeatures(PEST_SOURCE_NAME, {
      filter: ['has', 'point_count']
    });
    const indexedFeatures = indexBy('id', features) as { [key: string]: any[] };
    const points = Object.keys(indexedFeatures).reduce((acc, id) => {
      const feature = indexedFeatures[id][0];

      if (!feature) return acc;

      const { geometry, properties } = feature;
      const { maxPests } = properties;
      const maxFeed = feed.find((item) => item?.data?.pestsNumber === maxPests);
      const trapType = pestsAcronymMap.get(maxFeed?.data?.trapType || '');

      return acc.concat(
        turf.feature(geometry as turf.Geometry, {
          ...properties,
          trapType
        })
      );
    }, [] as turf.Feature[]);

    setMapSourceById(map, PEST_POINT_SOURCE_NAME, points);
  }
};

export const addPestsClustersMarkers = (map: MapboxMap | null, feed: IFeed[], config: IWeeklyTrapMonitoringConfig | null) => {
  if (!map || !config) {
    return;
  }

  const indexedFeed = indexBy('geoObjectID', feed) as { [key: string]: IFeed[] };
  const features = Object.keys(indexedFeed)
    .map((groveID) => {
      const treeFeed = indexedFeed[groveID];
      const highSeverityFeed = treeFeed.filter((entry) => {
        const highestSeverityTreshold = config[entry.data?.trapType || ''].colors[config[entry.data?.trapType || ''].colors.length - 1]?.min || 0;
        return (entry.data?.pestsNumber || 0) >= highestSeverityTreshold;
      });
      const feedItem = highSeverityFeed[0] || treeFeed[0];
      const colors = config?.[feedItem.data?.trapType || '']?.colors;
      const pestsCounted = feedItem.data?.pestsNumber || 0;
      const color = colors?.find((entry) => (entry.min === null || entry.min <= pestsCounted) && (entry.max === null || entry.max > pestsCounted))?.color || '';

      return turf.feature(JSON.parse(feedItem.geometry) as turf.Geometry, {
        pestsCounted,
        trapType: pestsAcronymMap.get(feedItem.data?.trapType || ''),
        color
      });
    })
    .filter((entry) => !!entry) as turf.Feature[];

  setMapSourceById(map, PEST_SOURCE_NAME, features);
};

export const addPestsClustersLayers = (map: MapboxMap): void => {
  if (!getMapSourceById(map, PEST_SOURCE_NAME)) {
    map.addSource(PEST_SOURCE_NAME, {
      data: turf.featureCollection([]),
      type: 'geojson',
      cluster: true,
      clusterMaxZoom: 19,
      clusterRadius: 30,
      clusterProperties: {
        hasRed: ['any', ['==', ['get', 'color'], mfColors.pestsBad], mfColors.pestsBad],
        hasOrange: ['any', ['==', ['get', 'color'], mfColors.pestsAverage], mfColors.pestsAverage],
        hasGreen: ['any', ['==', ['get', 'color'], mfColors.green], mfColors.green],
        maxPests: ['max', ['get', 'pestsCounted']]
      }
    });
  }

  if (!getMapSourceById(map, PEST_POINT_SOURCE_NAME)) {
    map.addSource(PEST_POINT_SOURCE_NAME, {
      data: turf.featureCollection([]),
      type: 'geojson'
    });
  }

  addLayerToMap(map, PESTS_CLUSTERS_LAYERS[0], {
    id: PESTS_CLUSTERS_LAYERS[0],
    type: 'circle',
    source: PEST_POINT_SOURCE_NAME,
    paint: {
      'circle-color': ['case', ['get', 'hasRed'], mfColors.pestsBad, ['get', 'hasOrange'], mfColors.pestsAverage, mfColors.pestsOk],
      'circle-radius': ['step', ['get', 'point_count'], 19, 2, 19, 3, 20],
      'circle-stroke-color': mfColors.white,
      'circle-stroke-width': 4
    }
  });

  addLayerToMap(map, PESTS_CLUSTERS_LAYERS[1], {
    id: PESTS_CLUSTERS_LAYERS[1],
    type: 'circle',
    source: PEST_SOURCE_NAME,
    filter: ['!', ['has', 'point_count']],
    paint: {
      'circle-color': ['match', ['get', 'color'], mfColors.pestsBad, mfColors.pestsBad, mfColors.pestsAverage, mfColors.pestsAverage, mfColors.pestsOk],
      'circle-radius': 17
    }
  });

  addLayerToMap(map, PESTS_CLUSTERS_LAYERS[2], {
    id: PESTS_CLUSTERS_LAYERS[2],
    type: 'symbol',
    source: PEST_POINT_SOURCE_NAME,
    layout: {
      'text-field': '{trapType} \n {maxPests}',
      'text-size': 13,
      'text-allow-overlap': true,
      'text-font': ['Open Sans Semibold', 'Arial Unicode MS Bold']
    },
    paint: {
      'text-color': mfColors.white
    }
  });

  addLayerToMap(map, PESTS_CLUSTERS_LAYERS[3], {
    id: PESTS_CLUSTERS_LAYERS[3],
    type: 'symbol',
    source: PEST_SOURCE_NAME,
    filter: ['!', ['has', 'point_count']],
    layout: {
      'text-field': '{trapType} \n {pestsCounted}',
      'text-size': 12,
      'text-allow-overlap': true,
      'text-font': ['Open Sans Semibold', 'Arial Unicode MS Bold']
    },
    paint: {
      'text-color': mfColors.white
    }
  });

  reorderLayers(
    map,
    PESTS_CLUSTERS_LAYERS.map((layerName, index) => ({
      zIndex: index,
      name: layerName
    }))
  );
};

export const addPestsClickHandlers = (map: MapboxMap, clickHandler: (event) => void): void => {
  [PESTS_CLUSTERS_LAYERS[0], PESTS_CLUSTERS_LAYERS[1]].forEach((layerName) => {
    map.on('click', layerName, clickHandler);
  });
};

export const removePestsClickHandlers = (map: MapboxMap, clickHandler: (event) => void): void => {
  [PESTS_CLUSTERS_LAYERS[0], PESTS_CLUSTERS_LAYERS[1]].forEach((layerName) => {
    map.off('click', layerName, clickHandler);
  });
};

const SOURCE_NAME = 'pestsP3-cluster';

export const setPestsP3ClusterSource = (map: MapboxMap, features: turf.Feature[]): void => {
  const source = getMapSourceById(map, SOURCE_NAME);
  if (source) {
    (source as GeoJSONSource).setData(turf.featureCollection(features) as any);
  }
};

export const addPestsP3ClustersLayers = (map: MapboxMap): void => {
  if (!getMapSourceById(map, SOURCE_NAME)) {
    map.addSource(SOURCE_NAME, {
      data: turf.featureCollection([]),
      type: 'geojson',
      cluster: true,
      clusterMaxZoom: 18,
      clusterRadius: 12,
      clusterProperties: {
        hasRed: ['any', ['==', ['get', 'color'], mfColors.superRed], mfColors.superRed],
        hasGreen: ['any', ['==', ['get', 'color'], mfColors.green], mfColors.green]
      }
    });
  }

  addLayerToMap(map, PESTSP3_CLUSTERS_LAYERS[0], {
    id: PESTSP3_CLUSTERS_LAYERS[0],
    type: 'circle',
    source: SOURCE_NAME,
    filter: ['has', 'point_count'],
    paint: {
      'circle-color': ['case', ['get', 'hasRed'], mfColors.superRed, ['get', 'hasGreen'], mfColors.green, mfColors.grey],
      'circle-radius': ['step', ['get', 'point_count'], 17, 2, 17, 3, 19]
    }
  });

  addLayerToMap(map, PESTSP3_CLUSTERS_LAYERS[1], {
    id: PESTSP3_CLUSTERS_LAYERS[1],
    type: 'circle',
    source: SOURCE_NAME,
    filter: ['!', ['has', 'point_count']],
    paint: {
      'circle-color': ['match', ['get', 'color'], mfColors.superRed, mfColors.superRed, mfColors.green, mfColors.green, mfColors.grey],
      'circle-radius': 15
    }
  });

  addLayerToMap(map, PESTSP3_CLUSTERS_LAYERS[2], {
    id: PESTSP3_CLUSTERS_LAYERS[2],
    type: 'symbol',
    source: SOURCE_NAME,
    filter: ['has', 'point_count'],
    layout: {
      'text-field': ['get', 'point_count_abbreviated'],
      'text-size': 16
    }
  });

  addLayerToMap(map, PESTSP3_CLUSTERS_LAYERS[3], {
    id: PESTSP3_CLUSTERS_LAYERS[3],
    type: 'symbol',
    source: SOURCE_NAME,
    filter: ['!', ['has', 'point_count']],
    layout: {
      'text-field': '1',
      'text-size': 16
    }
  });

  reorderLayers(
    map,
    PESTSP3_CLUSTERS_LAYERS.map((layerName, index) => ({
      zIndex: index,
      name: layerName
    }))
  );
};

export const addPestsP3ClustersMarkers = (map: MapboxMap | null, feed: IFeed[], config: IPestsP3Config[]) => {
  if (!map) {
    return;
  }

  const features = feed.map((feedItem) => {
    const pestReport = feedUtils.getPestsReport([feedItem], config);
    const highestPests = feedUtils.getHighestPestReport(pestReport);

    return turf.feature(JSON.parse(feedItem.geometry) as turf.Geometry, highestPests);
  }, [] as turf.Feature[]);

  setPestsP3ClusterSource(map, features);
};

export const addClickHandlers = (map: MapboxMap, clickHandler: (event) => void): void => {
  [PESTSP3_CLUSTERS_LAYERS[0], PESTSP3_CLUSTERS_LAYERS[1]].forEach((layerName) => {
    map.on('click', layerName, clickHandler);
  });
};

export const removeClickHandlers = (map: MapboxMap, clickHandler: (event) => void): void => {
  [PESTSP3_CLUSTERS_LAYERS[0], PESTSP3_CLUSTERS_LAYERS[1]].forEach((layerName) => {
    map.off('click', layerName, clickHandler);
  });
};
