import { IUnifiedGeojson } from 'hooks/farms.hooks';
import { Chart } from 'chart.js';

import { EMeasurementSystem } from 'models/region';
import { FEED_LABELS } from 'models/feed';
import { IChartLabelConfig, IRichItem, IRichItemDefaultConfig } from 'models/richItem';
import { IChartData, IChartOptions } from 'models/charts';

import { getCategoricalData, getColorProperty, getLinearData, getPercentageFromLabel } from './helpers';
import numberUtils from './numbers';

interface IChartLabelProps {
  activeRichItem: IRichItem | null;
  richItemsConfigs: IRichItemDefaultConfig[];
  languageID: string;
  measurementSystem: EMeasurementSystem;
}

interface IChartDataProps {
  richItemGeoJSON: IUnifiedGeojson | null;
  activeRichItem: IRichItem | null;
  chartProperty: string | null;
  chartLabels: IChartLabelConfig[] | null;
}

interface IChartOptionsProps {
  toggleHighlightedChartRange: (rangeValue: number) => void;
  activeRichItem: IRichItem | null;
  chartProperty: string | null;
  languageID: string;
  chartData: IChartData | null;
}

export const getChartLabels = ({ activeRichItem, richItemsConfigs, languageID, measurementSystem }: IChartLabelProps): IChartLabelConfig[] | null => {
  if (!activeRichItem) {
    return null;
  }

  const richItemConfig = richItemsConfigs.find((config) => config.id === activeRichItem.richItemTypeName)?.version?.[activeRichItem.richItemTypeVersion]?.[measurementSystem]?.[
    languageID
  ];

  return activeRichItem.chartLabelConfigs?.[languageID] || richItemConfig?.chartLabelConfigs || null;
};

export const getChartData = ({ richItemGeoJSON, activeRichItem, chartProperty, chartLabels }: IChartDataProps): IChartData | null => {
  if (!richItemGeoJSON || !activeRichItem || !chartLabels) return null;

  const { mapbox, geojson } = richItemGeoJSON;
  const colorPaint = getColorProperty(mapbox);
  const type = 'horizontalBar';

  if (!colorPaint || !['match', 'step', 'interpolate'].includes(colorPaint[0] as string) || !chartProperty) return null;

  const filteredGeojson = {
    ...geojson,
    features: geojson.features.filter((e) => e.properties[chartProperty] !== null)
  };
  const dataExpression = colorPaint[0];
  const isMatch = dataExpression === 'match';
  const getData = isMatch ? getCategoricalData : getLinearData;
  const labelValuesPairs = getData(filteredGeojson, chartLabels, chartProperty);

  return {
    type,
    data: {
      labels: chartLabels.map((label) => label.chart_label),
      mapbox_value_labels: chartLabels,
      datasets: [
        {
          borderWidth: 0,
          data: chartLabels.map((label) => labelValuesPairs[label.mapbox_value]),
          backgroundColor: chartLabels.map((label) => label.color)
        }
      ]
    }
  };
};

export const getChartOptions = ({ toggleHighlightedChartRange, activeRichItem, languageID, chartProperty, chartData }: IChartOptionsProps): IChartOptions | null => {
  const isWeed = activeRichItem && activeRichItem.labels && activeRichItem.labels.includes(FEED_LABELS.WEEDS);

  return {
    hover: {
      animationDuration: 0,
      onHover(event: { target: { style: { cursor: string } } }) {
        event.target.style.cursor = (this as Chart).getElementAtEvent(event).length ? 'pointer' : 'default';
      }
    },
    onClick(event) {
      const chart = this as Chart;
      if (!chart.getElementAtEvent(event)[0] || !chartData?.data?.mapbox_value_labels) {
        return;
      }
      toggleHighlightedChartRange(chartData.data.mapbox_value_labels[chart.getElementAtEvent(event)[0]._index].mapbox_value);
    },
    tooltips: {
      callbacks: {
        label: (item, data) => (isWeed ? getPercentageFromLabel(item, data) : item.xLabel),
        afterLabel: (item, data) => (isWeed ? null : getPercentageFromLabel(item, data))
      }
    },
    legend: { display: false },
    scales: {
      xAxes: [
        {
          ticks: {
            beginAtZero: true,
            display: !isWeed,
            callback(value: number) {
              return numberUtils.getLocalizedNumber(value, languageID);
            }
          }
        }
      ],
      yAxes: [
        {
          gridLines: {
            display: false,
            color: 'transparent',
            zeroLineColor: 'transparent'
          }
        }
      ]
    },
    title: { display: false, text: chartProperty }
  };
};
