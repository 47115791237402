import React, { useState, useEffect, useCallback, useMemo } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisVertical, faTimes } from '@fortawesome/pro-solid-svg-icons';
import { useTranslation, Trans } from 'react-i18next';

import Dropdown, { EToggleType, EPosition } from 'atomicComponents/Dropdown';
import { Input } from 'atomicComponents';
import Button from 'atomicComponents/Button';
import { useUpdateMissionsConfigs } from 'services/data/farms';

import { IFarm } from 'models/farm';
import { IGrove } from 'models/grove';
import { IFeed } from 'models/feed';
import { IWeeklyTrapMonitoringConfig, EMissionType } from 'models/mission';

import { mfColors } from 'vars';

const ContentWrapper = styled.div`
  padding: 24px;
  position: relative;
`;

const Control = styled.div`
  font-size: 20px;
`;

const Close = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 24px;
  height: 24px;
  cursor: pointer;
`;

const Separator = styled.div`
  width: 100%;
  height: 2px;
  background: ${mfColors.grey};
  margin: 16px 0;
`;

const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin: 0 0 16px;

  > .label {
    text-align: right;
  }

  .equals {
    padding: 0 16px 0 32px;
  }
`;

interface IColorSampleProps {
  color: string;
}

const ColorSample = styled.div`
  width: 100px;
  height: 32px;
  background: ${({ color }: IColorSampleProps) => color};
`;

const SELECT_STYLE = {
  background: mfColors.superLightBlue,
  borderRadius: '4px',
  width: '48px',
  fontSize: '14px',
  padding: '0px',
  display: 'inline-block',
  textAlign: 'center'
};

interface IProps {
  farm: IFarm | null;
  feed: IFeed;
  grove: IGrove | null;
  badgeColorsConfig: IWeeklyTrapMonitoringConfig | null;
}

const WeeklyTrapLegend = ({ farm, feed, grove, badgeColorsConfig }: IProps): JSX.Element | null => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isModified, setIsModified] = useState(false);
  const [badgeColors, setBadgeColors] = useState<IWeeklyTrapMonitoringConfig | null>(null);
  const updateMissionsConfigs = useUpdateMissionsConfigs();
  const { t } = useTranslation();

  const pestName = useMemo(() => t(`missions.pests.${feed.data?.trapType || ''}`), [t, feed]);

  const handleSaveConfigs = useCallback(() => {
    if (badgeColors && isModified) {
      updateMissionsConfigs(farm?.id || '', EMissionType.WeeklyTrapMonitoring, badgeColors);
      setIsModified(false);
      setIsExpanded(false);
    }
  }, [farm, isModified, updateMissionsConfigs, badgeColors]);

  const handleCancel = useCallback(() => {
    setIsExpanded(false);
    setBadgeColors(badgeColorsConfig);
  }, [badgeColorsConfig]);

  useEffect(() => {
    setBadgeColors(badgeColorsConfig);
  }, [badgeColorsConfig]);

  useEffect(
    () => () => {
      if (isExpanded) {
        setBadgeColors(badgeColorsConfig);
      }
    },
    [isExpanded, badgeColorsConfig]
  );

  const updateColor = useCallback(
    (index: number, value: number) => {
      setBadgeColors((prev) => {
        if (!feed.data?.trapType || !prev?.[feed.data.trapType]?.colors) return prev;
        const values = prev[feed.data.trapType].colors.map((row) => [row.min, row.max]).flat();
        if (values[index] === null) return prev;

        const updatedValueDiff = value - (values[index] as number);

        const updatedValues = values.map((val, ind) => {
          if (val === null) return val;

          if (updatedValueDiff < 0 && (index === 1 || index === values.length - 3) && ind === index + 1) {
            return value;
          }

          if (updatedValueDiff > 0 && (index === 2 || index === values.length - 2) && ind === index - 1) {
            return value;
          }

          if ((updatedValueDiff > 0 && ind > index && val < value) || ind === index || (updatedValueDiff < 0 && ind < index && val > value)) {
            return value;
          }

          return val;
        });

        return {
          ...prev,
          [feed.data.trapType]: {
            colors: prev[feed.data.trapType].colors.map((row, index) => {
              const min = index * 2;
              const max = index * 2 + 1;

              return {
                ...row,
                min: row.min === null ? row.min : updatedValues[min],
                max: row.max === null ? row.max : updatedValues[max]
              };
            })
          }
        };
      });
      setIsModified(true);
    },
    [feed.data?.trapType]
  );

  if (!badgeColors || !feed.data?.trapType) return null;

  return (
    <Dropdown
      toggleType={EToggleType.Click}
      onVisibilityChange={setIsExpanded}
      isVisible={isExpanded}
      position={EPosition.BottomRight}
      control={
        <Control>
          <FontAwesomeIcon icon={faEllipsisVertical} />
        </Control>
      }
      content={
        <ContentWrapper>
          <Close onClick={() => setIsExpanded(false)}>
            <FontAwesomeIcon icon={faTimes} />
          </Close>
          <span>{`${pestName} | ${farm?.name || ''}`}</span>
          <Separator />
          {badgeColors[feed.data.trapType].colors.map((entry, index) => (
            <Row key={index}>
              <div className="label">
                {entry.min === null && (
                  <Trans
                    i18nKey="missions.less_then"
                    t={t}
                    components={[
                      <Input
                        allowInput
                        value={(entry.max || 0).toLocaleString()}
                        type="number"
                        min={1}
                        max={999}
                        onChange={({ value }) => updateColor(index * 2 + 1, Number(value))}
                        name="less_then"
                        style={SELECT_STYLE}
                      />
                    ]}
                  />
                )}
                {entry.max === null && (
                  <Trans
                    i18nKey="missions.greater_or_equal_to"
                    t={t}
                    components={[
                      <Input
                        allowInput
                        value={(entry.min || 0).toLocaleString()}
                        type="number"
                        min={1}
                        max={999}
                        onChange={({ value }) => updateColor(index * 2, Number(value))}
                        name="greater_or_equal_to"
                        style={SELECT_STYLE}
                      />
                    ]}
                  />
                )}
                {entry.min !== null && entry.max !== null && (
                  <Trans
                    i18nKey="missions.from_x_to_y"
                    t={t}
                    components={[
                      <Input
                        allowInput
                        value={(entry.min || 0).toLocaleString()}
                        type="number"
                        min={1}
                        max={999}
                        onChange={({ value }) => updateColor(index * 2, Number(value))}
                        name="from_x_to_y"
                        style={SELECT_STYLE}
                      />,
                      <Input
                        allowInput
                        value={(entry.max || 0).toLocaleString()}
                        type="number"
                        min={1}
                        max={999}
                        onChange={({ value }) => updateColor(index * 2 + 1, Number(value))}
                        name="from_x_to_y"
                        style={SELECT_STYLE}
                      />
                    ]}
                  />
                )}
              </div>
              <div className="equals">=</div>
              <ColorSample color={entry.color} />
            </Row>
          ))}
          <Separator />
          <Buttons>
            <Button isSecondary onClick={handleCancel} text={t('shared.cancel')} />
            <Button isDisabled={!badgeColors || !isModified} onClick={handleSaveConfigs} text={t('shared.save')} />
          </Buttons>
        </ContentWrapper>
      }
    />
  );
};

export default WeeklyTrapLegend;
