/* eslint-disable*/
L.TileLayer.Zoomify = L.TileLayer.extend({
  options: {
    minZoom: 1,
    continuousWorld: true,
    tolerance: 0.8
  },

  initialize(url, config) {
    const options = L.setOptions(this, config);
    this._url = url;

    let imageSize = L.point(options.width, options.height);
    const { tileSize } = options;

    this._imageSize = [imageSize];
    this._gridSize = [this._getGridSize(imageSize)];

    while (parseInt(imageSize.x) > tileSize || parseInt(imageSize.y) > tileSize) {
      imageSize = imageSize.divideBy(2).floor();
      this._imageSize.push(imageSize);
      this._gridSize.push(this._getGridSize(imageSize));
    }

    this._imageSize.reverse();
    this._gridSize.reverse();

    this.options.maxNativeZoom = this._gridSize.length - (this.check512() ? 2 : 1);
    this.options.maxZoom = this.options.maxNativeZoom + 3;
  },

  onAdd(map) {
    L.TileLayer.prototype.onAdd.call(this, map);

    const mapSize = map.getSize();
    const zoom = this._getBestFitZoom(mapSize);
    const imageSize = this._imageSize[zoom];
    const center = map.options.crs.pointToLatLng(L.point(imageSize.x / 2, imageSize.y / 2), zoom);

    map.setView(center, zoom, true);
  },

  _getGridSize(imageSize) {
    const { tileSize } = this.options;

    return L.point(Math.ceil(imageSize.x / tileSize), Math.ceil(imageSize.y / tileSize));
  },

  _getBestFitZoom(mapSize) {
    const { tolerance } = this.options;

    let zoom = this._imageSize.length - 1;
    let imageSize;

    while (zoom) {
      imageSize = this._imageSize[zoom];
      if (imageSize.x * tolerance < mapSize.x && imageSize.y * tolerance < mapSize.y) {
        return zoom;
      }
      zoom -= 1;
    }

    return zoom;
  },

  _tileShouldBeLoaded(tilePoint) {
    const zoom = Math.min(this._map.getZoom(), this.options.maxNativeZoom);
    const gridSize = this._gridSize[zoom];

    return tilePoint.x >= 0 && tilePoint.x < gridSize.x && tilePoint.y >= 0 && tilePoint.y < gridSize.y;
  },

  _addTile(tilePoint, container) {
    const tilePos = this._getTilePos(tilePoint);
    const tile = this._getTile();
    const zoom = Math.min(this._map.getZoom(), this.options.maxNativeZoom);
    const imageSize = this._imageSize[zoom];
    const gridSize = this._gridSize[zoom];
    const { tileSize } = this.options;

    if (tilePoint.x === gridSize.x - 1) {
      tile.style.width = `${imageSize.x - tileSize * (gridSize.x - 1)}px`;
    }

    if (tilePoint.y === gridSize.y - 1) {
      tile.style.height = `${imageSize.y - tileSize * (gridSize.y - 1)}px`;
    }
    //
    L.DomUtil.setPosition(tile, tilePos, L.Browser.chrome || L.Browser.android23);

    this._tiles[`${tilePoint.x}:${tilePoint.y}`] = tile;
    this._loadTile(tile, tilePoint);

    if (tile.parentNode !== this._tileContainer) {
      container.appendChild(tile);
    }
  },

  _loadTile(tile, tilePoint) {
    tile._layer = this;
    tile.onload = this._tileOnLoad;
    tile.onerror = this._tileOnError;

    this._adjustTilePoint(tilePoint);
    // https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/crossorigin
    // in order to use cookies for the new tiled image services
    tile.crossOrigin = this.options.crossOrigin || 'use-credentials'; // make sure you set this attribute before set stc to the image
    tile.src = this.getTileUrl(tilePoint);

    this.fire('tileloadstart', {
      tile,
      url: tile.src
    });
  },

  getTileUrl(tilePoint) {
    const { x, y, z } = tilePoint;
    return this.options.tilesAccessLink ? `${this.options.tilesAccessLink}/${z}/${x}/${y}.jpg` : this.options.urlTable[`${z}-${x}-${y}`];
  },

  check512() {
    return this.options.realTileSize === 512;
  },

  getMaxNativeZoom() {
    return this.options.maxNativeZoom + (this.check512() ? 1 : 0);
  }
});

L.tileLayer.zoomify = function (url, options) {
  return new L.TileLayer.Zoomify(url, options);
};
