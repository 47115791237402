import React, { useMemo } from 'react';
import styled from 'styled-components';

import { ICommodityCombination } from 'models/commodity';
import { IGrove } from 'models/grove';
import { mfColors } from 'vars';

const Wrapper = styled.div`
  padding: 8px 0;
  font-size: 14px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

interface ICommodityItemProps {
  isActive: boolean;
}

const CommodityItem = styled.div`
  border-radius: 4px;
  padding: 8px 16px;
  background-color: ${({ isActive }: ICommodityItemProps) => (isActive ? mfColors.white : 'transparent')};
  font-size: 16px;
  margin: 2px;
  color: ${mfColors.darkGrey};
  font-family: MontserratBold;
  text-align: center;
  cursor: pointer;

  &:hover {
    background-color: ${mfColors.white35};
  }
`;
export type TClickValueModifier = (arr: ICommodityCombination | ICommodityCombination[]) => void;

interface IProps {
  grove: IGrove;
  commodityCombinations: ICommodityCombination[];
  selectedVarieties: ICommodityCombination[];
  onClick: TClickValueModifier;
}

const GroveCommoditiesSelector = ({ grove, commodityCombinations, selectedVarieties, onClick }: IProps) => {
  const commoditiesData = useMemo(() => {
    if (!grove.attributes.commodityCombinations?.length) return [];

    return grove.attributes.commodityCombinations
      .map((id: string) => commodityCombinations.find((combination) => combination.id === id))
      .filter((combination) => !!combination) as ICommodityCombination[];
  }, [grove.attributes.commodityCombinations, commodityCombinations]);

  const isCommodityActive = (commodity) => selectedVarieties.some((c) => c.id === commodity.id);

  return (
    <Wrapper>
      {commoditiesData.map((commodity) => (
        <CommodityItem key={commodity.id} isActive={isCommodityActive(commodity)} onClick={() => onClick(commodity)}>
          {commodity.variety}
        </CommodityItem>
      ))}
    </Wrapper>
  );
};

export default GroveCommoditiesSelector;
