import db from 'services/data/providers/firebase';
import { ref, child, get } from 'firebase/database';

import { ICompany } from 'models/company';
import { ICustomerSurvey } from 'models/survey';

const getCustomers = (): Promise<ICompany[]> => {
  const customerRef = ref(db.database);

  return get(child(customerRef, '/customers/')).then((snap) => {
    const customerSet = snap.val();
    return Object.keys(customerSet).map((key) => ({
      id: key,
      ...customerSet[key]
    })) as ICompany[];
  });
};

const getCustomerSurveys = (): Promise<ICustomerSurvey[]> => {
  const customerSurveysRef = ref(db.database);
  return get(child(customerSurveysRef, '/customer-surveys/')).then((snap) => {
    const customerSurveysSet = snap.val();
    const result: ICustomerSurvey[] = [];
    Object.keys(customerSurveysSet).forEach((key: string) => {
      Array.prototype.push.apply(
        result,
        Object.keys(customerSurveysSet[key]).map((id: string) => ({
          id,
          ...customerSurveysSet[key][id]
        }))
      );
    });
    return result;
  });
};

const customerSurveyService = {
  getCustomerSurveys,
  getCustomers
};

export default customerSurveyService;
