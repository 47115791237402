import React, { useEffect, useCallback, useState, useMemo, useContext } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Location, useLocation, useNavigate, useParams } from 'react-router-dom';
import * as turf from '@turf/turf';
import { ToastContainer, toast } from 'react-toastify';
import { useModal } from 'react-modal-hook';
import SideBar from 'containers/SideBar';
import Modal from 'atomicComponents/Modal';
import Button from 'atomicComponents/Button';

import { MapContext } from 'components/shared/Map';
import MissionsPageMenu from 'containers/MissionsPageMenu';
import MissionResolveMenu from 'containers/MissionResolveMenu';
import MissionGroveSelectionMenu from 'containers/MissionGroveSelectionMenu';
import CreateMissionModal, { ALL_ZONES_PARAM } from 'containers/CreateMissionModal';
import CreateMissionHeader from 'containers/CreateMissionHeader';
import ResolveMissionHeader from 'containers/ResolveMissionHeader';
import MissionsListHeader from 'containers/MissionsListHeader';
import MissionModal from 'components/missions/MissionModal';
import PestsFeedModal from 'containers/mission-heatmap/PestsFeedModal';
import MissionToggleIconsControl from 'components/main/MapControls/MissionToggleIconsControl';
import MissionSelectionSwitch from 'components/missions/MissionSelectionSwitch';

import { ICreateMissionIncompleteParams, IMission, EMissionStatus, EMissionType, GROVE_LEVEL_MISSION_TYPES, TREE_LEVEL_MISSION_TYPES, EMissionSubtype } from 'models/mission';
import { ISurveyWithTimeRange } from 'models/survey';
import { IGrove } from 'models/grove';
import { IUserInfo } from 'models/user';
import { TAnyReport, EReportType } from 'models/report';
import { ERichItemType } from 'models/richItem';
import { EEventType } from 'models/analytics';
import { ITreeMapboxFeature } from 'models/tree';
import { ICreateFeedIncompleteParams, IFeed } from 'models/feed';

import farmsHooks from 'hooks/farms.hooks';
import mapHooks from 'hooks/map.hooks';
import dashboardHooks from 'hooks/dashboard.hooks';
import missionUtils from 'utils/mission';
import { getTileset, TAnyWrappedTileType } from 'services/util/mapHelpers';
import addRastersLayers, { removeRasterLayers } from 'services/util/mapRasters';
import {
  drawZones,
  drawGroves,
  drawSelectedGroves,
  setGroveEventHandlers,
  removeGroveEventHandlers,
  drawHoveredGrove,
  clearHoveredGrove,
  addZoneLabelMarkers,
  removeAllZoneMarkers
} from 'services/util/mapFarm';
import {
  addPestsP3ClustersLayers,
  addPestsP3ClustersMarkers,
  addClickHandlers,
  removeClickHandlers,
  addPestsClustersMarkers,
  addPestsClustersLayers,
  addPestsClickHandlers,
  removePestsClickHandlers,
  setPestsPointClusterSource
} from 'services/util/mapPestCount';
import mapFarmMissionsService from 'services/util/mapFarmMissions';
import mapFarmRichItemsService from 'services/util/mapFarmRichItems';
import { drawHotspots, clearHotspots } from 'services/util/mapHotspot';
import signInHooks from 'hooks/signIn.hooks';
import missionHooks, { EMapSelectionMode } from 'hooks/mission.hooks';
import mapTilesetService from 'services/util/mapTileset';
import { missionsListLink, TMissionsLocationState } from 'utils/routes';
import { heatmapFeedSelector, isNewFieldReportsSelector, setMissionTypeFilter } from 'redux/missions/missionsSlice';

import { mfColors, mfSizes } from 'vars';
import { useDispatch, useSelector } from 'react-redux';
import { isSideBarExpandedSelector, setIsCapacityShown, setIsShowReplantInventory } from 'redux/appStateSlice';
import LocalLoader from 'atomicComponents/LocalLoader';
import { regionMeasurementSystemSelector, regionSelector } from 'redux/region/regionSlice';
import { capacityStatisticByRulesSelector, hiddenSegmentsSelector, newSegmentsSelector, replantRulesSelector } from 'redux/replant/replantSlice';
import { useReplantInventoryDialog } from 'containers/ReplantInventoryDialog';
import replantHooks, { NON_SURVEYID } from 'hooks/replant.hooks';
import appConfigHooks from 'hooks/appConfig.hooks';
import feedHooks from 'hooks/feed.hooks';
import replantUtils from 'utils/replant';
import mapGroveRichItemsService from 'services/util/mapGroveRichItems';
import FieldReports from 'containers/FieldReports';
import { addReplantsClustersMarkers, setReplantsClusterSource } from 'services/util/mapReplants';
import { latestSurveyGrovesSelector, latestSurveyStatsSelector } from 'redux/farm/farmSlice';
import SegmentTool from 'containers/SegmentTool';
import { getDistanceMeasurementType, getDistanceMeasurementValue } from 'redux/distanceMeasurementSlice';
import missionConfigHooks from 'hooks/missionsConfig.hooks';
import PestsReport from 'components/missions/PestsReport';

const MISSION_MAX_ZOOM = 15;

const MissionsWrapper = styled.div`
  position: fixed;
  top: 0px;
  right: 0px;
  z-index: 1;
  width: calc(100% - ${mfSizes.sideBarAreaWidth} - ${mfSizes.staticMenuWidth});
`;

const CreateMissionControlsWrapper = styled.div`
  position: fixed;
  bottom: 32px;
  left: calc(50% + (${mfSizes.sideBarAreaWidth} + ${mfSizes.staticMenuWidth}) / 2);
  transform: translateX(-50%);
  display: flex;
  flex-direction: row;
  z-index: 1;

  div {
    &:first-child {
      margin: 0 16px 0 0;
    }
  }
`;

const SideBarControlsWrapper = styled.div`
  position: absolute;
  bottom: 32px;
  right: 12px;
  margin: 0px auto;
  z-index: 2;
`;
const SegmentsControlsWrapper = styled(SideBarControlsWrapper)`
  right: auto;
  left: 445px;
`;

const CREATE_ACTION = 'create';

const MISSION_RICH_ITEMS: { [key: string]: ERichItemType } = {
  [EReportType.Cipo]: ERichItemType.Cipo,
  [EReportType.DedicatedVisit]: ERichItemType.Health,
  [EReportType.FreeArea]: ERichItemType.FreeArea,
  [EReportType.Height]: ERichItemType.Height,
  [EReportType.Weeds]: ERichItemType.Weeds
};

const MissionsScreen = (): JSX.Element => {
  const { activeMap: map, setMapOptions } = useContext(MapContext);
  const navigate = useNavigate();
  const location = useLocation() as Location;
  const params = useParams();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [reports, setReports] = useState<TAnyReport[]>([]);
  const [heatmapModalFeed, setHeatmapModalFeed] = useState<IFeed[]>([]);
  const [status, setStatus] = useState<EMissionStatus>(EMissionStatus.InProgress);
  const [criticalReports, setCriticalReports] = useState<TAnyReport[]>([]);
  const { farms, zones, surveys, allSurveys } = farmsHooks.useFarmEntities();
  const groves = useSelector(latestSurveyGrovesSelector);
  const selectedSurveyStats = useSelector(latestSurveyStatsSelector);
  const [selectedGrove, setSelectedGrove] = useState<IGrove | null>(null);
  const selectedSurvey = useMemo(() => surveys[0] || allSurveys[0], [allSurveys, surveys]);
  const selectedSurveyID = useMemo(() => selectedSurvey?.id, [selectedSurvey]);
  const { selectedFarm } = farmsHooks.useFarmMapInfo(params.farmID, undefined, undefined, selectedSurvey?.id);
  const selectedFarmID = useMemo(() => selectedFarm?.id, [selectedFarm]);
  const [hoveredMission, setHoveredMission] = useState<IMission | null>(null);
  const [selectedMission, setSelectedMission] = useState<IMission | null>(null);
  const initialCheckedGroves = useMemo(() => (location.state as TMissionsLocationState)?.checkedGroves || [], [location.state]);
  const [checkedGroves, setCheckedGroves] = useState<string[]>(initialCheckedGroves);
  const [missionToCreate, setMissionToCreate] = useState<ICreateMissionIncompleteParams | null>(null);
  const { setExpandedTreeImage } = feedHooks.useFeedImageModal();
  const [missionToEdit, setMissionToEdit] = useState<IMission | null>(null);
  const [mapFeedVisible, setMapFeedVisible] = useState(false);
  const [isFieldReports, setIsFieldReports] = useState(false);
  const { logMissionEvent, logMissionCreateClickEvent } = missionHooks.useMissionLogger();
  const { fieldReports } = missionHooks.useMissionFieldReports(params.farmID);
  const isNewFieldReports = useSelector(isNewFieldReportsSelector);
  const distanceValue = useSelector(getDistanceMeasurementValue);
  const distanceMeasurementType = useSelector(getDistanceMeasurementType);
  const newSegments = useSelector(newSegmentsSelector);
  dashboardHooks.useGetCompanies();
  const missionType = useMemo(() => (missionToCreate || missionToEdit || selectedMission)?.type, [missionToCreate, missionToEdit, selectedMission]);
  const isTreeLevelMission = useMemo(
    () => [...TREE_LEVEL_MISSION_TYPES, EMissionType.Replant].includes((missionToCreate?.type || missionToEdit?.type || selectedMission?.type) as EMissionType),
    [missionToCreate, missionToEdit, selectedMission]
  );
  const { mapSelectionMode, setMapSelectionMode } = missionHooks.useMapSelectionMode(isTreeLevelMission);
  const missionZoneID = useMemo(() => (missionToCreate || missionToEdit || selectedMission)?.zoneID, [missionToCreate, missionToEdit, selectedMission]);
  const editGroves = useMemo(() => {
    const missionGroves = (missionToEdit || selectedMission)?.groves;

    if (missionGroves) {
      const missionGroveIDs = Object.keys(missionGroves);

      return groves.filter((grove) => missionGroveIDs.includes(grove.id));
    }

    return groves;
  }, [missionToEdit, selectedMission, groves]);

  const missionRichItemType = useMemo(() => {
    if (!missionType) return null;
    return MISSION_RICH_ITEMS[missionType] || null;
  }, [missionType]);
  const { statistic, isLoading: isStatisticLoading } = farmsHooks.useRichItemsStatistic(missionRichItemType, selectedFarm?.id, selectedSurvey?.id, selectedFarm?.config?.features);
  const { satelliteHotspots, hotspots, getFarmHotspots } = farmsHooks.useFarmHotspots();
  const { getFarmReports, loading: reportsLoading } = farmsHooks.useFarmBulletinReports();
  const { missions, loading: missionsLoading } = farmsHooks.useFarmMissions(params.farmID);
  const { selectedDateRange, setSelectedDateRange, missionsSummaryMode, setMissionsSummaryMode, selectedMissions } = missionHooks.useSelectedMissions(selectedMission, missions);
  const feedMissions = useMemo(() => (missionToEdit ? [missionToEdit] : selectedMissions), [missionToEdit, selectedMissions]);
  const { feed, reportLocationTreshold } = missionHooks.useMissionFeed(feedMissions);
  const { isHeatmapVisible, setIsHeatmapVisible } = missionHooks.useMissionHeatmap(map, selectedMissions, feed);
  const region = useSelector(regionSelector);
  const { getFarmVectiles, vectiles } = farmsHooks.useGetFarmVectiles();
  const richItemMeasurementRanges = appConfigHooks.useGetRichItemRanges();
  const { rasterLayers } = farmsHooks.useGroveRastersTiles(selectedFarm, selectedGrove, selectedSurvey);
  const missionConfig = missionHooks.useMissionConfig(selectedMission);
  const [selectedTreeReports, setSelectedTreeReports] = useState<ICreateFeedIncompleteParams[]>([]);
  const heatmapFeed = useSelector(heatmapFeedSelector);
  const isAllowedToEdit = missionHooks.useIsAllowedToEdit();
  const [groveToResolve, setGroveToResolve] = useState<string | null>(null);

  const canEditSelectedMission = useMemo(() => isAllowedToEdit(selectedMission), [isAllowedToEdit, selectedMission]);

  const isSideBarExpanded = useSelector(isSideBarExpandedSelector);
  const user = signInHooks.useSignedInUser();
  const capacityStatistic = useSelector(capacityStatisticByRulesSelector);
  const metricType = useSelector(regionMeasurementSystemSelector);
  const replantRules = useSelector(replantRulesSelector);
  const hiddenSegments = useSelector(hiddenSegmentsSelector);
  const [replantMission, setReplantMission] = useState<ICreateMissionIncompleteParams | null>(null);

  replantHooks.useFarmReplantRules(params.farmID, metricType);
  replantHooks.useGetMissionReplantRules(missionToEdit?.id || selectedMission?.id);
  replantHooks.useReplantCapacityStatistic(ERichItemType.Replant, params.farmID, NON_SURVEYID, metricType);
  replantHooks.useClearReplantRules(params.farmID);

  useEffect(() => {
    if (map && missionType === EMissionType.Replant && capacityStatistic) {
      const grovesToShow = missionZoneID ? groves.filter((entry) => entry.zoneID === missionZoneID) : groves;
      addReplantsClustersMarkers(map, grovesToShow, capacityStatistic);
    }

    return () => {
      if (map) {
        setReplantsClusterSource(map, []);
      }
    };
  }, [map, editGroves, missionType, missionZoneID, groves, capacityStatistic]);

  const { geoJSON: capacityGeoJSON } = replantHooks.useFetchDrawSegments(
    ERichItemType.Capacity,
    missionType === EMissionType.Replant ? selectedFarm?.id : undefined,
    selectedGrove?.id,
    selectedSurvey?.id,
    richItemMeasurementRanges
  );

  const capacityGeoJSONByRules = useMemo(() => {
    if (!capacityGeoJSON || !replantRules) return null;

    return replantUtils.getReplantGeojson(capacityGeoJSON, replantRules, hiddenSegments, newSegments);
  }, [capacityGeoJSON, replantRules, hiddenSegments, newSegments]);

  const onHideSegment = replantHooks.useHideSegment(selectedFarm?.id, selectedGrove?.id, selectedSurvey?.id);
  const { showPopup } = mapHooks.useReplantPopup(map, metricType, null, () => showPopup(null), onHideSegment);
  const segmentClickHandler = replantHooks.useSegmentClickHandler(showPopup);

  useEffect(() => {
    // eslint-disable-next-line prettier/prettier
    const selectedReplantClickHandler = () => { };
    if (map && selectedGrove && capacityGeoJSONByRules) {
      mapGroveRichItemsService.addRichItemsLayers(map, capacityGeoJSONByRules, segmentClickHandler, selectedReplantClickHandler);
    }

    return () => {
      if (map && capacityGeoJSONByRules) {
        mapGroveRichItemsService.detachTreeClickListeners(map, segmentClickHandler, selectedReplantClickHandler);
        mapGroveRichItemsService.removeRichItemsLayers(map);
      }
    };
  }, [map, selectedGrove, capacityGeoJSONByRules, segmentClickHandler]);

  const handleSelectTree = useCallback(
    (tree: ITreeMapboxFeature | null) => {
      if (user && (missionToCreate || missionToEdit) && selectedGrove && isTreeLevelMission) {
        setSelectedTreeReports((prev) => {
          const mission = missionToCreate || missionToEdit;
          if (!tree || !mission) return prev;
          const treeReportExists = prev.find((entry) => entry.geoObjectID === tree.properties.id);

          if (treeReportExists) return prev.filter((entry) => entry.geoObjectID !== tree.properties.id);

          const { subGrove, row, treeIndex } = tree.properties;
          const treeName = subGrove && row ? `${subGrove}/${row}/${treeIndex}` : treeIndex;

          const report = missionUtils.getMissionReportParams(user, mission, treeName, tree.properties.id, tree, selectedGrove.id, selectedGrove.zoneID);
          return report ? [...prev, report] : prev;
        });
      }
    },
    [user, missionToCreate, missionToEdit, selectedGrove, isTreeLevelMission]
  );

  const handleUploadTrees = useCallback(
    (feed: ICreateFeedIncompleteParams[]) => {
      if (user) {
        setSelectedTreeReports((prev) => [...prev.filter((report) => !feed.find((entry) => entry.geoObjectID === report.geoObjectID)), ...feed]);
        toast(<MissionModal title={t('shared.ready')} content={t('missions.trees_selected')} />);
      }
    },
    [user, t]
  );

  const { treeClickHandler } = mapHooks.useMissionTreeClickHandler(map, handleSelectTree);

  signInHooks.useIsAllowedFarm(params.farmID);

  const previousSurvey = useMemo(() => {
    if (!selectedSurveyID) return null;

    const surveyIndex = surveys.findIndex((survey) => survey.id === selectedSurveyID);
    return surveyIndex < surveys.length - 1 ? surveys[surveyIndex + 1] : null;
  }, [selectedSurveyID, surveys]);

  const [showHeatmapFeedModal, hideHeatmapFeedModal] = useModal(
    () => (
      <Modal
        width="720px"
        onClose={() => {
          hideHeatmapFeedModal();
          setHeatmapModalFeed([]);
        }}
      >
        <PestsFeedModal config={missionConfig} feed={heatmapModalFeed} />
      </Modal>
    ),
    [missionConfig, heatmapModalFeed]
  );

  const handleCreateMission = useCallback(
    (missionToCreate: ICreateMissionIncompleteParams) => {
      if (missionToCreate.type === EMissionType.Replant) {
        dispatch(setIsShowReplantInventory(true));
        setReplantMission(missionToCreate);
      } else {
        setMissionToCreate(missionToCreate);
      }
    },
    [dispatch]
  );

  const [showCreateMissionModal, hideCreateMissionModal] = useModal(
    () => (
      <Modal
        width="1032px"
        onClose={() => {
          hideCreateMissionModal();
          navigate(missionsListLink(params.farmID as string));
        }}
      >
        <CreateMissionModal
          onUploadTrees={handleUploadTrees}
          onCreateMission={handleCreateMission}
          onSetCheckedGroves={setCheckedGroves}
          selectedSurvey={selectedSurvey}
          previousSurvey={previousSurvey}
        />
      </Modal>
    ),
    [selectedFarm, navigate, selectedSurvey, previousSurvey, handleCreateMission]
  );

  const handleUpdateReplant = useCallback(() => {
    if (replantMission) {
      setMissionToCreate(replantMission);
    }
  }, [replantMission]);

  useReplantInventoryDialog(replantRules, false, checkedGroves, setCheckedGroves, handleUpdateReplant);

  const missionZone = useMemo(() => zones.find((zone) => zone.id === missionZoneID) || null, [missionZoneID, zones]);
  const mapOptions = mapHooks.useGetMapOptions(zones, missionZone, selectedGrove, null);
  const missionsFeed = useMemo(() => {
    if (isFieldReports) return fieldReports;

    if (isHeatmapVisible && heatmapFeed.length) {
      return [];
    }

    return feed;
  }, [isFieldReports, feed, isHeatmapVisible, heatmapFeed, fieldReports]);
  const visiblePestsFeed = useMemo(() => {
    if (selectedGrove) {
      return heatmapFeed.filter((item) => item.groveID === selectedGrove.id);
    }
    return heatmapFeed;
  }, [selectedGrove, heatmapFeed]);

  mapHooks.useMapFeed(
    map,
    missionsFeed,
    selectedFarm,
    selectedGrove,
    reportLocationTreshold,
    true,
    selectedMission,
    !!selectedTreeReports.length,
    setExpandedTreeImage,
    mapFeedVisible
  );
  mapHooks.useMapCreateFeed(map, selectedTreeReports, reportLocationTreshold);

  const missionMapOptions = useMemo(
    () => ({
      ...mapOptions,
      maxZoom: MISSION_MAX_ZOOM
    }),
    [mapOptions]
  );

  useEffect(() => {
    dispatch(setMissionTypeFilter(null));
  }, [dispatch]);

  useEffect(() => {
    if (heatmapModalFeed.length) {
      showHeatmapFeedModal();
    } else {
      hideHeatmapFeedModal();
    }
  }, [heatmapModalFeed, showHeatmapFeedModal, hideHeatmapFeedModal]);

  useEffect(() => {
    if (!selectedMission || selectedGrove || !isTreeLevelMission || isHeatmapVisible || selectedMissions.length > 1) {
      setMapFeedVisible(false);
    }
  }, [selectedMission, selectedGrove, isTreeLevelMission, isHeatmapVisible, selectedMissions.length]);

  const addClusterPoints = useCallback(() => {
    if (!map) return;

    setPestsPointClusterSource(map, visiblePestsFeed);
  }, [map, visiblePestsFeed]);
  const onPestsHeatMapClick = useCallback(
    (e) => {
      e.preventDefault();

      const feature = e.features[0];
      const clickedGrove = groves.find((grove) => {
        const polygon = turf.polygon(grove.geometry.coordinates);

        return turf.booleanPointInPolygon(feature, polygon) ? grove : null;
      });
      const heatmapFeed = visiblePestsFeed.filter((item) => item.groveID === clickedGrove?.id);

      setHeatmapModalFeed(heatmapFeed);
    },
    [groves, visiblePestsFeed]
  );

  useEffect(() => {
    let clusterTimeout;
    if (map && isHeatmapVisible && selectedMission?.type === EMissionType.WeeklyTrapMonitoring && missionConfig) {
      addPestsClustersMarkers(map, [], missionConfig);
      addPestsClustersLayers(map);
      addPestsClickHandlers(map, onPestsHeatMapClick);
      addPestsClustersMarkers(map, visiblePestsFeed, missionConfig);
      map.on('zoom', addClusterPoints);

      clusterTimeout = setTimeout(() => {
        // clustersMarkers are not visible immediately
        addClusterPoints();
      }, 100);
    }

    return () => {
      if (map) {
        removePestsClickHandlers(map, onPestsHeatMapClick);
        addPestsClustersMarkers(map, [], missionConfig);
        setPestsPointClusterSource(map, []);
        map.off('zoom', addClusterPoints);
        clearTimeout(clusterTimeout);
      }
    };
  }, [map, selectedMission, visiblePestsFeed, missionConfig, isHeatmapVisible, onPestsHeatMapClick, addClusterPoints]);

  const { pestsP3Settings } = missionConfigHooks.useGetPestsP3Settings(params.farmID);
  const [showPestsReportModal, hidePestsReportModal] = useModal(
    () => (
      <Modal closeOnBackdrop width="900px" onClose={hidePestsReportModal}>
        <PestsReport pestsP3Settings={pestsP3Settings} feed={visiblePestsFeed} grove={selectedGrove} />
      </Modal>
    ),
    [pestsP3Settings, visiblePestsFeed, selectedGrove]
  );

  const onPestsP3HeatmapClick = useCallback(
    (e) => {
      e.preventDefault();

      showPestsReportModal();
    },
    [showPestsReportModal]
  );

  useEffect(() => {
    if (map && isHeatmapVisible && selectedMission?.subType === EMissionSubtype.PestsP3) {
      addPestsP3ClustersMarkers(map, [], []);
      addPestsP3ClustersLayers(map);
      addClickHandlers(map, onPestsP3HeatmapClick);
      addPestsP3ClustersMarkers(map, visiblePestsFeed, pestsP3Settings);
    }

    return () => {
      if (map) {
        removeClickHandlers(map, onPestsP3HeatmapClick);
        addPestsP3ClustersMarkers(map, [], []);
      }
    };
  }, [map, selectedMission, visiblePestsFeed, pestsP3Settings, isHeatmapVisible, onPestsP3HeatmapClick]);

  useEffect(() => {
    if (!params.missionID || missionsLoading) return;
    const updatedMission = missions.find((mission) => mission.id === params.missionID);

    if (!updatedMission) {
      navigate(`/${params.farmID}`);
    }
  }, [missions, missionsLoading, navigate, params.farmID, params.missionID]);

  useEffect(() => {
    if (!params.status && !params.missionID && !params.action && params.farmID) {
      navigate(missionsListLink(params.farmID, EMissionStatus.InProgress));
    }
  }, [navigate, params.farmID, params.status, params.missionID, params.action]);

  useEffect(() => {
    if (params.status) {
      setStatus(params.status as EMissionStatus);
    }
  }, [params.status]);

  useEffect(() => {
    const mission = missions.find((mission) => mission.id === params.missionID);
    if (mission && mission.groves) {
      setCheckedGroves(Object.keys(mission.groves).filter((groveID) => mission.groves[groveID] === EMissionStatus.Done));
    }
    setSelectedMission(mission || null);
  }, [missions, params.missionID]);

  useEffect(() => {
    if (missionMapOptions.bounds) {
      setMapOptions(missionMapOptions);
    }
  }, [missionMapOptions, setMapOptions]);

  useEffect(() => {
    if (selectedFarm?.id && user) {
      getFarmVectiles(selectedFarm.id);
    }
  }, [selectedFarm, getFarmVectiles, user]);

  const groveTiles = useMemo((): TAnyWrappedTileType | null => {
    if (selectedGrove && vectiles && selectedSurvey?.id) {
      return getTileset(vectiles[selectedSurvey.id]?.[selectedGrove.id]?.['current-tiles'] || null, selectedSurvey);
    }
    return null;
  }, [selectedGrove, vectiles, selectedSurvey]);

  const redirectToOverview = useCallback(() => {
    if (params.action === CREATE_ACTION || params.missionID) {
      navigate(missionsListLink(params.farmID as string), { replace: true });
    }
    setMissionToCreate(null);
    setMissionToEdit(null);
  }, [params, navigate]);

  const groveClickHandler = useCallback(
    (grove: IGrove): void => {
      if (isFieldReports) {
        setSelectedGrove(grove);
        return;
      }
      if (!missionToCreate?.type && !missionToEdit?.type && (!selectedMission?.type || !selectedMission.groves[grove.id])) {
        return;
      }
      if (missionZone && grove.zoneID !== missionZone.id) return;

      if (!isTreeLevelMission || (!selectedMission?.type && mapSelectionMode === EMapSelectionMode.Block)) {
        if (canEditSelectedMission) {
          setCheckedGroves((prev) => (prev.includes(grove.id) ? prev.filter((entry) => entry !== grove.id) : [...prev, grove.id]));
        } else {
          setGroveToResolve(grove.id);
        }
      } else {
        setSelectedGrove(grove);
      }
    },
    [
      canEditSelectedMission,
      mapSelectionMode,
      missionToCreate?.type,
      missionToEdit?.type,
      selectedMission?.type,
      selectedMission?.groves,
      isTreeLevelMission,
      missionZone,
      isFieldReports
    ]
  );

  const setMissionTitle = useCallback((title: string) => {
    setMissionToCreate((prev) => {
      if (!prev || prev.title === title) return prev;
      return {
        ...prev,
        title
      };
    });
    setMissionToEdit((prev) => {
      if (!prev || prev.title === title) return prev;
      return {
        ...prev,
        title
      };
    });
  }, []);

  useEffect(() => {
    if (!params.farmID && farms.length) {
      navigate(missionsListLink(farms[0].id));
    }
  }, [params.farmID, farms, navigate]);

  useEffect(() => {
    if (missionToEdit?.id) {
      setSelectedTreeReports(feed.filter((entry) => entry.missionID === missionToEdit.id));
    } else if (!missionToCreate?.type) {
      setSelectedTreeReports([]);
    }
  }, [missionToCreate?.type, missionToEdit?.id, feed]);

  useEffect(() => {
    const isGroveLevelType = !!missionType && GROVE_LEVEL_MISSION_TYPES.includes(missionType as EReportType) && missionType !== EMissionType.General;
    if (isGroveLevelType && selectedFarmID && selectedSurveyID && missionType !== EMissionType.Replant) {
      getFarmReports(missionType as EReportType, selectedFarmID, selectedSurveyID, true).then((reports) => {
        setReports(reports);
      });
      getFarmReports(missionType as EReportType, selectedFarmID, selectedSurveyID, false).then((reports) => {
        setCriticalReports(reports);
      });
    } else {
      setReports([]);
      setCriticalReports([]);
    }
  }, [getFarmReports, selectedFarmID, selectedSurveyID, missionType]);

  useEffect(() => {
    if (selectedFarmID && selectedSurveyID) {
      getFarmHotspots(selectedFarmID, selectedSurveyID);
    }
  }, [selectedFarmID, selectedSurveyID, getFarmHotspots]);

  useEffect(() => {
    setHoveredMission((prev) => (prev && !missions.find((mission) => mission.id === prev.id) ? null : prev));
  }, [missions]);

  useEffect(() => {
    if (!map) return;
    if (rasterLayers) {
      addRastersLayers(map, rasterLayers?.urls || [], rasterLayers?.bbox);
    } else {
      removeRasterLayers(map);
    }
  }, [map, rasterLayers]);

  useEffect(() => {
    if (missionToCreate) {
      hideCreateMissionModal();
    }
  }, [missionToCreate, hideCreateMissionModal]);

  useEffect(() => {
    if (missionType) {
      setIsFieldReports(false);
    }
  }, [missionType]);

  useEffect(() => {
    const isCreateMission = params.action === CREATE_ACTION;

    if (!missionType && !isCreateMission && !isFieldReports) {
      setSelectedGrove(null);
      setCheckedGroves([]);
    }
  }, [missionType, params, isFieldReports]);

  useEffect(() => {
    setCheckedGroves((prev) => {
      const newGroves = selectedTreeReports.filter((report) => !prev.includes(report.groveID)).map((report) => report.groveID);
      return newGroves.length ? [...prev, ...newGroves] : prev;
    });
  }, [selectedTreeReports]);

  useEffect(() => {
    setSelectedTreeReports((prev) => {
      if (!prev.find((report) => !checkedGroves.includes(report.groveID))) return prev;
      return prev.filter((report) => checkedGroves.includes(report.groveID));
    });
  }, [checkedGroves]);

  useEffect(() => {
    if (selectedFarm?.id) {
      setSelectedGrove(null);
    }
  }, [selectedFarm?.id]);

  useEffect(() => {
    if (missionToCreate?.type && missionToCreate?.type !== EMissionType.Replant && params.action === CREATE_ACTION) {
      if (initialCheckedGroves.length) {
        const precheckedGroves = groves.reduce((acc: string[], grove) => {
          if (initialCheckedGroves.includes(grove.id) && grove.active) {
            acc.push(grove.id);
          }

          return acc;
        }, []);
        setCheckedGroves(precheckedGroves);
      } else {
        const precheckedGroves = criticalReports.reduce((acc: string[], report) => {
          const reportGrove = groves.find((grove) => grove.id === report.groveID);

          if (reportGrove && (reportGrove.zoneID === missionToCreate.zoneID || !missionToCreate.zoneID)) {
            acc.push(reportGrove.id);
          }
          return acc;
        }, []);
        setCheckedGroves(precheckedGroves);
      }
    }
  }, [groves, missionToCreate?.type, missionToCreate?.zoneID, params, criticalReports, initialCheckedGroves]);

  useEffect(() => {
    if (selectedFarm && !params.farmID) {
      navigate(missionsListLink(selectedFarm.id));
    }
  }, [params.farmID, selectedFarm, navigate]);

  useEffect(() => {
    logMissionEvent(selectedMission, EEventType.MissionEnter);
  }, [logMissionEvent, selectedMission]);

  useEffect(() => {
    logMissionEvent(missionToEdit, EEventType.MissionEditClicked);
  }, [logMissionEvent, missionToEdit]);

  const handleCreateMissionClick = useCallback(() => {
    showCreateMissionModal();
    logMissionCreateClickEvent(EEventType.MissionCreateClicked);
  }, [showCreateMissionModal, logMissionCreateClickEvent]);

  useEffect(() => {
    if (selectedMission) {
      setCheckedGroves(() => {
        if (!selectedMission) return [];
        return Object.keys(selectedMission.groves).filter((id) => missionUtils.checkMissionGroveStatus(selectedMission, id, EMissionStatus.Done));
      });
    }
  }, [selectedMission]);

  useEffect(() => {
    if (missionToEdit?.groves) {
      setCheckedGroves(() => (missionToEdit?.groves ? Object.keys(missionToEdit.groves) : []));
    }
  }, [missionToEdit?.groves]);

  useEffect(() => {
    if (params.action === CREATE_ACTION && params.type && user) {
      logMissionCreateClickEvent(EEventType.MissionCreateFromBulletinClicked);
      setMissionToCreate(
        missionUtils.getMissionParams(
          params.type as EReportType,
          null,
          selectedFarm,
          !params.zoneID || params.zoneID === ALL_ZONES_PARAM ? null : params.zoneID,
          user as IUserInfo,
          [],
          [],
          null,
          false,
          t
        )
      );
    } else {
      setMissionToCreate(null);
    }
  }, [params.action, params.type, params.zoneID, selectedFarm, user, t, logMissionCreateClickEvent]);

  useEffect(() => {
    if (!missionToCreate) {
      return;
    }
    if (missionToCreate.farmID !== params.farmID && !!missionToCreate.farmID) {
      navigate(missionsListLink(missionToCreate.farmID));
    }
  }, [missionToCreate, navigate, params]);

  useEffect(() => {
    if (map && zones.length && groves.length) {
      drawZones(map, zones);
      drawGroves(map, groves, selectedGrove);
    }
  }, [map, zones, groves, selectedGrove]);

  useEffect(() => {
    if (map && zones.length > 1) {
      addZoneLabelMarkers(map, zones);
    }

    return () => {
      if (map) {
        removeAllZoneMarkers(map);
      }
    };
  }, [zones, map]);

  // clean hook
  useEffect(
    () => () => {
      if (map) {
        mapFarmMissionsService.removeMissionLayers(map);
        removeRasterLayers(map);
      }
    },
    [map]
  );

  const groveMousemoveHandler = useCallback(
    (event) => {
      const grove = groves.find((grove) => grove.id === event.features?.[0].properties?.id);
      if (!grove || !map || grove.id === selectedGrove?.id) {
        return;
      }

      drawHoveredGrove(map, grove, { active: !!grove.active });
    },
    [map, groves, selectedGrove?.id]
  );

  const groveMouseleaveHandler = useCallback(() => {
    if (!map) {
      return;
    }

    clearHoveredGrove(map);
  }, [map]);

  useEffect(() => {
    const handleSelectGrove = (event) => {
      if (event.defaultPrevented) return;

      const groveID = event.features?.[0].properties?.id;
      const grove = groves.find((grove) => groveID === grove.id);
      if (grove && grove.active) {
        groveClickHandler(grove);
      }
    };

    const groveHandlersParams = [handleSelectGrove, isTreeLevelMission ? groveMousemoveHandler : undefined, isTreeLevelMission ? groveMouseleaveHandler : undefined] as const;

    if (map) {
      setGroveEventHandlers(map, ...groveHandlersParams);
    }

    return () => {
      if (map) {
        removeGroveEventHandlers(map, ...groveHandlersParams);
      }
    };
  }, [map, groves, groveClickHandler, groveMousemoveHandler, groveMouseleaveHandler, isTreeLevelMission]);

  useEffect(() => {
    if (map) {
      const grovesToPaint = selectedMission?.groves ? Object.keys(selectedMission.groves) : checkedGroves;
      drawSelectedGroves(map, groves, grovesToPaint);
    }

    return () => {
      if (map) {
        drawSelectedGroves(map, [], []);
      }
    };
  }, [map, groves, checkedGroves, selectedMission?.groves]);

  useEffect(() => {
    setHoveredMission(null);
  }, [selectedMission, missionToEdit]);

  useEffect(() => {
    dispatch(setIsCapacityShown(missionType === EMissionType.Replant));
  }, [dispatch, missionType]);

  useEffect(() => {
    if (map) {
      if (groveTiles && selectedGrove && missionType !== EMissionType.Replant) {
        const bbox = turf.bbox(selectedGrove.geometry);
        mapTilesetService.addTilesetLayers(map, groveTiles, false, treeClickHandler, undefined, bbox);
        mapFarmRichItemsService.removeRichItemsLayers(map);
      } else if (!isHeatmapVisible && groves.length && selectedSurveyStats.length && statistic && missionRichItemType) {
        let statisticToShow;

        if (selectedMission) {
          statisticToShow = Object.fromEntries(Object.entries(statistic).filter(([key]) => !!selectedMission.groves[key]));
        } else if (missionToCreate?.zoneID || missionToEdit?.zoneID) {
          const zoneID = missionToCreate?.zoneID || missionToEdit?.zoneID;
          statisticToShow = Object.fromEntries(Object.entries(statistic).filter(([key]) => groves.find((grove) => grove.id === key)?.zoneID === zoneID));
        } else {
          statisticToShow = statistic;
        }

        mapFarmRichItemsService.addRichItemsLayers(map, missionRichItemType, groves, statisticToShow, selectedSurveyStats, region?.measurementSystem);
        mapTilesetService.removeTilesetLayers(map);
      } else if (!isHeatmapVisible) {
        mapTilesetService.removeTilesetLayers(map);
        mapFarmRichItemsService.removeRichItemsLayers(map);
      }
    }

    return (): void => {
      if (map) {
        mapTilesetService.detachTreeClickListeners(map, treeClickHandler);
      }
    };
  }, [
    map,
    groves,
    region,
    statistic,
    missionRichItemType,
    selectedSurveyStats,
    selectedMission,
    treeClickHandler,
    missionToCreate?.zoneID,
    missionToEdit?.zoneID,
    groveTiles,
    missionType,
    selectedGrove,
    isHeatmapVisible
  ]);

  const navigateToMission = useCallback(
    (mission) => {
      navigate(`/missions/${mission.farmID}/${mission.id}`);
    },
    [navigate]
  );

  useEffect(() => {
    if (!map) return;
    if ((missionType === EReportType.TargetedScouting || missionType === EMissionType.GeneralScouting) && satelliteHotspots.length) {
      drawHotspots(map, satelliteHotspots, []);
    } else {
      clearHotspots(map);
    }
  }, [map, missionType, satelliteHotspots]);

  useEffect(() => {
    if (!map) return;
    if ((missionType === EReportType.TargetedScouting || missionType === EMissionType.GeneralScouting) && hotspots.length) {
      drawHotspots(map, hotspots, []);
    } else {
      clearHotspots(map);
    }
  }, [map, missionType, hotspots]);

  useEffect(() => {
    if (!map) {
      return;
    }
    mapFarmMissionsService.removeMissionLayers(map);

    if ((hoveredMission || selectedMission) && groves.length && !selectedGrove && !isHeatmapVisible) {
      const missionGroves = hoveredMission ? groves.filter((grove) => !!hoveredMission.groves[grove.id]) : groves.filter((grove) => checkedGroves.includes(grove.id));
      mapFarmMissionsService.addMissionLayers(map, missionGroves, !!selectedMission);
    }
  }, [hoveredMission, selectedMission, map, groves, checkedGroves, selectedGrove, isHeatmapVisible]);

  const handleCheckSelectedGrove = useCallback(() => {
    if (!selectedGrove) return;

    setCheckedGroves((prev) => {
      const groveIsChecked = prev.includes(selectedGrove.id);
      if (!selectedMission && !groveIsChecked) {
        toast(<MissionModal title={t('shared.selected')} content={t('missions.grove_was_added_to_the_mission')} />, { toastId: 'checked' });
      }
      return groveIsChecked ? prev.filter((entry) => entry !== selectedGrove.id) : [...prev, selectedGrove.id];
    });
    setSelectedGrove(null);
  }, [selectedGrove, selectedMission, t]);

  const { showConfirmUpdateModal: showConfirmGoToUpdate } = missionHooks.useConfirmUpdateModal(handleCheckSelectedGrove, undefined, selectedMission);

  const confirmGroveToResolve = useCallback(() => {
    if (!groveToResolve) return;
    setCheckedGroves((prev) => (prev.includes(groveToResolve) ? prev.filter((entry) => entry !== groveToResolve) : [...prev, groveToResolve]));
    setGroveToResolve(null);
  }, [groveToResolve]);

  const resetGroveToResolve = useCallback(() => {
    setGroveToResolve(null);
  }, []);

  const { showConfirmUpdateModal: showConfirmChangeGroveStatus, hideConfirmUpdateModal: hideConfirmChangeGroveStatus } = missionHooks.useConfirmUpdateModal(
    confirmGroveToResolve,
    resetGroveToResolve,
    selectedMission
  );

  useEffect(() => {
    if (groveToResolve) {
      showConfirmChangeGroveStatus();
    } else {
      hideConfirmChangeGroveStatus();
    }
  }, [groveToResolve, showConfirmChangeGroveStatus, hideConfirmChangeGroveStatus]);

  if ((missionToCreate && !selectedSurvey) || !selectedFarm || isStatisticLoading || reportsLoading || missionsLoading) {
    return (
      <SideBar>
        <LocalLoader />
      </SideBar>
    );
  }

  return (
    <>
      {isSideBarExpanded && (
        <SideBar background={missionToCreate || missionToEdit ? mfColors.white : undefined}>
          {selectedMission && (
            <MissionResolveMenu mission={selectedMission} statistic={statistic || capacityStatistic} checkedGroves={checkedGroves} setCheckedGroves={setCheckedGroves} />
          )}
          {(missionToCreate || missionToEdit) && !isFieldReports && (
            <MissionGroveSelectionMenu
              onGoToOverview={redirectToOverview}
              previousSurvey={previousSurvey}
              mission={(missionToCreate || missionToEdit) as IMission}
              missions={missions}
              survey={selectedSurvey as ISurveyWithTimeRange}
              setTitle={setMissionTitle}
              checkedGroves={checkedGroves}
              setCheckedGroves={setCheckedGroves}
            />
          )}
          {((!missionToCreate && !missionToEdit && !selectedMission) || isFieldReports) && (
            <MissionsPageMenu
              onCreateMissionClicked={handleCreateMissionClick}
              onMissionHovered={setHoveredMission}
              onMissionSelected={navigateToMission}
              onMissionEdit={setMissionToEdit}
              survey={selectedSurvey}
              missions={missions}
              groves={groves}
              status={status}
              farm={selectedFarm}
              isFieldReports={isFieldReports}
              isNewFieldReports={isNewFieldReports}
              setIsFieldReports={setIsFieldReports}
            />
          )}
          {isFieldReports && <FieldReports groves={groves} onGroveClicked={groveClickHandler} />}
        </SideBar>
      )}
      {!missionToCreate && !missionToEdit && !selectedMission && !!missions.length && !isFieldReports && (
        <MissionsWrapper>
          <MissionsListHeader missions={missions} surveyStats={selectedSurveyStats} />
        </MissionsWrapper>
      )}
      {(missionToCreate || missionToEdit) && !isFieldReports && (
        <MissionsWrapper>
          <CreateMissionHeader
            onGoToOverview={redirectToOverview}
            surveyStats={selectedSurveyStats}
            onSetCheckedGroves={setCheckedGroves}
            mission={(missionToCreate || missionToEdit) as IMission}
            onUploadTrees={handleUploadTrees}
            selectedTreeReports={selectedTreeReports}
            originalTreeReports={feed}
            groves={groves}
            isEdit={!!missionToEdit}
            checkedGroves={checkedGroves}
            selectedSurvey={selectedSurvey}
          />
        </MissionsWrapper>
      )}
      {selectedMission && (
        <MissionsWrapper>
          <ResolveMissionHeader
            mission={selectedMission}
            selectedMissions={selectedMissions}
            statistic={statistic || capacityStatistic}
            feed={feed}
            surveyStats={selectedSurveyStats}
            missionsSummaryMode={missionsSummaryMode}
            onMissionsSummaryModeChanged={setMissionsSummaryMode}
            selectedDateRange={selectedDateRange}
            onSelectDateRange={setSelectedDateRange}
            survey={selectedSurvey as ISurveyWithTimeRange}
            reports={reports}
            groves={groves}
            isHeatmapVisible={isHeatmapVisible}
            onToggleHeatmap={setIsHeatmapVisible}
            checkedGroves={checkedGroves}
          />
        </MissionsWrapper>
      )}
      {!selectedGrove && isTreeLevelMission && (missionToCreate || missionToEdit) && (
        <CreateMissionControlsWrapper>
          <MissionSelectionSwitch type={missionType as EMissionType} onChange={setMapSelectionMode} value={mapSelectionMode} />
        </CreateMissionControlsWrapper>
      )}
      {selectedGrove && (
        <CreateMissionControlsWrapper>
          <Button isSecondary onClick={() => setSelectedGrove(null)} text={t('missions.back_to_farm_view')} />
          {!selectedMission && !isFieldReports && (
            <Button
              onClick={handleCheckSelectedGrove}
              text={t(checkedGroves.includes(selectedGrove.id) ? 'missions.unselect_grove' : 'missions.select_grove', { groveName: selectedGrove.name })}
            />
          )}
          {selectedMission && selectedMission.groves[selectedGrove.id] !== EMissionStatus.Done && (
            <Button
              onClick={() => (canEditSelectedMission ? handleCheckSelectedGrove() : showConfirmGoToUpdate())}
              text={t('missions.mark_grove_as_done', { groveName: selectedGrove.name })}
            />
          )}
        </CreateMissionControlsWrapper>
      )}
      {!!selectedMission && !selectedGrove && isTreeLevelMission && !isHeatmapVisible && selectedMissions.length === 1 && (
        <SideBarControlsWrapper>
          <MissionToggleIconsControl isSelected={mapFeedVisible} onToggleIsSelected={setMapFeedVisible} />
        </SideBarControlsWrapper>
      )}
      <SegmentsControlsWrapper>
        {selectedGrove && missionType === EMissionType.Replant && metricType && (
          <SegmentTool
            distance={distanceValue}
            measurementType={distanceMeasurementType}
            metricType={metricType}
            grove={selectedGrove}
            survey={selectedSurvey}
            farm={selectedFarm}
          />
        )}
      </SegmentsControlsWrapper>
      <ToastContainer hideProgressBar closeButton={false} position="bottom-center" autoClose={3500} />
    </>
  );
};

export default MissionsScreen;
