import { EDashboardType } from 'models/dashboard';

const getDashboardTypeKey = (type: EDashboardType): string => {
  switch (type) {
    case EDashboardType.TreeAnalytics:
      return 'dashboard.tree_analytics';
    case EDashboardType.HLBPreassigned:
      return 'dashboard.hlb_contamination_levels_preassigned';
    case EDashboardType.HLBNotPreassigned:
      return 'dashboard.hlb_contamination_levels_not_preassigned';
    case EDashboardType.MarkAsCounted:
      return 'dashboard.mark_as_counted';
    default:
      return '';
  }
};

const dashboardUtils = {
  getDashboardTypeKey
};

export default dashboardUtils;
