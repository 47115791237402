import { configureStore } from '@reduxjs/toolkit';
import { authReducer } from 'redux/auth/authSlice';
import { farmReducer } from 'redux/farm/farmSlice';
import { modeReducer } from './modeSlice';
import { distanceMeasurementReducer } from './distanceMeasurementSlice';
import { appStateReducer } from './appStateSlice';
import { regionReducer } from './region/regionSlice';
import { richItemsReducer } from './richItems/richItemsSlice';
import { companyReducer } from './company/companySlice';
import { replantReducer } from './replant/replantSlice';
import { bulletinReducer } from './bulletin/bulletinSlice';
import { missionsReducer } from './missions/missionsSlice';

export const store = configureStore({
  reducer: {
    auth: authReducer,
    mode: modeReducer,
    farm: farmReducer,
    distanceMeasurement: distanceMeasurementReducer,
    appState: appStateReducer,
    region: regionReducer,
    richItems: richItemsReducer,
    company: companyReducer,
    replant: replantReducer,
    bulletin: bulletinReducer,
    missions: missionsReducer
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware()
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
