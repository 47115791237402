import React, { useCallback, useState, useEffect } from 'react';
import i18n from 'i18next';
import { useModal } from 'react-modal-hook';
import Modal from 'atomicComponents/Modal';
import styled from 'styled-components';

import { useFetchUserSearches, useUpdateUserSearches, listenUserAlerts, useUpdateUserSettings, getUserSurveyPublishedAlert, useReadUserAlert } from 'services/data/users';
import { ESupportedLanguage } from 'hooks/translations.hooks';
import { IUserSearches, IUserAlerts } from 'models/user';
import useLogger from 'hooks/logger.hooks';
import signInHooks from 'hooks/signIn.hooks';

import { mfColors } from 'vars';
import { head } from 'utils/helpers';
import { toast } from 'react-toastify';
import PublishNotification from 'components/notifications/PublishNotification';

const IframeOverlap = styled.div`
  position: absolute;
  width: calc(100% - 48px - ${({ isMac }: { isMac?: boolean }) => (isMac ? '11px' : '13px')});
  top: 56px;
  left: 20px;
  background: ${mfColors.white};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 15px;
  box-shadow: 0 8px 10px -10px ${mfColors.grey};
`;

const OverlapLogo = styled.img`
  width: 120px;
`;

const OverlapTitle = styled.div`
  font-family: MontserratBold;
  font-size: 24px;
  margin-top: 12px;
`;

const ModalIframe = styled.iframe`
  margin-top: 20px;
  height: 700px;
  width: 100%;
  display: flex;
  overflow: hidden;
`;

interface IGetUserSearchesResult {
  searches: IUserSearches | null;
  getUserSearches: () => void;
  updateUserSearches: (searches: IUserSearches) => Promise<void>;
}

const useUserSearches = (): IGetUserSearchesResult => {
  const [searches, setSearches] = useState<IUserSearches | null>(null);
  const fetchUserSearches = useFetchUserSearches();
  const saveUserSearches = useUpdateUserSearches();

  const getUserSearches = useCallback(async (): Promise<void> => {
    setSearches(await fetchUserSearches());
  }, [fetchUserSearches]);

  const updateUserSearches = useCallback(
    async (searches: IUserSearches): Promise<void> => {
      await saveUserSearches(searches);
      setSearches(searches);
    },
    [saveUserSearches]
  );

  return {
    getUserSearches,
    updateUserSearches,
    searches
  };
};

export const useWhatsNewModal = () => {
  const isMac = /(Mac|iPhone|iPod|iPad)/i.test(navigator.platform);
  const user = signInHooks.useSignedInUser();

  const updateUserSettings = useUpdateUserSettings();
  const [showWhatsNewModal, hideWhatsNewModal] = useModal(() => {
    let url = 'https://docs.google.com/document/d/e/2PACX-1vQdkbDKK7Mp6LgcyVggdJiS1PW1-tbwTo-E0dMyON2EyGeZ_6Iyf2k8Tjqb1OR7RiyidHz89prFLzBe/pub';
    if (i18n.language === ESupportedLanguage.PT) {
      url = 'https://docs.google.com/document/d/e/2PACX-1vSgpmm2B_U4queh6hTcEKuSpnIim4Jbj8Qd1x47arT9Aa4L5KfLj2CX-trHXyUHBg_FHbzcAK54oBwX/pub';
    } else if (i18n.language === ESupportedLanguage.ES) {
      url = 'https://docs.google.com/document/d/e/2PACX-1vQF_DUdbU1YBShI889CtzqfyvOpKSLMLeCCv-1TpwNr4oXAxdSLqiPEAI2SxxpNlIUuNwx4id-nyRCw/pub';
    }

    return (
      <Modal closeOnBackdrop width="1080px" onClose={hideWhatsNewModal}>
        <IframeOverlap isMac={isMac}>
          <OverlapLogo alt="logo" src="/images/whats-new-seetree-logo.png" />
          <OverlapTitle>{i18n.t('shared.nav_bar.whats_new')}</OverlapTitle>
        </IframeOverlap>
        <ModalIframe id="newWeeklyTrapMonitoringIframe" title={i18n.t('shared.whats_new.weekly_trap_monitoring')} frameBorder="0" src={url} />
      </Modal>
    );
  }, []);

  const showModal = useCallback(() => {
    if (!user) return;
    showWhatsNewModal();
    updateUserSettings({ whatsNewLastVisit: +new Date() });
  }, [user, updateUserSettings, showWhatsNewModal]);

  return {
    showWhatsNewModal: showModal,
    hideWhatsNewModal
  };
};

const DAYS_14 = 12096e5;

export const useListenUserAlerts = (id: string | undefined, farmID: string | undefined) => {
  const [data, setData] = useState<IUserAlerts[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const { logError } = useLogger();

  useEffect(() => {
    setIsLoading(true);

    const onSucess = (res: IUserAlerts[]) => {
      setData(res);
      setIsLoading(false);
    };

    const onError = (error: Error, path, type) => {
      logError(error, path, type);
      setIsLoading(false);
    };

    const createdAt = Date.now() - DAYS_14;

    const unsubscribe = id && farmID ? listenUserAlerts({ id, farmID, createdAt }, onSucess, onError) : () => {};

    return () => unsubscribe();
  }, [id, logError, farmID]);

  return {
    data,
    isLoading
  };
};

export const useUserSurveyPublishedAlert = (farmID?: string, surveyID?: string) => {
  const user = signInHooks.useSignedInUser();
  const markAsReadAlert = useReadUserAlert();

  useEffect(() => {
    if (user && farmID && surveyID) {
      getUserSurveyPublishedAlert(user.id, farmID, surveyID).then((alerts) => {
        const alert = head(alerts);

        if (alert && !alert.read) {
          toast(<PublishNotification onClose={() => toast.dismiss('publishToastId')} />, { toastId: 'publishToastId' });
          markAsReadAlert(alert.id);
        }
      });
    }
  }, [user, farmID, surveyID, markAsReadAlert]);
};

const usersHooks = {
  useUserSearches,
  useListenUserAlerts,
  useWhatsNewModal,
  useUserSurveyPublishedAlert
};

export default usersHooks;
