const mfShadows = {
  searchShadow: `-10px -10px 20px rgba(255, 255, 255, 0.35), 10px 10px 20px rgba(189, 200, 210, 0.5), inset -10px -10px 15px rgba(255, 255, 255, 0.25),
    inset 10px 10px 15px rgba(197, 207, 215, 0.5)`,
  timelineShadow: '-5px -5px 10px rgba(255, 255, 255, 0.35), 5px 5px 10px rgba(189, 200, 210, 0.5)',
  tabShadow: `-10px -10px 20px rgba(255, 255, 255, 0.35), inset -10px -10px 15px rgba(255, 255, 255, 0.25),
    inset 10px 10px 15px rgba(197, 207, 215, 0.5)`,
  staticCardDefaultShadow: '0px 4px 4px rgba(0, 0, 0, 0.25), -10px -10px 20px rgba(255, 255, 255, 0.5), 0px 50px 30px rgba(67, 133, 243, 0.25)',
  modalShadow: '0px 4px 4px rgba(0, 0, 0, 0.25), -10px -10px 20px rgba(0, 0, 0, 0.25), 0px 50px 30px rgba(0, 0, 0, 0.25)',
  missionBlockShadow: '0px 4px 8px rgba(0, 0, 0, 0.25)',
  navBarShadow: 'rgb(0 0 0 / 10%) 5px 0px 5px',
  popupShadow: '0px 8px 15px rgba(0, 0, 0, 0.25)',
  missionTypeShadow: '0px 5px 10px rgba(0, 0, 0, 0.03), 0px 20px 40px rgba(0, 0, 0, 0.02), 0px 25px 50px rgba(0, 0, 0, 0.02)',
  imagePreviewShadow: '0 0 10px 0 #ffffff'
};

export default mfShadows;
