import React, { useMemo } from 'react';
import { IUnifiedHotspot } from 'models/hotspot';
import { TAnyReport } from 'models/report';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { EMissionStatus } from 'models/mission';
import numberUtils from 'utils/numbers';
import Progress from 'atomicComponents/Progress';
import { THRESHOLD_TYPES, TYPE_ICON, TYPE_TITLE, TypeIcon, TypeTitle, TypeItem } from './BulletinModal/BulletinModalTypeSelector';

const Wrapper = styled.div`
  margin-top: 40px;
  margin-bottom: 40px;
  line-height: 24px;
`;
const Title = styled.div`
  font-family: MontserratBold;
  font-size: 20px;
`;
const SubTitle = styled.div`
  font-size: 16px;
`;
const ProgressWrapper = styled.div`
  display: flex;
  align-items: center;
  flex: 2;
  font-size: 20px;
`;

interface IProps {
  reports: { [key: string]: (TAnyReport | IUnifiedHotspot)[] };
}

const BulletinDashboard = ({ reports }: IProps) => {
  const { t } = useTranslation();

  const reportsProgress = useMemo(() => {
    const types = Object.keys(reports);

    return types.reduce((acc, type) => {
      const reportData = reports[type];
      const resolvedCount = reportData.filter((item) => item.status === EMissionStatus.Done).length;

      const progress = reportData.length ? numberUtils.normalizeDecimal((resolvedCount / reportData.length) * 100, 0) : 100;

      return {
        ...acc,
        [type]: {
          count: reportData.length,
          resolvedCount,
          progress
        }
      };
    }, {} as { [key: string]: { count: number; resolvedCount: number; progress: number } });
  }, [reports]);

  return (
    <Wrapper>
      <Title>{t('main.bulletin.bulletins_dashboard')}</Title>
      <SubTitle>{t('main.bulletin.bulletins_dashboard_subtitle')}</SubTitle>
      <div style={{ marginTop: 18 }}>
        {THRESHOLD_TYPES.map((type) => (
          <TypeItem key={type}>
            <div style={{ display: 'flex', flex: 2 }}>
              <TypeIcon>{TYPE_ICON.get(type)}</TypeIcon>
              <TypeTitle>{t(`${TYPE_TITLE.get(type)}`)}</TypeTitle>
            </div>
            <ProgressWrapper>
              <div style={{ minWidth: 100, textAlign: 'right', marginRight: 18, fontWeight: 600 }}>
                {reportsProgress[type]?.resolvedCount || 0}
                <span style={{ margin: '0 8px' }}>{'/'}</span>
                {reportsProgress[type]?.count || 0}
              </div>
              <div style={{ width: 200 }}>
                <Progress value={reportsProgress[type]?.progress} />
              </div>
            </ProgressWrapper>
          </TypeItem>
        ))}
      </div>
    </Wrapper>
  );
};

export default BulletinDashboard;
