import { createSlice } from '@reduxjs/toolkit';
import { ERichItemType } from 'models/richItem';
import { ICompareParams } from 'models/compare';
import { RootState } from './store';

export interface IEvidenceParams {
  farmID: string;
  zoneID: string;
  groveID: string;
  treeID: string;
  richItemType: ERichItemType;
}

interface IAppState {
  isShowNotifications: boolean;
  isSideBarExpanded: boolean;
  isUserMenuShown: boolean;
  isShowBIDashboard: boolean;
  evidenceParams: IEvidenceParams | null;
  isCapacityShown: boolean;
  compareParams: ICompareParams | null;
  isFarmLayersFiltered: boolean;
  isShowReplantInventory: boolean;
}
const slice = createSlice({
  name: 'mode',
  initialState: {
    isShowNotifications: false,
    isSideBarExpanded: true,
    isUserMenuShown: false,
    isShowBIDashboard: false,
    evidenceParams: null,
    isCapacityShown: false,
    compareParams: null,
    isFarmLayersFiltered: false,
    isShowReplantInventory: false
  } as IAppState,
  reducers: {
    setIsShowNotifications: (state, action) => ({ ...state, isShowNotifications: action.payload }),
    setIsSideBarExpanded: (state, action) => ({ ...state, isSideBarExpanded: action.payload }),
    setIsUserMenuShown: (state, action) => ({ ...state, isUserMenuShown: action.payload }),
    setIsShowBIDashboard: (state, action) => ({ ...state, isShowBIDashboard: action.payload }),
    setEvidenceParams: (state, action) => ({ ...state, evidenceParams: action.payload }),
    setIsCapacityShown: (state, action) => ({ ...state, isCapacityShown: action.payload }),
    setCompareIsOpened: (state, action) => ({ ...state, compareParams: { ...state.compareParams, isOpened: action.payload } }),
    setIsFarmLayersFiltered: (state, action) => ({ ...state, isFarmLayersFiltered: action.payload }),
    setIsShowReplantInventory: (state, action) => ({ ...state, isShowReplantInventory: action.payload })
  }
});

export const {
  setIsShowNotifications,
  setIsSideBarExpanded,
  setIsUserMenuShown,
  setIsShowBIDashboard,
  setEvidenceParams,
  setCompareIsOpened,
  setIsCapacityShown,
  setIsFarmLayersFiltered,
  setIsShowReplantInventory
} = slice.actions;

export const isShowNotificationsSelector = (state: RootState) => state.appState.isShowNotifications;
export const isSideBarExpandedSelector = (state: RootState) => state.appState.isSideBarExpanded;
export const isUserMenuShownSelector = (state: RootState) => state.appState.isUserMenuShown;
export const isShowBIDashboardSelector = (state: RootState) => state.appState.isShowBIDashboard;
export const isCapacityShownSelector = (state: RootState) => state.appState.isCapacityShown;
export const isFarmLayersFilteredSelector = (state: RootState) => state.appState.isFarmLayersFiltered;
export const evidenceParamsSelector = (state: RootState) => state.appState.evidenceParams;
export const getCompareParams = (state: RootState) => state.appState.compareParams;
export const getIsCompareOpened = (state: RootState) => state.appState.compareParams?.isOpened;
export const isShowReplantInventorySelector = (state: RootState) => state.appState.isShowReplantInventory;
export const appStateReducer = slice.reducer;
