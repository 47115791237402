import React, { useMemo, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { convertArea } from '@turf/helpers';
import { IGrove } from 'models/grove';
import { ISurvey } from 'models/survey';
import { EMeasurementSystem } from 'models/region';
import { IGroveSurveyStats } from 'models/stats';
import { ERichItemType, NDVI_TRESHOLD_LOWEST, NDVI_TRESHOLD_LOW, NDVI_TRESHOLD_LOWER, NDVI_TRESHOLD_MEDIUM, NDVI_TRESHOLD_HIGH } from 'models/richItem';
import { TMappedStatistic, IParsedFruitYieldStatistic, TCardStatistic, IWeedsStatistic, IHealthStatistic } from 'models/statistic';
import { IZone } from 'models/zone';
import numberUtils from 'utils/numbers';
import { CIPO_SCORE_COLORS, WEEDS_SCORE_COLORS, NDVI_SCORE_COLORS } from 'models/colors';
import TreeStats from 'components/main/TreeStats/TreeStats';
import LocalLoader from 'atomicComponents/LocalLoader';
import FarmViewStatisticDataCard from 'components/main/DataCards/FarmViewStatisticDataCard';
import { mfColors } from 'vars';
import { ICommodityCombination } from 'models/commodity';
import FruitYieldDataCard, { getFruitYieldCardStatistic } from 'components/main/DataCards/FruitYieldDataCard';
import { TPaintValue } from 'services/util/mapHelpers';
import { IRichItemRange } from 'services/data/appConfig';
import replantUtils from 'utils/replant';

const Wrapper = styled.div`
  margin: 12px 0;
`;

const LoadingWrapper = styled.div`
  padding: 10px;
  border-radius: 8px;
  margin: 12px 0;
  background: ${mfColors.white};
`;

const getWeedsStatistic = (item: IWeedsStatistic): { statistic: number } => {
  const { categories, values } = item.statistic;

  if (!values || !categories) {
    return { statistic: 0 };
  }

  const max = Math.max(...values);
  const lastIndexOfMax = values.lastIndexOf(max);
  const statistic = categories[lastIndexOfMax];

  return { statistic };
};

interface IProps {
  selectedSurvey: ISurvey;
  selectedGrove: IGrove | null;
  onHighlightedScores: (scores: number[]) => void;
  colorBlind: boolean;
  dataCardOpened: boolean;
  scoresLoading: boolean;
  selectedSurveyStats: IGroveSurveyStats[];
  onCardOpened: (dataCardOpened: boolean) => void;
  highlightedScores: number[];
  measurementSystem?: EMeasurementSystem;
  activeRichItem: ERichItemType;
  groves: IGrove[];
  zone?: IZone | null;
  selectedCommodity?: ICommodityCombination;
  setDynamicPaintValue: (paintValue: TPaintValue | undefined) => void;
  statistic: TMappedStatistic | null;
  fruitStatistic: TMappedStatistic | null;
  richItemsRanges?: IRichItemRange[];
  capacityStatistic?: TMappedStatistic | null;
}

const FarmViewDataCard = ({
  selectedSurvey,
  selectedGrove,
  onHighlightedScores,
  colorBlind,
  dataCardOpened,
  onCardOpened,
  scoresLoading,
  highlightedScores,
  selectedSurveyStats,
  measurementSystem = EMeasurementSystem.Metric,
  activeRichItem,
  groves,
  zone,
  selectedCommodity,
  setDynamicPaintValue,
  statistic,
  fruitStatistic,
  richItemsRanges,
  capacityStatistic
}: IProps): JSX.Element => {
  const { t } = useTranslation();

  const getAreaLabel = (measurement, start, end) => {
    const unit = measurement === EMeasurementSystem.Metric ? 'meters' : 'feet';

    if (!end) {
      return `>${numberUtils.normalizeDecimal(convertArea(start, 'meters', unit), 1)}`;
    }

    return `${numberUtils.normalizeDecimal(convertArea(start, 'meters', unit), 1)}-${numberUtils.normalizeDecimal(convertArea(end, 'meters', unit), 1)}`;
  };

  const commodityGroves = useMemo(() => {
    if (!selectedCommodity) return groves;

    return groves.filter((item) => item?.attributes?.commodityCombinations?.includes(selectedCommodity?.id));
  }, [groves, selectedCommodity]);

  const totalFruits = useMemo(() => {
    if (activeRichItem !== ERichItemType.FruitYield || !fruitStatistic) return 0;

    return Object.keys(fruitStatistic).reduce((total, groveID) => {
      const count = (fruitStatistic[groveID] as IParsedFruitYieldStatistic)?.statistic?.count;

      return total + count;
    }, 0);
  }, [fruitStatistic, activeRichItem]);

  const averageSize = useMemo(() => {
    if (activeRichItem !== ERichItemType.FruitYield) return 0;

    const groveIDs = commodityGroves.map((item) => item.id);

    if (!groveIDs[0] || !fruitStatistic) {
      return 0;
    }

    const { sizes } = (fruitStatistic[groveIDs[0]] as IParsedFruitYieldStatistic)?.statistic || {};

    if (!sizes || !sizes.categories || !sizes.values) {
      return 0;
    }

    const { categories, values } = sizes;
    const middleOfRanges = categories
      .map((value, index, array) => {
        if (!array[index + 1]) {
          return 0;
        }

        return (value + array[index + 1]) / 2;
      })
      .filter((e) => !!e);

    const weightAvg = middleOfRanges.reduce((acc, middle, index) => {
      const value = values[index];

      return acc + middle * value;
    }, 0);

    return Math.round(weightAvg * 100) / 100;
  }, [activeRichItem, commodityGroves, fruitStatistic]);

  const fruitsPerArea = useMemo(() => {
    if (activeRichItem !== ERichItemType.FruitYield || !fruitStatistic) return 0;

    const fruitGroves = commodityGroves.filter((item) => fruitStatistic[item.id]);
    const area = numberUtils.getGrovesArea(fruitGroves, measurementSystem);

    return Math.round(totalFruits / area) || 0;
  }, [activeRichItem, totalFruits, commodityGroves, measurementSystem, fruitStatistic]);

  const averageFruits = useMemo(() => {
    if (activeRichItem !== ERichItemType.FruitYield || !fruitStatistic) return 0;

    const groveIDs = Object.keys(fruitStatistic);
    const grovesCountPerTree = groveIDs.reduce((total, groveID) => {
      const countPerTree = (fruitStatistic[groveID] as IParsedFruitYieldStatistic)?.statistic?.countPerTree;

      return total + countPerTree;
    }, 0);

    return Math.round(grovesCountPerTree / groveIDs.length);
  }, [activeRichItem, fruitStatistic]);

  const fruitYieldStatistic = useMemo((): TCardStatistic[] => {
    if (!fruitStatistic) return [] as TCardStatistic[];

    const groveIDs = commodityGroves.map((item) => item.id);

    const values = Object.keys(fruitStatistic)
      .filter((key) => groveIDs.includes(key))
      .map((groveID) => (fruitStatistic?.[groveID] as IParsedFruitYieldStatistic)?.statistic?.countPerTree);

    return getFruitYieldCardStatistic(values, 'countPerTree');
  }, [commodityGroves, fruitStatistic]);

  const capacity = useMemo(() => {
    if (!capacityStatistic) {
      return null;
    }

    const selectedStats = zone ? selectedSurveyStats.filter((item) => item.zoneID === zone.id) : selectedSurveyStats;
    const selectedCapacityStatistic = selectedStats.reduce((acc, next) => {
      const { groveID } = next;

      if (capacityStatistic[groveID]) {
        return { ...acc, [groveID]: capacityStatistic[groveID] };
      }

      return acc;
    }, {} as TMappedStatistic);

    return replantUtils.getFarmCapacity(selectedCapacityStatistic);
  }, [capacityStatistic, selectedSurveyStats, zone]);

  useEffect(() => {
    if (activeRichItem === ERichItemType.FruitYield && fruitYieldStatistic.length) {
      const colorPairs = (fruitYieldStatistic as any).reduce((acc, next) => [...acc, next.type, next.color], []);
      const paintValue = ['step', ['get', activeRichItem], '#E11282', ...colorPairs] as TPaintValue;

      // @TODO remove this condition
      // eslint-disable-next-line no-constant-condition
      if (false) {
        setDynamicPaintValue(paintValue);
      }
    }
  }, [activeRichItem, setDynamicPaintValue, fruitYieldStatistic]);

  const getRichItemMetric = useCallback(
    (richItemStats) => {
      if (!richItemsRanges) return [];
      const lastIdx = richItemsRanges.length;

      return richItemsRanges.map((item, idx, arr) => {
        let stats = [];
        const nextIdx = idx + 1;

        if (nextIdx === lastIdx) {
          stats = richItemStats.filter(({ statistic }) => statistic >= item.value);
        } else {
          stats = richItemStats.filter(({ statistic }) => statistic > item.value && statistic <= arr[nextIdx].value);
        }

        if (idx === 0) {
          stats = richItemStats.filter(({ statistic }) => statistic <= arr[nextIdx].value);
        }

        return {
          type: item.value,
          color: item.color,
          label: item.label,
          value: stats.length
        };
      });
    },
    [richItemsRanges]
  );

  const cardStatistic = useMemo(() => {
    if (!statistic) return [];

    const filteredGroves = zone ? groves.filter((grove) => grove.zoneID === zone.id) : groves;

    const richItemStats = filteredGroves
      .map((item) => statistic[item.id])
      .filter((item) => item && item.statistic)
      .map((item) => {
        if (activeRichItem === ERichItemType.Weeds) {
          return getWeedsStatistic(item as IWeedsStatistic);
        }

        return item;
      });

    switch (activeRichItem) {
      case ERichItemType.CanopyArea:
        return getRichItemMetric(richItemStats);
      case ERichItemType.Height:
        return getRichItemMetric(richItemStats);
      case ERichItemType.Volume:
        return getRichItemMetric(richItemStats);
      case ERichItemType.FreeArea:
        return getRichItemMetric(richItemStats);
      case ERichItemType.Weeds:
        return [
          {
            type: 0,
            label: `${getAreaLabel(measurementSystem, 0, 30)}`,
            color: WEEDS_SCORE_COLORS[0],
            value: richItemStats.filter((item) => item.statistic < 30).length
          },
          {
            type: 30,
            label: `${getAreaLabel(measurementSystem, 30, 60)}`,
            color: WEEDS_SCORE_COLORS[30],
            value: richItemStats.filter((item) => item.statistic >= 30 && item.statistic < 60).length
          },
          {
            type: 60,
            label: `${getAreaLabel(measurementSystem, 60, 90)}`,
            color: WEEDS_SCORE_COLORS[60],
            value: richItemStats.filter((item) => item.statistic >= 60 && item.statistic < 90).length
          },
          {
            type: 90,
            label: `${getAreaLabel(measurementSystem, 90, null)}`,
            color: WEEDS_SCORE_COLORS[90],
            value: richItemStats.filter((item) => item.statistic >= 90).length
          }
        ];
      case ERichItemType.Cipo:
        return [
          {
            type: 0,
            label: '0-3%',
            color: CIPO_SCORE_COLORS[0],
            value: richItemStats.filter((item) => item.statistic <= 0.03).length
          },
          {
            type: 3,
            label: '3-6%',
            color: CIPO_SCORE_COLORS[3],
            value: richItemStats.filter((item) => item.statistic > 0.03 && item.statistic <= 0.06).length
          },
          {
            type: 6,
            label: '6-10%',
            color: CIPO_SCORE_COLORS[6],
            value: richItemStats.filter((item) => item.statistic > 0.06 && item.statistic <= 0.1).length
          },
          {
            type: 10,
            label: '>10%',
            color: CIPO_SCORE_COLORS[10],
            value: richItemStats.filter((item) => item.statistic > 0.1).length
          }
        ];
      case ERichItemType.Ndvi:
        return [
          {
            type: 0,
            label: '0-0.2',
            color: NDVI_SCORE_COLORS[0],
            value: richItemStats.filter((item) => item.statistic <= NDVI_TRESHOLD_LOWEST).length
          },
          {
            type: 0.2,
            label: '0.2-0.25',
            color: NDVI_SCORE_COLORS[NDVI_TRESHOLD_LOWEST],
            value: richItemStats.filter((item) => item.statistic > NDVI_TRESHOLD_LOWEST && item.statistic <= NDVI_TRESHOLD_LOW).length
          },
          {
            type: 0.25,
            label: '0.25-0.3',
            color: NDVI_SCORE_COLORS[NDVI_TRESHOLD_LOW],
            value: richItemStats.filter((item) => item.statistic > NDVI_TRESHOLD_LOW && item.statistic <= NDVI_TRESHOLD_LOWER).length
          },
          {
            type: 0.3,
            label: '0.3-0.35',
            color: NDVI_SCORE_COLORS[NDVI_TRESHOLD_LOWER],
            value: richItemStats.filter((item) => item.statistic > NDVI_TRESHOLD_LOWER && item.statistic <= NDVI_TRESHOLD_MEDIUM).length
          },
          {
            type: 0.35,
            label: '0.35-0.4',
            color: NDVI_SCORE_COLORS[NDVI_TRESHOLD_MEDIUM],
            value: richItemStats.filter((item) => item.statistic > NDVI_TRESHOLD_MEDIUM && item.statistic <= NDVI_TRESHOLD_HIGH).length
          },
          {
            type: 0.4,
            label: '>0.4',
            color: NDVI_SCORE_COLORS[NDVI_TRESHOLD_HIGH],
            value: richItemStats.filter((item) => item.statistic > NDVI_TRESHOLD_HIGH).length
          }
        ];
      default:
        return [];
    }
  }, [statistic, groves, measurementSystem, activeRichItem, zone, getRichItemMetric]);

  const dataCardTitle = useMemo(() => {
    switch (activeRichItem) {
      case ERichItemType.CanopyArea:
        return `${t('main.heatmaps.canopy_area')}`;
      case ERichItemType.Height:
        return `${t('main.heatmaps.height')}`;
      case ERichItemType.Volume:
        return `${t('main.heatmaps.volume')}`;
      case ERichItemType.FreeArea:
        return `${t('main.heatmaps.free_area')}`;
      case ERichItemType.Weeds:
        return `${t('main.heatmaps.weeds')}`;
      case ERichItemType.Cipo:
        return `${t('main.heatmaps.cipo')}`;
      default:
        return '';
    }
  }, [activeRichItem, t]);

  const dataCardLabel = useMemo(() => {
    const unit = measurementSystem === EMeasurementSystem.Metric ? 'meters' : 'feet';
    let label = '';

    switch (activeRichItem) {
      case ERichItemType.CanopyArea:
        label = t(`main.data_card.square_${unit}`);
        break;
      case ERichItemType.Height:
        label = t(`main.data_card.${unit}`);
        break;
      case ERichItemType.Volume:
        label = t(`main.data_card.cubic_${unit}`);
        break;
      case ERichItemType.FreeArea:
        label = t(`main.data_card.${unit}`);
        break;
      case ERichItemType.Weeds:
        label = t(`main.data_card.weeds_${unit}`);
        break;
      case ERichItemType.Cipo:
        label = t('main.data_card.percentage_infected');
        break;
      default:
        break;
    }

    return label ? `(${label})` : label;
  }, [activeRichItem, measurementSystem, t]);

  const showTreeStats = useMemo(() => activeRichItem === ERichItemType.Health, [activeRichItem]);

  const filteredHealthStatistic = useMemo(() => {
    if (statistic && showTreeStats) {
      const scoresSum = Object.keys(statistic).reduce((result, groveID) => {
        const accStats = Object.keys(statistic[groveID] || {}).reduce((acc, score) => {
          const statisticScore = statistic[groveID][score] as IHealthStatistic;

          if (statisticScore) {
            acc[score] = statisticScore.total;
          }

          return acc;
        }, {});
        return {
          ...result,
          [groveID]: {
            ...accStats
          }
        };
      }, {});

      return scoresSum;
    }

    return statistic;
  }, [statistic, showTreeStats]);

  if (scoresLoading) {
    return (
      <LoadingWrapper>
        <LocalLoader />
      </LoadingWrapper>
    );
  }

  return (
    <Wrapper>
      {activeRichItem === ERichItemType.FruitYield && statistic && !selectedGrove && (
        <>
          <FruitYieldDataCard
            totalFruits={totalFruits}
            averageSize={averageSize}
            fruitsPerArea={fruitsPerArea}
            averageFruits={averageFruits}
            measurementSystem={measurementSystem}
            statistic={filteredHealthStatistic}
            fruitStatistic={fruitStatistic}
            fruitYieldCardStatistic={fruitYieldStatistic}
            commodityGroves={commodityGroves}
          />
        </>
      )}
      {!showTreeStats && cardStatistic.length ? <FarmViewStatisticDataCard statistic={cardStatistic} title={dataCardTitle} label={dataCardLabel} /> : null}
      {showTreeStats && (
        <TreeStats
          selectedGrove={selectedGrove}
          selectedSurvey={selectedSurvey}
          statistic={filteredHealthStatistic}
          selectedSurveyStats={selectedSurveyStats}
          onHighlightedScores={onHighlightedScores}
          colorBlind={colorBlind}
          dataCardOpened={dataCardOpened}
          onCardOpened={onCardOpened}
          highlightedScores={highlightedScores}
          capacity={capacity}
        />
      )}
    </Wrapper>
  );
};

export default FarmViewDataCard;
