import React, { useMemo } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import DataTable from 'atomicComponents/DataTable';
import MissionIcon from 'components/missions/MissionIcon';

import farmsHooks from 'hooks/farms.hooks';

import { IMission } from 'models/mission';
import { IGrove } from 'models/grove';

import { mfColors } from 'vars';
import dateUtils from 'utils/date';
import { useSelector } from 'react-redux';
import { capacityStatisticByRulesSelector } from 'redux/replant/replantSlice';

const Wrapper = styled.div``;

const TableWrapper = styled.div`
  max-height: 320px;
  overflow: auto;
  width: 780px;
`;

const TableCell = styled.div`
  background: ${mfColors.white};
  padding: 4px;
  min-height: 32px;
  font-size: 12px;
  line-height: 24px;
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;

  &.left {
    text-align: left;
  }
`;

interface ITotalProps {
  width: string;
}

const Total = styled.div`
  font-family: MontserratBold;
  font-size: 14px;
  padding: 8px 0;
  margin: 8px 0 0 36px;
  width: ${({ width }: ITotalProps) => width};
  border-bottom: 2px solid ${mfColors.darkGrey};
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .column {
    width: 176px;
    margin: 0 4px 0 0;
    flex-shrink: 0;
    text-align: center;
  }

  .title {
    padding: 0 4px;
    text-align: left;
    text-transform: uppercase;
  }
`;

const ROW_STYLE = {
  border: 'none',
  background: 'none',
  margin: '0 0 4px'
};

const HEADER_STYLE = {
  border: 'none',
  background: 'none',
  fontSize: '12px',
  margin: '0 0 8px'
};

const HEADER_COLUMN_STYLE = {
  textTransform: 'none',
  minHeight: '0px',
  padding: '0 2px'
};

interface IGroveWithReplants extends IGrove {
  groveAge: number;
  rootstock: string;
  variety: string;
  total: number;
}

interface IProps {
  missions: IMission[];
}

const ReplantHeaderDetails = ({ missions }: IProps) => {
  const { t } = useTranslation();
  const { groves } = farmsHooks.useFarmEntities();
  const capacityStatisticByRules = useSelector(capacityStatisticByRulesSelector);

  const grovesToShow = useMemo(() => {
    const groveIDs = missions.reduce((acc: string[], mission) => {
      const missionGroveIDs = Object.keys(mission.groves);
      return [...acc, ...missionGroveIDs.filter((groveID) => !acc.includes(groveID))];
    }, []);
    return groveIDs
      .map((groveID) => {
        const grove = groves.find((grove) => grove.id === groveID);
        if (!grove) return null;

        return {
          ...grove,
          groveAge: dateUtils.getYearDifference(grove?.attributes?.originalPlantYear || ''),
          rootstock: grove?.attributes.rootStock,
          variety: grove?.attributes.variety,
          total: capacityStatisticByRules?.[groveID]?.statistic
        };
      })
      .filter((grove) => !!grove) as IGroveWithReplants[];
  }, [groves, missions, capacityStatisticByRules]);

  const totalReplants = useMemo(() => grovesToShow.reduce((acc, next) => acc + (next.total || 0), 0), [grovesToShow]);

  const tableConfig = useMemo(() => {
    const iconColumn = {
      title: '',
      render: () => (
        <TableCell>
          <MissionIcon type={missions[0]?.type} size="24px" showBackground={false} />
        </TableCell>
      ),
      key: 'icon',
      sortable: false,
      width: '32px',
      style: {
        ...HEADER_COLUMN_STYLE,
        padding: '0px',
        margin: '0 2px 0 0'
      }
    };

    const titleColumn = {
      title: t('missions.block'),
      render: (grove: IGrove) => <TableCell className="left">{grove.name}</TableCell>,
      key: 'name',
      sortable: false,
      width: '180px',
      style: HEADER_COLUMN_STYLE
    };

    const groveAgeColumn = {
      title: t('main.info.grove_age'),
      render: (grove: IGroveWithReplants) => <TableCell>{grove.groveAge}</TableCell>,
      key: 'age',
      sortable: false,
      width: '180px',
      style: {
        ...HEADER_COLUMN_STYLE,
        textAlign: 'center'
      }
    };

    const rootstockColumn = {
      title: t('main.info.rootstock'),
      render: (grove: IGroveWithReplants) => <TableCell>{grove.rootstock}</TableCell>,
      key: 'rootstock',
      sortable: false,
      width: '180px',
      style: HEADER_COLUMN_STYLE
    };

    const varietyColumn = {
      title: t('missions.variety'),
      render: (grove: IGroveWithReplants) => <TableCell>{grove.variety}</TableCell>,
      key: 'variety',
      sortable: false,
      width: '180px',
      style: HEADER_COLUMN_STYLE
    };

    const totalReplantsColumn = {
      title: t('main.info.total_replants'),
      render: (grove: IGroveWithReplants) => <TableCell>{grove.total}</TableCell>,
      key: 'replants',
      sortable: false,
      width: '180px',
      style: HEADER_COLUMN_STYLE
    };

    return [iconColumn, titleColumn, groveAgeColumn, rootstockColumn, varietyColumn, totalReplantsColumn];
  }, [t, missions]);

  const tableProps = useMemo(() => {
    const props = {
      config: tableConfig,
      data: grovesToShow,
      showHeader: true,
      showFooter: false,
      rowsPerPage: 0,
      headerStyle: HEADER_STYLE,
      rowStyle: ROW_STYLE
    };
    return props;
  }, [tableConfig, grovesToShow]);

  return (
    <Wrapper>
      <TableWrapper>
        <DataTable<IGroveWithReplants> {...tableProps} />
      </TableWrapper>
      <Total width="712px">
        <div className="column title">{t('shared.total')}</div>
        <div className="column">{totalReplants}</div>
      </Total>
    </Wrapper>
  );
};

export default ReplantHeaderDetails;
