import React, { useMemo } from 'react';
import styled from 'styled-components';
import DataTable from 'atomicComponents/DataTable';
import { useTranslation } from 'react-i18next';
import { IPestsP3Config } from 'models/missions-config';
import { IFeed, IPestsP3FeedData } from 'models/feed';
import { mfColors } from 'vars';
import { IGrove } from 'models/grove';
import feedUtils from 'utils/feed';

const Wrapper = styled.div``;

const Title = styled.h1`
  font-size: 20px;
  margin-bottom: 40px;
  margin-top: 0;
  font-family: 'Montserrat';
`;

const StatsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 20px 0;
`;

const Stats = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  border: 1px solid ${mfColors.grey};
  padding: 4px 8px;
  border-radius: 4px;
  margin-right: 8px;

  &:last-child {
    margin-right: 0;
  }
`;

const StatsBold = styled.div`
  font-family: 'MontserratBold';
`;

const Circle = styled.div<{ color: string }>`
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: ${({ color }) => color};
  margin-right: 8px;
`;

const HEADER_COLUMN_STYLE = {
  minHeight: '0px'
};
const HEADER_STYLE = {
  borderTop: 'none',
  background: 'none',
  fontSize: '14px',
  paddingBottom: '16px'
};
const ROW_STYLE = {
  padding: '10px 0'
};

interface IProps {
  pestsP3Settings: IPestsP3Config[];
  feed: IFeed[];
  grove: IGrove | null;
}

const PestsReport = ({ pestsP3Settings, feed, grove }: IProps) => {
  const { t } = useTranslation();

  const aggregatedFeedData = useMemo(() => feedUtils.aggregateFeedData(feed), [feed]);
  const tableData = useMemo(() => feedUtils.getPestsReport(feed, pestsP3Settings), [feed, pestsP3Settings]);

  const tableConfig = useMemo(
    () => [
      {
        title: t('Pest'),
        render: (settings: IPestsP3Config) => (
          <div>
            <div>{t(`pests_p3.${settings.title}`)}</div>
            {settings.subtitle && <div>{t(`pests_p3.${settings.subtitle}`)}</div>}
          </div>
        ),
        sortable: false,
        width: '200px',
        style: {
          ...HEADER_COLUMN_STYLE,
          display: 'flex',
          justifyContent: 'flex-start'
        }
      },
      {
        title: `${t('missions.pests_p3.infected')} #`,
        render: (row) => <div>{`${row.infected}/${row.allTrees}`}</div>,
        sortable: false,
        width: '300px',
        style: {
          ...HEADER_COLUMN_STYLE
        }
      },
      {
        title: `${t('missions.pests_p3.infected')} %`,
        render: (row) => <div>{`${row.infectedPercent}%`}</div>,
        sortable: false,
        width: '200px',
        style: {
          ...HEADER_COLUMN_STYLE
        }
      },
      {
        title: t('threshold'),
        render: (row) => <Circle color={row.color} />,
        sortable: false,
        width: '200px',
        style: {
          ...HEADER_COLUMN_STYLE
        }
      }
    ],
    [t]
  );

  const tableProps = useMemo(() => {
    const props = {
      config: tableConfig,
      data: tableData,
      showHeader: true,
      showFooter: false,
      rowsPerPage: 0,
      headerStyle: HEADER_STYLE,
      rowStyle: ROW_STYLE,
      tableStyle: { height: '500px' }
    };
    return props;
  }, [tableConfig, tableData]);

  return (
    <Wrapper>
      <Title>
        {t('missions.pest_report')}
        {grove && (
          <>
            <span style={{ margin: '0 8px' }}>|</span>
            <span>{`${t('missions.block')} ${grove.name}`}</span>
          </>
        )}
      </Title>
      <StatsWrapper>
        <Stats>
          <div>{t('missions.pests_p3.scouted_trees')}</div>
          <StatsBold>{feed.length}</StatsBold>
        </Stats>
        <Stats>
          <div>{t('missions.pests_p3.avg_buds_trees')}</div>
          <StatsBold>{Math.round(Number((aggregatedFeedData as any).buds_count) / feed.length)}</StatsBold>
        </Stats>
        <Stats>
          <div>{t('missions.pests_p3.trees_with_buds')}</div>
          <StatsBold>{`${Math.round((feed.filter((item) => Number((item.data as IPestsP3FeedData).buds_count || 0) > 0).length / feed.length) * 100)} %`}</StatsBold>
        </Stats>
      </StatsWrapper>
      <DataTable<IPestsP3Config> {...tableProps} />
    </Wrapper>
  );
};

export default PestsReport;
