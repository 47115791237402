import { useState, useEffect } from 'react';
import treesService from 'services/data/trees';
import vectilesService from 'services/data/vectiles';

import { GeoindexScores } from 'models/tree';
import { TREE_AREA, TREE_LOW_ZOOM } from 'services/util/mapHelpers';
import { Map } from 'mapbox-gl';
import { ICommodityCombination } from 'models/commodity';
import Image from 'models/image';
import { IGrove } from 'models/grove';
import { centerOfMass } from '@turf/turf';

const useGetGeoindexScores = (farmID, surveys, treeID): { geoindexScores: GeoindexScores } => {
  const [geoindexScores, setGeoindexScores] = useState<GeoindexScores>({});
  const fetchScores = treesService.useFetchScores();

  useEffect(() => {
    async function getScores() {
      const scores = await fetchScores(farmID, treeID);
      setGeoindexScores(scores);
    }
    if (farmID && treeID && surveys.length) {
      getScores();
    }
  }, [farmID, surveys, treeID, fetchScores]);

  return {
    geoindexScores
  };
};

const useGetSourceTrees = (selectedImage: Image | null, groveTilesPath: string | null) => {
  const [sourceTrees, setSourceTrees] = useState<any>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    const func = async () => {
      if (!selectedImage) return;
      setIsLoading(true);

      const zoom = 19;
      const tile = vectilesService.convertCoordsToTilePos(selectedImage.center.coordinates, zoom);
      const tilesToLoad = [...vectilesService.getAdjacentTiles(tile), tile];
      let tilesData;
      if (groveTilesPath) {
        tilesData = await Promise.all(tilesToLoad.map((tile) => vectilesService.loadTileData(groveTilesPath, tile)));
        setSourceTrees(tilesData.reduce((res, coll) => res.concat(coll.features), [] as any));
        setIsLoading(false);
      }
    };

    func();
  }, [selectedImage, groveTilesPath]);

  return { sourceTrees, isLoading };
};

const useGetGroveSurveyTrees = (grove: IGrove | null, groveTilesPath: string | null) => {
  const [sourceTrees, setSourceTrees] = useState<any>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    const func = async () => {
      if (!grove) return;
      setIsLoading(true);
      const zoom = 16;
      const center = centerOfMass(grove.geometry).geometry.coordinates;
      const tile = vectilesService.convertCoordsToTilePos(center, zoom);
      const tilesToLoad = [...vectilesService.getAdjacentTiles(tile), tile];
      let tilesData;
      if (groveTilesPath) {
        tilesData = await Promise.all(tilesToLoad.map((tile) => vectilesService.loadTileData(groveTilesPath, tile)));
        setSourceTrees(tilesData.reduce((res, coll) => res.concat(coll.features), [] as any));
        setIsLoading(false);
      }
    };

    func();
  }, [grove, groveTilesPath]);

  return { sourceTrees, isLoading };
};

const useFilterTrees = (map: Map | null, selectedVarieties: ICommodityCombination[]) => {
  useEffect(() => {
    if (!map) {
      return;
    }

    const filterTilesetByCommodity = (filterValue: any) => {
      if (!Array.isArray(filterValue) || filterValue.length <= 0) {
        return null;
      }

      return ['in', 'commodityID', ...filterValue];
    };

    const getFilter = (name: string, value: string[]) => {
      switch (name) {
        case 'commodity':
          return filterTilesetByCommodity(value);
        default:
          return null;
      }
    };

    const varietyIDs = selectedVarieties.map((variety) => variety.id);
    const filter = getFilter('commodity', varietyIDs);

    [TREE_AREA, TREE_LOW_ZOOM].forEach((layer: string) => {
      if (map.getLayer(layer)) {
        map.setFilter(layer, filter ? ['all', filter] : filter);
      }
    });
  }, [map, selectedVarieties]);
};

const treeHooks = {
  useGetGeoindexScores,
  useGetSourceTrees,
  useFilterTrees,
  useGetGroveSurveyTrees
};

export default treeHooks;
