import React, { useMemo, useState, useEffect } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import MarkAsCountedFarmItem from 'containers/dashboard/MarkAsCountedFarmItem';

import { ICompany } from 'models/company';
import { IMission } from 'models/mission';
import { IFarmName } from 'models/farm';
import { IFeed } from 'models/feed';
import { IGrove } from 'models/grove';

import missionHooks from 'hooks/mission.hooks';
import { useFetchFarmGroves } from 'services/data/farms';
import dateUtils from 'utils/date';

import FormSelect from 'atomicComponents/FormSelect';
import LocalLoader from 'atomicComponents/LocalLoader';

import { mfColors } from 'vars';

const Wrapper = styled.div`
  width: 100%;
  padding: 16px 16px 16px 32px;
  height: 100%;
  background: #fbfafa;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 80px;
`;

const Title = styled.div`
  font-size: 28px;
  line-height: 34px;
`;

const Filter = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  span {
    margin: 0 8px 0 0;
  }
`;

const Content = styled.div`
  overflow: auto;
  height: calc(100% - 128px);
  min-width: 850px;
`;

const Placeholder = styled.div`
  height: 105px;
  line-height: 105px;
  font-size: 20px;
  text-align: center;
  color: ${mfColors.grey};
`;

enum ETimeframeOption {
  All = 'All',
  LastYear = 'LastYear',
  Last6Months = 'Last6Months',
  Last3Months = 'Last3Months'
}

interface IFarmsWithGroves extends IFarmName {
  groves: IGrove[];
}

interface IProps {
  company: ICompany;
  missions: IMission[];
  farms: IFarmName[];
  selectedFarm: string | null;
}

const MarkAsCounted = ({ missions, selectedFarm, company, farms }: IProps): JSX.Element => {
  const { t } = useTranslation();
  const [timeframe, setTimeframe] = useState(ETimeframeOption.All);
  const { feed, loading: feedLoading } = missionHooks.useMissionFeed(missions);
  const fetchFarmGroves = useFetchFarmGroves();
  const [farmsGroves, setFarmsGroves] = useState<IFarmsWithGroves[]>([]);

  const timeframeOptions = useMemo(() => {
    const res = [
      { value: ETimeframeOption.All, key: ETimeframeOption.All, text: t('shared.all') },
      { value: ETimeframeOption.LastYear, key: ETimeframeOption.LastYear, text: t('missions.last_year') },
      { value: ETimeframeOption.Last6Months, key: ETimeframeOption.Last6Months, text: t('missions.last_x_month', { x: 6 }) },
      { value: ETimeframeOption.Last3Months, key: ETimeframeOption.Last3Months, text: t('missions.last_x_month', { x: 3 }) }
    ];
    return res;
  }, [t]);

  useEffect(() => {
    setFarmsGroves([]);
    Promise.all(farms.map((farm) => fetchFarmGroves(farm.id))).then((groves) => {
      setFarmsGroves(
        farms.map((farm) => ({
          ...farm,
          groves: groves.find((entries) => entries[0].farmID === farm.id) || []
        }))
      );
    });
  }, [farms, fetchFarmGroves]);

  const feedToShow = useMemo(() => {
    switch (timeframe) {
      case ETimeframeOption.LastYear:
        return feed.filter((entry) => entry.createdAt > dateUtils.subtractYears(new Date(), 1));
      case ETimeframeOption.Last6Months:
        return feed.filter((entry) => entry.createdAt > dateUtils.subtractMonth(new Date(), 6));
      case ETimeframeOption.Last3Months:
        return feed.filter((entry) => entry.createdAt > dateUtils.subtractMonth(new Date(), 3));
      default:
        return feed;
    }
  }, [feed, timeframe]);

  const farmsFeedData = useMemo(() => {
    const farmsGrovesWithFeed = farmsGroves
      .map((farm) => {
        const grovesWithFeed = farm.groves
          .map((grove) => {
            const groveFeed = feedToShow.filter((entry) => entry.groveID === grove.id);
            const groveCountedFeed = groveFeed.filter((entry) => entry.data?.fruitCountStatus === 'isCounted');

            return {
              ...grove,
              feed: groveFeed,
              preassigned: groveFeed.filter((entry) => entry.isPreassigned),
              counted: groveCountedFeed
            };
          })
          .filter((entry) => !!entry.feed.length);
        return {
          ...farm,
          feed: grovesWithFeed.reduce((acc: IFeed[], grove) => [...acc, ...grove.feed], []),
          preassigned: grovesWithFeed.reduce((acc: IFeed[], grove) => [...acc, ...grove.preassigned], []),
          counted: grovesWithFeed.reduce((acc: IFeed[], grove) => [...acc, ...grove.counted], []),
          subEntries: grovesWithFeed.sort((a, b) => {
            const decimalAName = +a.name;
            const decimalBName = +b.name;
            if (!Number.isNaN(decimalAName) && !Number.isNaN(decimalBName)) {
              return decimalAName < decimalBName ? -1 : 1;
            }
            return a.name < b.name ? -1 : 1;
          })
        };
      })
      .filter((farm) => !!farm.feed.length);
    return farmsGrovesWithFeed;
  }, [farmsGroves, feedToShow]);

  const farmsFeedDataToShow = useMemo(() => {
    if (!selectedFarm) return farmsFeedData;
    return farmsFeedData.filter((entry) => entry.id === selectedFarm);
  }, [farmsFeedData, selectedFarm]);

  const overallFeedItem = useMemo(() => {
    const feed = farmsFeedData.reduce((acc: IFeed[], entry) => [...acc, ...entry.feed], []);
    const preassigned = farmsFeedData.reduce((acc: IFeed[], entry) => [...acc, ...entry.preassigned], []);
    const counted = farmsFeedData.reduce((acc: IFeed[], entry) => [...acc, ...entry.counted], []);

    return {
      id: 'overall',
      name: t('dashboard.overall'),
      feed,
      preassigned,
      counted
    };
  }, [farmsFeedData, t]);

  const loading = feedLoading || !farmsGroves.length;

  return (
    <Wrapper>
      <Header>
        <Title>{t('dashboard.company_fruit_count', { customer: company.name })}</Title>
        <Filter>
          <span>{t('dashboard.timeframe')}</span>
          <FormSelect<ETimeframeOption> isBordered value={timeframe} onChange={setTimeframe} options={timeframeOptions} />
        </Filter>
      </Header>
      {loading && <LocalLoader />}
      {!loading && (
        <Content>
          <MarkAsCountedFarmItem isExpandable={false} entry={overallFeedItem} />
          {!farmsFeedDataToShow.length && <Placeholder>{t('dashboard.no_reports_found_for_selected_filters')}</Placeholder>}
          {farmsFeedDataToShow.map((farm) => (
            <MarkAsCountedFarmItem key={farm.id} entry={farm} />
          ))}
        </Content>
      )}
    </Wrapper>
  );
};

export default MarkAsCounted;
