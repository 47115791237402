import React, { useMemo, useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/pro-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import { useModal } from 'react-modal-hook';

import farmsHooks from 'hooks/farms.hooks';
import { BulletinContext } from 'screens/Map';
import { IFarm } from 'models/farm';
import { IZone } from 'models/zone';
import { IGrove } from 'models/grove';
import { ISurveyWithTimeRange } from 'models/survey';
import { EReportType, REPORT_PRIORITY, REPORT_IMPORTANCE_TRESHOLDS, TAnyReport } from 'models/report';
import Bulletin from 'components/main/bulletin/Bulletin';
import SidebarBlockShowMore from 'components/main/SidebarBlockShowMore/SidebarBlockShowMore';
import FarmBulletinModal from 'containers/FarmBulletinModal';
import Modal from 'atomicComponents/Modal';
import LocalLoader from 'atomicComponents/LocalLoader';
import { EMeasurementSystem } from 'models/region';
import { mfColors } from 'vars';
import { IGroveSurveyStats } from 'models/stats';
import BulletinModal from 'components/main/bulletin/BulletinModal/BulletinModal';
import { EMissionStatus } from 'models/mission';

const Wrapper = styled.div`
  margin: 16px 0 0;
`;

const FarmStatsControl = styled.div`
  margin: 8px 0;
  color: ${mfColors.grey};
  text-align: center;

  > span {
    cursor: pointer;
  }
`;

const FarmStatsControlIcon = styled(FontAwesomeIcon)`
  margin: 0 0 0 8px;
  font-size: 14px;
`;

interface IProps {
  farm: IFarm;
  zone: IZone | null;
  groves: IGrove[];
  survey: ISurveyWithTimeRange;
  isOldSurvey: boolean;
  selectedGrove: IGrove | null;
  onGroveClicked: (grove: IGrove, reportType: EReportType) => void;
  metricType?: EMeasurementSystem;
  publishedGrovesInLastSurveys: IGroveSurveyStats[];
  replantReports: TAnyReport[];
}

const FarmBulletin = ({
  farm,
  zone,
  survey,
  isOldSurvey,
  groves,
  onGroveClicked,
  selectedGrove,
  metricType,
  publishedGrovesInLastSurveys,
  replantReports
}: IProps): JSX.Element => {
  const { t } = useTranslation();
  const [isShowMore, setIsShowMore] = useState(false);
  const { reports, isPending } = farmsHooks.useBulletinsEntity(farm.id, survey.id, groves, zone?.id, replantReports);
  const context = useContext(BulletinContext);

  const reportType = useMemo(() => EReportType[Object.keys(reports)[0]], [reports]);

  const toggleOpenedModal = () => {
    hideSettingsModal();
  };

  const onBack = () => {
    hideSettingsModal();
    showViewAllModal();
  };

  const [showSettingsModal, hideSettingsModal] = useModal(
    () => (
      <Modal closeOnBackdrop width="1080px" onClose={hideSettingsModal}>
        <BulletinModal reportType={reportType} onClose={toggleOpenedModal} onBack={onBack} isTypeSelectorActive />
      </Modal>
    ),
    [reportType, toggleOpenedModal]
  );

  const [showViewAllModal, hideViewAllModal] = useModal(
    () => (
      <Modal closeOnBackdrop width="1080px" onClose={hideViewAllModal}>
        <FarmBulletinModal
          onGroveClicked={(grove: IGrove, reportType: EReportType) => {
            hideViewAllModal();
            onGroveClicked(grove, reportType);
          }}
          zone={zone}
          farm={farm}
          survey={survey}
          reports={reports}
          groves={groves}
          selectedGrove={selectedGrove}
          metricType={metricType}
          showSettingsModal={() => {
            hideViewAllModal();
            showSettingsModal();
          }}
        />
      </Modal>
    ),
    [farm, reports, groves, selectedGrove, onGroveClicked, metricType]
  );

  useEffect(() => {
    setIsShowMore(false);
  }, [reports]);

  const filteredReports = useMemo(() => {
    const reportTypes = Object.keys(reports);
    const groveIDs = publishedGrovesInLastSurveys.map((item) => item.groveID);

    return reportTypes.reduce((acc, reportType) => {
      const reportsByType = reports[reportType].filter((item) => item.status !== EMissionStatus.Done);
      const groveHasReport = !selectedGrove || !!reportsByType.find((report) => report.groveID === selectedGrove.id);
      const filteredGroves = groveHasReport ? reportsByType.filter((report) => !groveIDs.includes(report.groveID)) : [];

      return { ...acc, [reportType]: filteredGroves };
    }, {});
  }, [reports, selectedGrove, publishedGrovesInLastSurveys]);

  const importantReports = useMemo(() => {
    const allReportTypes = Object.keys(filteredReports);

    if (selectedGrove) return allReportTypes;

    return allReportTypes.filter((reportType) => {
      if (!filteredReports[reportType].length) return false;
      const affectedGroves = zone?.id ? groves.filter((grove) => grove.zoneID === zone.id) : groves;
      const treshold = REPORT_IMPORTANCE_TRESHOLDS[reportType];
      return !treshold || filteredReports[reportType].length / affectedGroves.length > treshold;
    });
  }, [filteredReports, selectedGrove, groves, zone?.id]);

  const filteredReportBlocks = useMemo(() => {
    const reportTypes = Object.keys(filteredReports);
    return reportTypes
      .filter((reportType) => !!filteredReports[reportType]?.length)
      .sort((a, b) => REPORT_PRIORITY.indexOf(a as EReportType) - REPORT_PRIORITY.indexOf(b as EReportType))
      .sort((a, b) => (importantReports.includes(a) && importantReports.includes(b) ? 0 : importantReports.indexOf(b) - importantReports.indexOf(a)));
  }, [filteredReports, importantReports]);

  useEffect(() => {
    const bulletinExists = !!context?.preselectedBulletin && filteredReportBlocks.includes(context.preselectedBulletin);
    const preselectedReport = filteredReportBlocks[0];
    const preselectedBulletinChanges = preselectedReport !== context?.preselectedBulletin;

    if (context?.expandedBulletin) return;

    if (!bulletinExists || preselectedBulletinChanges) {
      context?.onChangePreselectedBulletin(!isOldSurvey ? (filteredReportBlocks[0] as EReportType) : null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredReportBlocks, isOldSurvey]);

  useEffect(() => {
    const preselectedBulletin = context?.preselectedBulletin;

    if (!preselectedBulletin) return;

    const bulletinReports = reports[preselectedBulletin] || [];
    context?.onPreselectedBulletinReports(!isOldSurvey ? bulletinReports : []);
  }, [context, reports, isOldSurvey]);

  if (isPending) return <LocalLoader />;

  return (
    <Wrapper>
      {filteredReportBlocks.map((reportType) => {
        const showAllReports = isShowMore || !importantReports.length || importantReports.includes(reportType);
        return showAllReports && !isOldSurvey ? (
          <Bulletin
            key={`${farm.id}${survey.id}${reportType}`}
            selectedFarm={farm}
            selectedZone={zone}
            onGroveClicked={onGroveClicked}
            selectedGrove={selectedGrove}
            groves={groves}
            reportsCount={(reports[reportType] || []).length}
            reports={filteredReports[reportType]}
            reportType={reportType as EReportType}
            metricType={metricType}
          />
        ) : null;
      })}
      {importantReports.length && importantReports.length < filteredReportBlocks.length ? (
        <>
          {isShowMore && (
            <FarmStatsControl>
              <span onClick={showViewAllModal}>
                {t('main.bulletin.expand_all_farm_stats')}
                <FarmStatsControlIcon icon={faArrowRight} />
              </span>
            </FarmStatsControl>
          )}
          <SidebarBlockShowMore
            showMoreKey="main.bulletin.show_all_bulletins"
            showLessKey="main.bulletin.show_less_bulletins"
            isShowMore={isShowMore}
            onToggle={() => setIsShowMore((prev) => !prev)}
          />
        </>
      ) : (
        <FarmStatsControl>
          <span onClick={showViewAllModal}>
            {t('main.bulletin.expand_all_farm_stats')}
            <FarmStatsControlIcon icon={faArrowRight} />
          </span>
        </FarmStatsControl>
      )}
    </Wrapper>
  );
};

export default FarmBulletin;
