import React, { useEffect, useRef, useMemo, useState } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faChevronLeft } from '@fortawesome/pro-solid-svg-icons';
import AliceCarousel from 'react-alice-carousel';

import { mfColors } from 'vars';

interface IWrapperProps {
  isScrollable: boolean;
  height: string;
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: ${mfColors.lightGrey};
  border-radius: 8px;
  width: 200px;
  opacity: 0.6;

  &:hover {
    opacity: 0.9;
  }

  .alice-carousel {
    position: relative;
    height: ${({ height }: IWrapperProps) => height};
    padding: ${({ isScrollable }: IWrapperProps) => (isScrollable ? '0px 32px' : '0px')};

    .alice-carousel__prev-btn,
    .alice-carousel__next-btn {
      display: ${({ isScrollable }: IWrapperProps) => (isScrollable ? 'block' : 'none')};
      width: auto;
      height: 24px;
      position: absolute;
      top: 50%;
      margin: -12px 0 0;
      font-weight: bold;
      font-size: 20px;
      text-align: center;
    }

    .alice-carousel__wrapper {
      position: relative;
      height: 100%;
    }

    .alice-carousel__stage-item {
      width: 100% !important;
      height: ${({ height }: IWrapperProps) => height};
      display: inline-flex;
      align-items: center;
      justify-content: center;
    }

    .alice-carousel__prev-btn {
      left: 0px;
      padding: 0 0 0 16px;
    }

    .alice-carousel__next-btn {
      right: 0px;
      padding: 0 16px 0 0;
    }
  }
`;

interface ISlideControlProps {
  isDisabled: boolean;
}

const SlideControl = styled.div`
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ isDisabled }: ISlideControlProps) => (isDisabled ? mfColors.grey : mfColors.darkGrey)};

  &:hover {
    cursor: ${({ isDisabled }: ISlideControlProps) => (isDisabled ? 'default' : 'pointer')};
  }
`;

export interface IOption<ValueType> {
  value: ValueType;
  text: JSX.Element | string;
}

interface IProps<T> {
  value: T;
  onChange: (value: T) => void;
  options: IOption<T>[];
  height?: string;
  style?: {
    [key: string]: string | number | { [key: string]: string | number };
  };
}

const SlideSwitch = <T extends unknown>({ value, onChange, options, height = '40px', style }: IProps<T>): JSX.Element => {
  const carouselRef = useRef<AliceCarousel | null>(null);
  const [selectedSlide, setSelectedSlide] = useState<number>();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const isPrevDisabled = useMemo(() => options.find((option) => option.value === value) === options[0], [options, value, selectedSlide]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const isNextDisabled = useMemo(() => options.find((option) => option.value === value) === options[options.length - 1], [options, value, selectedSlide]);

  useEffect(() => {
    if (options.length > 0) {
      const selectedOption = options.findIndex((option) => option.value === value);
      if (selectedOption !== -1) {
        setTimeout(() => {
          if (carouselRef.current) {
            carouselRef.current.slideTo(selectedOption);
            setSelectedSlide(selectedOption);
          }
        }, 0);
      }
    }
  }, [options, carouselRef, value]);

  return (
    <Wrapper style={style} height={height} isScrollable={options.length > 1}>
      <AliceCarousel
        onSlideChanged={(e) => {
          if (options[e.slide]) {
            onChange(options[e.slide].value);
          }
        }}
        renderPrevButton={() => (
          <SlideControl isDisabled={isPrevDisabled}>
            <FontAwesomeIcon icon={faChevronLeft} />
          </SlideControl>
        )}
        renderNextButton={() => (
          <SlideControl isDisabled={isNextDisabled}>
            <FontAwesomeIcon icon={faChevronRight} />
          </SlideControl>
        )}
        ref={carouselRef}
        disableDotsControls
      >
        {options.map((option, index) => (
          <span key={index}>{option.text}</span>
        ))}
      </AliceCarousel>
    </Wrapper>
  );
};

export default SlideSwitch;
