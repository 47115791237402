import React from 'react';
import styled from 'styled-components';
import { mfColors } from 'vars';

const Line = styled.div`
  border-bottom: 1px solid ${mfColors.grey};
`;

type Props = {
  style?: { [key: string]: string | number };
};

const Divider = ({ style = {} }: Props) => <Line style={style} />;

export default Divider;
