import React, { useState, useMemo, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';

import { useTranslation } from 'react-i18next';

import InlineSelect from 'atomicComponents/InlineSelect';
import MissionsTable from 'components/missions/MissionsTable';

import { IMission, TMissionType, EMissionStatus, EMissionSubtype, ALL_MISSION_TYPES } from 'models/mission';
import { EMeasurementSystem } from 'models/region';
import { IGroveSurveyStats } from 'models/stats';
import farmsHooks from 'hooks/farms.hooks';
import missionUtils, { EDateRange } from 'utils/mission';
import dateUtils from 'utils/date';
import { missionTypeFilterSelector, setMissionTypeFilter } from 'redux/missions/missionsSlice';
import { regionMeasurementSystemSelector } from 'redux/region/regionSlice';

import { mfColors } from 'vars';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  padding: 24px 0px 24px 32px;
  background: ${mfColors.navBarBackground};
`;

const Header = styled.div`
  margin: 0 0 16px;
  border-bottom: 1px solid ${mfColors.grey};
  width: 100%;
  display: flex;
  min-height: 56px;
`;

const Filter = styled.div`
  margin: 0 48px 0 0;
`;

interface IProps {
  missions: IMission[];
  surveyStats: IGroveSurveyStats[];
}

const MissionsListHeader = ({ missions, surveyStats }: IProps) => {
  const selectedType = useSelector(missionTypeFilterSelector);
  const [selectedDateRange, setSelectedDateRange] = useState<EDateRange>(EDateRange.ThisWeek);
  const [selectedStatus, setSelectedStatus] = useState<EMissionStatus | null>(null);
  const metricType = useSelector(regionMeasurementSystemSelector) || EMeasurementSystem.Metric;
  const { groves } = farmsHooks.useFarmEntities();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const checkMissionDate = useCallback((mission: IMission, selectedDateRange: EDateRange) => {
    const now = new Date();
    let missionUpdateDate: number = dateUtils.getStartOfWeek(now);

    const hasDoneGrove = !!Object.keys(mission.groves).find((groveID) => mission.groves[groveID] === EMissionStatus.Done);

    switch (selectedDateRange) {
      case EDateRange.LastWeek:
        if (!hasDoneGrove) return mission.timestamp >= dateUtils.subtractDays(missionUpdateDate, 7) && mission.timestamp < missionUpdateDate;
        return Object.keys(mission.logs || {}).find((groveID) => {
          const timestamp = mission.logs?.[groveID]?.timestamp;
          return !!timestamp && mission.groves[groveID] === EMissionStatus.Done && timestamp >= dateUtils.subtractDays(missionUpdateDate, 7) && timestamp < missionUpdateDate;
        });
      case EDateRange.Last30Days:
        missionUpdateDate = dateUtils.subtractDays(now, 30);
        break;
      case EDateRange.Last90days:
        missionUpdateDate = dateUtils.subtractDays(now, 90);
        break;
      case EDateRange.LastYear:
        missionUpdateDate = dateUtils.subtractDays(now, 365);
        break;
      default:
        break;
    }

    if (!hasDoneGrove) return mission.timestamp >= missionUpdateDate;
    return Object.keys(mission.logs || {}).find((groveID) => {
      const timestamp = mission.logs?.[groveID]?.timestamp;
      return !!timestamp && mission.groves[groveID] === EMissionStatus.Done && timestamp >= missionUpdateDate;
    });
  }, []);

  useEffect(() => {
    setSelectedDateRange(() => {
      const dateOptions = [EDateRange.ThisWeek, EDateRange.LastWeek, EDateRange.Last30Days, EDateRange.Last90days, EDateRange.LastYear].filter((option) => {
        const missionExists = !!missions.find((mission) => checkMissionDate(mission, option));
        return missionExists;
      });
      return dateOptions.length > 0 ? dateOptions[0] : EDateRange.ThisWeek;
    });
  }, [missions, checkMissionDate]);

  const missionsToShow = useMemo(() => {
    const result = missions
      .filter((mission) => !selectedType || mission.type === selectedType || mission.subType === selectedType)
      .filter((mission) => {
        if (!selectedStatus) return true;

        const groveIDs = Object.keys(mission.groves);
        const hasGroveInProgress = groveIDs.find((groveID) => mission.groves[groveID] === EMissionStatus.InProgress);

        return selectedStatus === EMissionStatus.Done ? !hasGroveInProgress : hasGroveInProgress;
      })
      .filter((mission) => checkMissionDate(mission, selectedDateRange));

    return result;
  }, [missions, selectedType, selectedDateRange, selectedStatus, checkMissionDate]);

  const typeOptions = useMemo(() => {
    const options = ALL_MISSION_TYPES.filter((type) => !!missions.find((mission) => mission.type === type || mission.subType === type)).map((type) => ({
      value: type,
      key: type,
      text: t(missionUtils.getMissionTypeKey(type))
    }));
    return [{ value: null, key: 'all', text: t('missions.all_types') }, ...options];
  }, [t, missions]);

  const statusOptions = useMemo(
    () => [
      { value: null, key: 'all', text: t('missions.all_statuses') },
      { value: EMissionStatus.InProgress, key: EMissionStatus.InProgress, text: t('missions.in_progress') },
      { value: EMissionStatus.Done, key: EMissionStatus.Done, text: t('missions.done') }
    ],
    [t]
  );

  const dateOptions = useMemo(
    () => [
      { value: EDateRange.ThisWeek, key: EDateRange.ThisWeek, text: t('missions.this_week') },
      { value: EDateRange.LastWeek, key: EDateRange.LastWeek, text: t('missions.last_week') },
      { value: EDateRange.Last30Days, key: EDateRange.Last30Days, text: t('missions.last_x_days', { count: 30 }) },
      { value: EDateRange.Last90days, key: EDateRange.Last90days, text: t('missions.last_x_days', { count: 90 }) },
      { value: EDateRange.LastYear, key: EDateRange.LastYear, text: t('missions.last_year') }
    ],
    [t]
  );

  const handleSelectType = useCallback(
    (type: TMissionType | EMissionSubtype | null) => {
      dispatch(setMissionTypeFilter(type));
    },
    [dispatch]
  );

  return (
    <Wrapper>
      <Header>
        <Filter>
          <InlineSelect<EDateRange> value={selectedDateRange} background={mfColors.navBarBackground} onChange={setSelectedDateRange} options={dateOptions} />
        </Filter>
        <Filter>
          <InlineSelect<TMissionType | EMissionSubtype | null> value={selectedType} background={mfColors.navBarBackground} onChange={handleSelectType} options={typeOptions} />
        </Filter>
        <Filter>
          <InlineSelect<EMissionStatus | null> value={selectedStatus} background={mfColors.navBarBackground} onChange={setSelectedStatus} options={statusOptions} />
        </Filter>
      </Header>
      <MissionsTable selectedDateRange={selectedDateRange} missions={missionsToShow} groves={groves} metricType={metricType} />
    </Wrapper>
  );
};

export default MissionsListHeader;
