import React, { useMemo } from 'react';
import styled from 'styled-components';

import { IMission, EMissionStatus } from 'models/mission';
import { IGrove } from 'models/grove';
import missionUtils from 'utils/mission';
import numberUtils from 'utils/numbers';
import { EMeasurementSystem } from 'models/region';

import { mfColors } from 'vars';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const ProgressValue = styled.div`
  text-align: right;
  font-size: 12px;
  font-family: MontserratBold;
`;

interface IProgressBarProps {
  progress: number;
}

const ProgressBar = styled.div`
  position: relative;
  height: 6px;
  border-radius: 3px;
  background: ${mfColors.superLightBlueBg};
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    background: ${mfColors.lightBlue};
    width: ${({ progress }: IProgressBarProps) => `${progress}%`};
  }
`;

interface IProps {
  mission: IMission;
  groves: IGrove[];
  showValue?: boolean;
}

const MissionProgress = ({ mission, groves, showValue = true }: IProps): JSX.Element => {
  const missionProgress = useMemo((): number => {
    const groveIDs = Object.keys(mission.groves);
    const missionGroves = groves.filter((grove) => groveIDs.includes(grove.id));
    const doneGroves = groves.filter((grove) => {
      const completedGroveIDs = groveIDs.filter((id: string) => missionUtils.checkMissionGroveStatus(mission, id, EMissionStatus.Done));
      return completedGroveIDs.includes(grove.id);
    });

    const ratio = (numberUtils.getGrovesArea(doneGroves, EMeasurementSystem.Imperial) / numberUtils.getGrovesArea(missionGroves, EMeasurementSystem.Imperial)) * 100;
    return Math.floor(ratio);
  }, [mission, groves]);

  return (
    <Wrapper>
      {showValue && <ProgressValue>{`${missionProgress}%`}</ProgressValue>}
      <ProgressBar progress={missionProgress} />
    </Wrapper>
  );
};

export default MissionProgress;
