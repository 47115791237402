import React from 'react';
import styled from 'styled-components';
import { mfColors } from 'vars';

interface IButtonProps {
  isDisabled: boolean;
  isSecondary: boolean;
  hoverBackground?: string;
  hoverColor?: string;
  style?: { [key: string]: string };
}

const getButtonColors = (isDisabled: boolean, isSecondary: boolean): { fill: string; hover: string; text: string; stroke: string } => {
  if (isSecondary) {
    return { fill: mfColors.lightGrey, hover: mfColors.lightGrey, text: mfColors.darkGrey, stroke: mfColors.lightGrey };
  }
  return isDisabled
    ? { fill: mfColors.blue, hover: mfColors.blue, text: mfColors.superLightBlue, stroke: mfColors.darkerBlue }
    : { fill: mfColors.blue, hover: mfColors.darkerBlue, text: mfColors.superLightBlue, stroke: mfColors.darkerBlue };
};

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: auto;
  height: 48px;
  padding: 14px 40px;
  border: ${({ isDisabled, isSecondary }) => (isDisabled ? '0' : `1px solid ${getButtonColors(isDisabled, isSecondary).stroke}`)};
  border-radius: 8px;
  background: ${({ isDisabled, isSecondary }: IButtonProps) => getButtonColors(isDisabled, isSecondary).fill};
  position: relative;
  font-size: 16px;
  &: hover {
    background: ${({ isDisabled, isSecondary, hoverBackground }: IButtonProps) => hoverBackground || getButtonColors(isDisabled, isSecondary).hover};
    color: ${({ hoverColor }: IButtonProps) => hoverColor};
  }
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background: ${mfColors.white};
    opacity: 0.5;
    display: ${({ isDisabled }) => (isDisabled ? 'block' : 'none')};
  }
  cursor: ${({ isDisabled }) => (isDisabled ? 'default' : 'pointer')};
  color: ${({ isDisabled, isSecondary }: IButtonProps) => getButtonColors(isDisabled, isSecondary).text};
`;

export const Text = styled.div`
  line-height: 20px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
`;

type Props = {
  onClick: (e?: React.MouseEvent) => void;
  isDisabled?: boolean;
  isSecondary?: boolean;
  text: string;
  style?: { [key: string]: string };
  hoverBackground?: string;
  hoverColor?: string;
  onMouseEnter?: (e?: React.MouseEvent) => void;
  onMouseLeave?: (e?: React.MouseEvent) => void;
};

const Button = ({ onClick, text, isDisabled = false, isSecondary = false, style = {}, hoverBackground, hoverColor, onMouseEnter, onMouseLeave }: Props) => (
  <Wrapper
    hoverBackground={hoverBackground}
    hoverColor={hoverColor}
    onClick={isDisabled ? () => {} : onClick}
    isDisabled={isDisabled}
    isSecondary={isSecondary}
    style={style}
    onMouseEnter={onMouseEnter}
    onMouseLeave={onMouseLeave}
  >
    <Text>{text}</Text>
  </Wrapper>
);

export default Button;
