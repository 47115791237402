import React, { useMemo, useState, useRef } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp, faTree } from '@fortawesome/pro-solid-svg-icons';

import { IFeed } from 'models/feed';

import numberUtils from 'utils/numbers';

import { mfColors } from 'vars';

interface IEntryProps {
  isSubEntry: boolean;
}

const Wrapper = styled.div``;

const Entry = styled.div`
  background: ${mfColors.white};
  height: 105px;
  padding: 0 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-radius: 8px;
  margin: ${({ isSubEntry }: IEntryProps) => (isSubEntry ? '0 0 16px 32px' : '0 0 16px')};
`;

interface IEntryTitleProps {
  isExpandable: boolean;
}

const EntryTitle = styled.div`
  color: ${mfColors.lightBlue};
  text-transform: uppercase;
  display: flex;
  align-items: center;
  cursor: ${({ isExpandable }: IEntryTitleProps) => (isExpandable ? 'pointer' : 'default')};

  .title {
    font-size: 24px;
    font-family: MontserratBold;
  }

  svg {
    margin: 0 16px 0 0;
    color: ${mfColors.grey};
    cursor: pointer;
  }
`;

const EntryStats = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;

  .stats {
    width: 160px;
    margin: 0 160px 0 0;
    text-align: center;
    color: ${mfColors.grey};

    @media (max-width: 1500px) {
      margin: 0;
    }

    svg {
      margin: 0 8px 0 0;
    }
  }
`;

const ProgressWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  .label {
    margin: 0 16px 0 0;
    color: ${mfColors.lightBlue};
    font-size: 14px;
    line-height: 18px;
    font-family: MontserratBold;
  }
`;

const FeedEntry = styled.div`
  background: ${mfColors.white};
  height: 105px;
  padding: 0 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-radius: 8px;
  margin: 0 0 16px 96px;
`;

const FeedEntryTitle = styled.div`
  color: ${mfColors.lightBlue};
  text-transform: uppercase;
  font-size: 18px;
`;

const FeedEntryStats = styled.div`
  color: ${mfColors.grey};
  font-size: 14px;

  .bold {
    font-family: MontserratBold;
  }
`;

interface IProgressBarProps {
  percentage: number;
}

const ProgressBar = styled.div`
  height: 14px;
  width: 330px;
  border-radius: 7px;
  overflow: hidden;
  position: relative;
  background: rgba(67, 133, 243, 0.2);

  @media (max-width: 1500px) {
    width: 180px;
  }

  &::before {
    content: '';
    display: block;
    top: 0;
    left: 0;
    position: absolute;
    background: ${mfColors.lightBlue};
    height: 100%;
    width: ${({ percentage }: IProgressBarProps) => `${percentage}%`};
  }
`;

export interface IEntryWithFeed {
  id: string;
  name: string;
  feed: IFeed[];
  preassigned: IFeed[];
  counted: IFeed[];
  subEntries?: IEntryWithFeed[];
}

interface IProps {
  entry: IEntryWithFeed;
  isExpandable?: boolean;
  isSubEntry?: boolean;
}

const MarkAsCountedFarmItem = ({ entry, isSubEntry = false, isExpandable = true }: IProps): JSX.Element => {
  const { t } = useTranslation();
  const [isExpanded, setIsExpanded] = useState(false);
  const expandableRef = useRef<HTMLDivElement | null>(null);
  const countedCount = useMemo(() => entry.counted.length, [entry.counted]);
  const preassignedCount = useMemo(() => entry.preassigned.length, [entry.preassigned]);

  const percentage = useMemo(() => Math.round(numberUtils.convertToPercentage(countedCount / preassignedCount)), [countedCount, preassignedCount]);

  return (
    <Wrapper>
      <Entry isSubEntry={isSubEntry}>
        <EntryTitle isExpandable={isExpandable} onClick={isExpandable ? () => setIsExpanded((prev) => !prev) : undefined}>
          {isExpandable && <FontAwesomeIcon icon={isExpanded ? faChevronDown : faChevronUp} />}
          <span className="title">{isExpandable && !entry.subEntries ? t('dashboard.block_x', { block: entry.name }) : entry.name}</span>
        </EntryTitle>
        <EntryStats>
          <div className="stats">
            <FontAwesomeIcon icon={faTree} />
            <span>{`${countedCount} / ${preassignedCount}`}</span>
          </div>
          <ProgressWrapper>
            <div className="label">{`${percentage}%`}</div>
            <ProgressBar percentage={percentage} />
          </ProgressWrapper>
        </EntryStats>
      </Entry>
      {isExpanded && (
        <div ref={expandableRef} className="expandable">
          {!entry.subEntries && (
            <>
              {entry.feed.map((feedItem) => (
                <FeedEntry key={feedItem.id}>
                  <FeedEntryTitle>{t('dashboard.tree_x', { tree: feedItem.geoObjectName?.replace(/null\//g, '') })}</FeedEntryTitle>
                  <FeedEntryStats>
                    {!!feedItem.serialNumber && (
                      <>
                        <span>{t('dashboard.serial_number_x', { serialNumber: feedItem.serialNumber })}</span>
                        <span> | </span>
                      </>
                    )}
                    <span className={feedItem.data?.fruitCountStatus === 'isCounted' ? 'bold' : undefined}>{t('missions.counted')}</span>
                    <span> | </span>
                    <span className={feedItem.data?.fruitCountStatus === 'isNotApplicable' ? 'bold' : undefined}>{t('missions.isNotApplicable')}</span>
                    <span> | </span>
                    <span className={feedItem.data?.fruitCountStatus === 'crownTree' ? 'bold' : undefined}>{t('missions.crownTree')}</span>
                  </FeedEntryStats>
                </FeedEntry>
              ))}
            </>
          )}
          {entry.subEntries && entry.subEntries.map((subEntry) => <MarkAsCountedFarmItem key={subEntry.id} entry={subEntry} isSubEntry />)}
        </div>
      )}
    </Wrapper>
  );
};

export default MarkAsCountedFarmItem;
