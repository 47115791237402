import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/pro-solid-svg-icons';
import { mfColors } from '../vars';

const Wrapper = styled.div`
  width: 100%;
  margin: 24px 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Icon = styled(FontAwesomeIcon)`
  color: ${mfColors.darkBlue};
`;

const LocalLoader = () => (
  <Wrapper>
    <Icon icon={faSpinner} className="fa-spin" size="2x" />
  </Wrapper>
);

export default LocalLoader;
