import React, { useState, useMemo, useCallback, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { IFarmName } from 'models/farm';
import { IGrove } from 'models/grove';
import { IZone } from 'models/zone';
import { IUserSearches } from 'models/user';

import { getMode } from 'redux/modeSlice';
import SearchBar from 'atomicComponents/SearchBar';
import BreadcrumbDropdownContent, { IListModel, ESuggestionModelType } from 'components/main/MainPageBreadcrumbs/BreadcrumbDropdownContent';
import SearchResultsDropdownContent from 'components/main/FarmSearch/SearchResultsDropdownContent';
import usersHooks from 'hooks/users.hooks';

import { useSelector } from 'react-redux';

const SearchWrapper = styled.div`
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  margin: 0 0 16px;
`;

const Search = styled.div`
  margin: 0;
  height: 34px;
  width: 100%;
`;

interface IProps {
  farm: IFarmName | null;
  zone: IZone | null;
  grove: IGrove | null;
  farms: IFarmName[];
  zones: IZone[];
  groves: IGrove[];
  showSearchHistory?: boolean;
  prefix?: string;
  onSelectEntry?: (model: IFarmName) => void;
}

const filterListModels = (models: IListModel[], searchText: string): IListModel[] => {
  const result = models.filter((entry) => entry.name.toLowerCase().indexOf(searchText.toLowerCase()) !== -1).sort((a, b) => a.name.localeCompare(b.name));
  return result;
};

const FarmSearch = ({ farm, farms, zones, groves, zone, grove, onSelectEntry, showSearchHistory = true, prefix = '' }: IProps): JSX.Element => {
  const { t } = useTranslation();
  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const [isSearchExpanded, setIsSearchExpanded] = useState<boolean>(false);
  const [searchText, setSearchText] = useState<string>('');
  const [showSuggestions, setShowSuggestions] = useState(false);

  const defaultSuggestions = useMemo(() => {
    let result: IListModel[] = farms;
    if (grove) {
      result = groves;
    } else if (zone) {
      result = zones;
    }
    return result.filter((model) => !!model.name).sort((a, b) => a.name.localeCompare(b.name));
  }, [zone, grove, farms, zones, groves]);

  const defaultSuggestionsModelType = useMemo(() => {
    if (grove) return ESuggestionModelType.Grove;
    if (zone) return ESuggestionModelType.Zone;
    return ESuggestionModelType.Farm;
  }, [zone, grove]);

  const farmSuggestions = useMemo(() => filterListModels(farms, searchText), [farms, searchText]);

  const zoneSuggestions = useMemo(() => filterListModels(zones, searchText), [zones, searchText]);

  const groveSuggestions = useMemo(() => filterListModels(groves, searchText), [groves, searchText]);

  const navigate = useNavigate();
  const { getUserSearches, updateUserSearches, searches: userSearches } = usersHooks.useUserSearches();

  const searchHistory: string[] = useMemo(() => {
    if (!userSearches) {
      return [];
    }
    if (grove) {
      return userSearches.groves?.filter((entry) => !!groves.find((grove) => grove.id === entry)) || [];
    }
    if (zone) {
      return userSearches.zones?.filter((entry) => !!zones.find((zone) => zone.id === entry)) || [];
    }
    return userSearches.farms || [];
  }, [userSearches, grove, zone, groves, zones]);

  const handleSuggestionClick = useCallback(
    (model: IListModel, type: ESuggestionModelType, e: React.MouseEvent<HTMLElement>) => {
      const updatedSearches: IUserSearches = {
        farms: userSearches?.farms || [],
        groves: userSearches?.groves || [],
        zones: userSearches?.zones || []
      };

      if (type === ESuggestionModelType.Grove) {
        updatedSearches.groves = [...new Set([model.name, ...updatedSearches.groves.slice(0, 4)])];
      } else if (type === ESuggestionModelType.Zone) {
        updatedSearches.zones = [...new Set([model.name, ...updatedSearches.zones.slice(0, 4)])];
      } else {
        updatedSearches.farms = [...new Set([model.name, ...updatedSearches.farms.slice(0, 4)])];
      }
      updateUserSearches(updatedSearches);

      if (onSelectEntry) {
        e.stopPropagation();
        e.preventDefault();
        onSelectEntry(model as IFarmName);
      }
    },
    [updateUserSearches, userSearches, onSelectEntry]
  );

  const linkPrefix = useMemo(() => {
    if (farm && grove && zone) {
      return `${prefix}/${farm.id}/${zone.id}/`;
    }
    if (farm && zone) {
      return `${prefix}/${farm.id}/`;
    }
    return `${prefix}/`;
  }, [farm, grove, zone, prefix]);

  const mode = useSelector(getMode);

  const getTitle = useMemo(() => {
    if (isSearchExpanded) {
      return '';
    }
    if (!farm?.name) return t('shared.all');
    return mode ? farm.name : grove?.name || zone?.name || farm.name;
  }, [t, mode, isSearchExpanded, grove?.name, zone?.name, farm?.name]);

  const navigateToHint = useCallback(
    (hint: string) => {
      const model = defaultSuggestions.find((model) => model.name === hint);

      if (model) {
        navigate(`${linkPrefix}${model.id}`);
      }
    },
    [navigate, linkPrefix, defaultSuggestions]
  );

  const handleListEnd = useCallback((direction: -1 | 1): void => {
    if (wrapperRef.current && direction === -1) {
      wrapperRef.current.querySelector('input')?.focus();
    }
  }, []);

  const handleGoToSuggestions = useCallback((): void => {
    if (wrapperRef.current) {
      wrapperRef.current.querySelector('button')?.focus();
    }
  }, []);

  useEffect(() => {
    getUserSearches();
  }, [getUserSearches]);

  return (
    <SearchWrapper ref={wrapperRef}>
      <Search>
        <SearchBar
          title={getTitle}
          suggestions={
            !searchText ? (
              <BreadcrumbDropdownContent
                hints={!searchText && showSearchHistory ? searchHistory : undefined}
                onHintClicked={navigateToHint}
                onEntryClicked={handleSuggestionClick}
                onListEnd={handleListEnd}
                models={defaultSuggestions}
                modelType={defaultSuggestionsModelType}
                linkPrefix={linkPrefix}
              />
            ) : (
              <SearchResultsDropdownContent
                linkPrefix={`${prefix}/`}
                farms={farmSuggestions}
                zones={zoneSuggestions}
                groves={groveSuggestions}
                onListEnd={handleListEnd}
                onEntryClicked={handleSuggestionClick}
              />
            )
          }
          onSearchChange={setSearchText}
          onGoToSuggestions={handleGoToSuggestions}
          onExpandedStateChange={setIsSearchExpanded}
          showSuggestions={showSuggestions}
          setShowSuggestions={setShowSuggestions}
          name="search"
        />
      </Search>
    </SearchWrapper>
  );
};

export default FarmSearch;
