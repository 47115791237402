import { useEffect } from 'react';
import i18n from 'i18next';
import moment from 'moment';
import 'moment/locale/pt';
import 'moment/locale/es';
import 'moment/locale/el';
import { initReactI18next } from 'react-i18next';
import detector from 'i18next-browser-languagedetector';

import translationEN from 'translations/en.json';
import translationPT from 'translations/pt.json';
import translationES from 'translations/es.json';
import translationEL from 'translations/el.json';

import signInHooks from 'hooks/signIn.hooks';

export enum ESupportedLanguage {
  EN = 'en',
  PT = 'pt',
  ES = 'es',
  EL = 'el'
}

const SUPPORTED_LANGUAGES = [ESupportedLanguage.EN, ESupportedLanguage.PT, ESupportedLanguage.ES, ESupportedLanguage.EL];

const useTranslationsInit = (): void => {
  const user = signInHooks.useSignedInUser();

  useEffect(() => {
    i18n
      .use(detector)
      .use(initReactI18next)
      .init({
        resources: {
          [ESupportedLanguage.EN]: {
            translation: translationEN
          },
          [ESupportedLanguage.PT]: {
            translation: translationPT
          },
          [ESupportedLanguage.ES]: {
            translation: translationES
          },
          [ESupportedLanguage.EL]: {
            translation: translationEL
          }
        },
        fallbackLng: ESupportedLanguage.EN,
        supportedLngs: SUPPORTED_LANGUAGES,
        interpolation: {
          escapeValue: false
        }
      });
  }, []);

  useEffect(() => {
    if (user?.settings?.language && SUPPORTED_LANGUAGES.includes(user.settings.language as ESupportedLanguage)) {
      i18n.changeLanguage(user.settings?.language as ESupportedLanguage);
    } else {
      i18n.changeLanguage(ESupportedLanguage.EN);
    }
    moment.locale(i18n.language);
  }, [user?.settings]);
};

const tranlationsHooks = {
  useTranslationsInit
};

export default tranlationsHooks;
