import { useCallback } from 'react';
import useLogger, { EStorageType } from 'hooks/logger.hooks';
import db from 'services/data/providers/firebase';
import { ref, get, update } from 'firebase/database';
import { doc, getDoc, updateDoc, collection, onSnapshot, query, orderBy, runTransaction, increment, where, getDocs, setDoc } from 'firebase/firestore';
import { IUserSearches, IUserInfo, TUserSettings, IUserAlerts, IUserAssociatedFarms } from 'models/user';
import { firestoreToArray } from 'utils/helpers';

export const useUpdateUserSearches = (): ((searches: IUserSearches) => Promise<void>) => {
  const { logError } = useLogger();

  return useCallback(
    async (searches: IUserSearches): Promise<void> => {
      const currentUserID = db.user?.uid;
      const path = '/users';
      const usersCollection = collection(db.firestore, path);

      try {
        await updateDoc(doc(usersCollection, currentUserID), { searches });
      } catch (e) {
        logError(e as Error, path, EStorageType.Firestore);
      }
    },
    [logError]
  );
};

export const useUpdateUserSettings = (): ((settings: TUserSettings) => Promise<void>) => {
  const { logError } = useLogger();

  return useCallback(
    async (settings: TUserSettings): Promise<void> => {
      const currentUserID = db.user?.uid;
      const path = '/users';
      const usersCollection = collection(db.firestore, path);

      try {
        await setDoc(doc(usersCollection, currentUserID), { settings }, { merge: true });
      } catch (e) {
        logError(e as Error, path, EStorageType.Firestore);
      }
    },
    [logError]
  );
};

export const useFetchUserSearches = (): (() => Promise<IUserSearches | null>) => {
  const { logError } = useLogger();

  return useCallback(async (): Promise<IUserSearches | null> => {
    const currentUserID = db.user?.uid;

    if (!currentUserID) return Promise.resolve(null);

    const path = '/users';
    const usersCollection = collection(db.firestore, path);

    try {
      const userRef = doc(usersCollection, currentUserID);
      return getDoc(userRef).then((doc) => (doc.exists() ? doc.data()?.searches : null));
    } catch (e) {
      logError(e as Error, path, EStorageType.Firestore);
      return Promise.resolve(null);
    }
  }, [logError]);
};

export const fetchUserById = async (id: string): Promise<IUserInfo> => {
  const userRef = doc(db.firestore, `users/${id}`);
  const userSnapshot = await getDoc(userRef);
  const data = userSnapshot.data() || {};
  return { ...data, id } as IUserInfo;
};

export const useUpdateUserSubscriptions = (): ((id: string, subscriptions: { [key: string]: boolean }) => Promise<void>) => {
  const { logError } = useLogger();

  return useCallback(
    async (id: string, subscriptions: { [key: string]: boolean }): Promise<void> => {
      const path = `/users/${id}`;

      try {
        const usersRef = ref(db.database, path);
        const userSnap = await get(usersRef);

        if (!userSnap) throw new Error('User not found');

        update(usersRef, {
          ...userSnap.val(),
          subscriptions
        });
      } catch (e) {
        logError(e as Error, path, EStorageType.Firebase);
      }
    },
    [logError]
  );
};

interface IListenParams {
  id: string;
  farmID: string;
  createdAt: number;
}
export const listenUserAlerts = (
  { id, farmID, createdAt }: IListenParams,
  onSucess: (notifications: IUserAlerts[]) => void,
  onError: (error: Error, path: string, type: string) => void
) => {
  const collectionPath = `/users/${id}/alerts`;
  const q = query(collection(db.firestore, collectionPath), where('params.farmID', '==', farmID), where('createdAt', '>=', createdAt), orderBy('createdAt', 'desc'));

  const unsubscribe = onSnapshot(
    q,
    (querySnapshot) => {
      const alerts = firestoreToArray(querySnapshot).filter((item) => item.type !== 'surveyPublished');

      onSucess(alerts);
    },
    (e) => {
      onError(e, collectionPath, EStorageType.Firestore);
    }
  );

  return unsubscribe;
};

export const getUserSurveyPublishedAlert = async (userID: string, farmID: string, surveyID: string) => {
  const collectionPath = `/users/${userID}/alerts`;
  const q = query(collection(db.firestore, collectionPath), where('type', '==', 'surveyPublished'), where('params.farmID', '==', farmID), where('params.surveyID', '==', surveyID));
  const docs = await getDocs(q);

  return firestoreToArray(docs) as IUserAlerts[];
};

export const useUpdateFarmVisit = (): ((userID: string, farmID: string, visitedAt: number) => Promise<void>) => {
  const { logError } = useLogger();

  return useCallback(
    async (userID: string, farmID: string, visitedAt: number): Promise<void> => {
      const path = 'farm-names';
      const userFarmsCollections = collection(db.firestore, path);
      const userFarmsDoc = doc(userFarmsCollections, userID);
      try {
        await updateDoc(userFarmsDoc, { [`${farmID}.visitedAt`]: visitedAt });
      } catch (e) {
        logError(e as Error, path, EStorageType.Firestore);
      }
    },
    [logError]
  );
};

export const useUpdateUserAlerts = (): ((alertID: string) => Promise<void>) => {
  const { logError } = useLogger();

  return useCallback(
    async (alertID: string): Promise<void> => {
      const userID = db.user?.uid;

      if (!userID) return Promise.resolve();

      const path = '/users';
      const usersCollection = collection(db.firestore, path);
      const usersAlertsCollection = collection(db.firestore, `${path}/${userID}/alerts`);

      try {
        await runTransaction(db.firestore, async (transaction) => {
          const userRef = doc(usersCollection, userID);
          const alertRef = doc(usersAlertsCollection, alertID);
          const userDoc = await transaction.get(userRef);

          const userData = userDoc.data();

          if (!userData || userData.unreadAlertsCount <= 0) {
            return;
          }

          transaction.update(userRef, { unreadAlertsCount: increment(-1) });
          transaction.update(alertRef, { read: true });
        });

        return Promise.resolve();
      } catch (e) {
        logError(e as Error, path, EStorageType.Firestore);
        return Promise.resolve();
      }
    },
    [logError]
  );
};

export const useReadUserAlert = (): ((alertID: string) => Promise<void>) => {
  const { logError } = useLogger();

  return useCallback(
    async (alertID: string): Promise<void> => {
      const userID = db.user?.uid;

      if (!userID) return Promise.resolve();

      const path = '/users';
      const usersAlertsCollection = collection(db.firestore, `${path}/${userID}/alerts`);
      const alertRef = doc(usersAlertsCollection, alertID);

      try {
        await updateDoc(alertRef, { read: true });

        return Promise.resolve();
      } catch (e) {
        logError(e as Error, path, EStorageType.Firestore);
        return Promise.resolve();
      }
    },
    [logError]
  );
};

export const fetchUserAssociateFarms = async (id: string): Promise<IUserAssociatedFarms> => {
  const farmsCollection = collection(db.firestore, 'farm-names');
  const farmsRef = doc(farmsCollection, id);
  const farmsDoc = await getDoc(farmsRef);
  return farmsDoc.data() as IUserAssociatedFarms;
};
