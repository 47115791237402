import React, { PropsWithChildren, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusLarge } from '@fortawesome/pro-solid-svg-icons';
import { useDispatch, useSelector } from 'react-redux';
import IconButton from 'atomicComponents/IconButton';
import {
  getDistanceMeasurementStatus,
  setMeasurePoints,
  toggleDistanceMeasurementStatus,
  EDistanceMeasurementType,
  getDistanceMeasurePoints,
  setDistanceMeasurementStatus,
  setMeasureAreaBbox
} from 'redux/distanceMeasurementSlice';
import { mfColors } from 'vars';
import { EMeasurementSystem } from 'models/region';
import rulerUtils from 'utils/ruler';
import { Button } from 'atomicComponents';
import { isCapacityShownSelector } from 'redux/appStateSlice';
import { storeReplantSegment } from 'services/data/replant';
import { addNewSegment } from 'redux/replant/replantSlice';
import { IGrove } from 'models/grove';
import { IFarm } from 'models/farm';
import { ISurveyWithTimeRange } from 'models/survey';

interface IWrapperProps {
  isOpened: boolean;
}

const Wrapper = styled.div`
  padding: 4px;
  background: ${mfColors.lightGrey};
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  overflow: hidden;
  transition: width 0.25s ease-in;
  width: 100%;
  opacity: ${({ isOpened }: IWrapperProps) => (isOpened ? '1' : '0.6')};

  &:hover {
    opacity: 1;
  }
`;

const Seperator = styled.div`
  width: 1px;
  margin: 10px 2px;
  height: 20px;
  background: ${mfColors.grey};
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding: 0px 4px;
`;

const Label = styled.div`
  text-transform: capitalize;
`;

const DistanceMeasurement = styled.div`
  font-family: Montserrat;
  font-size: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  opacity: ${({ isOpened }: IWrapperProps) => (isOpened ? '1' : '0')};
  transition: opacity 0.25s ease-in;
`;

const Column = styled.div``;

const SegmentIcon = styled(FontAwesomeIcon)`
  cursor: pointer;
  rotate: ${({ isOpened }: IWrapperProps) => (isOpened ? '45deg' : '0')};
  width: ${({ isOpened }: IWrapperProps) => (isOpened ? '22px' : '32px')};
  height: ${({ isOpened }: IWrapperProps) => (isOpened ? '22px' : '32px')};
  transition: all 0.3s ease-in;
`;

type TProps = PropsWithChildren<{
  distance: number;
  measurementType: EDistanceMeasurementType;
  metricType: EMeasurementSystem;
  grove: IGrove | null;
  farm: IFarm | null;
  survey: ISurveyWithTimeRange | null;
}>;

const SegmentTool = ({ distance, measurementType, metricType, grove, farm, survey }: TProps): JSX.Element => {
  const { t } = useTranslation();
  const distanceMeasurementStatus = useSelector(getDistanceMeasurementStatus);
  const measurePoints = useSelector(getDistanceMeasurePoints);
  const isCapacityShown = useSelector(isCapacityShownSelector);
  const dispatch = useDispatch();

  const isLine = useMemo(() => measurementType === EDistanceMeasurementType.Line, [measurementType]);

  useEffect(() => {
    if (!grove && distanceMeasurementStatus) {
      dispatch(setDistanceMeasurementStatus(false));
    }
  }, [grove, distanceMeasurementStatus, dispatch]);

  useEffect(() => {
    if (grove?.geometry) {
      dispatch(setMeasureAreaBbox(grove.geometry));
    }

    return () => {
      dispatch(setMeasureAreaBbox(null));
    };
  }, [grove, dispatch]);

  useEffect(
    () => () => {
      if (isCapacityShown) {
        dispatch(setDistanceMeasurementStatus(false));
      }
    },
    [isCapacityShown, dispatch]
  );

  const toggleRuler = () => {
    dispatch(toggleDistanceMeasurementStatus());
  };

  const refreshMeasurement = () => {
    dispatch(setMeasurePoints([]));
  };

  const saveSegments = async () => {
    const surveyID = survey?.id;
    const groveID = grove?.id;
    const farmID = farm?.id;
    const segmentLength = rulerUtils.getDistanceLength(distance, metricType);
    const lineString = rulerUtils.pointsToLine(measurePoints, { length: segmentLength });

    if (farmID && groveID && surveyID) {
      const segment = { farmID, groveID, surveyID, lineString, metricType };
      const id = await storeReplantSegment(segment);

      dispatch(addNewSegment({ ...segment, length: segmentLength, id }));
      toggleRuler();
      refreshMeasurement();
    }
  };

  return (
    <Wrapper isOpened={distanceMeasurementStatus}>
      <IconButton onClick={toggleRuler} active={false} icon={<SegmentIcon icon={faPlusLarge} isOpened={distanceMeasurementStatus} />} iconSize="lg" />
      {distanceMeasurementStatus && (
        <>
          <Seperator />
          <DistanceMeasurement isOpened={distanceMeasurementStatus}>
            <Column>
              <Row>
                <Label>{`${t(isLine ? 'shared.distance' : 'shared.area')} :`}</Label>
              </Row>
              <Row>{isLine ? rulerUtils.formatDistance(distance, metricType, t) : rulerUtils.formatArea(distance, metricType)}</Row>
            </Column>
            <Column>
              <Row>{!!distance && <Button style={{ padding: '0px 16px', height: '32px', borderRadius: '32px' }} onClick={saveSegments} text={t('shared.save')} />}</Row>
            </Column>
          </DistanceMeasurement>
        </>
      )}
    </Wrapper>
  );
};

export default SegmentTool;
