import React, { useMemo, useCallback } from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { IFarmName } from 'models/farm';
import { ICompany } from 'models/company';

import BreadcrumbDropdownContent, { ESuggestionModelType } from 'components/main/MainPageBreadcrumbs/BreadcrumbDropdownContent';

import Dropdown, { EToggleType } from 'atomicComponents/Dropdown';

import { mfColors } from 'vars';

const Wrapper = styled.div`
  font-size: 12px;
  line-height: 15px;
  display: inline-flex;
`;

const BreadcrumbLink = styled(NavLink)`
  text-decoration: none;
`;

const BreadcrumbEntry = styled.span`
  color: ${mfColors.lightBlue};
`;

const BreadcrumbSeparator = styled.span`
  color: ${mfColors.grey};
  margin: 0 6px;
`;

interface IProps {
  prefix: string;
  company: ICompany;
  farm: IFarmName | null;
  farms: IFarmName[];
  companies: ICompany[];
  onSelectCompany: (company: ICompany) => void;
  onSelectFarm: (farm: IFarmName | null) => void;
}

const Breadcrumbs = ({ prefix, company, companies, farm, farms, onSelectCompany, onSelectFarm }: IProps): JSX.Element => {
  const { t } = useTranslation();
  const companiesForSelect = useMemo(() => companies.filter((entry) => entry.id !== company.id), [companies, company]);
  const farmsForSelect = useMemo(() => {
    const nonSelectedFarms = farms.filter((entry) => entry.id !== farm?.id);

    if (!farm?.id) return nonSelectedFarms;
    return [
      {
        id: null,
        name: t('shared.all')
      },
      ...nonSelectedFarms
    ];
  }, [t, farms, farm]);

  const handleCompanyClick = useCallback(
    (entry: { id: string | null }, _entryType, e: React.MouseEvent<HTMLElement>) => {
      const company = companies.find((item) => item.id === entry.id);

      if (company) {
        onSelectCompany(company);
      }

      e.stopPropagation();
      e.preventDefault();
    },
    [companies, onSelectCompany]
  );

  const handleFarmClick = useCallback(
    (entry: { id: string | null }, _entryType, e: React.MouseEvent<HTMLElement>) => {
      if (!entry.id) {
        onSelectFarm(null);
      } else {
        const farm = farms.find((item) => item.id === entry.id);
        if (farm) {
          onSelectFarm(farm);
        }
      }

      e.stopPropagation();
      e.preventDefault();
    },
    [farms, onSelectFarm]
  );

  return (
    <Wrapper>
      {!!companiesForSelect?.length && (
        <>
          <Dropdown
            toggleType={EToggleType.Mouseover}
            control={
              <BreadcrumbLink onClick={(e: React.MouseEvent<HTMLElement>) => e.preventDefault()} to={farm?.id ? `${prefix}/${farm.id}` : prefix}>
                <BreadcrumbEntry>{company.name}</BreadcrumbEntry>
              </BreadcrumbLink>
            }
            content={<BreadcrumbDropdownContent onEntryClicked={handleCompanyClick} models={companiesForSelect} modelType={ESuggestionModelType.Farm} linkPrefix={prefix} />}
          />
          <BreadcrumbSeparator>{'>'}</BreadcrumbSeparator>
        </>
      )}
      <Dropdown
        toggleType={EToggleType.Mouseover}
        control={
          <BreadcrumbLink onClick={(e: React.MouseEvent<HTMLElement>) => e.preventDefault()} to={farm?.id ? `${prefix}/${farm.id}` : prefix}>
            <BreadcrumbEntry>{farm?.name || t('shared.all')}</BreadcrumbEntry>
          </BreadcrumbLink>
        }
        content={<BreadcrumbDropdownContent onEntryClicked={handleFarmClick} models={farmsForSelect} modelType={ESuggestionModelType.Farm} linkPrefix="/map/" />}
      />
    </Wrapper>
  );
};

export default Breadcrumbs;
