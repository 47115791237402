import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import commoditiesService from 'services/data/commodities';
import { ICommodity } from 'models/commodity';
import { useTranslation } from 'react-i18next';
import ButtonToggleGroup, { TToggleValueModifier } from './ButtonToggleGroup';

interface IProp {
  farmIDs: string[];
  selectedCommodities: string[];
  selectedVarieties: string[];
  selectCommodities: (commodities: string[]) => void;
  selectVarieties: (varieties: string[]) => void;
}

const FilterLabel = styled.span`
  line-height: 2.3;
  display: inline-block;
  padding-right: 10px;
`;
const Container = styled.div`
  display: flex;
`;

const CompanyCommodityFilter = ({ farmIDs = [], selectedCommodities, selectedVarieties, selectCommodities, selectVarieties }: IProp) => {
  const { t } = useTranslation();
  const [currentCommoditiesVarieties, setCurrentCommoditiesVarieties] = useState<string[]>([]);
  const [commoditiesVarieties, setCommoditiesVarieties] = useState<ICommodity[]>([]);
  const [commodities, setCommodities] = useState<string[]>([]);

  useEffect(() => {
    if (!farmIDs.length) {
      return;
    }
    selectCommodities([]);
    selectVarieties([]);
    commoditiesService.getGrovesCommoditiesVarieties({ farmIDs }).then((data) => {
      setCommoditiesVarieties(data);
      const uncheckedCommodities = [...new Set(data.map((commoditiesVariety) => commoditiesVariety.commodity))] as string[];
      setCommodities(uncheckedCommodities.filter((commodity) => commodity));
    });
  }, [farmIDs, selectCommodities, selectVarieties]);

  useEffect(() => {
    const filteredCommodityVarieties = commoditiesVarieties.filter((commodityVariety) => selectedCommodities.find((commodity) => commodity === commodityVariety.commodity));
    const uniqueVarieties = [...new Set(filteredCommodityVarieties.map((varietyObj) => varietyObj.variety))];
    setCurrentCommoditiesVarieties(uniqueVarieties);
  }, [commoditiesVarieties, selectedCommodities]);

  useEffect(() => {
    if (!commodities.length) {
      return;
    }

    selectCommodities([commodities[0]]);
  }, [commodities, selectCommodities]);

  useEffect(() => {
    const newVarieties = selectedVarieties.filter((variety) => currentCommoditiesVarieties.includes(variety));

    if (newVarieties.length === selectedVarieties.length) {
      return;
    }

    selectVarieties(newVarieties);
  }, [currentCommoditiesVarieties, selectVarieties, selectedVarieties]);

  useEffect(() => {
    if (!commoditiesVarieties.length) {
      return;
    }

    const varietiesByCommodity = commoditiesVarieties.reduce((res: { [key: string]: string[] }, currentVariety) => {
      if (res[currentVariety.commodity]) {
        if (!res[currentVariety.commodity].includes(currentVariety.variety)) {
          res[currentVariety.commodity].push(currentVariety.variety);
        }
      } else {
        res[currentVariety.commodity] = [currentVariety.variety];
      }
      return res;
    }, {});
    const varietiesToSelect = selectedCommodities
      .map((commodity) => {
        const hasSelectedVariety = varietiesByCommodity[commodity].find((variety: string) => selectedVarieties.includes(variety));

        if (hasSelectedVariety) {
          return null;
        }

        return varietiesByCommodity[commodity][0];
      })
      .filter((item) => item);

    if (!varietiesToSelect.length) {
      return;
    }

    selectVarieties([...selectedVarieties, ...varietiesToSelect].filter((variety) => !!variety) as string[]);
  }, [selectedCommodities, commoditiesVarieties, selectVarieties, selectedVarieties]);

  if (!commodities.length) {
    return null;
  }

  const doNotAllowEmptyArray = (callback: (array: string[]) => void) => (array: string[]) => {
    if (!array.length) {
      return;
    }

    callback(array);
  };

  return (
    <Container>
      <FilterLabel>{`${t('company_commodity_filter.header')}:`}</FilterLabel>
      <ButtonToggleGroup items={commodities} selected={selectedCommodities} onToggle={doNotAllowEmptyArray(selectCommodities) as TToggleValueModifier} multiple />
      <FilterLabel> &gt; </FilterLabel>
      <ButtonToggleGroup items={currentCommoditiesVarieties} selected={selectedVarieties} onToggle={doNotAllowEmptyArray(selectVarieties) as TToggleValueModifier} multiple />
    </Container>
  );
};

export default CompanyCommodityFilter;
