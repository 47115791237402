import React from 'react';
import styled from 'styled-components';
import { mfColors } from 'vars';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/pro-solid-svg-icons';

import farmsHooks from 'hooks/farms.hooks';
import { TMappedStatistic } from 'models/statistic';

import { IReplantRules } from 'models/replant';
import EditCalculator from 'components/replant/EditCalculator';

const Wrapper = styled.div`
  padding: 24px 24px 24px 48px;
  border-radius: 0 0 16px 16px;
  min-height: 588px;
  position: relative;
`;

const Header = styled.div`
  margin: 16px 0 32px;
  display: flex;
  align-items: center;

  span {
    font-size: 24px;
    font-family: MontserratBold;
  }

  border-bottom: 2px solid ${mfColors.superLightBlueBg};
  padding-bottom: 18px;
`;

const CloseIcon = styled(FontAwesomeIcon)`
  font-size: 24px;
  margin-right: 8px;
  cursor: pointer;
`;

const EDIT_SPACE = 30;

interface IProps {
  statistic: TMappedStatistic | null;
  replantRules: IReplantRules;
  close: () => void;
  onSave: (changedRules: IReplantRules) => void;
  controls: {
    saveControl: React.ReactNode;
    resetControl?: React.ReactNode;
  };
}

const ReplantCalculator = ({ statistic, replantRules, close, onSave, controls }: IProps) => {
  const { t } = useTranslation();
  const { selectedFarm } = farmsHooks.useSelectedFarmEntities();

  return (
    <Wrapper>
      <Header>
        <CloseIcon onClick={close} icon={faChevronLeft} />
        <span>{selectedFarm?.name}</span>
      </Header>
      <div style={{ fontSize: 22, marginBottom: `${EDIT_SPACE + 26}px`, marginTop: `${EDIT_SPACE + 26}px` }}>{t('replant.calculator.edit_settings_description')}</div>
      <EditCalculator statistic={statistic} replantRules={replantRules} controls={controls} onUpdateReplantRules={onSave} />
    </Wrapper>
  );
};

export default ReplantCalculator;
