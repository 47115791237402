import React from 'react';
import styled from 'styled-components';
import { mfColors } from 'vars';
import { ISurveyWithTimeRange } from 'models/survey';
import SurveySelect from 'components/shared/SurveySelect';
import { EPosition as ESelectPosition } from 'atomicComponents/FormSelect';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const ControlsBlock = styled.div`
  padding: 16px 14px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.5);
  border-radius: 8px;
  background-color: ${mfColors.superLightBlue};
  pointer-events: auto;
`;

interface IProps {
  surveys: ISurveyWithTimeRange[];
  selectedSurvey: ISurveyWithTimeRange;
  onSelectSurvey: (survey: ISurveyWithTimeRange) => void;
}

const MapSurveyControls = ({ surveys, selectedSurvey, onSelectSurvey }: IProps): JSX.Element => (
  <Wrapper>
    <ControlsBlock>
      <SurveySelect position={ESelectPosition.Top} onSelectSurvey={onSelectSurvey} surveys={surveys} selectedSurvey={selectedSurvey?.id || ''} />
    </ControlsBlock>
  </Wrapper>
);

export default MapSurveyControls;
