import React, { PropsWithChildren, useCallback, useContext, useEffect } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWrench, faRuler, faRetweet } from '@fortawesome/pro-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import { Map as Mapbox } from 'mapbox-gl';
import { useDispatch, useSelector } from 'react-redux';
import farmsHooks from 'hooks/farms.hooks';
import signInHooks from 'hooks/signIn.hooks';
import IconButton, { EIconType } from 'atomicComponents/IconButton';
import { useAnalytics } from 'hooks/analytics.hooks';
import { EEventType } from 'models/analytics';
import { getDistanceMeasurementStatus, setMeasurePoints, toggleDistanceMeasurementStatus, EDistanceMeasurementType, setMeasureAreaBbox } from 'redux/distanceMeasurementSlice';
import { ReactComponent as SplitScreen } from 'assets/images/split_screen.svg';
import { mfColors } from 'vars';
import { EMeasurementSystem } from 'models/region';
import { setCompareIsOpened, setIsSideBarExpanded } from 'redux/appStateSlice';
import { MapContext } from 'components/shared/Map';
import rulerUtils from 'utils/ruler';

interface IWrapperProps {
  isOpened: boolean;
}

const Wrapper = styled.div`
  padding: 4px;
  background: ${mfColors.lightGrey};
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  overflow: hidden;
  transition: width 0.25s ease-in;
  width: ${({ isOpened }: IWrapperProps) => (isOpened ? '100%' : '48px')};
  opacity: ${({ isOpened }: IWrapperProps) => (isOpened ? '1' : '0.6')};

  &:hover {
    width: 100%;
    transition: width 0.25s ease-in;
    opacity: 1;
  }

  &:hover .menu {
    width: 100%;
  }
`;

const Entry = styled.div`
  position: relative;
  font-size: 18px;

  &:hover svg {
    color: ${mfColors.grey};
  }
`;

const CompareIcon = styled.div`
  height: 1.25em;
  width: 1.25em;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Menu = styled.div`
  width: 0;
  transition: width 0.25s ease-in;
  display: flex;
  flex-direction: row;
`;

const Seperator = styled.div`
  width: 1px;
  margin: 10px 2px;
  height: 20px;
  background: ${mfColors.grey};
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding: 0px 4px;
`;

const Label = styled.div`
  text-transform: capitalize;
`;

const DistanceMeasurement = styled.div`
  font-family: Montserrat;
  font-size: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Column = styled.div``;

const ControlIcon = styled(FontAwesomeIcon)`
  cursor: pointer;
  margin: 2px;
`;

type TProps = PropsWithChildren<{
  map?: Mapbox | null;
  distance: number;
  measurementType: EDistanceMeasurementType;
  metricType: EMeasurementSystem;
}>;

const GroveControls = ({ map, distance, measurementType, metricType }: TProps): JSX.Element => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { selectedSurvey, selectedGrove, selectedZone, selectedFarm } = farmsHooks.useSelectedFarmEntities();
  const distanceMeasurementStatus = useSelector(getDistanceMeasurementStatus);
  const { logEvent } = useAnalytics();
  const dispatch = useDispatch();
  const user = signInHooks.useSignedInUser();
  const { mapOptions, setMapOptions } = useContext(MapContext);

  useEffect(() => {
    if (distanceMeasurementStatus && user) {
      logEvent({
        type: EEventType.OpenMeasurementTool,
        params: {
          email: user.email
        }
      });
    }
  }, [distanceMeasurementStatus, user, logEvent, dispatch]);

  useEffect(() => {
    if (!selectedGrove && distanceMeasurementStatus) {
      dispatch(toggleDistanceMeasurementStatus());
    }
  }, [selectedGrove, distanceMeasurementStatus, dispatch]);

  useEffect(() => {
    if (selectedGrove?.geometry) {
      dispatch(setMeasureAreaBbox(selectedGrove.geometry));
    }

    return () => {
      dispatch(setMeasureAreaBbox(null));
    };
  }, [selectedGrove, dispatch]);

  const toggleRuler = () => {
    dispatch(toggleDistanceMeasurementStatus());
  };

  const refreshMeasurement = () => {
    dispatch(setMeasurePoints([]));
  };

  const openComparePage = useCallback(() => {
    if (!map || !setMapOptions) {
      return;
    }

    setMapOptions({
      ...mapOptions,
      center: map.getCenter(),
      zoom: map.getZoom()
    });

    navigate(`/map/${selectedFarm?.id || ''}/${selectedZone?.id || ''}/${selectedGrove?.id || ''}?surveyID=${selectedSurvey?.id}`);
    dispatch(setCompareIsOpened(true));
    dispatch(setIsSideBarExpanded(false));
  }, [map, setMapOptions, mapOptions, selectedFarm, selectedZone, selectedGrove, selectedSurvey, navigate, dispatch]);

  return (
    <Wrapper isOpened={distanceMeasurementStatus}>
      {!distanceMeasurementStatus && (
        <>
          <Entry className="menu-toggler">
            <IconButton active={false} icon={faWrench} iconSize="lg" iconType={EIconType.FontAwesome} />
          </Entry>
          <Menu className="menu">
            <Entry>
              <IconButton active={false} onClick={toggleRuler} icon={faRuler} iconSize="lg" iconType={EIconType.FontAwesome} />
            </Entry>
            <Seperator />
            <Entry>
              <IconButton
                active={false}
                icon={
                  <CompareIcon>
                    <SplitScreen />
                  </CompareIcon>
                }
                disabled={!selectedGrove || !selectedZone || !selectedFarm || !selectedSurvey}
                iconType={EIconType.Custom}
                onClick={() => openComparePage()}
                tooltip={t('shared.nav_bar.split_screen')}
              />
            </Entry>
          </Menu>
        </>
      )}
      {distanceMeasurementStatus && (
        <>
          <Entry className="menu-toggler">
            <IconButton active={false} onClick={toggleRuler} icon={faRuler} iconSize="lg" iconType={EIconType.FontAwesome} />
          </Entry>
          <Seperator />
          <DistanceMeasurement>
            <Column>
              <Row>
                <Label>{`${t(measurementType === EDistanceMeasurementType.Line ? 'shared.distance' : 'shared.area')} :`}</Label>
                <ControlIcon icon={faRetweet} size="lg" onClick={refreshMeasurement} />
              </Row>
              <Row>{measurementType === EDistanceMeasurementType.Line ? rulerUtils.formatDistance(distance, metricType, t) : rulerUtils.formatArea(distance, metricType)}</Row>
            </Column>
          </DistanceMeasurement>
        </>
      )}
    </Wrapper>
  );
};

export default GroveControls;
