import React, { useMemo, useState, useEffect } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import dateUtils from 'utils/date';
import dateFormats from 'models/date';
import missionHooks from 'hooks/mission.hooks';
import missionUtils from 'utils/mission';
import farmsHooks from 'hooks/farms.hooks';

import DataTable from 'atomicComponents/DataTable';
import MissionIcon from 'components/missions/MissionIcon';
import WeeklyTrapLegend from 'components/missions/WeeklyTrapLegend';
import SlideSwitch from 'atomicComponents/SlideSwitch';

import { IMission, IWeeklyTrapMonitoringConfig } from 'models/mission';
import { IFeed } from 'models/feed';

import { mfColors } from 'vars';

const Wrapper = styled.div`
  padding: 24px 24px 24px 48px;
  border-radius: 0 0 16px 16px;
`;

const Header = styled.div`
  margin: 16px 0 32px;
  width: 916px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const Filter = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

const TableWrapper = styled.div`
  max-height: 320px;
  overflow: auto;
  width: 1056px;
`;

const TableCell = styled.div`
  background: ${mfColors.white};
  padding: 4px;
  min-height: 32px;
  font-size: 12px;
  line-height: 24px;
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;

  &.left {
    text-align: left;
  }

  &.nowrap {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 195px;
    display: block;
  }

  &.pests-count {
    color: ${mfColors.white};
    font-family: MontserratBold;
  }
`;

const ActionsCell = styled.div`
  padding: 0 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const ROW_STYLE = {
  border: 'none',
  background: 'none',
  margin: '0 0 4px'
};

const HEADER_STYLE = {
  border: 'none',
  background: 'none',
  fontSize: '12px',
  margin: '0 0 8px'
};

const HEADER_COLUMN_STYLE = {
  textTransform: 'none',
  minHeight: '0px',
  padding: '0 2px'
};

const SLIDER_STYLE = {
  width: '320px'
};

interface IProps {
  mission: IMission;
  feed: IFeed[];
}

const WeeklyTrapMonitoringHeaderDetails = ({ mission, feed }: IProps) => {
  const [selectedTimestamp, setSelectedTimestamp] = useState<number | null>(null);
  const badgeColorsConfig = missionHooks.useMissionConfig(mission);
  const { selectedFarm } = farmsHooks.useSelectedFarmEntities();
  const { surveyGroves: groves } = farmsHooks.useFarmEntities();

  const { t } = useTranslation();

  const sortedFeed = useMemo(() => feed.slice().sort((a, b) => a.updatedAt - b.updatedAt), [feed]);

  const dateOptions = useMemo(() => {
    const sortedFeedDates = sortedFeed
      .map((entry) => dateUtils.getStartOfWeek(entry.updatedAt))
      .filter((value: number, index: number, self: number[]) => self.indexOf(value) === index);

    return sortedFeedDates.map((date) => ({
      value: date,
      text: t('missions.week_x_y', {
        x: dateUtils.formatDate(date, dateFormats.DATE_FORMAT_DD_MMM),
        y: dateUtils.formatDate(moment(date).add(7, 'days').valueOf(), dateFormats.DATE_FORMAT_DD_MMM)
      })
    }));
  }, [sortedFeed, t]);

  useEffect(() => {
    const lastFeedDate = sortedFeed.length > 0 ? sortedFeed.reverse()[0]?.updatedAt : null;
    setSelectedTimestamp(lastFeedDate ? dateUtils.getStartOfWeek(lastFeedDate) : null);
  }, [sortedFeed]);

  const feedToShow = useMemo(() => {
    const endDate = moment(selectedTimestamp).add(7, 'days').valueOf();
    if (!selectedTimestamp) return sortedFeed;

    return missionUtils.getGroupedWeeklyTrapFeedForPeriod(sortedFeed, selectedTimestamp, endDate);
  }, [sortedFeed, selectedTimestamp]);

  const tableConfig = useMemo(
    () => [
      {
        title: '',
        render: () => (
          <TableCell>
            <MissionIcon type={mission.type} size="24px" showBackground={false} />
          </TableCell>
        ),
        key: 'icon',
        sortable: false,
        width: '32px',
        style: {
          ...HEADER_COLUMN_STYLE,
          padding: '0px',
          margin: '0 2px 0 0'
        }
      },
      {
        title: t('missions.block'),
        render: (feed: IFeed) => {
          const grove = groves.find((grove) => grove.id === feed.groveID);
          return <TableCell className="left">{grove?.name || ''}</TableCell>;
        },
        key: 'name',
        sortable: false,
        width: '120px',
        style: HEADER_COLUMN_STYLE
      },
      {
        title: t('shared.date'),
        render: (feed: IFeed) => <TableCell>{dateUtils.formatDate(feed.updatedAt, dateFormats.DATE_FORMAT_DD_MMM)}</TableCell>,
        key: 'date',
        sortable: false,
        width: '120px',
        style: HEADER_COLUMN_STYLE
      },
      {
        title: t('missions.reported_by'),
        render: (feed: IFeed) => <TableCell>{feed.user.displayName}</TableCell>,
        key: 'reportedBy',
        sortable: false,
        width: '160px',
        style: HEADER_COLUMN_STYLE
      },
      {
        title: t('missions.pest_type'),
        render: (feed: IFeed) => <TableCell className="nowrap">{t(`missions.pests.${feed.data?.trapType || ''}`)}</TableCell>,
        key: 'pestsType',
        sortable: false,
        width: '200px',
        style: HEADER_COLUMN_STYLE
      },
      {
        title: t('missions.pests_counted'),
        render: (feed: IFeed) => {
          const colors = badgeColorsConfig?.[feed.data?.trapType || '']?.colors;
          const pestsNumber = feed.data?.pestsNumber || 0;
          const backgroundColor = colors?.find((entry) => (entry.min === null || entry.min <= pestsNumber) && (entry.max === null || entry.max > pestsNumber))?.color || '';
          return (
            <TableCell className="pests-count" style={{ backgroundColor }}>
              {pestsNumber}
            </TableCell>
          );
        },
        key: 'pestsCounted',
        sortable: false,
        width: '140px',
        style: HEADER_COLUMN_STYLE
      },
      {
        title: t('missions.changed_bait'),
        render: (feed: IFeed) => <TableCell>{t(feed.data?.changedBait ? 'shared.yes' : 'shared.no')}</TableCell>,
        key: 'changedBait',
        sortable: false,
        width: '100px',
        style: HEADER_COLUMN_STYLE
      },
      {
        title: '',
        render: (feed: IFeed) => {
          const grove = groves.find((grove) => grove.id === feed.groveID) || null;
          return (
            <ActionsCell>
              <WeeklyTrapLegend farm={selectedFarm} feed={feed} grove={grove} badgeColorsConfig={badgeColorsConfig as IWeeklyTrapMonitoringConfig} />
            </ActionsCell>
          );
        },
        key: 'actions',
        sortable: false,
        width: '24px',
        style: HEADER_COLUMN_STYLE
      }
    ],
    [t, selectedFarm, groves, mission.type, badgeColorsConfig]
  );

  const tableProps = useMemo(() => {
    const props = {
      config: tableConfig,
      data: feedToShow,
      showHeader: true,
      showFooter: false,
      rowsPerPage: 0,
      headerStyle: HEADER_STYLE,
      rowStyle: ROW_STYLE
    };
    return props;
  }, [tableConfig, feedToShow]);

  return (
    <Wrapper>
      <Header>
        <Filter>{!!selectedTimestamp && <SlideSwitch<number> style={SLIDER_STYLE} value={selectedTimestamp} options={dateOptions} onChange={setSelectedTimestamp} />}</Filter>
      </Header>
      <TableWrapper>
        <DataTable<IFeed> {...tableProps} />
      </TableWrapper>
    </Wrapper>
  );
};

export default WeeklyTrapMonitoringHeaderDetails;
