/* eslint-disable camelcase */
import numeral from 'numeral';
import { ICapacityStatistic, IFarmCapacityStatistic, TMappedStatistic } from 'models/statistic';
import { CUSTOMER_SCORES } from 'models/scores';
import { IReplantCalculatedStatistic, IReplantCount, IReplantRules, IReplantSegments } from 'models/replant';
import { IGrove } from 'models/grove';
import { EMeasurementSystem } from 'models/region';
import { indexBy, pick, sumObjectKeys } from 'utils/helpers';
import numberUtils from 'utils/numbers';
import { IRichItemFeature } from 'models/richItem';
import { IUnifiedGeojson } from 'hooks/farms.hooks';
import { ICapacitySegment } from 'services/data/replant';
import { IGroveSurveyStats } from 'models/stats';
import { TAnyReport } from 'models/report';

const REPLANT_RULES_KEYS = [
  '_0_replants_in_segment_up_to_length',
  '_1_replant_in_segment_up_to_length',
  '_2_replants_in_segment_up_to_length',
  '_3_replants_in_segment_up_to_length',
  '_5_or_more_replants_spacing_num'
];

const getGroveCustomerScoresTrees = (statistic: TMappedStatistic, groveID: string) => {
  const total = CUSTOMER_SCORES.reduce((sum: number, score: number) => {
    if (!statistic || !statistic[groveID] || !statistic[groveID][score]) {
      return sum;
    }
    return sum + statistic[groveID][score].total;
  }, 0);

  return total;
};

const getGroveCapacity = (capacityStatistic: TMappedStatistic, statistic: TMappedStatistic, groveID: string) => {
  const plantedTrees = capacityStatistic?.[groveID]?.statistic;

  if (!plantedTrees) return 0;

  const totalTrees = getGroveCustomerScoresTrees(statistic, groveID);

  return (Number(plantedTrees) / totalTrees) * 100;
};

const getFarmCapacity = (statistic: TMappedStatistic): number => {
  const groveIDs = Object.keys(statistic);

  return groveIDs.reduce((acc, groveID) => acc + Number(statistic[groveID].statistic), 0);
};

const getReplantSegments = (statistic: TMappedStatistic): IReplantSegments => {
  const groveIDs = Object.keys(statistic);

  return groveIDs.reduce((acc, groveID) => sumObjectKeys(acc, (statistic[groveID] as IReplantCalculatedStatistic).segments), {} as IReplantSegments);
};

const getReplantsCount = (statistic: TMappedStatistic): IReplantCount => {
  const groveIDs = Object.keys(statistic);

  return groveIDs.reduce((acc, groveID) => sumObjectKeys(acc, (statistic[groveID] as IReplantCalculatedStatistic).replants), {} as IReplantCount);
};

const getLastDefinedRule = (replantRules: IReplantRules) => {
  const { _0_replants_in_segment_up_to_length, _1_replant_in_segment_up_to_length, _2_replants_in_segment_up_to_length, _3_replants_in_segment_up_to_length } = replantRules;

  if (_3_replants_in_segment_up_to_length) return _3_replants_in_segment_up_to_length;

  if (_2_replants_in_segment_up_to_length) return _2_replants_in_segment_up_to_length;

  if (_1_replant_in_segment_up_to_length) return _1_replant_in_segment_up_to_length;

  return _0_replants_in_segment_up_to_length;
};

const calculateReplants = (replantRules: IReplantRules, length: number, segments: IReplantSegments, replants: IReplantCount) => {
  const {
    _0_replants_in_segment_up_to_length,
    _1_replant_in_segment_up_to_length,
    _2_replants_in_segment_up_to_length,
    _3_replants_in_segment_up_to_length,
    _5_or_more_replants_spacing_num
  } = replantRules;

  let numTrees = 0;

  if (length < _0_replants_in_segment_up_to_length) {
    numTrees = 0;

    segments._0_segments += 1;
    replants._0_replants += numTrees;
  }

  if (length > _0_replants_in_segment_up_to_length && length < _1_replant_in_segment_up_to_length) {
    numTrees = 1;

    segments._1_segment += 1;
    replants._1_replant += numTrees;
  }

  if (length > _1_replant_in_segment_up_to_length && length < _2_replants_in_segment_up_to_length) {
    numTrees = 2;

    segments._2_segments += 1;
    replants._2_replants += numTrees;
  }

  if (length > _2_replants_in_segment_up_to_length && length < _3_replants_in_segment_up_to_length) {
    numTrees = 3;

    segments._3_segments += 1;
    replants._3_replants += numTrees;
  }

  if (length > getLastDefinedRule(replantRules)) {
    const replantDivider = _5_or_more_replants_spacing_num;

    numTrees = Math.round(length / replantDivider);
    segments._5_or_more += 1;
    replants._5_or_more += numTrees;
  }

  return numTrees;
};

export const calculateReplantCapacityByRules = (
  statistic: ICapacityStatistic[],
  replantRules: IReplantRules,
  groves: IGrove[],
  groveSurveStats: IGroveSurveyStats[]
): TMappedStatistic => {
  const indexedStatistic = indexBy('groveID', statistic);
  const indexedStats = indexBy('groveID', groveSurveStats);

  const mappedStatistic = {};

  groves.forEach((grove) => {
    const statistic = indexedStatistic[grove.id];
    const isIncluded = indexedStats[grove.id]?.[0]?.isIncluded;

    if (!isIncluded) return;

    if (!statistic) return;
    const segments = {
      _0_segments: 0,
      _1_segment: 0,
      _2_segments: 0,
      _3_segments: 0,
      _4_segments: 0,
      _5_or_more: 0
    };
    const replants = {
      _0_replants: 0,
      _1_replant: 0,
      _2_replants: 0,
      _3_replants: 0,
      _4_replants: 0,
      _5_or_more: 0
    };
    let numTrees = 0;

    statistic.forEach((item: ICapacityStatistic) => {
      const trees = calculateReplants(replantRules, item.length, segments, replants);

      numTrees += trees;
    });

    mappedStatistic[grove.id] = {
      groveID: grove.id,
      statistic: numTrees,
      segments,
      replants
    };
  });

  return mappedStatistic;
};

const getSelectedStatistic = (statistic: TMappedStatistic | null, checkedGroves?: string[]) => {
  if (!statistic) return null;

  if (!checkedGroves) return statistic;

  return Object.keys(statistic).reduce((acc, next) => {
    if (checkedGroves?.includes(next)) {
      return {
        ...acc,
        [next]: statistic[next]
      };
    }

    return acc;
  }, {});
};

const convertRulesByMetricType = (replantRules: IReplantRules, metricType?: EMeasurementSystem) => {
  if (metricType === EMeasurementSystem.Metric) {
    return replantRules;
  }

  const convertedRules = pick(replantRules, REPLANT_RULES_KEYS);

  REPLANT_RULES_KEYS.forEach((key) => {
    const ruleLength = convertedRules[key];

    if (ruleLength) {
      convertedRules[key] = numberUtils.convertMeterToFeet(ruleLength);
    }
  });

  return {
    ...replantRules,
    ...convertedRules
  };
};
const filterReplantSegments = (features: IRichItemFeature[], segmentsToHide: string[]) => features.filter((item) => !segmentsToHide.includes(item.properties.id));

const getReplantGeojson = (capacityGeoJSON: IUnifiedGeojson, replantRules: IReplantRules, hiddenSegments: string[], newSegments?: ICapacitySegment[]) => {
  const { geojson, mapbox } = capacityGeoJSON;

  if (newSegments?.length) {
    const newSegmentsFeatures = newSegments.map((item) => ({
      geometry: item.lineString.geometry,
      type: 'LineString',
      properties: {
        length: item.length,
        id: item.id
      }
    }));

    geojson.features.push(...(newSegmentsFeatures as IRichItemFeature[]));
  }

  geojson.features = replantUtils.filterReplantSegments(geojson.features, hiddenSegments).map((item) => {
    const { length } = item.properties;
    const trees = replantUtils.calculateReplants(replantRules, length as number, {} as IReplantSegments, {} as IReplantCount);

    item.properties.capacity = trees;

    return item;
  });

  return { geojson, mapbox };
};

const formatToReplantStatistic = (data: IFarmCapacityStatistic, drawSegments: ICapacitySegment[]) => {
  const groveIDs = Object.keys(data);
  const indexedSegments = indexBy('groveID', drawSegments);

  const result = [] as ICapacityStatistic[];

  groveIDs.forEach((groveID) => {
    const segmentsLength = data[groveID];
    const groveDrawSegments = indexedSegments[groveID] as ICapacitySegment[];

    if (segmentsLength) {
      segmentsLength.forEach((leng) => {
        result.push({ groveID, length: leng, statistic: leng, segmentID: `${leng}` });
      });
    }

    if (groveDrawSegments) {
      groveDrawSegments.forEach((segment) => {
        const leng = +segment.lineString.properties.length;

        result.push({ groveID, length: leng, statistic: leng, segmentID: segment.id as string });
      });
    }
  });

  return result;
};

const formatReplantNumber = (number: number | string) => {
  if (Number.isNaN(number)) {
    return number.toString();
  }

  return numeral(number).format('0,0');
};

const resolveReplantReports = (replantReports: TAnyReport[], replantMissionReports?: TAnyReport[]) => {
  if (!replantReports || !replantMissionReports) return [];

  return replantReports.map((report) => {
    const groveReport = replantMissionReports.find((item) => item.groveID === report.groveID);

    if (groveReport) {
      return { ...report, ...groveReport };
    }

    return report;
  });
};

const replantUtils = {
  getGroveCapacity,
  getFarmCapacity,
  getReplantSegments,
  getReplantsCount,
  calculateReplantCapacityByRules,
  calculateReplants,
  getSelectedStatistic,
  getLastDefinedRule,
  convertRulesByMetricType,
  filterReplantSegments,
  getReplantGeojson,
  formatToReplantStatistic,
  formatReplantNumber,
  resolveReplantReports
};

export default replantUtils;
