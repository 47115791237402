import { EMissionStatus } from 'models/mission';

export const Missions = 'missions';
export const Map = 'map';

export enum Pages {
  Map = 'map',
  Missions = 'missions'
}

export type TMissionsLocationState = {
  checkedGroves?: string[];
};

export const farmLink = (page: Pages, farmID?: string): string => (farmID ? `/${page}/${farmID}` : `/${page}`);

export const missionsListLink = (farmID: string, status?: EMissionStatus): string => (status ? `/missions/${farmID}/status/${status}` : `/missions/${farmID}`);
