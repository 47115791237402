import React, { useCallback, useMemo, useState, useEffect, useRef } from 'react';
import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { faChartColumn, faLocationDot, faRocket, faBell, faBars } from '@fortawesome/pro-solid-svg-icons';
import { useLocation, matchPath } from 'react-router-dom';
import { mfColors, mfSizes } from 'vars';
import NavIcon from 'atomicComponents/NavIcon';
import IconButton, { EIconType } from 'atomicComponents/IconButton';
import signInHooks from 'hooks/signIn.hooks';
import farmsHooks from 'hooks/farms.hooks';
import { useAnalytics } from 'hooks/analytics.hooks';
import { useDispatch, useSelector } from 'react-redux';
import { farmLink, Pages } from 'utils/routes';
import { EEventType } from 'models/analytics';
import {
  isShowNotificationsSelector,
  evidenceParamsSelector,
  setIsShowNotifications,
  setIsSideBarExpanded,
  isSideBarExpandedSelector,
  isUserMenuShownSelector,
  setIsUserMenuShown,
  isShowBIDashboardSelector,
  setIsShowBIDashboard,
  setEvidenceParams,
  setCompareIsOpened
} from 'redux/appStateSlice';
import { ExpandedNavBarItemOutAnimation as navBarItemOutAnimation, ExpandedNavBarItemInAnimation as navBarItemInAnimation } from 'atomicComponents/animations';

const DEFAULT_TOP_POSITION = -40;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  height: 100%;
  width: ${mfSizes.staticMenuWidth};
  background-color: ${mfColors.navBarBackground};
  position: relative;
  z-index: 6;
`;

const StaticMenuPart = styled.div`
  flex: 0 0 ${mfSizes.staticMenuWidth};
  width: ${mfSizes.staticMenuWidth};
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
`;

const Entry = styled.div`
  margin: 15px 0;
  position: relative;
`;

const TopEntries = styled.div`
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
`;

interface ISelectedProps {
  top: number;
  isExpanded: boolean;
}
/* eslint-disable */
const SelectedBorder = styled.div`
  position: absolute;
  top: ${({ top }: ISelectedProps) => (top ? `${top}px` : '0')};
  width: 40px;
  height: 40px;
  border-radius: 8px;
  border: 2px solid;
  border-color: #31456a;
  transition: top 0.5s ease-in-out;
  ${({ isExpanded }: ISelectedProps) => (!isExpanded ? css`animation: ${navBarItemOutAnimation} 0.25s ease-in-out forwards;` : css`animation: ${navBarItemInAnimation} 0.25s ease-in-out forwards;`)}
`;
/* eslint-enable */
const REPORTS_SCREEN_CHECK_REGEX = /^\/$/;

const NavBar = () => {
  const { t } = useTranslation();
  const user = signInHooks.useSignedInUser();
  const { selectedFarm } = farmsHooks.useSelectedFarmEntities();
  const { logEvent } = useAnalytics();
  const location = useLocation();
  const isShowNotifications = useSelector(isShowNotificationsSelector);
  const isSideBarExpanded = useSelector(isSideBarExpandedSelector);
  const isUserMenuShown = useSelector(isUserMenuShownSelector);
  const isShowBIDashboard = useSelector(isShowBIDashboardSelector);
  const evidenceParams = useSelector(evidenceParamsSelector);
  const dispatch = useDispatch();
  const locationUrl = useMemo(() => farmLink(Pages.Map, selectedFarm?.id), [selectedFarm]);
  const missionsUrl = useMemo(() => farmLink(Pages.Missions, selectedFarm?.id), [selectedFarm]);
  const menuElement = useRef<HTMLDivElement>(null);
  const locationElement = useRef<HTMLDivElement>(null);
  const biDashboardElement = useRef<HTMLDivElement>(null);
  const missionsElement = useRef<HTMLDivElement>(null);
  const notificationsElement = useRef<HTMLDivElement>(null);
  const selectedTopElement = useRef<HTMLDivElement>(null);
  const [selectedElementTopPosition, setSelectedElementTopPosition] = useState<number>(DEFAULT_TOP_POSITION);

  useEffect(() => {
    if (isShowNotifications && user) {
      logEvent({
        type: EEventType.OpenNotifications,
        params: {
          email: user.email
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isShowNotifications]);

  const isNavActive = useCallback(
    (url: string | null) => {
      if (url) {
        return !isShowNotifications && !isUserMenuShown && location.pathname.indexOf(url) === 0;
      }

      return !isShowNotifications && !isUserMenuShown && REPORTS_SCREEN_CHECK_REGEX.test(location.pathname);
    },
    [location, isShowNotifications, isUserMenuShown]
  );

  useEffect(() => {
    if (isUserMenuShown) {
      setTopPosition(menuElement);
    } else if (isShowNotifications) {
      setTopPosition(notificationsElement);
    } else if (isShowBIDashboard) {
      setTopPosition(biDashboardElement);
    } else if (isNavActive(locationUrl)) {
      setTopPosition(locationElement);
    } else if (isNavActive(missionsUrl)) {
      setTopPosition(missionsElement);
    }
  }, [isNavActive, locationUrl, missionsUrl, isUserMenuShown, isShowNotifications, isShowBIDashboard]);

  const handleNavClicks = (url: string, element) => {
    const activePath = matchPath({ path: location.pathname, end: true }, url);
    setTopPosition(element);
    dispatch(setCompareIsOpened(false));

    if (isShowNotifications) {
      dispatch(setIsShowNotifications(false));

      return;
    }

    if (isShowBIDashboard) {
      dispatch(setIsShowBIDashboard(false));

      return;
    }

    if (evidenceParams) {
      dispatch(setEvidenceParams(null));

      return;
    }

    if (isUserMenuShown) {
      dispatch(setIsUserMenuShown(false));

      return;
    }

    dispatch(setIsSideBarExpanded(true));

    if (activePath) {
      dispatch(setIsSideBarExpanded(false));
    }

    if (!isSideBarExpanded && activePath) {
      dispatch(setIsSideBarExpanded(true));
    }
  };

  const handleBIDashboard = () => {
    if (!isSideBarExpanded) {
      dispatch(setIsSideBarExpanded(true));
    }

    dispatch(setIsShowBIDashboard(!isShowBIDashboard));
    dispatch(setEvidenceParams(null));
    dispatch(setIsShowNotifications(false));
    dispatch(setIsUserMenuShown(false));
    dispatch(setCompareIsOpened(false));
  };

  const handleNotifications = () => {
    if (!isSideBarExpanded) {
      dispatch(setIsSideBarExpanded(true));
    }

    dispatch(setIsShowBIDashboard(false));
    dispatch(setIsUserMenuShown(false));
    dispatch(setIsShowNotifications(!isShowNotifications));
    dispatch(setCompareIsOpened(false));
  };

  const userMenuClickHandler = () => {
    if (!isSideBarExpanded) {
      dispatch(setIsSideBarExpanded(true));
    }

    dispatch(setIsShowBIDashboard(false));
    dispatch(setIsUserMenuShown(!isUserMenuShown));
    dispatch(setIsShowNotifications(false));
    dispatch(setCompareIsOpened(false));
  };

  const setTopPosition = (element) => {
    if (!element || !element.current || !selectedTopElement || !selectedTopElement.current) {
      return setSelectedElementTopPosition(DEFAULT_TOP_POSITION);
    }

    const clickTopPosition = element.current.getBoundingClientRect().top;
    const activeTop = selectedTopElement?.current?.getBoundingClientRect().top;
    if (activeTop === clickTopPosition) {
      return null;
    }

    return setSelectedElementTopPosition(clickTopPosition);
  };

  return (
    <Wrapper>
      <StaticMenuPart>
        <SelectedBorder ref={selectedTopElement} top={selectedElementTopPosition} isExpanded={isSideBarExpanded} />
        {user && (
          <TopEntries>
            <Entry ref={menuElement}>
              <IconButton active={isUserMenuShown} icon={faBars} iconType={EIconType.FontAwesome} iconSize="lg" onClick={() => userMenuClickHandler()} />
            </Entry>
          </TopEntries>
        )}
        <Entry ref={locationElement} onClick={() => handleNavClicks(locationUrl, locationElement)}>
          <NavIcon icon={faLocationDot} iconSize="lg" url={locationUrl} active={isNavActive(locationUrl)} tooltip={t('shared.nav_bar.explore')} />
        </Entry>
        <Entry ref={biDashboardElement} onClick={() => handleBIDashboard()}>
          <IconButton active={isShowBIDashboard} icon={faChartColumn} iconType={EIconType.FontAwesome} iconSize="lg" tooltip={t('shared.nav_bar.dashboard')} />
        </Entry>
        <Entry ref={missionsElement} onClick={() => handleNavClicks(missionsUrl, missionsElement)}>
          <NavIcon icon={faRocket} iconSize="lg" url={missionsUrl} active={isNavActive(missionsUrl)} tooltip={t('shared.nav_bar.missions')} />
        </Entry>
        <Entry ref={notificationsElement}>
          <IconButton
            active={isShowNotifications}
            icon={faBell}
            iconType={EIconType.FontAwesome}
            iconSize="lg"
            tooltip={t('shared.nav_bar.notifications')}
            onClick={() => handleNotifications()}
          />
        </Entry>
      </StaticMenuPart>
    </Wrapper>
  );
};

export default NavBar;
