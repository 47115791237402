import React, { useEffect, useCallback, useRef, RefObject, useState } from 'react';

import EKeyCode from 'models/keyboard';

const scriptSet = new Set();

export const useImportScript = (resourceUrl: string, callback: (result: boolean) => void) => {
  useEffect(() => {
    if (scriptSet.has(resourceUrl)) {
      callback(true);

      return;
    }
    const script = document.createElement('script');
    script.src = resourceUrl;
    script.onload = () => {
      scriptSet.add(resourceUrl);
      callback(true);
    };
    document.body.appendChild(script);
  }, [resourceUrl, callback]);
};

export const useArrowNavigationListener = <T extends HTMLDivElement>(
  containerRef: RefObject<HTMLDivElement | null>,
  childSelector: string,
  rangeOutSideHandler?: (position: -1 | 1) => void
): ((e: React.KeyboardEvent<T>) => void) => {
  const listener = useCallback(
    (e: React.KeyboardEvent<T>) => {
      if (e.keyCode === EKeyCode.ArrowUp || e.keyCode === EKeyCode.ArrowDown) {
        e.preventDefault();
        const eventTarget = e.target;
        const children = (containerRef.current ? [...containerRef.current.querySelectorAll(childSelector)] : []) as T[];
        const targetIndex = children.indexOf(eventTarget as T);
        if (targetIndex >= 0 && targetIndex <= children.length - 1) {
          if (targetIndex === 0 && e.keyCode === EKeyCode.ArrowUp) {
            rangeOutSideHandler && rangeOutSideHandler(-1);
          } else if (targetIndex === children.length - 1 && e.keyCode === EKeyCode.ArrowDown) {
            rangeOutSideHandler && rangeOutSideHandler(1);
          } else {
            children[e.keyCode === EKeyCode.ArrowDown ? targetIndex + 1 : targetIndex - 1]?.focus();
          }
        }
      }
    },
    [containerRef, childSelector, rangeOutSideHandler]
  );
  return listener;
};

export const useIsMounted = (): boolean => {
  const isMountedRef = useRef<boolean>(false);
  useEffect(() => {
    isMountedRef.current = true;
  }, []);
  return isMountedRef.current;
};

export const useThrottle = <T>(value: T, duration: number) => {
  const [throttledValue, setThrottledValue] = useState<T>(value);
  useEffect(() => {
    const handler = setTimeout(() => {
      setThrottledValue(value);
    }, duration);

    return () => {
      clearTimeout(handler);
    };
  }, [value, duration]);

  return throttledValue;
};
