export enum EDashboardType {
  TreeAnalytics = 'TreeAnalytics',
  Sisense = 'Sisense',
  HLBPreassigned = 'HLBPreassigned',
  HLBNotPreassigned = 'HLBNotPreassigned',
  MarkAsCounted = 'MarkAsCounted'
}

export interface ICustomerDashboard {
  id: string;
  name: string;
  filters: string[];
}

export const CUSTOMER_SPECIFIC_DASHBOARDS: Map<string, ICustomerDashboard[]> = new Map()
  .set('-MLNbTWiX3OUSBTl_UBL', [{ id: '6316e413adbca000378bf6b1', name: 'Fruit', filters: ['farmName'] }])
  .set('-LyxJUeNlyl-JQfa3VE', [{ id: '5f86b948922f65002dfcdf90', name: 'KPIs', filters: [] }]);
