import React, { useState, useCallback, useMemo } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { faCheck } from '@fortawesome/pro-solid-svg-icons';

import Button from 'atomicComponents/Button';
import Checkbox from 'atomicComponents/Checkbox';

import missionUtils from 'utils/mission';

import { EMissionKpi, EMissionType } from 'models/mission';
import { CUSTOMER_SCORES } from 'models/scores';

import { mfColors } from 'vars';

interface IProps {
  onCreateMission: (kpis: EMissionKpi[], kpiScores?: number[]) => void;
  onBack: () => void;
}

const Wrapper = styled.div`
  width: 100%;
  min-height: 640px;
  display: flex;
  flex-direction: column;
`;

const Title = styled.div`
  color: ${mfColors.grey};
  border-bottom: 1px solid ${mfColors.grey};
  padding: 4px 0;
  margin: 16px 0;
`;

const Subtitle = styled.div`
  font-family: MontserratBold;
`;

const CheckboxesWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`;

const CheckboxesColumn = styled.div`
  margin: 24px 0 0 32px;

  .content {
    border-left: 1px solid ${mfColors.grey};
    padding: 4px 16px;
  }
`;

const checkboxRowStyles = `
  display: flex;
  flex-direction: row;
  align-items: center;

  .label {
    line-height: 24px;
    margin: 0 0 0 8px;
  }

  &:last-child {
    margin: 0;
  }
`;

const CheckboxRow = styled.div`
  margin: 0 0 24px;
  ${checkboxRowStyles}
`;

const ScoreCheckboxRow = styled.div`
  margin: 0 0 12px;
  ${checkboxRowStyles}
`;

const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
  flex-grow: 1;
`;

const BUTTON_STYLE = {
  height: '40px'
};

const BACK_BUTTON_STYLE = {
  ...BUTTON_STYLE,
  background: 'none',
  border: 'none',
  color: mfColors.blue
};

const FIRST_COLUMN_KPI = [EMissionKpi.GroveArea, EMissionKpi.NumberOfTrees, EMissionKpi.HealthScore, EMissionKpi.NumberOfScores];

const SECOND_COLUMN_KPI = [EMissionKpi.CanopyArea, EMissionKpi.TreeVolume, EMissionKpi.Variety, EMissionKpi.PlantYear, EMissionKpi.IrrigationMethod];

const ConfigureGeneralMission = ({ onCreateMission, onBack }: IProps): JSX.Element => {
  const { t } = useTranslation();
  const [kpis, setKpis] = useState<EMissionKpi[]>([EMissionKpi.GroveArea, EMissionKpi.NumberOfTrees]);
  const [kpiScores, setKpiScores] = useState<number[]>([2, 3, 4, 5, 6]);
  const missionName = useMemo(() => t(missionUtils.getMissionTypeKey(EMissionType.General)), [t]);

  const toggleKpi = useCallback((kpi: EMissionKpi) => {
    setKpis((prev) => {
      if (prev.includes(kpi)) return prev.filter((entry) => entry !== kpi);
      return [...prev, kpi];
    });
  }, []);

  const toggleKpiScore = useCallback((score: number) => {
    setKpiScores((prev) => {
      if (prev.includes(score)) return prev.filter((entry) => entry !== score);
      return [...prev, score];
    });
  }, []);

  const handleCreateMission = useCallback(() => {
    let kpisToSubmit: EMissionKpi[] = kpis;
    if (!kpisToSubmit.length) {
      kpisToSubmit = [EMissionKpi.GroveArea, EMissionKpi.NumberOfTrees];
    } else if (kpisToSubmit.length === 1) {
      if (kpisToSubmit.includes(EMissionKpi.GroveArea)) {
        kpisToSubmit.push(EMissionKpi.NumberOfTrees);
      } else {
        kpisToSubmit.push(EMissionKpi.GroveArea);
      }
    }

    onCreateMission(kpis, kpiScores);
  }, [onCreateMission, kpis, kpiScores]);

  return (
    <Wrapper>
      <Title>{t('missions.general_mission_avanced_options')}</Title>
      <Subtitle>{t('missions.for_mission_planning_choose_two_parameters')}</Subtitle>
      <CheckboxesWrapper>
        <CheckboxesColumn>
          <div className="content">
            {FIRST_COLUMN_KPI.map((kpi) => (
              <CheckboxRow key={kpi}>
                <Checkbox isDisabled={!kpis.includes(kpi) && kpis.length > 1} checked={kpis.includes(kpi)} onCheckedChange={() => toggleKpi(kpi)} icon={faCheck} />
                <span className="label">{t(missionUtils.getMissionMetricDescriptionKey(kpi))}</span>
              </CheckboxRow>
            ))}
          </div>
          {kpis.includes(EMissionKpi.NumberOfScores) && (
            <CheckboxesColumn>
              <div className="content">
                {CUSTOMER_SCORES.map((score) => (
                  <ScoreCheckboxRow key={score}>
                    <Checkbox checked={kpiScores.includes(score)} onCheckedChange={() => toggleKpiScore(score)} icon={faCheck} />
                    <span className="label">{score}</span>
                  </ScoreCheckboxRow>
                ))}
              </div>
            </CheckboxesColumn>
          )}
        </CheckboxesColumn>
        <CheckboxesColumn>
          <div className="content">
            {SECOND_COLUMN_KPI.map((kpi) => (
              <CheckboxRow key={kpi}>
                <Checkbox isDisabled={!kpis.includes(kpi) && kpis.length > 1} checked={kpis.includes(kpi)} onCheckedChange={() => toggleKpi(kpi)} icon={faCheck} />
                <span className="label">{t(missionUtils.getMissionMetricDescriptionKey(kpi))}</span>
              </CheckboxRow>
            ))}
          </div>
        </CheckboxesColumn>
      </CheckboxesWrapper>
      <ButtonsWrapper>
        <Button style={BACK_BUTTON_STYLE} onClick={onBack} text={t('missions.back')} />
        <Button style={BUTTON_STYLE} onClick={handleCreateMission} text={t('missions.create_type_mission', { missionName })} />
      </ButtonsWrapper>
    </Wrapper>
  );
};

export default ConfigureGeneralMission;
