import { useCallback } from 'react';
import { ref, get } from 'firebase/database';
import db from 'services/data/providers/firebase';
import useLogger, { EStorageType } from 'hooks/logger.hooks';

const useFetchScores = () => {
  const { logError } = useLogger();
  return useCallback(
    async (farmID, treeID) => {
      const path = `/trees/${farmID}/${treeID}/scores`;
      const scoresRef = ref(db.database, path);

      try {
        const res = await get(scoresRef).then((snap) => snap.val());
        return res;
      } catch (e) {
        logError(e as Error, path, EStorageType.Firebase);
        return Promise.resolve(null);
      }
    },
    [logError]
  );
};

const treesService = {
  useFetchScores
};

export default treesService;
