import { MEASURE_POINTS_LAYER } from 'constants/map';
import React, { useState, useEffect, useRef, useMemo, createContext, useContext } from 'react';
import * as turf from '@turf/turf';
import mapboxgl from 'mapbox-gl';
import styled from 'styled-components';
import { point, lineString, lineDistance, Position } from '@turf/turf';
import { addZonesLayers, addGrovesLayers } from 'services/util/mapFarm';
import { addHotspotLayers, addHotspotPolygonLayers } from 'services/util/mapHotspot';
import { addBulletinLayers } from 'services/util/mapBulletin';
import { addFeedLayers } from 'services/util/mapFeed';
import { addPoiLayers } from 'services/util/mapPoi';
import { initMeasurementLayers, removeEventHandlers, setMapMeasurementSource, setMeasurementClickHandlers, removeMeasurementLayers } from 'services/util/mapMeasurement';
import { useSelector, useDispatch } from 'react-redux';
import {
  getDistanceMeasurePoints,
  getDistanceMeasurementStatus,
  setDistanceMeasurementValue,
  setDistanceMeasurementType,
  ILineString,
  EDistanceMeasurementType,
  IMeasurePoint,
  addMeasurePoint,
  setMeasurePoints,
  removeMeasurePoint,
  getDistanceMeasurementPolygonSelector,
  getIsPolygonSupported,
  getMeasureAreaBbox
} from 'redux/distanceMeasurementSlice';
import appConfig from 'config/config.json';
import { mfColors } from 'vars';
import { IMapState } from 'models/map';
import { addReplantClustersLayers } from 'services/util/mapReplants';

mapboxgl.accessToken = appConfig.mapBoxToken;

const MAP_CONTROLS_ZINDEX = 2;

const Overlay = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: ${MAP_CONTROLS_ZINDEX};
  background-color: ${mfColors.white};
  opacity: 0.5;
`;

const OverlayMessage = styled.div`
  position: absolute;
  border-radius: 8px;
  padding: 24px;
  z-index: ${MAP_CONTROLS_ZINDEX + 1};
  font-size: 16px;
  background-color: ${mfColors.white};
  box-shadow: 0px -7px 10px rgba(255, 255, 255, 0.5), 0px 8px 10px rgba(0, 0, 0, 0.75);
  left: 0;
  right: 0;
  bottom: 140px;
  margin: 0 auto;
  width: 400px;
`;

const ControlsWrapper = styled.div`
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  bottom: 32px;
  z-index: ${MAP_CONTROLS_ZINDEX};
  pointer-events: none;
`;

export enum ELegendPosition {
  TopLeft,
  TopRight
}

const getLegendPosition = (position: ELegendPosition, isFullScreen: boolean): { top?: string; bottom?: string; left?: string; right?: string } => {
  switch (position) {
    case ELegendPosition.TopLeft:
      return {
        top: '12px',
        left: isFullScreen ? '36px' : '12px',
        bottom: 'auto',
        right: 'auto'
      };
    case ELegendPosition.TopRight:
      return {
        top: '12px',
        left: 'auto',
        bottom: 'auto',
        right: isFullScreen ? '36px' : '12px'
      };
    default:
      return {};
  }
};

const LegendWrapper = styled.div`
  position: absolute;
  bottom: 32px;
  z-index: ${MAP_CONTROLS_ZINDEX};
`;

const SidebarControlsWrapper = styled.div`
  position: absolute;
  right: 12px;
  bottom: 32px;
  z-index: ${MAP_CONTROLS_ZINDEX};
`;

const WeatherWrapper = styled.div`
  position: absolute;
  top: 12px;
  right: 12px;
  z-index: ${MAP_CONTROLS_ZINDEX};
`;

const MissingDataMessageWrapper = styled.div`
  pointer-events: none;
  position: absolute;
  bottom: 32px;
  left: 0;
  right: 0;
  margin: 0 auto;
  z-index: ${MAP_CONTROLS_ZINDEX};
`;

const GroveControlsWrapper = styled.div`
  position: absolute;
  bottom: 32px;
  left: 36px;
  margin: 0 auto;
  z-index: ${MAP_CONTROLS_ZINDEX};
`;

interface IWrapperProps {
  isFullScreen: boolean;
  legendPosition: ELegendPosition;
}

const Wrapper = styled.div<IWrapperProps>`
  width: 100%;
  height: 100%;
  position: relative;
`;

const ZoomControlsWrapper = styled.div`
  position: absolute;
  bottom: 32px;
  right: 12px;
  margin: 0px auto;
  z-index: 2;
`;

type TOptions = Pick<mapboxgl.MapboxOptions, 'bounds' | 'center' | 'zoom'> & { zoomDuration?: number };

interface IProps {
  options?: TOptions;
  onMapInit?: (map: mapboxgl.Map) => void;
  overlayMessage?: JSX.Element | string;
  weather?: JSX.Element;
  controls?: JSX.Element;
  sidebarControls?: JSX.Element;
  legend?: JSX.Element;
  isFullScreen?: boolean;
  legendPosition?: ELegendPosition;
  missingDataMessage?: JSX.Element;
  groveControls?: JSX.Element;
  zoomControls?: JSX.Element;
}

const MAPBOX_STYLE = 'mapbox://styles/mapbox/satellite-v9';

interface IMapContext {
  activeMap: mapboxgl.Map | null;
  setActiveMap: (map: mapboxgl.Map) => void;
  mapState: IMapState | null;
  setMapState: (state: IMapState | null) => void;
  setMapOptions: (state: TOptions) => void;
  mapOptions: TOptions | null;
}

export const MapContext = createContext<IMapContext>({
  activeMap: null,
  setActiveMap: (map: mapboxgl.Map) => null,
  setMapOptions: () => null,
  mapOptions: null,
  mapState: null,
  setMapState: (state: any) => null
});

const Map = ({
  options,
  onMapInit,
  overlayMessage,
  controls,
  legend,
  sidebarControls,
  weather,
  isFullScreen = true,
  groveControls,
  legendPosition = ELegendPosition.TopLeft,
  missingDataMessage,
  zoomControls
}: IProps) => {
  const mapContainer = useRef<HTMLDivElement>(null);
  const [map, setMap] = useState<mapboxgl.Map | null>(null);
  const distanceMeasurementStatus = useSelector(getDistanceMeasurementStatus);
  const measurePoints = useSelector(getDistanceMeasurePoints);
  const distanceMeasurementPolygon = useSelector(getDistanceMeasurementPolygonSelector);
  const measureAreaBbox = useSelector(getMeasureAreaBbox);
  const isPolygonSupported = useSelector(getIsPolygonSupported);
  const dispatch = useDispatch();
  const legendPositionStyle = useMemo(() => getLegendPosition(legendPosition, isFullScreen), [legendPosition, isFullScreen]);
  const { setActiveMap, mapOptions: globalMapOptions } = useContext(MapContext);
  const mapOptions = options || globalMapOptions;

  useEffect(() => {
    if (mapContainer.current) {
      const mapbox = new mapboxgl.Map({
        container: mapContainer.current,
        style: MAPBOX_STYLE
      });
      mapbox.on('load', () => {
        addZonesLayers(mapbox);
        addReplantClustersLayers(mapbox);
        addGrovesLayers(mapbox);
        addHotspotLayers(mapbox);
        addBulletinLayers(mapbox);
        addPoiLayers(mapbox);
        addHotspotPolygonLayers(mapbox);
        addFeedLayers(mapbox, () => {
          setMap(mapbox);
        });
      });
    }

    return () => {
      setMap(null);
    };
  }, [mapContainer]);
  useEffect(() => {
    if (map && mapOptions?.center && mapOptions?.zoom) {
      map.setCenter(mapOptions.center);
      map.setZoom(mapOptions.zoom);
      return;
    }
    if (map && mapOptions?.bounds) {
      if (mapOptions?.zoomDuration !== undefined) {
        map.fitBounds(mapOptions.bounds, { duration: mapOptions.zoomDuration });
      } else {
        map.fitBounds(mapOptions.bounds);
      }
    }
  }, [map, mapOptions]);

  useEffect(() => {
    if (map && onMapInit) {
      onMapInit(map);
    }
  }, [map, onMapInit]);

  useEffect(() => {
    if (map && !onMapInit) {
      setActiveMap(map);
    }
  }, [map, onMapInit, setActiveMap]);

  useEffect(() => {
    if (map) {
      let newSource: Array<IMeasurePoint | ILineString> = [...measurePoints];

      if (measurePoints.length > 1) {
        const coordinates = measurePoints.filter((measurePoint) => measurePoint.geometry.type === 'Point').map((measurePoint) => measurePoint.geometry.coordinates) as Position[];
        const linestring = lineString(coordinates) as ILineString;
        newSource = [...measurePoints, linestring];

        dispatch(setDistanceMeasurementType(EDistanceMeasurementType.Line));
        dispatch(setDistanceMeasurementValue(lineDistance(linestring)));

        if (distanceMeasurementPolygon) {
          const area = turf.area(distanceMeasurementPolygon);
          dispatch(setDistanceMeasurementValue(area));
          dispatch(setDistanceMeasurementType(EDistanceMeasurementType.Area));
        }
      } else {
        dispatch(setDistanceMeasurementValue(0));
      }

      setMapMeasurementSource(map, newSource);
    }
  }, [map, measurePoints, distanceMeasurementPolygon, dispatch]);

  useEffect(() => {
    const setCursorPointer = function () {
      if (!map) {
        return;
      }

      map.getCanvas().style.cursor = 'pointer';
    };

    const setCrosshairCursor = function () {
      if (!map) {
        return;
      }

      map.getCanvas().style.cursor = 'crosshair';
    };

    const clickHandler = function (event?: any) {
      if (!map) {
        return;
      }

      const allFeatures = map.queryRenderedFeatures(undefined, { layers: [MEASURE_POINTS_LAYER] });

      // prettier-ignore
      if (
        allFeatures.length > 2
        && allFeatures.find((feature, index, self) => self.filter((entry) => entry?.properties?.coordinates.toString() === feature?.properties?.coordinates.toString()).length > 1)
      ) {
        return;
      }

      const features = map.queryRenderedFeatures(event.point, {
        layers: [MEASURE_POINTS_LAYER]
      });
      const pointFeature = features[0];
      const featureID = features[0]?.properties?.id;

      if (featureID && !isPolygonSupported) {
        dispatch(removeMeasurePoint(featureID));

        return;
      }

      const coordinates = [event.lngLat.lng, event.lngLat.lat];

      let featurePoint = point(coordinates, {
        id: String(new Date().getTime()),
        coordinates
      });

      if (pointFeature && pointFeature.geometry && pointFeature.geometry.type === 'Point' && pointFeature.properties?.coordinates) {
        const closureCoordinates = JSON.parse(pointFeature.properties.coordinates);
        featurePoint = point(closureCoordinates, {
          id: String(new Date().getTime()),
          coordinates: closureCoordinates
        });
      }

      dispatch(addMeasurePoint(featurePoint));
    };

    const deleteHandler = function (event?: any) {
      const { features } = event;
      const featureID = features[0].properties.id;

      dispatch(removeMeasurePoint(featureID));
    };
    if (map && distanceMeasurementStatus) {
      initMeasurementLayers(map, measureAreaBbox);
      setMeasurementClickHandlers(map, clickHandler, deleteHandler, setCursorPointer, setCrosshairCursor);
    }

    return () => {
      if (map) {
        removeEventHandlers(map, clickHandler, deleteHandler, setCursorPointer, setCrosshairCursor);
        removeMeasurementLayers(map);
        dispatch(setMeasurePoints([]));
        setCursorPointer();
      }
    };
  }, [map, distanceMeasurementStatus, dispatch, measureAreaBbox, isPolygonSupported]);

  return (
    <Wrapper legendPosition={legendPosition} isFullScreen={isFullScreen} style={{ height: '100%', width: '100%' }} ref={mapContainer}>
      {controls && <ControlsWrapper>{controls}</ControlsWrapper>}
      {sidebarControls && <SidebarControlsWrapper>{sidebarControls}</SidebarControlsWrapper>}
      {legend && <LegendWrapper style={legendPositionStyle}>{legend}</LegendWrapper>}
      {weather && <WeatherWrapper>{weather}</WeatherWrapper>}
      {overlayMessage && (
        <>
          <Overlay />
          <OverlayMessage>{overlayMessage}</OverlayMessage>
        </>
      )}
      {missingDataMessage && <MissingDataMessageWrapper>{missingDataMessage}</MissingDataMessageWrapper>}
      {groveControls && <GroveControlsWrapper>{groveControls}</GroveControlsWrapper>}
      {zoomControls && <ZoomControlsWrapper>{zoomControls}</ZoomControlsWrapper>}
    </Wrapper>
  );
};

export default Map;
