import React, { useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { DEFAULT_REPORT_TRESHOLDS, EReportType } from 'models/report';
import { Button } from 'atomicComponents';
import { useDispatch, useSelector } from 'react-redux';
import { getSelectedFarm, setSelectedFarm } from 'redux/farm/farmSlice';
import { DedicatedVisitIcon, TreeToppingIcon, TreeHedgingIcon, WeedIcon, BindweedsTreatmentIcon, TargetedScoutingIcon } from 'assets/images';
import { mfColors } from 'vars';
import FormSelect, { EPosition } from 'atomicComponents/FormSelect';
import { regionMeasurementSystemSelector } from 'redux/region/regionSlice';
import { EMeasurementSystem } from 'models/region';
import { useUpdateFarmBulletinSettings } from 'services/data/farms';
import { getFarmBulletinThresholds, getThresholdOptionsConfigs, getThresholdRangesConfigs } from 'redux/bulletin/bulletinSlice';
import numberUtils from 'utils/numbers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSeedling } from '@fortawesome/pro-solid-svg-icons';
import MonthsList from './MonthsList';

const buttonStyles = {
  fontSize: '18px',
  padding: '8px 12px',
  width: '150px',
  height: '39px',
  borderRadius: '6px'
};

const secondaryButtonStyles = {
  ...buttonStyles,
  border: `1px solid ${mfColors.darkGrey}`,
  color: mfColors.darkGrey,
  background: mfColors.white
};

const setupButtonStyles = {
  color: mfColors.lightBlue,
  background: mfColors.white,
  height: '39px',
  padding: '8px 0',
  border: 'none',
  textDecoration: 'underline',
  textTransform: 'none'
};

const inputStyles = {
  fontFamily: 'MontserratBold'
};

const Wrapper = styled.div``;

const Header = styled.div`
  display: flex;
  margin-bottom: 32px;
`;

const HeaderIcon = styled.div`
  display: flex;
  width: 44px;
  height: 44px;
  justify-content: center;
  align-items: center;
  background: ${mfColors.missionFillColor};
  border: 1px solid ${mfColors.missionBorderColor};
  color: ${mfColors.missionTextColor};
  border-radius: 50%;
  padding: 0 8px;
`;

const HeaderText = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  font-family: MontserratBold;
  align-self: center;
  font-size: 18px;
`;
const Title = styled.div`
  display: flex;
  text-transform: capitalize;
`;

const Description = styled.div`
  margin-top: 4px;
`;

const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 54px;
  margin-bottom: 18px;
  border-bottom: 1px solid ${mfColors.black40};
  padding-bottom: 32px;
`;

const DateWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const DateTitle = styled.div`
  display: flex;
  font-size: 18px;
  margin-bottom: 16px;
`;

const DatePicker = styled.div`
  display: flex;
`;

const Footer = styled.div`
  display: flex;
  justify-content: end;
`;

const ButtonWrapper = styled.div`
  display: flex;
  margin-left: 15px;
  text-transform: uppercase;
  font-family: MontserratBold;
`;

const ThresholdSelector = styled.div`
  display: flex;
  width: 100%;
  border-bottom: 1px solid ${mfColors.black40};
  margin-bottom: 32px;
`;

const InputWrapper = styled.div`
  display: flex;
`;

const ThresholdContent = styled.div`
  display: flex;
  align-items: center;
  font-size: 18px;

  ${InputWrapper} {
    margin: 0 5px;
  }
`;
const Input = styled.input`
  display: flex;
  background: #d9d9d9;
  border-radius: 4px;
  border: none;
  width: 60px;
  height: 29px;
  text-align: center;

  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &:focus {
    outline: none;
  }
`;

const ErrorMessage = styled.div`
  display: flex;
  color: red;
`;

interface IBulletinModalSettingsProps {
  reportType: EReportType;
  onClose: (e?: React.MouseEvent) => void;
  onTypeSelectorOpenChange: (isOpen: boolean) => void;
}

const BulletinModalSettings = ({ reportType, onClose, onTypeSelectorOpenChange }: IBulletinModalSettingsProps): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const farm = useSelector(getSelectedFarm);
  const thresholdOptionsConfigs = useSelector(getThresholdOptionsConfigs);
  const thresholdRangesConfigs = useSelector(getThresholdRangesConfigs);
  const updateFarmBulletinSettings = useUpdateFarmBulletinSettings();
  const measurementSystem = useSelector(regionMeasurementSystemSelector);
  const farmCompanyThresholds = useSelector(getFarmBulletinThresholds);

  const isMetric = useMemo(() => measurementSystem === EMeasurementSystem.Metric, [measurementSystem]);

  const relevantMonths = useMemo(() => {
    if (farm && farm?.bulletinRelevantMonths && farm.bulletinRelevantMonths[reportType]) {
      return farm.bulletinRelevantMonths[reportType];
    }

    return Array.from(Array(12).keys());
  }, [farm, reportType]);

  const currentThreshold = useMemo(() => {
    if (farm && farm?.farmBulletinThresholds && farm.farmBulletinThresholds[reportType]) {
      return farm.farmBulletinThresholds[reportType].value;
    }

    if (!farmCompanyThresholds[reportType]?.value) {
      if (!isMetric && (reportType === EReportType.FreeArea || reportType === EReportType.Height)) {
        return numberUtils.convertMeterToFeet(DEFAULT_REPORT_TRESHOLDS[reportType]);
      }

      return DEFAULT_REPORT_TRESHOLDS[reportType];
    }

    return farmCompanyThresholds[reportType].value;
  }, [farm, farmCompanyThresholds, reportType, isMetric]);

  const [threshold, setThreshold] = useState<number | number[]>(currentThreshold);
  const [selectedMonths, setSelectedMonths] = useState<number[]>(relevantMonths);

  const title = useMemo((): string => {
    switch (reportType) {
      case EReportType.FreeArea:
        return t('missions.tree_hedging');
      case EReportType.Height:
        return t('missions.tree_topping');
      case EReportType.Cipo:
        return t('missions.cipo_treatment');
      case EReportType.Weeds:
        return t('missions.weed_mowing');
      case EReportType.DedicatedVisit:
        return t('missions.grove_scouting');
      case EReportType.Replant:
        return t('main.bulletin.tree_planting');
      default:
        return '';
    }
  }, [reportType, t]);

  const thresholdText = useMemo((): JSX.Element => {
    if (!thresholdOptionsConfigs) {
      return <></>;
    }

    const handleInputChange = (e) => {
      const { value } = e.target;
      if (value.length > e.target.maxLength) {
        e.target.value = value.slice(0, e.target.maxLength);
      }
      setThreshold(parseFloat(value));
    };

    const metric = isMetric ? 'm' : 'ft';

    switch (reportType) {
      case EReportType.FreeArea:
        return (
          <ThresholdContent>
            <span>{t('main.bulletin.modal.average_free_area_between_rows_is_less_than')}</span>
            <InputWrapper>
              <Input value={threshold as number} name="free_area_value" type="number" onChange={handleInputChange} maxLength={4} />
            </InputWrapper>
            <span>{metric}</span>
          </ThresholdContent>
        );
      case EReportType.Height:
        return (
          <ThresholdContent>
            <span>{t('main.bulletin.modal.average_tree_height_is_more_than')}</span>
            <InputWrapper>
              <Input value={threshold as number} name="height_value" type="number" onChange={handleInputChange} maxLength={4} />
            </InputWrapper>
            <span>{metric}</span>
          </ThresholdContent>
        );
      case EReportType.Cipo:
        return (
          <ThresholdContent>
            <span>{t('main.bulletin.modal.average_bindweed_infection_greater_than')}</span>
            <InputWrapper>
              <FormSelect
                position={EPosition.Bottom}
                value={threshold || currentThreshold}
                onChange={(value) => setThreshold(value)}
                options={thresholdOptionsConfigs[EReportType.Cipo]}
                isControlsHidden
                height="29px"
                style={inputStyles}
              />
            </InputWrapper>
          </ThresholdContent>
        );
      case EReportType.Weeds:
        return (
          <ThresholdContent>
            <span>{t('main.bulletin.modal.weed_segments_are_more_than')}</span>
            <InputWrapper>
              <FormSelect
                position={EPosition.Bottom}
                value={threshold || currentThreshold}
                onChange={(value) => setThreshold(value)}
                options={thresholdOptionsConfigs[EReportType.Weeds]}
                isControlsHidden
                height="29px"
                style={inputStyles}
              />
            </InputWrapper>
            <span>{t('main.bulletin.modal.of_the_grove')}</span>
          </ThresholdContent>
        );
      case EReportType.DedicatedVisit:
        return (
          <ThresholdContent>
            <span>{t('main.bulletin.modal.more_than')}</span>
            <InputWrapper>
              <FormSelect
                position={EPosition.Bottom}
                value={threshold || currentThreshold}
                onChange={(value) => setThreshold(value)}
                options={thresholdOptionsConfigs[EReportType.DedicatedVisit]}
                isControlsHidden
                height="29px"
                style={inputStyles}
              />
            </InputWrapper>
            <span>{t('main.bulletin.modal.increase_in_weak_trees_since_previous_survey')}</span>
          </ThresholdContent>
        );
      case EReportType.Replant:
        return (
          <ThresholdContent>
            <span>{t('main.bulletin.modal.blocks_younger_than')}</span>
            <InputWrapper>
              <FormSelect
                position={EPosition.Bottom}
                value={threshold[0] || currentThreshold}
                onChange={(value) => setThreshold((prev) => [value, prev[1]])}
                options={thresholdOptionsConfigs.years}
                isControlsHidden
                height="29px"
                style={inputStyles}
              />
            </InputWrapper>
            <span style={{ marginRight: 4 }}>{t('shared.years')}</span>
            <span>{t('main.bulletin.modal.and_more_than')}</span>
            <InputWrapper>
              <FormSelect
                position={EPosition.Bottom}
                value={threshold[1] || currentThreshold}
                onChange={(value) => setThreshold((prev) => [prev[0], value])}
                options={thresholdOptionsConfigs[EReportType.DedicatedVisit]}
                isControlsHidden
                height="29px"
                style={inputStyles}
              />
            </InputWrapper>
            <span style={{ textTransform: 'lowercase' }}>{t('replant.replants')}</span>
          </ThresholdContent>
        );
      default:
        return <></>;
    }
  }, [reportType, threshold, currentThreshold, thresholdOptionsConfigs, isMetric, t]);

  const description = useMemo((): string => (farm ? t('main.bulletin.modal.settings_for_farm', { name: farm.name }) : ''), [farm, t]);

  const icon = useMemo(() => {
    switch (reportType) {
      case EReportType.DedicatedVisit:
        return <DedicatedVisitIcon />;
      case EReportType.Height:
        return <TreeToppingIcon />;
      case EReportType.FreeArea:
        return <TreeHedgingIcon />;
      case EReportType.Weeds:
        return <WeedIcon style={{ marginTop: '-6px' }} />;
      case EReportType.Cipo:
        return <BindweedsTreatmentIcon />;
      case EReportType.Replant:
        return <FontAwesomeIcon icon={faSeedling} />;
      case EReportType.TargetedScouting:
        return <TargetedScoutingIcon />;
      default:
        return <></>;
    }
  }, [reportType]);

  const thresholdRanges = useMemo(() => {
    if (!measurementSystem || !thresholdRangesConfigs || !thresholdRangesConfigs[reportType]) {
      return null;
    }

    return thresholdRangesConfigs[reportType][measurementSystem];
  }, [reportType, measurementSystem, thresholdRangesConfigs]);

  const isValid = useMemo((): boolean => {
    if (!threshold && typeof threshold !== 'number') {
      return true;
    }

    switch (reportType) {
      case EReportType.Height:
      case EReportType.FreeArea: {
        if (!thresholdRanges) {
          return false;
        }

        return threshold >= thresholdRanges.min && threshold <= thresholdRanges.max;
      }
      default:
        return !!threshold;
    }
  }, [reportType, threshold, thresholdRanges]);

  const errorMessage = useMemo((): JSX.Element => {
    switch (reportType) {
      case EReportType.Height:
      case EReportType.FreeArea: {
        const metric = isMetric ? 'm' : 'ft';
        const min = `${thresholdRanges.min} ${metric}`;
        const max = `${thresholdRanges.max} ${metric}`;
        return <ErrorMessage>{t('main.bulletin.modal.the_recommended_value_is_between', { min, max })}</ErrorMessage>;
      }
      default:
        return <></>;
    }
  }, [t, reportType, isMetric, thresholdRanges]);

  const onSave = useCallback(
    async (e) => {
      if (isValid && farm?.id && threshold) {
        const settings = {
          farmBulletinThresholds: {
            [reportType]: {
              value: threshold,
              measurementSystem
            }
          },
          bulletinRelevantMonths: {
            [reportType]: selectedMonths
          }
        };
        await updateFarmBulletinSettings(farm?.id, settings);

        const updatedFarm = {
          ...farm,
          farmBulletinThresholds: {
            ...farm.farmBulletinThresholds,
            ...settings.farmBulletinThresholds
          },
          bulletinRelevantMonths: {
            ...farm.bulletinRelevantMonths,
            ...settings.bulletinRelevantMonths
          }
        };

        dispatch(setSelectedFarm(updatedFarm));
        onClose(e);
      }
    },
    [isValid, farm, reportType, threshold, measurementSystem, selectedMonths, updateFarmBulletinSettings, onClose, dispatch]
  );

  return (
    <Wrapper>
      <Header>
        <HeaderIcon>{icon}</HeaderIcon>
        <HeaderText>
          <Title>{title}</Title>
          <Description>{description}</Description>
        </HeaderText>
      </Header>
      <ModalBody>
        <ThresholdSelector>{thresholdText}</ThresholdSelector>
        {!isValid && errorMessage}
        <DateWrapper>
          <DateTitle>{`${t('main.bulletin.modal.most_relevant_months_for_this_bulletin')}:`}</DateTitle>
          <DatePicker>
            <MonthsList selectedMonths={selectedMonths} setSelectedMonths={setSelectedMonths} />
          </DatePicker>
        </DateWrapper>
      </ModalBody>
      <Footer>
        <ButtonWrapper>
          <Button onClick={() => onTypeSelectorOpenChange(true)} text={t('main.bulletin.modal.setup_other_thresholds')} style={setupButtonStyles} />
        </ButtonWrapper>
        <ButtonWrapper>
          <Button onClick={onClose} isSecondary text={t('main.bulletin.modal.cancel')} style={secondaryButtonStyles} />
        </ButtonWrapper>
        <ButtonWrapper>
          <Button onClick={onSave} text={t('main.bulletin.modal.save')} style={buttonStyles} />
        </ButtonWrapper>
      </Footer>
    </Wrapper>
  );
};

export default BulletinModalSettings;
