import { useCallback } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import db from 'services/data/providers/firebase';
import { ICommodity, ICommodityCombination } from 'models/commodity';
import useLogger, { EStorageType } from 'hooks/logger.hooks';
import api from './providers/api';

const getGrovesCommoditiesVarieties = ({ farmIDs }: { farmIDs: string[] }): Promise<ICommodity[]> => api.post('/getGrovesCommoditiesVarieties', { farms: farmIDs });

export const useFetchAllCommodityCombinations = (): (() => Promise<ICommodityCombination[]>) => {
  const { logError } = useLogger();

  return useCallback(async (): Promise<ICommodityCombination[]> => {
    const combinationCollection = collection(db.firestore, '/commodity-combinations');

    try {
      const res = await getDocs(combinationCollection).then((docs) => {
        const combinations: ICommodityCombination[] = [];
        docs.forEach((doc) => {
          combinations.push({
            id: doc.id,
            ...doc.data()
          } as ICommodityCombination);
        });
        return combinations;
      });
      return res;
    } catch (e) {
      logError(e as Error, '/commodity-combinations', EStorageType.Firestore);
      return Promise.resolve([]);
    }
  }, [logError]);
};

const commoditiesService = {
  getGrovesCommoditiesVarieties,
  useFetchAllCommodityCombinations
};

export default commoditiesService;
