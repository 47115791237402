import React, { useState, useEffect, useMemo, useCallback, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import AliceCarousel from 'react-alice-carousel';

import farmsHooks from 'hooks/farms.hooks';
import numberUtils from 'utils/numbers';
import { useFetchGrovesStats } from 'services/data/farms';

import SurveyTimeline from 'components/main/Timeline/SurveyTimeline';
import LocalLoader from 'atomicComponents/LocalLoader';

import { ISurvey } from 'models/survey';
import { IGrove } from 'models/grove';
import { IFarm } from 'models/farm';
import { IGroveSurveyStats } from 'models/stats';
import { ERichItemType } from 'models/richItem';
import { HEALTHY, NOT_BEARING, NOT_PRODUCING, UNDER_PERFORMING, SCORES } from 'models/scores';
import { SCORE_COLORS } from 'models/colors';

import { mfColors } from 'vars';

const Header = styled.div`
  display: flex;
  flex-direction: row;
  background: ${mfColors.superLightBlue};
  margin: -24px -24px 0;
  padding: 24px 64px 24px 24px;
  border-radius: 8px 8px 0 0;
`;

const TitleColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 280px;
  align-items: center;
  margin: 0 24px 0 0;

  > div {
    width: 100%;
  }
`;

const Title = styled.div`
  font-size: 32px;
  line-height: 32px;
  margin: 0 0 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: center;
`;

const ScoresPickerColumn = styled.div`
  display: flex;
  flex-direction: row;
`;

interface IScorePickerBlockParams {
  isActive: boolean;
  score: number;
}

const ScorePickerBlock = styled.div`
  border-radius: 8px;
  overflow: hidden;
  position: relative;
  min-width: 200px;
  cursor: pointer;
  margin: 0 16px 0 0;

  * {
    cursor: pointer;
    position: relative;
    z-index: 1;
  }

  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: ${mfColors.white};
    opacity: ${({ isActive }: IScorePickerBlockParams) => (isActive ? 1 : 0)};
    z-index: 0;
  }

  &:hover {
    &::before {
      opacity: ${({ isActive }: IScorePickerBlockParams) => (isActive ? 1 : 0.4)};
    }
  }

  .title {
    line-height: 40px;
    text-align: center;
    padding: 0 8px;
    text-align: center;

    span {
      margin: 0 4px;
    }
  }

  .separator {
    height: 2px;
    width: 100%;
    background-color: ${({ score, isActive }: IScorePickerBlockParams) => (isActive ? SCORE_COLORS[score].fill : mfColors.grey)};
  }

  .badges {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 8px 16px;
  }
`;

const Score = styled.div`
  display: flex;
  flex-direction: row;
  width: 50%;

  .percentage {
    color: ${mfColors.grey};
    font-size: 12px;
  }
`;

interface IBadgeProps {
  color: string;
}

const Badge = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 100%;
  margin: 2px 12px 0 0;
  background: ${(props: IBadgeProps) => props.color};
`;

const ChartTitle = styled.div`
  color: ${mfColors.grey};
  margin: 24px 16px 0;
`;

const Slides = styled.div`
  margin: 16px 0;
  padding: 0 16px;
  width: 100%;
  position: relative;

  .alice-carousel__prev-btn,
  .alice-carousel__next-btn {
    position: absolute;
    padding: 0;
    width: 32px;
    height: 32px;
    top: 50%;
    margin-top: -16px;
    border-radius: 50%;
    background: ${mfColors.superLightBlueBg};
    line-height: 32px;
    font-size: 20px;
    opacity: 0.7;
    cursor: pointer;

    &:hover {
      opacity: 1;
    }

    > div {
      height: 100%;
      text-align: center;
    }

    p {
      padding: 0;
      width: 100%;
      color: ${mfColors.darkGrey};
    }
  }

  .alice-carousel__prev-btn {
    left: -24px;
  }

  .alice-carousel__next-btn {
    right: -24px;
  }
`;

const Slide = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const Bar = styled.div`
  width: 64px;
  border-radius: 8px;
  min-height: 320px;
  padding: 8px 2px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  font-size: 12px;

  * {
    cursor: pointer;
  }

  &:hover {
    background-color: ${mfColors.superLightBlueBg};
  }

  .value {
    margin: 8px 0;
    flex-grow: 0;
    text-align: center;
  }

  .percentage {
    border-top: 1px solid ${mfColors.grey};
    padding: 4px 0 0;
    width: 100%;
    text-align: center;
    color: ${mfColors.grey};
  }

  .grove {
    margin: 4px 0 0;
    width: 100%;
    border-radius: 4px;
    line-height: 24px;
    background-color: ${mfColors.superLightBlueBg};
    color: ${mfColors.darkGrey};
    text-align: center;
    opacity: 0.7;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
`;

interface IBarGraphProps {
  height: number;
}

const BarGraph = styled.div`
  width: 24px;
  flex-grow: 1;
  position: relative;

  .content {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    height: ${({ height }: IBarGraphProps) => `${height}%`};
  }
`;

interface IBarGraphSegmentProps {
  height: number;
  color: string;
}

const BarGraphSegment = styled.div`
  height: ${({ height }: IBarGraphSegmentProps) => `${height}%`};
  width: 100%;
  background-color: ${({ color }: IBarGraphSegmentProps) => color};
`;

const BARS_PER_SLIDE = 16;

interface IChartDescriptor {
  id: string;
  name: string;
  zoneID: string;
  score: number;
  barHeightPercentage: number;
  scorePercentage: string;
  scoresPercentages: { [key: string]: number };
}

interface IProps {
  selectedScores: number[];
}

const ScoresDistributionModal = ({ selectedScores: initialSelectedScores }: IProps): JSX.Element | null => {
  const { t } = useTranslation();
  const carouselRef = useRef<AliceCarousel | null>(null);
  const navigate = useNavigate();
  const fetchGrovesStats = useFetchGrovesStats();
  const { selectedFarm, selectedZone, selectedSurvey: initialSurvey } = farmsHooks.useSelectedFarmEntities();
  const { surveys, groves } = farmsHooks.useFarmEntities();
  const [selectedSurvey, setSelectedSurvey] = useState<ISurvey | null>(null);
  const [selectedSurveyStats, setSelectedSurveyStats] = useState<IGroveSurveyStats[]>([]);
  const [selectedScores, setSelectedScores] = useState<number[]>(initialSelectedScores);
  const { statistic, isLoading: statisticLoading } = farmsHooks.useRichItemsStatistic(ERichItemType.Health, selectedFarm?.id, selectedSurvey?.id);

  const sumScores = useCallback((scores: number[], stat) => scores.reduce((val: number, score) => (stat[score]?.total ? val + stat[score].total : val), 0), []);

  const handleChartClick = useCallback(
    (zoneID: string, groveID: string) => {
      navigate(`/map/${(selectedFarm as IFarm).id}/${zoneID}/${groveID}`);
    },
    [navigate, selectedFarm]
  );

  const publishedGroveIDs = useMemo(() => {
    if (!statistic) return [];
    return Object.keys(statistic).filter(
      (groveID) => !!selectedSurveyStats.find((entry) => entry.groveID === groveID && (!selectedZone || entry.zoneID === selectedZone.id))?.isPublished
    );
  }, [statistic, selectedSurveyStats, selectedZone]);

  const zoneGroves = useMemo(() => groves.filter((grove) => !selectedZone || grove.zoneID === selectedZone.id), [groves, selectedZone]);

  const totalTrees = useMemo(() => {
    if (!statistic) return 0;
    return publishedGroveIDs.reduce((acc: number, groveID) => acc + sumScores(SCORES, statistic[groveID]), 0);
  }, [publishedGroveIDs, statistic, sumScores]);

  const areaStats = useMemo(() => {
    if (!statistic) {
      return null;
    }

    const result = {};

    SCORES.forEach((score: number) => {
      result[score] = publishedGroveIDs.reduce((acc: number, groveID) => acc + (statistic?.[groveID]?.[score]?.total || 0), 0);
    });

    return result;
  }, [statistic, publishedGroveIDs]);

  useEffect(() => {
    setSelectedScores(initialSelectedScores);
  }, [initialSelectedScores]);

  useEffect(() => {
    setSelectedSurvey(initialSurvey);
  }, [initialSurvey]);

  useEffect(() => {
    if (selectedFarm?.id && selectedSurvey?.id) {
      fetchGrovesStats(selectedFarm?.id).then((items) => {
        setSelectedSurveyStats(items.filter((item) => item.surveyID === selectedSurvey.id));
      });
    } else {
      setSelectedSurveyStats([]);
    }
  }, [selectedFarm?.id, fetchGrovesStats, selectedSurvey?.id]);

  const scoreTabs = useMemo(
    () => [
      {
        scores: HEALTHY,
        title: t('shared.healthy')
      },
      {
        scores: UNDER_PERFORMING,
        title: t('shared.underperforming')
      },
      {
        scores: NOT_PRODUCING,
        title: t('shared.non_producing')
      },
      {
        scores: NOT_BEARING,
        title: t('main.info.non_bearing')
      }
    ],
    [t]
  );

  const items = useMemo(() => {
    if (!statistic || !areaStats) {
      return [];
    }

    const groveBars = (
      publishedGroveIDs
        .map((groveID) => {
          const grove = groves.find((grove) => grove.id === groveID) as IGrove;
          const groveScores = sumScores(selectedScores, statistic[groveID]);
          const groveTotalScores = sumScores(SCORES, statistic[groveID]);

          const result = {
            id: grove.id,
            name: grove.name,
            zoneID: grove.zoneID,
            score: groveScores,
            scoresPercentages: {},
            scorePercentage: `${numberUtils.normalizeDecimal((groveScores / groveTotalScores) * 100, 0)}%`
          };

          result.scoresPercentages = selectedScores.reduce(
            (acc: { [key: string]: number }, score: number) => ({
              ...acc,
              [score]: numberUtils.normalizeDecimal((sumScores([score], statistic[groveID]) / groveScores) * 100)
            }),
            {}
          );

          return result;
        })
        .filter((bar) => !!bar?.score) as IChartDescriptor[]
    ).sort((a, b) => b.score - a.score);

    const maxScore = Math.max.apply(
      null,
      groveBars.map((bar) => bar.score)
    );

    const result: IChartDescriptor[][] = [];

    for (let i = 0; i < groveBars.length; i += 1) {
      if (i % BARS_PER_SLIDE === 0) {
        result.push([]);
      }
      result[result.length - 1].push({
        ...groveBars[i],
        barHeightPercentage: selectedScores.length === SCORES.length ? 100 : Math.ceil((groveBars[i].score / maxScore) * 100)
      });
    }

    return result;
  }, [statistic, selectedScores, sumScores, groves, areaStats, publishedGroveIDs]);

  useEffect(() => {
    setTimeout(() => {
      if (items.length > 0 && carouselRef.current) {
        carouselRef.current.slideTo(0);
      }
    }, 0);
  }, [items, carouselRef]);

  if (statisticLoading) return <LocalLoader />;
  if (!selectedSurvey || !selectedFarm || !areaStats) return null;

  return (
    <>
      <Header>
        <TitleColumn>
          <Title>{selectedFarm.name}</Title>
          <SurveyTimeline
            showLegend={false}
            onSelectSurvey={setSelectedSurvey}
            surveys={surveys}
            selectedSurveyStats={[]}
            onSelectHotspots={() => {}}
            selectedSurvey={selectedSurvey.id}
          />
        </TitleColumn>
        <ScoresPickerColumn>
          {scoreTabs.map((tab, index) => (
            <ScorePickerBlock key={index} score={tab.scores[0]} isActive={selectedScores[0] === tab.scores[0]} onClick={() => setSelectedScores(tab.scores)}>
              <div className="title">
                <span>{tab.title}</span>
                <span>{tab.scores.reduce((acc: number, score) => acc + areaStats?.[score], 0)}</span>
              </div>
              <div className="separator" />
              <div className="badges">
                {tab.scores.map((score, index) => (
                  <Score key={index}>
                    <Badge color={SCORE_COLORS[score].fill} />
                    <div>
                      <div>{areaStats[score]}</div>
                      <div className="percentage">{`${numberUtils.normalizeDecimal(((areaStats[score] || 0) / (totalTrees || 0)) * 100, 1)}%`}</div>
                    </div>
                  </Score>
                ))}
              </div>
            </ScorePickerBlock>
          ))}
        </ScoresPickerColumn>
      </Header>
      <ChartTitle>
        <span>{t('main.info.published_of_total_groves', { published: publishedGroveIDs.length, total: zoneGroves.length })}</span>
        <span> | </span>
        <span>{t('main.info.total_trees', { count: totalTrees })}</span>
      </ChartTitle>
      <Slides>
        <AliceCarousel ref={carouselRef} disableDotsControls>
          {items.map((slide, index) => (
            <Slide key={index}>
              {slide.map((bar, index) => (
                <Bar onClick={() => handleChartClick(bar.zoneID, bar.id)} key={index}>
                  <BarGraph height={bar.barHeightPercentage}>
                    <div className="content">
                      {Object.keys(bar.scoresPercentages).map((score) => (
                        <BarGraphSegment key={score} color={SCORE_COLORS[score].fill} height={bar.scoresPercentages[score]} />
                      ))}
                    </div>
                  </BarGraph>
                  <div className="value">{bar.score}</div>
                  <div className="percentage">{bar.scorePercentage}</div>
                  <div className="grove">{bar.name}</div>
                </Bar>
              ))}
            </Slide>
          ))}
        </AliceCarousel>
      </Slides>
    </>
  );
};

export default ScoresDistributionModal;
