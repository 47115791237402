import { IFeed, EFeedItemType, NO_PESTS, NO_DISEASE, NOT_APPLICABLE_STATUSES } from 'models/feed';
import { IMission, EMissionSubtype } from 'models/mission';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { renderToString } from 'react-dom/server';
import images from 'services/data/images';
import styled from 'styled-components';
import dateFormats from 'models/date';
import dateUtils from 'utils/date';

const Wrapper = styled.div`
  font-size: 14px;
  border-radius: 6px;
  padding: 0;
  font-weight: 400;
  padding: 24px;
`;

const TreeTitle = styled.div`
  font-family: 'MontserratBold';
`;

const ImagesWrapper = styled.div`
  display: flex;
  margin: 8px 0;
  justify-content: space-around;
  flex-wrap: wrap;
`;

const Image = styled.img`
  height: 60px;
  margin-right: 8px;
  border-radius: 8px;
  cursor: pointer;
  margin-top: 8px;
`;

const Text = styled.div`
  word-break: break-word;
`;

interface IProps {
  feed: IFeed[];
  farmID: string;
  mission: IMission | null;
}

const FeedPopup = ({ feed, farmID, mission }: IProps): JSX.Element => {
  const { t } = useTranslation();
  const mainFeedEntry = feed?.[0];
  const treeName = (mainFeedEntry.geoObjectName || '').split('/').slice(-2).join('/');

  const imageUrls = useMemo(() => {
    if (!mainFeedEntry.images) return [];

    return Object.keys(mainFeedEntry.images || {}).map((imageKey) => {
      const previewImageName = images.getImagePreview(imageKey, { width: 100, height: 100 });
      const fullImageName = images.getImagePreview(imageKey, {});
      return {
        url: images.getImageURLFromBucket(farmID, mainFeedEntry.id, fullImageName),
        previewUrl: images.getImagePreviewURLFromBucket(farmID, mainFeedEntry.id, previewImageName)
      };
    });
  }, [mainFeedEntry, farmID]);

  const content = useMemo((): JSX.Element | string => {
    switch (mainFeedEntry.type) {
      case EFeedItemType.Count:
        return (
          <>
            {mission && mission.subType !== EMissionSubtype.BloomMonth && mainFeedEntry?.data?.fruitCountStatus && (
              <>
                <div>{t(`missions.${mainFeedEntry.data?.fruitCountStatus}`)}</div>
                {mission.subType !== EMissionSubtype.MarkAsCounted && !NOT_APPLICABLE_STATUSES.includes(mainFeedEntry.data?.fruitCountStatus) && (
                  <div>{t('missions.count', { count: mainFeedEntry.data.fruitCountNumber })}</div>
                )}
              </>
            )}
            {mission && mission.subType === EMissionSubtype.BloomMonth && mainFeedEntry.data?.bloomMonth && (
              <>
                <div>{t('missions.total_submitted_reports_count', { count: feed.length })}</div>
              </>
            )}
          </>
        );
      case EFeedItemType.General:
        return mainFeedEntry.title ? (
          <>
            <TreeTitle>{mainFeedEntry.title}</TreeTitle>
            {mainFeedEntry.body && <div>{mainFeedEntry.body}</div>}
          </>
        ) : (
          ''
        );
      case EFeedItemType.WeeklyTrapMonitoring:
        return t('missions.total_submitted_reports_count', { count: feed.length });
      case EFeedItemType.Hlb:
        return mainFeedEntry?.data?.hlbStatus ? t(`missions.hlb.${mainFeedEntry.data.hlbStatus}`) : '';
      case EFeedItemType.Pests:
        if (!mainFeedEntry?.data?.pestsType) return '';
        return mainFeedEntry.data?.pestsType === NO_PESTS ? t('missions.no_pests') : mainFeedEntry.data.pestsType;
      case EFeedItemType.PestsP3:
        return (
          <>
            <div>{dateUtils.formatDate(mainFeedEntry.createdAt, dateFormats.DATE_FORMAT_DD_MMMM_YYYY)}</div>
            <div>{mainFeedEntry.user.displayName}</div>
          </>
        );
      case EFeedItemType.Disease:
        if (!mainFeedEntry?.data?.diseaseType) return '';
        return mainFeedEntry.data.diseaseType === NO_DISEASE ? t('missions.no_disease') : mainFeedEntry.data.diseaseType;
      case EFeedItemType.Sampling:
      case EFeedItemType.Irrigation:
        return (
          <>
            {mainFeedEntry.title && <div>{mainFeedEntry.title}</div>}
            {mainFeedEntry.body && <div>{mainFeedEntry.body}</div>}
          </>
        );
      case EFeedItemType.Clipping:
        return mainFeedEntry?.data?.clippingStatus ? t(`missions.${mainFeedEntry.data.clippingStatus}`) : t('missions.marked_for_clipping');
      default:
        return mainFeedEntry.title;
    }
  }, [mission, mainFeedEntry, feed, t]);

  return (
    <Wrapper>
      <TreeTitle>
        Tree:
        {` ${treeName}`}
      </TreeTitle>
      <Text>{content}</Text>
      {mainFeedEntry.data?.title && <Text>{mainFeedEntry.data.title}</Text>}
      <ImagesWrapper>
        {imageUrls.map((item) => (
          <Image data-url={item.url} className="feed-popup-image" key={item.url} src={item.url} alt="Preview" />
        ))}
      </ImagesWrapper>
    </Wrapper>
  );
};
const FeedHTMLPopup = (props: IProps) => renderToString(<FeedPopup {...props} />);

export default FeedHTMLPopup;
