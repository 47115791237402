import { Map, AnyLayer } from 'mapbox-gl';
import * as turf from '@turf/turf';

import { reorderLayers, addLayerToMap, removeMapLayerById, removeMapSourceById, LAYERS_PRIORITY, TREE_LAYERS } from 'services/util/mapHelpers';

export const removeRasterLayers = (map: Map) => {
  map
    .getStyle()
    .layers?.filter((layer) => layer.type === 'raster' && layer.source !== 'mapbox')
    .forEach((layer) => {
      removeMapLayerById(map, layer.id);
      removeMapSourceById(map, layer.id);
    });
};

const addRastersLayers = (map: Map, urls: string[], bbox?: turf.BBox): void => {
  const layersByPriority: string[] = [];

  map
    .getStyle()
    .layers?.filter((layer) => layer.type === 'raster' && layer.source !== 'mapbox')
    .forEach((layer) => {
      removeMapLayerById(map, layer.id);
      removeMapSourceById(map, layer.id);
    });

  if (!bbox) {
    return;
  }

  urls.forEach((url) => {
    layersByPriority.push(url);

    const isMapbox = url.includes('mapbox://');

    addLayerToMap(map, url, {
      id: url,
      type: 'raster',
      bounds: bbox,
      source: isMapbox ? { url, type: 'raster' } : { tiles: [url], type: 'raster', bounds: bbox }
    } as AnyLayer);
  });

  const precedingLayerIndex = LAYERS_PRIORITY.indexOf(TREE_LAYERS[0]);
  const orderedLayers = [...LAYERS_PRIORITY.slice(0, precedingLayerIndex), ...layersByPriority, ...LAYERS_PRIORITY.slice(precedingLayerIndex, LAYERS_PRIORITY.length - 1)];

  reorderLayers(
    map,
    orderedLayers.map((layer, index) => ({
      name: layer,
      zIndex: index
    }))
  );
};

export default addRastersLayers;
