import { Map, AnySourceData, GeoJSONSource } from 'mapbox-gl';
import * as turf from '@turf/turf';
import {
  getMapSourceById,
  getMapLayerById,
  removeMapLayerById,
  removeMapSourceById,
  reorderLayers,
  TAnyWrappedTileType,
  IWrappedTileType,
  TStrokePaintType,
  TMapMouseEvent,
  TREE_AREA,
  ADDITIONAL_TREE_AREA,
  TREE_HOVERED,
  TREE_HOVER_AREA,
  TREE_LOW_ZOOM,
  LAYERS_PRIORITY,
  TREE_PAINT_CONFIG,
  TREE_MIN_TILESET_ZOOM,
  TREE_HOVERABLE_ZOOM_TRESHOLD,
  SELECTED_TREES,
  GALLERY_ICON,
  addLayerToMap,
  loadImage,
  TREE_AREA_SOURCE
} from 'services/util/mapHelpers';
import { COLORS, SCORE_COLORS, SCORE_BLIND_COLORS } from 'models/colors';
import { SCORES } from 'models/scores';
import { ITreeMapboxFeature } from 'models/tree';

const getAdditionalLayerPaintConfig = () => ({
  'circle-radius': {
    base: 1,
    stops: [
      [16, 2],
      [19, 10]
    ]
  },
  'circle-stroke-width': {
    base: 1,
    stops: [
      [TREE_HOVERABLE_ZOOM_TRESHOLD, 0],
      [TREE_HOVERABLE_ZOOM_TRESHOLD, 3.8]
    ]
  },
  'circle-color': COLORS.missingLight.fill,
  'circle-stroke-color': {
    type: 'categorical' as TStrokePaintType,
    property: 'score',
    default: 'transparent',
    stops: [[0, COLORS.missingLight.border]]
  }
});

const setCircleColor = (map: Map, isColorBlind: boolean): void => {
  const scoreColors = isColorBlind ? SCORE_BLIND_COLORS : SCORE_COLORS;
  const circleColor = ['fill', 'border'].reduce(
    (colors, property) => {
      const stops = SCORES.map((key) => [key, scoreColors[key][property]]);
      colors[property] = {
        stops,
        property: 'score',
        type: 'categorical'
      };

      return colors;
    },
    { fill: '', border: '' }
  );
  const layers = [TREE_LOW_ZOOM, TREE_AREA, TREE_HOVERED, SELECTED_TREES];

  layers.forEach((layer) => {
    if (!map.getLayer(layer)) {
      return;
    }
    map.setPaintProperty(layer, 'circle-color', layer === SELECTED_TREES ? 'white' : circleColor.fill);
    map.setPaintProperty(layer, 'circle-stroke-color', circleColor.border);
  });
};

const addLowZoomTrees = (map: Map, tilesetUrlType: TAnyWrappedTileType): void => {
  const mapLayer = getMapLayerById(map, TREE_LOW_ZOOM);
  const mapSource = getMapSourceById(map, TREE_LOW_ZOOM);

  if (mapLayer) {
    removeMapLayerById(map, TREE_LOW_ZOOM);
  }

  if (mapSource) {
    removeMapSourceById(map, TREE_LOW_ZOOM);
  }

  map.addSource(TREE_LOW_ZOOM, { type: 'vector', maxzoom: TREE_MIN_TILESET_ZOOM, ...tilesetUrlType });

  map.addLayer({
    id: TREE_LOW_ZOOM,
    type: 'circle',
    maxzoom: TREE_MIN_TILESET_ZOOM,
    source: TREE_LOW_ZOOM,
    'source-layer': 'original',
    paint: TREE_PAINT_CONFIG
  });
};

const addHoveredTreesLayers = (map: Map): void => {
  const mapLayer = getMapLayerById(map, TREE_HOVERED);
  const mapSource = getMapSourceById(map, TREE_HOVERED);

  if (mapLayer) {
    removeMapLayerById(map, TREE_HOVERED);
  }

  if (!mapSource) {
    map.addSource(TREE_HOVERED, {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: []
      }
    });
  }

  map.addLayer({
    id: TREE_HOVERED,
    type: 'circle',
    source: TREE_HOVERED,
    paint: {
      'circle-radius': 10,
      'circle-stroke-width': 10
    }
  });
};

const addTreesClickListeners = (map: Map, treeClickHandler: (feature: TMapMouseEvent) => void): void => {
  map.on('click', treeClickHandler);
};

const addSelectedTreesClickListener = (map: Map, selectedTreeClickHandler: (event: TMapMouseEvent) => void): void => {
  map.on('click', SELECTED_TREES, selectedTreeClickHandler);
};

const addTilesetLayers = (
  map: Map,
  tilesetUrlType: TAnyWrappedTileType,
  colorBlind: boolean,
  treeClickHandler?: (feature: TMapMouseEvent) => void,
  selectedTreesHandler?: (event: TMapMouseEvent) => void,
  bbox?: turf.BBox
) => {
  const layers = [TREE_AREA, ADDITIONAL_TREE_AREA, TREE_HOVER_AREA];

  const sourceConfig: AnySourceData = (tilesetUrlType as IWrappedTileType).tiles ? { type: 'vector', minzoom: TREE_MIN_TILESET_ZOOM, maxzoom: 20 } : { type: 'vector' };

  if (bbox) {
    sourceConfig.bounds = bbox;
  }

  layers.forEach((layer) => {
    const mapLayer = getMapLayerById(map, layer);
    if (mapLayer) {
      removeMapLayerById(map, layer);
    }
  });

  removeMapSourceById(map, TREE_AREA_SOURCE);
  map.addSource(TREE_AREA_SOURCE, {
    ...sourceConfig,
    ...tilesetUrlType
  } as AnySourceData);

  map.addLayer({
    id: TREE_AREA,
    type: 'circle',
    source: TREE_AREA_SOURCE,
    'source-layer': 'original',
    paint: TREE_PAINT_CONFIG
  });

  map.addLayer({
    paint: {
      'circle-radius': {
        base: 2,
        stops: [
          [15, 4],
          [17, 8],
          [19, 14],
          [22, 30]
        ]
      },
      'circle-opacity': 0
    },
    id: TREE_HOVER_AREA,
    type: 'circle',
    minzoom: TREE_MIN_TILESET_ZOOM,
    source: TREE_AREA_SOURCE,
    'source-layer': 'original'
  });

  map.addLayer({
    id: ADDITIONAL_TREE_AREA,
    type: 'circle',
    source: TREE_AREA_SOURCE,
    'source-layer': 'original',
    paint: getAdditionalLayerPaintConfig()
  });

  addSelectedTreesLayers(map, colorBlind, selectedTreesHandler);
  addHoveredTreesLayers(map);
  addLowZoomTrees(map, tilesetUrlType);
  setCircleColor(map, colorBlind);

  if (treeClickHandler) {
    addTreesClickListeners(map, treeClickHandler);
  }

  reorderLayers(
    map,
    LAYERS_PRIORITY.map((layer, index) => ({
      name: layer,
      zIndex: index
    }))
  );
};

const addSelectedTreesLayers = (map: Map, colorBlind: boolean, selectedTreeClickHandler?: (event: TMapMouseEvent) => void): void => {
  const mapLayer = getMapLayerById(map, SELECTED_TREES);
  const mapSource = getMapSourceById(map, SELECTED_TREES);

  if (mapLayer) {
    removeMapLayerById(map, SELECTED_TREES);
  }

  if (!mapSource) {
    map.addSource(SELECTED_TREES, {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: []
      }
    });
  }

  map.addLayer({
    id: SELECTED_TREES,
    type: 'circle',
    source: SELECTED_TREES,
    paint: {
      'circle-stroke-width': {
        base: 2,
        stops: [
          [17, 6],
          [22, 12]
        ]
      },
      'circle-stroke-opacity': 0.6,
      'circle-stroke-color': 'blue',
      'circle-radius': {
        base: 1,
        stops: [
          [17, 10],
          [22, 18]
        ]
      }
    }
  });

  if (selectedTreeClickHandler) {
    addSelectedTreesClickListener(map, selectedTreeClickHandler);
  }

  loadImage(map, '/images/plus-icon.png', GALLERY_ICON).then(() => {
    addLayerToMap(map, GALLERY_ICON, {
      id: GALLERY_ICON,
      type: 'symbol',
      source: SELECTED_TREES,
      layout: {
        'icon-image': GALLERY_ICON,
        'icon-size': {
          base: 0.5,
          stops: [
            [17, 0.35],
            [22, 0.7]
          ]
        },
        'icon-rotation-alignment': 'viewport',
        'icon-allow-overlap': true
      },
      paint: { 'icon-color': 'black' }
    });
  });
};

const removeTilesetLayers = (map: Map): void => {
  const layers = [TREE_AREA, ADDITIONAL_TREE_AREA, TREE_HOVERED, TREE_LOW_ZOOM, TREE_HOVER_AREA, GALLERY_ICON, SELECTED_TREES];

  layers.forEach((layer) => {
    removeMapLayerById(map, layer);
  });
};

const drawSelectedTrees = (map: Map, tree: ITreeMapboxFeature | null): void => {
  const selectedTreesSource = map.getSource(SELECTED_TREES) as GeoJSONSource;

  if (!selectedTreesSource) return;
  selectedTreesSource.setData(turf.featureCollection(tree ? [tree] : []));
};

const detachTreeClickListeners = (map: Map, treeClickHandler: (feature: TMapMouseEvent) => void, selectedTreeClickHandler?: (event: TMapMouseEvent) => void) => {
  map.off('click', treeClickHandler);

  if (selectedTreeClickHandler) {
    map.off('click', SELECTED_TREES, selectedTreeClickHandler);
  }
};

const highlightScores = (map: Map, highlighted: number[]) => {
  [TREE_AREA, TREE_LOW_ZOOM].forEach((layer: string) => {
    if (!map.getLayer(layer)) return;
    const notHighlighted = SCORES.filter((score: number) => !highlighted.includes(score));
    const toStop = (value: number) => (score: number) => [score, value];
    const property = 'circle-opacity';
    const opacity = 0;
    const stops = [...notHighlighted.map(toStop(opacity)), ...highlighted.map(toStop(1))];
    const highlightedStops = {
      stops,
      default: 1,
      type: 'categorical',
      property: 'score'
    };

    map.setPaintProperty(layer, property, !highlighted.length ? 1 : highlightedStops, layer);
  });
};

const mapTilesetService = {
  addTilesetLayers,
  drawSelectedTrees,
  removeTilesetLayers,
  detachTreeClickListeners,
  highlightScores
};

export default mapTilesetService;
