import React, { useMemo } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { EReportType, TAnyReport } from 'models/report';
import { IGrove } from 'models/grove';
import { IUnifiedHotspot } from 'models/hotspot';

interface IReportTitleProps {
  reports: (TAnyReport | IUnifiedHotspot)[];
  reportsCount: number;
  reportType: EReportType;
  selectedGrove: IGrove | null;
}

const TitleBold = styled.span`
  font-family: MontserratBold;
`;

const BulletinTitle = ({ reportType, reports, reportsCount, selectedGrove }: IReportTitleProps): JSX.Element => {
  const { t } = useTranslation();
  const uniqueGroves = useMemo(() => reports.filter((value, index, self) => self.find((entry) => entry.groveID === value.groveID) === value), [reports]);
  const titlePrefix = useMemo((): JSX.Element => {
    let titleText = '';
    let titlePrefix = '';

    if (!uniqueGroves.length) {
      titleText = `${t('main.bulletin.no_groves')} `;
    } else if (!selectedGrove) {
      const resolvedTitle = reportsCount > 0 && uniqueGroves.length < reportsCount ? `/${reportsCount}` : '';
      titleText = `${uniqueGroves.length}${resolvedTitle} ${t(uniqueGroves.length > 1 ? 'main.bulletin.grove_plural' : 'main.bulletin.grove')} `;
    } else {
      titlePrefix = t(uniqueGroves.length > 1 ? 'main.bulletin.this_grove_and' : 'main.bulletin.this_grove');
      titleText = uniqueGroves.length > 1 ? t('main.bulletin.more', { count: uniqueGroves.length - 1 }) : '';
    }
    return (
      <span>
        {titlePrefix}
        <TitleBold>{titleText}</TitleBold>
      </span>
    );
  }, [uniqueGroves, selectedGrove, reportsCount, t]);
  const titleSuffix = useMemo((): JSX.Element => {
    let textSuffix: JSX.Element | string = '';
    const textPrefix = t('main.bulletin.require', { count: uniqueGroves.length });
    switch (reportType) {
      case EReportType.FreeArea:
        textSuffix = <TitleBold>{t('main.bulletin.tree_hedging')}</TitleBold>;
        break;
      case EReportType.Height:
        textSuffix = <TitleBold>{t('main.bulletin.tree_topping')}</TitleBold>;
        break;
      case EReportType.Cipo:
        textSuffix = <TitleBold>{t('main.bulletin.cipo_treatment')}</TitleBold>;
        break;
      case EReportType.Weeds:
        textSuffix = <TitleBold>{t('main.bulletin.weeds_mowing')}</TitleBold>;
        break;
      case EReportType.TargetedScouting:
        textSuffix = <TitleBold>{t('main.bulletin.targeted_scouting')}</TitleBold>;
        break;
      case EReportType.Replant:
        textSuffix = <TitleBold>{t('main.bulletin.tree_planting')}</TitleBold>;
        break;
      default:
        textSuffix = <TitleBold>{t('main.bulletin.dedicated_visit')}</TitleBold>;
        break;
    }

    return (
      <span>
        {textPrefix}
        {textSuffix}
      </span>
    );
  }, [uniqueGroves, reportType, t]);

  return (
    <span>
      {titlePrefix}
      {titleSuffix}
    </span>
  );
};

export default BulletinTitle;
