import { IGroveSurveyStats } from 'models/stats';
import { NOT_PRODUCING, UNDER_PERFORMING, NOT_BEARING, HEALTHY, CUSTOMER_SCORES } from 'models/scores';
import { IGrove } from 'models/grove';
import { IZone } from 'models/zone';
import { IHealthStatistic, TMappedStatistic } from 'models/statistic';
import { indexBy } from './helpers';

export interface IProductivityProps {
  statistic: TMappedStatistic | null;
  selectedSurveyStats: IGroveSurveyStats[];
  groves: IGrove[];
  selectedGrove: IGrove | null;
  selectedZone: IZone | null;
}

const PRODUCTIVITY_SCORES = [...UNDER_PERFORMING, ...HEALTHY];

const getTotalCountScores = (statistic: { [key: number]: number }, scores: number[]) => scores.reduce((total: number, score: number) => total + statistic[score] || 0, 0);

const getScoresSum = (statistic: IHealthStatistic, scores: number[]) => scores.reduce((total: number, score: number) => total + (statistic[score]?.total || 0), 0);

const getGroveScore = (scoreCheck: { [key: number]: (percent: number) => boolean }, percentage: number): number => {
  const result = Object.entries(scoreCheck).find(([, check]) => check(percentage));
  return result ? +result[0] : 0;
};

const getGroveHealthScore = (percentage: number, isAlmonds = false): number => {
  const scoreCheck = {
    0: (percent) => percent === null,
    1: (percent) => percent > 0.8,
    2: (percent) => percent > (isAlmonds ? 0.2 : 0.3),
    3: (percent) => percent > (isAlmonds ? 0.1 : 0.2),
    4: (percent) => percent > (isAlmonds ? 0.05 : 0.1),
    5: (percent) => percent <= (isAlmonds ? 0.05 : 0.1)
  };

  return getGroveScore(scoreCheck, percentage);
};

const getGroveHealthPercentage = (groveStatistic: IGroveSurveyStats, isNotBearing: boolean, isAlmonds = false): number => {
  const notProducingPadding = isAlmonds ? 1 : 0.2;
  const notProducingCount = getTotalCountScores(groveStatistic.scores || {}, NOT_PRODUCING);
  const underPerformingCount = getTotalCountScores(groveStatistic.scores || {}, UNDER_PERFORMING);
  const notBearingCount = getTotalCountScores(groveStatistic.scores || {}, NOT_BEARING);

  if (isNotBearing) {
    return notBearingCount / groveStatistic.numberOfTrees;
  } else {
    return (notProducingCount + notProducingPadding * underPerformingCount) / groveStatistic.numberOfTrees;
  }
};

const checkNonBearing = (groveStatistic: IGroveSurveyStats): boolean => {
  const notBearingCount = getTotalCountScores(groveStatistic?.scores || {}, NOT_BEARING);
  const notProducingCount = getTotalCountScores(groveStatistic?.scores || {}, NOT_PRODUCING);
  return notBearingCount + notProducingCount === groveStatistic.numberOfTrees;
};

const calculateScore = (statistic: IGroveSurveyStats, isAlmonds = false): number => {
  const isNotBearing = checkNonBearing(statistic);
  if (isNotBearing && getGroveHealthPercentage(statistic, isNotBearing, isAlmonds) >= 0.6) {
    return 6;
  } else {
    const percentage = getGroveHealthPercentage(statistic, false, isAlmonds);
    return getGroveHealthScore(percentage, isAlmonds);
  }
};

export const getPercentages = (value: number, total: number) => {
  if (!total) {
    return 0;
  }

  return (value / total) * 100;
};

const getGroveCustomerScoresTrees = (statistic: TMappedStatistic, groveID: string) => {
  const total = CUSTOMER_SCORES.reduce((sum: number, score: number) => {
    if (!statistic || !statistic[groveID] || !statistic[groveID][score]) {
      return sum;
    }
    return sum + statistic[groveID][score].total;
  }, 0);

  return total;
};

const getGroveCapacity = (capacityStatistic: TMappedStatistic, statistic: TMappedStatistic, groveID: string) => {
  const plantedTrees = capacityStatistic?.[groveID]?.statistic;

  if (!plantedTrees) return 0;

  const totalTrees = getGroveCustomerScoresTrees(statistic, groveID);

  return (Number(plantedTrees) / totalTrees) * 100;
};

export const getAreaSelectedGroves = (groves: IGrove[], selectedGrove: IGrove | null, selectedZone: IZone | null) => {
  let selectedAreaGroves: IGrove[] = [];

  if (selectedGrove) {
    selectedAreaGroves = [selectedGrove];
  } else if (selectedZone) {
    selectedAreaGroves = groves.filter((grove) => grove.zoneID === selectedZone.id);
  } else {
    selectedAreaGroves = groves;
  }

  return indexBy('id', selectedAreaGroves);
};

export const getTreesAmoutByScores = (statistic: TMappedStatistic, indexedGroves: { (key: string): IGrove } | {}, selectedSurveyStats: IGroveSurveyStats[], scores: number[]) => {
  const result = Object.keys(statistic).reduce((acc: number, groveID) => {
    if (!indexedGroves[groveID]) return acc;
    const stat = selectedSurveyStats.find((entry) => entry.groveID === groveID);
    if (!stat?.isPublished) return acc;

    const total = scores.reduce((sum: number, score: number) => {
      if (!statistic[groveID][score]) {
        return sum;
      }
      return sum + statistic[groveID][score].total;
    }, 0);

    return acc + total;
  }, 0);

  return result;
};

const getFarmCapacity = (statistic: TMappedStatistic): number => {
  const groveIDs = Object.keys(statistic);

  return groveIDs.reduce((acc, groveID) => acc + Number(statistic[groveID].statistic), 0);
};

export const getProductivity = ({ statistic, selectedSurveyStats, groves, selectedGrove, selectedZone }: IProductivityProps) => {
  const indexedGroves = getAreaSelectedGroves(groves, selectedGrove, selectedZone);

  if (!statistic) {
    return null;
  }

  const plantedTrees = getTreesAmoutByScores(statistic, indexedGroves, selectedSurveyStats, PRODUCTIVITY_SCORES);
  const totalTrees = getTreesAmoutByScores(statistic, indexedGroves, selectedSurveyStats, CUSTOMER_SCORES);

  return getPercentages(plantedTrees, totalTrees);
};

const scoreUtils = {
  calculateScore,
  getPercentages,
  getGroveCapacity,
  getFarmCapacity,
  getProductivity,
  getScoresSum
};

export default scoreUtils;
