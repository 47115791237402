import { MEASURE_AREA_LAYER, MEASURE_POINTS_LAYER, MEASURE_LINES_LAYER } from 'constants/map';
import { Map, AnyLayer, GeoJSONSource, AnySourceImpl, MapMouseEvent, MapboxGeoJSONFeature } from 'mapbox-gl';
import * as turf from '@turf/turf';

import { IColorStops } from 'models/map';
import { ITile } from 'models/tile';
import { ISurvey } from 'models/survey';
import { IUnifiedGeojson } from 'hooks/farms.hooks';

export const ZONES_LAYERS = ['zones-fill', 'zones-line', 'zones-fill-hovered', 'zones-line-hovered', 'zones-fill-selected', 'zones-line-selected', 'zones-text'];

export const GROVES_LAYERS = ['groves-line', 'groves-fill'];

export const REPLANT_CLUSTERS_LAYERS = ['replant-cluster-circle', 'replant-unclustered-circle', 'replant-cluster-symbol', 'replant-unclustered-symbol'];

export const PESTS_CLUSTERS_LAYERS = ['pest-cluster-circle', 'pests-unclustered-circle', 'pests-cluster-symbol', 'pests-unclustered-symbol'];
export const PESTSP3_CLUSTERS_LAYERS = ['pestsP3-cluster-circle', 'pestsP3-unclustered-circle', 'pestsP3-cluster-symbol', 'pestsP3-unclustered-symbol'];

export const GROVES_SELECTED_LAYERS = ['groves-lines-selected'];

export const HOTSPOT_LAYERS = [
  'hotspots-base',
  'hotspots-border-shadow-1',
  'hotspots-border-shadow-2',
  'hotspot-marker',
  'satellite-hotspot-marker',
  'selected-satellite-hotspot-marker'
];

export const BULLETIN_LAYER = 'bulletin';

export const HOTSPOT_POLYGON_LAYERS = ['hotspot-polygon'];

export const FEED_LAYERS = ['feed-requests', 'feed-reports', 'feed-active', 'manual-score'];

export const GROVES_HOVERED_LAYERS = ['groves-fill-hovered', 'groves-line-hovered'];

export const GROVES_TEXT_LAYERS = ['groves-text'];

export const GROVES_RICH_ITEMS_LAYERS = ['groves-layers-fill', 'groves-layers-border'];

export const MISSION_RESOLVED_GROVE_BORDER = 'groves-resolved-mission-border';
export const MISSION_RESOLVED_GROVE_BG = 'groves-resolved-mission-bg';
export const MISSION_RESOLVED_GROVE_TITLE = 'grove-resolved-mission-title';

export const GROVES_MISSIONS_LAYERS = ['groves-missions-fill', 'groves-missions-border', 'selected-mission-groves', 'resolved-mission-groves'];

export const TREE_AREA = 'tileset';
export const ADDITIONAL_TREE_AREA = 'tileset-additional';
export const TREE_HOVERED = 'tree-hovered';
export const TREE_HOVER_AREA = 'trees-hover-area';
export const TREE_LOW_ZOOM = 'tileset-low-zoom';
export const SELECTED_TREES = 'selected-trees';
export const CUSTOM_GEOJSON_TREE_AREA = 'custom-geojson-tileset';
export const CUSTOM_GEOJSON_TREE_HOVER_AREA = 'custom-geojson-hover-tileset';
export const CUSTOM_GEOJSON_TREE_HOVERED = 'custom-geojson-tree-hovered';
export const CUSTOM_GEOJSON_TREE_SELECTED = 'custom-geojson-tree-selected';
export const CUSTOM_GEOJSON_TREE_SELECTED_ICON = 'custom-geojson-tree-selected-icon';
export const CUSTOM_GEOJSON_LINE_HOVERED = 'custom-geojson-line-hovered';
export const CUSTOM_GEOJSON_LINE_SELECTED = 'custom-geojson-line-selected';
export const GALLERY_ICON = 'gallery-icon';

export const TREE_LAYERS = [TREE_AREA, ADDITIONAL_TREE_AREA, TREE_HOVERED, TREE_LOW_ZOOM, TREE_HOVER_AREA, SELECTED_TREES, GALLERY_ICON];

export const RICH_ITEM_LAYERS = [
  CUSTOM_GEOJSON_TREE_HOVERED,
  CUSTOM_GEOJSON_LINE_HOVERED,
  CUSTOM_GEOJSON_TREE_AREA,
  CUSTOM_GEOJSON_TREE_HOVER_AREA,
  CUSTOM_GEOJSON_TREE_SELECTED,
  CUSTOM_GEOJSON_TREE_SELECTED_ICON,
  CUSTOM_GEOJSON_LINE_SELECTED
];

export const LANDCOVER = 'landcover';
export const HILLSHADE = 'hillshade';
export const CONTOUR = 'contour';
export const CONTOUR_LABELS = 'countour-labels';
export const LANDCOVER_GEOJSON = 'landcover-geojson';
export const HILLSHADE_GEOJSON = 'hillshade-geojson';
export const CONTOUR_GEOJSON = 'contour-geojson';
export const CONTOUR_LABELS_GEOJSON = 'countour-labels-geojson';

export const TERRAIN_LAYERS = [LANDCOVER, HILLSHADE, CONTOUR, CONTOUR_LABELS, LANDCOVER_GEOJSON, CONTOUR_GEOJSON];

export const IRRIGATION_POINTS = 'irrigation_points';
export const IRRIGATION_LINES = 'irrigation_lines';
export const IRRIGATION_POLYGONS = 'irrigation_polygons';

export const IRRIGATION_LAYERS = [IRRIGATION_POINTS, IRRIGATION_LINES, IRRIGATION_POLYGONS];

export const SAMPLE_MARKERS = 'sample_markers';
export const SELECTED_SAMPLE_MARKERS = 'selected_sample_markers';

export const POI_LAYER = 'poi';

export const LAYERS_PRIORITY = [
  ...ZONES_LAYERS,
  ...GROVES_LAYERS,
  ...GROVES_RICH_ITEMS_LAYERS,
  ...GROVES_TEXT_LAYERS,
  GROVES_HOVERED_LAYERS[0],
  ...TERRAIN_LAYERS,
  ...TREE_LAYERS,
  ...RICH_ITEM_LAYERS,
  ...HOTSPOT_LAYERS,
  ...HOTSPOT_POLYGON_LAYERS,
  BULLETIN_LAYER,
  ...IRRIGATION_LAYERS,
  GROVES_HOVERED_LAYERS[1],
  MISSION_RESOLVED_GROVE_BG,
  ...GROVES_SELECTED_LAYERS,
  MISSION_RESOLVED_GROVE_BORDER,
  MISSION_RESOLVED_GROVE_TITLE,
  ...GROVES_MISSIONS_LAYERS,
  ...FEED_LAYERS,
  POI_LAYER,
  ...REPLANT_CLUSTERS_LAYERS,
  ...PESTSP3_CLUSTERS_LAYERS,
  ...PESTS_CLUSTERS_LAYERS,
  MEASURE_AREA_LAYER,
  MEASURE_POINTS_LAYER,
  MEASURE_LINES_LAYER
];

export const TREE_AREA_SOURCE = 'tileset-source';
export const CUSTOM_GEOJSON_TREE_AREA_SOURCE = 'custom-geojson-tileset-source';

export const CLICKABLE_TREE_LAYERS = [CUSTOM_GEOJSON_TREE_HOVER_AREA, TREE_HOVER_AREA];

export type TStrokePaintType = 'categorical' | 'identity' | 'exponential' | 'interval';

export type TMapMouseEvent = MapMouseEvent & { features?: MapboxGeoJSONFeature[] | undefined };

export const getMapSourceById = (map: Map, id: string): AnySourceImpl | null => map.getSource(id);

export const setMapSourceById = (map: Map, id: string, features: turf.Feature[]) => {
  const source = getMapSourceById(map, id);

  if (source) {
    (source as GeoJSONSource).setData(turf.featureCollection(features) as any);
  }
};

export const getMapLayerById = (map: Map, id: string) => map.getLayer(id);

export const RESET_ZOOM_TRESHOLD = 18;
export const TREE_HOVERABLE_ZOOM_TRESHOLD = 19;
export const TREE_MIN_TILESET_ZOOM = 16;

export const TREE_PAINT_CONFIG = {
  'circle-radius': {
    base: 1,
    stops: [
      // TODO Commented according to Romy request. Uncomment on Request
      // [TREE_MIN_TILESET_ZOOM, 12],
      // [18, 50],
      // [TREE_HOVERABLE_ZOOM_TRESHOLD, 100],
      // [TREE_HOVERABLE_ZOOM_TRESHOLD, 8]
      [TREE_MIN_TILESET_ZOOM, 2],
      [TREE_HOVERABLE_ZOOM_TRESHOLD, 8]
    ]
  },
  // TODO Commented according to Romy request. Uncomment on Request
  // 'circle-blur': {
  //   stops: [
  //     [TREE_HOVERABLE_ZOOM_TRESHOLD, 2],
  //     [TREE_HOVERABLE_ZOOM_TRESHOLD, 0]
  //   ]
  // },
  'circle-stroke-width': {
    base: 1,
    stops: [
      [TREE_HOVERABLE_ZOOM_TRESHOLD, 0],
      [TREE_HOVERABLE_ZOOM_TRESHOLD, 3.8]
    ]
  }
};

export const removeMapLayerById = (map: Map, id: string): void => {
  if (getMapLayerById(map, id)) {
    map.removeLayer(id);
  }
};

export const removeMapSourceById = (map: Map, id: string): void => {
  if (getMapSourceById(map, id)) {
    map.removeSource(id);
  }
};

export const addLayerToMap = (mapbox: Map, layerID: string, options: AnyLayer): void => {
  const layer = getMapLayerById(mapbox, layerID);

  if (layer) return;

  mapbox.addLayer(options);
};

export const addMapSource = (mapbox: Map, id: string, data: any) => {
  if (!mapbox) return;

  const source = getMapSourceById(mapbox, id) as GeoJSONSource;
  const collection = turf.featureCollection(data) as any;

  if (source) {
    source.setData(collection);
  } else {
    mapbox.addSource(id, {
      type: 'geojson',
      data: collection
    });
  }
};

interface IReorderedLayer {
  name: string;
  zIndex: number;
}

export const reorderLayers = (mapbox: Map, layers: IReorderedLayer[]) => {
  layers
    .filter((layer: IReorderedLayer) => mapbox.getLayer(layer.name))
    .sort((current: IReorderedLayer, next: IReorderedLayer) => current.zIndex - next.zIndex)
    .forEach((layer: IReorderedLayer) => {
      mapbox.moveLayer(layer.name);
    });
};

export const fillColorStops = (colorStops: IColorStops) => ({
  type: 'categorical',
  property: 'status',
  stops: Object.keys(colorStops).map((key) => [key, colorStops[key]])
});

const wrapTileUrl = (tile: string): string => `https://storage.googleapis.com/${tile}/{z}/{x}/{y}.pbf`;

export interface IWrappedTileType {
  tiles: string[];
}

export interface IWrappedURLType {
  url: string;
}

const wrapTileType = (tile: string): IWrappedTileType => ({ tiles: [wrapTileUrl(tile)] });

const wrapURLType = (url: string): IWrappedURLType => ({ url });

const getSurveyTile = (survey: ISurvey): string | null => survey?.['tileset-cdn']?.tiles || null;

const getSurveyMapbox = (survey: ISurvey): string | null => survey.tileset || null;

const getWrappedSurveyURL = (survey: ISurvey): IWrappedTileType | null => {
  const surveyUrl = getSurveyTile(survey);
  return surveyUrl ? wrapTileType(surveyUrl) : null;
};

const getWrappedSurveyMapboxURL = (survey: ISurvey): IWrappedURLType | null => {
  const mapboxUrl = getSurveyMapbox(survey);
  return mapboxUrl ? wrapURLType(mapboxUrl) : null;
};

const getLegacyTileset = (survey: ISurvey): IWrappedTileType | IWrappedURLType | null => getWrappedSurveyURL(survey) || getWrappedSurveyMapboxURL(survey) || null;

export type TAnyWrappedTileType = IWrappedTileType | IWrappedURLType;

export const getTileset = (tileModel: ITile | null, survey: ISurvey): TAnyWrappedTileType | null => (tileModel ? wrapTileType(tileModel.tiles) : getLegacyTileset(survey));

export const loadImage = async (mapbox: Map, imagePath: string, name: string): Promise<void> => {
  if (mapbox.hasImage(name)) return Promise.resolve();

  return new Promise((resolve, reject) => {
    mapbox.loadImage(imagePath, (err, image) => {
      if (err) {
        return reject(err);
      }

      if (image && !mapbox.hasImage(name)) {
        mapbox.addImage(name, image);
      }

      return resolve();
    });
  });
};

export const getPolygonFeature = <T>(geometry: turf.Position[][], feature: T): turf.Feature<turf.Polygon, T> | null => {
  try {
    return turf.polygon(geometry, feature);
  } catch {
    return null;
  }
};

export type TPaintMatcher = ['get', string];
export type TPaintValue = ['match' | 'step', TPaintMatcher, ...(string | number)[]];

export const isGeometryType = (geoJSON: IUnifiedGeojson, geometryType: String): boolean => geoJSON.geojson.features[0]?.geometry?.type === geometryType;
