import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { mfColors } from 'vars';

const Wrapper = styled.div`
  background-color: ${mfColors.white};
  padding: 10px;
  border-radius: 8px;
  box-shadow: ${mfColors.cardShadowGrey} 0px 1px 2px 0px;
  transition: background-color 0.3s ease-in-out 0s;
`;

const CardWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  text-align: center;
`;

const Header = styled.div`
  font-family: MontserratBold;
  font-size: 14px;
`;

const Title = styled.span`
  text-transform: capitalize;
  margin-right: 2px;
`;

const Label = styled.span`
  font-family: Montserrat;
  font-size: 10px;
`;

const CardItem = styled.div`
  display: flex;
  flex-direction: column;
  padding: 19px 0;
`;

const ScoreValue = styled.span`
  font-family: MontserratBold;
  font-size: 20px;
`;

const ScoreLabel = styled.span`
  font-family: Montserrat;
  font-size: 10px;
  text-transform: capitalize;
`;

const ScoreText = styled.span`
  font-family: Montserrat;
  font-size: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ScoreBadge = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 6px;
  margin: 15px auto 4px;
`;

type CardStatistic = {
  type: number;
  value: number;
  label: string;
  color: string;
};
interface IProps {
  statistic: CardStatistic[];
  title: string;
  label: string;
}

const FarmViewStatisticDataCard = ({ statistic = [], label, title }: IProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <Header>
        <Title>{title}</Title>
        <Label>{label}</Label>
      </Header>
      <CardWrapper>
        {statistic.map((score) => (
          <CardItem key={score.type}>
            <ScoreValue>{score.value}</ScoreValue>
            <ScoreLabel>{t('shared.grove')}</ScoreLabel>
            <ScoreBadge style={{ backgroundColor: score.color }} />
            <ScoreText>{score.label}</ScoreText>
          </CardItem>
        ))}
      </CardWrapper>
    </Wrapper>
  );
};

export default FarmViewStatisticDataCard;
