import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { ICapacity } from 'models/tree';
import numberUtils from 'utils/numbers';
import { EMeasurementSystem } from 'models/region';
import { mfColors } from 'vars';

interface IProps {
  feature: ICapacity;
  metricType?: EMeasurementSystem;
  onHideReplant: (feature: ICapacity) => void;
}

const Wrapper = styled.div`
  padding: 16px 32px 32px;
`;

const Header = styled.div`
  font-family: MontserratBold;
  display: flex;
  justify-content: center;
  font-size: 16px;
  margin: 0 0 16px;
`;

const ConfirmSection = styled.div`
  font-size: 15px;
  line-height: 25px;
`;

const HideLink = styled.div`
  color: ${mfColors.blue};
  text-decoration: underline;
  text-align: center;
  cursor: pointer;
`;

const Action = styled.span`
  color: ${({ color }) => color};
  font-family: MontserratBold;
  cursor: pointer;
`;

const ActionGroup = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 8px;
`;

const ReplantPopupContent = ({ feature, metricType, onHideReplant }: IProps): JSX.Element => {
  const { t } = useTranslation();
  const [showConfirm, setShowConfirm] = useState(false);

  const title = useMemo(() => {
    const { capacity } = feature;
    if (capacity !== undefined) {
      return t(`main.info.replants${capacity > 1 ? '_plural' : ''}`, { count: capacity });
    }

    return '';
  }, [t, feature]);

  const length = useMemo(() => {
    const { length } = feature;
    if (length !== undefined) {
      return `${t('shared.length')} - ${numberUtils.normalizeDecimal(length, 2)}`;
    }
    return '';
  }, [t, feature]);

  return (
    <Wrapper
      onMouseMove={(e: React.MouseEvent) => {
        e.stopPropagation();
        e.preventDefault();
      }}
    >
      {!showConfirm && (
        <>
          <Header>{title}</Header>
          <Header>
            {length}
            {metricType === EMeasurementSystem.Metric ? ' m' : ' ft'}
          </Header>
          {feature.segmentID && <HideLink onClick={() => setShowConfirm(true)}>{t('replant.hide_segment')}</HideLink>}
        </>
      )}
      {showConfirm && (
        <ConfirmSection>
          <div style={{ textTransform: 'capitalize' }}>{t('missions.sure')}</div>
          <div>{t('replant.hide_segment_description')}</div>
          <ActionGroup>
            <Action onClick={() => setShowConfirm(false)} color={mfColors.blue}>
              {t('shared.no_keep')}
            </Action>
            <Action onClick={() => onHideReplant(feature)} color={mfColors.superRed}>
              {t('shared.yes_remove')}
            </Action>
          </ActionGroup>
        </ConfirmSection>
      )}
    </Wrapper>
  );
};

export default ReplantPopupContent;
