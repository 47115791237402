import { createGlobalStyle } from 'styled-components';
import interReg from 'assets/fonts/Inter-Regular.ttf';
import interBold from 'assets/fonts/Inter-Bold.ttf';
import srcReg from 'assets/fonts/Montserrat-Regular.ttf';
import srcBold from 'assets/fonts/Montserrat-Bold.ttf';
import 'react-toastify/dist/ReactToastify.css';

import { mfColors, mfSizes, mfShadows } from 'vars';
import { SCORE_COLORS } from 'models/colors';

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: Montserrat;
    src: url(${srcReg});
    font-weight:400;
  }

  @font-face {
    font-family: Inter;
    src: url(${interReg});
    font-weight: 400;
  }
  @font-face {
    font-family: InterBold;
    src: url(${interBold});
    font-weight: 400;
  }

  @font-face {
    font-family: MontserratBold;
    src: url(${srcBold});
    font-weight: 400;
  }

  html, body {
    width: 100%;
    height: 100%;
    font-family: Montserrat, sans-serif;
    color: ${mfColors.darkGrey}
  }

  #root {
    min-height: 100%;
    display: flex;
  }

  .App {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .mapboxgl-popup-content {
    border-radius: 8px;
    padding: 12px 0 0;
    box-shadow: ${mfShadows.popupShadow};
  }

  .mapboxgl-popup-tip {
    border: 0;
    width: 40px;
    height: 40px;
    position: relative;

    :before {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      top: -24px;
      left: 0;
      border-radius: 8px;
      background-color: #ffffff;
      transform: rotate(45deg);
      z-index: 2;
    }
  }

  .mapboxgl-popup-anchor-left {
    .mapboxgl-popup-tip {
      :before {
        top: 0;
        left: 24px;
      }
    }
  }

  .mapboxgl-popup-anchor-right {
    .mapboxgl-popup-tip {
      :before {
        top: 0;
        left: -24px;
      }
    }
  }

  .mapboxgl-popup-anchor-top-right,
  .mapboxgl-popup-anchor-top-left,
  .mapboxgl-popup-anchor-top {
    .mapboxgl-popup-tip {
      :before {
        bottom: -24px;
        top: auto;
      }
    }
  }

  .mapboxgl-marker {
    &.capacity-marker {
      font-family: MontserratBold;
      font-size: 11px;

      .wrapper {
        width: 36px;
        height: 26px;
        border-radius: 15px;
        background: ${mfColors.white};
      }

      &.grove-score-5 {
        color: ${SCORE_COLORS[5].fill};
      }

      &.grove-score-4 {
        color: ${SCORE_COLORS[4].fill};
      }

      &.grove-score-3 {
        color: ${SCORE_COLORS[3].fill};
      }

      &.grove-score-2 {
        color: ${SCORE_COLORS[2].fill};
      }

      &.grove-score-1 {
        color: ${SCORE_COLORS[1].fill};
      }

      &.grove-score-0 {
        color: ${SCORE_COLORS[0].fill};
      }

      .wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .chart {
        position: absolute;
        top: 1.5px;
        right: 1.5px;
        bottom: 1.5px;
        left: 1.5px;
        height: 33px;
        border-radius: 50%;
        display: grid;
        place-items: center;

        .value {
          position: absolute;
          text-align: center;
          z-index: 2;
        }
      }

      .chart:before {
        content: '';
        background: ${mfColors.white};
        position: absolute;
        border-radius: 50%;
        height: 27px;
        width: 27px;
      }
    }
  }

  .leaflet-top.leaflet-right .leaflet-control {
    margin-right: 12px;
    margin-top: 54px;
  }

  * {
    box-sizing: border-box;
  }

  ::-webkit-scrollbar {
    width: ${mfSizes.scrollBarWidth};
    height: ${mfSizes.scrollBarWidth};
  }

  ::-webkit-scrollbar-track {
    background: ${mfColors.greyBlue50};
  }

  ::-webkit-scrollbar-thumb {
    background: ${mfColors.scrollthumbBackground};
  }
`;

export default GlobalStyle;
