import React, { useState, useEffect, useRef, useLayoutEffect, useCallback, useMemo } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { faChevronUp, faChevronDown, faCheck } from '@fortawesome/pro-solid-svg-icons';

import { SpringAnimation } from 'atomicComponents/animations';
import FormInput from 'atomicComponents/FormInput';
import { mfColors, mfShadows } from '../vars';

export enum EPosition {
  Top = 'top',
  Bottom = 'bottom'
}

interface IControlProps {
  isControlsHidden: boolean;
}

const Control = styled.div`
  position: relative;
  background: ${({ isControlsHidden }: IControlProps) => (!isControlsHidden ? mfColors.superLightBlue : '#D9D9D9')};
  font-size: 16px;
  line-height: 20px;
  padding: 0 8px;
  min-width: ${({ isControlsHidden }: IControlProps) => (!isControlsHidden ? '160px' : '60px')};
  border-radius: ${({ isControlsHidden }: IControlProps) => (!isControlsHidden ? '16px' : '4px')};
  ${({ isControlsHidden }: IControlProps) => (!isControlsHidden ? '' : 'justify-content: center;')};

  display: flex;
  flex-direction: row;
  align-items: center;

  .icon {
    mergin: 0 12px 0 0;
  }
`;

const ControlIcon = styled(FontAwesomeIcon)`
  position: absolute;
  right: 8px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 20px;
`;

interface IOptionProps {
  isControlsHidden: boolean;
}

const Option = styled.div`
  position: relative;
  padding: 0 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-top: 1px solid ${mfColors.grey};
  ${({ isControlsHidden }: IOptionProps) => (!isControlsHidden ? '' : 'justify-content: center;')};

  .icon {
    mergin: 0 12px 0 0;
  }

  &:hover {
    background: ${mfColors.white};
  }
`;

const OptionsPlaceholder = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  border-top: 1px solid ${mfColors.grey};
  justify-content: center;
`;

interface IOptionsWrapperProps {
  isControlsHidden: boolean;
}

const OptionsWrapper = styled.div`
  position: fixed;
  animation: ${SpringAnimation} 300ms ease-in-out forwards;
  transform-origin: top center;
  background: ${({ isControlsHidden }: IOptionsWrapperProps) => (!isControlsHidden ? mfColors.superLightBlue : '#D9D9D9')};
  padding: 0;
  z-index: 7;
  max-height: 200px;
  overflow: auto;
`;

interface ISelectProps {
  height: string;
  isBordered: boolean;
  isExpanded: boolean;
  position: EPosition;
  isControlsHidden: boolean;
}

const getControlBorderRadius = ({ isExpanded, isControlsHidden, position }: ISelectProps) => {
  const size = isControlsHidden ? '4px' : '8px';
  const expandedPosition = position === EPosition.Bottom ? `${size} ${size} 0 0` : `0 0 ${size} ${size}`;
  return isExpanded ? expandedPosition : size;
};

const ControlWrapper = styled.div<ISelectProps>`
  cursor: pointer;
  color: ${mfColors.darkGrey};

  ${Control} {
    border: ${({ isBordered }: ISelectProps) => (isBordered ? `1px solid ${mfColors.grey}` : 'none')};
    box-shadow: ${({ isBordered }: ISelectProps) => (isBordered ? mfShadows.searchShadow : 'none')};
    height: ${({ height }: ISelectProps) => height};
    border-radius: ${getControlBorderRadius};
  }

  ${Option} {
    height: ${({ height }: ISelectProps) => height};
    &:first-child {
      border-top: ${({ isBordered }: ISelectProps) => (isBordered ? 'none' : `1px solid ${mfColors.grey}`)};
    }
  }

  ${OptionsPlaceholder} {
    height: ${({ height }: ISelectProps) => height};
  }

  ${OptionsWrapper} {
    display: ${(props: ISelectProps) => (props.isExpanded ? 'block' : 'none')};
    border: ${({ isBordered }: ISelectProps) => (isBordered ? `1px solid ${mfColors.grey}` : 'none')};
    border-top: none;
    border-radius: ${({ position }: ISelectProps) => (position === EPosition.Bottom ? '0 0 8px 8px' : '8px 8px 0 0')};
    box-shadow: ${({ isBordered }: ISelectProps) => (isBordered ? mfShadows.searchShadow : 'none')};
  }
`;

interface IDropdownPosition {
  top: string;
  left: string;
  bottom: string;
  width: string;
}

export interface IOption<ValueType> {
  value: ValueType;
  text: JSX.Element | string;
  key: string;
  icon?: JSX.Element;
}

interface IProps<T> {
  value: T;
  onChange: (value: T) => void;
  options: IOption<T>[];
  isBordered?: boolean;
  isSearchable?: boolean;
  searchPlaceholder?: string;
  placeholder?: string;
  height?: string;
  position?: EPosition;
  style?: { [key: string]: string };
  isControlsHidden?: boolean;
}

const FormSelect = <T extends unknown>({
  value,
  onChange,
  options,
  position = EPosition.Bottom,
  isBordered = false,
  isSearchable = false,
  searchPlaceholder = '',
  placeholder = '',
  height = '48px',
  style = {},
  isControlsHidden = false
}: IProps<T>): JSX.Element => {
  const { t } = useTranslation();
  const controlRef = useRef<HTMLDivElement>(null);
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const [searchText, setSearchText] = useState<string>('');
  const [dropdownPosition, setDropdownPosition] = useState<IDropdownPosition | null>(null);

  const selectedOption = useMemo(() => options.find((option) => option.value === value), [options, value]);

  const optionsToShow = useMemo(
    () => options.filter((option) => typeof option.text !== 'string' || option.text.toLowerCase().indexOf(searchText.toLowerCase()) !== -1),
    [options, searchText]
  );

  const trackDropdownPosition = useCallback(() => {
    if (controlRef && controlRef.current) {
      const controlRect = controlRef.current.getBoundingClientRect();
      const positionStyle: IDropdownPosition | null = {
        left: `${controlRect.left}px`,
        top: position === EPosition.Bottom ? `${controlRect.bottom}px` : 'auto',
        bottom: position === EPosition.Top ? `${window.innerHeight - controlRect.top}px` : 'auto',
        width: `${controlRect.width}px`
      };

      setDropdownPosition(positionStyle);
    }
  }, [setDropdownPosition, position]);

  const handleClickOutside = useCallback(
    (e: MouseEvent) => {
      if (controlRef.current && e.target && !controlRef.current.contains(e.target as HTMLElement)) {
        setIsExpanded(false);
      }
    },
    [setIsExpanded, controlRef]
  );

  useEffect(() => {
    setSearchText('');
  }, [isExpanded]);

  useEffect(() => {
    if (isExpanded) {
      trackDropdownPosition();
    }
  }, [isExpanded, trackDropdownPosition]);

  useLayoutEffect(() => {
    trackDropdownPosition();
    window.addEventListener('scroll', trackDropdownPosition);
    window.addEventListener('click', handleClickOutside, true);
    return () => {
      window.removeEventListener('scroll', trackDropdownPosition);
      window.removeEventListener('click', handleClickOutside, true);
    };
  }, [trackDropdownPosition, handleClickOutside]);

  return (
    <ControlWrapper
      position={position}
      isBordered={isBordered}
      height={height}
      isExpanded={isExpanded}
      ref={controlRef}
      onClick={() => setIsExpanded((expanded) => !expanded)}
      style={style}
      isControlsHidden={isControlsHidden}
    >
      <Control isControlsHidden={isControlsHidden}>
        {selectedOption?.icon && <span className="icon">{selectedOption.icon}</span>}
        <span>{selectedOption?.text || placeholder}</span>
        {!isControlsHidden && <ControlIcon icon={isExpanded ? faChevronUp : faChevronDown} />}
      </Control>
      {dropdownPosition && (
        <OptionsWrapper
          onClick={(e) => {
            e.stopPropagation();
          }}
          isControlsHidden={isControlsHidden}
          style={dropdownPosition}
        >
          {isSearchable && <FormInput inputWidth="100%" value={searchText} onChange={setSearchText} name="searchOptions" placeholder={searchPlaceholder} />}
          {optionsToShow.length ? (
            optionsToShow.map((option) => (
              <Option
                key={option.key}
                onClick={() => {
                  onChange(option.value);
                  setIsExpanded(false);
                }}
                isControlsHidden={isControlsHidden}
              >
                {option.icon && <span className="icon">{option.icon}</span>}
                <div>{option.text}</div>
                {option.value === value && !isControlsHidden && <ControlIcon icon={faCheck} />}
              </Option>
            ))
          ) : (
            <OptionsPlaceholder>{t('shared.search.no_options_found')}</OptionsPlaceholder>
          )}
        </OptionsWrapper>
      )}
    </ControlWrapper>
  );
};

export default FormSelect;
