import React, { useMemo, useState, useCallback, useEffect } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import LocalLoader from 'atomicComponents/LocalLoader';
import DataTable from 'atomicComponents/DataTable';
import Button from 'atomicComponents/Button';
import { IMission, EMissionType, TMissionType, EMissionSubtype } from 'models/mission';
import { ICreateFeedIncompleteParams, IFeed, EClippedStatus, EFeedStatus, missionFeedTypeRelation, HLB_INFECTED_STATUSES } from 'models/feed';
import farmsHooks from 'hooks/farms.hooks';
import { useFetchMissionReports } from 'services/data/farms';
import missionUtils from 'utils/mission';

import { mfColors } from 'vars';

const Wrapper = styled.div`
  width: 100%;
`;

const Title = styled.div`
  font-family: 'MontserratBold';
  margin: 0 0 24px;
`;

const TableWrapper = styled.div`
  max-height: 352px;
  border-top: 2px solid ${mfColors.grey};
  overflow: auto;

  .title-column {
    font-size: 12px;
  }
`;

const Total = styled.div`
  font-family: MontserratBold;
  font-size: 18px;
  padding: 16px 0;
  width: 100%;
  border-top: 2px solid ${mfColors.grey};
  display: flex;
  flex-direction: row;

  .column {
    margin: 0 0 0 44px;
    text-align: center;

    & .title {
      margin: 0;
    }
  }
`;

const Controls = styled.div`
  margin: 10px 0 0;
  display: flex;
  justify-content: flex-end;

  > * {
    margin: 0 0 0 12px;
  }
`;

const missionSources = new Map<TMissionType, TMissionType>().set(EMissionType.Clipping, EMissionType.HLBScouting).set(EMissionType.GeneralScouting, EMissionType.Clipping);

interface IProps {
  farmID: string;
  zoneID: string | null;
  type: TMissionType;
  subType: EMissionSubtype | null;
  onSelectFeed: (feed: ICreateFeedIncompleteParams[]) => void;
}

const CopyMissionFeed = ({ farmID, zoneID, type, subType, onSelectFeed }: IProps) => {
  const { t } = useTranslation();
  const { missions, loading: missionsLoading } = farmsHooks.useFarmMissions(farmID);
  const [feedLoading, setFeedLoading] = useState(false);
  const [checkedMissions, setCheckedMissions] = useState<string[]>([]);
  const [feeds, setFeeds] = useState<{ [key: string]: IFeed[] }>({});
  const feedSourceMissionType = missionSources.get(type) || null;
  const fetchMissionFeed = useFetchMissionReports();
  const feedSourceMissionName = useMemo(() => {
    if (!feedSourceMissionType) return '';
    return t(missionUtils.getMissionTypeKey(feedSourceMissionType));
  }, [feedSourceMissionType, t]);
  const loading = feedLoading || missionsLoading;
  const missionName = useMemo(() => t(missionUtils.getMissionTypeKey(type)), [type, t]);

  const missionsToDisplay = useMemo(() => {
    const result = missions.filter((mission) => mission.type === feedSourceMissionType).sort((a, b) => b.timestamp - a.timestamp);
    return result;
  }, [missions, feedSourceMissionType]);

  const toggleCheckedMission = useCallback((missionID: string, isChecked: boolean) => {
    if (isChecked) {
      setCheckedMissions((prev) => [...prev, missionID]);
    } else {
      setCheckedMissions((prev) => prev.filter((mission) => mission !== missionID));
    }
  }, []);

  useEffect(() => {
    setFeedLoading(true);
    Promise.all(
      missionsToDisplay.map((mission) => {
        const feedType = missionFeedTypeRelation.get(mission?.type) || (mission.subType && missionFeedTypeRelation.get(mission?.subType));
        if (feedType) {
          return fetchMissionFeed(mission.id, feedType);
        } else {
          return Promise.resolve([]);
        }
      })
    ).then((feeds) => {
      setFeeds(
        feeds.reduce((acc: { [key: string]: IFeed[] }, val: IFeed[], ind: number) => {
          const missionID = missionsToDisplay[ind]?.id;
          if (missionID) {
            return {
              ...acc,
              [missionID]: val.filter((entry) => !zoneID || entry.zoneID === zoneID)
            };
          }

          return acc;
        }, {})
      );
      setFeedLoading(false);
    });
  }, [missionsToDisplay, fetchMissionFeed, zoneID]);

  const selectedTrees = useMemo(() => {
    const selectedFeed = checkedMissions
      .reduce(
        (acc: ICreateFeedIncompleteParams[], missionID) => [
          ...acc,
          ...(feeds[missionID] || []).filter((entry) => {
            if (type === EMissionType.GeneralScouting) return entry?.data?.clippingStatus === EClippedStatus.Clipped;
            return !!entry?.data?.hlbStatus && HLB_INFECTED_STATUSES.includes(entry.data.hlbStatus);
          })
        ],
        []
      )
      .map((feed) => {
        const feedType = missionFeedTypeRelation.get(type) || (subType && missionFeedTypeRelation.get(subType));
        return {
          ...feed,
          type: feedType || feed.type,
          status: EFeedStatus.InProgress
        };
      });
    return selectedFeed.filter((row, index, self) => self.findIndex((entry) => entry.geoObjectID === row.geoObjectID) === index);
  }, [checkedMissions, feeds, type, subType]);

  const tableConfig = useMemo(() => {
    const columns = missionUtils.getContinuousMissionColumns(feedSourceMissionType, feeds, t, toggleCheckedMission, checkedMissions);
    return columns;
  }, [t, toggleCheckedMission, checkedMissions, feeds, feedSourceMissionType]);

  const tableProps = useMemo(() => {
    const props = {
      config: tableConfig,
      data: missionsToDisplay,
      showFooter: false,
      rowsPerPage: 0,
      placeholder: <>{t('missions.there_are_no_missions_matching_the_active_filter')}</>
    };
    return props;
  }, [t, tableConfig, missionsToDisplay]);

  if (!feedSourceMissionType) return null;

  return (
    <Wrapper>
      <Title>
        {t('missions.trees_selected_from_mission', { mission: feedSourceMissionName })}
        &nbsp;&#x27F6;&nbsp;
        {t('missions.used_in_a_new_mission', { mission: missionName })}
      </Title>
      {loading && <LocalLoader />}
      {!loading && (
        <>
          <TableWrapper>
            <DataTable<IMission> {...tableProps} />
          </TableWrapper>
          <Total>
            <div className="column title">{t('missions.total_trees_selected_for_the_mission')}</div>
            <div className="column">{selectedTrees.length}</div>
          </Total>
          <Controls>
            <Button isDisabled={!selectedTrees.length} onClick={() => onSelectFeed(selectedTrees)} text={t('missions.select_trees_for_mission', { mission: missionName })} />
          </Controls>
        </>
      )}
    </Wrapper>
  );
};

export default CopyMissionFeed;
