import React from 'react';
import styled from 'styled-components';
import { IMission } from 'models/mission';
import { Dropdown } from 'atomicComponents';
import { EPosition, EToggleType } from 'atomicComponents/Dropdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisV } from '@fortawesome/pro-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import dateUtils from 'utils/date';
import dateFormats from 'models/date';

const DropdownRow = styled.div`
  margin: 0 0 4px;

  &.title {
    font-family: MontserratBold;
  }
`;

const DropdownWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
`;

interface IProps {
  mission: IMission;
}

const UpdatedAtCell = ({ mission }: IProps): JSX.Element => {
  const { t } = useTranslation();
  const { updatedAt } = mission;

  return (
    <>
      <Dropdown
        toggleType={EToggleType.Click}
        control={
          <span className="icon">
            <FontAwesomeIcon icon={faEllipsisV} />
          </span>
        }
        content={
          <DropdownWrapper>
            <DropdownRow className="title">{mission.title}</DropdownRow>
            <DropdownRow>{t('missions.created_on', { date: dateUtils.formatDate(mission.timestamp, dateFormats.DATE_FORMAT_DD_MMMM_YYYY) })}</DropdownRow>
            <DropdownRow>{t('missions.by', { user: mission.createdBy })}</DropdownRow>
            {updatedAt && <DropdownRow>{t('missions.last_updated', { date: dateUtils.formatDate(updatedAt, dateFormats.DATE_FORMAT_DD_MMMM_YYYY) })}</DropdownRow>}
          </DropdownWrapper>
        }
        position={EPosition.BottomRight}
      />
    </>
  );
};

export default UpdatedAtCell;
