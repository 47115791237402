import React, { useCallback, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { useModal } from 'react-modal-hook';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalculator } from '@fortawesome/pro-solid-svg-icons';

import { isShowReplantInventorySelector, setIsShowReplantInventory } from 'redux/appStateSlice';
import Modal from 'atomicComponents/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { capacityStatisticByRulesSelector, setCheckedReplantGroves, setStateReplantRules } from 'redux/replant/replantSlice';
import { ICapacityStatistic } from 'models/statistic';
import replantUtils from 'utils/replant';
import ReplantCalculator from 'containers/ReplantCalculator';

import { IReplantRules } from 'models/replant';
import { mfColors } from 'vars';
import { Button } from 'atomicComponents';
import replantHooks from 'hooks/replant.hooks';
import { useUpdateReplantRules } from 'services/data/replant';
import { useTranslation } from 'react-i18next';

const DialogToggle = styled.div`
  cursor: pointer;

  > span {
    margin: 0 8px 0 0;
  }
`;

const DialogIcon = styled(FontAwesomeIcon)`
  height: 18px;
  margin-right: 8px;
`;

interface IProps {
  showText?: boolean;
}

const ReplantInventoryDialog = ({ showText = false }: IProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const toggleReplantInventoryDialog = (event: React.MouseEvent) => {
    event.stopPropagation();

    dispatch(setIsShowReplantInventory(true));
  };

  return (
    <DialogToggle onClick={toggleReplantInventoryDialog}>
      <span>{t('missions.replant_calculator')}</span>
      <DialogIcon icon={faCalculator} />
    </DialogToggle>
  );
};

export const useReplantInventoryDialog = (
  replantRules: IReplantRules,
  isFarmView: boolean,
  checkedGroves?: string[],
  onSetCheckedGroves?: (groves: string[]) => void,
  handleUpdateReplant?: () => void
) => {
  const { t } = useTranslation();
  const capacityStatistic = useSelector(capacityStatisticByRulesSelector);
  const isShowReplantInventory = useSelector(isShowReplantInventorySelector);
  const dispatch = useDispatch();
  const updateReplantRules = useUpdateReplantRules();
  const resetReplantRules = replantHooks.useResetFarmRules();

  useEffect(() => {
    if (!capacityStatistic) return;

    if (!checkedGroves && onSetCheckedGroves) {
      const selectedStatistic = Object.keys(capacityStatistic);

      onSetCheckedGroves(selectedStatistic);
    }
  }, [checkedGroves, capacityStatistic, onSetCheckedGroves]);

  useEffect(() => {
    if (checkedGroves && checkedGroves.length) {
      const checkedReplantGroves = checkedGroves.map((groveID) => {
        const data = capacityStatistic?.[groveID] as ICapacityStatistic;

        return { groveID, replants: data?.statistic || 0 };
      });

      dispatch(setCheckedReplantGroves(checkedReplantGroves));
    }
  }, [dispatch, checkedGroves, capacityStatistic]);

  const selectedStatistic = useMemo(() => {
    if (!capacityStatistic) return null;

    return replantUtils.getSelectedStatistic(capacityStatistic, checkedGroves);
  }, [capacityStatistic, checkedGroves]);

  const onUpdateReplantRules = useCallback(
    (changedRules: IReplantRules) => {
      if (isFarmView) {
        updateReplantRules(replantRules.id, changedRules);
      } else {
        dispatch(setStateReplantRules({ ...changedRules, id: replantRules.id }));
      }

      dispatch(setIsShowReplantInventory(false));
      if (handleUpdateReplant) {
        handleUpdateReplant();
      }
    },
    [dispatch, replantRules, handleUpdateReplant, isFarmView, updateReplantRules]
  );

  const onResetSettings = useCallback(() => {
    resetReplantRules(replantRules.id);
    dispatch(setIsShowReplantInventory(false));
  }, [resetReplantRules, replantRules.id, dispatch]);

  const CalculatorControls = useMemo(() => {
    if (isFarmView) {
      return {
        saveControl: <span>{t('replant.calculator.update_farm_setup')}</span>,
        resetControl: <Button isSecondary hoverBackground={mfColors.blue} hoverColor={mfColors.white} onClick={onResetSettings} text={t('replant.inventory.reset_to_original')} />
      };
    }

    return { saveControl: <span>{t('replant.calculator.update_calculations')}</span> };
  }, [isFarmView, onResetSettings, t]);

  const [showCalculator, hideCalculator] = useModal(
    () => (
      <Modal
        width="1032px"
        onClose={() => {
          dispatch(setIsShowReplantInventory(false));
        }}
      >
        <ReplantCalculator
          statistic={selectedStatistic}
          replantRules={replantRules}
          close={() => dispatch(setIsShowReplantInventory(false))}
          onSave={onUpdateReplantRules}
          controls={CalculatorControls}
        />
      </Modal>
    ),
    [selectedStatistic, replantRules, dispatch, onUpdateReplantRules]
  );

  useEffect(() => {
    if (isShowReplantInventory) {
      showCalculator();
    } else {
      hideCalculator();
    }
  }, [isShowReplantInventory, showCalculator, hideCalculator]);
};

export default ReplantInventoryDialog;
