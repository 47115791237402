import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight } from '@fortawesome/pro-solid-svg-icons';
import { mfColors } from 'vars';

interface IProps {
  items: string[];
  onChangeItem: (item: string) => void;
  selectedItem: string | null;
}

const NavigationContainer = styled.div`
  display: flex;
  background-color: ${mfColors.white};
  padding: 6px;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
`;

const ArrowButton = styled.div<{ isDisabled: boolean }>`
  display: flex;
  cursor: pointer;
  padding: 0px 10px;
  align-items: center;
  color: ${({ isDisabled }) => (isDisabled ? mfColors.lightGrey : mfColors.black)};
`;

const CardNavigation = ({ items, onChangeItem, selectedItem = '' }: IProps): JSX.Element => {
  const { t } = useTranslation();
  const handleNext = () => {
    if (!selectedItem) return;
    const index = items.indexOf(selectedItem);

    if (index !== items.length - 1 && index !== -1) {
      onChangeItem(items[index + 1]);
    }
  };

  const handlePrevious = () => {
    if (!selectedItem) return;
    const index = items.indexOf(selectedItem);
    if (index !== 0 && index !== -1) {
      onChangeItem(items[index - 1]);
    }
  };

  const isBeforeDisabled = !!selectedItem && !items.indexOf(selectedItem);
  const isNextDisabled = !!selectedItem && items.length === items.indexOf(selectedItem) + 1;

  return (
    <div>
      {items.length ? (
        <NavigationContainer>
          <ArrowButton onClick={handlePrevious} isDisabled={isBeforeDisabled}>
            <FontAwesomeIcon icon={faArrowLeft} />
          </ArrowButton>
          <div>{selectedItem}</div>
          <ArrowButton onClick={handleNext} isDisabled={isNextDisabled}>
            <FontAwesomeIcon icon={faArrowRight} />
          </ArrowButton>
        </NavigationContainer>
      ) : (
        t('card_navigation.empty_state')
      )}
    </div>
  );
};

export default CardNavigation;
