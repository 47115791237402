import { useCallback } from 'react';
import api from 'services/data/providers/api';

import useLogger, { EStorageType } from 'hooks/logger.hooks';
import { IWeather } from 'models/weather';

const useFetchWeather = (): ((farmID: string, groveID?: string) => Promise<IWeather[]>) => {
  const { logError } = useLogger();

  return useCallback(
    async (farmID: string, groveID?: string): Promise<IWeather[]> => {
      const path = '/getWeatherStatistic';
      const body = {
        farmID,
        ...(groveID ? { groveID } : {})
      };

      try {
        const response = await api.post<{ data: string }>(path, body);
        return JSON.parse(response.data);
      } catch (e) {
        logError(e as Error, path, EStorageType.Api);
        return Promise.resolve([]);
      }
    },
    [logError]
  );
};

export default useFetchWeather;
