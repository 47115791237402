import React, { useEffect, useState, useCallback, useMemo, createContext, useContext, useRef } from 'react';
import styled from 'styled-components';
import { useParams, useNavigate, useSearchParams, useLocation } from 'react-router-dom';
import { useModal } from 'react-modal-hook';
import { GeoJSONSource } from 'mapbox-gl';
import { useDispatch, useSelector } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import SideBar from 'containers/SideBar';
import * as turf from '@turf/turf';
import MainPageMenu from 'containers/MainPageMenu';
import GroveCommoditiesSelector from 'containers/GroveCommoditiesSelector';
import MainPageBreadcrumbs from 'components/main/MainPageBreadcrumbs/MainPageBreadcrumbs';
import { MapContext } from 'components/shared/Map';
import RichItemsLegend from 'components/main/MapControls/RichItemsLegend';
import SidebarControls, { IGroveLayersFlags } from 'components/main/MapControls/SidebarControls';
import { IHighlightedRichItemsEvent } from 'components/main/MapControls/RichItemsChart';
import FarmInfo from 'components/main/FarmInfo/FarmInfo';
import FarmBulletin from 'containers/FarmBulletin';
import LocalLoader from 'atomicComponents/LocalLoader';
import HotspotsTimeline from 'components/main/HotspotsTimeline/HotspotsTimeline';
import GroveViewDataCard from 'components/main/DataCards/GroveViewDataCard';
import FarmViewDataCard from 'components/main/DataCards/FarmViewDataCard';
import ScoresDistributionModal from 'containers/ScoresDistributionModal';
import NewDisplayInvitation from 'components/shared/NewDisplayInvitation';
import Modal from 'atomicComponents/Modal';
import farmsHooks from 'hooks/farms.hooks';
import signInHooks from 'hooks/signIn.hooks';
import commoditiesHooks from 'hooks/commodities.hooks';
import mapHooks, { IPopupFeature } from 'hooks/map.hooks';
import treeHooks from 'hooks/trees.hooks';
import {
  drawZones,
  drawGroves,
  setZoneEventHandlers,
  removeZoneEventHandlers,
  setGroveEventHandlers,
  removeGroveEventHandlers,
  addZoneLabelMarkers,
  removeAllZoneMarkers,
  drawHoveredGrove,
  clearHoveredGrove
} from 'services/util/mapFarm';
import { drawBulletins, clearBulletins, toggleBulletinsLayerVisibility } from 'services/util/mapBulletin';
import { getTileset, TAnyWrappedTileType, TMapMouseEvent, SELECTED_TREES, HOTSPOT_LAYERS, addMapSource, TPaintValue } from 'services/util/mapHelpers';
import mapTilesetService from 'services/util/mapTileset';
import { addTerrainLayers, removeTerrainLayers } from 'services/util/mapTerrain';
import { addIrrigationLayers, removeIrrigationLayers } from 'services/util/mapIrrigation';
import mapGroveRichItemsService, { SELECTED_RICH_ITEMS_LAYERS } from 'services/util/mapGroveRichItems';
import mapFarmRichItemsService from 'services/util/mapFarmRichItems';
import addRastersLayers, { removeRasterLayers } from 'services/util/mapRasters';
import { drawHotspots, clearHotspots, clearHotspotsIcons } from 'services/util/mapHotspot';
import { useFetchCustomTerrain, useFetchIrrigationSources } from 'services/data/farms';
import { useAnalytics } from 'hooks/analytics.hooks';
import { IGrove } from 'models/grove';
import { EEventType } from 'models/analytics';
import { ITreeMapboxFeature } from 'models/tree';
import { IUnifiedHotspot, EHotspotType } from 'models/hotspot';
import { EReportType, reportRichItemsMap, TAnyReport } from 'models/report';
import { ERichItemType, IRichItemMapboxFeature, EVIDENCE_IGNORED_RICH_ITEMS, RICH_ITEM_TYPES_GEO_ANALYTICS, RICH_ITEM_FRUIT_TYPES } from 'models/richItem';
import { CUSTOM_TERRAIN_FARMS } from 'models/terrain';
import { ISurveyWithTimeRange } from 'models/survey';
import { drawPoi } from 'services/util/mapPoi';
import { findFeaturesInsidePolygon, isDescendant, sortBy, uniq, uniqBy } from 'utils/helpers';
import dateUtils from 'utils/date';
import hotspotsUtils from 'utils/hotspots';
import dateFormats from 'models/date';
import { InitialLayersState } from 'models/map';
import { useUpdateFarmVisit } from 'services/data/users';
import {
  getDistanceMeasurementStatus,
  getDistanceMeasurementValue,
  getDistanceMeasurementType,
  getDistanceMeasurementPolygonSelector,
  setMeasurePoints
} from 'redux/distanceMeasurementSlice';
import HotspotModal from 'containers/HotspotModal';
import GroveControls from 'containers/GroveControls';
import {
  isCapacityShownSelector,
  isFarmLayersFilteredSelector,
  isSideBarExpandedSelector,
  setEvidenceParams,
  setIsCapacityShown,
  setIsFarmLayersFiltered
} from 'redux/appStateSlice';

import { mfColors, mfSizes } from 'vars';
import {
  feedViewBqStatsSelector,
  getSelectedFarmSurvey,
  nextSurveySelector,
  previousSurveySelector,
  isOldSurveySelector,
  selectedSurveyStatsSelector,
  setSelectedSurvey,
  publishedGrovesInLastSurveysSelector
} from 'redux/farm/farmSlice';
import { regionMeasurementSystemSelector, regionSelector } from 'redux/region/regionSlice';
import { richItemTypeSelector, richItemSubTypeSelector, setRichItemType, setRichItemSubType, setIsMissingRichItem } from 'redux/richItems/richItemsSlice';
import { ICommodityCombination } from 'models/commodity';
import dashboardHooks from 'hooks/dashboard.hooks';
import appConfigHooks from 'hooks/appConfig.hooks';
import { IRichItemRangeConfig } from 'services/data/appConfig';
import { ICapacityStatistic, IParsedFruitYieldStatistic } from 'models/statistic';
import ZoomControls from 'components/main/MapControls/ZoomControls';
import SegmentTool from 'containers/SegmentTool';
import { capacityStatisticByRulesSelector, farmReplantRulesSelector, hiddenSegmentsSelector, newSegmentsSelector } from 'redux/replant/replantSlice';
import replantHooks, { NON_SURVEYID } from 'hooks/replant.hooks';
import replantUtils from 'utils/replant';
import TilesHooks from 'hooks/tiles.hooks';
import feedHooks from 'hooks/feed.hooks';
import ClusterMetrics from 'components/main/Cluster/ClusterMetrics';
import { setMeasurementLayerPaint } from 'services/util/mapMeasurement';
import { getFarmBulletinThresholds } from 'redux/bulletin/bulletinSlice';
import usersHooks from 'hooks/users.hooks';
import { addReplantsClustersMarkers, setReplantsClusterSource } from 'services/util/mapReplants';
import { useReplantInventoryDialog } from 'containers/ReplantInventoryDialog';

const ScrollingContent = styled.div`
  overflow-y: auto;
  padding: 0 16px 0 0;
`;

interface ILegendProps {
  isExpanded: boolean;
}

const LegendWrapper = styled.div`
  position: absolute;
  top: 12px;
  left: calc(${({ isExpanded }: ILegendProps) => (isExpanded ? `${mfSizes.sideBarAreaWidth} + ${mfSizes.staticMenuWidth} + 12px` : `${mfSizes.staticMenuWidth} + 12px`)});
  transition: left 0.5s ease-in;
  z-index: 2;
  display: flex;
  flex-direction: row;
  right: 12px;

  > div {
    align-self: flex-start;
  }
`;

interface ILegendProps {
  isExpanded: boolean;
}

const getInvitationOffset = ({ isExpanded }: ILegendProps) => {
  if (isExpanded) return `50% + 25px + ${mfSizes.sideBarAreaWidth} / 2 + ${mfSizes.staticMenuWidth} / 2`;
  return `50% + 25px + ${mfSizes.staticMenuWidth} / 2`;
};

const InvitationWrapper = styled.div`
  position: absolute;
  top: 12px;
  left: calc(${getInvitationOffset});
  transform: translateX(-50%);
  z-index: 3;
  width: 660px;
  max-width: 45%;
  transition: width 0.3s;

  @media (max-width: 1260px) {
    width: 20%;
    max-width: 100%;
  }

  @media (max-width: 1360px) {
    width: 25%;
    max-width: 100%;
  }

  @media (max-width: 1500px) {
    width: 35%;
    max-width: 100%;
  }

  &:hover {
    width: 660px;
  }
`;

const SideBarControlsWrapper = styled.div`
  position: absolute;
  bottom: 32px;
  right: 12px;
  margin: 0px auto;
  z-index: 2;
`;

const ZoomControlsWrapper = styled.div`
  position: absolute;
  bottom: 32px;
  right: 70px;
  margin: 0px auto;
  z-index: 2;
`;

interface IGroveControlsProps {
  isExpanded: boolean;
}

const GroveControlsWrapper = styled.div`
  position: absolute;
  bottom: 32px;
  left: calc(${({ isExpanded }: IGroveControlsProps) => (isExpanded ? `${mfSizes.sideBarAreaWidth} + ${mfSizes.staticMenuWidth} + 12px` : `${mfSizes.staticMenuWidth} + 12px`)});
  transition: left 0.5s ease-in;
  z-index: 2;
`;

const BlockSplitter = styled.div`
  width: auto;
  height: 1px;
  background: ${mfColors.grey};
  margin: 0 12px;
`;

const StaticContent = styled.div`
  margin: 0 16px 0 0;
`;

const EXIT_GROVE_TRESHOLD = 14;

interface IBulletinContext {
  expandedBulletin: EReportType | null;
  preselectedBulletin: EReportType | null;
  onExpandBulletin: (type: EReportType | null) => void;
  onChangePreselectedBulletin: (type: EReportType | null) => void;
  preselectedBulletinReports: TAnyReport[];
  onPreselectedBulletinReports: (reports: TAnyReport[]) => void;
}

export const BulletinContext = createContext<IBulletinContext | null>(null);

const MapScreen = (): JSX.Element => {
  const { logEvent } = useAnalytics();
  const [layers, setLayers] = useState<IGroveLayersFlags>(InitialLayersState);
  const [selectedHotspot, setSelectedHotspot] = useState<IUnifiedHotspot | null>(null);
  const [selectedTimepickerHotspots, setSelectedTimepickerHotspots] = useState<IUnifiedHotspot[]>([]);
  const [hoveredHotspot, setHoveredHotspot] = useState<IUnifiedHotspot | null>(null);
  const [treeForPopup, setTreeForPopup] = useState<IPopupFeature | null>(null);
  const [highlightedRichItems, setHighlightedRichItems] = useState<IHighlightedRichItemsEvent | null>(null);
  const [selectedTreeFeature, setSelectedTreeFeature] = useState<ITreeMapboxFeature | IRichItemMapboxFeature | null>(null);
  const [expandedBulletin, setExpandedBulletin] = useState<EReportType | null>(null);
  const [preselectedBulletin, setPreselectedBulletin] = useState<EReportType | null>(null);
  const [preselectedBulletinReports, setPreselectedBulletinReports] = useState<TAnyReport[]>([]);
  const activeRichItem = useSelector(richItemTypeSelector);
  const feedViewBqStatistic = useSelector(feedViewBqStatsSelector);
  const activeRichItemSubType = useSelector(richItemSubTypeSelector);
  const isCapacityShown = useSelector(isCapacityShownSelector);
  const isFarmLayersFiltered = useSelector(isFarmLayersFilteredSelector);
  const activeRichItemRef = useRef(activeRichItem);
  const [highlightedScores, setHighlightedScores] = useState<number[]>([]);
  const [dataCardOpened, setDataCardOpened] = useState(false);
  const [selectedCommodity, setSelectedCommodity] = useState<ICommodityCombination>();
  const [selectedVarieties, setSelectedVarieties] = useState<ICommodityCombination[]>([]);
  const [dynamicPaintValue, setDynamicPaintValue] = useState<TPaintValue | undefined>(undefined);
  const { getFarmVectiles, vectiles, loading: vectilesLoading } = farmsHooks.useGetFarmVectiles();
  const { getGroveTrees, trees } = farmsHooks.useGroveTrees();
  const { satelliteHotspots, hotspots, loading: hotspotsLoading, getFarmHotspots, resetHotspots } = farmsHooks.useFarmHotspots();
  const { commodityCombinations } = commoditiesHooks.useGetAllCommodityCombinations();
  const richItemMeasurementRanges = appConfigHooks.useGetRichItemRanges();
  const { activeMap: map, setMapOptions } = useContext(MapContext);
  const updateFarmVisit = useUpdateFarmVisit();
  const { search } = useLocation();
  const { setExpandedTreeImage } = feedHooks.useFeedImageModal();

  const params = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const surveyID = searchParams.get('surveyID');
  const dispatch = useDispatch();
  const setSurvey = useCallback((survey: ISurveyWithTimeRange | null) => dispatch(setSelectedSurvey(survey)), [dispatch]);
  const { selectedFarm, selectedSurvey, selectedGrove, selectedZone } = farmsHooks.useSelectedFarmEntities();
  const selectedSurveyRef = useRef(selectedSurvey);
  const selectedGroveRef = useRef(selectedGrove);
  const { farms, zones, surveyGroves: groves, surveys } = farmsHooks.useFarmEntities();
  const publishedGrovesInLastSurveys = useSelector(publishedGrovesInLastSurveysSelector);

  const selectedFarmSurvey = useSelector(getSelectedFarmSurvey);
  const previousSurvey = useSelector(previousSurveySelector);
  const nextSurvey = useSelector(nextSurveySelector);
  const isOldSurvey = useSelector(isOldSurveySelector);
  const surveyAnomalies = useMemo(() => hotspotsUtils.filterSurveyHotspots(surveys, satelliteHotspots, selectedSurvey?.id), [satelliteHotspots, surveys, selectedSurvey]);
  const satelliteAnomalies = useMemo(() => hotspotsUtils.filterGroveHotspots(surveyAnomalies, selectedGrove?.id), [surveyAnomalies, selectedGrove]);
  const selectedSurveyStats = useSelector(selectedSurveyStatsSelector);
  const user = signInHooks.useSignedInUser();
  const { feed, reportLocationTreshold } = farmsHooks.useFarmFeed(selectedFarm?.id, selectedSurvey?.id, selectedGrove?.id);
  const { poi } = farmsHooks.useFarmPoi(selectedFarm?.id, selectedGrove?.id);
  const { richItems, richItemsLoaded } = farmsHooks.useRichItems(selectedFarm?.id, selectedGrove?.id, selectedFarmSurvey?.id, selectedFarm?.config?.features);
  const { defaultConfigs: richItemsConfigs } = farmsHooks.useRichItemsDefaultConfigs();
  const { richItems: grovesRichItems } = farmsHooks.useGrovesRichItems(
    selectedFarm?.id,
    selectedSurvey?.id,
    activeRichItem === ERichItemType.FruitYield ? activeRichItem : undefined
  );
  const {
    statistic,
    healthStatistic,
    isLoading: statisticLoading
  } = farmsHooks.useRichItemsStatistic(activeRichItem, selectedFarm?.id, selectedFarmSurvey?.id, selectedFarm?.config?.features);
  const { rasterLayers } = farmsHooks.useGroveRastersTiles(selectedFarm, selectedGrove, selectedSurvey);
  const navigate = useNavigate();
  const mapOptions = mapHooks.useGetMapOptions(zones, selectedZone, selectedGrove, selectedHotspot);
  const { groveIDs: newlyPublishedGroves } = farmsHooks.useNewPublishedGroves();
  const replantRules = useSelector(farmReplantRulesSelector);

  const fruitStatistic = useMemo(() => {
    if (activeRichItem === ERichItemType.FruitYield && statistic) {
      return grovesRichItems.reduce((acc, next) => {
        const { groveID } = next;
        const groveStatistic = statistic[groveID];

        return { ...acc, [groveID]: groveStatistic };
      }, {});
    }

    return null;
  }, [activeRichItem, grovesRichItems, statistic]);

  signInHooks.useIsAllowedFarm(params.farmID);
  treeHooks.useFilterTrees(map, selectedVarieties);
  dashboardHooks.useGetCompanies();

  const isNewDisplayInviteActive = window.location.hostname.includes('newdisplay');

  const bulletinContext = useMemo(
    () => ({
      expandedBulletin,
      preselectedBulletin,
      onExpandBulletin: setExpandedBulletin,
      onChangePreselectedBulletin: setPreselectedBulletin,
      preselectedBulletinReports,
      onPreselectedBulletinReports: setPreselectedBulletinReports
    }),
    [expandedBulletin, preselectedBulletin, preselectedBulletinReports]
  );

  mapHooks.useMapFeed(map, feed, selectedFarm, selectedGrove, reportLocationTreshold, layers.flags, null, false, setExpandedTreeImage);

  useEffect(() => {
    if (mapOptions.bounds) {
      setMapOptions(mapOptions);
    }
  }, [mapOptions, setMapOptions]);

  const region = useSelector(regionSelector);
  const regionMeasurementSystem = useSelector(regionMeasurementSystemSelector);
  const onClosePopup = useCallback(() => setSelectedTreeFeature(null), []);
  const onHideSegment = replantHooks.useHideSegment(selectedFarm?.id, selectedGrove?.id, selectedSurvey?.id);
  const { showPopup } = mapHooks.useTreePopup(map, region?.measurementSystem, null, onClosePopup, onHideSegment);
  const { showPopup: showHotspotPopup } = mapHooks.useHotspotPopup(map);
  const richItemsRangesByRegion = useMemo((): IRichItemRangeConfig | null => {
    if (regionMeasurementSystem && richItemMeasurementRanges && [...RICH_ITEM_TYPES_GEO_ANALYTICS, ERichItemType.FreeArea].includes(activeRichItem)) {
      const richItemMetric = richItemMeasurementRanges[regionMeasurementSystem][activeRichItem] as IRichItemRangeConfig;

      return richItemMetric;
    }

    if (selectedGrove && RICH_ITEM_FRUIT_TYPES.includes(activeRichItem) && selectedGrove.richItemRanges) {
      const groveRichItemRanges = selectedGrove.richItemRanges[ERichItemType.FruitAggregation];
      const { mapbox, ranges } = groveRichItemRanges;

      return {
        mapbox: JSON.parse(mapbox),
        ranges
      };
    }

    return null;
  }, [activeRichItem, richItemMeasurementRanges, regionMeasurementSystem, selectedGrove]);
  const selectedRichItem = farmsHooks.useSelectedRichItem(richItems, activeRichItem, activeRichItemSubType, richItemsRangesByRegion?.mapbox);
  const { geoJSON: richItemGeoJSON, loading: richItemsLoading } = farmsHooks.useRichItemGeoJSON(
    selectedRichItem,
    trees,
    selectedFarm?.id,
    selectedGrove?.id,
    selectedSurvey?.id,
    region?.measurementSystem
  );
  replantHooks.useReplantCapacityStatistic(ERichItemType.Replant, selectedFarm?.id, selectedFarmSurvey?.id, regionMeasurementSystem);
  useReplantInventoryDialog(replantRules, true);
  const capacityStatistic = useSelector(capacityStatisticByRulesSelector);
  const hiddenSegments = useSelector(hiddenSegmentsSelector);
  const newSegments = useSelector(newSegmentsSelector);
  const farmBulletinThresholds = useSelector(getFarmBulletinThresholds);
  const replantBulletin = replantHooks.useReplantBulletinGroves(groves, healthStatistic, farmBulletinThresholds);
  const selectedCapacityStatistic = useMemo(() => {
    if (!capacityStatistic) return null;

    return replantUtils.getSelectedStatistic(capacityStatistic);
  }, [capacityStatistic]);

  usersHooks.useUserSurveyPublishedAlert(selectedFarm?.id, selectedFarmSurvey?.id);

  useEffect(() => {
    if (map) {
      if (!isCapacityShown) {
        setReplantsClusterSource(map, []);
        setTreeForPopup(null);
        showPopup(null);
        setSelectedTreeFeature(null);
        toggleBulletinsLayerVisibility(map, 'visible');
      }

      if (selectedCapacityStatistic && isCapacityShown) {
        const grovesToShow = selectedZone ? groves.filter((entry) => entry.zoneID === selectedZone.id) : groves;
        addReplantsClustersMarkers(map, grovesToShow, selectedCapacityStatistic);
        toggleBulletinsLayerVisibility(map, 'none');
      }
    }

    return () => {
      if (map) {
        setReplantsClusterSource(map, []);
      }
    };
  }, [map, groves, selectedCapacityStatistic, statistic, isCapacityShown, selectedZone, showPopup]);

  useEffect(() => {
    if (!selectedCapacityStatistic) {
      dispatch(setIsCapacityShown(false));
      dispatch(setIsFarmLayersFiltered(false));
    }

    return () => {
      if (!selectedCapacityStatistic) {
        dispatch(setIsCapacityShown(false));
        dispatch(setIsFarmLayersFiltered(false));
      }
    };
  }, [selectedCapacityStatistic, dispatch, expandedBulletin]);

  const { geoJSON: capacityGeoJSON } = replantHooks.useFetchDrawSegments(ERichItemType.Capacity, selectedFarm?.id, selectedGrove?.id, NON_SURVEYID, richItemMeasurementRanges);

  const capacityGeoJSONByRules = useMemo(() => {
    if (!capacityGeoJSON || !replantRules) return null;

    return replantUtils.getReplantGeojson(capacityGeoJSON, replantRules, hiddenSegments, newSegments);
  }, [capacityGeoJSON, replantRules, hiddenSegments, newSegments]);

  const capacityGroveData = useMemo(() => {
    if (!selectedCapacityStatistic || !selectedGrove) return null;

    return (selectedCapacityStatistic[selectedGrove?.id] as ICapacityStatistic)?.statistic;
  }, [selectedCapacityStatistic, selectedGrove]);

  useEffect(() => {
    if (!capacityGroveData && selectedGrove) {
      dispatch(setIsCapacityShown(false));
      dispatch(setIsFarmLayersFiltered(false));
    }
  }, [selectedGrove, capacityGroveData, dispatch]);

  const fetchCustomTerrain = useFetchCustomTerrain();
  const fetchIrrigationSources = useFetchIrrigationSources(selectedFarm);
  const distanceMeasurementStatus = useSelector(getDistanceMeasurementStatus);
  const distanceValue = useSelector(getDistanceMeasurementValue);
  const distanceMeasurementType = useSelector(getDistanceMeasurementType);
  const distanceMeasurementPolygon = useSelector(getDistanceMeasurementPolygonSelector);
  const isSideBarExpanded = useSelector(isSideBarExpandedSelector);
  const groveTilePath = TilesHooks.useGetGroveTiles(vectiles, selectedGrove, selectedSurvey);
  const { sourceTrees } = treeHooks.useGetGroveSurveyTrees(selectedGrove, groveTilePath);
  const { treeClickHandler } = mapHooks.useTreeClickHandler(map, trees, sourceTrees, setTreeForPopup, setSelectedTreeFeature, distanceMeasurementStatus);
  const [showHotspotModal, hideHotspotModal] = useModal(
    () => (
      <Modal width="420px" onClose={() => hideHotspotModal()}>
        <HotspotModal hotspot={selectedHotspot} />
      </Modal>
    ),
    [selectedHotspot]
  );
  const [showHealthModal, hideHealthModal] = useModal(
    () => (
      <Modal
        width="1320px"
        onClose={() => {
          hideHealthModal();
          setHighlightedScores([]);
        }}
      >
        <ScoresDistributionModal selectedScores={highlightedScores} />
      </Modal>
    ),
    [highlightedScores]
  );

  const clusterTrees = useMemo(() => {
    if (trees && distanceMeasurementPolygon) {
      const treeModels = Object.keys(trees).map((treeID) => ({
        ...trees[treeID],
        treeID
      }));

      return findFeaturesInsidePolygon(distanceMeasurementPolygon, treeModels);
    }

    return [];
  }, [trees, distanceMeasurementPolygon]);

  useEffect(() => {
    if (map && distanceMeasurementPolygon) {
      setMeasurementLayerPaint(map, 'white', 5);
    }

    return () => {
      if (map) {
        setMeasurementLayerPaint(map, '#000', 2.5);
      }
    };
  }, [map, distanceMeasurementPolygon]);

  const scoresLoading = richItemsLoading || vectilesLoading;

  const hotspotsToShow = useMemo(() => [...hotspots, ...satelliteAnomalies], [hotspots, satelliteAnomalies]);

  const groveTiles = useMemo((): TAnyWrappedTileType | null => {
    const isPublished = selectedSurveyStats.find((e) => e.groveID === selectedGrove?.id)?.isPublished;

    if (selectedGrove && vectiles && selectedSurvey?.id && isPublished) {
      return getTileset(vectiles[selectedSurvey.id]?.[selectedGrove.id]?.['current-tiles'] || null, selectedSurvey);
    }
    return null;
  }, [selectedGrove, vectiles, selectedSurvey, selectedSurveyStats]);

  useEffect(() => {
    if (isOldSurvey) {
      resetHotspots();
    }
  }, [isOldSurvey, resetHotspots]);

  const previousSurveyRef = useRef(previousSurvey);
  const nextSurveyRef = useRef(nextSurvey);
  const grovesRef = useRef(groves);

  const groveClickHandler = useCallback(
    (event?: any): void => {
      event.preventDefault();

      const properties = event.features?.[0].properties;
      const groveID = properties?.id;
      const nonActive = properties?.active === false;

      if (!selectedFarm || selectedGrove?.id === groveID || nonActive) {
        return;
      }

      const grove = groves.find((grove) => grove.id === groveID);

      if (grove) {
        navigate(`/map/${selectedFarm.id}/${grove.zoneID}/${grove.id}`);
      }
    },
    [navigate, groves, selectedFarm, selectedGrove?.id]
  );

  const groveMousemoveHandler = useCallback(
    (event) => {
      const grove = groves.find((grove) => grove.id === event.features?.[0].properties?.id);
      if (!grove || !map || grove.id === selectedGrove?.id) {
        return;
      }

      const groveProperties = { active: !!grove.active };

      drawHoveredGrove(map, grove, groveProperties);
    },
    [map, groves, selectedGrove?.id]
  );

  const groveMouseleaveHandler = useCallback(() => {
    if (!map) {
      return;
    }

    clearHoveredGrove(map);
  }, [map]);

  const zoneClickHandler = useCallback(
    (event?: any): void => {
      if (!selectedFarm || !map || !!selectedGrove || event.defaultPrevented) {
        return;
      }
      const zone = zones.find((zone) => zone.id === event.features?.[0].properties?.id);

      if (zone) {
        navigate(`/map/${selectedFarm.id}/${zone.id}${search}`);
      }
    },
    [navigate, selectedFarm, selectedGrove, zones, map, search]
  );

  const zoneMousemoveHandler = useCallback(
    (event) => {
      const feature = zones.find((feature) => feature.id === event.features?.[0].properties?.id);
      if (!feature || !map || !!selectedGrove) {
        return;
      }

      const polygon = turf.feature(feature.geometry);
      (map.getSource('hovered-zone') as GeoJSONSource).setData(turf.featureCollection([polygon]) as any);
      map.getCanvas().style.cursor = 'pointer';
    },
    [map, zones, selectedGrove]
  );

  const zoneMouseleaveHandler = useCallback(() => {
    if (!map) {
      return;
    }

    (map.getSource('hovered-zone') as GeoJSONSource).setData(turf.featureCollection([]));
    map.getCanvas().style.cursor = '';
  }, [map]);

  const hotspotHoverHandler = useCallback(
    (hotspotID: string | null) => {
      if (!hotspotID) {
        setHoveredHotspot(null);
      } else {
        setHoveredHotspot((prev) => {
          const hotspotModel = [...satelliteHotspots, ...hotspots].find((hotspot) => hotspot.clusterID === hotspotID);

          if (prev?.clusterID === hotspotID || !hotspotModel) {
            return prev;
          }

          return hotspotModel;
        });
      }
    },
    [satelliteHotspots, hotspots]
  );

  useEffect(() => {
    setExpandedBulletin(null);
  }, [selectedSurvey?.id]);

  useEffect(() => {
    if (map) {
      clearBulletins(map);
      if (preselectedBulletin && !selectedGrove && selectedFarm?.id) {
        const filteredGroves = selectedZone?.id ? grovesRef.current.filter((grove) => grove.zoneID === selectedZone.id) : grovesRef.current;
        drawBulletins(map, preselectedBulletin, filteredGroves, preselectedBulletinReports);
      }
    }

    return () => {
      if (map) {
        clearBulletins(map);
      }
    };
  }, [map, selectedGrove, preselectedBulletin, preselectedBulletinReports, hotspotsLoading, selectedFarm?.id, selectedZone?.id]);

  useEffect(() => {
    setTreeForPopup(null);

    return () => {
      setTreeForPopup(null);
      showPopup(null);
      setSelectedTreeFeature(null);
    };
  }, [dispatch, selectedGrove, activeRichItem, showPopup]);

  useEffect(() => {
    activeRichItemRef.current = activeRichItem;
  }, [activeRichItem]);

  useEffect(() => {
    selectedSurveyRef.current = selectedSurvey;
  }, [selectedSurvey]);

  useEffect(() => {
    previousSurveyRef.current = previousSurvey;
  }, [previousSurvey]);

  useEffect(() => {
    nextSurveyRef.current = nextSurvey;
  }, [nextSurvey]);

  useEffect(() => {
    selectedGroveRef.current = selectedGrove;
  }, [selectedGrove]);

  useEffect(() => {
    grovesRef.current = groves;
  }, [groves]);

  useEffect(() => {
    if (user?.id && selectedFarm?.id) {
      updateFarmVisit(user.id, selectedFarm.id, +new Date());
    }
  }, [selectedFarm?.id, user?.id, updateFarmVisit]);

  useEffect(() => {
    if (user && selectedFarm && selectedTimepickerHotspots.length > 0) {
      const { date } = selectedTimepickerHotspots[0];
      logEvent({
        type: EEventType.SatelliteTimelineHotspotClick,
        params: {
          email: user.email,
          farmName: selectedFarm.name,
          detectionDate: date ? dateUtils.formatDate(date, dateFormats.DATE_FORMAT_DD_MMM_YYYY) : ''
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTimepickerHotspots]);

  useEffect(() => {
    const zoomHandler = (e) => {
      if (map && !!e.originalEvent && map.getZoom() < EXIT_GROVE_TRESHOLD && params.groveID) {
        navigate(`/map/${params.farmID}`);
      }
    };

    map && map.on('zoom', zoomHandler);

    return () => {
      map && map.off('zoom', zoomHandler);
    };
  }, [map, params.zoneID, params.farmID, params.groveID, navigate]);

  const selectedTreeClickHandler = useCallback(
    (event: TMapMouseEvent): void => {
      if (!map || isCapacityShown) return;
      const features = map.queryRenderedFeatures(event.point);
      if (!features) {
        return;
      }
      const treeFeature = features.find((feature) => [...SELECTED_RICH_ITEMS_LAYERS, SELECTED_TREES].includes(feature.layer.id)) as IRichItemMapboxFeature;

      if (treeFeature && selectedFarm && selectedGrove && selectedZone && !EVIDENCE_IGNORED_RICH_ITEMS.includes(activeRichItemRef.current)) {
        dispatch(
          setEvidenceParams({
            farmID: selectedFarm.id,
            zoneID: selectedZone.id,
            groveID: selectedGrove.id,
            treeID: (treeFeature as any).properties.id,
            richItemType: activeRichItemRef.current
          })
        );
      }
    },
    [map, dispatch, selectedFarm, selectedZone, selectedGrove, isCapacityShown]
  );

  const bulletingClickHandler = useCallback(
    (grove: IGrove, reportType: EReportType) => {
      if (selectedFarm?.id && selectedGrove?.id !== grove.id) {
        setSelectedHotspot(null);
        navigate(`/map/${selectedFarm.id}/${grove.zoneID}/${grove.id}`);
      }

      const reportRichItem = reportRichItemsMap.get(reportType);

      if (reportRichItem) {
        dispatch(setRichItemType(reportRichItem));
      }
    },
    [navigate, dispatch, selectedFarm?.id, selectedGrove?.id]
  );

  useEffect(() => {
    if (selectedFarm && activeRichItem && user) {
      logEvent({
        type: EEventType.SelectRichItem,
        params: {
          email: user.email,
          farmName: selectedFarm.name,
          groveName: selectedGrove?.name || '',
          richItemType: activeRichItem
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeRichItem]);

  useEffect(() => {
    if (!user || !selectedFarm || !selectedSurvey) return;
    logEvent({
      type: EEventType.SelectSurvey,
      params: {
        email: user.email,
        farmName: selectedFarm.name,
        groveName: selectedGrove?.name || '',
        surveyName: selectedSurvey.name
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSurvey]);

  useEffect(() => {
    if (!user) return;
    if (selectedGrove && selectedFarm && params.groveID) {
      logEvent({
        type: EEventType.SelectGrove,
        params: {
          email: user.email,
          farmName: selectedFarm?.name || '',
          groveName: selectedGrove.name
        }
      });
    } else if (selectedFarm) {
      logEvent({
        type: EEventType.SelectFarm,
        params: {
          email: user?.email || '',
          farmName: selectedFarm.name
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFarm, selectedGrove]);

  useEffect(() => {
    if (selectedGrove && satelliteAnomalies.length) {
      const sortedAnomalies = satelliteAnomalies.sort((a, b) => ((b.date as string) > (a.date as string) ? 1 : -1));
      const firstHotspot = sortedAnomalies.length > 0 ? sortedAnomalies[satelliteAnomalies.length - 1] : null;

      if (firstHotspot && firstHotspot.date) {
        const firstHotspotDate = dateUtils.getMilliseconds(firstHotspot.date);

        setSelectedTimepickerHotspots(satelliteAnomalies.filter((hotspot) => dateUtils.getMilliseconds(hotspot.date as string) === firstHotspotDate));
      }
    }
  }, [selectedGrove, satelliteAnomalies]);

  useEffect(() => {
    const sortedAnomalies = surveyAnomalies.sort((a, b) => ((a.date as string) < (b.date as string) ? -1 : 1));
    const lastHotspot = sortedAnomalies.length > 0 ? sortedAnomalies[surveyAnomalies.length - 1] : null;
    if (lastHotspot && lastHotspot.date) {
      const lastHotspotDate = dateUtils.getMilliseconds(lastHotspot.date);

      setSelectedTimepickerHotspots(surveyAnomalies.filter((hotspot) => dateUtils.getMilliseconds(hotspot.date as string) === lastHotspotDate));
    } else {
      setSelectedTimepickerHotspots([]);
    }
  }, [surveyAnomalies, setSelectedTimepickerHotspots]);

  useEffect(() => {
    setSelectedTreeFeature(null);
  }, [activeRichItem]);

  // clean hook
  useEffect(
    () => () => {
      if (map) {
        mapTilesetService.removeTilesetLayers(map);
        mapFarmRichItemsService.removeRichItemsLayers(map);
        mapGroveRichItemsService.removeRichItemsLayers(map);
        removeRasterLayers(map);
      }
    },
    [map]
  );

  useEffect(() => {
    if (!map) return;

    if (activeRichItem === ERichItemType.Health && !isCapacityShown) {
      mapTilesetService.drawSelectedTrees(map, selectedTreeFeature as ITreeMapboxFeature);
      mapGroveRichItemsService.drawSelectedRichItems(map, null);
    } else if (isCapacityShown) {
      mapGroveRichItemsService.drawSelectedRichItems(map, selectedTreeFeature as IRichItemMapboxFeature);
      mapTilesetService.drawSelectedTrees(map, null);
    } else {
      mapGroveRichItemsService.drawSelectedRichItems(map, selectedTreeFeature as IRichItemMapboxFeature);
      mapTilesetService.drawSelectedTrees(map, null);
    }
  }, [map, selectedTreeFeature, activeRichItem, isCapacityShown]);

  useEffect(() => {
    if (map && selectedGrove) {
      setSelectedTreeFeature(null);
      addMapSource(map, SELECTED_TREES, []);
    }
  }, [map, selectedGrove]);

  useEffect(() => {
    setSelectedHotspot(null);
  }, [selectedGrove]);

  useEffect(() => {
    setLayers(InitialLayersState);
  }, [selectedFarm]);

  const setMissingDataStatus = useCallback(
    (value) => {
      dispatch(setIsMissingRichItem(value));
    },
    [dispatch]
  );

  useEffect(() => {
    if (map && activeRichItem && selectedSurveyStats.length && statistic && !selectedGrove && !params.groveID && !statisticLoading) {
      let statisticToShow = statistic || {};

      if (selectedZone) {
        const statisticGroves = Object.keys(statisticToShow);
        statisticToShow = statisticGroves.reduce((acc, groveID: string) => {
          if (groves.find((grove) => grove.id === groveID)?.zoneID !== selectedZone.id) return acc;
          return {
            ...acc,
            [groveID]: statisticToShow[groveID]
          };
        }, {});
      }

      const isMissingData = !Object.keys(statisticToShow).length;

      setMissingDataStatus(isMissingData);

      mapFarmRichItemsService.addRichItemsLayers(
        map,
        activeRichItem,
        groves,
        statisticToShow,
        selectedSurveyStats,
        region?.measurementSystem,
        dynamicPaintValue,
        selectedCommodity,
        newlyPublishedGroves
      );
    }
    return () => {
      setMissingDataStatus(false);
    };
  }, [
    activeRichItem,
    map,
    statistic,
    selectedGrove,
    params.groveID,
    selectedZone,
    selectedSurveyStats,
    groves,
    statisticLoading,
    region?.measurementSystem,
    dynamicPaintValue,
    selectedCommodity,
    setMissingDataStatus,
    newlyPublishedGroves
  ]);

  const shouldRemoveFarmLevelRichItems = !!map && (!!selectedGrove || statisticLoading);

  useEffect(() => {
    if (shouldRemoveFarmLevelRichItems) {
      mapFarmRichItemsService.removeRichItemsLayers(map);
    }
  }, [map, shouldRemoveFarmLevelRichItems]);

  useEffect(() => {
    showPopup(treeForPopup);
  }, [treeForPopup, showPopup]);

  useEffect(() => {
    showHotspotPopup(hoveredHotspot, selectedSurvey);
  }, [hoveredHotspot, selectedSurvey, showHotspotPopup]);

  useEffect(() => {
    if (!map) return;
    if (rasterLayers) {
      addRastersLayers(map, rasterLayers?.urls || [], rasterLayers?.bbox);
    }
  }, [map, rasterLayers]);

  useEffect(() => {
    if (selectedFarm?.id && selectedSurvey?.id) {
      getFarmHotspots(selectedFarm.id, selectedSurvey.id);
    }
  }, [selectedFarm, selectedSurvey, getFarmHotspots]);

  useEffect(() => {
    if (selectedFarm?.id && selectedSurvey?.id && selectedGrove?.id) {
      getGroveTrees(selectedFarm.id, selectedGrove.id, selectedSurvey.id);
    }
  }, [selectedFarm, selectedGrove, selectedSurvey, getGroveTrees]);

  useEffect(() => {
    if (!params.farmID && farms?.length) {
      navigate(`/map/${farms[0].id}`);
    }
  }, [params, navigate, farms]);

  useEffect(() => {
    if (!surveyID && surveys?.length) {
      setSearchParams({ surveyID: surveys[0].id });
    }
  }, [surveyID, surveys, setSearchParams]);

  useEffect(() => {
    if (selectedSurvey) {
      setSearchParams({ surveyID: selectedSurvey.id });
    }
  }, [setSearchParams, selectedSurvey]);

  useEffect(() => {
    if (params.farmID && user) {
      getFarmVectiles(params.farmID);
    }
  }, [params.farmID, getFarmVectiles, user]);

  useEffect(() => {
    if (!selectedGrove) {
      setDataCardOpened(false);
    }
  }, [selectedGrove]);

  useEffect(() => {
    if (map && zones.length > 1) {
      addZoneLabelMarkers(map, zones, zoneClickHandler);
    }

    return () => {
      if (map) {
        removeAllZoneMarkers(map);
      }
    };
  }, [zones, map, zoneClickHandler]);

  useEffect(() => {
    const clickHandler = (event?: any) => {
      const hotspot = hotspotsToShow.find((hotspot) => hotspot.clusterID === event.features?.[0].properties?.id && hotspot.type === EHotspotType.Satellite);

      if (hotspot && selectedGrove?.id === hotspot.groveID) {
        setSelectedHotspot(hotspot);
        showHotspotModal();
      }
    };
    const hoverHandler = (event: TMapMouseEvent): void => {
      if (!map || !selectedGrove) return;
      const features = map.queryRenderedFeatures(event.point);
      const hoverableFeature = features?.find((feature) => HOTSPOT_LAYERS.includes(feature.layer.id));

      if (hoverableFeature) {
        map.getCanvas().style.cursor = 'pointer';
        hotspotHoverHandler(hoverableFeature.properties?.id);
      } else {
        const decendantChecker = (node: HTMLElement | null) => !!node?.classList?.contains('mapboxgl-popup');
        if (!isDescendant(event.originalEvent?.relatedTarget as HTMLDivElement, decendantChecker)) {
          hotspotHoverHandler(null);
          map.getCanvas().style.cursor = '';
        }
      }
    };

    if (map && zones.length && groves.length) {
      if (selectedGrove) {
        clearHoveredGrove(map);
      }
      if (selectedHotspot) {
        drawHotspots(map, [selectedHotspot], [], clickHandler, hoverHandler);
      } else if (!selectedGrove) {
        clearHotspots(map, clickHandler, hoverHandler);
      }

      const loading = hotspotsLoading;

      if ((preselectedBulletin === EReportType.TargetedScouting || !preselectedBulletin) && !loading && hotspotsToShow.length > 0 && layers.clusters) {
        const filteredHotspotsToShow = hotspotsToShow.filter((hotspot) => {
          if (!selectedZone) return true;
          const grove = groves.find((grove) => grove.id === hotspot.groveID);
          return grove ? grove.zoneID === selectedZone.id : true;
        });
        drawHotspots(map, filteredHotspotsToShow, selectedTimepickerHotspots, clickHandler, hoverHandler, !!selectedGrove);
      }

      if (hotspotsToShow.length <= 0 || !layers.clusters) {
        clearHotspots(map, clickHandler, hoverHandler);
      }

      drawZones(map, zones);
      drawGroves(map, groves, selectedGrove, newlyPublishedGroves);
    }

    return () => {
      if (map) {
        clearHotspots(map, clickHandler, hoverHandler);
        clearHotspotsIcons(map);
      }
    };
  }, [
    map,
    zones,
    groves,
    newlyPublishedGroves,
    selectedGrove,
    selectedZone,
    hotspotsToShow,
    selectedTimepickerHotspots,
    hotspotsLoading,
    selectedHotspot,
    hotspotHoverHandler,
    showHotspotModal,
    layers.clusters,
    preselectedBulletin
  ]);

  useEffect(() => {
    if (map) {
      setGroveEventHandlers(map, groveClickHandler, groveMousemoveHandler, groveMouseleaveHandler);
      setZoneEventHandlers(map, zoneClickHandler, zoneMousemoveHandler, zoneMouseleaveHandler);
    }

    return () => {
      if (map) {
        removeGroveEventHandlers(map, groveClickHandler, groveMousemoveHandler, groveMouseleaveHandler);
        removeZoneEventHandlers(map, zoneClickHandler, zoneMousemoveHandler, zoneMouseleaveHandler);
      }
    };
  }, [map, groveClickHandler, groveMousemoveHandler, groveMouseleaveHandler, zoneClickHandler, zoneMousemoveHandler, zoneMouseleaveHandler]);

  useEffect(() => {
    if (map) {
      mapGroveRichItemsService.removeRichItemsLayers(map);
      mapTilesetService.removeTilesetLayers(map);
      const isActiveHealth = activeRichItem === ERichItemType.Health;

      if (richItemGeoJSON && !isActiveHealth && layers.scores && !scoresLoading) {
        mapGroveRichItemsService.addRichItemsLayers(map, richItemGeoJSON, treeClickHandler, selectedTreeClickHandler);
        mapTilesetService.removeTilesetLayers(map);
      } else if (groveTiles && layers.scores && !scoresLoading) {
        const bbox = selectedGrove ? turf.bbox(selectedGrove.geometry) : undefined;
        mapTilesetService.addTilesetLayers(map, groveTiles, layers.colorBlind, treeClickHandler, selectedTreeClickHandler, bbox);
        mapGroveRichItemsService.removeRichItemsLayers(map);
      } else if (capacityGeoJSONByRules && isCapacityShown) {
        mapGroveRichItemsService.addRichItemsLayers(map, capacityGeoJSONByRules, treeClickHandler, selectedTreeClickHandler);
      } else {
        mapGroveRichItemsService.removeRichItemsLayers(map);
        mapTilesetService.removeTilesetLayers(map);
      }
    }

    return () => {
      if (map) {
        mapTilesetService.detachTreeClickListeners(map, treeClickHandler, selectedTreeClickHandler);
        mapGroveRichItemsService.detachTreeClickListeners(map, treeClickHandler, selectedTreeClickHandler);
      }
    };
  }, [
    map,
    groveTiles,
    selectedGrove,
    richItemGeoJSON,
    scoresLoading,
    layers.scores,
    layers.colorBlind,
    treeClickHandler,
    selectedTreeClickHandler,
    activeRichItem,
    capacityGeoJSONByRules,
    isCapacityShown
  ]);

  useEffect(() => {
    if (map && highlightedRichItems) {
      const { richItemGeoJSON, chartLabels, selectedRanges } = highlightedRichItems;
      mapGroveRichItemsService.highlightChartRanges(map, chartLabels, selectedRanges, richItemGeoJSON, activeRichItem);
    }
  }, [map, highlightedRichItems, activeRichItem]);

  useEffect(() => {
    if (!map) return;
    if (layers.terrain) {
      if (selectedGrove && CUSTOM_TERRAIN_FARMS.includes(selectedGrove.farmID)) {
        fetchCustomTerrain(selectedGrove.name).then((terrain) => {
          if (terrain && selectedGrove) {
            addTerrainLayers(map, selectedGrove, terrain);
          }
        });
      } else {
        addTerrainLayers(map, selectedGrove);
      }
    } else {
      removeTerrainLayers(map);
    }
  }, [selectedGrove, fetchCustomTerrain, map, layers.terrain]);

  useEffect(() => {
    if (!map) return;
    if (layers.irrigation && selectedFarm && selectedFarm.irrigationSourceNames) {
      fetchIrrigationSources().then((geoJSON) => {
        if (geoJSON) {
          addIrrigationLayers(map, geoJSON);
        }
      });
    } else {
      removeIrrigationLayers(map);
    }
  }, [selectedGrove, fetchIrrigationSources, map, layers.irrigation, selectedFarm]);

  useEffect(() => {
    if (map) {
      if (poi.length && layers.poi) {
        drawPoi(map, poi);
      } else {
        drawPoi(map, []);
      }
    }

    return () => {
      if (map) {
        drawPoi(map, []);
      }
    };
  }, [map, poi, layers.poi]);

  useEffect(() => {
    if (!map) return;

    if (selectedGrove) {
      mapTilesetService.highlightScores(map, highlightedScores);
    }
  }, [map, highlightedScores, selectedGrove]);

  useEffect(() => {
    if (!selectedGrove && highlightedScores.length) {
      showHealthModal();
    } else {
      hideHealthModal();
    }
  }, [selectedGrove, highlightedScores, hideHealthModal, showHealthModal]);

  useEffect(() => {
    setHighlightedScores([]);
  }, [selectedSurvey, selectedGrove, selectedRichItem]);

  const allCommoditiesOnFarmSelector = useMemo(() => {
    const uniqCommodities = uniq(groves.map((e) => e?.attributes?.commodityCombinations).flat());

    return uniqBy(
      'subCommodity',
      sortBy(
        'subCommodity',
        commodityCombinations.filter((e) => uniqCommodities.includes(e.id))
      )
    );
  }, [groves, commodityCombinations]);

  useEffect(() => {
    if (allCommoditiesOnFarmSelector.length > 1 && activeRichItem === ERichItemType.FruitYield) {
      setSelectedCommodity(allCommoditiesOnFarmSelector[0]);
    }

    return () => {
      setSelectedCommodity(undefined);
    };
  }, [allCommoditiesOnFarmSelector, activeRichItem]);

  const handleChangeVariety = (variety) => {
    const commodity = commodityCombinations.find((item) => item.id === variety.id);

    if (!commodity) {
      return;
    }

    const index = selectedVarieties.indexOf(commodity);

    if (index >= 0) {
      selectedVarieties.splice(index, 1);
      setSelectedVarieties([...selectedVarieties]);
      return;
    }
    setSelectedVarieties([...selectedVarieties, commodity]);
  };

  useEffect(() => {
    if (richItemsLoaded) return;

    const isSameRichItemTypeExists = richItems.find((item) => item.richItemTypeName === activeRichItemRef.current);
    const healthRichItem = richItems.find((item) => item.richItemTypeName === ERichItemType.Health);

    if (!isSameRichItemTypeExists) {
      dispatch(setRichItemType(healthRichItem?.richItemTypeName || richItems[0]?.richItemTypeName));
    }
  }, [richItems, dispatch, richItemsLoaded]);

  useEffect(() => {
    if (!map || selectedGrove) return;

    if (map.getLayer('groves-layers-fill')) {
      map.setLayoutProperty('groves-layers-fill', 'visibility', !isFarmLayersFiltered ? 'visible' : 'none');
    }
  }, [map, selectedGrove, isFarmLayersFiltered]);

  useEffect(() => {
    if (activeRichItem === ERichItemType.FruitYield && fruitStatistic) {
      const groveIDs = Object.keys(fruitStatistic);

      const prepareRanges = groveIDs.reduce((acc, groveID) => {
        const data = fruitStatistic[groveID] as IParsedFruitYieldStatistic;

        if (!data) return acc;

        const statisticCount = data.statistic.countPerTree;
        const groveRanges = groves.find((grove) => grove.id === groveID);
        const ranges = groveRanges?.richItemRanges?.[ERichItemType.FruitYield]?.ranges;

        if (!ranges) return acc;

        const range = ranges.find((item, idx, arr) => statisticCount >= item.value && statisticCount < arr[idx + 1]?.value);
        const lastRange = ranges[ranges.length - 1];

        if (acc.includes(statisticCount)) return acc;

        if (statisticCount > lastRange.value) {
          return acc.concat([statisticCount, lastRange.color]);
        }

        if (!range) return acc;

        return acc.concat([statisticCount, range.color]);
      }, [] as (number | string)[]);

      const dynamicPaintValue = ['match', ['get', activeRichItem], ...prepareRanges, 'transparent'];

      if (prepareRanges.length) {
        setDynamicPaintValue(dynamicPaintValue as TPaintValue);
      } else {
        setDynamicPaintValue('transparent' as any);
      }
    }
  }, [groves, activeRichItem, fruitStatistic, selectedGrove]);

  useEffect(() => {
    const rangePaint = richItemsRangesByRegion?.mapbox?.paint;
    const activeRichItemPaint = (rangePaint?.['circle-color'] || rangePaint?.['line-color']) as TPaintValue;

    if (activeRichItemPaint) {
      setDynamicPaintValue(activeRichItemPaint);
    }

    return () => {
      setDynamicPaintValue(undefined);
    };
  }, [activeRichItem, richItemsRangesByRegion]);

  const onFarmLayersFiltered = useCallback(
    (val) => {
      dispatch(setIsFarmLayersFiltered(val));
    },
    [dispatch]
  );

  if (!selectedFarm) {
    return (
      <SideBar>
        <LocalLoader />
      </SideBar>
    );
  }

  return (
    <>
      {isSideBarExpanded && (
        <SideBar>
          <StaticContent>
            {selectedFarm && <MainPageBreadcrumbs groves={groves} zones={zones} farms={farms} grove={selectedGrove} zone={selectedZone} farm={selectedFarm} />}
            <MainPageMenu
              selectedSurvey={selectedSurvey?.id}
              surveys={surveys}
              onSelectSurvey={setSurvey}
              onSelectHotspots={setSelectedTimepickerHotspots}
              farms={farms}
              zones={zones}
              groves={groves}
              farm={selectedFarm}
              zone={selectedZone}
              grove={selectedGrove}
              tree={activeRichItem === ERichItemType.Health && !isCapacityShown ? (selectedTreeFeature as ITreeMapboxFeature) : null}
              prefix="/map"
            />
            {selectedFarm && selectedSurvey && (
              <>
                <FarmInfo
                  metricType={region?.measurementSystem}
                  groves={groves}
                  selectedZone={selectedZone}
                  selectedGrove={selectedGrove}
                  selectedSurvey={selectedSurvey}
                  selectedSurveyStats={selectedSurveyStats}
                  commodityCombinations={commodityCombinations}
                  statistic={healthStatistic}
                />
                <BlockSplitter />
              </>
            )}
          </StaticContent>
          <ScrollingContent>
            {selectedFarm && selectedSurvey && selectedGrove && (
              <>
                {selectedGrove?.attributes?.commodityCombinations && selectedGrove.attributes.commodityCombinations?.length > 1 && (
                  <>
                    <GroveCommoditiesSelector
                      commodityCombinations={commodityCombinations}
                      grove={selectedGrove}
                      onClick={handleChangeVariety}
                      selectedVarieties={selectedVarieties}
                    />
                    <BlockSplitter />
                  </>
                )}
                <GroveViewDataCard
                  richItemGeoJSON={richItemGeoJSON}
                  selectedGrove={selectedGrove}
                  selectedSurvey={selectedSurvey}
                  onHighlightedScores={setHighlightedScores}
                  colorBlind={layers.colorBlind}
                  scoresLoading={scoresLoading}
                  dataCardOpened={dataCardOpened}
                  selectedSurveyStats={selectedSurveyStats}
                  onCardOpened={setDataCardOpened}
                  highlightedScores={highlightedScores}
                  onHighlightedRichItemsChange={setHighlightedRichItems}
                  richItemsConfigs={richItemsConfigs}
                  measurementSystem={region?.measurementSystem}
                  selectedRichItem={selectedRichItem}
                  selectedVarieties={selectedVarieties}
                  statistic={feedViewBqStatistic}
                  richItemsRanges={richItemsRangesByRegion?.ranges}
                  capacityGroveData={capacityGroveData}
                  activeRichItem={activeRichItem}
                />
              </>
            )}
            {selectedFarm && selectedSurvey && !selectedGrove && (
              <FarmViewDataCard
                zone={selectedZone}
                selectedGrove={selectedGrove}
                selectedSurvey={selectedSurvey}
                onHighlightedScores={setHighlightedScores}
                colorBlind={layers.colorBlind}
                scoresLoading={statisticLoading}
                selectedSurveyStats={selectedSurveyStats}
                dataCardOpened={dataCardOpened}
                onCardOpened={setDataCardOpened}
                highlightedScores={highlightedScores}
                measurementSystem={region?.measurementSystem}
                activeRichItem={activeRichItem}
                statistic={activeRichItem !== ERichItemType.FruitYield ? feedViewBqStatistic : healthStatistic}
                fruitStatistic={fruitStatistic}
                groves={groves}
                selectedCommodity={selectedCommodity}
                setDynamicPaintValue={setDynamicPaintValue}
                richItemsRanges={richItemsRangesByRegion?.ranges}
                capacityStatistic={selectedCapacityStatistic}
              />
            )}
            {selectedFarmSurvey && (
              <>
                <BlockSplitter />
                <BulletinContext.Provider value={bulletinContext}>
                  <FarmBulletin
                    farm={selectedFarm}
                    zone={selectedZone}
                    groves={groves}
                    survey={selectedFarmSurvey}
                    onGroveClicked={bulletingClickHandler}
                    isOldSurvey={isOldSurvey}
                    selectedGrove={selectedGrove}
                    metricType={region?.measurementSystem}
                    publishedGrovesInLastSurveys={publishedGrovesInLastSurveys}
                    replantReports={replantBulletin}
                  />
                </BulletinContext.Provider>
              </>
            )}
          </ScrollingContent>
        </SideBar>
      )}
      <LegendWrapper isExpanded={isSideBarExpanded}>
        <>
          <RichItemsLegend
            onRichItemSelected={(richItemType) => dispatch(setRichItemType(richItemType))}
            onRichItemSubtypeSelected={(type) => dispatch(setRichItemSubType(type))}
            richItems={richItems}
            activeRichItem={selectedRichItem}
            activeRichItemSubType={activeRichItemSubType}
            onLayersChanged={setLayers}
            onFarmLayersFiltered={onFarmLayersFiltered}
            farmLayersFiltered={isFarmLayersFiltered}
            farm={selectedFarm}
            zone={selectedZone}
            grove={selectedGrove}
            survey={selectedSurvey}
          />
          <HotspotsTimeline
            grove={selectedGrove}
            satelliteHotspots={satelliteAnomalies}
            selectedSatelliteHotspots={selectedTimepickerHotspots}
            onSelectHotspots={setSelectedTimepickerHotspots}
          />
        </>
      </LegendWrapper>
      {isNewDisplayInviteActive && (
        <InvitationWrapper isExpanded={isSideBarExpanded}>
          <NewDisplayInvitation />
        </InvitationWrapper>
      )}
      {distanceMeasurementPolygon && (
        <LegendWrapper isExpanded={isSideBarExpanded} style={{ maxWidth: 'fit-content' }}>
          <ClusterMetrics trees={clusterTrees} cluster={distanceMeasurementPolygon} metricType={regionMeasurementSystem} handleClose={() => dispatch(setMeasurePoints([]))} />
        </LegendWrapper>
      )}
      <SideBarControlsWrapper>
        <SidebarControls
          groveView={!!selectedGrove}
          layers={layers}
          onLayersChanged={setLayers}
          activeRichItem={activeRichItem}
          isIrrigationSource={!!selectedFarm?.irrigationSourceNames}
        />
      </SideBarControlsWrapper>
      <ZoomControlsWrapper>
        <ZoomControls map={map} />
      </ZoomControlsWrapper>
      <GroveControlsWrapper isExpanded={isSideBarExpanded}>
        {(selectedGrove || distanceMeasurementStatus) && !isCapacityShown && regionMeasurementSystem ? (
          <GroveControls map={map} distance={distanceValue} measurementType={distanceMeasurementType} metricType={regionMeasurementSystem} />
        ) : undefined}
      </GroveControlsWrapper>
      <GroveControlsWrapper isExpanded={isSideBarExpanded}>
        {(selectedGrove || distanceMeasurementStatus) && isCapacityShown && regionMeasurementSystem ? (
          <SegmentTool
            distance={distanceValue}
            measurementType={distanceMeasurementType}
            metricType={regionMeasurementSystem}
            grove={selectedGrove}
            survey={selectedSurvey}
            farm={selectedFarm}
          />
        ) : undefined}
      </GroveControlsWrapper>
      <ToastContainer closeButton={false} position="bottom-center" autoClose={false} />
    </>
  );
};

export default MapScreen;
