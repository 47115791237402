import React, { useEffect, useCallback, useState, useRef, useMemo } from 'react';
import { Chart } from 'chart.js';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { IMission } from 'models/mission';
import { IFeed, EHLBStatus, severityBackgroundMap, HLB_INFECTED_STATUSES } from 'models/feed';
import { IGrove } from 'models/grove';

import numberUtils from 'utils/numbers';
import missionUtils from 'utils/mission';
import farmsHooks from 'hooks/farms.hooks';

import { mfColors } from 'vars';

const Wrapper = styled.div``;

const Title = styled.div`
  font-size: 24px;
  margin: 16px 0;
`;

const SubTitle = styled.div`
  font-size: 18px;
  padding: 4px 0;
  margin: 0 0 8px;
  border-bottom: 2px solid ${mfColors.grey};
  width: 100%;
  max-width: 780px;
`;

const ChartWrapper = styled.div`
  margin: 16px 0;
  min-height: 260px;
  max-width: 780px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  canvas {
    height: 100%;
  }

  .column {
    width: 48%;
  }

  .chart-wrapper {
    min-height: 260px;
  }
`;

const SingleChartWrapper = styled.div`
  margin: 16px 0;
  height: 260px;
  max-width: 780px;
`;

const Controls = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;
`;

interface IControlProps {
  isDisabled: boolean;
}

const Control = styled.span`
  margin: 0 16px;
  cursor: ${({ isDisabled }: IControlProps) => (isDisabled ? 'default' : 'pointer')};
  opacity: ${({ isDisabled }: IControlProps) => (isDisabled ? 0.6 : 1)};
`;

const chartSeverities = [EHLBStatus.F1, EHLBStatus.F2, EHLBStatus.F3, EHLBStatus.F4];

const MIN_GROVES_TO_SHOW = 64;
const GROVE_TO_SHOW_STEP = 10;

interface IGroveWithFeed extends IGrove {
  feed: IFeed[];
  hlbFeed: IFeed[];
}

interface IProps {
  missions: IMission[];
  feed: IFeed[];
}

const HLBDashboardNonPreassigned = ({ missions, feed }: IProps) => {
  const severityChartCanvasRef = useRef<HTMLCanvasElement>(null);
  const infectionRateChartCanvasRef = useRef<HTMLCanvasElement>(null);
  const grovesChartCanvasRef = useRef<HTMLCanvasElement>(null);
  const infectionRateChartRef = useRef<Chart | null>(null);
  const severityChartRef = useRef<Chart | null>(null);
  const grovesChartRef = useRef<Chart | null>(null);
  const { groves, selectedSurveyStats: surveyStats, grovesSurveysStats } = farmsHooks.useFarmEntities();
  const [grovesToShow, setGrovesToShow] = useState(MIN_GROVES_TO_SHOW);
  const { t } = useTranslation();

  const missionsGroves = useMemo(() => {
    const groveIDs = missions.reduce((acc: string[], mission) => {
      const missionGroveIDs = Object.keys(mission.groves);
      return [...acc, ...missionGroveIDs.filter((groveID) => !acc.includes(groveID))];
    }, []);
    return groveIDs
      .map((groveID) => {
        const grove = groves.find((grove) => grove.id === groveID);
        if (!grove) return null;

        const groveFeed = feed.filter((entry) => entry.groveID === grove.id);
        const hlbFeed = groveFeed.filter((entry) => HLB_INFECTED_STATUSES.includes(entry?.data?.hlbStatus as EHLBStatus));

        return {
          ...grove,
          feed: groveFeed,
          hlbFeed
        };
      })
      .filter((grove) => !!grove) as IGroveWithFeed[];
  }, [groves, missions, feed]);

  const totalTreesCount = useMemo((): number => {
    const result = missionsGroves.reduce((acc: number, grove: IGroveWithFeed) => acc + missionUtils.getTreesSum([grove], surveyStats, grovesSurveysStats), 0);
    return result;
  }, [surveyStats, grovesSurveysStats, missionsGroves]);

  const missionsGrovesToShow = useMemo(() => {
    const result = missionsGroves.sort((a, b) => b.hlbFeed.length / b.feed.length - a.hlbFeed.length / a.feed.length).slice(0, grovesToShow);
    return result;
  }, [missionsGroves, grovesToShow]);

  const changeGrovesToShow = useCallback(
    (increment: number) => {
      setGrovesToShow((prev) => {
        const newValue = prev + increment;
        if (newValue < MIN_GROVES_TO_SHOW) {
          return MIN_GROVES_TO_SHOW;
        } else if (newValue > missionsGroves.length) {
          return missionsGroves.length;
        }
        return newValue;
      });
    },
    [missionsGroves.length]
  );

  useEffect(() => {
    if (!severityChartCanvasRef.current) return;
    const labels: string[] = [];
    const data: number[] = [];
    const backgrounds: string[] = [];
    const allReportedItems = feed.filter((entry) => chartSeverities.includes(entry.data?.hlbStatus as EHLBStatus));

    chartSeverities.forEach((severityLevel) => {
      const severityCount = feed.filter((entry) => entry.data?.hlbStatus === severityLevel).length;
      const percentage = numberUtils.normalizeDecimal(numberUtils.convertToPercentage(severityCount / allReportedItems.length), 2);
      labels.push(t('missions.severity_level_percentage', { level: severityLevel, percentage: `${percentage}%` }));
      data.push(percentage);
      backgrounds.push(severityBackgroundMap.get(severityLevel) || '');
    });

    const config = {
      type: 'pie',
      data: {
        labels,
        datasets: [
          {
            data,
            backgroundColor: backgrounds
          }
        ]
      },
      options: {
        responsive: true,
        tooltips: {
          callbacks: {
            label: (context) => {
              const value = data[context.index];
              return ` ${value}%`;
            }
          }
        },
        plugins: {
          legend: {
            position: 'top'
          },
          title: {
            display: false
          }
        },
        maintainAspectRatio: false
      }
    };

    if (severityChartRef.current) {
      severityChartRef.current.destroy();
    }

    severityChartRef.current = new Chart(severityChartCanvasRef.current, config);
  }, [feed, t]);

  useEffect(() => {
    if (!infectionRateChartCanvasRef.current) return;
    const labels: string[] = [];
    const data: number[] = [];
    const backgrounds: string[] = [];
    const infectedReportItems = feed.filter((entry) => chartSeverities.includes(entry.data?.hlbStatus as EHLBStatus));

    const infectedPercentage = numberUtils.normalizeDecimal(numberUtils.convertToPercentage(infectedReportItems.length / totalTreesCount), 2);
    data.push(infectedPercentage);
    labels.push(t('missions.hlb_positive_percentage', { percentage: `${infectedPercentage}%` }));
    backgrounds.push(severityBackgroundMap.get(EHLBStatus.F4) || '');

    data.push(100 - infectedPercentage);
    labels.push(t('missions.hlb_negative_percentage', { percentage: `${100 - infectedPercentage}%` }));
    backgrounds.push(severityBackgroundMap.get(EHLBStatus.NoSymptoms) || '');

    const config = {
      type: 'pie',
      data: {
        labels,
        datasets: [
          {
            data,
            backgroundColor: backgrounds
          }
        ]
      },
      options: {
        responsive: true,
        tooltips: {
          callbacks: {
            label: (context) => {
              const value = data[context.index];
              return ` ${value}%`;
            }
          }
        },
        plugins: {
          legend: {
            position: 'top'
          },
          title: {
            display: false
          }
        },
        maintainAspectRatio: false
      }
    };

    if (infectionRateChartRef.current) {
      infectionRateChartRef.current.destroy();
    }

    infectionRateChartRef.current = new Chart(infectionRateChartCanvasRef.current, config);
  }, [feed, totalTreesCount, t]);

  useEffect(() => {
    if (!grovesChartCanvasRef.current) return;
    const labels: string[] = [];
    const noHlbData: number[] = [];
    const hlbData: number[] = [];

    // prettier-ignore
    const maxPercentage = Math.ceil(missionsGrovesToShow.reduce((acc: number, grove: IGroveWithFeed) => {
      const totalTrees = missionUtils.getTreesSum([grove], surveyStats, grovesSurveysStats);
      if (!totalTrees) return acc;

      const hlbRatio = grove.hlbFeed.length / totalTrees;
      return hlbRatio > acc ? hlbRatio : acc;
    }, 0) * 100) / 100;
    const chartMax = maxPercentage > 0.15 ? maxPercentage : 0.15;

    missionsGrovesToShow.forEach((grove: IGroveWithFeed) => {
      const totalTrees = missionUtils.getTreesSum([grove], surveyStats, grovesSurveysStats);
      if (!totalTrees) return;

      const hlbRatio = grove.hlbFeed.length / totalTrees;

      labels.push(grove.name);
      noHlbData.push(numberUtils.normalizeDecimal(numberUtils.convertToPercentage(chartMax - hlbRatio), 2));
      hlbData.push(numberUtils.normalizeDecimal(numberUtils.convertToPercentage(hlbRatio), 2));
    });

    const config = {
      type: 'bar',
      data: {
        labels,
        datasets: [
          {
            label: t('missions.hlb_plus'),
            data: hlbData,
            backgroundColor: severityBackgroundMap.get(EHLBStatus.F4),
            stack: 'Stack 0',
            maxBarThickness: 80
          },
          {
            label: t('missions.no_hlb'),
            data: noHlbData,
            backgroundColor: severityBackgroundMap.get(EHLBStatus.NoSymptoms),
            stack: 'Stack 0',
            maxBarThickness: 80
          }
        ]
      },
      options: {
        tooltips: {
          callbacks: {
            label: (context) => {
              const formattedPercentage = numberUtils.normalizeDecimal(context.datasetIndex === 1 ? context.yLabel + (100 - chartMax * 100) : context.yLabel, 2);
              return `${t(context.datasetIndex === 0 ? 'missions.hlb_plus' : 'missions.no_hlb')}: ${formattedPercentage} %`;
            }
          }
        },
        plugins: {
          title: {
            display: false
          }
        },
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          yAxes: [
            {
              stacked: true,
              ticks: {
                max: chartMax * 100,
                callback: (value: number) => `${value}%`
              }
            }
          ],
          xAxes: [
            {
              ticks: {
                autoSkip: false,
                callback: (value: string) => {
                  if (missionsGrovesToShow.length > MIN_GROVES_TO_SHOW) return '';
                  return value;
                }
              }
            }
          ]
        }
      }
    };

    if (grovesChartRef.current) {
      grovesChartRef.current.destroy();
    }

    grovesChartRef.current = new Chart(grovesChartCanvasRef.current, config);
  }, [missionsGrovesToShow, feed, groves, surveyStats, grovesSurveysStats, t]);

  return (
    <Wrapper>
      <Title>{t('missions.percentage_of_hlb_contamination_levels')}</Title>
      <ChartWrapper>
        <div className="column">
          <SubTitle>{t('missions.severity_distribution')}</SubTitle>
          <div className="chart-wrapper">
            <canvas ref={severityChartCanvasRef} />
          </div>
        </div>
        <div className="column">
          <SubTitle>{t('missions.infection_rate')}</SubTitle>
          <div className="chart-wrapper">
            <canvas ref={infectionRateChartCanvasRef} />
          </div>
        </div>
      </ChartWrapper>
      <SubTitle>{t('missions.per_grove')}</SubTitle>
      <SingleChartWrapper>
        <canvas ref={grovesChartCanvasRef} />
        {missionsGroves.length > MIN_GROVES_TO_SHOW && (
          <Controls>
            <Control onClick={() => grovesToShow > MIN_GROVES_TO_SHOW && changeGrovesToShow(-GROVE_TO_SHOW_STEP)} isDisabled={grovesToShow <= MIN_GROVES_TO_SHOW}>
              {t('shared.see_less')}
            </Control>
            <Control
              onClick={() => grovesToShow < missionsGroves.length && changeGrovesToShow(GROVE_TO_SHOW_STEP)}
              color="primary"
              isDisabled={grovesToShow >= missionsGroves.length}
            >
              {t('shared.see_more')}
            </Control>
          </Controls>
        )}
      </SingleChartWrapper>
    </Wrapper>
  );
};

export default HLBDashboardNonPreassigned;
