import React, { useCallback, useMemo } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import dateUtils from 'utils/date';
import dateFormats from 'models/date';
import { ISurveyWithTimeRange } from 'models/survey';
import FormSelect, { EPosition } from 'atomicComponents/FormSelect';

import { mfColors } from 'vars';

const SurveySelectTitle = styled.div`
  font-family: MontserratBold;
`;

const SurveySelectSubtitle = styled.div`
  color: ${mfColors.grey};
`;

interface IProps {
  selectedSurvey: string;
  surveys: ISurveyWithTimeRange[];
  onSelectSurvey: (survey: ISurveyWithTimeRange) => void;
  position?: EPosition;
}

const SurveySelect = ({ selectedSurvey, surveys, onSelectSurvey, position = EPosition.Bottom }: IProps): JSX.Element => {
  const { t } = useTranslation();

  const surveysOptions = useMemo(() => {
    const entries = surveys
      .filter((survey) => !!survey.date && !!survey.endDate)
      .map((survey, index) => {
        const hasOneDate = survey.from === survey.to || !survey.to;
        const surveyDates = hasOneDate
          ? dateUtils.formatDate(survey.from, dateFormats.DATE_FORMAT_DD_MMM_YYYY)
          : `${dateUtils.formatDate(survey.from, dateFormats.DATE_FORMAT_DD_MMM)} - ${dateUtils.formatDate(survey.to, dateFormats.DATE_FORMAT_DD_MMM_YYYY)}`;
        const entry = {
          value: survey.id,
          key: survey.id,
          text: (
            <>
              <SurveySelectTitle>{surveyDates}</SurveySelectTitle>
              <SurveySelectSubtitle>{`${survey.name || ''}${index === 0 ? ` (${t('shared.latest')})` : ''}`}</SurveySelectSubtitle>
            </>
          )
        };
        return entry;
      });
    return entries;
  }, [surveys, t]);

  const handleSelectSurvey = useCallback(
    (value: string | null) => {
      const selectedSurvey = surveys.find((survey) => survey.id === value);

      if (selectedSurvey) {
        onSelectSurvey(selectedSurvey);
      }
    },
    [onSelectSurvey, surveys]
  );

  return <FormSelect<string> position={position} isBordered value={selectedSurvey} onChange={handleSelectSurvey} options={surveysOptions} />;
};

export default SurveySelect;
