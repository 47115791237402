import { Map, GeoJSONSource, Popup } from 'mapbox-gl';
import * as turf from '@turf/turf';

import { EFeedItemType, EFeedStatus, IFeed, ICreateFeedIncompleteParams, EHLBStatus } from 'models/feed';
import { IMission } from 'models/mission';

import { FEED_LAYERS, LAYERS_PRIORITY, addLayerToMap, reorderLayers } from 'services/util/mapHelpers';
import FeedHTMLPopup from 'components/shared/popups/FeedPopup';
import { TEventHandler } from 'models/map';
import missionUtils from 'utils/mission';

export type TPaintType = 'categorical' | 'identity' | 'exponential' | 'interval';

export interface IFeedPopupClickParams {
  imageUrls: {
    url: string;
    previewUrl: string;
  }[];
  index: number;
}

const getFeedLayout = (isActive = false) => {
  const stops = [
    ['fruitDone', 'fruitDone'],
    ['fruitInProgress', 'fruitInProgress'],
    ['fruitDoneWrongLocation', 'fruitDoneWrongLocation'],
    ['inProgress', 'inProgress'],
    ['done', 'done'],
    ['calibration', 'calibration'],
    ['calibrationApproved', 'calibrationApproved'],
    ['calibrationDeclined', 'calibrationDeclined'],
    ['hlbGoCheck', 'hlbGoCheck'],
    ['hlbSampled', 'hlbSampled'],
    ['hlbLevel1', 'hlbLevel1'],
    ['hlbLevelf1', 'hlbLevel1'],
    ['hlbLevel2', 'hlbLevel2'],
    ['hlbLevelf2', 'hlbLevel2'],
    ['hlbLevel3', 'hlbLevel3'],
    ['hlbLevelf3', 'hlbLevel3'],
    ['hlbLevel4', 'hlbLevel4'],
    ['hlbLevelf4', 'hlbLevel4'],
    ['hlbLevelX', 'hlbLevelX'],
    ['hlbLevelNoSymp', 'hlbLevelNoSymp'],
    ['hlbLevelno_symptoms', 'hlbLevelNoSymp'],
    ['irrigation', 'irrigation'],
    ['irrigationDone', 'irrigationDone'],
    ['sampling', 'sampling'],
    ['samplingDone', 'samplingDone'],
    ['pestsInProgress', 'pestsInProgress'],
    ['diseaseInProgress', 'diseaseInProgress'],
    ['pestsDone', 'done'],
    ['diseaseDone', 'done']
  ];
  return {
    'icon-size': {
      base: 1,
      stops: [
        [15, isActive ? 0.18 : 0.12],
        [19, isActive ? 0.45 : 0.3]
      ]
    },
    'icon-allow-overlap': true,
    'icon-offset': {
      stops: [
        [15, [0, isActive ? -45 : -50]],
        [17, [0, isActive ? -65 : -70]],
        [20, [0, isActive ? -90 : -100]]
      ]
    },
    'icon-image': {
      type: 'categorical' as TPaintType,
      property: 'decoratedStatus',
      stops,
      default: 'unread'
    }
  };
};

const loadFeedIcons = (map: Map): Promise<void[]> => {
  // prettier-ignore
  const promiseIcon = ({ path, name }): Promise<void> => new Promise((res, rej) => {
    map.loadImage(path, (err, image) => {
      if (err || !image) {
        return rej(err);
      }

      map.addImage(name, image);
      return res();
    });
  });

  return Promise.all(
    [
      { path: '/images/feed/read.png', name: 'read' },
      { path: '/images/feed/unread.png', name: 'unread' },
      { path: '/images/feed/request.png', name: 'inProgress' },
      { path: '/images/feed/request_processed.png', name: 'done' },
      { path: '/images/feed/manual-score.png', name: EFeedItemType.ManualScore },
      { path: '/images/feed/manual-reindex.png', name: EFeedItemType.ManualReindex },
      { path: '/images/feed/request_fruit.png', name: 'fruitInProgress' },
      { path: '/images/feed/request_processed_fruit.png', name: 'fruitDone' },
      { path: '/images/feed/fruit-flag-done-wrong-location.png', name: 'fruitDoneWrongLocation' },
      { path: '/images/feed/calibration.png', name: 'calibration' },
      { path: '/images/feed/calibration-approved.png', name: 'calibrationApproved' },
      { path: '/images/feed/calibration-declined.png', name: 'calibrationDeclined' },
      { path: '/images/feed/hlb_go_check.png', name: 'hlbGoCheck' },
      { path: '/images/feed/hlb_sampled.png', name: 'hlbSampled' },
      { path: '/images/feed/hlb_level_1.png', name: 'hlbLevel1' },
      { path: '/images/feed/hlb_level_2.png', name: 'hlbLevel2' },
      { path: '/images/feed/hlb_level_3.png', name: 'hlbLevel3' },
      { path: '/images/feed/hlb_level_4.png', name: 'hlbLevel4' },
      { path: '/images/feed/hlb_level_x.png', name: 'hlbLevelX' },
      { path: '/images/feed/hlb_no_symp.png', name: 'hlbLevelNoSymp' },
      { path: '/images/feed/irrigation.png', name: 'irrigation' },
      { path: '/images/feed/irrigation_done.png', name: 'irrigationDone' },
      { path: '/images/feed/sampling.png', name: 'sampling' },
      { path: '/images/feed/sampling_done.png', name: 'samplingDone' },
      { path: '/images/feed/pests.png', name: 'pestsInProgress' },
      { path: '/images/feed/disease.png', name: 'diseaseInProgress' }
    ].map(promiseIcon)
  );
};

export const createLayers = (map: Map): void => {
  FEED_LAYERS.forEach((source) => {
    map.addSource(source, {
      data: turf.featureCollection([]),
      type: 'geojson'
    });
  });

  addLayerToMap(map, FEED_LAYERS[0], {
    id: FEED_LAYERS[0],
    type: 'symbol',
    source: FEED_LAYERS[0],
    layout: getFeedLayout()
  });

  addLayerToMap(map, FEED_LAYERS[3], {
    id: FEED_LAYERS[3],
    type: 'symbol',
    source: FEED_LAYERS[3],
    layout: {
      ...getFeedLayout(),
      'icon-image': {
        type: 'categorical',
        property: 'decoratedStatus',
        stops: [
          [EFeedItemType.ManualScore, EFeedItemType.ManualScore],
          [EFeedItemType.ManualReindex, EFeedItemType.ManualReindex]
        ]
      }
    }
  });

  addLayerToMap(map, FEED_LAYERS[1], {
    id: FEED_LAYERS[1],
    type: 'symbol',
    source: FEED_LAYERS[1],
    layout: getFeedLayout()
  });

  addLayerToMap(map, FEED_LAYERS[2], {
    id: FEED_LAYERS[2],
    type: 'symbol',
    source: FEED_LAYERS[2],
    layout: getFeedLayout(true)
  });

  FEED_LAYERS.forEach((layer) => {
    map.on('mousemove', layer, () => {
      map.getCanvas().style.cursor = 'pointer';
    });

    map.on('mouseout', layer, () => {
      map.getCanvas().style.cursor = '';
    });
  });

  reorderLayers(
    map,
    LAYERS_PRIORITY.map((layer, index) => ({
      zIndex: index,
      name: layer
    }))
  );
};

export const addFeedLayers = (map: Map, onLoad: () => void): void => {
  loadFeedIcons(map).then(() => {
    createLayers(map);
    onLoad();
  });
};

const getFruitCountStatus = (feature: ICreateFeedIncompleteParams, reportLocationThreshold: number): string => {
  if (feature.status === EFeedStatus.Done) {
    const reportLocation = feature.reportLocation || null;
    const feedItemLocation = feature.geometry || null;
    if (reportLocation && feedItemLocation) {
      // const distance = turf.distance(reportLocation, feedItemLocation, { units: 'meters' });
      const distance = 0;
      if (distance >= reportLocationThreshold) {
        return 'fruitDoneWrongLocation';
      }
    }

    return 'fruitDone';
  }

  return 'fruitInProgress';
};

const getHLBStatus = (feature: ICreateFeedIncompleteParams): string => {
  const hlbStatus = missionUtils.getDataByKeyFromReport(feature, 'hlbStatus');

  if (hlbStatus === EHLBStatus.NotApplicable) {
    return 'hlbLevelX';
  }

  if (hlbStatus === EHLBStatus.Sampled) {
    return 'hlbSampled';
  }

  if (hlbStatus === EHLBStatus.NoSymptoms) {
    return 'hlbLevelNoSymp';
  }

  return hlbStatus ? `hlbLevel${hlbStatus.replace('f', '')}` : 'hlbGoCheck';
};

const getFeatureStatus = (feature: ICreateFeedIncompleteParams): string => {
  const { status, type, data } = feature;
  const fieldReportDone = data?.isDone;

  if (typeof fieldReportDone === 'boolean') {
    return fieldReportDone ? `${type}Done` : type;
  }

  if (status === EFeedStatus.Done) {
    return `${type}Done`;
  }

  return type;
};

interface IDecoratedFeed extends IFeed {
  decoratedStatus: string;
}

const getDecoratedFeature = (feature: ICreateFeedIncompleteParams, reportLocationThreshold: number): turf.Feature<turf.Geometry, IDecoratedFeed> => {
  const properties = {
    ...feature,
    decoratedStatus: feature.status
  } as IDecoratedFeed;
  const fieldReportDone = feature.data?.isDone;

  if (feature.type === EFeedItemType.Count) {
    properties.decoratedStatus = getFruitCountStatus(feature, reportLocationThreshold);
  }

  if (feature.type === EFeedItemType.Hlb) {
    properties.decoratedStatus = getHLBStatus(feature);
  }

  if ([EFeedItemType.Irrigation, EFeedItemType.Sampling].includes(feature.type)) {
    properties.decoratedStatus = getFeatureStatus(feature);
  }

  if ([EFeedItemType.ManualReindex, EFeedItemType.ManualScore].some((type) => type === feature.type)) {
    properties.decoratedStatus = properties.type;
  }

  if ([EFeedItemType.Pests, EFeedItemType.PestsP3, EFeedItemType.WeeklyTrapMonitoring, EFeedItemType.PestAndDisease].includes(feature.type)) {
    properties.decoratedStatus = feature.status === EFeedStatus.Done || fieldReportDone ? 'pestsInProgress' : 'pestsInProgress';
  }

  if (feature.type === EFeedItemType.Disease) {
    properties.decoratedStatus = feature.status === EFeedStatus.Done || fieldReportDone ? 'diseaseDone' : 'diseaseInProgress';
  }

  if (typeof fieldReportDone === 'boolean') {
    if (feature.type === EFeedItemType.Pests || feature.type === EFeedItemType.WeeklyTrapMonitoring) {
      properties.decoratedStatus = fieldReportDone ? 'pestsInProgress' : 'pestsInProgress';
    }

    if (feature.type === EFeedItemType.Disease) {
      properties.decoratedStatus = fieldReportDone ? 'diseaseDone' : 'diseaseInProgress';
    }
  }

  if (feature.type === EFeedItemType.Calibration) {
    let decoratedStatus = '';

    switch (properties.status) {
      case EFeedStatus.Done:
        decoratedStatus = 'calibrationApproved';
        break;
      case EFeedStatus.Rejected:
        decoratedStatus = 'calibrationDeclined';
        break;
      default:
        decoratedStatus = 'calibration';
    }

    properties.decoratedStatus = decoratedStatus;
  }

  if (feature.type === EFeedItemType.General) {
    properties.decoratedStatus = feature.title ? EFeedStatus.Done : EFeedStatus.InProgress;

    if (typeof fieldReportDone === 'boolean') {
      properties.decoratedStatus = fieldReportDone ? EFeedStatus.Done : '';
    }
  }

  const { geometry } = feature;

  return turf.feature(geometry ? JSON.parse(geometry) : geometry, properties);
};

export const drawFeed = (map: Map, feed: ICreateFeedIncompleteParams[], reportLocationTreshold: number, clickFeedHandler?: TEventHandler): void => {
  const reportsSource = map.getSource(FEED_LAYERS[1]) as GeoJSONSource;
  const requestsSource = map.getSource(FEED_LAYERS[0]) as GeoJSONSource;

  if (!reportsSource || !requestsSource) return;

  const filteredMapFeed = feed.filter((item) => !!item.geometry && ![EFeedItemType.ManualScore, EFeedItemType.ManualReindex].includes(item.type));

  const requests = filteredMapFeed.map((item) => getDecoratedFeature(item, reportLocationTreshold));

  if (clickFeedHandler) {
    FEED_LAYERS.forEach((layer) => {
      map.on('click', layer, clickFeedHandler);
    });
  }

  requestsSource.setData(turf.featureCollection(requests) as any);
};

export const detachFeedEvents = (map: Map, clickFeedHandler: TEventHandler): void => {
  FEED_LAYERS.forEach((layer) => {
    map.off('click', layer, clickFeedHandler);
  });

  const reportsSource = map.getSource(FEED_LAYERS[1]) as GeoJSONSource;
  const requestsSource = map.getSource(FEED_LAYERS[0]) as GeoJSONSource;

  if (reportsSource && requestsSource) {
    requestsSource.setData(turf.featureCollection([]) as any);
    reportsSource.setData(turf.featureCollection([]) as any);
  }
};

export const drawSelectedFeedItem = (map: Map, item: IFeed | null, reportLocationTreshold: number): void => {
  const activeFeedItemSource = map.getSource(FEED_LAYERS[2]) as GeoJSONSource;

  if (!activeFeedItemSource) return;

  const features = item ? [getDecoratedFeature(item, reportLocationTreshold)] : [];
  activeFeedItemSource.setData(turf.featureCollection(features) as any);
};

export const showFeedPopup = (
  map: Map,
  items: IFeed[],
  farmID: string,
  setSelectedFeedItem: (val: null) => void,
  mission: IMission | null,
  onFeedPopupImageClicked?: (params: IFeedPopupClickParams) => void
): void => {
  const popups = document.querySelectorAll('.mapboxgl-popup');
  for (let i = 0; i < popups.length; i += 1) {
    popups[i].remove();
  }
  if (items && items?.[0]?.geoObjectType === 'tree') {
    const geometry = JSON.parse(items[0].geometry);

    const popup = new Popup()
      .setLngLat(geometry.coordinates)
      .setHTML(FeedHTMLPopup({ feed: items, farmID, mission }))
      .addTo(map);

    if (onFeedPopupImageClicked) {
      const images = popup.getElement().querySelectorAll('.feed-popup-image');
      const imageUrls = [...images].map((image) => ({
        url: image.getAttribute('data-url') || '',
        previewUrl: image.getAttribute('src') || ''
      }));

      images.forEach((image, index) => {
        image.addEventListener('click', () => {
          onFeedPopupImageClicked({
            imageUrls,
            index
          });
        });
      });
    }

    popup.on('close', () => {
      setSelectedFeedItem(null);
    });
  }
};
