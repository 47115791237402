import React, { useMemo } from 'react';
import { faPlus } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { mfColors } from 'vars';

const Wrapper = styled.div`
  width: 50%;
  margin: 22px auto 18px auto;
`;

const SlotTitle = styled.span`
  font-size: 16px;
  font-family: MontserratBold;
  color: ${mfColors.lightBlue};
  margin-left: 8px;
  cursor: pointer;
`;

const DividerLine = styled.div`
  border-bottom: 1px solid black;
  flex: 1;
`;

const DividerWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 16px 40px;
`;

interface IProps {
  onAdd: (slotIndex: number) => void;
  optionsLength: number;
  lastSlotCount?: number;
}

const SlotAddForm = ({ onAdd, optionsLength, lastSlotCount }: IProps) => {
  const { t } = useTranslation();
  const availableSlots = useMemo(
    () => [
      t('replant.calculator.define_slot', { count: 0 }),
      t('replant.calculator.define_slots', { count: 1 }),
      t('replant.calculator.define_slots', { count: 2 }),
      t('replant.calculator.define_slots', { count: 3 }),
      t('replant.calculator.define_large_slots', { count: lastSlotCount || undefined })
    ],
    [t, lastSlotCount]
  );
  const slotToAdd = useMemo(() => {
    if (!availableSlots[optionsLength + 1]) return null;

    return availableSlots[optionsLength];
  }, [optionsLength, availableSlots]);
  const lastSlotToAdd = useMemo(() => {
    if (optionsLength === availableSlots.length) return null;

    return availableSlots[4];
  }, [availableSlots, optionsLength]);

  return (
    <Wrapper>
      {slotToAdd && (
        <>
          <div onClick={() => onAdd(optionsLength)} style={{ textAlign: 'center' }}>
            <FontAwesomeIcon icon={faPlus} size={'lg'} color={mfColors.lightBlue} />
            <SlotTitle>{slotToAdd}</SlotTitle>
          </div>
          <DividerWrapper>
            <DividerLine />
            <div style={{ fontSize: 14, margin: '0 10px' }}>{t('shared.or')}</div>
            <DividerLine />
          </DividerWrapper>
        </>
      )}
      {lastSlotToAdd && (
        <div onClick={() => onAdd(availableSlots.length - 1)} style={{ textAlign: 'center' }}>
          <FontAwesomeIcon icon={faPlus} size={'lg'} color={mfColors.lightBlue} />
          <SlotTitle>{lastSlotToAdd}</SlotTitle>
        </div>
      )}
    </Wrapper>
  );
};

export default SlotAddForm;
