import React, { useEffect, useMemo, useState, useCallback, Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { Map } from 'mapbox-gl';
import { useModal } from 'react-modal-hook';

import { useFetchStatistic } from 'services/data/richItems';
import farmsHooks from 'hooks/farms.hooks';
import {
  useFetchGrovesStats,
  useFetchMissionReports,
  useFetchMissionsReports,
  useFetchGroveTrees,
  useFetchDefaultMissionsConfigs,
  useSubscribeToMissionsConfigs,
  useFetchReportLocationTreshold,
  useFetchBigQueryTreesData,
  useFetchSampledTrees,
  TGrovesParam
} from 'services/data/farms';

import regionsHooks from 'hooks/regions.hooks';
import signInHooks from 'hooks/signIn.hooks';
import { useAnalytics } from 'hooks/analytics.hooks';
import { addMissionsHeatmapMarkers, removeMissionsHeatmapMarkers } from 'services/util/mapMissionsHeatmap';

import MissionModal from 'components/missions/MissionModal';
import Modal from 'atomicComponents/Modal';

import { TAnyReport, EReportType } from 'models/report';
import { TAnyStatistic, TMappedStatistic } from 'models/statistic';
import { EMissionKpi, ICreateMissionIncompleteParams, EMissionType, EMissionSubtype, TMissionType, IMission, TMissionConfigEntry, EBudgetType } from 'models/mission';
import { ERichItemType } from 'models/richItem';
import { IGroveSurveyStats } from 'models/stats';
import { ISurveyWithTimeRange, ISurvey } from 'models/survey';
import { IFeed, ICreateFeedIncompleteParams, missionFeedTypeRelation, NOT_APPLICABLE_STATUSES } from 'models/feed';
import { ITreeData } from 'models/tree';
import { IUserInfo } from 'models/user';

import { EMeasurementSystem } from 'models/region';
import { EEventType, TMissionGeneralEventType, TMissionCreateClickEventType } from 'models/analytics';
import { IGrove } from 'models/grove';
import { useDispatch, useSelector } from 'react-redux';
import { capacityStatisticByRulesSelector } from 'redux/replant/replantSlice';
import replantHooks, { NON_SURVEYID } from 'hooks/replant.hooks';
import missionUtils, { IMetricGroveEntry, IMissionGrove, EMissionsSummaryMode, EDateRange } from 'utils/mission';
import { arrayToCsv, downloadBlob } from 'utils/helpers';
import dateUtils from 'utils/date';
import dateFormats from 'models/date';
import numberUtils from 'utils/numbers';

import { AlertIcon } from 'assets/images';
import { fieldReportsSelector, setFieldReports } from 'redux/missions/missionsSlice';
import feedUtils from 'utils/feed';

const KPI_RICH_ITEMS: { [key: string]: ERichItemType } = {
  [EMissionKpi.CanopyArea]: ERichItemType.CanopyArea,
  [EMissionKpi.TreeVolume]: ERichItemType.Volume,
  [EMissionKpi.CipoPercentage]: ERichItemType.Cipo,
  [EMissionKpi.CipoYesCount]: ERichItemType.Cipo,
  [EMissionKpi.WeedsPercentage]: ERichItemType.Weeds,
  [EMissionKpi.CanopyArea]: ERichItemType.CanopyArea,
  [EMissionKpi.TreeVolume]: ERichItemType.Volume,
  [EMissionKpi.TotalReplants]: ERichItemType.Capacity
};

const KPI_REPORTS: { [key: string]: EReportType } = {
  [EMissionKpi.HotspotsArea]: EReportType.TargetedScouting,
  [EMissionKpi.HotspotsPopulation]: EReportType.TargetedScouting,
  [EMissionKpi.Height]: EReportType.Height,
  [EMissionKpi.FreeArea]: EReportType.FreeArea,
  [EMissionKpi.WeedsPercentage]: EReportType.Weeds,
  [EMissionKpi.UnderperformingDifference]: EReportType.DedicatedVisit
};

interface IMissionLoggerResult {
  logMissionEvent: (mission: ICreateMissionIncompleteParams | null, eventType: TMissionGeneralEventType) => void;
  logMissionCreateClickEvent: (eventType: TMissionCreateClickEventType) => void;
  logMissionSelectZoneEvent: (zoneID: string | null) => void;
  logMissionSelectTypeEvent: (type: TMissionType) => void;
  logMissionResolveGroveEvent: (mission: IMission, groveID: string) => void;
}

const useMissionLogger = (): IMissionLoggerResult => {
  const { logEvent } = useAnalytics();
  const user = signInHooks.useSignedInUser();
  const { zones, groves } = farmsHooks.useFarmEntities();
  const { selectedFarm } = farmsHooks.useSelectedFarmEntities();

  const logMissionEvent = useCallback(
    (mission: ICreateMissionIncompleteParams | null, eventType: TMissionGeneralEventType) => {
      if (mission && user && selectedFarm) {
        logEvent({
          type: eventType,
          params: {
            email: user.email,
            farm: selectedFarm.name,
            missionName: mission.title,
            type: mission.type,
            zone: mission.zoneID ? zones.find((zone) => zone.id === mission.zoneID)?.name || '' : ''
          }
        });
      }
    },
    [user, zones, selectedFarm, logEvent]
  );

  const logMissionCreateClickEvent = useCallback(
    (eventType: TMissionCreateClickEventType) => {
      if (user && selectedFarm) {
        logEvent({
          type: eventType,
          params: {
            email: user.email,
            farm: selectedFarm.name
          }
        });
      }
    },
    [logEvent, selectedFarm, user]
  );

  const logMissionSelectZoneEvent = useCallback(
    (zoneID: string | null) => {
      if (user && selectedFarm) {
        logEvent({
          type: EEventType.MissionSelectZoneClicked,
          params: {
            email: user.email,
            farm: selectedFarm.name,
            zone: zoneID ? zones.find((zone) => zone.id === zoneID)?.name || '' : ''
          }
        });
      }
    },
    [zones, user, selectedFarm, logEvent]
  );

  const logMissionSelectTypeEvent = useCallback(
    (type: TMissionType) => {
      if (user && selectedFarm) {
        logEvent({
          type: EEventType.MissionSelectTypeClicked,
          params: {
            email: user.email,
            farm: selectedFarm.name,
            type
          }
        });
      }
    },
    [user, selectedFarm, logEvent]
  );

  const logMissionResolveGroveEvent = useCallback(
    (mission: IMission, groveID: string) => {
      if (user && selectedFarm) {
        logEvent({
          type: EEventType.MissionGroveResolve,
          params: {
            email: user.email,
            farm: selectedFarm.name,
            missionName: mission.title,
            grove: groves.find((grove) => grove.id === groveID)?.name || ''
          }
        });
      }
    },
    [user, selectedFarm, groves, logEvent]
  );

  return {
    logMissionEvent,
    logMissionCreateClickEvent,
    logMissionSelectZoneEvent,
    logMissionSelectTypeEvent,
    logMissionResolveGroveEvent
  };
};

interface IGetSampledTreesResult {
  getSampledTrees: (groveParams: TGrovesParam, treesCount: number, budgetType: EBudgetType, metricType?: EMeasurementSystem) => Promise<ICreateFeedIncompleteParams[]>;
  progress: number | null;
}

const GROVES_CHUNK_SIZE = 90;

const useGetSampledTrees = (user: IUserInfo, missionParams: ICreateMissionIncompleteParams, farmID: string): IGetSampledTreesResult => {
  const [progress, setProgress] = useState<number | null>(null);
  const fetchBigQueryTreesData = useFetchBigQueryTreesData();
  const fetchSampledTrees = useFetchSampledTrees();

  const getSampledTrees = useCallback(
    async (groveParams: TGrovesParam, treesCount: number, budgetType: EBudgetType, metricType = EMeasurementSystem.Metric) => {
      setProgress(0);

      const groveParamsChunks = groveParams.reduce((acc: TGrovesParam[], entry, index) => {
        const chunk = Math.round(index / GROVES_CHUNK_SIZE);
        if (!acc[chunk]) {
          acc[chunk] = [];
        }
        acc[chunk].push(entry);
        return acc;
      }, []);

      const chunkProgressStep = Math.floor(100 / (groveParamsChunks.length * 2));

      const sampledTreesGroups = await Promise.all(
        groveParamsChunks.map((groveParams) => {
          const promise = fetchSampledTrees(groveParams, treesCount, budgetType, metricType).then((data) => {
            setProgress((prev) => (prev || 0) + chunkProgressStep);
            return data;
          });
          return promise;
        })
      );

      const treeIDs = sampledTreesGroups.reduce((acc: string[], grovesTrees) => {
        const trees = (grovesTrees?.groves || []).flat();
        Array.prototype.push.apply(
          acc,
          trees.reduce((acc: string[], entry) => [...acc, ...(entry.trees || [])], [])
        );
        return acc;
      }, []);

      const treesChunkSize = Math.ceil(treeIDs.length / sampledTreesGroups.length);
      const treesChunks = treeIDs.reduce((acc: string[][], treeID: string, index: number) => {
        const chunk = Math.floor(index / treesChunkSize);
        if (!acc[chunk]) {
          acc[chunk] = [];
        }
        acc[chunk].push(treeID);
        return acc;
      }, []);

      const treesModelsGroups = await Promise.all(
        treesChunks.map((treeIDs) => {
          const promise = fetchBigQueryTreesData(farmID, treeIDs).then((data) => {
            setProgress((prev) => (prev || 0) + chunkProgressStep);
            return data;
          });
          return promise;
        })
      );
      const treesModels = treesModelsGroups.reduce(
        (acc: { [key: string]: ITreeData }, group) => ({
          ...acc,
          ...group
        }),
        {}
      );

      const treesFeed = treeIDs
        .map((treeID) => {
          const treeModel = treesModels[treeID];
          if (!treeModel) return null;
          return missionUtils.getMissionReportParams(
            user,
            missionParams,
            treeModel.index,
            treeID,
            { geometry: { type: 'Point', coordinates: treeModel.coordinates } },
            treeModel.groveID,
            treeModel.zoneID
          );
        })
        .filter((tree) => !!tree) as ICreateFeedIncompleteParams[];

      setProgress(100);

      return treesFeed;
    },
    [farmID, user, missionParams, fetchBigQueryTreesData, fetchSampledTrees]
  );

  return {
    getSampledTrees,
    progress
  };
};

const useMissionGroves = (mission: ICreateMissionIncompleteParams, groves: IMissionGrove[], survey: ISurveyWithTimeRange | null): IMetricGroveEntry[] => {
  const [kpiStatistic, setKpiStatistic] = useState<TAnyStatistic[]>([]);
  const [kpiReports, setKpiReports] = useState<(TAnyReport[] | null)[]>([]);
  const [surveyStats, setSurveyStats] = useState<IGroveSurveyStats[]>([]);
  const { surveys } = farmsHooks.useFarmEntities();
  const { hotspots, getFarmHotspots } = farmsHooks.useFarmHotspots();
  const metricType = regionsHooks.useGetMeasurementSystem() || EMeasurementSystem.Metric;
  const fetchGrovesStats = useFetchGrovesStats();
  const surveyID = useMemo(() => survey?.id, [survey]);
  const missionFarmID = useMemo(() => mission?.farmID, [mission]);

  const fetchStatistic = useFetchStatistic();
  const capacityStatisticByRules = useSelector(capacityStatisticByRulesSelector);
  replantHooks.useReplantCapacityStatistic(ERichItemType.Replant, mission.farmID, NON_SURVEYID, metricType);
  const { getFarmReports } = farmsHooks.useFarmReports();

  const previousSurvey = useMemo(() => {
    if (!survey) return null;

    const surveyIndex = surveys.findIndex((entry) => entry.id === survey.id);
    return surveyIndex < surveys.length - 1 ? surveys[surveyIndex + 1] : null;
  }, [survey, surveys]);

  const kpiRichItemTypes = useMemo(() => {
    const kpis = mission.kpis || missionUtils.getDefaultKpis(mission.type) || [];
    return kpis.map((kpi) => KPI_RICH_ITEMS[kpi]);
  }, [mission.kpis, mission.type]);

  const kpiReportTypes = useMemo(() => {
    const kpis = mission.kpis || missionUtils.getDefaultKpis(mission.type) || [];
    return kpis.map((kpi) => KPI_REPORTS[kpi]);
  }, [mission.kpis, mission.type]);

  useEffect(() => {
    if (missionFarmID && surveyID) {
      fetchGrovesStats(missionFarmID).then((stats) => setSurveyStats(stats.filter((stat) => stat.surveyID === surveyID)));
    }
  }, [missionFarmID, fetchGrovesStats, surveyID]);

  useEffect(() => {
    if (surveyID) {
      getFarmHotspots(missionFarmID, surveyID);
    }
  }, [missionFarmID, surveyID, getFarmHotspots]);

  useEffect(() => {
    Promise.all(
      kpiRichItemTypes.map((type) => {
        if (type && surveyID) {
          return fetchStatistic(missionFarmID, surveyID, type);
        } else {
          return Promise.resolve(null);
        }
      })
    ).then((kpiStatistics) => {
      setKpiStatistic(kpiStatistics as (TAnyStatistic | null)[]);
    });
  }, [kpiRichItemTypes, fetchStatistic, missionFarmID, surveyID]);

  useEffect(() => {
    Promise.all(
      kpiReportTypes.map((type) => {
        if (type && surveyID) {
          return getFarmReports(type as EReportType, missionFarmID, surveyID, previousSurvey?.id, true).then((reports) => {
            if (type !== EReportType.TargetedScouting) return reports;
            return [...reports, ...hotspots];
          });
        } else {
          return Promise.resolve(null);
        }
      })
    ).then((kpiStatistics) => {
      setKpiReports(kpiStatistics as (TAnyReport[] | null)[]);
    });
  }, [getFarmReports, kpiReportTypes, hotspots, missionFarmID, surveyID, previousSurvey?.id]);

  const grovesToShow = useMemo(() => {
    const result = groves
      .filter((grove) => !mission?.zoneID || grove.zoneID === mission.zoneID)
      .map((grove: IMissionGrove) => {
        const kpi = missionUtils.getMissionGroveKpi(mission, grove, surveyStats, kpiStatistic, kpiReports, metricType, capacityStatisticByRules);

        return {
          ...grove,
          kpi
        };
      });
    return result;
  }, [mission, groves, kpiStatistic, capacityStatisticByRules, kpiReports, surveyStats, metricType]);

  return grovesToShow;
};

const useMissionConfig = (mission: IMission | null): TMissionConfigEntry | null => {
  const [config, setConfig] = useState<TMissionConfigEntry | null>(null);
  const fetchDefaultMissionConfigs = useFetchDefaultMissionsConfigs();
  const subscribeToMissionsConfigs = useSubscribeToMissionsConfigs();

  useEffect(() => {
    setConfig(null);
    let unsubscribe: (() => void) | null = null;
    if (mission) {
      fetchDefaultMissionConfigs().then((defaultMissionConfigs) => {
        unsubscribe = subscribeToMissionsConfigs(mission.farmID, (missionConfigs) => {
          setConfig(missionConfigs.find((config) => config.id === mission.type) || missionUtils.getDefaultMissionConfigs(defaultMissionConfigs, mission) || null);
        });
      });
    }

    return () => {
      unsubscribe && unsubscribe();
    };
  }, [fetchDefaultMissionConfigs, subscribeToMissionsConfigs, mission]);

  return config;
};

interface IMissionFeedResult {
  feed: IFeed[];
  reportLocationTreshold: number;
  loading: boolean;
}

const useMissionFeed = (missions: IMission[]): IMissionFeedResult => {
  const [feed, setFeed] = useState<IFeed[]>([]);
  const [loading, setLoading] = useState(false);
  const [reportLocationTreshold, setReportLocationTreshold] = useState<number>(0);
  const fetchMissionsFeed = useFetchMissionsReports();
  const fetchReportLocationTreshold = useFetchReportLocationTreshold();

  const getMissionFeed = useCallback(async (): Promise<void> => {
    setFeed([]);
    setLoading(true);

    const feedType = missions[0]?.type && (missionFeedTypeRelation.get(missions[0]?.type) || (missions[0]?.subType && missionFeedTypeRelation.get(missions[0]?.subType)));
    if (feedType) {
      const paramsGroups = missions.reduce((acc: string[][], mission, index) => {
        const groupIndex = Math.floor(index / 10);
        if (!acc[groupIndex]) {
          acc[groupIndex] = [];
        }
        acc[groupIndex].push(mission.id);
        return acc;
      }, []);
      const feed = await Promise.all(paramsGroups.map((missions) => fetchMissionsFeed(missions, feedType)));
      setFeed(feedUtils.decorateFeed(feed.flat()));
    }
    setLoading(false);
  }, [fetchMissionsFeed, missions]);

  const getReportLocationTreshold = useCallback(async (): Promise<void> => {
    setReportLocationTreshold(await fetchReportLocationTreshold());
  }, [fetchReportLocationTreshold]);

  useEffect(() => {
    getMissionFeed();
  }, [getMissionFeed]);

  useEffect(() => {
    getReportLocationTreshold();
  }, [getReportLocationTreshold]);

  return {
    feed,
    reportLocationTreshold,
    loading
  };
};

interface ISelectedMissionsResult {
  selectedMissions: IMission[];
  missionsSummaryMode: EMissionsSummaryMode;
  setMissionsSummaryMode: (mode: EMissionsSummaryMode) => void;
  selectedDateRange: EDateRange | null;
  setSelectedDateRange: (range: EDateRange | null) => void;
}

const useSelectedMissions = (selectedMission: IMission | null, missions: IMission[]): ISelectedMissionsResult => {
  const [missionsSummaryMode, setMissionsSummaryMode] = useState(EMissionsSummaryMode.CurrentMission);
  const [selectedDateRange, setSelectedDateRange] = useState<EDateRange | null>(null);

  const selectedMissions = useMemo(() => {
    if (!selectedMission?.type || missionsSummaryMode === EMissionsSummaryMode.CurrentMission) {
      return !selectedMission?.type ? [] : [selectedMission];
    }
    return missions.filter((mission) => {
      const now = new Date();
      let startDate;

      switch (selectedDateRange) {
        case EDateRange.Last30Days:
          startDate = dateUtils.subtractDays(now, 30);
          break;
        case EDateRange.Last90days:
          startDate = dateUtils.subtractDays(now, 90);
          break;
        case EDateRange.LastYear:
          startDate = dateUtils.subtractDays(now, 365);
          break;
        default:
          break;
      }

      return mission.type === selectedMission.type && (!selectedMission.subType || mission.subType === selectedMission.subType) && (!startDate || mission.timestamp > startDate);
    });
  }, [selectedMission, missions, missionsSummaryMode, selectedDateRange]);

  useEffect(() => {
    setMissionsSummaryMode(EMissionsSummaryMode.CurrentMission);
  }, [selectedMission]);

  useEffect(() => {
    if (missionsSummaryMode === EMissionsSummaryMode.CurrentMission) {
      setSelectedDateRange(null);
    }
  }, [missionsSummaryMode]);

  return {
    selectedMissions,
    missionsSummaryMode,
    setMissionsSummaryMode,
    selectedDateRange,
    setSelectedDateRange
  };
};

const useIsAllowedToEdit = (): ((mission: IMission | null) => boolean) => {
  const user = signInHooks.useSignedInUser();
  const isAllowedToEdit = useCallback((mission: IMission | null) => !mission?.createdBy || mission.createdBy === user?.displayName, [user]);

  return isAllowedToEdit;
};

interface IConfirmEditModalResult {
  showConfirmEditModal: () => void;
  hideConfirmEditModal: () => void;
}

const useConfirmEditModal = (onOk: (mission: IMission) => void, onCancel: () => void, missionToEdit: IMission | null, cancelKey?: string): IConfirmEditModalResult => {
  const { t } = useTranslation();
  const [showConfirmEditModal, hideConfirmEditModal] = useModal(() => {
    const handleOk = () => {
      hideConfirmEditModal();
      if (missionToEdit) {
        onOk(missionToEdit);
      }
    };
    const handleCancel = () => {
      hideConfirmEditModal();
      onCancel();
    };
    return (
      <Modal showClose={false} width="520px" onClose={handleCancel}>
        <MissionModal
          onOk={handleOk}
          onCancel={handleCancel}
          icon={<AlertIcon />}
          iconFirst
          title={t('missions.sure')}
          content={t('missions.you_are_not_authorized_to_edit_this_mission')}
          okText={t('missions.yes_go_ahead')}
          cancelText={t(cancelKey || 'missions.no_go_back')}
        />
      </Modal>
    );
  }, [t, onOk, onCancel, missionToEdit]);

  return {
    showConfirmEditModal,
    hideConfirmEditModal
  };
};

interface IConfirmUpdateModalResult {
  showConfirmUpdateModal: () => void;
  hideConfirmUpdateModal: () => void;
}

const useConfirmUpdateModal = (onOk: (mission: IMission) => void, onCancel?: () => void, missionToUpdate?: IMission | null): IConfirmUpdateModalResult => {
  const { t } = useTranslation();
  const [showConfirmUpdateModal, hideConfirmUpdateModal] = useModal(() => {
    const handleOk = () => {
      hideConfirmUpdateModal();
      if (missionToUpdate) {
        onOk(missionToUpdate);
      }
    };
    const handleCancel = () => {
      hideConfirmUpdateModal();
      onCancel && onCancel();
    };
    return (
      <Modal showClose={false} width="520px" onClose={handleCancel}>
        <MissionModal
          onOk={handleOk}
          onCancel={handleCancel}
          icon={<AlertIcon />}
          iconFirst
          title={t('missions.sure')}
          content={t('missions.you_are_not_authorized_to_update_this_mission')}
          okText={t('missions.yes_go_ahead')}
          cancelText={t('missions.no_go_back')}
        />
      </Modal>
    );
  }, [t, onOk, onCancel, missionToUpdate]);

  return {
    showConfirmUpdateModal,
    hideConfirmUpdateModal
  };
};

interface IConfirmDeleteModalResult {
  showConfirmDeleteModal: () => void;
  hideConfirmDeleteModal: () => void;
}

const useConfirmDeleteModal = (onOk: () => void, onCancel: () => void): IConfirmDeleteModalResult => {
  const { t } = useTranslation();
  const [showConfirmDeleteModal, hideConfirmDeleteModal] = useModal(() => {
    const handleOk = () => {
      hideConfirmDeleteModal();
      onOk();
    };
    const handleCancel = () => {
      hideConfirmDeleteModal();
      onCancel();
    };
    return (
      <Modal showClose={false} width="520px" onClose={handleCancel}>
        <MissionModal
          onOk={handleOk}
          onCancel={handleCancel}
          icon={<AlertIcon />}
          iconFirst
          title={t('missions.sure')}
          content={t('missions.you_are_not_authorized_to_delete_this_mission')}
          okText={t('missions.yes_go_ahead')}
          cancelText={t('missions.no_go_back')}
        />
      </Modal>
    );
  }, [t, onOk, onCancel]);

  return {
    showConfirmDeleteModal,
    hideConfirmDeleteModal
  };
};

interface IExportWeeklyTrapFeedResult {
  exportTrees: (groves: IGrove[], feed: IFeed[]) => void;
}

const useExportWeeklyTrapFeed = (): IExportWeeklyTrapFeedResult => {
  const { t } = useTranslation();

  const exportTrees = useCallback(
    (groves: IGrove[], feed: IFeed[]) => {
      const treesToExport = feed
        .filter((feedEntry) => !!feedEntry.data?.trapType)
        .map((feedEntry) => {
          const grove = groves.find((grove) => grove.id === feedEntry.groveID);
          return [
            grove?.name || '',
            dateUtils.formatDate(feedEntry.updatedAt, dateFormats.DATE_FORMAT_DD_MMM),
            feedEntry.user.displayName,
            feedEntry.data?.trapType || '',
            feedEntry.data?.pestsNumber || 0,
            t(feedEntry.data?.changedBait ? 'shared.yes' : 'shared.no'),
            feedEntry.serialNumber || ''
          ];
        });
      downloadBlob(
        arrayToCsv([
          [
            t('missions.block'),
            t('shared.date'),
            t('missions.reported_by'),
            t('missions.pest_type'),
            t('missions.pests_counted'),
            t('missions.changed_bait'),
            t('missions.serial_number')
          ],
          ...treesToExport
        ]),
        'export.csv',
        'text/csv;charset=utf-8;'
      );
    },
    [t]
  );

  return {
    exportTrees
  };
};

interface IExportFeedResult {
  exportTrees: (mission: IMission, groves: IGrove[], survey: ISurvey, feed: IFeed[], filename: string) => void;
  loading: boolean;
}

const useExportFeed = (): IExportFeedResult => {
  const [exportLoading, setExportLoading] = useState(false);
  const { selectedFarm } = farmsHooks.useSelectedFarmEntities();
  const { t } = useTranslation();
  const fetchGroveTrees = useFetchGroveTrees();
  const metricType = regionsHooks.useGetMeasurementSystem();

  const exportTrees = useCallback(
    async (mission: IMission, groves: IGrove[], survey: ISurvey, feed: IFeed[], filename: string) => {
      if (!missionUtils.isExportSupported(mission.type, mission.subType)) return;
      setExportLoading(true);
      const grovesIDs = feed.map((feedEntry) => feedEntry.groveID);
      const uniqueGroveIDs = grovesIDs.filter((groveID, index) => grovesIDs.indexOf(groveID) === index);
      const grovesTrees = await Promise.all(uniqueGroveIDs.map((groveID) => fetchGroveTrees(selectedFarm?.id || '', groveID, survey.id)));
      const trees = grovesTrees.reduce((acc, next) => {
        Object.keys(next).forEach((key: string) => {
          acc[key] = next[key];
        });
        return acc;
      }, {});
      const grovesByIDs = groves.reduce(
        (acc: { [key: string]: IGrove }, grove) => ({
          ...acc,
          [grove.id]: grove
        }),
        {}
      );

      const treesToExport: string[][] = [];

      const fruitCountFieldTitles = [
        t('missions.counted_not_applicable'),
        ...(mission?.subType === EMissionSubtype.BloomMonth ? [t('missions.bloom_month_header')] : []),
        ...(mission?.subType !== EMissionSubtype.BloomMonth ? [t('missions.number_of_fruits')] : []),
        ...(mission?.subType === EMissionSubtype.BloomMonth ? Array.from(Array(20).keys()).map((key) => t('missions.fruit_x_diameter', { i: key + 1 })) : [])
      ];
      const showGroveID = mission.type === EMissionType.FruitCount;
      const getTreeIndex = (tree, feedEntry: IFeed) => tree?.index || feedEntry.geoObjectName;

      for (let i = 0; i < feed.length; i += 1) {
        const feedEntry = feed[i];

        const tree = trees[feedEntry.geoObjectID];
        let countedStatus = '';

        if (feedEntry?.data?.fruitCountStatus && NOT_APPLICABLE_STATUSES.includes(feedEntry.data.fruitCountStatus)) {
          countedStatus = t('missions.not_applicable');
        } else if (feedEntry?.data?.fruitCountStatus === 'isCounted') {
          countedStatus = t('missions.counted');
        }

        const fruitCountFields = [
          countedStatus,
          ...(mission?.subType === EMissionSubtype.BloomMonth ? [(feedEntry?.data?.bloomMonth || '').replace('b1', '')] : []),
          ...(mission?.subType !== EMissionSubtype.BloomMonth ? [feedEntry?.data?.fruitCountNumber || 0] : []),
          // prettier-ignore
          ...(mission?.subType === EMissionSubtype.BloomMonth
            ? Array.from(Array(20).keys()).map((key) => {
              const bloomData = feedEntry?.data?.[`f${key + 1}`];
              if (bloomData) {
                const parsedBloomData = JSON.parse(bloomData);
                return parsedBloomData.diameter;
              }
              return '';
            })
            : [])
        ];

        treesToExport.push([
          selectedFarm?.name || '',
          ...(showGroveID ? [`"${feedEntry.groveID}"`] : []),
          grovesByIDs[feedEntry.groveID]?.name || '',
          `"${feedEntry.geoObjectID}"`,
          getTreeIndex(tree, feedEntry),
          ...(mission.type === EMissionType.HLBScouting ? [feedEntry?.data?.hlbStatus || ''] : []),
          ...(mission.type === EMissionType.FruitCount ? fruitCountFields : []),
          ...(mission.type === EMissionType.Clipping ? [feedEntry?.data?.clippingStatus ? t(`missions.${feedEntry.data.clippingStatus}`) : ''] : []),
          ...(mission.subType === EMissionSubtype.PestsP3 ? missionUtils.getPestsP3CSVReportData(feedEntry) : []),
          tree?.score || '',
          tree?.volume ? numberUtils.formatVolume(numberUtils.convertVolumeMeterToFeet(tree.volume, metricType), 1, metricType, false) : '',
          grovesByIDs[feedEntry.groveID]?.attributes?.commodity || '',
          grovesByIDs[feedEntry.groveID]?.attributes?.subCommodity || '',
          grovesByIDs[feedEntry.groveID]?.attributes?.variety || '',
          grovesByIDs[feedEntry.groveID]?.attributes?.rootStock || '',
          grovesByIDs[feedEntry.groveID]?.attributes?.originalPlantYear || '',
          0, // add strata
          t(feedEntry?.isFarFromReport ? 'shared.no' : 'shared.yes'),
          dateUtils.formatDate(feedEntry.updatedAt, dateFormats.DATE_FORMAT_FULL),
          feedEntry.user.displayName || '',
          feedEntry.user.email || '',
          feedEntry.serialNumber || ''
        ] as string[]);
      }

      downloadBlob(
        arrayToCsv([
          [
            t('missions.farm_name'),
            ...(showGroveID ? ['groveID'] : []),
            t('missions.grove_name'),
            'treeID',
            t('missions.tree_index_number'),
            ...(mission.type === EMissionType.HLBScouting ? [t('missions.hlb_value')] : []),
            ...(mission.type === EMissionType.FruitCount ? fruitCountFieldTitles : []),
            ...(mission.type === EMissionType.Clipping ? [t('missions.clipped_not_clipped')] : []),
            ...(mission.subType === EMissionSubtype.PestsP3 ? missionUtils.getPestsP3CSVHeaders(t) : []),
            t('missions.health_score'),
            t('main.map.volume'),
            t('main.info.commodity'),
            t('main.info.sub_commodity'),
            t('missions.variety'),
            t('main.info.rootstock'),
            t('missions.original_plant_year'),
            t('missions.strata'),
            t('missions.reported_close_to_tree'),
            t('missions.done_date'),
            t('missions.user_reported'),
            t('missions.user_email'),
            t('missions.serial_number')
          ],
          ...treesToExport
        ]),
        `${filename}.csv`,
        'text/csv;charset=utf-8;'
      );
      setExportLoading(false);
    },
    [fetchGroveTrees, selectedFarm, metricType, t]
  );

  return {
    loading: exportLoading,
    exportTrees
  };
};

interface IMissionHeatmapResult {
  isHeatmapVisible: boolean;
  setIsHeatmapVisible: Dispatch<SetStateAction<boolean>>;
}

const useMissionHeatmap = (map: Map | null, missions: IMission[], feed: IFeed[]): IMissionHeatmapResult => {
  const [isHeatmapVisible, setIsHeatmapVisible] = useState(false);
  const { groves, selectedSurveyStats: surveyStats, grovesSurveysStats } = farmsHooks.useFarmEntities();

  useEffect(() => {
    if (!missions.length) {
      setIsHeatmapVisible(false);
    }
  }, [missions]);

  useEffect(() => {
    if (!map) return;

    removeMissionsHeatmapMarkers(map);

    if (isHeatmapVisible && missions.length) {
      const groveIDs = missions.reduce((acc: string[], mission) => {
        const missionGroveIDs = Object.keys(mission.groves);
        return [...acc, ...missionGroveIDs.filter((groveID) => !acc.includes(groveID))];
      }, []);
      const missionGroves = groveIDs
        .map((groveID) => {
          const grove = groves.find((grove) => grove.id === groveID);
          if (!grove) return null;

          return grove;
        })
        .filter((grove) => !!grove) as IGrove[];

      addMissionsHeatmapMarkers(map, missions, missionGroves as IGrove[], feed, surveyStats, grovesSurveysStats);
    }
  }, [map, missions, isHeatmapVisible, groves, feed, surveyStats, grovesSurveysStats]);

  return {
    isHeatmapVisible,
    setIsHeatmapVisible
  };
};

const useFeedByGrove = <T extends IFeed>(feed: T[]): { [key: string]: T[] } => {
  const feedByGrove = useMemo(() => {
    const result = feed.reduce((acc: { [key: string]: T[] }, entry) => {
      if (!acc[entry.groveID]) {
        acc[entry.groveID] = [];
      }
      acc[entry.groveID].push(entry);
      return acc;
    }, {});
    return result;
  }, [feed]);

  return feedByGrove;
};

interface ReplantRow {
  id: string;
  name: string;
  groveAge: number;
  rootstock: string;
  variety: string;
  replants: number;
}

const useExportReplants = () => {
  const { t } = useTranslation();

  const exportReplants = useCallback(
    async (mission: IMission, groves: IGrove[], statistic: TMappedStatistic) => {
      const data = Object.keys(statistic).reduce((acc, groveID) => {
        if (!mission.groves[groveID]) return acc;

        const groveData = groves.find((grove) => grove.id === groveID);
        const plantYear = groveData?.attributes?.originalPlantYear;

        const replantRow = {
          id: groveID,
          name: groveData?.name,
          groveAge: plantYear ? dateUtils.getYearDifference(plantYear) : '',
          rootstock: groveData?.attributes?.rootStock || '',
          variety: groveData?.attributes?.variety || '',
          replants: statistic?.[groveID]?.statistic || 0
        };

        return acc.concat(replantRow as ReplantRow);
      }, [] as ReplantRow[]);

      const rowsToExport = data.map((item) => [item.name, item.groveAge, item.rootstock, item.variety, item.replants]);
      downloadBlob(
        arrayToCsv([[t('shared.grove'), t('main.info.grove_age'), t('main.info.rootstock'), t('missions.variety'), t('main.info.total_replants')], ...rowsToExport]),
        `${mission.title}_replant_inventory.csv`,
        'text/csv;charset=utf-8;'
      );
    },
    [t]
  );

  return {
    exportReplants
  };
};

const useMissionFieldReports = (farmID?: string) => {
  const dispatch = useDispatch();
  const fetchMissionFeed = useFetchMissionReports();
  const fieldReports = useSelector(fieldReportsSelector);

  useEffect(() => {
    if (farmID) {
      const missionID = `${farmID}-Field_Report`;
      fetchMissionFeed(missionID, undefined, 'updatedAt').then((reports) => {
        dispatch(setFieldReports(reports));
      });
    }
  }, [farmID, fetchMissionFeed, dispatch]);

  return {
    fieldReports
  };
};

export enum EMapSelectionMode {
  Tree = 'Tree',
  Block = 'Block'
}

interface IMapSelectionModeResult {
  mapSelectionMode: EMapSelectionMode;
  setMapSelectionMode: Dispatch<SetStateAction<EMapSelectionMode>>;
}

const useMapSelectionMode = (isTreeLevelMission: boolean): IMapSelectionModeResult => {
  const [mapSelectionMode, setMapSelectionMode] = useState<EMapSelectionMode>(EMapSelectionMode.Block);

  useEffect(() => {
    setMapSelectionMode(isTreeLevelMission ? EMapSelectionMode.Tree : EMapSelectionMode.Block);
  }, [isTreeLevelMission]);

  return {
    mapSelectionMode,
    setMapSelectionMode
  };
};

const missionHooks = {
  useMissionGroves,
  useMissionFeed,
  useMissionLogger,
  useMissionConfig,
  useExportFeed,
  useIsAllowedToEdit,
  useConfirmEditModal,
  useConfirmDeleteModal,
  useConfirmUpdateModal,
  useExportWeeklyTrapFeed,
  useMissionHeatmap,
  useSelectedMissions,
  useFeedByGrove,
  useExportReplants,
  useGetSampledTrees,
  useMissionFieldReports,
  useMapSelectionMode
};

export default missionHooks;
