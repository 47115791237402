import React from 'react';
import styled from 'styled-components';

import mfColors from 'vars/colors';

const Wrapper = styled.label`
  position: relative;
  min-width: 24px;
  height: 24px;
  cursor: pointer;

  input[type='radio'] {
    position: absolute;
    visibility: hidden;
  }

  input[type='radio']:checked ~ div::before {
    display: block;
  }
`;

const Label = styled.div`
  padding: ${({ padding }: { padding: string }) => padding};
  height: 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Check = styled.div`
  display: block;
  position: absolute;
  border: 1px solid ${mfColors.iconButtonBorder};
  border-radius: 100%;
  height: 24px;
  width: 24px;
  top: 0;
  left: 0;
  z-index: 2;
  transition: border 0.25s linear;

  &::before {
    content: '';
    display: none;
    position: absolute;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    top: 4px;
    left: 4px;
    background: ${mfColors.darkBlue};
  }
`;
interface IProps {
  name: string;
  value: string;
  checked: boolean;
  label?: JSX.Element | string;
  disabled?: boolean;
  hidden?: boolean;
}

const Radio = ({ name, value, checked, label, disabled = false, hidden = false }: IProps): JSX.Element => (
  <Wrapper>
    <input type="radio" disabled={disabled} name={name} value={value} checked={checked} onChange={() => {}} />
    {!hidden ? <Check /> : undefined}
    {label && <Label padding={hidden ? '0' : '0 0 0 24px'}>{label}</Label>}
  </Wrapper>
);

export default Radio;
