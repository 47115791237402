import React, { useMemo } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import numberUtils from 'utils/numbers';
import { ITree } from 'models/tree';
import { EMeasurementSystem } from 'models/region';

interface IProps {
  tree: ITree;
  metricType?: EMeasurementSystem;
}

const Wrapper = styled.div`
  padding: 16px 32px 32px;
`;

const ContentRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 0 16px;
  font-size: 16px;

  &:last-child {
    margin: 0;
  }
`;

const MetricTitle = styled.div`
  min-width: 140px;
`;

const MetricValue = styled.div`
  font-family: MontserratBold;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const ScoreValue = styled.span`
  margin: 0 0 0 20px;
`;

const Header = styled.div`
  font-family: MontserratBold;
  display: flex;
  justify-content: center;
  font-size: 16px;
  margin: 0 0 16px;
`;

const TreePopupContent = ({ tree, metricType }: IProps): JSX.Element => {
  const { t } = useTranslation();

  const canopyAreaValue = tree['canopy area'];

  const height = useMemo(() => numberUtils.formatHeight(tree.height, 1, metricType), [tree.height, metricType]);

  const volume = useMemo(() => numberUtils.formatVolume(tree.volume, 1, metricType), [tree.volume, metricType]);

  const canopyArea = useMemo(() => numberUtils.formatSquare(canopyAreaValue, 1, metricType), [canopyAreaValue, metricType]);

  const RichItemRenderer = (richItem: string, score: number) => {
    const richItemValue = useMemo(
      () => (parseInt(richItem.split(' ')[0]) !== 0 ? <MetricValue dangerouslySetInnerHTML={{ __html: richItem }} /> : <MetricValue> N/A </MetricValue>),
      [richItem]
    );
    const isTreeScoreZero = score === 0;
    const returnedValue = isTreeScoreZero ? <MetricValue> 0 </MetricValue> : richItemValue;
    return returnedValue;
  };

  const CipoDetectedRenderer = (score: number) => {
    const isTreeScoreZero = score === 0;
    const returnedValue = isTreeScoreZero ? 'No' : tree.detected;
    return <MetricValue>{returnedValue}</MetricValue>;
  };

  return (
    <Wrapper
      onMouseMove={(e: React.MouseEvent) => {
        e.stopPropagation();
        e.preventDefault();
      }}
    >
      {!!tree.treeName && <Header>{tree.treeName}</Header>}
      <ContentRow>
        <MetricTitle>{t('main.map.health_score')}</MetricTitle>
        <MetricValue>
          <ScoreValue>{tree.score}</ScoreValue>
        </MetricValue>
      </ContentRow>
      <ContentRow>
        <MetricTitle>{t('main.map.tree_height')}</MetricTitle>
        {RichItemRenderer(height, tree.score)}
      </ContentRow>
      <ContentRow>
        <MetricTitle>{t('main.map.volume')}</MetricTitle>
        {RichItemRenderer(volume, tree.score)}
      </ContentRow>
      <ContentRow>
        <MetricTitle>{t('main.map.canopy_area')}</MetricTitle>
        {RichItemRenderer(canopyArea, tree.score)}
      </ContentRow>
      {tree.detected && (
        <ContentRow>
          <MetricTitle>{t('main.map.cipo_detected')}</MetricTitle>
          {CipoDetectedRenderer(tree.score)}
        </ContentRow>
      )}
    </Wrapper>
  );
};

export default TreePopupContent;
