import React, { useState, useEffect, useRef, useLayoutEffect, useCallback, useMemo } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp, faChevronDown } from '@fortawesome/pro-solid-svg-icons';

import { SpringAnimation } from 'atomicComponents/animations';

export enum EPosition {
  Top = 'top',
  Bottom = 'bottom'
}

const Control = styled.div`
  position: relative;
  padding: 0 24px 0 0px;
`;

const ControlIcon = styled(FontAwesomeIcon)`
  position: absolute;
  right: 6px;
  top: 50%;
  transform: translateY(-50%);
`;

const Option = styled.div`
  position: relative;
  padding: 8px 0;
`;

interface IOptionsWrapperProps {
  background: string;
  isAbsolute: boolean;
}

const OptionsWrapper = styled.div`
  position: ${({ isAbsolute }: IOptionsWrapperProps) => (isAbsolute ? 'absolute' : 'fixed')};
  animation: ${SpringAnimation} 300ms ease-in-out forwards;
  transform-origin: top center;
  background: ${({ background }: IOptionsWrapperProps) => background};
  padding: 0 0 0 4px;
  z-index: 7;
  max-height: 200px;
  overflow: auto;
  min-width: 132px;
`;

interface ISelectProps {
  isExpanded: boolean;
  isDisabled: boolean;
}

const ControlWrapper = styled.div<ISelectProps>`
  cursor: ${({ isDisabled }: ISelectProps) => (isDisabled ? 'default' : 'pointer')};
  opacity: ${({ isDisabled }: ISelectProps) => (isDisabled ? 0.7 : 1)};
  position: relative;
  line-height: 20px;
  padding: 8px 0;

  ${OptionsWrapper} {
    display: ${(props: ISelectProps) => (props.isExpanded ? 'block' : 'none')};
  }
`;

interface IDropdownPosition {
  top: string;
  left: string;
  bottom: string;
}

export interface IOption<ValueType> {
  value: ValueType;
  text: JSX.Element | string;
  key: string;
  icon?: JSX.Element;
}

interface IProps<T> {
  value: T;
  onChange: (value: T) => void;
  options: IOption<T>[];
  isDisabled?: boolean;
  placeholder?: string;
  position?: EPosition;
  isAbsolute?: boolean;
  background?: string;
  style?: { [key: string]: string };
  dropdownStyle?: { [key: string]: string };
}

const InlineSelect = <T extends unknown>({
  value,
  onChange,
  options,
  isDisabled = false,
  background = 'transparent',
  position = EPosition.Bottom,
  placeholder = '',
  isAbsolute = false,
  style,
  dropdownStyle
}: IProps<T>): JSX.Element => {
  const controlRef = useRef<HTMLDivElement>(null);
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const [dropdownPosition, setDropdownPosition] = useState<IDropdownPosition | null>(null);

  const selectedOption = useMemo(() => options.find((option) => option.value === value), [options, value]);
  const optionsToShow = useMemo(() => options.filter((option) => option.value !== value), [options, value]);

  const trackDropdownPosition = useCallback(() => {
    if (controlRef && controlRef.current) {
      const controlRect = controlRef.current.getBoundingClientRect();
      const positionStyle: IDropdownPosition | null = {
        left: `${isAbsolute ? 0 : controlRect.left - 4}px`,
        top: position === EPosition.Bottom ? `${isAbsolute ? controlRect.height : controlRect.bottom}px` : 'auto',
        bottom: position === EPosition.Top ? `${isAbsolute ? controlRect.height : window.innerHeight - controlRect.top}px` : 'auto'
      };

      setDropdownPosition({
        ...dropdownStyle,
        ...positionStyle
      });
    }
  }, [dropdownStyle, position, isAbsolute]);

  const handleClickOutside = useCallback(
    (e: MouseEvent) => {
      if (controlRef.current && e.target && !controlRef.current.contains(e.target as HTMLElement)) {
        setIsExpanded(false);
      }
    },
    [setIsExpanded, controlRef]
  );

  useEffect(() => {
    if (isExpanded) {
      trackDropdownPosition();
    }
  }, [isExpanded, trackDropdownPosition]);

  useLayoutEffect(() => {
    trackDropdownPosition();
    window.addEventListener('scroll', trackDropdownPosition);
    window.addEventListener('click', handleClickOutside, true);
    return () => {
      window.removeEventListener('scroll', trackDropdownPosition);
      window.removeEventListener('click', handleClickOutside, true);
    };
  }, [trackDropdownPosition, handleClickOutside]);

  return (
    <ControlWrapper isDisabled={isDisabled} style={style} isExpanded={isExpanded} ref={controlRef} onClick={() => !isDisabled && setIsExpanded((expanded) => !expanded)}>
      <Control>
        <span>{selectedOption?.text || placeholder}</span>
        <ControlIcon icon={isExpanded ? faChevronUp : faChevronDown} />
      </Control>
      {dropdownPosition && (
        <OptionsWrapper
          isAbsolute={isAbsolute}
          background={background}
          onClick={(e) => {
            e.stopPropagation();
          }}
          style={dropdownPosition}
        >
          {optionsToShow.map((option) => (
            <Option
              key={option.key}
              onClick={() => {
                onChange(option.value);
                setIsExpanded(false);
              }}
            >
              {option.text}
            </Option>
          ))}
        </OptionsWrapper>
      )}
    </ControlWrapper>
  );
};

export default InlineSelect;
