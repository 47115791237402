import React, { useMemo } from 'react';
import styled from 'styled-components';

import { SisenseFrame } from 'components/biDashboard';

import { ICustomerDashboard } from 'models/dashboard';
import { IFarmName } from 'models/farm';

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`;

interface IProps {
  dashboard: ICustomerDashboard;
  selectedFarm: IFarmName | null;
}

const Sisense = ({ dashboard, selectedFarm }: IProps): JSX.Element => {
  const filters = useMemo(() => {
    const result = {};

    dashboard.filters.forEach((element) => {
      if (element === 'farmName' && selectedFarm) {
        result[element] = selectedFarm.name;
      }
    });

    return result;
  }, [dashboard, selectedFarm]);

  return (
    <Wrapper>
      <SisenseFrame dashboardID={dashboard.id} filters={filters} />
    </Wrapper>
  );
};

export default Sisense;
