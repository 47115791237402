import { keyframes } from 'styled-components';

export const SpringAnimation = keyframes`
  0% {
    transform: scaleY(0);
    opacity: 0;
  }
  80% {
    transform: scaleY(1.1);
    opacity: 0.8;
  }
  100% {
    transform: scaleY(1);
    opacity: 1;
  }
`;

export default SpringAnimation;
