import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from 'redux/store';
import { ICompany } from 'models/company';
import { getSelectedFarm } from 'redux/farm/farmSlice';

interface ICompanyState {
  companies: ICompany[];
}

const initialState = {
  companies: []
} as ICompanyState;

const slice = createSlice({
  name: 'company',
  initialState,
  reducers: {
    setCompanies: (state, action: PayloadAction<ICompany[]>) => ({ ...state, companies: action.payload })
  }
});

export const companyReducer = slice.reducer;

export const getCompanies = (state: RootState) => state.company.companies;

export const getFarmCompany = createSelector([getSelectedFarm, getCompanies], (farm, companies): ICompany | null => {
  if (!farm || !companies.length) {
    return null;
  }

  return companies.find((company) => company.id === farm.customerID) || null;
});

export const { setCompanies } = slice.actions;
