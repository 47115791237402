import { Map, GeoJSONSource } from 'mapbox-gl';
import * as turf from '@turf/turf';

import {
  loadImage,
  reorderLayers,
  getMapSourceById,
  MISSION_RESOLVED_GROVE_BORDER,
  MISSION_RESOLVED_GROVE_BG,
  MISSION_RESOLVED_GROVE_TITLE,
  GROVES_MISSIONS_LAYERS,
  LAYERS_PRIORITY,
  TPaintValue
} from 'services/util/mapHelpers';
import { MISSION_COLORS } from 'models/colors';
import { EReportType, TAnyReport, ICipoReport } from 'models/report';
import { IGrove } from 'models/grove';

const SOURCE_NAME = 'mission-groves';
const SELECTED_GROVES_SOURCE_NAME = 'mission-selected-groves';
const RESOLVED_GROVES_SOURCE_NAME = 'mission-resolved-groves';

const SUPPORTED_MISSION_TYPES = [EReportType.Cipo];

const getPaintValue = (missionType: EReportType): TPaintValue | null => {
  if (!SUPPORTED_MISSION_TYPES.includes(missionType)) {
    return null;
  }
  const colorPairs = Object.keys(MISSION_COLORS[missionType])
    .sort((key, nextKey) => Number(key) - Number(nextKey))
    .reduce((result: (number | string)[], score) => {
      result.push(+score, MISSION_COLORS[missionType][score]);
      return result;
    }, []);
  return ['step', ['get', missionType], MISSION_COLORS[missionType][0], -1, 'transparent', ...colorPairs];
};

const removeReportsLayers = (map: Map): void => {
  if (map.getLayer(GROVES_MISSIONS_LAYERS[0])) {
    map.removeLayer(GROVES_MISSIONS_LAYERS[0]);
  }
  if (map.getLayer(GROVES_MISSIONS_LAYERS[1])) {
    map.removeLayer(GROVES_MISSIONS_LAYERS[1]);
  }
  if (map.getSource(SOURCE_NAME)) {
    map.removeSource(SOURCE_NAME);
  }
};

const removeMissionLayers = (map: Map): void => {
  if (map.getLayer(MISSION_RESOLVED_GROVE_BG)) {
    map.removeLayer(MISSION_RESOLVED_GROVE_BG);
  }
  if (map.getLayer(MISSION_RESOLVED_GROVE_BORDER)) {
    map.removeLayer(MISSION_RESOLVED_GROVE_BORDER);
  }
  if (map.getLayer(MISSION_RESOLVED_GROVE_TITLE)) {
    map.removeLayer(MISSION_RESOLVED_GROVE_TITLE);
  }
  if (map.getLayer(GROVES_MISSIONS_LAYERS[2])) {
    map.removeLayer(GROVES_MISSIONS_LAYERS[2]);
  }
  if (map.getLayer(GROVES_MISSIONS_LAYERS[3])) {
    map.removeLayer(GROVES_MISSIONS_LAYERS[3]);
  }
  if (map.getSource(SELECTED_GROVES_SOURCE_NAME)) {
    (map.getSource(SELECTED_GROVES_SOURCE_NAME) as GeoJSONSource).setData(turf.featureCollection([]));
    map.removeSource(SELECTED_GROVES_SOURCE_NAME);
  }
  if (map.getSource(RESOLVED_GROVES_SOURCE_NAME)) {
    (map.getSource(RESOLVED_GROVES_SOURCE_NAME) as GeoJSONSource).setData(turf.featureCollection([]));
    map.removeSource(RESOLVED_GROVES_SOURCE_NAME);
  }
};

const setGrovesScore = (map: Map, missionType: EReportType, groves: IGrove[], reports: TAnyReport[]): void => {
  const source = getMapSourceById(map, SOURCE_NAME);

  if (source) {
    const features = groves.map((grove) => {
      const feature = turf.polygon(grove.geometry.coordinates, grove);
      const report = reports.find((report) => report.groveID === grove.id);

      if (!report) {
        return {
          ...feature,
          properties: {
            ...feature.properties,
            [missionType]: -1
          }
        };
      }

      switch (missionType) {
        case EReportType.Cipo:
          return {
            ...feature,
            properties: {
              ...feature.properties,
              [missionType]: (report as ICipoReport).cipo_rate * 100
            }
          };
        default:
          return feature;
      }
    });
    (source as GeoJSONSource).setData(turf.featureCollection(features));
  }
};

const setSelectedGroves = (map: Map, groves: IGrove[]): void => {
  const source = getMapSourceById(map, SELECTED_GROVES_SOURCE_NAME);

  if (source) {
    const features = groves.map((grove) => turf.polygon(grove.geometry.coordinates, grove));
    (source as GeoJSONSource).setData(turf.featureCollection(features));
  }
};

const setResolvedGroves = (map: Map, groves: IGrove[]): void => {
  const source = getMapSourceById(map, RESOLVED_GROVES_SOURCE_NAME);

  if (source) {
    const features = groves.map((grove) => turf.polygon(grove.geometry.coordinates, grove));
    (source as GeoJSONSource).setData(turf.featureCollection(features));
  }
};

const addMissionLayers = async (map: Map, selectedGroves: IGrove[] = [], isResolve = false): Promise<void> => {
  await loadImage(map, '/images/missions/resolved.png', 'resolved');

  if (!map.getSource(SELECTED_GROVES_SOURCE_NAME)) {
    map.addSource(SELECTED_GROVES_SOURCE_NAME, {
      data: turf.featureCollection([]),
      type: 'geojson'
    });
  }
  if (!map.getSource(RESOLVED_GROVES_SOURCE_NAME)) {
    map.addSource(RESOLVED_GROVES_SOURCE_NAME, {
      data: turf.featureCollection([]),
      type: 'geojson'
    });
  }

  if (isResolve) {
    setResolvedGroves(map, selectedGroves);
  } else {
    setSelectedGroves(map, selectedGroves);
  }

  if (map.getLayer(MISSION_RESOLVED_GROVE_BG)) {
    map.removeLayer(MISSION_RESOLVED_GROVE_BG);
  }

  if (map.getLayer(GROVES_MISSIONS_LAYERS[2])) {
    map.removeLayer(GROVES_MISSIONS_LAYERS[2]);
  }
  if (map.getLayer(GROVES_MISSIONS_LAYERS[3])) {
    map.removeLayer(GROVES_MISSIONS_LAYERS[3]);
  }

  map.addLayer({
    id: MISSION_RESOLVED_GROVE_BG,
    type: 'fill',
    source: RESOLVED_GROVES_SOURCE_NAME,
    paint: {
      'fill-color': '#ECECEC'
    }
  });

  map.addLayer({
    id: MISSION_RESOLVED_GROVE_BORDER,
    type: 'line',
    source: RESOLVED_GROVES_SOURCE_NAME,
    paint: {
      'line-color': '#9D9D9D',
      'line-width': 3
    }
  });

  map.addLayer({
    id: MISSION_RESOLVED_GROVE_TITLE,
    type: 'symbol',
    source: RESOLVED_GROVES_SOURCE_NAME,
    paint: {
      'text-color': '#9d9d9d',
      'text-halo-width': 2
    },
    layout: {
      'text-allow-overlap': true,
      'text-font': ['Roboto Regular'],
      'text-offset': [0, 0.5],
      'text-size': {
        stops: [
          [11.4, 2],
          [14, 21]
        ]
      },
      'text-field': '{name}'
    }
  });

  map.addLayer({
    id: GROVES_MISSIONS_LAYERS[2],
    type: 'line',
    source: SELECTED_GROVES_SOURCE_NAME,
    paint: {
      'line-color': '#ffffbb',
      'line-width': 6
    }
  });

  map.addLayer({
    id: GROVES_MISSIONS_LAYERS[3],
    type: 'symbol',
    source: RESOLVED_GROVES_SOURCE_NAME,
    layout: {
      'icon-image': 'resolved',
      'icon-allow-overlap': true,
      'icon-size': {
        base: 2,
        stops: [
          [16, 0.5],
          [19, 1],
          [24, 2]
        ]
      },
      'icon-offset': {
        stops: [
          [16, [0, -25]],
          [17, [0, -40]],
          [20, [0, -50]]
        ]
      }
    }
  });

  reorderLayers(
    map,
    LAYERS_PRIORITY.map((layer, index) => ({
      zIndex: index,
      name: layer
    }))
  );
};

const addReportsLayers = (map: Map, missionType: EReportType, groves: IGrove[], reports: TAnyReport[]): void => {
  if (!map.getSource(SOURCE_NAME)) {
    map.addSource(SOURCE_NAME, {
      data: turf.featureCollection([]),
      type: 'geojson'
    });
  }

  setGrovesScore(map, missionType, groves, reports);

  const color = getPaintValue(missionType);

  if (map.getLayer(GROVES_MISSIONS_LAYERS[0])) {
    map.removeLayer(GROVES_MISSIONS_LAYERS[0]);
  }

  if (map.getLayer(GROVES_MISSIONS_LAYERS[1])) {
    map.removeLayer(GROVES_MISSIONS_LAYERS[1]);
  }

  if (!color) {
    return;
  }

  map.addLayer({
    id: GROVES_MISSIONS_LAYERS[0],
    type: 'fill',
    source: SOURCE_NAME,
    paint: {
      'fill-color': color
    }
  });

  map.addLayer({
    id: GROVES_MISSIONS_LAYERS[1],
    type: 'line',
    source: SOURCE_NAME,
    paint: {
      'line-color': '#ffffe0',
      'line-width': 1
    }
  });

  reorderLayers(
    map,
    LAYERS_PRIORITY.map((layer, index) => ({
      zIndex: index,
      name: layer
    }))
  );
};

const mapFarmMissionsService = {
  addReportsLayers,
  removeMissionLayers,
  addMissionLayers,
  removeReportsLayers
};

export default mapFarmMissionsService;
