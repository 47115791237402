import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { IGrove } from 'models/grove';
import { EReportType, TAnyReport } from 'models/report';
import { EMeasurementSystem } from 'models/region';

import BulletinInfo from 'components/main/bulletin/BulletinInfo';

import numberUtils from 'utils/numbers';
import { IUnifiedHotspot } from 'models/hotspot';
import dateUtils from 'utils/date';
import BulletinGrove from './BulletinGrove';

const ReportInfoRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0 0 8px;
  padding: 0 12px 0 0;

  &:last-child {
    margin: 0;
  }
`;

interface IProps {
  reports: (TAnyReport | IUnifiedHotspot)[];
  reportType: EReportType;
  groves: IGrove[];
  onGroveClicked?: (grove: IGrove, reportType: EReportType) => void;
  metricType?: EMeasurementSystem;
}

const BulletinItem = ({ reports, reportType, groves, onGroveClicked, metricType = EMeasurementSystem.Metric }: IProps): JSX.Element | null => {
  const { t } = useTranslation();

  const grove = useMemo((): IGrove | null => {
    const report = reports[0];
    const groveModel = groves.find((grove) => grove.id === report.groveID);
    return groveModel || null;
  }, [groves, reports]);

  const affectedArea = useMemo(() => {
    if (reportType === EReportType.TargetedScouting) {
      const totalArea = (reports as IUnifiedHotspot[]).reduce((acc, next) => acc + (next?.area || 0), 0) / 10000;
      return numberUtils.formatArea(totalArea, 2, metricType);
    }

    if (reportType === EReportType.Replant) {
      return dateUtils.getYearDifference(grove?.attributes?.originalPlantYear || '');
    }

    return numberUtils.formatArea(numberUtils.getGrovesArea(grove ? [grove] : [], metricType), 1, metricType);
  }, [grove, reports, reportType, metricType]);

  const areaInfoRow = useMemo((): JSX.Element => {
    let textTitle: JSX.Element | string = '';
    switch (reportType) {
      case EReportType.TargetedScouting:
        textTitle = <span>{t('main.bulletin.area_affected')}</span>;
        break;
      case EReportType.Replant:
        textTitle = <span>{t('main.info.grove_age')}</span>;
        break;
      default:
        textTitle = <span>{t('main.bulletin.grove_area')}</span>;
        break;
    }

    return (
      <ReportInfoRow>
        {textTitle}
        <b>{affectedArea}</b>
      </ReportInfoRow>
    );
  }, [affectedArea, reportType, t]);

  if (!grove || !reports.length) return null;

  return (
    <>
      <BulletinGrove grove={grove} isClickable={!!onGroveClicked} onGroveClicked={() => onGroveClicked && onGroveClicked(grove, reportType)} />
      <>
        {areaInfoRow}
        <ReportInfoRow>
          <BulletinInfo report={reports[0]} reportType={reportType} metricType={metricType} />
        </ReportInfoRow>
      </>
    </>
  );
};

export default BulletinItem;
