import React, { useMemo, useState, useEffect, useCallback } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/pro-solid-svg-icons';

import SideBar from 'containers/SideBar';
import FarmSearch from 'containers/FarmSearch';
import Breadcrumbs from 'components/biDashboard/Breadcrumbs';
import SurveyTimeline from 'components/main/Timeline/SurveyTimeline';
import StaticCard from 'atomicComponents/StaticCard';
import { Divider } from 'atomicComponents';
import Button, { Text as ButtonText } from 'atomicComponents/Button';
import TreeAnalytics from 'containers/dashboard/TreeAnalytics';
import HlbContamination from 'containers/dashboard/HlbContamination';
import MarkAsCounted from 'containers/dashboard/MarkAsCounted';
import Sisense from 'containers/dashboard/Sisense';

import { ICompany } from 'models/company';
import { IFarmName } from 'models/farm';
import { EDashboardType, ICustomerDashboard, CUSTOMER_SPECIFIC_DASHBOARDS } from 'models/dashboard';
import { IMission, EMissionType, EMissionSubtype } from 'models/mission';
import { ICustomerSurveyWithTimeRange, ISurvey, ICustomerSurvey } from 'models/survey';

import farmsHooks from 'hooks/farms.hooks';
import dashboardHooks from 'hooks/dashboard.hooks';
import dashboardUtils from 'utils/dashboard';
import { useFetchFarmSurveys, useFetchFarmMissions } from 'services/data/farms';
import surveyUtils from 'utils/survey';
import customerSurvey from 'services/data/customerSurvey';

import { mfColors, mfSizes, mfShadows } from 'vars';

import { isSideBarExpandedSelector, setIsShowBIDashboard } from 'redux/appStateSlice';
import LocalLoader from 'atomicComponents/LocalLoader';

const DashboardWrapper = styled.div`
  position: fixed;
  top: 0px;
  right: 0px;
  z-index: 1;
  width: calc(100% - ${mfSizes.sideBarAreaWidth} - ${mfSizes.staticMenuWidth} + 8px);
  padding: 0 0 0 8px;
  background: ${mfColors.sideBarBackground};
  height: 100%;
  overflow: auto;
`;

const SidebarContent = styled.div`
  padding: 0 16px 0 0;
`;

const Title = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  font-size: 24px;
  font-family: MontserratBold;
  padding: 0 16px 8px 0;
`;

const SubTitle = styled.div`
  font-size: 16px;
  font-family: MontserratBold;
  color: ${mfColors.grey};
  margin: 0 0 16px;
`;

const Types = styled.div`
  ${ButtonText} {
    margin: 0;
  }
`;

const CloseIcon = styled(FontAwesomeIcon)`
  font-size: 24px;
  cursor: pointer;
`;

const LINE_STYLE = { margin: '16px 0px 16px -16px', opacity: 0.5 };
const SURVEY_LINE_STYLE = { ...LINE_STYLE, margin: '12px 4px 16px 4px' };
const BOARDS_LINE_STYLE = { ...LINE_STYLE, margin: '16px 4px' };
const DASHBOARD_TYPE_STYLE_ACTIVE = { padding: '0 12px', fontFamily: 'MontserratBold', margin: '0 0 16px' };
const DASHBOARD_TYPE_STYLE = { ...DASHBOARD_TYPE_STYLE_ACTIVE, background: mfColors.grey, opacity: '0.8' };

const SeetreeDashboard = (): JSX.Element => {
  const { t } = useTranslation();
  const location = useLocation();
  const params = useParams<Record<string, string>>();
  const [dashboardType, setDashboardType] = useState<EDashboardType>(EDashboardType.TreeAnalytics);
  const { farms, selectedSurveyStats } = farmsHooks.useFarmEntities();
  const { selectedFarm: initialSelectedFarm } = farmsHooks.useSelectedFarmEntities();
  const [selectedFarm, setSelectedFarm] = useState<IFarmName | null>(initialSelectedFarm);
  const [selectedCompany, setSelectedCompany] = useState<ICompany | null>(null);
  const [selectedDashboard, setSelectedDashboard] = useState<ICustomerDashboard | null>(null);
  const [farmsSurveys, setfarmsSurveys] = useState<ISurvey[][]>([]);
  const [farmsHLBMissions, setFarmsHLBMissions] = useState<IMission[]>([]);
  const [farmsMarkAsCountedMissions, setFarmsMarkAsCountedMissions] = useState<IMission[]>([]);
  const [customerSurveys, setCustomerSurveys] = useState<ICustomerSurvey[]>([]);
  const [pickerSurveys, setPickerSurveys] = useState<ICustomerSurveyWithTimeRange[]>([]);
  const [selectedSurvey, setSelectedSurvey] = useState<ICustomerSurveyWithTimeRange | null>(null);
  dashboardHooks.useGetCompanies();
  const fetchFarmSurveys = useFetchFarmSurveys();
  const fetchFarmMissions = useFetchFarmMissions();
  const companies = dashboardHooks.useGetUserCompanies();
  const dispatch = useDispatch();
  const isSideBarExpanded = useSelector(isSideBarExpandedSelector);
  const customerSpecificDashboards = useMemo(() => {
    if (!selectedCompany) return [];
    return CUSTOMER_SPECIFIC_DASHBOARDS.get(selectedCompany.id) || [];
  }, [selectedCompany]);

  const basePath = useMemo(
    () => Object.values(params).reduce((path: string, param?: string) => (param ? path.replace('/status', '').replace(`/${param}`, '') : path), location.pathname),
    [location.pathname, params]
  );

  useEffect(() => {
    if (selectedDashboard) {
      setDashboardType(EDashboardType.Sisense);
    }
  }, [selectedDashboard]);

  useEffect(() => {
    if (dashboardType !== EDashboardType.Sisense) {
      setSelectedDashboard(null);
    }
  }, [dashboardType]);

  useEffect(() => {
    setDashboardType(EDashboardType.TreeAnalytics);
  }, [selectedCompany]);

  useEffect(() => {
    customerSurvey.getCustomerSurveys().then(setCustomerSurveys);
  }, []);

  useEffect(() => {
    setSelectedCompany((prev) => {
      if (!initialSelectedFarm?.id) return null;
      return companies.find((entry) => !!entry.farms[initialSelectedFarm.id]) || null;
    });
  }, [companies, initialSelectedFarm?.id]);

  useEffect(() => {
    setPickerSurveys(() => {
      if (!selectedCompany) return [];
      return customerSurveys
        .filter((survey) => survey.customerID === selectedCompany?.id)
        .filter((survey) => {
          if (!selectedFarm?.id) return true;
          const farmSurveys = farmsSurveys.find((surveys) => surveys[0].farmID === selectedFarm.id);
          return farmSurveys && !!farmSurveys.find((entry) => entry.customerSurveyID === survey.id && entry.status === 'published');
        })
        .map((survey) => surveyUtils.getSurveyWithTimeRange(survey))
        .sort((a, b) => (b.from < a.from ? 1 : -1)) as ICustomerSurveyWithTimeRange[];
    });
  }, [customerSurveys, selectedCompany, selectedFarm?.id, farmsSurveys]);

  useEffect(() => {
    if (pickerSurveys.length) {
      setSelectedSurvey((prev) => pickerSurveys.find((survey) => survey.id === prev?.id) || pickerSurveys[pickerSurveys.length - 1] || null);
    }
  }, [pickerSurveys]);

  useEffect(() => {
    Promise.all(farms.map((farm) => fetchFarmSurveys(farm.id))).then((surveyGroups) => {
      setfarmsSurveys(surveyGroups.filter((surveys) => !!surveys.length));
    });
  }, [farms, fetchFarmSurveys]);

  useEffect(() => {
    setSelectedFarm((prev) => {
      if (!prev?.id || !selectedCompany) return prev;
      if (!selectedCompany?.farms?.[prev?.id]) {
        return null;
      }
      return prev;
    });
  }, [selectedCompany]);

  useEffect(() => {
    if (dashboardType === EDashboardType.MarkAsCounted) {
      setSelectedFarm(null);
    }
  }, [dashboardType]);

  const companyFarms = useMemo(
    () => farms.filter((entry, index) => !!selectedCompany?.farms?.[entry.id]).sort((a, b) => (a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1)),
    [selectedCompany, farms]
  );

  useEffect(() => {
    Promise.all(companyFarms.map((farm) => fetchFarmMissions(farm.id, EMissionType.HLBScouting))).then((missionsGroups: IMission[][]) => {
      setFarmsHLBMissions(missionsGroups.flat());
    });
  }, [companyFarms, fetchFarmMissions]);

  useEffect(() => {
    Promise.all(companyFarms.map((farm) => fetchFarmMissions(farm.id, EMissionType.FruitCount, EMissionSubtype.MarkAsCounted))).then((missionsGroups: IMission[][]) => {
      setFarmsMarkAsCountedMissions(missionsGroups.flat());
    });
  }, [companyFarms, fetchFarmMissions]);

  const handleSelectFarm = useCallback(
    (farmID: string) => {
      setSelectedFarm((prev) => {
        if (prev?.id === farmID) return null;
        return farms.find((entry) => entry.id === farmID) || null;
      });
    },
    [farms]
  );

  const preassignedHLBMissions = useMemo(() => farmsHLBMissions.filter((mission) => mission.isPreassigned), [farmsHLBMissions]);
  const notPreassignedHLBMissions = useMemo(() => farmsHLBMissions.filter((mission) => !mission.isPreassigned), [farmsHLBMissions]);

  if (!companies.length || !selectedCompany) {
    return (
      <SideBar>
        <LocalLoader />
      </SideBar>
    );
  }

  return (
    <>
      {isSideBarExpanded && (
        <SideBar>
          <Title>
            {t('dashboard.bi_dashboard')}
            <CloseIcon onClick={() => dispatch(setIsShowBIDashboard(false))} icon={faChevronLeft} />
          </Title>
          <div />
          <Divider style={LINE_STYLE} />
          <SidebarContent>
            {selectedCompany && (
              <Breadcrumbs
                prefix={basePath}
                companies={companies}
                farms={companyFarms}
                farm={selectedFarm}
                company={selectedCompany}
                onSelectFarm={setSelectedFarm}
                onSelectCompany={setSelectedCompany}
              />
            )}
            <FarmSearch
              farm={selectedFarm}
              farms={companyFarms}
              onSelectEntry={setSelectedFarm}
              zones={[]}
              groves={[]}
              zone={null}
              grove={null}
              showSearchHistory={false}
              prefix={basePath}
            />
            {dashboardType !== EDashboardType.HLBPreassigned && dashboardType !== EDashboardType.HLBNotPreassigned && dashboardType !== EDashboardType.MarkAsCounted && (
              <>
                {selectedSurvey && (
                  <>
                    <Divider style={SURVEY_LINE_STYLE} />
                    <StaticCard
                      padding="24px"
                      width="100%"
                      shadow={mfShadows.timelineShadow}
                      background={mfColors.white}
                      content={
                        <SurveyTimeline onSelectSurvey={setSelectedSurvey} surveys={pickerSurveys} selectedSurveyStats={selectedSurveyStats} selectedSurvey={selectedSurvey.id} />
                      }
                    />
                  </>
                )}
                <Divider style={BOARDS_LINE_STYLE} />
              </>
            )}
            <SubTitle>{t('dashboard.boards')}</SubTitle>
            <Types>
              <Button
                style={dashboardType !== EDashboardType.TreeAnalytics ? DASHBOARD_TYPE_STYLE : DASHBOARD_TYPE_STYLE_ACTIVE}
                isSecondary={dashboardType !== EDashboardType.TreeAnalytics}
                onClick={() => setDashboardType(EDashboardType.TreeAnalytics)}
                text={t(dashboardUtils.getDashboardTypeKey(EDashboardType.TreeAnalytics))}
              />
              {!!preassignedHLBMissions.length && (
                <Button
                  style={dashboardType !== EDashboardType.HLBPreassigned ? DASHBOARD_TYPE_STYLE : DASHBOARD_TYPE_STYLE_ACTIVE}
                  isSecondary={dashboardType !== EDashboardType.HLBPreassigned}
                  onClick={() => setDashboardType(EDashboardType.HLBPreassigned)}
                  text={t(dashboardUtils.getDashboardTypeKey(EDashboardType.HLBPreassigned))}
                />
              )}
              {!!notPreassignedHLBMissions.length && (
                <Button
                  style={dashboardType !== EDashboardType.HLBNotPreassigned ? DASHBOARD_TYPE_STYLE : DASHBOARD_TYPE_STYLE_ACTIVE}
                  isSecondary={dashboardType !== EDashboardType.HLBNotPreassigned}
                  onClick={() => setDashboardType(EDashboardType.HLBNotPreassigned)}
                  text={t(dashboardUtils.getDashboardTypeKey(EDashboardType.HLBNotPreassigned))}
                />
              )}
              {!!farmsMarkAsCountedMissions.length && (
                <Button
                  style={dashboardType !== EDashboardType.MarkAsCounted ? DASHBOARD_TYPE_STYLE : DASHBOARD_TYPE_STYLE_ACTIVE}
                  isSecondary={dashboardType !== EDashboardType.MarkAsCounted}
                  onClick={() => setDashboardType(EDashboardType.MarkAsCounted)}
                  text={t(dashboardUtils.getDashboardTypeKey(EDashboardType.MarkAsCounted))}
                />
              )}
              {customerSpecificDashboards.map((entry) => (
                <Button
                  key={entry.id}
                  style={selectedDashboard?.id !== entry.id ? DASHBOARD_TYPE_STYLE : DASHBOARD_TYPE_STYLE_ACTIVE}
                  isSecondary={selectedDashboard?.id !== entry.id}
                  onClick={() => setSelectedDashboard(entry)}
                  text={entry.name}
                />
              ))}
            </Types>
          </SidebarContent>
        </SideBar>
      )}
      <DashboardWrapper>
        {dashboardType === EDashboardType.TreeAnalytics && (
          <TreeAnalytics
            company={selectedCompany}
            farms={companyFarms}
            customerSurveys={pickerSurveys}
            farmsSurveys={farmsSurveys}
            selectedSurvey={selectedSurvey}
            selectedFarm={selectedFarm?.id || null}
            onSelectFarm={handleSelectFarm}
          />
        )}
        {dashboardType === EDashboardType.HLBPreassigned && (
          <HlbContamination
            type={EDashboardType.HLBPreassigned}
            missions={preassignedHLBMissions}
            company={selectedCompany}
            customerSurveys={farmsSurveys}
            farms={companyFarms}
            selectedFarm={selectedFarm?.id || null}
            onSelectFarm={handleSelectFarm}
          />
        )}
        {dashboardType === EDashboardType.HLBNotPreassigned && (
          <HlbContamination
            type={EDashboardType.HLBNotPreassigned}
            missions={notPreassignedHLBMissions}
            company={selectedCompany}
            customerSurveys={farmsSurveys}
            farms={companyFarms}
            selectedFarm={selectedFarm?.id || null}
            onSelectFarm={handleSelectFarm}
          />
        )}
        {dashboardType === EDashboardType.MarkAsCounted && (
          <MarkAsCounted farms={companyFarms} company={selectedCompany} missions={farmsMarkAsCountedMissions} selectedFarm={selectedFarm?.id || null} />
        )}
        {dashboardType === EDashboardType.Sisense && !!selectedDashboard && <Sisense dashboard={selectedDashboard} selectedFarm={selectedFarm} />}
      </DashboardWrapper>
    </>
  );
};

export default SeetreeDashboard;
