import { Map, AnyLayer } from 'mapbox-gl';
import {
  addLayerToMap,
  removeMapLayerById,
  removeMapSourceById,
  reorderLayers,
  LANDCOVER,
  HILLSHADE,
  CONTOUR,
  CONTOUR_LABELS,
  LANDCOVER_GEOJSON,
  CONTOUR_GEOJSON,
  LAYERS_PRIORITY,
  TERRAIN_LAYERS
} from 'services/util/mapHelpers';
import { IGrove } from 'models/grove';
import { ITerrainGeojson, CUSTOM_TERRAIN_FARMS } from 'models/terrain';

export const TERRAIN_SOURCE = 'mapbox-terrain';
export const TERRAIN_SOURCE_GEOJSON = 'mapbox-terrain-geojson';

const defaultTerrain = [
  {
    id: LANDCOVER,
    source: TERRAIN_SOURCE,
    'source-layer': 'landcover',
    type: 'fill',
    paint: {
      'fill-color': 'rgba(66,100,251, 0.3)',
      'fill-outline-color': 'rgba(66,100,251, 1)'
    }
  },
  {
    id: HILLSHADE,
    source: 'mapbox-terrain',
    'source-layer': 'hillshade',
    type: 'fill',
    paint: {
      'fill-color': 'green',
      'fill-outline-color': 'rgba(66,100,251, 1)'
    }
  },
  {
    id: CONTOUR,
    source: TERRAIN_SOURCE,
    'source-layer': 'contour',
    type: 'line',
    paint: { 'line-color': 'rgba(255,255,255, 0.5)' }
  },
  {
    id: CONTOUR_LABELS,
    source: TERRAIN_SOURCE,
    'source-layer': 'contour',
    type: 'symbol',
    layout: {
      'symbol-placement': 'line',
      'text-field': ['concat', ['to-string', ['get', 'ele']], 'm']
    },
    paint: { 'text-color': 'rgba(255,255,255, 0.5)' }
  }
];

const customTerrain = [
  {
    id: LANDCOVER_GEOJSON,
    source: TERRAIN_SOURCE_GEOJSON,
    type: 'fill',
    paint: {
      'fill-color': 'rgba(66,100,251, 0.3)',
      'fill-outline-color': 'rgba(66,100,251, 1)'
    }
  },
  {
    id: CONTOUR_GEOJSON,
    source: TERRAIN_SOURCE_GEOJSON,
    type: 'line',
    paint: { 'line-color': 'rgba(255,255,255, 0.5)' }
  }
];

export const addTerrainLayers = (map: Map, grove: IGrove | null, customGeoJSON?: ITerrainGeojson): void => {
  TERRAIN_LAYERS.forEach((layer) => {
    removeMapLayerById(map, layer);
  });
  removeMapSourceById(map, TERRAIN_SOURCE_GEOJSON);
  removeMapSourceById(map, TERRAIN_SOURCE);

  if (grove && CUSTOM_TERRAIN_FARMS.includes(grove.farmID) && customGeoJSON) {
    map.addSource(TERRAIN_SOURCE_GEOJSON, {
      data: customGeoJSON,
      type: 'geojson'
    });
    customTerrain.forEach((layer) => {
      addLayerToMap(map, layer.id, layer as AnyLayer);
    });
  } else {
    map.addSource(TERRAIN_SOURCE, {
      url: 'mapbox://mapbox.mapbox-terrain-v2',
      type: 'vector'
    });
    defaultTerrain.forEach((layer) => {
      addLayerToMap(map, layer.id, layer as AnyLayer);
    });
  }

  reorderLayers(
    map,
    LAYERS_PRIORITY.map((layer, index) => ({
      name: layer,
      zIndex: index
    }))
  );
};

export const removeTerrainLayers = (map: Map): void => {
  TERRAIN_LAYERS.forEach((layer) => {
    removeMapLayerById(map, layer);
  });
  removeMapSourceById(map, TERRAIN_SOURCE_GEOJSON);
  removeMapSourceById(map, TERRAIN_SOURCE);
};
