import { useCallback } from 'react';
import db from 'services/data/providers/firebase';
import api from 'services/data/providers/api';
import useLogger, { EStorageType } from 'hooks/logger.hooks';
import { collection, doc, getDoc } from 'firebase/firestore';
import { ERichItemType } from 'models/richItem';
import { EMeasurementSystem } from 'models/region';
import { IUnifiedGeojson } from 'hooks/farms.hooks';
import { IThresholdConfigs } from 'models/thresholds';

export const useFetchLayers = (): (() => Promise<string[]>) => {
  const { logError } = useLogger();
  return useCallback(async (): Promise<string[]> => {
    const path = '/app-config';
    const appConfigCollection = collection(db.firestore, path);
    const layersRef = doc(appConfigCollection, 'layers');

    try {
      const layers = await getDoc(layersRef).then((layerDoc) => (layerDoc.exists() ? layerDoc.data() : null));
      return layers ? Object.keys(layers).filter((layer: string) => !!layers[layer]) : [];
    } catch (e) {
      logError(e as Error, path, EStorageType.Firestore);
      return Promise.resolve([]);
    }
  }, [logError]);
};

export const useFetchWhatsNewTimestamp = (): (() => Promise<number>) => {
  const { logError } = useLogger();
  return useCallback(async (): Promise<number> => {
    const path = '/app-config';
    const appConfigCollection = collection(db.firestore, path);
    const whatNewRef = doc(appConfigCollection, 'whats-new');

    try {
      const whatsNewConfig = await getDoc(whatNewRef).then((doc) => (doc.exists() ? doc.data() : null));
      return whatsNewConfig ? whatsNewConfig.timestamp : 0;
    } catch (e) {
      logError(e as Error, path, EStorageType.Firestore);
      return Promise.resolve(0);
    }
  }, [logError]);
};

export interface IRichItemRange {
  color: string;
  value: number;
  label: string;
}

export interface IRichItemRangeConfig {
  ranges: IRichItemRange[];
  mapbox: IUnifiedGeojson['mapbox'];
}

export interface IRichItemMeasurementRanges {
  [EMeasurementSystem.Metric]: {
    [ERichItemType.Height]: IRichItemRangeConfig;
    [ERichItemType.CanopyArea]: IRichItemRangeConfig;
    [ERichItemType.Volume]: IRichItemRangeConfig;
  };
  [EMeasurementSystem.Imperial]: {
    [ERichItemType.Height]: IRichItemRangeConfig;
    [ERichItemType.CanopyArea]: IRichItemRangeConfig;
    [ERichItemType.Volume]: IRichItemRangeConfig;
  };
}

export const useFetchRichItemMeasurementRanges = (): (() => Promise<IRichItemMeasurementRanges | undefined>) => {
  const { logError } = useLogger();

  return useCallback(async (): Promise<IRichItemMeasurementRanges | undefined> => {
    try {
      const richItemMetrics = await api.get('/getRichItemMeasurementRanges', true);

      return richItemMetrics as IRichItemMeasurementRanges;
    } catch (e) {
      logError(e as Error);
      return Promise.resolve(undefined);
    }
  }, [logError]);
};
export const useFetchThresholdConfigs = (): (() => Promise<IThresholdConfigs | null>) => {
  const { logError } = useLogger();

  return useCallback(async (): Promise<IThresholdConfigs | null> => {
    const path = '/app-config';
    const appConfigCollection = collection(db.firestore, path);
    const configsRef = doc(appConfigCollection, 'bulletin-configs');

    try {
      const configs = await getDoc(configsRef).then((doc) => (doc.exists() ? doc.data() : null));
      return configs ? (configs.thresholds as IThresholdConfigs) : null;
    } catch (e) {
      logError(e as Error, path, EStorageType.Firestore);
      return Promise.resolve(null);
    }
  }, [logError]);
};
