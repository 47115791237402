export const CUSTOMER_SCORES = [0, 1, 2, 3, 4, 5, 6];
export const ERROR = [-1];
export const SCORES = [...ERROR, ...CUSTOMER_SCORES];
export const NOT_PRODUCING = [1, 0];
export const NOT_BEARING = [6];
export const UNDER_PERFORMING = [3, 2];
export const HEALTHY = [5, 4];
export const WEAK_SCORES = [...NOT_PRODUCING, ...UNDER_PERFORMING];
export const TREES_FACTS_SCORES = [1, 2, 3, 4, 5, 6];
export const NON_REPLANT_TREES = [2, 3, 4, 5, 6];
