import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { mfColors } from 'vars';

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  z-index: 1;
`;

const Popup = styled.div`
  border-radius: 8px;
  overflow: hidden;
  background: ${mfColors.missingDataBackground};
  color: #fff;
  font-size: 17px;
  padding: 6px 12px;
  border-radius: 17px;
  margin: 10px auto;
`;

const Type = styled.span`
  color: ${mfColors.lightBlue};
`;

interface IProps {
  richItemType: string | null;
}

const MissingDataMessagePopup = ({ richItemType }: IProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <Popup>
        {`${t('main.map.no_report_data')}`}
        <Type>{` ${t(`${richItemType}`)}`}</Type>
      </Popup>
    </Wrapper>
  );
};

export default MissingDataMessagePopup;
