import React, { useMemo } from 'react';

import numberUtils from 'utils/numbers';
import missionUtils from 'utils/mission';

import { IGrove } from 'models/grove';
import { IMission, EMissionStatus } from 'models/mission';
import { EMeasurementSystem } from 'models/region';
import { ICreateFeedIncompleteParams, EFeedStatus } from 'models/feed';
import { IGroveSurveyStats } from 'models/stats';
import MissionStats from 'components/missions/MissionStats';

interface IProps {
  surveyStats: IGroveSurveyStats[];
  groves: IGrove[];
  feed: ICreateFeedIncompleteParams[];
  mission?: Pick<IMission, 'type' | 'groves' | 'timestamp' | 'createdBy' | 'isPreassigned'>;
  metricType?: EMeasurementSystem;
  isCreated?: boolean;
  showBackground?: boolean;
}

const TreeLevelMissionStats = ({ surveyStats, groves, feed, mission, metricType = EMeasurementSystem.Metric, isCreated = true, showBackground = false }: IProps): JSX.Element => {
  const resolvedGroves = useMemo(() => {
    if (!mission?.groves) return [];
    return groves.filter((grove) => mission.groves[grove.id] === EMissionStatus.Done);
  }, [groves, mission?.groves]);
  const affectedGroves = useMemo(() => (mission?.groves ? groves.filter((grove) => mission.groves[grove.id]) : groves), [groves, mission?.groves]);

  const resolvedTreesCount = useMemo(() => feed.filter((feedEntry) => feedEntry.status === EFeedStatus.Done).length, [feed]);

  const affectedTreesCount = useMemo(() => {
    if (!mission) return 0;
    return (!isCreated && feed.length) || mission.isPreassigned ? feed.length : missionUtils.getTreesSum(affectedGroves, surveyStats);
  }, [feed, affectedGroves, surveyStats, mission, isCreated]);

  const resolvedArea = useMemo(() => numberUtils.formatArea(numberUtils.getGrovesArea(resolvedGroves, metricType), 1, metricType), [resolvedGroves, metricType]);

  const affectedArea = useMemo(() => numberUtils.formatArea(numberUtils.getGrovesArea(affectedGroves, metricType), 1, metricType), [metricType, affectedGroves]);

  return (
    <MissionStats
      groves={groves}
      mission={mission}
      isCreated={isCreated}
      showBackground={showBackground}
      resolvedArea={resolvedArea}
      affectedArea={affectedArea}
      resolvedTreesCount={resolvedTreesCount}
      affectedTreesCount={affectedTreesCount}
    />
  );
};

export default TreeLevelMissionStats;
