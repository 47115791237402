import { useMemo } from 'react';

import { IGrove } from 'models/grove';

const useGetGroveTiles = (vectiles, selectedGrove: IGrove | null, selectedSurvey): string | null => {
  const groveTiles = useMemo((): string | null => {
    if (selectedGrove && vectiles && selectedSurvey?.id) {
      const tile = vectiles[selectedSurvey.id]?.[selectedGrove.id]?.['current-tiles'] || null;
      if (tile) {
        return `https://storage.googleapis.com/${tile.tiles}`;
      }
    }
    return null;
  }, [vectiles, selectedGrove, selectedSurvey]);

  return groveTiles;
};

const TilesHooks = {
  useGetGroveTiles
};

export default TilesHooks;
