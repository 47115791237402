import { useCallback } from 'react';
import db from 'services/data/providers/firebase';
import api from 'services/data/providers/api';
import { ref, child, get, query as databaseQuery, orderByChild, equalTo } from 'firebase/database';
import {
  addDoc,
  updateDoc,
  deleteDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  onSnapshot,
  Unsubscribe,
  query,
  where,
  orderBy,
  QueryConstraint,
  setDoc,
  writeBatch
} from 'firebase/firestore';
import { getDownloadURL, ref as storageRef } from 'firebase/storage';

import useLogger, { EStorageType } from 'hooks/logger.hooks';
import { IFarm, IFarmBulletinRelevantMonths } from 'models/farm';
import { IZone } from 'models/zone';
import { IGrove } from 'models/grove';
import { ISurvey } from 'models/survey';
import { IGroveSurveyStats } from 'models/stats';
import { IHotspots } from 'models/hotspot';
import { ITile } from 'models/tile';
import { ITreeData } from 'models/tree';
import { IFeed, ICreateFeedParams, EFeedItemType } from 'models/feed';
import { IRasters } from 'models/rasters';
import { ITerrainGeojson } from 'models/terrain';
import { TIrrigationSourceType } from 'models/irrigation';
import { EReportType, TAnyReport, IScoreDifferenceReport, DEFAULT_REPORT_TRESHOLDS } from 'models/report';
import {
  TMissionType,
  EMissionSubtype,
  ICreateMissionParams,
  IMission,
  EBudgetType,
  TMissionConfigEntry,
  IWeeklyTrapMonitoringConfig,
  IDefaultWeeklyTrapColorsConfig
} from 'models/mission';
import { IPoi } from 'models/poi';
import { collectionToArray, splitEvery } from 'utils/helpers';
import { IFarmBulletinThreshold } from 'models/company';
import { IRichItemGeojson } from 'models/richItem';
import { EMeasurementSystem } from 'models/region';
import { IMissionsConfig } from 'models/missions-config';

const missionsDB = db.firestoreDev ? db.firestoreDev : db.firestore;

export const useFetchAllFarms = (): (() => Promise<IFarm[]>) => {
  const { logError } = useLogger();

  return useCallback(async (): Promise<IFarm[]> => {
    const farmsCollection = collection(db.firestore, '/farms');

    try {
      const res = await getDocs(farmsCollection).then((farmsDocs) => {
        const farms: IFarm[] = [];
        farmsDocs.forEach((doc) => {
          farms.push({
            id: doc.id,
            ...doc.data()
          } as IFarm);
        });

        return farms;
      });
      return res;
    } catch (e) {
      logError(e as Error, '/farms', EStorageType.Firestore);
      return Promise.resolve([]);
    }
  }, [logError]);
};

export const useFetchFarm = (): ((farmID: string) => Promise<IFarm | null>) => {
  const { logError } = useLogger();

  return useCallback(
    async (farmID: string): Promise<IFarm | null> => {
      const farmRef = doc(collection(db.firestore, '/farms'), farmID);

      try {
        const doc = await getDoc(farmRef);
        const farm = doc.exists() ? ({ ...doc.data(), id: doc.id } as IFarm) : null;

        return farm;
      } catch (e) {
        logError(e as Error, '/farms', EStorageType.Firestore);
        return Promise.resolve(null);
      }
    },
    [logError]
  );
};

export const useFetchFarmZones = (): ((farmID: string) => Promise<IZone[]>) => {
  const { logError } = useLogger();

  return useCallback(
    async (farmID: string): Promise<IZone[]> => {
      const path = `/farms/${farmID}/zones`;
      const zonesCollection = collection(db.firestore, path);

      try {
        const res = await getDocs(zonesCollection).then((zonesDocs) => {
          const zones: IZone[] = [];
          zonesDocs.forEach((doc) => {
            const zoneData = doc.data();
            zones.push({
              id: doc.id,
              ...zoneData,
              geometry: JSON.parse(zoneData.geometry)
            } as IZone);
          });
          return zones;
        });
        return res;
      } catch (e) {
        logError(e as Error, path, EStorageType.Firestore);
        return Promise.resolve([]);
      }
    },
    [logError]
  );
};

export const useFetchFarmGroves = (): ((farmID: string) => Promise<IGrove[]>) => {
  const { logError } = useLogger();

  return useCallback(
    async (farmID: string): Promise<IGrove[]> => {
      const path = `/farms/${farmID}/groves`;
      const grovesCollection = collection(db.firestore, path);

      try {
        const res = await getDocs(grovesCollection).then((grovesDocs) => {
          const groves: IGrove[] = [];
          grovesDocs.forEach((doc) => {
            const groveData = doc.data();
            groves.push({
              id: doc.id,
              ...groveData,
              geometry: JSON.parse(groveData.geometry)
            } as IGrove);
          });
          return groves;
        });
        return res;
      } catch (e) {
        logError(e as Error, path, EStorageType.Firebase);
        return Promise.resolve([]);
      }
    },
    [logError]
  );
};

export const useFetchFarmSurveys = (): ((farmID: string) => Promise<ISurvey[]>) => {
  const { logError } = useLogger();

  return useCallback(
    async (farmID: string): Promise<ISurvey[]> => {
      const path = `/farms/${farmID}/surveys`;
      const surveysCollection = collection(db.firestore, path);

      try {
        const res = await getDocs(surveysCollection).then((surveysDocs) => {
          const surveys: ISurvey[] = [];
          surveysDocs.forEach((doc) => {
            const surveyData = doc.data();
            surveys.push({
              id: doc.id,
              ...surveyData
            } as ISurvey);
          });
          return surveys;
        });
        return res;
      } catch (e) {
        logError(e as Error, path, EStorageType.Firebase);
        return Promise.resolve([]);
      }
    },
    [logError]
  );
};

type TFetchScoresDifference = (
  farmID: string,
  currentSurveyID: string,
  previousSurveyID: string,
  value?: number,
  nextSurveyID?: string | null
) => Promise<IScoreDifferenceReport[]>;

export const useFetchScoresDifference = (): TFetchScoresDifference => {
  const { logError } = useLogger();

  return useCallback(
    async (farmID: string, currentSurveyID: string, previousSurveyID: string, value = 10, nextSurveyID?: string | null): Promise<IScoreDifferenceReport[]> => {
      const path = '/getBigQueryScoresDifference';
      try {
        const res = await api.post<IScoreDifferenceReport[]>(path, { farmID, currentSurveyID, compareSurvey: previousSurveyID, value, nextSurveyID });
        return res;
      } catch (e) {
        logError(e as Error, path, EStorageType.Api);
        return Promise.resolve([]);
      }
    },
    [logError]
  );
};

export type TFarmsTreesData = { [keys: string]: { score: number; count: number }[] };
type TFetchFarmsTreesData = (params: { farmID: string; surveyID: string }[]) => Promise<TFarmsTreesData>;

export const useFetchFarmsTreesData = (): TFetchFarmsTreesData => {
  const { logError } = useLogger();

  return useCallback(
    async (params: { farmID: string; surveyID: string }[]): Promise<TFarmsTreesData> => {
      const path = '/getBigQueryFarmsTreesData';
      try {
        const res = await api.post<{ data: string }>(path, { params });
        return JSON.parse(res.data);
      } catch (e) {
        logError(e as Error, path, EStorageType.Api);
        return Promise.resolve({});
      }
    },
    [logError]
  );
};

export type TFarmsCipoData = { [keys: string]: { [key: string]: number } };
type TFetchFarmsCipoData = (farmIDs: string[]) => Promise<TFarmsCipoData>;

export const useFetchFarmsCipoData = (): TFetchFarmsCipoData => {
  const { logError } = useLogger();

  return useCallback(
    async (farmIDs: string[]): Promise<TFarmsCipoData> => {
      const path = '/getBigQueryFarmsCipoData';
      try {
        const res = await api.post<{ data: string }>(path, { farmIDs });
        return JSON.parse(res.data);
      } catch (e) {
        logError(e as Error, path, EStorageType.Api);
        return Promise.resolve({});
      }
    },
    [logError]
  );
};

type TFetchFarmReports = (farmID: string, surveyID: string, report: EReportType, isFullMode: boolean, threshold: number, nextSurveyID?: string | null) => Promise<TAnyReport[]>;

export const useFetchFarmReports = (): TFetchFarmReports => {
  const { logError } = useLogger();

  return useCallback(
    async (farmID: string, surveyID: string, report: EReportType, fetchFullDataset: boolean, threshold: number, nextSurveyID?: string | null): Promise<TAnyReport[]> => {
      const path = '/getWidgetStats';
      let conditions: { [key: string]: number | string };
      switch (report) {
        case EReportType.Cipo:
          conditions = {
            condition: 'gte',
            key: 'cipo_rate',
            value: threshold || DEFAULT_REPORT_TRESHOLDS[EReportType.Cipo]
          };
          break;
        case EReportType.Weeds:
          conditions = {
            condition: 'gt',
            key: '_60_90cm_percentage + _30_60cm_percentage + _90cm_above_percentage',
            value: threshold || DEFAULT_REPORT_TRESHOLDS[EReportType.Weeds]
          };
          break;
        case EReportType.Height:
          conditions = {
            condition: 'gte',
            key: 'Height',
            value: threshold || DEFAULT_REPORT_TRESHOLDS[EReportType.Height]
          };
          break;
        case EReportType.FreeArea:
          conditions = {
            condition: 'lte',
            key: 'freeArea_avg',
            value: threshold || DEFAULT_REPORT_TRESHOLDS[EReportType.FreeArea]
          };
          break;
        default:
          conditions = {};
      }
      const requestBody = {
        report,
        farmID,
        surveyID,
        ...(!fetchFullDataset ? conditions : {}),
        ...(nextSurveyID ? { nextSurveyID } : {})
      };

      try {
        const res = await api.post<TAnyReport[]>(path, requestBody);
        return res;
      } catch (e) {
        logError(e as Error, path, EStorageType.Api);
        return Promise.resolve([]);
      }
    },
    [logError]
  );
};

type TFetchGroveTrees = (farmID: string, groveID: string, surveyID: string) => Promise<IRichItemGeojson>;

export const useFetchGroveTrees = (): TFetchGroveTrees => {
  const { logError } = useLogger();

  return useCallback(
    async (farmID: string, groveID: string, surveyID: string): Promise<IRichItemGeojson> => {
      const requestBody = {
        farmID,
        groveID,
        surveyID
      };
      const path = '/getBigQueryGroveTreesData';

      try {
        const treesData = await api.post<IRichItemGeojson>(path, requestBody);
        return treesData;
      } catch (e) {
        logError(e as Error, path, EStorageType.Api);
        return Promise.resolve({});
      }
    },
    [logError]
  );
};

type TFetchRasterTiles = (farmID: string, surveyID: string, groveID: string) => Promise<IRasters | null>;

export const useFetchRasterTiles = (): TFetchRasterTiles => {
  const { logError } = useLogger();

  return useCallback(
    async (farmID: string, surveyID: string, groveID: string): Promise<IRasters | null> => {
      const path = `/farms/${farmID}/grove-survey-rastersTiles`;
      const rastersCollection = collection(db.firestore, path);

      try {
        const rastersRef = doc(rastersCollection, `${groveID}${surveyID}`);
        return getDoc(rastersRef).then((doc) => (doc.exists() ? (doc.data() as IRasters) : null));
      } catch (e) {
        logError(e as Error, path, EStorageType.Firestore);
        return Promise.resolve(null);
      }
    },
    [logError]
  );
};

export interface IGetVectilesResult {
  [key: string]: {
    [key: string]: {
      ['current-tiles']: ITile;
    };
  };
}

type TFetchVectiles = (farmID: string) => Promise<IGetVectilesResult | null>;

export const useFetchVectiles = (): TFetchVectiles => {
  const { logError } = useLogger();

  return useCallback(
    (farmID: string): Promise<IGetVectilesResult | null> => {
      const path = '/surveys-groves-vectiles';

      const farmsRef = ref(db.database, path);

      try {
        return get(child(farmsRef, farmID)).then((snap) => snap.val());
      } catch (e) {
        logError(e as Error, path, EStorageType.Firebase);
        return Promise.resolve(null);
      }
    },
    [logError]
  );
};

type TFetchFarmHotspots = (farmID: string, surveyID: string) => Promise<IHotspots>;

export const useFetchFarmHotspots = (): TFetchFarmHotspots => {
  const { logError } = useLogger();

  return useCallback(
    async (farmID: string, surveyID: string): Promise<IHotspots> => {
      const path = '/getAllHotspots';
      const requestBody = {
        farmID,
        surveyID
      };
      try {
        return await api.post<IHotspots>(path, requestBody);
      } catch (e) {
        logError(e as Error, path, EStorageType.Api);
        return Promise.resolve({ satellites: [], anomalies: [] });
      }
    },
    [logError]
  );
};

type TCreateFarmMission = (mission: ICreateMissionParams) => Promise<string | null>;

export const useAddFarmMission = (): TCreateFarmMission => {
  const { logError } = useLogger();

  return useCallback(
    async (mission: ICreateMissionParams): Promise<string | null> => {
      const path = '/farm-tasks';

      const missionsCollection = collection(missionsDB, path);

      try {
        const docRef = await addDoc(missionsCollection, mission);
        return docRef.id;
      } catch (e) {
        logError(e as Error, path, EStorageType.Firestore);
        return Promise.resolve(null);
      }
    },
    [logError]
  );
};

type TUpdateFarmMission = (missionID: string, params: Partial<IMission>) => Promise<void>;

export const useUpdateFarmMission = (): TUpdateFarmMission => {
  const { logError } = useLogger();

  return useCallback(
    async (missionID: string, params: Partial<IMission>): Promise<void> => {
      const path = '/farm-tasks';
      const missionsCollection = collection(missionsDB, path);
      const payload = { ...params, updatedAt: +new Date() };

      try {
        await updateDoc(doc(missionsCollection, missionID), payload);
      } catch (e) {
        logError(e as Error, path, EStorageType.Firestore);
      }
    },
    [logError]
  );
};
export interface IFarmBulletinSettings {
  farmBulletinThresholds: IFarmBulletinThreshold;
  bulletinRelevantMonths: IFarmBulletinRelevantMonths;
}

type TUpdateFarmBulletinSettings = (farmID: string, params: IFarmBulletinSettings) => Promise<void>;

export const useUpdateFarmBulletinSettings = (): TUpdateFarmBulletinSettings => {
  const { logError } = useLogger();

  return useCallback(
    async (farmID: string, params: IFarmBulletinSettings): Promise<void> => {
      const path = '/farms';
      const farmsCollection = collection(db.firestore, path);

      try {
        await setDoc(doc(farmsCollection, farmID), { ...params }, { merge: true });
      } catch (e) {
        logError(e as Error, path, EStorageType.Firestore);
      }
    },
    [logError]
  );
};

type TFetchMissionFeed = (missionID: string, type?: EFeedItemType, orderField?: string) => Promise<IFeed[]>;

export const useFetchMissionReports = (): TFetchMissionFeed => {
  const { logError } = useLogger();

  return useCallback(
    async (missionID: string, type?: EFeedItemType, orderField?: string): Promise<IFeed[]> => {
      const path = '/farm-tasks-reports';
      const reportsCollection = collection(missionsDB, path);

      try {
        // eslint-disable-next-line prettier/prettier
        const conditions = [
          where('missionID', '==', missionID),
          ...(type ? [where('type', '==', type)] : []),
          ...(orderField ? [orderBy(orderField, 'desc')] : [])
        ];
        const q = query(reportsCollection, ...conditions);
        const querySnapshot = await getDocs(q);
        const result: IFeed[] = [];
        querySnapshot.forEach((doc) => {
          result.push({
            ...doc.data(),
            id: doc.id
          } as IFeed);
        });
        return result;
      } catch (e) {
        logError(e as Error, path, EStorageType.Firestore);
        return Promise.resolve([]);
      }
    },
    [logError]
  );
};

type TFetchMissionsFeed = (missionIDs: string[], type: EFeedItemType) => Promise<IFeed[]>;

export const useFetchMissionsReports = (): TFetchMissionsFeed => {
  const { logError } = useLogger();

  return useCallback(
    async (missionIDs: string[], type: EFeedItemType): Promise<IFeed[]> => {
      const path = '/farm-tasks-reports';
      const reportsCollection = collection(db.firestore, path);

      try {
        const q = query(reportsCollection, where('missionID', 'in', missionIDs), where('type', '==', type));
        const querySnapshot = await getDocs(q);
        const result: IFeed[] = [];
        querySnapshot.forEach((doc) => {
          result.push({
            ...doc.data(),
            id: doc.id
          } as IFeed);
        });
        return result;
      } catch (e) {
        logError(e as Error, path, EStorageType.Firestore);
        return Promise.resolve([]);
      }
    },
    [logError]
  );
};

export const useFetchDefaultMissionsConfigs = (): (() => Promise<IDefaultWeeklyTrapColorsConfig[]>) => {
  const { logError } = useLogger();

  return useCallback(async (): Promise<IDefaultWeeklyTrapColorsConfig[]> => {
    const path = '/farm-tasks-default-config';
    const missionsConfigsCollection = collection(db.firestore, path);

    try {
      return getDocs(missionsConfigsCollection).then((snap) => snap.docs.map((doc) => ({ id: doc.id, ...doc.data() } as IDefaultWeeklyTrapColorsConfig)));
    } catch (e) {
      logError(e as Error, path, EStorageType.Firestore);
      return [];
    }
  }, [logError]);
};

export const useSubscribeToMissionsConfigs = (): ((farmID: string, setConfigs: (configs: TMissionConfigEntry[]) => void) => Unsubscribe) => {
  const { logError } = useLogger();

  return useCallback(
    (farmID: string, setConfigs: (configs: TMissionConfigEntry[]) => void): Unsubscribe => {
      const path = `/farms/${farmID}/tasks-config`;
      const missionsConfigsCollection = collection(db.firestore, path);

      return onSnapshot(
        query(missionsConfigsCollection),
        (configDocs) => {
          const configs: TMissionConfigEntry[] = [];
          configDocs.forEach((doc) => {
            const configData = doc.data();
            configs.push({
              id: doc.id,
              ...configData
            } as TMissionConfigEntry);
          });
          setConfigs(configs);
        },
        (e) => {
          logError(e as Error, path, EStorageType.Firestore);
        }
      );
    },
    [logError]
  );
};

export const useUpdateMissionsConfigs = (): ((farmID: string, type: string, props: IWeeklyTrapMonitoringConfig) => Promise<void>) => {
  const { logError } = useLogger();

  return useCallback(
    async (farmID: string, type: string, params: IWeeklyTrapMonitoringConfig) => {
      const path = `/farms/${farmID}/tasks-config`;
      const missionsConfigsCollection = collection(db.firestore, path);

      try {
        await setDoc(doc(missionsConfigsCollection, type), params as any);
      } catch (e) {
        logError(e as Error, path, EStorageType.Firestore);
      }
    },
    [logError]
  );
};

type TFetchAllMissionsFeed = (surveyID: string, type: EFeedItemType) => Promise<IFeed[]>;

export const useFetchAllMissionsReports = (): TFetchAllMissionsFeed => {
  const { logError } = useLogger();

  return useCallback(
    async (surveyID: string, type: EFeedItemType): Promise<IFeed[]> => {
      const path = '/farm-tasks-reports';
      const reportsCollection = collection(missionsDB, path);

      try {
        const q = query(reportsCollection, where('surveyID', '==', surveyID), where('type', '==', type));
        const querySnapshot = await getDocs(q);
        const result: IFeed[] = [];
        querySnapshot.forEach((doc) => {
          result.push({
            ...doc.data(),
            id: doc.id
          } as IFeed);
        });
        return result;
      } catch (e) {
        logError(e as Error, path, EStorageType.Firestore);
        return Promise.resolve([]);
      }
    },
    [logError]
  );
};

type TFetchBigQueryGroveTreesData = (farmID: string, treeIDs: string[]) => Promise<{ [key: string]: ITreeData }>;

export const useFetchBigQueryTreesData = (): TFetchBigQueryGroveTreesData => {
  const { logError } = useLogger();

  return useCallback(
    async (farmID: string, treeIDs: string[]): Promise<{ [key: string]: ITreeData }> => {
      const treesChunks = splitEvery(300, treeIDs);
      const path = '/getBigQueryTreesData';

      try {
        const result: ITreeData[][] = await Promise.all(
          treesChunks.map(async (chunk) => {
            const treesData = await api.post<{ data: string }>(path, { farmID, treeIDs: chunk });
            return JSON.parse(treesData.data);
          })
        );
        const trees = result.flat().reduce((acc, trees) => ({ ...acc, ...trees }), {});

        return trees;
      } catch (e) {
        logError(e as Error, path, EStorageType.Api);
        return Promise.resolve({});
      }
    },
    [logError]
  );
};

export const useCreateMissionReports = (): ((params: ICreateFeedParams[]) => void) => {
  const { logError } = useLogger();

  return useCallback(
    async (params: ICreateFeedParams[]): Promise<void> => {
      const path = '/farm-tasks-reports';
      const batch = writeBatch(missionsDB);
      const reportsCollection = collection(missionsDB, path);

      try {
        params.forEach((param) => {
          const newFeedRef = doc(reportsCollection);
          batch.set(newFeedRef, param);
        });
        await batch.commit();
      } catch (e) {
        logError(e as Error, path, EStorageType.Firestore);
      }
    },
    [logError]
  );
};

export const useDeleteMissionReport = (): ((id: string) => void) => {
  const { logError } = useLogger();

  return useCallback(
    async (id: string): Promise<void> => {
      const path = '/farm-tasks-reports';
      const reportsCollection = collection(missionsDB, path);

      try {
        await deleteDoc(doc(reportsCollection, id));
      } catch (e) {
        logError(e as Error, path, EStorageType.Firestore);
      }
    },
    [logError]
  );
};

export const useUpdateMissionReports = (): ((payload: { id: string; [key: string]: string | boolean }[]) => void) => {
  const { logError } = useLogger();

  return useCallback(
    async (payload: { id: string; [key: string]: string | boolean }[]): Promise<void> => {
      const path = '/farm-tasks-reports';
      const batch = writeBatch(missionsDB);
      const reportsCollection = collection(missionsDB, path);

      try {
        payload.forEach((param) => {
          const feedRef = doc(reportsCollection, param.id);
          batch.update(feedRef, param);
        });
        await batch.commit();
      } catch (e) {
        logError(e as Error, path, EStorageType.Firestore);
      }
    },
    [logError]
  );
};

export const useUpdateMissionReport = (): ((id: string, payload: { [key: string]: string | boolean }) => void) => {
  const { logError } = useLogger();

  return useCallback(
    async (id: string, payload: { [key: string]: string | boolean }): Promise<void> => {
      const path = '/farm-tasks-reports';
      const reportsCollection = collection(missionsDB, path);

      try {
        await updateDoc(doc(reportsCollection, id), payload);
      } catch (e) {
        logError(e as Error, path, EStorageType.Firestore);
      }
    },
    [logError]
  );
};

export const useSubscribeToFarmMissions = (setMissions: (missions: IMission[]) => void): ((farmID: string) => Unsubscribe) => {
  const { logError } = useLogger();

  const subscribeToFarmMissions = useCallback(
    (farmID: string) => {
      const path = '/farm-tasks';
      const missionsCollection = collection(missionsDB, path);
      return onSnapshot(
        query(missionsCollection, where('farmID', '==', farmID), where('isDeleted', '==', false)),
        (missionsDocs) => {
          const missions: IMission[] = [];
          missionsDocs.forEach((doc) => {
            const missionData = doc.data();
            missions.push({
              id: doc.id,
              ...missionData
            } as IMission);
          });
          setMissions(missions);
        },
        (e) => {
          logError(e as Error, path, EStorageType.Firestore);
        }
      );
    },
    [logError, setMissions]
  );

  return subscribeToFarmMissions;
};

export const useFetchFarmMissions = (): ((farmID: string, type: TMissionType, subType?: EMissionSubtype) => Promise<IMission[]>) => {
  const { logError } = useLogger();

  const fetchFarmMissions = useCallback(
    async (farmID: string, type: TMissionType, subType?: EMissionSubtype) => {
      const path = '/farm-tasks';
      const missionsCollection = collection(db.firestore, path);

      const conditions = [where('farmID', '==', farmID), where('type', '==', type), where('isDeleted', '==', false)];

      if (subType) {
        conditions.push(where('subType', '==', subType));
      }

      const q = query(missionsCollection, ...conditions);
      try {
        const querySnapshot = await getDocs(q);
        const result: IMission[] = [];
        querySnapshot.forEach((doc) => {
          result.push({
            ...doc.data(),
            id: doc.id
          } as IMission);
        });
        return result;
      } catch (e) {
        logError(e as Error, path, EStorageType.Firestore);
        return Promise.resolve([]);
      }
    },
    [logError]
  );

  return fetchFarmMissions;
};

type TFetchGrovesStats = (farmID: string, surveyID?: string) => Promise<IGroveSurveyStats[]>;

export const useFetchGrovesStats = (): TFetchGrovesStats => {
  const { logError } = useLogger();

  return useCallback(
    async (farmID: string, surveyID?: string): Promise<IGroveSurveyStats[]> => {
      const path = `/farms/${farmID}/grove-surveys-stats`;
      const statsCollection = collection(db.firestore, path);
      const queryConditions = [...(surveyID ? [where('surveyID', '==', surveyID)] : [])];
      const q = query(statsCollection, ...queryConditions);

      try {
        const res = await getDocs(q).then((statsDocs) => {
          const stats: IGroveSurveyStats[] = [];
          statsDocs.forEach((doc) => {
            const statsData = doc.data();
            stats.push({
              id: doc.id,
              ...statsData
            } as IGroveSurveyStats);
          });
          return stats;
        });
        return res;
      } catch (e) {
        logError(e as Error, path, EStorageType.Firestore);
        return Promise.resolve([]);
      }
    },
    [logError]
  );
};

interface IFetchFarmFeedParams {
  surveyID: string;
  farmID: string;
  groveID?: string;
  internal: boolean;
  geoObjectID?: string;
}

type TFetchFarmFeed = (params: IFetchFarmFeedParams) => Promise<IFeed[]>;

type TFeedQuery = {
  levelVisibility?: string;
  groveID?: string;
  surveyID?: string;
  internal?: boolean;
  geoObjectID?: string;
  appVisibility?: string;
};

const buildFeedQuery = (params: TFeedQuery) => {
  const query = [] as QueryConstraint[];

  if (params.levelVisibility) {
    query.push(where(params.levelVisibility, '==', true));
  }

  if (params.groveID) {
    query.push(where('groveID', '==', params.groveID));
  }

  if (params.surveyID) {
    query.push(where('surveyID', '==', params.surveyID));
  }

  if (typeof params.internal === 'boolean') {
    query.push(where('internal', '==', params.internal));
  }

  if (params.geoObjectID) {
    query.push(where('geoObjectID', 'in', params.geoObjectID));
  }

  if (params.appVisibility) {
    query.push(where('appVisibility', 'in', params.appVisibility));
  }

  query.push(orderBy('updatedAt', 'desc'));

  return query;
};

export const useFetchFarmFeed = (): TFetchFarmFeed => {
  const { logError } = useLogger();

  return useCallback(
    async (params: IFetchFarmFeedParams): Promise<IFeed[]> => {
      const path = `/farms/${params.farmID}/feed`;
      const feedCollection = collection(db.firestore, path);
      const queryConditions = buildFeedQuery(params);

      try {
        const q = query(feedCollection, ...queryConditions);
        const querySnapshot = await getDocs(q);
        const result: IFeed[] = [];
        querySnapshot.forEach((doc) => {
          result.push({
            ...doc.data(),
            id: doc.id
          } as IFeed);
        });
        return result;
      } catch (e) {
        logError(e as Error, path, EStorageType.Firestore);
        return Promise.resolve([]);
      }
    },
    [logError]
  );
};

export type TGrovesParam = { farmID: string; groveID: string; surveyID: string; total: number; area: number }[];
export type TGrovesSampledTrees = { groves: { groveID: string; farmID: string; surveyID: string; trees: string[] }[] };
type TFetchSampledTrees = (groves: TGrovesParam, budget: number, budgetType: EBudgetType, metricType: EMeasurementSystem) => Promise<TGrovesSampledTrees | null>;

export const useFetchSampledTrees = (): TFetchSampledTrees => {
  const { logError } = useLogger();

  return useCallback(
    async (groves: TGrovesParam, budget: number, budgetType: EBudgetType, metricType: EMeasurementSystem): Promise<TGrovesSampledTrees | null> => {
      const requestBody: any = {
        groves
      };
      if (budgetType === EBudgetType.PerBlock) {
        requestBody.budget = budget;
      } else if (metricType === EMeasurementSystem.Metric) {
        requestBody.budget_ha = budget;
      } else {
        requestBody.budget_ac = budget;
      }
      const path = '/getBigQuerySampledTreesData';

      try {
        const treesData = await api.post<{ data: string }>(path, requestBody);

        return JSON.parse(treesData.data);
      } catch (e) {
        logError(e as Error, path, EStorageType.Api);
        return Promise.resolve(null);
      }
    },
    [logError]
  );
};

type TFetchReportLocationTreshold = () => Promise<number>;

export const useFetchReportLocationTreshold = (): TFetchReportLocationTreshold => {
  const { logError } = useLogger();

  return useCallback(async (): Promise<number> => {
    const path = '/config/reportLocationThreshold';
    const tresholdRef = ref(db.database, path);

    try {
      const res = await get(tresholdRef).then((snap) => snap.val());
      return res;
    } catch (e) {
      logError(e as Error, path, EStorageType.Firebase);
      return Promise.resolve(0);
    }
  }, [logError]);
};

type TFetchCustomTerrain = (groveName: string) => Promise<ITerrainGeojson | null>;

export const useFetchCustomTerrain = (): TFetchCustomTerrain => {
  const { logError } = useLogger();

  return useCallback(
    async (groveName: string): Promise<ITerrainGeojson | null> => {
      const path = `gs://seetree-proto-topographic-geojsons/${groveName}.geojson`;

      try {
        const downloadUrl = await getDownloadURL(storageRef(db.storage, path));
        return api.get<ITerrainGeojson>(downloadUrl, false, {});
      } catch (e) {
        logError(e as Error, path, EStorageType.Storage);
        return Promise.resolve(null);
      }
    },
    [logError]
  );
};

type TFetchIrrigationSources = () => Promise<{ [key: string]: ITerrainGeojson } | null>;

export const useFetchIrrigationSources = (farm: IFarm | null): TFetchIrrigationSources => {
  const { logError } = useLogger();

  return useCallback(async (): Promise<{ [key: string]: ITerrainGeojson } | null> => {
    const path = 'gs://seetree-proto-irrigation-geojsons';

    try {
      if (!farm || !farm.irrigationSourceNames) return null;

      const { irrigationSourceNames } = farm;
      const types = Object.keys(irrigationSourceNames) as TIrrigationSourceType[];
      const paths = Object.values(irrigationSourceNames) as TIrrigationSourceType[];
      const geoJSONs = await Promise.all(
        paths.map(async (irrigationPath: string) => {
          const downloadUrl = await getDownloadURL(storageRef(db.storage, `${path}/${farm.id}/${irrigationPath}.geojson`));
          return api.get<ITerrainGeojson>(downloadUrl, false, {});
        })
      );
      return geoJSONs.reduce(
        (acc: { [key: string]: ITerrainGeojson }, data: ITerrainGeojson, index: number) => ({
          ...acc,
          [types[index]]: data
        }),
        {}
      );
    } catch (e) {
      logError(e as Error, path, EStorageType.Storage);
      return Promise.resolve(null);
    }
  }, [logError, farm]);
};

type TFetchPoi = (farmID: string, groveID: string) => Promise<IPoi[]>;

export const useFetchPoi = (): TFetchPoi => {
  const { logError } = useLogger();

  return useCallback(
    async (farmID: string, groveID: string): Promise<IPoi[]> => {
      const path = `/poi/${farmID}`;

      try {
        const q = databaseQuery(ref(db.database, path), orderByChild('groveID'), equalTo(groveID));
        const querySnapshot = await get(q);

        return collectionToArray(querySnapshot);
      } catch (e) {
        logError(e as Error, path, EStorageType.Firebase);
        return Promise.resolve([]);
      }
    },
    [logError]
  );
};

export const useFetchBulletin = () => {
  const { logError } = useLogger();

  return useCallback(
    async (farmID: string, surveyID: string, showAll?: boolean): Promise<{ [key: string]: TAnyReport[] }> => {
      const path = `/getFarmBulletin${showAll ? '?showAll=true' : ''}`;
      try {
        const res = await api.post<{ [key: string]: TAnyReport[] }>(path, { farmID, surveyID });
        return res;
      } catch (e) {
        logError(e as Error, path, EStorageType.Api);
        return Promise.resolve({});
      }
    },
    [logError]
  );
};

export const useFarmMissionsConfig = () => {
  const { logError } = useLogger();

  return useCallback(
    async (farmID: string): Promise<IMissionsConfig | null> => {
      const docRef = doc(collection(db.firestore, `/farms/${farmID}/configs`), 'missions');
      const docSnap = await getDoc(docRef);
      try {
        const data = docSnap.exists() ? (docSnap.data() as IMissionsConfig) : null;

        return data;
      } catch (e) {
        logError(e as Error, `/farms/${farmID}/configs`, EStorageType.Firestore);
        return Promise.resolve(null);
      }
    },
    [logError]
  );
};

export const useUpdateFarmMissionsConfig = () => {
  const { logError } = useLogger();

  return useCallback(
    async (farmID: string, missionsConfig: IMissionsConfig) => {
      const docRef = doc(collection(db.firestore, `/farms/${farmID}/configs`), 'missions');

      try {
        return setDoc(docRef, missionsConfig, { merge: true });
      } catch (e) {
        logError(e as Error, `/farms/${farmID}/configs`, EStorageType.Firestore);

        return null;
      }
    },
    [logError]
  );
};
