import React, { useMemo, useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp, faChevronDown, faEyeSlash } from '@fortawesome/pro-solid-svg-icons';
import { ScoresIcon } from 'assets/images';
import Dropdown, { EToggleType, EPosition } from 'atomicComponents/Dropdown';
import RichItemsModal from 'components/main/MapControls/RichItemsModal';

import { mfColors } from 'vars';
import { ERichItemType, ERichItemSubType, IRichItem } from 'models/richItem';
import { IGroveLayersFlags } from 'components/main/MapControls/SidebarControls';
import SlideSwitch from 'atomicComponents/SlideSwitch';
import { IGrove } from 'models/grove';
import { IFarm } from 'models/farm';
import { IZone } from 'models/zone';
import { ISurvey } from 'models/survey';
import { ELegendPosition } from 'components/shared/Map';
import { useDispatch } from 'react-redux';
import { setIsCapacityShown } from 'redux/appStateSlice';

interface ILegendWrapperProps {
  isMinimized: boolean;
  isVisible: boolean;
}

const LegendWrapper = styled.div`
  font-family: MontserratBold;
  background: ${mfColors.lightGrey};
  border-radius: ${({ isVisible, isMinimized }: ILegendWrapperProps) => (isVisible && !isMinimized ? '8px 8px 0 0' : '8px')};
  width: ${({ isMinimized }: ILegendWrapperProps) => (isMinimized ? '52px' : '200px')};
  opacity: 0.6;
  transition: width 0.25s ease-in-out;
  position: relative;
  z-index: 1;

  &:hover {
    opacity: 0.9;
  }
`;

const SubmenuWrapper = styled.div`
  position: absolute;
  left: calc(50% - 100px);
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  text-transform: capitalize;
`;

const HeaderIcons = styled.div`
  display: flex;
  flex-direction: row;

  svg {
    margin-left: 16px;
  }

  svg:first-child {
    margin-left: 0;
  }
`;

interface IDropdownWrapperProps {
  isMinimized: boolean;
}

const DropdownWrapper = styled.div`
  font-size: 16px;
  padding: 16px 8px 16px 24px;
  display: ${({ isMinimized }: IDropdownWrapperProps) => (isMinimized ? 'none' : 'block')};
  width: ${({ isMinimized }: IDropdownWrapperProps) => (isMinimized ? '52px' : '200px')};
`;

const Label = styled.div`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

const MinimizedContent = styled.div`
  padding: 10px;
  cursor: pointer;
  line-height: 0;

  svg {
    width: 32px;
    height: 32px;
  }
`;

interface IProps {
  richItems: IRichItem[];
  onRichItemSelected: (richItem: ERichItemType) => void;
  onRichItemSubtypeSelected: (subType: ERichItemSubType) => void;
  activeRichItem: IRichItem | null;
  activeRichItemSubType: ERichItemSubType | null;
  onLayersChanged?: (setter: (layers: IGroveLayersFlags) => IGroveLayersFlags) => void;
  onFarmLayersFiltered?: (setter: boolean) => void;
  farmLayersFiltered?: boolean;
  farm?: IFarm | null;
  zone?: IZone | null;
  grove?: IGrove | null;
  survey?: ISurvey | null;
  legendPosition?: ELegendPosition | null;
}

const RichItemsLegend = ({
  richItems,
  onRichItemSelected,
  onRichItemSubtypeSelected,
  activeRichItem,
  activeRichItemSubType,
  onLayersChanged,
  onFarmLayersFiltered,
  farmLayersFiltered,
  farm,
  zone,
  grove,
  survey,
  legendPosition
}: IProps): JSX.Element | null => {
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [isMinimized, setMinimized] = useState(false);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const labels = useMemo(
    () => ({
      [ERichItemType.Cipo]: t('cipo'),
      [ERichItemType.Height]: t('height'),
      [ERichItemType.Health]: t('main.heatmaps.health'),
      [ERichItemType.Volume]: t('volume'),
      [ERichItemType.Weeds]: t('main.heatmaps.weeds'),
      [ERichItemType.CanopyArea]: t('canopy_area'),
      [ERichItemType.FreeArea]: t('free_area'),
      [ERichItemType.FruitYield]: t('fruit_tree'),
      [ERichItemType.FruitAggregation]: t('main.heatmaps.fruit_aggregation'),
      [ERichItemType.Ndvi]: t('ndvi'),
      [ERichItemType.TreeGrowth]: t('tree_growth')
    }),
    [t]
  );

  const dropdownStyle = useMemo(
    () => ({
      width: isMinimized ? '52px' : '200px',
      padding: '0',
      boxShadow: 'none',
      borderRadius: '0 0 8px 8px',
      transition: 'width 0.25s ease-in-out'
    }),
    [isMinimized]
  );

  useEffect(() => {
    const layersToSwitch = ['scores', 'clusters', 'flags'];

    if (!onLayersChanged) {
      return;
    }

    layersToSwitch.forEach((layer) => {
      onLayersChanged((prev) => ({
        ...prev,
        [layer]: !isMinimized
      }));
    });
  }, [isMinimized, onLayersChanged]);

  const onChangeMinimized = useCallback(
    (val) => {
      setMinimized(val);

      if (onFarmLayersFiltered) {
        onFarmLayersFiltered(val);
      }

      dispatch(setIsCapacityShown(false));
    },
    [dispatch, onFarmLayersFiltered]
  );

  useEffect(() => {
    setMinimized(!!farmLayersFiltered);
  }, [farmLayersFiltered]);

  useEffect(() => {
    if (isMinimized) {
      setDropdownVisible(false);
    }
  }, [isMinimized]);

  useEffect(() => {
    setDropdownVisible(false);
  }, [activeRichItem]);

  useEffect(() => {
    if (activeRichItem?.richItemTypeName !== ERichItemType.Health) {
      setMinimized(false);
    }
  }, [activeRichItem, farm, zone, grove, survey]);

  const richItemSubTypeOptions = useMemo(
    () => [
      { value: ERichItemSubType.PreThinning, text: t('shared.pre_thinning') },
      { value: ERichItemSubType.PostThinning, text: t('shared.post_thinning') }
    ],
    [t]
  );

  if (!activeRichItem) return null;

  return (
    <>
      <LegendWrapper isVisible={dropdownVisible} isMinimized={isMinimized}>
        {!!richItems.length && isMinimized && (
          <MinimizedContent onClick={() => onChangeMinimized(!isMinimized)}>
            <ScoresIcon />
          </MinimizedContent>
        )}
        {!!richItems.length && (
          <Dropdown
            style={dropdownStyle}
            offset={{ y: 0 }}
            toggleType={EToggleType.Mouseover}
            onVisibilityChange={setDropdownVisible}
            isVisible={dropdownVisible}
            control={
              <DropdownWrapper isMinimized={isMinimized}>
                <Header>
                  <Label>{labels[activeRichItem.richItemTypeName] || activeRichItem.richItemTypeName}</Label>
                  <HeaderIcons>
                    <FontAwesomeIcon icon={faEyeSlash} onClick={() => onChangeMinimized(!isMinimized)} />
                    <FontAwesomeIcon icon={dropdownVisible ? faChevronUp : faChevronDown} />
                  </HeaderIcons>
                </Header>
              </DropdownWrapper>
            }
            content={<RichItemsModal onRichItemSelected={onRichItemSelected} richItems={richItems} activeRichItem={activeRichItem.richItemTypeName} />}
            position={legendPosition === ELegendPosition.TopRight ? EPosition.BottomRight : EPosition.BottomLeft}
          />
        )}
      </LegendWrapper>
      {activeRichItemSubType && (
        <SubmenuWrapper>
          <SlideSwitch<ERichItemSubType> value={activeRichItemSubType} options={richItemSubTypeOptions} onChange={onRichItemSubtypeSelected} />
        </SubmenuWrapper>
      )}
    </>
  );
};

export default RichItemsLegend;
