import React from 'react';
import { faCheck } from '@fortawesome/pro-solid-svg-icons';

import Checkbox, { ESizes } from 'atomicComponents/Checkbox';

import { IMission } from 'models/mission';

import missionHooks from 'hooks/mission.hooks';

interface IProps {
  mission: IMission;
  isDone: boolean;
  isChecked: boolean;
  onCheckedChange: (isChecked: boolean) => void;
}

const UpdateGroveControl = ({ mission, isDone, isChecked, onCheckedChange }: IProps): JSX.Element => {
  const isAllowedToEdit = missionHooks.useIsAllowedToEdit();
  const { showConfirmUpdateModal } = missionHooks.useConfirmUpdateModal(() => onCheckedChange(!isChecked), undefined, mission);

  return (
    <Checkbox isDisabled={isDone} icon={faCheck} checked={isChecked} onCheckedChange={isAllowedToEdit(mission) ? onCheckedChange : showConfirmUpdateModal} size={ESizes.small} />
  );
};

export default UpdateGroveControl;
