import React, { useRef, useEffect, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Chart } from 'chart.js';
import styled from 'styled-components';

const maxDataLengh = (data) => {
  const result = data.reduce((acc, next) => {
    const dataLength = next.data.length;
    return acc < dataLength ? dataLength : acc;
  }, 0);
  return result;
};

const Controls = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;
`;

interface IControlProps {
  isDisabled: boolean;
}

const Control = styled.span`
  margin: 0 16px;
  cursor: ${({ isDisabled }: IControlProps) => (isDisabled ? 'default' : 'pointer')};
  opacity: ${({ isDisabled }: IControlProps) => (isDisabled ? 0.6 : 1)};
`;

interface IDataset {
  data: number[];
  backgroundColor: string | string[];
  borderColor: string | string[];
}

interface IAxisTitle {
  x: string;
  y: string;
}

interface IProps {
  labels: string[];
  datasets: IDataset[];
  title?: string;
  axisTitle?: IAxisTitle;
  isVertical?: boolean;
}

const DATA_PORTION_SIZE = 10;

const BarChart = ({ labels, datasets, axisTitle, title, isVertical = true }: IProps): JSX.Element => {
  const { t } = useTranslation();
  const [dataChunk, setDataChunk] = useState(DATA_PORTION_SIZE);
  const [chart, setChart] = useState<Chart | null>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);

  const labelsChunk = useMemo(() => labels.slice(0, dataChunk), [dataChunk, labels]);

  const datasetsChunk = useMemo(() => {
    const result = datasets.map((item) => ({
      ...item,
      data: item.data.slice(0, dataChunk)
    }));
    return result;
  }, [datasets, dataChunk]);

  const data = useMemo(
    () => ({
      labels: labelsChunk,
      datasets: datasetsChunk
    }),
    [labelsChunk, datasetsChunk]
  );

  const config = useMemo(
    () => ({
      type: isVertical ? 'bar' : 'horizontalBar',
      data,
      options: {
        indexAxis: isVertical ? 'x' : 'y',
        plugins: { legend: false },
        title: title ? { display: true, text: title } : undefined,
        scales: {
          xAxes: [
            {
              scaleLabel: { display: !!axisTitle, labelString: axisTitle?.x || '', fontSize: 15 },
              ticks: { beginAtZero: true },
              gridLines: { display: false }
            }
          ],
          yAxes: [
            {
              scaleLabel: { display: !!axisTitle, labelString: axisTitle?.y || '', fontSize: 15 },
              ticks: { beginAtZero: true },
              gridLines: { display: false }
            }
          ]
        }
      }
    }),
    [axisTitle, title, data, isVertical]
  );

  useEffect(() => {
    if (canvasRef.current) {
      if (chart) {
        chart.destroy();
      }
      setChart(new Chart(canvasRef.current, config));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataChunk, config]);

  const loadMoreData = () => {
    const nextChunk = dataChunk + 10;
    const isReachedLastChunk = datasets.some((dts) => dts.data.length <= dataChunk);

    if (!isReachedLastChunk) {
      setDataChunk(nextChunk);
    }
  };

  const removeMoreData = () => {
    const nextChunk = dataChunk - DATA_PORTION_SIZE;
    const isReachedFirstChunk = nextChunk < DATA_PORTION_SIZE;

    if (!isReachedFirstChunk) {
      setDataChunk(nextChunk);
    }
  };

  const disabledAddMore = maxDataLengh(datasets) <= dataChunk;

  return (
    <div>
      <div>
        <canvas ref={canvasRef} />
      </div>
      {(dataChunk > DATA_PORTION_SIZE || !disabledAddMore) && (
        <Controls>
          <Control onClick={() => dataChunk > DATA_PORTION_SIZE && removeMoreData()} isDisabled={dataChunk <= DATA_PORTION_SIZE}>
            {t('shared.see_less')}
          </Control>
          <Control onClick={() => !disabledAddMore && loadMoreData()} color="primary" isDisabled={disabledAddMore}>
            {t('shared.see_more')}
          </Control>
        </Controls>
      )}
    </div>
  );
};

export default BarChart;
