import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { EMissionStatus } from 'models/mission';

import { mfColors } from 'vars';

interface IWrapperProps {
  background: string;
  color: string;
  isDisabled: boolean;
}

const Wrapper = styled.div`
  background: ${({ background }: IWrapperProps) => background};
  color: ${({ color }: IWrapperProps) => color};
  border-radius: 12px;
  font-size: 12px;
  min-width: 88px;
  line-height: 30px;
  padding: 0 16px;
  display: inline-block;
  text-transform: uppercase;
  opacity: ${({ isDisabled }: IWrapperProps) => (isDisabled ? 0.5 : 1)};
`;

interface IProps {
  status: EMissionStatus;
  isDisabled: boolean;
  style?: { [key: string]: string };
  onClick?: () => void;
}

const MissionStatus = ({ status, isDisabled, style, onClick }: IProps): JSX.Element | null => {
  const { t } = useTranslation();
  const text = useMemo(() => {
    switch (status) {
      case EMissionStatus.InProgress:
        return t('missions.in_progress');
      case EMissionStatus.Done:
        return t('missions.done');
      default:
        return null;
    }
  }, [status, t]);
  const colors = useMemo(() => {
    switch (status) {
      case EMissionStatus.Done:
        return { background: mfColors.lightGreen, color: mfColors.green };
      case EMissionStatus.InProgress:
      default:
        return { background: mfColors.superLightBlueBg, color: mfColors.lightBlue };
    }
  }, [status]);

  return text ? (
    <Wrapper isDisabled={isDisabled} onClick={onClick} background={colors.background} color={colors.color} style={style}>
      {text}
    </Wrapper>
  ) : null;
};

export default MissionStatus;
