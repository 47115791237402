import React, { useCallback, useEffect, useMemo } from 'react';
import farmsHooks from 'hooks/farms.hooks';
import styled from 'styled-components';
import DataTable from 'atomicComponents/DataTable';
import { useTranslation } from 'react-i18next';
import FormInput from 'atomicComponents/FormInput';
import Checkbox from 'atomicComponents/Checkbox';
import { faCheck } from '@fortawesome/pro-solid-svg-icons';
import { mfColors } from 'vars';
import { IPestsP3Config } from 'models/missions-config';
import missionConfigHooks from 'hooks/missionsConfig.hooks';
import { useThrottle } from 'hooks/util.hooks';
import { useUpdateFarmMissionsConfig } from 'services/data/farms';

const Wrapper = styled.div``;

const Title = styled.h1`
  font-size: 20px;
  margin-bottom: 40px;
  margin-top: 0;
  font-family: 'Montserrat';
`;

const HEADER_COLUMN_STYLE = {
  minHeight: '0px'
};
const HEADER_STYLE = {
  borderTop: 'none',
  background: 'none',
  fontSize: '16px',
  paddingBottom: '16px'
};
const ROW_STYLE = {
  padding: '10px 0'
};

const ThresholdSettings = () => {
  const { t } = useTranslation();
  const { selectedFarm } = farmsHooks.useSelectedFarmEntities();

  const { pestsP3Settings, setPestsP3Settings } = missionConfigHooks.useGetPestsP3Settings(selectedFarm?.id);
  const updateFarmMissionsConfig = useUpdateFarmMissionsConfig();
  const throttledValue = useThrottle(pestsP3Settings, 1000);

  useEffect(() => {
    if (selectedFarm?.id && throttledValue.length) {
      updateFarmMissionsConfig(selectedFarm.id, { pestsP3: throttledValue });
    }
  }, [updateFarmMissionsConfig, throttledValue, selectedFarm?.id]);

  const onChangeThreshold = useCallback(
    (id: string, value: string) => {
      const threshold = Number(value);
      const updateItem = (item) => {
        if (item.id === id) {
          return {
            ...item,
            threshold: Number(value)
          };
        }

        return item;
      };

      if (threshold >= 0 && threshold <= 100) {
        setPestsP3Settings((prev) => prev.map(updateItem));
      }
    },
    [setPestsP3Settings]
  );

  const tableConfig = useMemo(
    () => [
      {
        title: t('Pest'),
        render: (settings: IPestsP3Config) => (
          <div>
            <div>{t(`pests_p3.${settings.title}`)}</div>
            {settings.subtitle && <div>{t(`pests_p3.${settings.subtitle}`)}</div>}
          </div>
        ),
        sortable: false,
        width: '200px',
        style: {
          ...HEADER_COLUMN_STYLE,
          display: 'flex',
          justifyContent: 'flex-start'
        }
      },
      {
        title: t('threshold'),
        render: (settings: IPestsP3Config) => (
          <>
            <FormInput
              onChange={(val) => onChangeThreshold(settings.id, val)}
              name="spacing"
              value={`${settings.threshold}`}
              inputWidth="150px"
              inputHeight="32px"
              textPosition="center"
              autocomplete="off"
              background="transparent"
              color={mfColors.lightBlue}
            />
            <span style={{ marginLeft: 4, width: 11 }}>{settings.id !== 'diaphorina_adult_count' ? '%' : ''}</span>
          </>
        ),
        sortable: false,
        width: '400px',
        style: {
          ...HEADER_COLUMN_STYLE
        }
      },
      {
        title: `${t('shared.no_actions')}?`,
        render: (settings: IPestsP3Config) => <Checkbox checked={!!settings.nonAction} onCheckedChange={() => {}} icon={faCheck} />,
        sortable: false,
        width: '200px',
        style: {
          ...HEADER_COLUMN_STYLE
        }
      }
    ],
    [t, onChangeThreshold]
  );

  const tableProps = useMemo(() => {
    const props = {
      config: tableConfig,
      data: pestsP3Settings,
      showHeader: true,
      showFooter: false,
      rowsPerPage: 0,
      headerStyle: HEADER_STYLE,
      rowStyle: ROW_STYLE,
      tableStyle: { height: '500px' }
    };
    return props;
  }, [tableConfig, pestsP3Settings]);

  return (
    <Wrapper>
      <Title>
        {t('shared.threshold_settings')}
        <span style={{ margin: '0 8px' }}>|</span>
        {selectedFarm?.name}
      </Title>
      <DataTable<IPestsP3Config> {...tableProps} />
    </Wrapper>
  );
};

export default ThresholdSettings;
