import signInHooks from 'hooks/signIn.hooks';
import React, { useCallback, useEffect } from 'react';

const SignOut = () => {
  const signOut = signInHooks.useSignOut();
  const handleSignOut = useCallback(() => {
    signOut();
  }, [signOut]);

  useEffect(() => {
    handleSignOut();
  }, [handleSignOut]);

  return <div>{null}</div>;
};

export default SignOut;
