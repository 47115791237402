import React from 'react';
import styled from 'styled-components';

import { mfColors } from 'vars';

interface IWrapperProps {
  width: string;
}

const Wrapper = styled.div<IWrapperProps>`
  position: relative;
  width: ${({ width }: IWrapperProps) => width};
`;

interface IInputProps {
  height: string;
  hasIcon: boolean;
  background?: string;
  textPosition?: string;
  color?: string;
}

const Input = styled.input<IInputProps>`
  font-family: 'Montserrat', monospace;
  margin: auto;
  outline: none;
  background: ${({ background }: IInputProps) => background || mfColors.superLightBlue};
  border-radius: 8px;
  border: ${({ color }: IInputProps) => (color ? `2px solid ${color}` : 'none')};
  transition: all 1s;
  opacity: 0;
  z-index: 5;
  font-size: 16px;
  line-height: 20px;
  width: 100%;
  color: ${mfColors.darkGrey};

  padding: 0 8px 0 ${({ hasIcon }: IInputProps) => (hasIcon ? '38px' : '8px')};
  opacity: 1;
  cursor: text;
  height: ${({ height }: IInputProps) => height};

  &::placeholder {
    color: #a4a4a4;
    opacity: 1;
    font-size: 16px;
    line-height: 20px;
  }
  ${({ textPosition }) => (textPosition ? `text-align: ${textPosition}` : '')}
`;

interface IProps {
  name: string;
  value?: string;
  onChange?: (searchText: string) => void;
  icon?: JSX.Element;
  placeholder?: string;
  inputWidth?: string;
  inputHeight?: string;
  isDisabled?: boolean;
  background?: string;
  textPosition?: string;
  wrapperStyle?: {
    [style: string]: string | number;
  };
  min?: number;
  max?: number;
  autocomplete?: string;
  color?: string;
}

const FormInput = ({
  name,
  value,
  onChange,
  placeholder,
  icon,
  background,
  inputWidth = '258px',
  inputHeight = '48px',
  isDisabled = false,
  textPosition,
  wrapperStyle = {},
  min,
  max,
  autocomplete,
  color
}: IProps) => (
  <Wrapper width={inputWidth} style={wrapperStyle}>
    {icon}
    <Input
      height={inputHeight}
      value={value}
      hasIcon={!!icon}
      placeholder={placeholder}
      disabled={isDisabled}
      background={background}
      onChange={(e: React.FormEvent<HTMLInputElement>) => onChange && onChange(e.currentTarget.value)}
      name={name}
      textPosition={textPosition}
      min={min}
      max={max}
      autoComplete={autocomplete}
      color={color}
    />
  </Wrapper>
);

export default FormInput;
