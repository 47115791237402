import React, { useState, useEffect, useMemo, useCallback } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useModal } from 'react-modal-hook';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisV, faChevronLeft, faTrashCan, faEnvelope, faEnvelopeOpen, faCircleSmall } from '@fortawesome/pro-solid-svg-icons';
import { deleteField } from 'firebase/firestore';
import { useSelector } from 'react-redux';

import ResolveMissionGrovesModal from 'containers/ResolveMissionGrovesModal';
import MissionStatus from 'components/missions/MissionStatus';
import Modal from 'atomicComponents/Modal';
import StaticCard from 'atomicComponents/StaticCard';
import Dropdown, { EToggleType, EPosition } from 'atomicComponents/Dropdown';
import Button from 'atomicComponents/Button';
import MissionType, { Label as MissionTypeWrapper } from 'components/missions/MissionType';
import MissionModal from 'components/missions/MissionModal';
import MissionProgress from 'components/missions/MissionProgress';
import FarmSearch from 'containers/FarmSearch';
import { IMission, EMissionStatus } from 'models/mission';
import { IFarm } from 'models/farm';
import { ISurvey } from 'models/survey';
import { IGrove } from 'models/grove';
import { EEventType } from 'models/analytics';
import { useUpdateFarmMission } from 'services/data/farms';
import dateUtils from 'utils/date';
import dateFormats from 'models/date';
import farmsHooks from 'hooks/farms.hooks';
import missionHooks from 'hooks/mission.hooks';
import { missionTypeFilterSelector } from 'redux/missions/missionsSlice';
import { farmLink, missionsListLink, Pages } from 'utils/routes';
import { mfColors, mfShadows } from 'vars';

const Wrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-right: 16px;
`;

const Navigation = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin: 0 0 16px;
`;

const Back = styled(FontAwesomeIcon)`
  font-size: 24px;
  cursor: pointer;
`;

const CreateMission = styled.div`
  margin: 32px 0 0;
`;

const MissionsListPlaceholder = styled.div`
  padding: 64px 0 0;
  text-align: center;

  > div {
    margin: 0 0 24px;
  }
`;

const MissionListFilter = styled.div`
  margin: 32px 0 0;
  padding: 0 0 16px;
  border-bottom: 1px solid ${mfColors.greyBlue50};
  display: flex;
  flex-direction: row;

  > div {
    margin: 0 8px 0 0;
  }
`;

const MissionsList = styled.div`
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  margin: 16px 0 0;
  overflow: auto;
`;

const MissionsListEmpty = styled.div`
  margin: 16px 0 0;
  text-align: center;
`;

const MissionEntry = styled.div`
  margin: 0 0 0 8px;
  width: calc(100% - 16px);
  cursor: pointer;

  > div {
    margin: 0 0 8px 0;
  }

  &:last-child {
    margin: 0 0 16px 8px;
  }
`;

const MissionHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 24px 0 0;
  position: relative;

  ${MissionTypeWrapper} {
    max-width: 164px;
  }
`;

const MissionContent = styled.div`
  margin: 8px 0;
  font-family: MontserratBold;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const MissionDate = styled.div`
  color: ${mfColors.grey};
  margin-left: auto;
`;

const MissionsListPlaceholderControl = styled.span`
  color: ${mfColors.blue};
  cursor: pointer;
`;

const DropdownMenuToggle = styled.div`
  position: absolute;
  top: 50%;
  right: 0;
  cursor: pointer;
  margin-top: -12px;
  height: 24px;
  width: 18px;
  font-size: 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const DropdownMenuEntry = styled.div`
  min-width: 120px;
  padding: 6px 0;
  font-size: 12px;
  display: block;
  cursor: pointer;
`;

const FieldReportsIcon = styled.div<{ isActive: boolean }>`
  align-self: center;
  cursor: pointer;
  border: 1px solid;
  border-color: ${({ isActive }) => (isActive ? mfColors.superLightBlueBg : mfColors.cardShadowGrey)};
  border-radius: 15px;
  padding: 5px;
  background: ${({ isActive }) => (isActive ? mfColors.superLightBlueBg : mfColors.cardShadowGrey)};
  position: relative;
`;
const DotIconWrapper = styled.span`
  position: absolute;
  top: -7px;
  right: 0px;
`;

const statusFilterStyle = {
  minWidth: '140px',
  cursor: 'pointer',
  backgroundColor: mfColors.superLightBlueBg,
  color: mfColors.lightBlue
};

const inactiveStatusFilterStyle = {
  ...statusFilterStyle,
  color: mfColors.grey,
  backgroundColor: mfColors.greyBlue50
};

interface IProps {
  missions: IMission[];
  farm: IFarm;
  survey: ISurvey | null;
  status: EMissionStatus;
  onMissionHovered: (mission: IMission | null) => void;
  onMissionSelected: (mission: IMission | null) => void;
  onMissionEdit: (mission: IMission | null) => void;
  onCreateMissionClicked: () => void;
  groves: IGrove[];
  isFieldReports: boolean;
  isNewFieldReports: boolean;
  setIsFieldReports: (val: boolean) => void;
}

const MissionsPageMenu = ({
  missions,
  farm,
  survey,
  status,
  onMissionHovered,
  onMissionSelected,
  onMissionEdit,
  onCreateMissionClicked,
  groves,
  isFieldReports,
  isNewFieldReports,
  setIsFieldReports
}: IProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const missionTypeFilter = useSelector(missionTypeFilterSelector);
  const [missionToDelete, setMissionToDelete] = useState<IMission | null>(null);
  const [missionToEdit, setMissionToEdit] = useState<IMission | null>(null);
  const [missionToUpdate, setMissionToUpdate] = useState<IMission | null>(null);
  const [missionToReopen, setMissionToReopen] = useState<IMission | null>(null);
  const [missionToResolve, setMissionToResolve] = useState<IMission | null>(null);
  const { logMissionEvent } = missionHooks.useMissionLogger();
  const updateFarmMission = useUpdateFarmMission();
  const { farms } = farmsHooks.useFarmEntities();
  const isAllowedToEdit = missionHooks.useIsAllowedToEdit();
  const missionsToShow = useMemo(() => {
    const filteredMissions = missions
      .filter((mission) => {
        if (!missionTypeFilter) return true;
        return mission.type === missionTypeFilter || mission.subType === missionTypeFilter;
      })
      .filter((mission) => (status === EMissionStatus.Done ? mission.approved : !mission.approved))
      .sort((a, b) => (b.timestamp || 0) - (a.timestamp || 0));
    return filteredMissions;
  }, [missions, status, missionTypeFilter]);

  const [showDeleteMissionModal, hideDeleteMissionModal] = useModal(() => {
    const handleClose = () => setMissionToDelete(null);
    return (
      <Modal showClose={false} width="400px" onClose={handleClose}>
        <MissionModal
          onOk={async () => {
            if (missionToDelete) {
              await updateFarmMission(missionToDelete.id, {
                isDeleted: true
              });
              logMissionEvent(missionToDelete, EEventType.MissionDelete);
              hideDeleteMissionModal();
            }
          }}
          onCancel={handleClose}
          icon={<FontAwesomeIcon icon={faTrashCan} />}
          iconFirst
          title={t('missions.delete_mission')}
          content={t('missions.it_will_be_gone_forever')}
          okText={t('shared.yes')}
          cancelText={t('shared.no')}
        />
      </Modal>
    );
  }, [missionToDelete, logMissionEvent, t]);

  const [showResolveMissionModal, hideResolveMissionModal] = useModal(() => {
    const handleClose = () => setMissionToResolve(null);
    return (
      <Modal showClose={false} closeOnBackdrop width="440px" onClose={handleClose}>
        <ResolveMissionGrovesModal mission={missionToResolve as IMission} onCancel={handleClose} />
      </Modal>
    );
  }, [missionToResolve, t]);

  const [showReopenMissionModal, hideReopenMissionModal] = useModal(() => {
    const handleClose = () => setMissionToReopen(null);
    return (
      <Modal showClose={false} width="400px" onClose={handleClose}>
        <MissionModal
          onOk={async () => {
            if (missionToReopen) {
              await updateFarmMission(missionToReopen.id, {
                groves: Object.keys(missionToReopen.groves).reduce(
                  (acc: { [key: string]: EMissionStatus }, groveID: string) => ({
                    ...acc,
                    [groveID]: EMissionStatus.InProgress
                  }),
                  {}
                ),
                logs: deleteField() as any,
                approved: false
              });
              hideReopenMissionModal();
            }
          }}
          onCancel={handleClose}
          title={t('missions.sure')}
          content={t('missions.all_completed_groves_will_be_marked_as_not_completed')}
          okText={t('missions.yes_go_ahead')}
          cancelText={t('missions.no_go_back')}
        />
      </Modal>
    );
  }, [missionToReopen, t]);

  const onCancelUpdate = useCallback(() => {
    setMissionToUpdate(null);
  }, []);

  const onCancelEdit = useCallback(() => {
    setMissionToEdit(null);
  }, []);

  const onCancelDelete = useCallback(() => {
    setMissionToDelete(null);
  }, []);

  const onCancelMove = useCallback(() => {
    setMissionToReopen(null);
  }, []);

  const { showConfirmEditModal, hideConfirmEditModal } = missionHooks.useConfirmEditModal(onMissionEdit, onCancelEdit, missionToEdit);
  const { showConfirmDeleteModal, hideConfirmDeleteModal } = missionHooks.useConfirmDeleteModal(showDeleteMissionModal, onCancelDelete);
  const { showConfirmUpdateModal, hideConfirmUpdateModal } = missionHooks.useConfirmUpdateModal(onMissionSelected, onCancelUpdate, missionToUpdate);
  const { showConfirmUpdateModal: showConfirmMoveModal, hideConfirmUpdateModal: hideConfirmMoveModal } = missionHooks.useConfirmUpdateModal(
    showReopenMissionModal,
    onCancelMove,
    missionToReopen
  );

  const navigateToStatus = useCallback(
    (status: EMissionStatus) => {
      navigate(missionsListLink(farm.id, status));
      setIsFieldReports(false);
    },
    [navigate, farm, setIsFieldReports]
  );

  const fieldReportsIcon = useMemo(() => {
    if (isFieldReports && isNewFieldReports) return faEnvelopeOpen;

    if (isFieldReports) return faEnvelope;

    if (isNewFieldReports) return faEnvelopeOpen;

    return faEnvelope;
  }, [isFieldReports, isNewFieldReports]);

  const goToFarm = useCallback(() => {
    navigate(farmLink(Pages.Map, farm.id));
  }, [navigate, farm]);

  useEffect(() => {
    if (missionToDelete) {
      if (isAllowedToEdit(missionToDelete)) {
        showDeleteMissionModal();
      } else {
        showConfirmDeleteModal();
      }
    } else {
      hideConfirmDeleteModal();
      hideDeleteMissionModal();
    }
  }, [missionToDelete, hideConfirmDeleteModal, showConfirmDeleteModal, isAllowedToEdit, showDeleteMissionModal, hideDeleteMissionModal]);

  useEffect(() => {
    if (missionToResolve) {
      showResolveMissionModal();
    } else {
      hideResolveMissionModal();
    }
  }, [missionToResolve, showResolveMissionModal, hideResolveMissionModal]);

  useEffect(() => {
    if (!missionToEdit) {
      hideConfirmEditModal();
    } else {
      showConfirmEditModal();
    }
  }, [missionToEdit, hideConfirmEditModal, showConfirmEditModal]);

  useEffect(() => {
    if (!missionToUpdate) {
      hideConfirmUpdateModal();
    } else if (isAllowedToEdit(missionToUpdate)) {
      hideConfirmUpdateModal();
      onMissionSelected(missionToUpdate);
    } else {
      showConfirmUpdateModal();
    }
  }, [missionToUpdate, onMissionSelected, isAllowedToEdit, hideConfirmUpdateModal, showConfirmUpdateModal]);

  useEffect(() => {
    if (!missionToReopen) {
      hideConfirmMoveModal();
      hideReopenMissionModal();
    } else if (isAllowedToEdit(missionToReopen)) {
      showReopenMissionModal();
    } else {
      showConfirmMoveModal();
    }
  }, [missionToReopen, isAllowedToEdit, hideConfirmMoveModal, showConfirmMoveModal, showReopenMissionModal, hideReopenMissionModal]);

  return (
    <Wrapper>
      <Navigation>
        <Back onClick={goToFarm} icon={faChevronLeft} />
      </Navigation>
      <FarmSearch farm={farm} farms={farms} zones={[]} groves={[]} zone={null} grove={null} prefix="/missions" />
      <CreateMission>
        <Button onClick={onCreateMissionClicked} text={t('main.bulletin.create_mission')} />
      </CreateMission>
      <MissionListFilter>
        <MissionStatus
          isDisabled={!missions.length}
          onClick={() => navigateToStatus(EMissionStatus.InProgress)}
          status={EMissionStatus.InProgress}
          style={status !== EMissionStatus.InProgress || isFieldReports ? inactiveStatusFilterStyle : statusFilterStyle}
        />
        <MissionStatus
          isDisabled={!missions.length}
          onClick={() => navigateToStatus(EMissionStatus.Done)}
          status={EMissionStatus.Done}
          style={status !== EMissionStatus.Done || isFieldReports ? inactiveStatusFilterStyle : statusFilterStyle}
        />
        <FieldReportsIcon onClick={() => setIsFieldReports(true)} isActive={isFieldReports}>
          <FontAwesomeIcon icon={fieldReportsIcon} color={isFieldReports ? mfColors.blue : mfColors.darkGrey} />
          {isNewFieldReports && (
            <DotIconWrapper>
              <FontAwesomeIcon icon={faCircleSmall} color={mfColors.red} style={{ width: 12, height: 12 }} />
            </DotIconWrapper>
          )}
        </FieldReportsIcon>
      </MissionListFilter>
      {!missions.length ? (
        <>
          {!isFieldReports && (
            <MissionsListPlaceholder>
              <div>{t('missions.no_missions_created_yet')}</div>
              <div>
                <MissionsListPlaceholderControl onClick={onCreateMissionClicked}>{t('missions.create_a_mission')}</MissionsListPlaceholderControl>
                {t('missions.to_see_a_list_of_assignable_groves')}
              </div>
            </MissionsListPlaceholder>
          )}
        </>
      ) : (
        <>
          {!missionsToShow.length && !isFieldReports && <MissionsListEmpty>{t('missions.there_are_no_missions_matching_the_active_filter')}</MissionsListEmpty>}
          {!!missionsToShow.length && !isFieldReports && (
            <MissionsList>
              {missionsToShow.map((mission) => (
                <MissionEntry
                  key={mission.id}
                  onClick={() => setMissionToUpdate(mission)}
                  onMouseEnter={() => onMissionHovered(mission)}
                  onMouseLeave={() => onMissionHovered(null)}
                >
                  <StaticCard
                    shadow={mfShadows.missionBlockShadow}
                    padding="16px"
                    width="100%"
                    background={mfColors.white}
                    content={
                      <>
                        <MissionHeader>
                          <MissionType type={mission.subType || mission.type} />
                          {!!mission.timestamp && <MissionDate>{dateUtils.formatDate(mission.timestamp, dateFormats.DATE_FORMAT_DD_MM_YYYY)}</MissionDate>}
                          <Dropdown
                            toggleType={EToggleType.Click}
                            control={
                              <DropdownMenuToggle>
                                <FontAwesomeIcon icon={faEllipsisV} />
                              </DropdownMenuToggle>
                            }
                            position={EPosition.BottomLeft}
                            content={
                              <>
                                {!mission.approved && <DropdownMenuEntry onClick={() => setMissionToUpdate(mission)}>{t('shared.update')}</DropdownMenuEntry>}
                                {mission.approved && <DropdownMenuEntry onClick={() => setMissionToReopen(mission)}>{t('missions.move_back_to_in_progress')}</DropdownMenuEntry>}
                                <DropdownMenuEntry onClick={() => (!isAllowedToEdit(mission) ? setMissionToEdit(mission) : onMissionEdit(mission))}>
                                  {t('shared.edit')}
                                </DropdownMenuEntry>
                                <DropdownMenuEntry onClick={() => setMissionToDelete(mission)}>{t('shared.delete')}</DropdownMenuEntry>
                                {!mission.approved && (
                                  <DropdownMenuEntry onClick={() => setMissionToResolve(mission)}>{t('missions.move_entire_mission_to_done')}</DropdownMenuEntry>
                                )}
                              </>
                            }
                          />
                        </MissionHeader>
                        <MissionContent>{mission.title}</MissionContent>
                        <MissionProgress mission={mission} groves={groves} />
                      </>
                    }
                  />
                </MissionEntry>
              ))}
            </MissionsList>
          )}
        </>
      )}
    </Wrapper>
  );
};

export default MissionsPageMenu;
