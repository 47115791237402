import React, { useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { useAnalytics } from 'hooks/analytics.hooks';
import signInHooks from 'hooks/signIn.hooks';
import farmsHooks from 'hooks/farms.hooks';

import { EEventType } from 'models/analytics';

import { mfColors, mfShadows } from 'vars';

const Wrapper = styled.div`
  padding: 1px 28px 0;
  border-radius: 24px;
  background: ${mfColors.white};
  color: ${mfColors.blue};
  line-height: 48px;
  cursor: pointer;
  box-shadow: ${mfShadows.missionBlockShadow};
  font-family: InterBold;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &:hover {
    background: ${mfColors.superLightBlue};
  }
`;

const NewDisplayInvitation = (): JSX.Element => {
  const { t } = useTranslation();
  const location = useLocation();
  const { logEvent } = useAnalytics();
  const user = signInHooks.useSignedInUser();
  const { selectedFarm } = farmsHooks.useSelectedFarmEntities();

  const handleClick = useCallback(() => {
    if (!user) return;
    const url = `https://myfarm.seetree.ai/${location.pathname}${location.search}`;
    logEvent({
      type: EEventType.GoToNewDisplay,
      params: {
        email: user.email,
        userName: user.displayName,
        farmName: selectedFarm?.name || '',
        url
      }
    });
    window.location.href = url;
  }, [location, user, selectedFarm, logEvent]);

  return <Wrapper onClick={handleClick}>{t('shared.come_check_out_the_new_display')}</Wrapper>;
};

export default NewDisplayInvitation;
