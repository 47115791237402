import React, { Dispatch, SetStateAction, useCallback, useMemo } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { mfColors } from 'vars';
import dateUtils from 'utils/date';

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 16px;
  background: ${mfColors.white};
  box-shadow: 3px 3px 7px #bdc8d2;
  border-radius: 8px;
  padding: 16px;
  width: 300px;
`;

interface IMonthsProps {
  isSelected: boolean;
}

const MonthBlock = styled.div`
  padding: 4px 8px;
  background: ${mfColors.lightGrey};
  border: ${({ isSelected }: IMonthsProps) => (isSelected ? `1px solid ${mfColors.darkGrey}` : '1px solid #fbfbfb')};
  text-align: center;
  border-radius: 4px;
  font-family: MontserratBold;
  cursor: pointer;
`;

interface IProps {
  setSelectedMonths: Dispatch<SetStateAction<number[]>>;
  selectedMonths: number[];
}

const MonthsList = ({ setSelectedMonths, selectedMonths }: IProps): JSX.Element => {
  const { i18n } = useTranslation();
  const months = useMemo(() => dateUtils.getMonthList(i18n.language), [i18n]);
  const isSelected = (index) => selectedMonths.includes(index);

  const onClick = useCallback(
    (monthIndex: number) => {
      if (!selectedMonths.includes(monthIndex)) {
        return setSelectedMonths([...selectedMonths, monthIndex]);
      }

      const tmpMonths = [...selectedMonths];
      const index = tmpMonths.indexOf(monthIndex);
      tmpMonths.splice(index, 1);

      return setSelectedMonths([...tmpMonths]);
    },
    [setSelectedMonths, selectedMonths]
  );

  return (
    <Wrapper>
      {months.map((month, index) => (
        <MonthBlock key={index} onClick={() => onClick(index)} isSelected={isSelected(index)}>
          {month}
        </MonthBlock>
      ))}
    </Wrapper>
  );
};

export default MonthsList;
