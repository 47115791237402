import React, { useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import TreeCarousel from 'components/TreeTimeline/TreeCarousel';
import dateUtils from 'utils/date';
import dateFormats from 'models/date';
import treeHooks from 'hooks/trees.hooks';

import { ISurveyWithTimeRange } from 'models/survey';
import { ITreeMapboxFeature } from 'models/tree';

const Wrapper = styled.div`
  font-size: 14px;
`;

const Header = styled.h3`
  padding: 14px 14px;
  display: flex;
  justify-content: space-between;
`;

const Title = styled.div``;

const TreeIndex = styled.div``;

interface IProps {
  farmID: string;
  surveys: ISurveyWithTimeRange[];
  selectedSurvey: string;
  onSelectSurvey: (survey: ISurveyWithTimeRange) => void;
  tree: ITreeMapboxFeature;
}

const TreeTimeline = ({ surveys, onSelectSurvey, selectedSurvey, farmID, tree }: IProps) => {
  const { t } = useTranslation();
  const { geoindexScores } = treeHooks.useGetGeoindexScores(farmID, surveys, tree.properties.id);
  const { subGrove, row, treeIndex } = tree.properties;
  const treeName = `${subGrove}/${row}/${treeIndex}`;

  const treesScores = useMemo(() => {
    if (!surveys.length && !Object.keys(geoindexScores).length) return [];
    return surveys
      .filter((survey) => typeof geoindexScores?.[survey.id] === 'number')
      .map((survey) => ({
        score: geoindexScores[survey.id],
        surveyID: survey.id,
        date: dateUtils.formatDate(survey.date || survey.from, dateFormats.DATE_FORMAT_MMM_YY)
      }))
      .reverse();
  }, [surveys, geoindexScores]);

  const handleSelectSurvey = useCallback(
    (value: string | null) => {
      const selectedSurvey = surveys.find((survey) => survey.id === value);

      if (selectedSurvey) {
        onSelectSurvey(selectedSurvey);
      }
    },
    [onSelectSurvey, surveys]
  );

  return (
    <Wrapper>
      <Header>
        <Title>{t('main.feed.tree_timeline.score_history')}</Title>
        <TreeIndex>
          <span>{t('main.feed.tree_timeline.tree')}</span>
          <span> </span>
          <span>{treeName}</span>
        </TreeIndex>
      </Header>
      <TreeCarousel trees={treesScores} selected={selectedSurvey} handleChange={handleSelectSurvey} />
    </Wrapper>
  );
};

export default TreeTimeline;
