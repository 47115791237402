import React from 'react';
import { convertLength, convertArea, Units, Position, lineString } from '@turf/turf';
import { EMeasurementSystem } from 'models/region';
import numberUtils from 'utils/numbers';
import { ILineString, IMeasurePoint } from 'redux/distanceMeasurementSlice';

export const measurementSystemToUnitMap = {
  [EMeasurementSystem.Metric]: 'metres',
  [EMeasurementSystem.Imperial]: 'feet'
};

const getDistanceLength = (distance: number, metricType: EMeasurementSystem) => {
  const unit = (measurementSystemToUnitMap[metricType] || 'metres') as Units;

  return convertLength(distance, 'kilometers', unit);
};

const formatDistance = (distance: number, metricType: EMeasurementSystem, t) => {
  const unit = (measurementSystemToUnitMap[metricType] || 'metres') as Units;
  const length = getDistanceLength(distance, metricType).toLocaleString();

  return `${length} ${t(`shared.measurement.${unit}`)}`;
};

const formatArea = (distance: number, metricType: EMeasurementSystem) => {
  const area1 = metricType !== EMeasurementSystem.Metric ? convertArea(distance, 'meters', 'feet') : distance;

  return (
    <span>
      {numberUtils.normalizeDecimal(area1, 1)}
      {` ${measurementSystemToUnitMap[metricType] || 'metres'}`}
      <sup>2</sup>
    </span>
  );
};

const pointsToLine = (features: (IMeasurePoint | ILineString)[], properties?: { [key: string]: number | string }) => {
  const coordinates = features.filter((feature) => feature.geometry.type === 'Point').map((measurePoint) => measurePoint.geometry.coordinates) as Position[];
  return lineString(coordinates, properties) as ILineString;
};

const rulerUtils = {
  formatDistance,
  formatArea,
  pointsToLine,
  getDistanceLength
};

export default rulerUtils;
