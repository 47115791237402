import React, { useMemo } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import dateUtils from 'utils/date';
import dateFormats from 'models/date';

import { IGrove } from 'models/grove';
import { IMission, EMissionStatus } from 'models/mission';

import { mfColors } from 'vars';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const InfoBlock = styled.div`
  min-height: 68px;
  width: 204px;
  border-radius: 8px;
  padding: 0px 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 16px 0 0;
  font-size: 16px;

  .title {
    font-family: MontserratBold;
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .description {
    margin: 0 0 8px;
    color: ${mfColors.grey};
  }
`;

interface IInfoBlockWrapperProps {
  hasBackground: boolean;
}

const InfoBlockWrapper = styled.div<IInfoBlockWrapperProps>`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  ${InfoBlock} {
    background: ${({ hasBackground }: IInfoBlockWrapperProps) => (hasBackground ? mfColors.white : 'transparent')};
  } ;
`;

const ResolvedValue = styled.span`
  background: ${mfColors.white};
  border-radius: 3px;
  color: ${mfColors.grey};
  padding: 0 4px;
`;

interface IProps {
  groves: IGrove[];
  mission?: Pick<IMission, 'groves' | 'timestamp' | 'createdBy'>;
  resolvedTreesCount: number;
  affectedTreesCount: number;
  resolvedArea: string | number;
  affectedArea: string | number;
  isCreated?: boolean;
  showBackground?: boolean;
  resolvedTitle?: string;
}

const MissionStats = ({
  groves,
  mission,
  resolvedTreesCount,
  affectedTreesCount,
  resolvedArea,
  affectedArea,
  isCreated = true,
  showBackground = false,
  resolvedTitle = 'shared.trees'
}: IProps): JSX.Element => {
  const { t } = useTranslation();

  const resolvedGrovesCount = useMemo(() => {
    if (!mission?.groves) return [];
    return groves.filter((grove) => mission.groves[grove.id] === EMissionStatus.Done).length;
  }, [groves, mission?.groves]);

  const affectedGrovesCount = useMemo(() => (mission?.groves ? Object.keys(mission.groves).length : groves.length), [mission?.groves, groves]);

  return (
    <Wrapper>
      <InfoBlockWrapper hasBackground={showBackground}>
        <InfoBlock>
          <div className="description">{t('missions.groves')}</div>
          {/* prettier-ignore */}
          <div className="title">
            {!!resolvedGrovesCount && (
              <>
                <ResolvedValue>{resolvedGrovesCount}</ResolvedValue>
                {' / '}
              </>
            )}
            {affectedGrovesCount}
          </div>
        </InfoBlock>
        <InfoBlock>
          <div className="description">{t(resolvedTitle)}</div>
          {/* prettier-ignore */}
          <div className="title">
            {!!resolvedTreesCount && (
              <>
                <ResolvedValue>{resolvedTreesCount.toLocaleString()}</ResolvedValue>
                {' / '}
              </>
            )}
            {affectedTreesCount.toLocaleString()}
          </div>
        </InfoBlock>
        <InfoBlock>
          <div className="description">{t('missions.total_area')}</div>
          {/* prettier-ignore */}
          <div className="title">
            {!!resolvedArea && (
              <>
                <ResolvedValue>{resolvedArea}</ResolvedValue>
                {' / '}
              </>
            )}
            {affectedArea}
          </div>
        </InfoBlock>
        {!!mission && isCreated && (
          <InfoBlock>
            <div className="description">{t('shared.created')}</div>
            <div className="title">{dateUtils.formatDate(mission.timestamp, dateFormats.DATE_FORMAT_DD_MM_YYYY)}</div>
            <div className="title">{mission.createdBy}</div>
          </InfoBlock>
        )}
      </InfoBlockWrapper>
    </Wrapper>
  );
};

export default MissionStats;
