import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-solid-svg-icons';

import StaticCard from 'atomicComponents/StaticCard';

import { mfColors, mfShadows } from '../vars';

const MODAL_ZINDEX = 6;

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: ${MODAL_ZINDEX + 1};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CardWrapper = styled.div`
  position: relative;
`;

const Backdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: ${mfColors.darkGrey};
  opacity: 0.3;
  z-index: ${MODAL_ZINDEX};
`;

const CloseIcon = styled(FontAwesomeIcon)`
  position: absolute;
  top: 24px;
  right: 24px;
  font-size: 24px;
  cursor: pointer;
`;

type TProps = PropsWithChildren<{
  width?: string;
  background?: string;
  onClose: (event?: React.MouseEvent) => void;
  showClose?: boolean;
  showBox?: boolean;
  closeOnBackdrop?: boolean;
}>;

const Modal = ({ width, background, onClose, showBox = true, showClose = true, closeOnBackdrop = false, children }: TProps) => (
  <>
    <Wrapper onClick={closeOnBackdrop ? onClose : undefined}>
      {showBox ? (
        <CardWrapper onClick={(e) => e.stopPropagation()}>
          <StaticCard width={width} shadow={mfShadows.modalShadow} padding="24px" background={background || mfColors.white} content={<>{children}</>} />
          {showClose && <CloseIcon onClick={onClose} icon={faTimes} />}
        </CardWrapper>
      ) : (
        <>{children}</>
      )}
    </Wrapper>
    <Backdrop />
  </>
);

export default Modal;
