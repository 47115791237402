import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import type { RootState } from 'redux/store';
import { IAuthRequest } from 'models/auth';
import { IUserInfo, IUser, IUserAssociatedFarms } from 'models/user';
import authService from 'services/data/auth';
import { fetchUserById, fetchUserAssociateFarms } from 'services/data/users';
import userMapperService from 'services/mappers/user';

interface IAuthState {
  user: IUserInfo | null;
  userFarms: IUserAssociatedFarms | null;
  authErrored: boolean;
  featchUserInProgress: boolean;
}

export const signInByCredentials = createAsyncThunk('auth/signInByCredentials', async (params: IAuthRequest): Promise<IUser> => {
  const response = await authService.signIn(params.email, params.password);
  return userMapperService.mapSignedInUser(response.user as any as IUser);
});

export const signOut = createAsyncThunk('auth/signOut', async (): Promise<void> => authService.signOut());

export const fetchUser = createAsyncThunk('user/getUser', async (id: string): Promise<IUserInfo> => fetchUserById(id));
export const fetchUserFarms = createAsyncThunk('user/getUserFarms', async (id: string): Promise<IUserAssociatedFarms> => fetchUserAssociateFarms(id));

const slice = createSlice({
  name: 'auth',
  initialState: { authErrored: false, user: null, userFarms: null, featchUserInProgress: false } as IAuthState,
  reducers: {
    updateUnreadAlertsCount: (state) => {
      const unreadAlertsCount = state.user?.unreadAlertsCount || 1;

      if (state.user) {
        state.user = { ...state.user, unreadAlertsCount: unreadAlertsCount - 1 };
      }
    },
    updateUserSubscriptionState: (state, action) => {
      if (state.user) {
        const userSubscriptions = state.user?.subscriptions || {};

        state.user = { ...state.user, subscriptions: { ...userSubscriptions, ...action.payload } };
      }
    }
  },
  extraReducers: (builder) => {
    builder.addCase(signInByCredentials.fulfilled, (state) => {
      state.authErrored = false;
    });
    builder.addCase(signInByCredentials.rejected, (state) => {
      state.authErrored = true;
    });
    builder.addCase(fetchUser.pending, (state) => {
      state.user = null;
      state.featchUserInProgress = true;
    });
    builder.addCase(fetchUser.fulfilled, (state, action) => {
      state.user = action.payload;
      state.featchUserInProgress = false;
    });
    builder.addCase(fetchUser.rejected, (state) => {
      state.user = null;
      state.featchUserInProgress = false;
    });
    builder.addCase(fetchUserFarms.pending, (state) => {
      state.userFarms = null;
    });
    builder.addCase(fetchUserFarms.fulfilled, (state, action) => {
      state.userFarms = action.payload;
    });
    builder.addCase(fetchUserFarms.rejected, (state) => {
      state.userFarms = null;
    });
    builder.addCase(signOut.fulfilled, (state) => {
      state.user = null;
    });
  }
});

export const authReducer = slice.reducer;
export const { updateUnreadAlertsCount, updateUserSubscriptionState } = slice.actions;
export const getAuthErrored = (state: RootState) => state.auth.authErrored;
export const getUser = (state: RootState) => state.auth.user;
export const getUserFarms = (state: RootState) => state.auth.userFarms;
export const getFetchUserInProgress = (state: RootState) => state.auth.featchUserInProgress;
export const getUserSubscriptions = (state: RootState) => state.auth.user?.subscriptions || {};
