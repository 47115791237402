import { keyframes } from 'styled-components';

export const ExpandedNavBarItemOutAnimation = keyframes`
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    opacity: 1;
  }

  100% {
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
    opacity: 0;
  }
`;

export const ExpandedNavBarItemInAnimation = keyframes`
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    opacity: 0;
  }

  100% {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
    opacity: 1;
  }
`;
