import { useState, useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { IAuthRequest } from 'models/auth';
import { IUserInfo, IUserAssociatedFarms, ADMIN_ROLES } from 'models/user';

import db from 'services/data/providers/firebase';

import { signInByCredentials, signOut, getAuthErrored, getUser, getUserFarms, getFetchUserInProgress, fetchUser, fetchUserFarms } from 'redux/auth/authSlice';

type TSignInAction = (params: IAuthRequest) => void;

interface ISignIn {
  signIn: TSignInAction;
  signInErrored: boolean;
}

const useSignIn = (): ISignIn => {
  const dispatch = useDispatch();
  const signInErrored = useSelector(getAuthErrored);
  const signIn = useCallback(
    (params: IAuthRequest): void => {
      dispatch(signInByCredentials(params));
    },
    [dispatch]
  );

  return {
    signIn,
    signInErrored
  };
};

const useSignOut = (): (() => void) => {
  const dispatch = useDispatch();
  return useCallback((): void => {
    dispatch(signOut());
  }, [dispatch]);
};

const useSignedInUser = (): IUserInfo | null => useSelector(getUser);

const useSignedInUserFarms = (): IUserAssociatedFarms | null => useSelector(getUserFarms);

const useIsAdmin = (): boolean => {
  const signedInUser = useSignedInUser();

  return useMemo(() => {
    const roles = signedInUser?.roles;
    if (!roles) return false;

    return !!ADMIN_ROLES.find((role) => roles[role]);
  }, [signedInUser]);
};

const useFetchSignedInUser = (): void => {
  const { isAuthorized } = useGetSignedInStatus();
  const dispatch = useDispatch();
  const fetchInProgress = useSelector(getFetchUserInProgress);
  const user = useSignedInUser();

  useEffect(() => {
    const currentUserID = db.user?.uid;
    if (currentUserID && isAuthorized && !fetchInProgress && !user) {
      dispatch(fetchUser(currentUserID));
      dispatch(fetchUserFarms(currentUserID));
    }
  }, [dispatch, isAuthorized, fetchInProgress, user]);
};

interface IGetSignedInStatusResult {
  isAuthorized: boolean;
  isLoading: boolean;
}

const AUTH_LOADER_TIMEOUT = 600;

const useGetSignedInStatus = (): IGetSignedInStatusResult => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isAuthorized, setIsAuthorized] = useState<boolean>(!!db.user);

  const authStateChangeHandler = useCallback(
    (authStatus: boolean) => {
      setIsAuthorized(authStatus);
      setIsLoading(false);
    },
    [setIsLoading, setIsAuthorized]
  );

  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsLoading(false);
    }, AUTH_LOADER_TIMEOUT);

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  });

  useEffect(() => {
    db.subscribeAuthStateChanged(authStateChangeHandler);
    return () => {
      db.unsubscribeAuthStateChanged(authStateChangeHandler);
    };
  }, [authStateChangeHandler]);

  return {
    isAuthorized,
    isLoading
  };
};

const useIsAllowedFarm = (farmID?: string): void => {
  const navigate = useNavigate();
  const user = useSignedInUser();

  useEffect(() => {
    if (user && farmID && !user.farms[farmID]) {
      navigate('/404');
    }
  }, [farmID, navigate, user]);
};

const signInHooks = {
  useSignIn,
  useSignOut,
  useFetchSignedInUser,
  useSignedInUser,
  useSignedInUserFarms,
  useGetSignedInStatus,
  useIsAdmin,
  useIsAllowedFarm
};

export default signInHooks;
