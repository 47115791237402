import React, { useState, useMemo, useCallback, useEffect, Dispatch, SetStateAction } from 'react';
import { useNavigate } from 'react-router-dom';
import ReactDOMServer from 'react-dom/server';
import ReactDOM from 'react-dom';
import * as turf from '@turf/turf';
import { Map, LngLatBoundsLike, Popup, LngLatLike } from 'mapbox-gl';

import { detachFeedEvents, drawFeed, drawSelectedFeedItem, showFeedPopup, IFeedPopupClickParams } from 'services/util/mapFeed';

import missionUtils from 'utils/mission';

import TreePopupContent from 'components/main/TreePopupContent/TreePopupContent';
import ReplantPopupContent from 'components/main/TreePopupContent/ReplantPopupContent';
import HotspotPopupContent from 'components/main/HotspotPopupContent/HotspotPopupContent';
import { TMapMouseEvent, CLICKABLE_TREE_LAYERS } from 'services/util/mapHelpers';

import { IFeed, ICreateFeedIncompleteParams } from 'models/feed';
import { IZone } from 'models/zone';
import { IFarm } from 'models/farm';
import { IGrove } from 'models/grove';
import { IUnifiedHotspot } from 'models/hotspot';
import { ISurveyWithTimeRange } from 'models/survey';
import { ICapacity, ITree, ITreeMapboxFeature, TSelectedFeature } from 'models/tree';
import { IMission } from 'models/mission';

import { EMeasurementSystem } from 'models/region';
import { IMapOptions } from 'models/map';
import { IRichItemGeojson } from 'models/richItem';

const useGetMapOptions = (zones: IZone[], zone: IZone | null, grove: IGrove | null, hotspot: IUnifiedHotspot | null, zoom?: number): IMapOptions => {
  const options = useMemo(() => {
    if (hotspot) {
      const hotspotPolygon = turf.polygon([hotspot.geometry]);
      const bbox = turf.bbox(turf.buffer(turf.featureCollection([hotspotPolygon]), zoom || 0.02));
      return { bounds: turf.square(bbox) as LngLatBoundsLike, zoomDuration: 0 };
    } else if (grove) {
      const grovePolygon = turf.feature(grove.geometry);
      const bbox = turf.bbox(turf.buffer(turf.featureCollection([grovePolygon]), zoom || 0.02));
      return { bounds: turf.square(bbox) as LngLatBoundsLike };
    } else if (zone) {
      const zonePolygon = turf.feature(zone.geometry);
      const bbox = turf.bbox(turf.buffer(turf.featureCollection([zonePolygon]), zoom || 0.5));
      return { bounds: turf.square(bbox) as LngLatBoundsLike };
    } else if (!zones.length) {
      return {};
    } else {
      const polygons = zones.map((zone) => turf.feature(zone.geometry));
      const bbox = turf.bbox(turf.buffer(turf.featureCollection(polygons), zoom || 0.5));
      return { bounds: turf.square(bbox) as LngLatBoundsLike };
    }
  }, [zones, zone, grove, hotspot, zoom]);

  return options;
};

export interface IPopupTree extends ITree {
  coordinates: number[];
  height: number;
}

export type IPopupFeature = TSelectedFeature & {
  coordinates: number[];
  type: 'tree' | 'line';
};

interface ITreePopupResult {
  showPopup: (tree: IPopupFeature | null) => void;
}

const useTreePopup = (
  map: Map | null,
  metricType?: EMeasurementSystem,
  container?: HTMLElement | null,
  onClosePopup?: () => void,
  onClickPopup?: (popupFeature: ICapacity) => void
): ITreePopupResult => {
  const removePopups = useCallback(() => {
    const popups = (container || document).querySelectorAll('.mapboxgl-popup');
    for (let i = 0; i < popups.length; i += 1) {
      popups[i].remove();
    }
  }, [container]);

  const showPopup = useCallback(
    (popupFeature: IPopupFeature | null) => {
      removePopups();

      if (popupFeature && map && popupFeature.type === 'tree') {
        const popup = new Popup({
          closeButton: false,
          maxWidth: '400px'
        })
          .setHTML(ReactDOMServer.renderToString(<TreePopupContent tree={popupFeature as ITree} metricType={metricType} />))
          .setLngLat(popupFeature.coordinates as LngLatLike)
          .addTo(map);

        if (onClosePopup) {
          popup.on('close', onClosePopup);
        }
      }

      if (popupFeature && map && (popupFeature as ICapacity).length) {
        const placeholder = document.createElement('div');
        const popup = new Popup({
          closeButton: false,
          maxWidth: '400px'
        })
          .setDOMContent(placeholder)
          .setLngLat(popupFeature.coordinates as LngLatLike)
          .addTo(map);

        const handleHideReplant = (val: ICapacity) => {
          if (onClickPopup) {
            onClickPopup(val);
          }

          popup.remove();
        };

        ReactDOM.render(<ReplantPopupContent feature={popupFeature as ICapacity} metricType={metricType} onHideReplant={handleHideReplant} />, placeholder);

        if (onClosePopup) {
          popup.on('close', () => {
            onClosePopup();
            ReactDOM.unmountComponentAtNode(placeholder);
          });
        }
      }
    },
    [removePopups, map, metricType, onClosePopup, onClickPopup]
  );

  return {
    showPopup
  };
};

const useReplantPopup = (
  map: Map | null,
  metricType?: EMeasurementSystem,
  container?: HTMLElement | null,
  onClosePopup?: () => void,
  onClickPopup?: (popupFeature: ICapacity) => void
): ITreePopupResult => {
  const removePopups = useCallback(() => {
    const popups = (container || document).querySelectorAll('.mapboxgl-popup');
    for (let i = 0; i < popups.length; i += 1) {
      popups[i].remove();
    }
  }, [container]);

  const showPopup = useCallback(
    (popupFeature: IPopupFeature | null) => {
      removePopups();

      if (popupFeature && map && popupFeature.type === 'line') {
        const placeholder = document.createElement('div');
        const popup = new Popup({
          closeButton: false,
          maxWidth: '400px'
        })
          .setDOMContent(placeholder)
          .setLngLat(popupFeature.coordinates as LngLatLike)
          .addTo(map);

        const handleHideReplant = (val: ICapacity) => {
          if (onClickPopup) {
            onClickPopup(val);
          }

          popup.remove();
        };

        ReactDOM.render(<ReplantPopupContent feature={popupFeature as ICapacity} metricType={metricType} onHideReplant={handleHideReplant} />, placeholder);

        if (onClosePopup) {
          popup.on('close', () => {
            onClosePopup();
            ReactDOM.unmountComponentAtNode(placeholder);
          });
        }
      }
    },
    [removePopups, map, metricType, onClosePopup, onClickPopup]
  );

  return {
    showPopup
  };
};

const useMapCreateFeed = (map: Map | null, feed: ICreateFeedIncompleteParams[], reportLocationTreshold: number) => {
  useEffect(() => {
    if (map && feed.length) {
      drawFeed(map, feed, reportLocationTreshold);
    }
  }, [map, feed, reportLocationTreshold]);
};

const useMapFeed = (
  map: Map | null,
  feed: IFeed[],
  selectedFarm: IFarm | null,
  selectedGrove: IGrove | null,
  reportLocationTreshold: number,
  showFeed = true,
  mission: IMission | null = null,
  skipDraw = false,
  onFeedPopupImageClicked?: (params: IFeedPopupClickParams) => void,
  showFarmFeed = false
) => {
  const navigate = useNavigate();
  const [selectedFeedItem, setSelectedFeedItem] = useState<IFeed | null>(null);

  useEffect(() => {
    setSelectedFeedItem((prev) => (prev?.groveID === selectedGrove?.id ? prev : null));
  }, [selectedGrove?.id]);

  useEffect(() => {
    if (selectedFarm && selectedFeedItem && selectedFeedItem.groveID && selectedGrove?.id && selectedGrove.id !== selectedFeedItem.groveID && !selectedFeedItem.missionID) {
      navigate(`/map/${selectedFarm.id}/${selectedFeedItem.zoneID}/${selectedFeedItem.groveID}`);
    }
  }, [selectedFarm, selectedGrove?.id, selectedFeedItem, navigate]);

  useEffect(() => {
    if (map && selectedFeedItem?.geometry) {
      const geometry = JSON.parse(selectedFeedItem.geometry);

      if (geometry.coordinates) {
        map.flyTo({
          center: geometry.coordinates
        });
      }
    }
  }, [selectedFeedItem, map]);

  useEffect(() => {
    let mapFeed: IFeed[] = [];
    if (selectedGrove && feed.length) {
      mapFeed = feed.filter((item) => item.groveID === selectedGrove.id);
    } else if (showFarmFeed) {
      mapFeed = feed;
    }
    // TODO Remove this check after removing all feed from map
    if (!mission && selectedFarm?.id) {
      mapFeed = missionUtils.filterMapFeed(selectedFarm.id, mapFeed);
    }

    const clickFeedHandler = (event) => {
      const feedItem = feed.find((item) => item.id === event.features?.[0].properties?.id);

      if (feedItem) {
        setSelectedFeedItem(feedItem);
      }
    };

    if (map && !skipDraw) {
      drawFeed(map, showFeed ? mapFeed : [], reportLocationTreshold, clickFeedHandler);
    }

    return () => {
      if (map && !skipDraw) {
        detachFeedEvents(map, clickFeedHandler);
      }
    };
  }, [map, mission, selectedFarm?.id, feed, selectedGrove, reportLocationTreshold, showFeed, skipDraw, showFarmFeed]);

  useEffect(() => {
    if (map && selectedFarm) {
      const feedItems = missionUtils.getGeoObjectFeedGroup(selectedFeedItem, feed, mission);
      showFeedPopup(map, feedItems, selectedFarm.id, setSelectedFeedItem, mission, onFeedPopupImageClicked);
    }
  }, [map, mission, feed, selectedFeedItem, selectedFarm, setSelectedFeedItem, onFeedPopupImageClicked]);

  useEffect(() => {
    if (!map) return;
    drawSelectedFeedItem(map, selectedFeedItem, reportLocationTreshold);
  }, [map, selectedFeedItem, reportLocationTreshold]);
};

interface ITreeClickHandlerResult {
  treeClickHandler: (event: TMapMouseEvent) => void;
}

const useTreeClickHandler = (
  map: Map | null,
  trees: IRichItemGeojson,
  sourceTrees: ITreeMapboxFeature[],
  onSelectTreeForPopup: Dispatch<SetStateAction<IPopupFeature | null>>,
  onSelectTree: (tree: ITreeMapboxFeature | null) => void,
  isClickDisabled?: boolean
): ITreeClickHandlerResult => {
  const treeClickHandler = useCallback(
    (event: TMapMouseEvent) => {
      if (!map || isClickDisabled) return;

      const features = map.queryRenderedFeatures(event.point);
      const treeFeature = features.find((feature) => CLICKABLE_TREE_LAYERS.includes(feature.layer.id)) as ITreeMapboxFeature;

      if (!treeFeature) {
        onSelectTree(null);
        onSelectTreeForPopup(null);
      } else {
        onSelectTree(treeFeature);
        onSelectTreeForPopup((prev) => {
          const treeID = treeFeature.properties?.id;
          const treeModel = trees[treeID];
          const treeSourceModel = sourceTrees.find((tree) => tree.properties.id === treeID);
          const treeName = getTreeName(treeSourceModel);
          const geometryType = treeFeature.geometry.type;

          if (geometryType === 'LineString') {
            const center = turf.center(treeFeature.geometry);
            return {
              coordinates: center.geometry.coordinates as number[],
              capacity: treeFeature.properties.capacity,
              length: treeFeature.properties.length,
              segmentID: treeFeature.properties.id,
              type: 'line'
            } as IPopupFeature;
          }

          if ((prev as IPopupTree)?.treeID === treeID || treeFeature.geometry.type !== 'Point' || !treeModel) {
            return prev;
          }

          return {
            treeID,
            ...treeModel,
            coordinates: treeFeature.geometry.coordinates,
            treeName,
            type: 'tree'
          } as IPopupFeature;
        });
      }
    },
    [map, trees, sourceTrees, onSelectTree, onSelectTreeForPopup, isClickDisabled]
  );

  const getTreeName = (tree?: ITreeMapboxFeature): string => {
    if (!tree) {
      return '';
    }
    const { subGrove, row, treeIndex } = tree && tree?.properties;

    return subGrove && row && treeIndex ? `${subGrove}/${row}/${treeIndex}` : '';
  };

  return {
    treeClickHandler
  };
};

const useMissionTreeClickHandler = (map: Map | null, onSelectTree: (tree: ITreeMapboxFeature | null) => void): ITreeClickHandlerResult => {
  const treeClickHandler = useCallback(
    (event: TMapMouseEvent) => {
      if (!map) return;

      const features = map.queryRenderedFeatures(event.point);
      const treeFeature = features.find((feature) => CLICKABLE_TREE_LAYERS.includes(feature.layer.id)) as ITreeMapboxFeature;

      if (!treeFeature) {
        onSelectTree(null);
      } else {
        onSelectTree(treeFeature);
      }
    },
    [map, onSelectTree]
  );

  return {
    treeClickHandler
  };
};

interface IHotspotPopupResult {
  showPopup: (hotspot: IUnifiedHotspot | null, survey: ISurveyWithTimeRange | null) => void;
}

const useHotspotPopup = (map: Map | null): IHotspotPopupResult => {
  const removePopups = useCallback(() => {
    const popups = document.querySelectorAll('.mapboxgl-popup');
    for (let i = 0; i < popups.length; i += 1) {
      popups[i].remove();
    }
  }, []);

  const showPopup = useCallback(
    (hotspot: IUnifiedHotspot | null, survey: ISurveyWithTimeRange | null) => {
      removePopups();

      if (hotspot && survey && map) {
        const { geometry } = hotspot;
        const center = turf.centroid(turf.polygon([geometry]));
        new Popup({
          closeButton: false,
          maxWidth: '400px'
        })
          .setHTML(ReactDOMServer.renderToString(<HotspotPopupContent hotspot={hotspot} survey={survey} />))
          .setLngLat(center.geometry.coordinates as LngLatLike)
          .addTo(map);
      }
    },
    [removePopups, map]
  );

  return {
    showPopup
  };
};

let mapResizer;
const ANIMATION_DURATION = 1000;

const useMapResizer = (map: Map | null, startInterval: boolean) => {
  useEffect(() => {
    clearInterval(mapResizer);

    if (map && startInterval) {
      mapResizer = setInterval(() => {
        map.resize();
      }, 5);
    }

    return () => {
      setTimeout(() => {
        map && map.resize();
        clearInterval(mapResizer);
      }, ANIMATION_DURATION);
    };
  }, [map, startInterval]);
};

const mapHooks = {
  useTreeClickHandler,
  useMissionTreeClickHandler,
  useGetMapOptions,
  useTreePopup,
  useHotspotPopup,
  useMapResizer,
  useMapFeed,
  useMapCreateFeed,
  useReplantPopup
};

export default mapHooks;
