import { Map } from 'mapbox-gl';
import { IPoi } from 'models/poi';
import { addLayerToMap, loadImage, addMapSource, reorderLayers, LAYERS_PRIORITY } from 'services/util/mapHelpers';
import { point } from '@turf/turf';

export const POI = 'poi';
export const TRAP_IMAGE = 'trap-icon';

export const addPoiLayers = async (map: Map): Promise<void> => {
  await loadImage(map, '/images/trap.png', TRAP_IMAGE);

  addMapSource(map, POI, []);
  addLayerToMap(map, POI, {
    id: POI,
    source: POI,
    type: 'symbol',
    layout: {
      'icon-image': TRAP_IMAGE,
      'icon-size': {
        stops: [
          [17, 0.35],
          [22, 1]
        ]
      }
    }
  });

  reorderLayers(
    map,
    LAYERS_PRIORITY.map((layer, index) => ({
      zIndex: index,
      name: layer
    }))
  );
};

export const drawPoi = (map: Map, poi: IPoi[]): void => {
  const poiFeatures = poi
    .filter((item) => item?.geometry)
    .map((item) => {
      const { geometry } = item;

      const isString = typeof geometry === 'string';

      return isString ? JSON.parse(item.geometry) : item.geometry;
    })
    .filter((item) => item.coordinates)
    .map((item) => point(item.coordinates));

  addMapSource(map, POI, poiFeatures);
};
