import React, { useMemo } from 'react';
import styled from 'styled-components';

import { TMissionType, EMissionType } from 'models/mission';
import { EReportType } from 'models/report';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSeedling } from '@fortawesome/pro-solid-svg-icons';
import {
  DedicatedVisitIcon,
  TargetedScoutingIcon,
  TreeToppingIcon,
  TreeHedgingIcon,
  WeedIcon,
  HLBIcon,
  FruitIcon,
  PestsIcon,
  SamplingIcon,
  TrapIcon,
  ClippingIcon,
  IrrigationIcon,
  GeneralIcon,
  BindweedsTreatmentIcon
} from 'assets/images';

import { mfColors } from 'vars';

interface IWrapperProps {
  size: string;
  showBackground: boolean;
}

const Wrapper = styled.div`
  background: ${({ showBackground }: IWrapperProps) => (showBackground ? mfColors.missionFillColor : 'none')};
  color: ${mfColors.missionTextColor};
  border-radius: 50%;
  height: ${({ size }: IWrapperProps) => size};
  width: ${({ size }: IWrapperProps) => size};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
`;

interface IProps {
  type: TMissionType;
  size?: string;
  showBackground?: boolean;
}

const MissionIcon = ({ type, size = '40px', showBackground = true }: IProps): JSX.Element | null => {
  const icon = useMemo(() => {
    switch (type) {
      case EReportType.DedicatedVisit:
        return <DedicatedVisitIcon />;
      case EReportType.TargetedScouting:
        return <TargetedScoutingIcon />;
      case EMissionType.GeneralScouting:
        return <GeneralIcon />;
      case EReportType.Height:
        return <TreeToppingIcon />;
      case EReportType.FreeArea:
        return <TreeHedgingIcon />;
      case EReportType.Weeds:
        return <WeedIcon style={{ marginTop: '-6px' }} />;
      case EReportType.Cipo:
        return <BindweedsTreatmentIcon />;
      case EMissionType.HLBScouting:
        return <HLBIcon />;
      case EMissionType.WeeklyTrapMonitoring:
        return <TrapIcon />;
      case EMissionType.PestsDesease:
      case EMissionType.SmartPestScouting:
        return <PestsIcon />;
      case EMissionType.FruitCount:
        return <FruitIcon />;
      case EMissionType.Sampling:
        return <SamplingIcon />;
      case EMissionType.Clipping:
        return <ClippingIcon />;
      case EMissionType.IrrigationScouting:
        return <IrrigationIcon />;
      case EMissionType.General:
        return <GeneralIcon />;
      case EMissionType.Replant:
        return <FontAwesomeIcon icon={faSeedling} />;
      default:
        return <></>;
    }
  }, [type]);

  return icon ? (
    <Wrapper size={size} showBackground={showBackground}>
      {icon}
    </Wrapper>
  ) : null;
};

export default MissionIcon;
