import React, { useMemo } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import DataTable from 'atomicComponents/DataTable';

import { IWeeklyTrapMonitoringConfig } from 'models/mission';
import { IFeed } from 'models/feed';
import farmsHooks from 'hooks/farms.hooks';
import dateUtils from 'utils/date';
import dateFormats from 'models/date';
// import missionUtils from 'utils/mission';

import { mfColors } from 'vars';

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Title = styled.div`
  font-family: MontserratBold;
  font-size: 18px;
  line-height: 24px;
  margin: 0 0 24px;
  text-align: left;
  color: ${mfColors.darkGrey};
  width: 100%;
`;

const TableWrapper = styled.div`
  max-height: 400px;
  overflow: auto;
  width: 100%;
`;

const TableCell = styled.div`
  background: ${mfColors.white};
  padding: 4px 4px 4px 0;
  min-height: 32px;
  font-size: 12px;
  line-height: 24px;
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;

  &.left {
    text-align: left;
  }

  &.pests-count {
    color: ${mfColors.white};
    font-family: MontserratBold;
  }
`;

const HEADER_STYLE = {
  fontSize: '12px'
};

const COLUMN_STYLE = {
  padding: '8px 20px 8px 8px'
};

interface IProps {
  feed: IFeed[];
  config: IWeeklyTrapMonitoringConfig | null;
}

const PestsFeedModal = ({ feed, config }: IProps): JSX.Element | null => {
  const { t } = useTranslation();
  const { groves } = farmsHooks.useFarmEntities();

  const grove = useMemo(() => {
    const feedEntry = feed[0];
    if (!feedEntry) return null;
    return groves.find((entry) => entry.id === feedEntry.groveID) || null;
  }, [feed, groves]);

  const tableConfig = useMemo(
    () => [
      {
        title: t('shared.date'),
        render: (feed: IFeed) => <TableCell>{dateUtils.formatDate(feed.updatedAt, dateFormats.DATE_FORMAT_DD_MMM)}</TableCell>,
        key: 'date',
        sortable: false,
        width: '80px',
        style: COLUMN_STYLE
      },
      {
        title: t('missions.reported_by'),
        render: (feed: IFeed) => <TableCell className="left">{feed.user.displayName}</TableCell>,
        key: 'reported_by',
        sortable: false,
        width: '140px',
        style: {
          ...COLUMN_STYLE,
          justifyContent: 'flex-start'
        }
      },
      {
        title: t('missions.pest_type'),
        render: (feed: IFeed) => <TableCell className="left">{t(`missions.pests.${feed.data?.trapType || ''}`)}</TableCell>,
        key: 'pest_type',
        sortable: false,
        width: '180px',
        style: {
          ...COLUMN_STYLE,
          justifyContent: 'flex-start'
        }
      },
      {
        title: t('missions.pests_counted'),
        render: (feed: IFeed) => {
          const colors = config?.[feed.data?.trapType || '']?.colors;
          const pestsNumber = feed.data?.pestsNumber || 0;
          const backgroundColor = colors?.find((entry) => (entry.min === null || entry.min <= pestsNumber) && (entry.max === null || entry.max > pestsNumber))?.color || '';
          return (
            <TableCell className="pests-count" style={{ backgroundColor }}>
              {pestsNumber}
            </TableCell>
          );
        },
        key: 'pestsCounted',
        sortable: false,
        width: '160px',
        style: COLUMN_STYLE
      },
      {
        title: t('missions.changed_bait'),
        render: (feed: IFeed) => <TableCell className="left">{t(feed.data?.changedBait ? 'shared.yes' : 'shared.no')}</TableCell>,
        key: 'changedBait',
        sortable: false,
        width: '100px',
        style: {
          ...COLUMN_STYLE,
          justifyContent: 'flex-start'
        }
      }
    ],
    [t, config]
  );

  const tableProps = useMemo(() => {
    const props = {
      config: tableConfig,
      data: feed,
      showHeader: true,
      showFooter: false,
      rowsPerPage: 0,
      headerStyle: HEADER_STYLE
    };
    return props;
  }, [tableConfig, feed]);

  if (!grove || !config) return null;

  return (
    <Wrapper>
      <Title>{t('missions.block_name', { name: grove.name })}</Title>
      <TableWrapper>
        <DataTable<IFeed> {...tableProps} />
      </TableWrapper>
    </Wrapper>
  );
};

export default PestsFeedModal;
