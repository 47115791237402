export const subtractLight = (color: string, amount: number): string => {
  const cc = parseInt(color, 16) - amount;
  const c = cc < 0 ? 0 : cc;
  return c.toString(16).length > 1 ? c.toString(16) : `0${c.toString(16)}`;
};

export const getDarkerColor = (color: string, amount: number): string => {
  const baseColor = color.includes('#') ? color.substring(1, color.length) : color;
  // eslint-disable-next-line no-bitwise
  amount = ((255 * amount) / 100) | 0;
  return `#${subtractLight(baseColor.substring(0, 2), amount)}${subtractLight(baseColor.substring(2, 4), amount)}${subtractLight(baseColor.substring(4, 6), amount)}`;
};
