import { SATELLITE_HOTSPOTS_FEATURE_KEY } from 'models/hotspot';
import { IFarmBulletinThreshold } from './company';

export type TFarmSurvey = { [key: string]: number };
export type TFarmZone = { [key: string]: boolean };

export interface IFarmFeatures {
  cipo: boolean;
  freeArea: boolean;
  geoAnalytics: boolean;
  health: boolean;
  weeds: boolean;
  ndvi?: boolean;
  [SATELLITE_HOTSPOTS_FEATURE_KEY]?: boolean;
}

export interface IFarmBulletinRelevantMonths {
  [key: string]: number[];
}

export interface IFarmName {
  id: string;
  name: string;
}

export interface IFarm {
  id: string;
  active: boolean;
  address: string;
  config: {
    features?: IFarmFeatures;
    surveyLength: string;
  };
  createdAt: number;
  customerID: string;
  farmBulletinThresholds: IFarmBulletinThreshold;
  bulletinRelevantMonths: IFarmBulletinRelevantMonths;
  map: {
    bbox: [number, number, number, number];
    center: {
      lat: number;
      lng: number;
    };
    defaultZoom: number;
  };
  name: string;
  region: string;
  surveys: {
    active: TFarmSurvey;
    published: TFarmSurvey;
  };
  zones: TFarmZone;
  permitted: boolean;
  visitedAt: number;
  irrigationSourceNames?: {
    lines: string;
    points: string;
    polygons: string;
  };
}

export enum ECommodity {
  Almonds = 'Almonds',
  Avocado = 'Avocado',
  Oranges = 'Oranges',
  Tangarin = 'Tangarin',
  Grapefruit = 'Grapefruit',
  Minneola = 'Minneola',
  Pomegranate = 'Pomegranate',
  Hazelnuts = 'Hazelnuts',
  Macadamia = 'Macadamia',
  Olives = 'Olives',
  MandarinsHybrides = 'Mandarins & Mandarin Hybrids',
  LemonsLimes = 'Lemons and Limes',
  PalmOil = 'PalmOil',
  Mango = 'Mango',
  Pummelos = 'Pummelos & Hybrids',
  Pistachio = 'Pistachio',
  Pears = 'Pears',
  Lemons = 'Lemons',
  Cherries = 'Cherries',
  Mandarins = 'Mandarins',
  Apples = 'Apples',
  Litchi = 'Litchi'
}

export const CITRUS_COMMODITIES = [
  ECommodity.Oranges,
  ECommodity.Tangarin,
  ECommodity.Grapefruit,
  ECommodity.Minneola,
  ECommodity.MandarinsHybrides,
  ECommodity.LemonsLimes,
  ECommodity.Pummelos,
  ECommodity.Lemons,
  ECommodity.Mandarins
];
