import React, { useMemo } from 'react';
import styled from 'styled-components';

import { mfColors, mfShadows } from 'vars';

export enum EAvatarSize {
  XS = 'xs',
  SM = 'sm',
  MD = 'md',
  LG = 'lg'
}

// prettier-ignore
const AVATAR_SIZES = new Map<EAvatarSize, string>()
  .set(EAvatarSize.XS, '24px')
  .set(EAvatarSize.SM, '32px')
  .set(EAvatarSize.MD, '40px')
  .set(EAvatarSize.LG, '48px');

// prettier-ignore
const AVATAR_FONT_SIZES = new Map<EAvatarSize, string>()
  .set(EAvatarSize.XS, '12px')
  .set(EAvatarSize.SM, '16px')
  .set(EAvatarSize.MD, '20px')
  .set(EAvatarSize.LG, '24px');

interface IWrapperProps {
  size: EAvatarSize;
}

const Wrapper = styled.div`
  border-radius: 50%;
  width: ${({ size }: IWrapperProps) => AVATAR_SIZES.get(size)};
  height: ${({ size }: IWrapperProps) => AVATAR_SIZES.get(size)};
  font-size: ${({ size }: IWrapperProps) => AVATAR_FONT_SIZES.get(size)};
  color: ${mfColors.white};
  background-color: ${mfColors.avatarPlaceholderColor};
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  user-select: none;
  box-shadow: ${mfShadows.searchShadow};
`;

const AvatarImage = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

interface IProps {
  name: string;
  url?: string | null;
  size?: EAvatarSize;
}

const Avatar = ({ url, name, size = EAvatarSize.SM }: IProps): JSX.Element => {
  const placeholder = useMemo(() => name.toUpperCase().substr(0, 1), [name]);

  return <Wrapper size={size}>{url ? <AvatarImage src={url} alt={name} /> : <span>{placeholder}</span>}</Wrapper>;
};

export default Avatar;
