import Overlay from 'atomicComponents/Overlay';
import MissingDataMessagePopup from 'components/main/MissingDataMessagePopup/MissingDataMessagePopup';
import WeatherPopup from 'components/main/WeatherPopup/WeatherPopup';
import Map, { MapContext } from 'components/shared/Map';
import NavBar from 'containers/NavBar';
import farmsHooks from 'hooks/farms.hooks';
import mapHooks from 'hooks/map.hooks';
import regionsHooks from 'hooks/regions.hooks';
import useActiveURLs from 'hooks/route.hook';
import React, { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { isShowBIDashboardSelector, isSideBarExpandedSelector, evidenceParamsSelector, getIsCompareOpened, IEvidenceParams, setEvidenceParams } from 'redux/appStateSlice';
import { regionMeasurementSystemSelector } from 'redux/region/regionSlice';
import { richItemTypeSelector, isMissingRichItemSelector } from 'redux/richItems/richItemsSlice';
import SeetreeDashboard from 'screens/SeetreeDashboard';
import CompareScreen from 'screens/Compare';
import TreeGallery from 'screens/TreeGallery';
import styled from 'styled-components';
import { Pages } from 'utils/routes';

import { mfSizes } from 'vars';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  min-height: 100%;
  width: 100%;
  height: 100%;
`;
interface IWrapperProps {
  sideBarExpanded: boolean;
  isVisible?: boolean;
}

const emptyWidth = '0px';

const OutletWrapper = styled.div`
  width: ${({ sideBarExpanded }: IWrapperProps) => (sideBarExpanded ? mfSizes.sideBarAreaWidth : emptyWidth)};
  height: ${({ sideBarExpanded }: IWrapperProps) => (sideBarExpanded ? '100%' : emptyWidth)};
  transition: width 0.5s;
  display: ${({ isVisible }: IWrapperProps) => (isVisible ? 'block' : 'none')};
`;

const MapSectionWrapper = styled.div`
  width: calc(100% - ${({ sideBarExpanded }: IWrapperProps) => (sideBarExpanded ? mfSizes.sideBarAreaWidth : emptyWidth)});
  height: 100%;
`;

const MapLayout = () => {
  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const surveyID = searchParams.get('surveyID');
  const richItemType = useSelector(richItemTypeSelector);
  const isMissingRichItem = useSelector(isMissingRichItemSelector);
  const sideBarExpanded = useSelector(isSideBarExpandedSelector);
  const { selectedFarm } = farmsHooks.useFarmMapInfo(params.farmID, params.zoneID, params.groveID, surveyID || '');
  const { activeMap } = useContext(MapContext);
  const isShowBIDashboard = useSelector(isShowBIDashboardSelector);
  const isCompareOpened = useSelector(getIsCompareOpened);
  const evidenceParams = useSelector(evidenceParamsSelector) as IEvidenceParams;

  regionsHooks.useGetRegions();

  useEffect(() => {
    if (location.pathname === '/') {
      navigate(Pages.Map);
    }
  }, [location, navigate]);

  const metricType = useSelector(regionMeasurementSystemSelector);
  const { isMapUrl } = useActiveURLs(selectedFarm);

  mapHooks.useMapResizer(activeMap, !sideBarExpanded);
  mapHooks.useMapResizer(activeMap, !isShowBIDashboard);
  mapHooks.useMapResizer(activeMap, !isCompareOpened);

  const onGalleryClose = () => {
    dispatch(setEvidenceParams(null));
  };

  return (
    <Wrapper>
      <NavBar />
      <OutletWrapper sideBarExpanded={sideBarExpanded} isVisible={!isShowBIDashboard && !evidenceParams}>
        <Outlet />
      </OutletWrapper>

      {isShowBIDashboard && (
        <Overlay fullSize={sideBarExpanded}>
          <SeetreeDashboard />
        </Overlay>
      )}
      {evidenceParams && (
        <Overlay fullSize={sideBarExpanded}>
          <TreeGallery evidenceParams={evidenceParams} onClose={onGalleryClose} />
        </Overlay>
      )}
      {isCompareOpened && (
        <Overlay fullSize={!sideBarExpanded}>
          <CompareScreen />
        </Overlay>
      )}
      <MapSectionWrapper sideBarExpanded={sideBarExpanded}>
        <Map
          weather={isMapUrl ? <WeatherPopup farm={selectedFarm} metricType={metricType} /> : undefined}
          missingDataMessage={isMissingRichItem ? <MissingDataMessagePopup richItemType={richItemType} /> : undefined}
        />
      </MapSectionWrapper>
    </Wrapper>
  );
};

export default MapLayout;
