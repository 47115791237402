import { MEASURE_SOURCE, MEASURE_AREA_SOURCE, MEASURE_AREA_LAYER, MEASURE_POINTS_LAYER, MEASURE_LINES_LAYER } from 'constants/map';
import { Map, GeoJSONSource } from 'mapbox-gl';
import { featureCollection, bboxPolygon, square, bbox, buffer, Feature, Polygon } from '@turf/turf';
import { addLayerToMap, reorderLayers } from 'services/util/mapHelpers';
import { TEventHandler } from 'models/map';

const _measureSources = [MEASURE_SOURCE, MEASURE_AREA_SOURCE];
const _measureLayers = [MEASURE_AREA_LAYER, MEASURE_POINTS_LAYER, MEASURE_LINES_LAYER];

const removeSources = (map: Map): void => {
  _measureSources.forEach((source) => {
    if (map.getSource(source)) {
      map.removeSource(source);
    }
  });
};

const removeLayers = (map: Map): void => {
  _measureLayers.forEach((layer) => {
    if (map.getLayer(layer)) {
      map.removeLayer(layer);
    }
  });
};

export const removeMeasurementLayers = (map: Map): void => {
  removeLayers(map);
  removeSources(map);
};

const addMeasureLayers = (map: Map): void => {
  addLayerToMap(map, MEASURE_AREA_LAYER, {
    id: MEASURE_AREA_LAYER,
    type: 'fill',
    source: MEASURE_AREA_SOURCE,
    paint: { 'fill-opacity': 0 }
  });

  addLayerToMap(map, MEASURE_POINTS_LAYER, {
    id: MEASURE_POINTS_LAYER,
    type: 'circle',
    source: MEASURE_SOURCE,
    paint: {
      'circle-color': '#000',
      'circle-radius': {
        base: 1,
        stops: [
          [16, 1.8],
          [19.4, 8]
        ]
      },
      'circle-stroke-width': {
        base: 2,
        stops: [
          [17, 1],
          [21, 3.8]
        ]
      },
      'circle-stroke-color': '#fff'
    },
    filter: ['in', '$type', 'Point']
  });

  addLayerToMap(map, MEASURE_LINES_LAYER, {
    id: MEASURE_LINES_LAYER,
    type: 'line',
    source: MEASURE_SOURCE,
    layout: {
      'line-cap': 'round',
      'line-join': 'round'
    },
    paint: {
      'line-color': '#000',
      'line-width': 2.5
    },
    filter: ['in', '$type', 'LineString']
  });

  reorderLayers(
    map,
    _measureLayers.map((layerName, index) => ({ zIndex: 1000 + index, name: layerName }))
  );
};

export const addMeasureSources = (map: Map, measureArea: Polygon): void => {
  removeMeasurementLayers(map);

  map.addSource(MEASURE_SOURCE, {
    type: 'geojson',
    data: featureCollection([])
  });
  map.addSource(MEASURE_AREA_SOURCE, {
    type: 'geojson',
    data: bboxPolygon(square(bbox(buffer(measureArea, 0.2))))
  });
};

export const setMapMeasurementSource = (map: Map, measurePoints: Feature[]): void => {
  const source = map.getSource(MEASURE_SOURCE) as GeoJSONSource;

  if (source) {
    source.setData(featureCollection(measurePoints) as any);
  }
};

const addEventHandlers = (map: Map, clickHandler: TEventHandler, deleteHandler: TEventHandler, setCursorPointer: TEventHandler, setCrosshairCursor: TEventHandler): void => {
  map.on('click', MEASURE_AREA_LAYER, clickHandler);
  map.on('contextmenu', MEASURE_POINTS_LAYER, deleteHandler);
  map.on('mousemove', MEASURE_AREA_LAYER, setCrosshairCursor);
  map.on('mousemove', MEASURE_POINTS_LAYER, setCursorPointer);
};

export const removeEventHandlers = (
  map: Map,
  clickHandler: TEventHandler,
  deleteHandler: TEventHandler,
  setCursorPointer: TEventHandler,
  setCrosshairCursor: TEventHandler
): void => {
  map.off('click', MEASURE_AREA_LAYER, clickHandler);
  map.off('contextmenu', MEASURE_POINTS_LAYER, deleteHandler);
  map.off('mousemove', MEASURE_AREA_LAYER, setCrosshairCursor);
  map.off('mousemove', MEASURE_POINTS_LAYER, setCursorPointer);
};

export const initMeasurementLayers = (map: Map, measureArea: Polygon | null): void => {
  if (!measureArea) {
    return;
  }

  addMeasureSources(map, measureArea);
  addMeasureLayers(map);
};

export const setMeasurementClickHandlers = (
  map: Map,
  clickHandler: TEventHandler,
  deleteHandler: TEventHandler,
  setCursorPointer: TEventHandler,
  setCrosshairCursor: TEventHandler
): void => {
  if (!map || !clickHandler || !deleteHandler) {
    return;
  }

  addEventHandlers(map, clickHandler, deleteHandler, setCursorPointer, setCrosshairCursor);
};

export const setMeasurementLayerPaint = (map: Map, color: string, width: number) => {
  if (map.getLayer(MEASURE_POINTS_LAYER)) {
    map.setPaintProperty(MEASURE_POINTS_LAYER, 'circle-color', color);
  }

  if (map.getLayer(MEASURE_LINES_LAYER)) {
    map.setPaintProperty(MEASURE_LINES_LAYER, 'line-color', color);
    map.setPaintProperty(MEASURE_LINES_LAYER, 'line-width', width);
  }
};
