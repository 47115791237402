import React, { useState, useCallback, useMemo, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamation, faCut, faWaveSine, faSeedling } from '@fortawesome/pro-solid-svg-icons';

import Button from 'atomicComponents/Button';
import BulletinTitle from 'components/main/bulletin/BulletinTitle';
import BulletinItem from 'components/main/bulletin/BulletinItem';
import BulletinModal from 'components/main/bulletin/BulletinModal/BulletinModal';
import SidebarBlockTitle, { ETitleType } from 'components/main/SidebarBlockTitle/SidebarBlockTitle';
import SidebarBlockItem from 'components/main/SidebarBlockItem/SidebarBlockItem';
import SidebarBlockShowMore from 'components/main/SidebarBlockShowMore/SidebarBlockShowMore';
import { ExpandAnimation } from 'atomicComponents/animations';
import { BulletinContext } from 'screens/Map';
import { IGrove } from 'models/grove';
import { IFarm } from 'models/farm';
import { IZone } from 'models/zone';
import { EReportType, TAnyReport, IHeightReport, IFreeAreaReport, ICipoReport, IWeedsReport, IScoreDifferenceReport } from 'models/report';
import { EMeasurementSystem } from 'models/region';
import { mfColors } from 'vars';

import { TargetedScoutingIcon, TreeHedgingIcon, WeedIcon } from 'assets/images';
import { IUnifiedHotspot } from 'models/hotspot';
import Modal from 'atomicComponents/Modal';
import { useModal } from 'react-modal-hook';

const Wrapper = styled.div`
  border: 3px solid ${mfColors.alertRed};
  padding: 32px 18px 0 24px;
  border-radius: 16px;
  background: ${mfColors.white};
  margin: 0 0 18px;
  position: relative;
  cursor: pointer;
`;

const SidebarBlockContent = styled.div`
  animation: ${ExpandAnimation} 500ms ease-in-out forwards;
`;

const SidebarBlockControl = styled.div`
  margin: 0 0 32px;
`;

const REPORT_ITEMS_COUNT = 5;

interface IProps {
  groves: IGrove[];
  reportsCount: number;
  reports: (TAnyReport | IUnifiedHotspot)[];
  reportType: EReportType;
  selectedFarm: IFarm;
  selectedZone: IZone | null;
  selectedGrove: IGrove | null;
  onGroveClicked: (grove: IGrove, reportType: EReportType) => void;
  metricType?: EMeasurementSystem;
}

const Bulletin = ({ groves, reportsCount, reports, reportType, selectedFarm, selectedZone, selectedGrove, onGroveClicked, metricType }: IProps): JSX.Element => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const context = useContext(BulletinContext);
  const [isShowMore, setIsShowMore] = useState<boolean>(false);
  const isExpanded = context?.expandedBulletin === reportType;

  const sortedReports = useMemo(() => {
    const sorted = reports.sort((a, b) => {
      switch (reportType) {
        case EReportType.DedicatedVisit:
          return (b as IScoreDifferenceReport).underperforming_difference - (a as IScoreDifferenceReport).underperforming_difference;
        case EReportType.Height:
          return +(b as IHeightReport).Height - +(a as IHeightReport).Height;
        case EReportType.FreeArea:
          return +(a as IFreeAreaReport).freeArea_avg - +(b as IFreeAreaReport).freeArea_avg;
        case EReportType.Cipo:
          return (b as ICipoReport).cipo_rate - (a as ICipoReport).cipo_rate;
        case EReportType.Weeds:
          // prettier-ignore
          return (
            (+(b as IWeedsReport)._30_60cm_percentage + +(b as IWeedsReport)._60_90cm_percentage + +(b as IWeedsReport)._90cm_above_percentage)
            - (+(a as IWeedsReport)._30_60cm_percentage + +(a as IWeedsReport)._60_90cm_percentage + +(a as IWeedsReport)._90cm_above_percentage)
          );
        default:
          return 0;
      }
    });
    return sorted;
  }, [reports, reportType]);

  const sortedReportsGroups = useMemo(() => {
    const reportGroups = sortedReports.reduce((acc: (TAnyReport | IUnifiedHotspot)[][], report) => {
      const reportGroup = acc.find((entry) => entry?.[0].groveID === report.groveID);
      if (!reportGroup) {
        acc.push([report]);
      } else {
        reportGroup.push(report);
      }
      return acc;
    }, []);
    return reportGroups;
  }, [sortedReports]);

  const reportsToShow = useMemo(() => (isShowMore ? sortedReportsGroups : sortedReportsGroups.slice(0, REPORT_ITEMS_COUNT)), [sortedReportsGroups, isShowMore]);

  const navigateToMissions = useCallback(() => {
    const missionsLink = `/missions/${selectedFarm.id}/${selectedZone?.id || 'all'}/create/${reportType}`;
    const groveIDs = reports.map((item) => item.groveID);

    navigate(missionsLink, { state: { checkedGroves: groveIDs } });
  }, [navigate, selectedFarm, selectedZone, reportType, reports]);

  const onClickBulletin = useCallback(
    (reportType: EReportType, isExpanded: boolean) => {
      if (context) {
        context.onChangePreselectedBulletin(reportType);
        context?.onExpandBulletin(!isExpanded ? reportType : null);
      }
    },
    [context]
  );

  const showMore = (event) => {
    event.stopPropagation();
    setIsShowMore((prev) => !prev);
  };

  const icon = useMemo(() => {
    switch (reportType) {
      case EReportType.DedicatedVisit:
        return <FontAwesomeIcon icon={faExclamation} size="lg" />;
      case EReportType.TargetedScouting:
        return <TargetedScoutingIcon />;
      case EReportType.Height:
        return <FontAwesomeIcon icon={faCut} size="lg" />;
      case EReportType.FreeArea:
        return <TreeHedgingIcon />;
      case EReportType.Weeds:
        return <WeedIcon />;
      case EReportType.Cipo:
        return <FontAwesomeIcon icon={faWaveSine} size="lg" />;
      case EReportType.Replant:
        return <FontAwesomeIcon icon={faSeedling} size="lg" />;
      default:
        return <></>;
    }
  }, [reportType]);

  const [showSettingsModal, hideSettingsModal] = useModal(
    () => (
      <Modal closeOnBackdrop width="1080px" onClose={hideSettingsModal}>
        <BulletinModal reportType={reportType} onClose={hideSettingsModal} />
      </Modal>
    ),
    [reportType]
  );

  return (
    <>
      <Wrapper onClick={() => onClickBulletin(reportType, isExpanded)}>
        <SidebarBlockTitle
          icon={icon}
          onExpandedChange={(event) => {
            event.stopPropagation();
            showSettingsModal();
          }}
          content={<BulletinTitle reports={reports} reportsCount={reportsCount} reportType={reportType} selectedGrove={selectedGrove} />}
          type={ETitleType.Bulletin}
          reportType={reportType}
        />
        {isExpanded && (
          <SidebarBlockContent onClick={(e) => e.stopPropagation()}>
            <SidebarBlockControl>
              <Button onClick={navigateToMissions} text={t('main.bulletin.create_mission')} />
            </SidebarBlockControl>
            {reportsToShow.map((reports, index) => (
              <SidebarBlockItem key={index}>
                <BulletinItem reports={reports} reportType={reportType} metricType={metricType} groves={groves} onGroveClicked={onGroveClicked} />
              </SidebarBlockItem>
            ))}
            {sortedReportsGroups.length > REPORT_ITEMS_COUNT && (
              <SidebarBlockShowMore isShowMore={isShowMore} onToggle={(e) => showMore(e)} itemsRemaining={sortedReportsGroups.length - REPORT_ITEMS_COUNT} />
            )}
          </SidebarBlockContent>
        )}
      </Wrapper>
    </>
  );
};

export default Bulletin;
