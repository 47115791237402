import { ERichItemType, NDVI_TRESHOLD_LOW, NDVI_TRESHOLD_LOWEST, NDVI_TRESHOLD_LOWER, NDVI_TRESHOLD_MEDIUM, NDVI_TRESHOLD_HIGH } from 'models/richItem';
import { EReportType } from 'models/report';

interface IColorValue {
  fill: string;
  border: string;
}

export const COLORS: { [key: string]: IColorValue } = {
  error: {
    fill: '#dff9fb',
    border: '#c7ecee'
  },
  missing: {
    fill: '#616161',
    border: '#8d8d8d'
  },
  missingLight: {
    fill: 'transparent',
    border: '#696969'
  },
  worst: {
    fill: '#bbbbbb',
    border: '#7a7a7a'
  },
  bad: {
    fill: '#ff2020',
    border: '#991414'
  },
  normal: {
    fill: '#fe9b3d',
    border: '#995d25'
  },
  good: {
    fill: '#A2BD42',
    border: '#758830'
  },
  best: {
    fill: '#246b2b',
    border: '#274d32'
  },
  replant: {
    fill: '#1F97A2',
    border: '#1AB5C3'
  },
  unpublished: {
    fill: 'transparent',
    border: '#000'
  },
  default: {
    fill: 'transparent',
    border: '#fff'
  }
};

export const BLIND_COLORS = {
  ...COLORS,
  missing: {
    fill: '#2e2e2e',
    border: '#d5d5d5'
  },
  worst: {
    fill: '#cb1f1f',
    border: '#590000'
  },
  bad: {
    fill: '#ff6162',
    border: '#8b2221'
  },
  normal: {
    fill: '#fafaaf',
    border: '#a8a86f'
  },
  good: {
    fill: '#acd9ea',
    border: '#7099a8'
  },
  best: {
    fill: '#2c7ab6',
    border: '#124369'
  },
  replant: {
    fill: '#a7a89d',
    border: '#677174'
  }
};

export const SCORE_COLORS = {
  '-1': COLORS.unpublished,
  0: COLORS.missing,
  1: COLORS.worst,
  2: COLORS.bad,
  3: COLORS.normal,
  4: COLORS.good,
  5: COLORS.best,
  6: COLORS.replant
};

export const SCORE_BLIND_COLORS = {
  '-1': COLORS.unpublished,
  0: BLIND_COLORS.missing,
  1: BLIND_COLORS.worst,
  2: BLIND_COLORS.bad,
  3: BLIND_COLORS.normal,
  4: BLIND_COLORS.good,
  5: BLIND_COLORS.best,
  6: BLIND_COLORS.replant
};

export const CIPO_SCORE_COLORS = {
  0: '#009800',
  3: '#F2DC6A',
  6: '#FF8800',
  10: '#F83500'
};

type TRichItemColorSet = { [key: string | number]: string };

export const FREE_AREA_SCORE_COLORS: TRichItemColorSet = {
  0: '#F83500',
  1: '#FF8800',
  2: '#F2DC6A',
  3: '#009800'
};

export const HEIGHT_SCORE_COLORS: TRichItemColorSet = {
  0: '#9C00E5',
  1: '#004BFC',
  2: '#5CD9FF',
  3: '#FFC12D',
  4: '#EEAA56',
  5: '#B16E08'
};

export const GROWTH_SCORE_COLORS: TRichItemColorSet = {
  0: '#8f04dc',
  15: '#2d7aff',
  30: '#7dd7ff',
  45: '#ffce97',
  60: '#ff8600'
};

export const VOLUME_SCORE_COLORS: TRichItemColorSet = {
  0: '#9C00E5',
  25: '#004BFC',
  50: '#5CD9FF',
  75: '#FFC12D',
  100: '#EEAA56',
  125: '#B16E08'
};

export const CANOPY_AREA_SCORE_COLORS: TRichItemColorSet = {
  0: '#9C00E5',
  5: '#004BFC',
  10: '#5CD9FF',
  15: '#FFC12D',
  20: '#EEAA56',
  25: '#B16E08'
};

export const FRUIT_YIELD_SCORE_COLORS: TRichItemColorSet = {
  0: '#E11282',
  400: '#6999AD',
  800: '#F8965F'
};

export const NDVI_SCORE_COLORS: TRichItemColorSet = {
  0: '#f7fbff',
  [NDVI_TRESHOLD_LOWEST]: '#d1e3f3',
  [NDVI_TRESHOLD_LOW]: '#9ac8e1',
  [NDVI_TRESHOLD_LOWER]: '#529dcc',
  [NDVI_TRESHOLD_MEDIUM]: '#1c6cb1',
  [NDVI_TRESHOLD_HIGH]: '#08306b'
};

export const WEEDS_SCORE_COLORS = {
  0: '#009800',
  30: '#F2DC6A',
  60: '#FF8800',
  90: '#F83500'
};

export const FRUIT_YIELD_COUNT_COLORS = FRUIT_YIELD_SCORE_COLORS;

export const FRUIT_YIELD_SIZES_COLORS = ['#A47DCB', '#88BB76', '#4385F3', '#66B0C0', '#CB7DA2'];

export const MAP_SELECTED_CLUSTER_LINE_COLORS = { disclaimer: '#f1e4cc', cipoDataset: '#5b2c6f', clorosisDataset: '#ffde34' };

export const RICH_ITEM_TYPES_COLORS: { [key: string]: TRichItemColorSet } = {
  [ERichItemType.Cipo]: CIPO_SCORE_COLORS,
  [ERichItemType.FreeArea]: FREE_AREA_SCORE_COLORS,
  [ERichItemType.Height]: HEIGHT_SCORE_COLORS,
  [ERichItemType.Volume]: VOLUME_SCORE_COLORS,
  [ERichItemType.CanopyArea]: CANOPY_AREA_SCORE_COLORS,
  [ERichItemType.Ndvi]: NDVI_SCORE_COLORS,
  [ERichItemType.TreeGrowth]: GROWTH_SCORE_COLORS,
  [ERichItemType.Weeds]: WEEDS_SCORE_COLORS
};

export const MISSION_COLORS = {
  [EReportType.Cipo]: CIPO_SCORE_COLORS
};

export const MAPBOX_SUPPORTED_COLORS = ['fill-color', 'circle-color', 'line-color'];
