import { keyframes } from 'styled-components';

export const ExpandAnimation = keyframes`
  0% {
    transform: scaleY(0)
  }
  100% {
    transform: scaleY(1)
  }
`;

export default ExpandAnimation;
