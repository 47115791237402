import { Map, GeoJSONSource, ExpressionName } from 'mapbox-gl';
import * as turf from '@turf/turf';

import { IGrove } from 'models/grove';
import { TMappedStatistic } from 'models/statistic';

import { getMapSourceById, addLayerToMap, reorderLayers, REPLANT_CLUSTERS_LAYERS, LAYERS_PRIORITY } from 'services/util/mapHelpers';

import { mfColors } from 'vars';
import { Feature } from '@turf/turf';

const SOURCE_NAME = 'replant-cluster';

export const setReplantsClusterSource = (map: Map, features: Feature[]): void => {
  const source = getMapSourceById(map, SOURCE_NAME);
  if (source) {
    (source as GeoJSONSource).setData(turf.featureCollection(features) as any);
  }
};

export const addReplantClustersLayers = (map: Map): void => {
  if (!getMapSourceById(map, SOURCE_NAME)) {
    map.addSource(SOURCE_NAME, {
      data: turf.featureCollection([]),
      type: 'geojson',
      cluster: true,
      clusterMaxZoom: 13,
      clusterRadius: 56,
      clusterProperties: {
        plantedTrees: ['+', ['get', 'plantedTrees']]
      }
    });
  }

  addLayerToMap(map, REPLANT_CLUSTERS_LAYERS[0], {
    id: REPLANT_CLUSTERS_LAYERS[0],
    type: 'circle',
    source: SOURCE_NAME,
    filter: ['has', 'point_count'],
    paint: {
      'circle-color': mfColors.white,
      'circle-radius': ['step', ['get', 'point_count'], 22, 2, 25, 3, 28],
      'circle-translate': [0, 28]
    }
  });

  addLayerToMap(map, REPLANT_CLUSTERS_LAYERS[1], {
    id: REPLANT_CLUSTERS_LAYERS[1],
    type: 'circle',
    source: SOURCE_NAME,
    filter: ['!', ['has', 'point_count']],
    paint: {
      'circle-color': mfColors.white,
      'circle-radius': 22,
      'circle-translate': [0, 28]
    }
  });

  addLayerToMap(map, REPLANT_CLUSTERS_LAYERS[2], {
    id: REPLANT_CLUSTERS_LAYERS[2],
    type: 'symbol',
    source: SOURCE_NAME,
    filter: ['has', 'point_count'],
    paint: {
      'text-translate': [0, 28]
    },
    layout: {
      'text-field': ['number-format' as ExpressionName, ['get', 'plantedTrees'], { 'max-fraction-digits': 1 }],
      'text-size': 16
    }
  });

  addLayerToMap(map, REPLANT_CLUSTERS_LAYERS[3], {
    id: REPLANT_CLUSTERS_LAYERS[3],
    type: 'symbol',
    source: SOURCE_NAME,
    filter: ['!', ['has', 'point_count']],
    paint: {
      'text-translate': [0, 28]
    },
    layout: {
      'text-field': ['number-format' as ExpressionName, ['get', 'plantedTrees'], { 'max-fraction-digits': 1 }],
      'text-size': 14
    }
  });

  reorderLayers(
    map,
    LAYERS_PRIORITY.map((layerName, index) => ({
      zIndex: index,
      name: layerName
    }))
  );
};

export const addReplantsClustersMarkers = (map: Map | null, groves: IGrove[], capacityStatistic: TMappedStatistic | null) => {
  if (!map) {
    return;
  }

  const features = groves.reduce((acc, grove) => {
    const groveStatistic = capacityStatistic?.[grove.id];
    const plantedTrees = groveStatistic?.statistic as number;

    if (!plantedTrees) return acc;

    return acc.concat(turf.centerOfMass(grove.geometry, { properties: { id: grove.id, plantedTrees } }));
  }, [] as Feature[]);

  setReplantsClusterSource(map, features);
};
