import React, { useMemo, useCallback } from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import signInHooks from 'hooks/signIn.hooks';
import usersHooks from 'hooks/users.hooks';
import { useUpdateUserAlerts } from 'services/data/users';
import { updateUnreadAlertsCount } from 'redux/auth/authSlice';

import LocalLoader from 'atomicComponents/LocalLoader';
import NotificationsView from 'components/notifications/NotificationsView';
import { mfColors } from 'vars';
import farmsHooks from 'hooks/farms.hooks';

const Container = styled.div`
  padding-left: 16px;
  margin-top: 49px;
  z-index: 3;
  text-align: left;
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
`;

const Title = styled.div`
  font-weight: bold;
  font-size: 24px;
  position: relative;
  margin-right: 16px;
  padding-bottom: 10px;
  border-bottom: 1px solid ${mfColors.grey};
`;

const farmLink = (params) => {
  const { farmID } = params;

  return `/map/${farmID}`;
};

const Notifications = () => {
  const user = signInHooks.useSignedInUser();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const updateUserAlerts = useUpdateUserAlerts();
  const { selectedFarm } = farmsHooks.useSelectedFarmEntities();

  const userID = useMemo(() => user?.id, [user?.id]);
  const { data, isLoading } = usersHooks.useListenUserAlerts(userID, selectedFarm?.id);

  const handleClickNotification = useCallback(
    (notification) => {
      const { id, params, read } = notification;

      if (!read) {
        updateUserAlerts(id);

        dispatch(updateUnreadAlertsCount());
      }

      if (params) {
        navigate(farmLink(params));
      }
    },
    [updateUserAlerts, navigate, dispatch]
  );

  return (
    <Container>
      <Title>{t('notifications.title')}</Title>
      {isLoading ? <LocalLoader /> : <NotificationsView farmName={selectedFarm?.name} notifications={data} handleClickNotification={handleClickNotification} />}
    </Container>
  );
};

export default Notifications;
