import { useEffect, useState } from 'react';
import { useFarmMissionsConfig } from 'services/data/farms';
import { IPestsP3Config } from 'models/missions-config';

export const BUDS = {
  title: 'buds',
  id: 'buds_count'
};

export const DEFAULT_PESTS_P3_SETTINGS = [
  {
    title: 'diaphorina',
    subtitle: 'adult_tree',
    threshold: 0,
    id: 'diaphorina_adult_count',
    count: 1
  },
  {
    title: 'diaphorina',
    subtitle: 'nymph_buds',
    threshold: 0,
    id: 'diaphorina_nymph',
    count: 3
  },
  {
    title: 'tamarixia',
    nonAction: true,
    threshold: 10,
    id: 'diaphorina_tamarixia',
    count: 1
  },
  {
    title: 'leaf_miner',
    threshold: 15,
    id: 'leaf_miner',
    count: 3
  },
  {
    title: 'cirropilus',
    nonAction: true,
    threshold: 30,
    id: 'leaf_miner_cirropilus',
    count: 3
  },
  {
    title: 'ageniaspis',
    nonAction: true,
    threshold: 30,
    id: 'leaf_miner_ageniaspis',
    count: 3
  },
  {
    title: 'leaf_mites',
    subtitle: 'red_leaves',
    threshold: 5,
    id: 'leaf_mites_red',
    count: 3
  },
  {
    title: 'leaf_mites',
    subtitle: 'texana_leaves',
    threshold: 15,
    id: 'leaf_mites_texana',
    count: 3
  },
  {
    title: 'predatory_mite',
    nonAction: true,
    threshold: 30,
    id: 'leaf_mites_predatory_mite',
    count: 1
  },
  {
    title: 'acaro_negrilla',
    subtitle: 'acaro_leaves',
    threshold: 30,
    id: 'acaro_negrila_three_leaves',
    count: 3
  },
  {
    title: 'acaro_negrilla',
    subtitle: 'acaro_fruits',
    threshold: 20,
    id: 'acaro_negrila_three_new_fruits',
    count: 3
  },
  {
    title: 'acaro_negrilla',
    subtitle: 'acaro_dev_fruits',
    threshold: 10,
    id: 'acaro_negrila_three_developed_fruits',
    count: 3
  },
  {
    title: 'acaro_negrilla',
    subtitle: 'acaro_ripe_fruits',
    threshold: 5,
    id: 'acaro_negrila_three_ripe_fruits',
    count: 3
  },
  {
    title: 'leprosis_mite',
    subtitle: 'two_fruits',
    threshold: 15,
    id: 'leprosis_mite_two_fruits',
    count: 2
  },
  {
    title: 'leprosis_mite',
    subtitle: 'seminue_branch',
    threshold: 15,
    id: 'leprosis_mite_two_seminue_branch',
    count: 2
  },
  {
    title: 'leprosis_mite',
    subtitle: 'internal_fruit_last_season',
    threshold: 10,
    id: 'leprosis_mite_two_internal_fruit',
    count: 2
  },
  {
    title: 'white_mite',
    subtitle: 'three_leaves',
    threshold: 10,
    id: 'white_mite_three_leaves',
    count: 3
  },
  {
    title: 'white_mite',
    subtitle: 'mite_small_fruits',
    threshold: 10,
    id: 'white_mite_three_new_small_fruits',
    count: 3
  },
  {
    title: 'white_mite',
    subtitle: 'mite_new_fruits',
    threshold: 10,
    id: 'white_mite_three_new_fruits',
    count: 3
  },
  {
    title: 'white_mite',
    subtitle: 'mite_dev_fruits',
    threshold: 10,
    id: 'white_mite_three_new_developed_fruits',
    count: 3
  },
  {
    title: 'thrips',
    subtitle: 'thrips_nymph',
    threshold: 10,
    id: 'thrips_nymph',
    count: 3
  },
  {
    title: 'thrips',
    subtitle: 'adult_flowers',
    threshold: 10,
    id: 'thrips_adult',
    count: 3
  },
  {
    title: 'fruit_fly',
    subtitle: 'damage_fruits',
    threshold: 10,
    id: 'fruit_fly_damage',
    count: 3
  },
  {
    title: 'fruit_fly',
    subtitle: 'fly',
    threshold: 10,
    id: 'fruit_fly_fly',
    count: 1
  },
  {
    title: 'bug',
    threshold: 10,
    id: 'bug_tree',
    count: 1
  },
  {
    title: 'lacewing',
    threshold: 25,
    id: 'lacewing_larva_and_adult',
    count: 1
  },
  {
    title: 'coccinellids',
    threshold: 25,
    id: 'coccinellids_larva_and_adult',
    count: 1
  },
  {
    title: 'weevil',
    threshold: 10,
    id: 'weevil_tree',
    count: 1
  },
  {
    title: 'locust',
    threshold: 10,
    id: 'locust_tree',
    count: 1
  },
  {
    title: 'grasshopper',
    threshold: 10,
    id: 'grasshopper_tree',
    count: 1
  },
  {
    title: 'mealybug',
    threshold: 10,
    id: 'mealybug_tree',
    count: 1
  },
  {
    title: 'citrus_blackfly',
    threshold: 10,
    id: 'citrus_blackfly_tree',
    count: 1
  },
  {
    title: 'ant',
    subtitle: 'arriera_bed',
    threshold: 10,
    id: 'ant_arriera',
    count: 1
  },
  {
    title: 'ant',
    subtitle: 'fire_ants',
    threshold: 10,
    id: 'ant_fire_ants',
    count: 1
  },
  {
    title: 'scale',
    subtitle: 'snow_scale',
    threshold: 10,
    id: 'scale_snow_scale',
    count: 1
  },
  {
    title: 'scale',
    subtitle: 'red_scale',
    threshold: 10,
    id: 'scale_red_scale',
    count: 1
  },
  {
    title: 'symptom_leprosis',
    subtitle: 'expanded_bud',
    threshold: 10,
    id: 'symptom_of_leprosis_expanded_bud',
    count: 1
  },
  {
    title: 'symptom_leprosis',
    subtitle: 'internal_fruit',
    threshold: 10,
    id: 'symptom_of_leprosis_internal_fruit',
    count: 1
  }
];

const useGetPestsP3Settings = (farmID?: string) => {
  const [pestsP3Settings, setPestsP3Settings] = useState<IPestsP3Config[]>([]);
  const getFarmMissionsConfig = useFarmMissionsConfig();

  useEffect(() => {
    if (farmID) {
      getFarmMissionsConfig(farmID).then((config) => {
        if (config && config.pestsP3) {
          setPestsP3Settings(config.pestsP3);
        } else {
          setPestsP3Settings(DEFAULT_PESTS_P3_SETTINGS);
        }
      });
    }
  }, [farmID, getFarmMissionsConfig]);

  return { pestsP3Settings, setPestsP3Settings };
};

const missionConfigHooks = {
  useGetPestsP3Settings
};

export default missionConfigHooks;
