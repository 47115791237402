export type TTerrainGeometryType = 'MultiPolygon';

export interface ITerrainFeature {
  geometry: {
    type: TTerrainGeometryType;
    coordinates: [number, number][][][];
  };
  properties: { [key: string]: string | number };
  type: 'Feature';
}

export interface ITerrainGeojson {
  geojson: {
    crs?: {
      type: string;
      properties: { [key: string]: string | number };
    };
  };
  features: ITerrainFeature[];
  type: 'FeatureCollection';
}

export const CUSTOM_TERRAIN_FARMS = [
  '-L6yjkdV3YeIOQgd-kjo' // RIO BRAVO
];
