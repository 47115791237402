import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { IFarm } from 'models/farm';
import { IGrove } from 'models/grove';
import { EReportType, TAnyReport, IHeightReport, IFreeAreaReport, ICipoReport, IWeedsReport } from 'models/report';
import { EMeasurementSystem } from 'models/region';
import BarChart from 'components/shared/BarChart';
import numberUtils from 'utils/numbers';
import { getFarmBulletinThresholds } from 'redux/bulletin/bulletinSlice';

interface IColorConfig {
  backgroundColor: string;
  borderColor: string;
}

interface IDatasetEntry {
  value: number;
  groveID: string;
}

interface IProps {
  farm: IFarm;
  reports: TAnyReport[];
  groves: IGrove[];
  reportType: EReportType;
  metricType?: EMeasurementSystem;
}

const reportColorsMap: Map<EReportType, IColorConfig> = new Map()
  .set(EReportType.FreeArea, { backgroundColor: '#43be75', borderColor: '#43be75' })
  .set(EReportType.Height, { backgroundColor: '#AE3C00', borderColor: '#AE3C00' })
  .set(EReportType.Weeds, { backgroundColor: '#efdc7b', borderColor: '#efdc7b' })
  .set(EReportType.Cipo, { backgroundColor: '#ff6bf8', borderColor: '#ff6bf8' });

const BulletinChart = ({ farm, reportType, reports, groves, metricType }: IProps): JSX.Element | null => {
  const { t } = useTranslation();
  const farmBulletinThresholds = useSelector(getFarmBulletinThresholds);
  const axisTitle = useMemo(() => {
    let yTitle = '';
    switch (reportType) {
      case EReportType.Height:
        yTitle = `${t('main.bulletin.height')}, ${metricType === EMeasurementSystem.Metric ? 'm' : 'ft'}`;
        break;
      case EReportType.Cipo:
        yTitle = t('main.bulletin.CIPO_percentage');
        break;
      case EReportType.FreeArea:
        yTitle = `${t('main.bulletin.free_area')}, ${metricType === EMeasurementSystem.Metric ? 'm' : 'ft'}`;
        break;
      case EReportType.Weeds:
        yTitle = t('main.bulletin.weeds');
        break;
      default:
        yTitle = '';
    }

    return {
      x: t('main.bulletin.grove'),
      y: yTitle
    };
  }, [reportType, metricType, t]);
  const sortedReports = useMemo((): IDatasetEntry[] => {
    const result = reports
      .map((report) => {
        const thresholdValue = (farmBulletinThresholds[reportType]?.value as number) || null;
        let value;
        switch (reportType) {
          case EReportType.Height:
            value = numberUtils.convertReportValue(+(report as IHeightReport).Height, reportType, thresholdValue, 1);
            break;
          case EReportType.FreeArea:
            value = numberUtils.convertReportValue(+(report as IFreeAreaReport).freeArea_avg, reportType, thresholdValue, 1);
            break;
          case EReportType.Cipo:
            value = numberUtils.convertReportValue((report as ICipoReport).cipo_rate * 100, reportType, thresholdValue, 2);
            break;
          case EReportType.Weeds:
            value = numberUtils.normalizeDecimal(
              (+(report as IWeedsReport)._30_60cm_percentage + +(report as IWeedsReport)._60_90cm_percentage + +(report as IWeedsReport)._90cm_above_percentage) * 100
            );
            break;
          default:
            value = 0;
        }

        return {
          ...report,
          value
        };
      })
      .sort((a, b) => (reportType === EReportType.FreeArea ? a.value - b.value : b.value - a.value));

    return result;
  }, [reports, reportType, farmBulletinThresholds]);

  const labels = useMemo(() => sortedReports.map((report) => groves.find((grove) => grove.id === report.groveID)?.name || ''), [sortedReports, groves]);

  const datasets = useMemo(() => {
    const colors = reportColorsMap.get(reportType);
    const data = sortedReports.map((report: IDatasetEntry) => report.value);

    if (!colors) return null;

    return [
      {
        ...colors,
        data
      }
    ];
  }, [reportType, sortedReports]);

  return datasets ? <BarChart title={farm.name} labels={labels} datasets={datasets} axisTitle={axisTitle} /> : null;
};

export default BulletinChart;
