export const SOUTH_AFRICA_REGION_NAME = 'South Africa';
export const CALIFORNIA_REGION_NAME = 'California';

export enum EMeasurementSystem {
  Metric = 'metric',
  Imperial = 'imperial'
}

export interface IRegion {
  id: string;
  measurementSystem: EMeasurementSystem;
  name: string;
}

export type IRegionIncomplete = Omit<IRegion, 'id'>;
