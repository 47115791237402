import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';

import { mfColors } from 'vars';

const Wrapper = styled.div`
  position: relative;
  padding: 20px 0;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: ${mfColors.darkBlue};
    opacity: 0.1;
  }
`;

type TProps = PropsWithChildren<{}>;

const SidebarBlockItem = ({ children }: TProps): JSX.Element => <Wrapper>{children}</Wrapper>;

export default SidebarBlockItem;
