import React, { useState, useEffect, useRef, useCallback, useLayoutEffect, Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faTimes } from '@fortawesome/pro-solid-svg-icons';

import { SpringAnimation } from 'atomicComponents/animations';
import EKeyCode from 'models/keyboard';

import { mfColors } from 'vars';

const HEIGHT = 36;
const INPUT_BORDER_WIDTH = 2;

const Wrapper = styled.div`
  position: relative;
  padding-right: 34px;

  &:hover {
    cursor: pointer;
  }
`;

interface IExpandableProps {
  isExpanded: boolean;
}

interface IInputProps extends IExpandableProps {
  width: string;
}

const expandedInput = `
  padding: 0 80px 0 20px;
  opacity: 1;
  cursor: text;
  border: ${INPUT_BORDER_WIDTH}px solid ${mfColors.lightBlue};
`;

const expandedToggle = `
  background: rgba(156, 204, 242, 0.5);
  z-index: 6;
  box-shadow: none;
`;

const Toggle = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  height: ${HEIGHT}px;
  width: ${HEIGHT}px;
  margin: auto;
  top: ${INPUT_BORDER_WIDTH}px;
  right: ${INPUT_BORDER_WIDTH}px;
  width: ${HEIGHT - INPUT_BORDER_WIDTH * 2}px;
  height: ${HEIGHT - INPUT_BORDER_WIDTH * 2}px;
  transition: all 1s;
  background: ${mfColors.inputBackground};
  border: 2px solid transparent;
  box-sizing: border-box;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 5;

  ${({ isExpanded }: IExpandableProps) => isExpanded && expandedToggle}
`;

const Input = styled.input`
  font-family: 'Montserrat', monospace;
  position: absolute;
  margin: auto;
  top: 0;
  right: 0;
  outline: none;
  background: ${mfColors.inputBackground};
  border-radius: 100px;
  transition: all 1s;
  opacity: 0;
  z-index: 5;
  font-size: 16px;
  line-height: 20px;
  color: ${mfColors.darkGrey};

  ${({ isExpanded }: IInputProps) => isExpanded && expandedInput}
  width: ${({ isExpanded, width }: IInputProps) => (isExpanded ? width : '36px')};
  height: 36px;

  &::placeholder {
    color: #a4a4a4;
    opacity: 1;
    font-size: 16px;
    line-height: 20px;
  }
`;

interface ISuggestionsProps {
  width: string;
}

const SuggestionsWrapper = styled.div`
  position: absolute;
  top: ${HEIGHT + 4}px;
  right: 0;
  width: ${({ width }: ISuggestionsProps) => width};
  animation: ${SpringAnimation} 300ms ease-in-out forwards;
  transform-origin: top center;
  background: ${mfColors.navBarBackground};
  box-shadow: -5px -5px 10px ${mfColors.cardShadowGrey}, 5px 5px 10px ${mfColors.cardShadowGrey};
  border-radius: 8px;
  padding: 16px 24px;
  z-index: 2;
  cursor: pointer;
`;

const Title = styled.span`
  font-size: 28px;
  line-height: 34px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-weight: bold;
`;

const SearchWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;
interface IProps {
  name: string;
  onSearchChange: (searchText: string) => void;
  showSuggestions: boolean;
  setShowSuggestions: Dispatch<SetStateAction<boolean>>;
  onGoToSuggestions?: () => void;
  onExpandedStateChange?: (showSuggestions: boolean) => void;
  inputWidth?: string;
  suggestions?: JSX.Element;
  title: string;
}

const SearchBar = ({ name, onExpandedStateChange, showSuggestions, onGoToSuggestions, setShowSuggestions, onSearchChange, suggestions, inputWidth = '323px', title }: IProps) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [searchText, setSearchText] = useState<string>('');
  const controlRef = useRef<HTMLDivElement>(null);

  const handleClickOutside = useCallback(
    (e: MouseEvent) => {
      if (controlRef.current && e.target && !controlRef.current.contains(e.target as HTMLElement)) {
        setShowSuggestions(false);
      }
    },
    [setShowSuggestions, controlRef]
  );

  const handleArrowDown = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.keyCode === EKeyCode.ArrowDown) {
        e.preventDefault();
        onGoToSuggestions && onGoToSuggestions();
      }
    },
    [onGoToSuggestions]
  );

  useEffect(() => {
    onExpandedStateChange && onExpandedStateChange(showSuggestions);
  }, [onExpandedStateChange, showSuggestions]);

  useEffect(() => {
    if (showSuggestions && inputRef.current) {
      setSearchText('');
      inputRef.current.focus();
    }
  }, [inputRef, showSuggestions]);

  useEffect(() => {
    onSearchChange(searchText);
  }, [onSearchChange, searchText]);

  useLayoutEffect(() => {
    window.addEventListener('click', handleClickOutside, true);
    return () => {
      window.removeEventListener('click', handleClickOutside, true);
    };
  }, [handleClickOutside]);

  return (
    <Wrapper ref={controlRef}>
      <Input
        value={searchText}
        onKeyDown={handleArrowDown}
        onChange={(e: React.FormEvent<HTMLInputElement>) => setSearchText(e.currentTarget.value)}
        ref={inputRef}
        name={name}
        isExpanded={showSuggestions}
        width={inputWidth}
      />
      {showSuggestions && (
        <SuggestionsWrapper onClick={() => setShowSuggestions(false)} width={inputWidth}>
          {suggestions || <div>No Farms Found</div>}
        </SuggestionsWrapper>
      )}
      <SearchWrapper onClick={() => setShowSuggestions((expanded) => !expanded)}>
        <Title>{title}</Title>
        <Toggle isExpanded={showSuggestions}>
          <FontAwesomeIcon icon={showSuggestions ? faTimes : faSearch} className="fa-sm" />
        </Toggle>
      </SearchWrapper>
    </Wrapper>
  );
};

export default SearchBar;
