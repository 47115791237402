import React, { useMemo } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import missionUtils, { EDateRange } from 'utils/mission';
import numberUtils from 'utils/numbers';

import MissionIcon from 'components/missions/MissionIcon';
import MissionProgress from 'components/missions/MissionProgress';
import DataTable from 'atomicComponents/DataTable';

import { IGrove } from 'models/grove';
import { EMeasurementSystem } from 'models/region';
import { IMission, EMissionStatus } from 'models/mission';

import { mfColors } from 'vars';
import UpdatedAtCell from 'components/missions/Table/UpdatedAtCell';

const TableWrapper = styled.div`
  max-height: 200px;
  overflow: auto;
`;

const TablePlaceholder = styled.div`
  margin: 36px 0 36px 36px;
  border-bottom: 1px solid ${mfColors.grey};
  padding: 8px 4px;
  width: 100%;
`;

const TableCell = styled.div`
  background: ${mfColors.white};
  padding: 4px;
  min-height: 32px;
  font-size: 12px;
  line-height: 24px;
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;

  &.left {
    text-align: left;
  }

  &.no-bg {
    background: none;
  }
`;

const Total = styled.div`
  font-family: MontserratBold;
  font-size: 14px;
  padding: 8px 0;
  margin: 8px 0 0 36px;
  border-bottom: 2px solid ${mfColors.darkGrey};
  display: flex;
  flex-direction: row;

  .title {
    width: 256px;
    padding: 0 4px;
    margin: 0 4px 0 0;
  }

  .blocks {
    width: 160px;
    text-align: center;

    &.offset {
      margin-left: 180px;
    }
  }

  .progress {
    width: 180px;
  }
`;

const ClippedText = styled.div`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const ROW_STYLE = {
  border: 'none',
  background: 'none',
  margin: '0 0 4px'
};

const HEADER_STYLE = {
  border: 'none',
  background: 'none',
  fontSize: '12px',
  margin: '0 0 8px'
};

const HEADER_COLUMN_STYLE = {
  textTransform: 'none',
  minHeight: '0px',
  padding: '0 2px'
};

interface IProps {
  selectedDateRange: EDateRange;
  groves: IGrove[];
  missions: IMission[];
  metricType: EMeasurementSystem;
}

const MissionsTable = ({ groves, selectedDateRange, missions, metricType }: IProps): JSX.Element => {
  const { t } = useTranslation();

  const totalResolvedGroves = useMemo(() => {
    const result = missions.reduce((acc: IGrove[], mission: IMission) => {
      const groveIDs = Object.keys(mission.groves).filter((groveID) => mission.groves[groveID] === EMissionStatus.Done);
      const resolvedGroves = groveIDs.map((groveID) => groves.find((grove) => grove.id === groveID) as IGrove).filter((grove) => !!grove);
      return [...acc, ...resolvedGroves];
    }, []);
    return result;
  }, [missions, groves]);

  const totalAffectedGroves = useMemo(() => {
    const result = missions.reduce((acc: IGrove[], mission: IMission) => {
      const groveIDs = Object.keys(mission.groves);
      const totalGroves = groveIDs.map((groveID) => groves.find((grove) => grove.id === groveID) as IGrove).filter((grove) => !!grove);
      return [...acc, ...totalGroves];
    }, []);
    return result;
  }, [missions, groves]);

  const totalResolvedArea = useMemo(() => {
    const area = numberUtils.getGrovesArea(totalResolvedGroves, metricType);
    return numberUtils.formatArea(area, 1, metricType);
  }, [totalResolvedGroves, metricType]);

  const totalAffectedArea = useMemo(() => {
    const area = numberUtils.getGrovesArea(totalAffectedGroves, metricType);
    return numberUtils.formatArea(area, 1, metricType);
  }, [totalAffectedGroves, metricType]);

  const totalResolvedAreaForPeriod = useMemo(() => {
    const result = missions.reduce((acc: number, mission: IMission) => {
      const missionResolvedArea = missionUtils.getResolvedAreaForPeriod(mission, selectedDateRange, groves, metricType);
      return acc + missionResolvedArea;
    }, 0);
    return numberUtils.formatArea(result, 1, metricType);
  }, [missions, metricType, selectedDateRange, groves]);

  const tableConfig = useMemo(() => {
    let completedTitle = '';
    if (selectedDateRange === EDateRange.ThisWeek) {
      completedTitle = t('missions.completed_this_weak');
    } else if (selectedDateRange === EDateRange.LastWeek) {
      completedTitle = t('missions.completed_last_weak');
    } else if ([EDateRange.Last30Days, EDateRange.Last90days].includes(selectedDateRange)) {
      completedTitle = t('missions.completed_within_the_last_x_days', { x: selectedDateRange === EDateRange.Last30Days ? 30 : 90 });
    } else if (selectedDateRange === EDateRange.LastYear) {
      completedTitle = t('missions.completed_within_the_last_year');
    }

    const titleColumn = {
      title: '',
      render: (mission: IMission) => (
        <TableCell className="left">
          <ClippedText>{mission.title}</ClippedText>
        </TableCell>
      ),
      key: 'name',
      sortable: false,
      width: '260px',
      style: HEADER_COLUMN_STYLE
    };
    return [
      {
        title: '',
        render: (mission: IMission) => (
          <TableCell>
            <MissionIcon type={mission.type} size="24px" showBackground={false} />
          </TableCell>
        ),
        key: 'icon',
        sortable: false,
        width: '32px',
        style: {
          ...HEADER_COLUMN_STYLE,
          padding: '0px',
          margin: '0 2px 0 0'
        }
      },
      titleColumn,
      {
        title: t('missions.blocks'),
        render: (mission: IMission) => {
          const resolvedGroves = Object.keys(mission.groves).filter((groveID) => mission.groves[groveID] === EMissionStatus.Done).length;
          const affectedGroves = Object.keys(mission.groves).length;
          return <TableCell>{`${resolvedGroves} / ${affectedGroves}`}</TableCell>;
        },
        key: 'groves',
        sortable: false,
        width: '160px',
        style: HEADER_COLUMN_STYLE
      },
      {
        title: t('missions.area'),
        render: (mission: IMission) => {
          const resolvedGroves = groves.filter((grove) => mission.groves[grove.id] === EMissionStatus.Done);
          const affectedGroves = groves.filter((grove) => mission.groves[grove.id]);
          const resolvedArea = numberUtils.formatArea(numberUtils.getGrovesArea(resolvedGroves, metricType), 1, metricType);
          const affectedArea = numberUtils.formatArea(numberUtils.getGrovesArea(affectedGroves, metricType), 1, metricType);
          return <TableCell>{`${resolvedArea} / ${affectedArea}`}</TableCell>;
        },
        key: 'area',
        sortable: false,
        width: '160px',
        style: HEADER_COLUMN_STYLE
      },
      {
        title: t('missions.progress'),
        render: (mission: IMission) => (
          <TableCell>
            <MissionProgress mission={mission} groves={groves} showValue={false} />
          </TableCell>
        ),
        key: 'progress',
        sortable: false,
        width: '180px',
        style: HEADER_COLUMN_STYLE
      },
      {
        title: completedTitle,
        render: (mission: IMission) => {
          const resolvedArea = missionUtils.getResolvedAreaForPeriod(mission, selectedDateRange, groves, metricType);
          return <TableCell>{numberUtils.formatArea(resolvedArea, 1, metricType)}</TableCell>;
        },
        key: 'area_this_week',
        sortable: false,
        width: '220px',
        style: HEADER_COLUMN_STYLE
      },
      {
        title: '',
        render: (mission: IMission) => (
          <TableCell className="no-bg">
            <UpdatedAtCell mission={mission} />
          </TableCell>
        ),
        key: 'info',
        sortable: false,
        width: '24px',
        style: HEADER_COLUMN_STYLE
      }
    ];
  }, [t, groves, selectedDateRange, metricType]);

  const tableProps = useMemo(() => {
    const props = {
      config: tableConfig,
      data: missions,
      placeholder: <TablePlaceholder>{t('missions.no_missions_found')}</TablePlaceholder>,
      showHeader: true,
      showFooter: false,
      rowsPerPage: 0,
      headerStyle: HEADER_STYLE,
      rowStyle: ROW_STYLE
    };
    return props;
  }, [tableConfig, missions, t]);

  return (
    <>
      <TableWrapper>
        <DataTable<IMission> {...tableProps} />
      </TableWrapper>
      {!!missions.length && (
        <Total>
          <div className="title">{t('shared.total')}</div>
          <div className="blocks">{`${totalResolvedGroves.length} / ${totalAffectedGroves.length}`}</div>
          <div className="blocks">{`${totalResolvedArea} / ${totalAffectedArea}`}</div>
          <div className="blocks offset">{totalResolvedAreaForPeriod}</div>
        </Total>
      )}
    </>
  );
};

export default MissionsTable;
