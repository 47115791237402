import React, { useCallback } from 'react';
import styled from 'styled-components';
import { mfColors } from '../vars';

const StyledInput = styled.input`
  width: 100%;
  height: 44px;
  font-size: 16px;
  line-height: 20px;
  background: ${mfColors.inputBackground};
  border: 2px solid ${mfColors.lightGrey};
  box-sizing: border-box;
  padding: 0 38px;
  box-shadow: -10px -10px 20px ${mfColors.white35}, 10px 10px 20px ${mfColors.greyBlue50}, inset -10px -10px 15px ${mfColors.white25},
    inset 10px 10px 15px ${mfColors.lightGreyBlue50};
  border-radius: 100px;
  outline: none;
  &:hover {
    border: 2px solid ${mfColors.lightBlue};
  }
  $:focus {
    border: 2px solid ${mfColors.lightBlue};
  }
  &:placeholder {
    color: ${mfColors.lightGrey};
    opacity: 1;
    font-size: 16px;
    line-height: 20px;
  }
`;

type Props = {
  name: string;
  onChange?: ({ value }: { value: string }) => void;
  onEnterPress?: () => void | Promise<any>;
  onFocus?: ({ target }: { target: EventTarget | null }) => void;
  value?: string;
  isDisabled?: boolean;
  placeholder?: string;
  style?: any;
  type?: string;
  maxLength?: any;
  autofocus?: boolean;
  allowInput?: boolean;
  min?: number;
  max?: number;
  step?: number;
};

const Input = ({
  name,
  onChange = () => {},
  onFocus = () => {},
  onEnterPress = () => {},
  value,
  isDisabled = false,
  placeholder = '',
  style = {},
  type = 'text',
  maxLength = '',
  autofocus = false,
  allowInput = false,
  step,
  min,
  max
}: Props) => {
  const handleEnterPress = (event: any) => {
    if (event.key === 'Enter' && !!onEnterPress) {
      onEnterPress();
    }
  };

  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (type === 'number' && !allowInput) {
        event.preventDefault();
      }
    },
    [type, allowInput]
  );

  const handleChange = ({ target }: { target: any }) => {
    const { value } = target;
    onChange({ value });
  };

  const handleFocus = (event: any) => {
    onFocus && onFocus({ target: event.target });
  };

  return (
    <StyledInput
      name={name}
      value={value}
      readOnly={isDisabled}
      placeholder={placeholder}
      onKeyPress={handleEnterPress}
      onKeyDown={handleKeyDown}
      onChange={handleChange}
      onFocus={handleFocus}
      type={type}
      min={min}
      max={max}
      step={step}
      maxLength={maxLength}
      autoFocus={autofocus}
      style={style}
    />
  );
};

export default Input;
