const mfColors = {
  white: '#FFFFFF',
  black: '#000000',
  red: '#AE2929',
  superRed: '#ff2020',
  alertRed: '#D67C7C',
  warningYellow: '#FFCF53',
  green: '#5FB668',
  lightGreen: '#BEEDC3',
  superLightBlue: '#E4E9ED',
  superLightBlueBg: '#DAE4FE',
  lightBlue: '#4385F3',
  darkerBlue: '#478EF9',
  darkBlue: '#31456a',
  blue: '#005EEC',
  grey: '#A4A4A4',
  darkGrey: '#4A4A4A',
  lightGrey: '#ECF0F3',
  superLightGrey: '#F9F9F9',
  cardShadowGrey: '#BDC8D2',
  black40: 'rgba(0, 0, 0, 0.4)',
  white50: 'rgba(255, 255, 255, 0.5)',
  white35: 'rgba(255, 255, 255, 0.35)',
  white25: 'rgba(255, 255, 255, 0.25)',
  greyBlue50: 'rgba(189, 200, 210, 0.5)',
  grey25: 'rgba(164, 164, 164, 0.25)',
  grey50: 'rgba(164, 164, 164, 0.5)',
  lightGreyBlue50: 'rgba(197, 207, 215, 0.5)',
  inputBackground: 'rgba(228, 233, 237, 0.5)',
  avatarPlaceholderColor: '#b245da',
  scoreBgGrey: '#f0f0f0',
  iconButtonBorder: 'rgba(49, 69, 106, 0.2)',
  notificationBadge: '#D75959',
  timebarColor: 'rgba(67, 133, 243, 0.1)',
  timebarSatelliteColor: 'rgba(74, 74, 74, 0.25)',
  timebarSurveyColor: 'rgba(74, 74, 74, 1)',
  missingDataBackground: '#131415',
  overlayBg: 'rgba(0, 0, 0, 0.3)',
  // tabs
  activeTabBg: 'rgba(49, 69, 106, 0.15)',
  inactiveTabBg: 'rgba(49, 69, 106, 0.04)',
  hoveredTabBg: 'rgba(49, 69, 106, 0.08)',
  // missions
  missionTextColor: 'rgba(174, 41, 41, 0.85)',
  missionBorderColor: 'rgba(174, 41, 41, 0.5)',
  missionFillColor: 'rgba(174, 41, 41, 0.05)',
  // sidebar
  navBarBackground: '#f2f2f2',
  sideBarBackground: '#ececec',
  // scrollbar
  scrollthumbBackground: '#c2c6ca',
  // pests treshold colors
  pestsOk: '#84CA4C',
  pestsAverage: '#FCB242',
  pestsBad: '#FD2B2F',
  // create mission
  missionsGroupBackground: '#EDF0F3',
  // dashbaord
  missingTreeColor: '#BBA1FA'
};

export default mfColors;
