import { TMissionType, EMissionType, EMissionSubtype } from 'models/mission';

interface IFeedVisibility {
  cluster?: boolean;
  grove?: boolean;
  map?: boolean;
  farm?: boolean;
}

interface IFeedUser {
  uid: string;
  email: string;
  displayName: string;
}

export enum EFeedItemType {
  GoCheck = 'goCheck',
  GoCheckInternal = 'goCheckInternal',
  Note = 'note',
  CutTree = 'cutTree',
  Calibration = 'calibration',
  Count = 'count',
  ManualScore = 'manualScore',
  Group = 'group',
  ScannedTree = 'scannedTree',
  ManualReport = 'manualReport',
  AutoReport = 'autoReport',
  InternalRichItem = 'internalRichItem',
  GeoObjectDescription = 'geoObjectDescription',
  ManualReindex = 'manualReindex',
  Commodity = 'commodity',
  Hlb = 'hlb',
  Irrigation = 'irrigation',
  Sampling = 'sampling',
  Disease = 'disease',
  Pests = 'pests',
  PestsP3 = 'pestsP3',
  General = 'general',
  WeeklyTrapMonitoring = 'weeklyTrapMonitoring',
  Clipping = 'clipping',
  PestAndDisease = 'pest&disease'
}

// TODO add migated farm to this object. Remove this config after all reports are migrated
export const MIGRATED_FARMS_FEED = {
  '-Low56U3rq6HRijKmGqb': [EFeedItemType.Count],
  '-MXlZ7I6_d40mBQAQZck': [EFeedItemType.Count]
};

export const NOT_APPLICABLE_STATUSES = ['not_applicable', 'isNotApplicable'];

export enum EFeedStatus {
  Done = 'done',
  InProgress = 'inProgress',
  Rejected = 'rejected'
}

export const NO_PESTS = 'no_pest';
export const NO_DISEASE = 'no_disease';

export enum EHLBStatus {
  NoSymptoms = '0',
  F1 = '1',
  F2 = '2',
  F3 = '3',
  F4 = '4',
  NotApplicable = 'isNotApplicable',
  Sampled = 'sampled'
}

export enum EClippedStatus {
  Clipped = 'clipped',
  NotClipped = 'not_clipped',
  ToDo = 'todo'
}

export const HLB_INFECTED_STATUSES = [EHLBStatus.F1, EHLBStatus.F2, EHLBStatus.F3, EHLBStatus.F4];

type TPestType = 'fruit_fly' | 'red_scale' | 'mealy_bug' | 'bollworm' | 'leaf_hopper' | 'lemon_moth' | 'false_codling_moth_sterile' | 'false_codling_moth_wild';

export interface ICreateFeedIncompleteParams {
  geoObjectType: string;
  type: EFeedItemType;
  createdAt: number;
  updatedAt: number;
  farmID: string;
  zoneID: string;
  groveID: string;
  isReport: boolean;
  reportLocation?: string;
  geoObjectID: string;
  geoObjectName: string | null;
  geometry: string;
  status: EFeedStatus | null;
  isPreassigned?: boolean;
  isFarFromReport: boolean;
  user: IFeedUser;
  hlbStatus?: EHLBStatus;
  title?: string;
  body: string;
  images?: {
    [imageKey: string]: number;
  };
  data?: {
    title?: string;
    text?: string;
    body?: string;
    clippingStatus?: string;
    hlbStatus?: EHLBStatus;
    fruitCountNumber?: number;
    fruitCountStatus?: string;
    pestsType?: string;
    trapType?: TPestType;
    pestsNumber?: number;
    changedBait?: boolean;
    diseaseType?: string;
    bloomMonth?: string;
    isDone?: boolean;
    isNotApplicable?: boolean;
  };
  serialNumber?: string;
}

export interface ICreateFeedParams extends ICreateFeedIncompleteParams {
  missionID: string;
}

export interface IFeed extends ICreateFeedParams {
  id: string;
  appVisibility: number;
  customerID: string;
  internal: boolean;
  labels: string[];
  levelVisibility: IFeedVisibility;
  score: number | null;
  showMyFarm: boolean;
  surveyID: string;
  title: string;
  updatedAt: number;
  serialNumber?: string;
}

export interface IDecoratedFeed extends IFeed {
  fruitCountStatus?: 'isNotApplicable' | 'isCounted' | 'crownTree';
}

export interface IPestsP3FeedData {
  // eslint-disable-next-line camelcase
  buds_count: string;
  // eslint-disable-next-line camelcase
  diaphorina_adult_count?: string | number;
}

export const FEED_LABELS = {
  CIPO: '-LnmIGAN6HcSmlbrGrSD',
  FRUIT: '-LnmgAWMlQW1RKYvKMZf',
  HEALTH: '-LnrF9vgUSy_rbk6qslt',
  HLB: '-LoK5e9xSDey5Js4luvn',
  TREE_METRICS: '-LqGmTkJEk9WWBzi_Ck0',
  DISEASES_AND_PESTS: '-Lro-Cu2_wm2EjVxcRNk',
  IRRIGATION_AND_SOIL: '-Lro-FxLMkZTPvWbI1xy',
  REPOSITORY: '-LrstFRqwhvAxB7NFmB2',
  SPECIAL_MAPS: '-LrstTkLiKN8D0vsxPSH',
  HEIGHT: '-LrwVnqZuov24xe6ke-N',
  VOLUME: '-LrwVpYxZhzbMy7GxjEk',
  DENSITY: '-LrwVq20HNO2rtUyI4lZ',
  REPLANTS: '-Lu8HFfN6ksy1irPd2xA',
  SAMPLING: '-Lu8IwlhKFCuO9mFMRSj',
  NUTRITION: '-Lu8J0Qmd3l1Lbmyq1_r',
  WEEDS: '-Lu8JGaF3eRhCAWMy-i8',
  EXPIRIMENTS: '-LvAO6OhvZ7VfSPL8kVw',
  SPECIAL_REPORT: '-LwAlplKnwEjfLTQNJ5Y',
  SCOUTING: '-LwDLI2vdb6oBjrFnZdK',
  CANOPY_AREA: '-M8jqlj8zwlnClGiJDYR',
  TREE_GROWTH: '-MA6y-zx_OeeIyAeUGuj',
  SUSPECTED_STRESS: '5ssoezfGE7M5QvSYEJAN'
};

export const missionFeedTypeRelation = new Map<TMissionType | EMissionSubtype, EFeedItemType>()
  .set(EMissionType.HLBScouting, EFeedItemType.Hlb)
  .set(EMissionType.GeneralScouting, EFeedItemType.General)
  .set(EMissionType.IrrigationScouting, EFeedItemType.Irrigation)
  .set(EMissionType.Sampling, EFeedItemType.Sampling)
  .set(EMissionType.Clipping, EFeedItemType.Clipping)
  .set(EMissionType.FruitCount, EFeedItemType.Count)
  .set(EMissionSubtype.Pests, EFeedItemType.Pests)
  .set(EMissionSubtype.PestsP3, EFeedItemType.PestsP3)
  .set(EMissionType.WeeklyTrapMonitoring, EFeedItemType.WeeklyTrapMonitoring)
  .set(EMissionSubtype.Disease, EFeedItemType.Disease);

// prettier-ignore
export const HLBSeverityMap = new Map<string, number>()
  .set(EHLBStatus.F1, 1)
  .set(EHLBStatus.F2, 2)
  .set(EHLBStatus.F3, 3)
  .set(EHLBStatus.F4, 4);

export const severityBackgroundMap = new Map<EHLBStatus, string>()
  .set(EHLBStatus.NoSymptoms, '#8CD094')
  .set(EHLBStatus.F1, '#F8F9C9')
  .set(EHLBStatus.F2, '#F4F09C')
  .set(EHLBStatus.F3, '#F0E478')
  .set(EHLBStatus.F4, '#EBD14A')
  .set(EHLBStatus.Sampled, '#b2ffff');
