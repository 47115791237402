import React, { useState, useCallback, useMemo } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { LayersIcon, ScoresIcon, ClustersIcon, ColorblindIcon, TerrainIcon, TopographyIcon } from 'assets/images';

import { ExpandAnimation } from 'atomicComponents/animations';

import { mfColors } from 'vars';
import { ERichItemType } from 'models/richItem';

export interface IGroveLayersFlags {
  irrigation: boolean;
  colorBlind: boolean;
  terrain: boolean;
  scores: boolean;
  clusters: boolean;
  flags: boolean;
  poi: boolean;
}

type TGroveLayer = keyof IGroveLayersFlags;

const CONTROL_BUTTON_DIMENSION = '50px';

const Wrapper = styled.div`
  cursor: pointer;
  position: relative;
`;

interface IControlProps {
  isExpanded: boolean;
}

const Control = styled.div`
  display: flex;
  flex-direction: row;
  align-atems: center;
  justify-content: center;
  width: ${CONTROL_BUTTON_DIMENSION};
  height: ${CONTROL_BUTTON_DIMENSION};
  border-radius: 8px;
  opacity: ${({ isExpanded }: IControlProps) => (isExpanded ? 1 : 0.6)};
`;

interface ILayerTogglesWrapperProps {
  isExpanded: boolean;
}

const LayerTogglesWrapper = styled.div`
  position: absolute;
  left: 0;
  bottom: 40px;
  display: ${({ isExpanded }: ILayerTogglesWrapperProps) => (isExpanded ? 'block' : 'none')};
  animation: ${ExpandAnimation} 300ms ease-in-out forwards;
  transform-origin: bottom;
  background-color: ${mfColors.lightGrey};
  border-radius: 6px 6px 0 0;
`;

const LayerTogglesGroup = styled.div``;

interface ILayerToggleProps {
  selected: boolean;
  disabled?: boolean;
}

const Tooltip = styled.div`
  visibility: hidden;
  position: absolute;
  right: 40px;
  opacity: 0;
  transition: opacity 0.5s;
  transition-delay: 0.1s;
  border-radius: 2px;
`;

const TooltipBody = styled.div`
  height: 25px;
  padding: 0 8px;
  border-radius: 4px;
  font-size: 10px;
  line-height: 25px;
  color: ${mfColors.darkGrey};
  background-color: ${mfColors.lightGrey};
  width: max-content;
  text-transform: uppercase;
`;

const LayerToggle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 34px;
  height: ${CONTROL_BUTTON_DIMENSION};
  margin: 8px;
  position: relative;
  border-bottom: 1px solid ${mfColors.grey};

  > svg {
    max-width: 32px;
    max-height: 32px;
    opacity: ${({ selected }: ILayerToggleProps) => (selected ? 1 : 0.7)};
    fill: ${({ disabled }: ILayerToggleProps) => disabled && mfColors.grey50};
  }

  > span {
    font-size: 10px;
    line-height: 12px;
  }

  &:hover {
    ${Tooltip} {
      visibility: visible;
      opacity: 1;
    }
  }
`;

interface IProps {
  layers: IGroveLayersFlags;
  groveView: boolean;
  onLayersChanged: (setter: (layers: IGroveLayersFlags) => IGroveLayersFlags) => void;
  activeRichItem: ERichItemType;
  isIrrigationSource: boolean;
}

const SidebarControls = ({ layers, groveView, onLayersChanged, activeRichItem, isIrrigationSource }: IProps): JSX.Element => {
  const [isExpanded, setIsExpanded] = useState(false);
  const { t } = useTranslation();
  const toggleLayer = useCallback(
    (layer: TGroveLayer) => {
      onLayersChanged((prev) => ({
        ...prev,
        [layer]: !prev[layer]
      }));
    },
    [onLayersChanged]
  );
  const layerIsActive = useMemo(() => layers.terrain || layers.irrigation, [layers]);

  return (
    <Wrapper onMouseEnter={() => setIsExpanded(true)} onMouseLeave={() => setIsExpanded(false)}>
      <Control isExpanded={isExpanded || layerIsActive}>
        <LayersIcon />
      </Control>
      <LayerTogglesWrapper isExpanded={isExpanded}>
        <LayerTogglesGroup>
          <LayerToggle selected={layers.irrigation} disabled={!isIrrigationSource} onClick={() => isIrrigationSource && toggleLayer('irrigation')}>
            <Tooltip>
              <TooltipBody>{t('main.map.irrigation')}</TooltipBody>
            </Tooltip>
            <TopographyIcon />
          </LayerToggle>
          <LayerToggle selected={layers.terrain} onClick={() => toggleLayer('terrain')}>
            <Tooltip>
              <TooltipBody>{t('main.map.terrain')}</TooltipBody>
            </Tooltip>
            <TerrainIcon />
          </LayerToggle>
        </LayerTogglesGroup>
        {groveView && (
          <LayerTogglesGroup>
            {activeRichItem === ERichItemType.Health && (
              <LayerToggle selected={layers.colorBlind} onClick={() => toggleLayer('colorBlind')}>
                <Tooltip>
                  <TooltipBody>{t('main.map.colorblind')}</TooltipBody>
                </Tooltip>
                <ColorblindIcon />
              </LayerToggle>
            )}
            <LayerToggle selected={layers.clusters} onClick={() => toggleLayer('clusters')}>
              <Tooltip>
                <TooltipBody>{t('main.map.clusters')}</TooltipBody>
              </Tooltip>
              <ClustersIcon />
            </LayerToggle>
            <LayerToggle selected={layers.scores} onClick={() => toggleLayer('scores')}>
              <Tooltip>
                <TooltipBody>{t('main.map.scores')}</TooltipBody>
              </Tooltip>
              <ScoresIcon />
            </LayerToggle>
          </LayerTogglesGroup>
        )}
      </LayerTogglesWrapper>
    </Wrapper>
  );
};

export default SidebarControls;
