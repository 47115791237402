import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { EReportType } from 'models/report';
import BulletinModalSettings from './BulletinModalSettings';
import BulletinModalTypeSelector from './BulletinModalTypeSelector';

interface IBulletinModalProps {
  reportType: EReportType;
  isTypeSelectorActive?: boolean;
  onClose: (e?: React.MouseEvent) => void;
  onBack?: () => void;
}

const Wrapper = styled.div``;
const BulletinModal = ({ reportType, onClose, onBack, isTypeSelectorActive = false }: IBulletinModalProps): JSX.Element => {
  const [isTypeSelectorOpened, setIsTypeSelectorOpened] = useState(false);
  const [type, setType] = useState<EReportType>(reportType);

  useEffect(() => {
    setIsTypeSelectorOpened(isTypeSelectorActive);
  }, [isTypeSelectorActive]);

  const onTypeSelectorOpenChange = (isOpen: boolean) => {
    setIsTypeSelectorOpened(isOpen);
  };

  return (
    <Wrapper>
      {!isTypeSelectorOpened ? (
        <BulletinModalSettings reportType={type} onClose={onClose} onTypeSelectorOpenChange={onTypeSelectorOpenChange} />
      ) : (
        <BulletinModalTypeSelector onTypeSelectorOpenChange={onTypeSelectorOpenChange} onTypeChange={setType} onBack={onBack} />
      )}
    </Wrapper>
  );
};

export default BulletinModal;
