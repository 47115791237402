import { useState, useEffect } from 'react';

import commoditiesService from 'services/data/commodities';

import { ICommodityCombination } from 'models/commodity';

interface IGetAllCommodityCombinationsResult {
  commodityCombinations: ICommodityCombination[];
}

const useGetAllCommodityCombinations = (): IGetAllCommodityCombinationsResult => {
  const [commodityCombinations, setCommodityCombinations] = useState<ICommodityCombination[]>([]);
  const fetchAllCommodityCombinations = commoditiesService.useFetchAllCommodityCombinations();

  useEffect(() => {
    fetchAllCommodityCombinations().then(setCommodityCombinations);
  }, [fetchAllCommodityCombinations]);

  return {
    commodityCombinations
  };
};

const commoditiesHooks = {
  useGetAllCommodityCombinations
};

export default commoditiesHooks;
