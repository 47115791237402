import { useCallback } from 'react';
import useLogger, { EStorageType } from 'hooks/logger.hooks';
import db from 'services/data/providers/firebase';
import { doc, updateDoc, collection, query, where, deleteField, onSnapshot, Unsubscribe, setDoc, QueryConstraint, getDocs, addDoc, deleteDoc, getDoc } from 'firebase/firestore';
import { firestoreToArray, head } from 'utils/helpers';
import { IReplantRules } from 'models/replant';
import { ILineString } from 'redux/distanceMeasurementSlice';
import { EMeasurementSystem } from 'models/region';

export const useUpdateReplantRules = () => {
  const { logError } = useLogger();

  return useCallback(
    async (docID: string, replantRules: IReplantRules): Promise<void> => {
      const path = 'replant_rules';
      const docRef = doc(collection(db.firestore, path), docID);

      try {
        await updateDoc(docRef, { calculationSettings: replantRules });
      } catch (e) {
        logError(e as Error, path, EStorageType.Firestore);
      }
    },
    [logError]
  );
};

export const useAddReplantRules = () => {
  const { logError } = useLogger();

  return useCallback(
    async (docID: string, replantRules: IReplantRules, customParams: object): Promise<void> => {
      const path = 'replant_rules';
      const docRef = doc(collection(db.firestore, path), docID);

      try {
        await setDoc(docRef, { ...replantRules, ...customParams, id: docRef.id });
      } catch (e) {
        logError(e as Error, path, EStorageType.Firestore);
      }
    },
    [logError]
  );
};

export const useResetCalculationReplantRules = () => {
  const { logError } = useLogger();

  return useCallback(
    async (docID: string): Promise<void> => {
      const path = 'replant_rules';
      const docRef = doc(collection(db.firestore, path), docID);

      try {
        await updateDoc(docRef, { calculationSettings: deleteField() });
      } catch (e) {
        logError(e as Error, path, EStorageType.Firestore);
      }
    },
    [logError]
  );
};

export const listenFarmReplantRules = (farmID: string, onSuccess: (data: IReplantRules) => void, onError: (err, level, storageType) => void): Unsubscribe => {
  const replantRulesCollection = collection(db.firestore, 'replant_rules');

  const unsub = onSnapshot(
    query(replantRulesCollection, where('farmID', '==', farmID)),
    (doc) => {
      const data = head(firestoreToArray(doc));

      onSuccess(data as IReplantRules);
    },
    (e) => {
      const path = 'replant_rules';

      onError(e as Error, path, EStorageType.Firestore);
    }
  );

  return unsub;
};

type TSegmentRequest = {
  farmID: string;
  groveID: string;
  surveyID: string;
};

export type THideSegment = TSegmentRequest & { segmentID: string };

export const hideReplantSegment = async ({ farmID, segmentID, groveID, surveyID }: THideSegment) => {
  const docRef = doc(db.firestore, 'replant_segments_deleted', segmentID);
  const deleteDocRef = doc(db.firestore, 'replant_segments', segmentID);

  const getDrawSegment = await getDoc(deleteDocRef);

  if (getDrawSegment.exists()) {
    await deleteDoc(deleteDocRef);
  } else {
    await setDoc(docRef, { farmID, segmentID, groveID, surveyID, createdAt: new Date() });
  }

  return docRef.id;
};

export type ICapacitySegment = TSegmentRequest & { lineString: ILineString; metricType: EMeasurementSystem; id?: string; length?: number };

export const storeReplantSegment = async ({ farmID, groveID, surveyID, lineString, metricType }: ICapacitySegment) => {
  const docRef = collection(db.firestore, 'replant_segments');

  const ref = await addDoc(docRef, { farmID, lineString: JSON.stringify(lineString), groveID, surveyID, metricType, createdAt: new Date() });

  return ref.id;
};

export const getReplantRulesBy = async (params: { farmID?: string; missionID?: string }) => {
  const queryParams = [] as QueryConstraint[];

  if (params.farmID) {
    queryParams.push(where('farmID', '==', params.farmID));
  }

  if (params.missionID) {
    queryParams.push(where('missionID', '==', params.missionID));
  }

  const replantRulesCollection = collection(db.firestore, 'replant_rules');
  const q = query(replantRulesCollection, ...queryParams);
  const querySnapshot = await getDocs(q);
  const data = head(firestoreToArray(querySnapshot));

  return data ? (data as IReplantRules) : null;
};

interface IGetSegmentsParams {
  farmID: string;
  surveyID?: string;
  groveID?: string;
}

export const getDrawReplantSegments = async ({ farmID, surveyID, groveID }: IGetSegmentsParams) => {
  const queryParams = [where('farmID', '==', farmID)];

  if (surveyID) {
    queryParams.push(where('surveyID', '==', surveyID));
  }

  if (groveID) {
    queryParams.push(where('groveID', '==', groveID));
  }

  const replantRulesCollection = collection(db.firestore, 'replant_segments');
  const q = query(replantRulesCollection, ...queryParams);
  const querySnapshot = await getDocs(q);
  const data = firestoreToArray(querySnapshot).map((item) => ({ ...item, lineString: JSON.parse(item.lineString) }));

  return data as ICapacitySegment[];
};
