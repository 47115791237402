import React, { useMemo, ChangeEvent } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import Radio from 'atomicComponents/Radio';
import { ERichItemType, IRichItem } from 'models/richItem';
import { mfColors } from 'vars';

const Wrapper = styled.div`
  font-size: 16px;
  line-height: 20px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 4px 8px;
  border-top: 1px solid ${mfColors.grey25};
`;

const RowLabel = styled.div`
  margin: 0;
  text-transform: capitalize;
`;

const CenteredContent = styled.div`
  padding: 4px 16px;
  width: 100%;

  &:hover {
    border-radius: 16px;
    background: rgba(67, 133, 243, 1);
    color: ${mfColors.white};
  }
`;

interface IProps {
  richItems: IRichItem[];
  activeRichItem: string | null;
  onRichItemSelected: (richItem: ERichItemType) => void;
}

const getRichItemKey = (richItem: string): string => `main.heatmaps.${richItem.toLowerCase().replace(/\s/g, '_')}`;

const RichItemsModal = ({ richItems, activeRichItem, onRichItemSelected }: IProps): JSX.Element => {
  const { t } = useTranslation();
  const richItemsGroups = useMemo(
    () => richItems.filter((richItem, index, self) => self.findIndex((entry) => entry.richItemTypeName === richItem.richItemTypeName) === index),
    [richItems]
  );

  return (
    <Wrapper>
      <div onChange={(e: ChangeEvent<HTMLInputElement>) => onRichItemSelected(e.target.value as ERichItemType)}>
        {richItemsGroups.map((richItem, index) => (
          <Row key={richItem.richItemTypeName}>
            <CenteredContent>
              <Radio
                name="richItem"
                value={richItem.richItemTypeName}
                checked={richItem.richItemTypeName === activeRichItem}
                label={<RowLabel>{t(getRichItemKey(richItem.richItemTypeName))}</RowLabel>}
                hidden
              />
            </CenteredContent>
          </Row>
        ))}
      </div>
    </Wrapper>
  );
};

export default RichItemsModal;
