import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isCapacityShownSelector, setIsCapacityShown, setIsFarmLayersFiltered } from 'redux/appStateSlice';
import styled from 'styled-components';
import { mfColors } from 'vars';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSeedling } from '@fortawesome/pro-solid-svg-icons';
import ReplantInfo from 'components/replant/ReplantInfo';
import replantUtils from 'utils/replant';

interface ICapacityBlockProps {
  active?: boolean;
}

const CapacityBlock = styled.div`
  margin-top: 12px;
  border-radius: 8px;
  font-size: 13px;
  font-family: MontserratBold;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  background-color: ${({ active }: ICapacityBlockProps) => (active ? mfColors.white : mfColors.sideBarBackground)};
  border: ${({ active }: ICapacityBlockProps) => (active ? `1px solid ${mfColors.timebarSurveyColor}` : `1px solid ${mfColors.sideBarBackground}`)};

  &:hover {
    filter: drop-shadow(0px 5px 10px rgba(0, 0, 0, 0.03)) drop-shadow(0px 20px 40px rgba(0, 0, 0, 0.03)) drop-shadow(0px 25px 50px rgba(0, 0, 0, 0.03));
  }
`;

const CapacityRow = styled.div`
  display: flex;
  justify-content: space-beetween;
  align-items: center;
`;

const CapacityLabel = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Icon = styled.div`
  margin: 0px 8px 0 4px;
  width: 26px;
  display: flex;
  justify-content: center;

  & > * {
    width: 13px;
  }
`;

const CapacityValue = styled.div`
  font-family: MontserratBold;
  margin-right: 10px;
  padding: 6px 12px;
`;

interface IProps {
  capacity: number;
}

const TreeCapacity = ({ capacity }: IProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isCapacityShown = useSelector(isCapacityShownSelector);
  const handleCapacityClick = () => {
    dispatch(setIsCapacityShown(!isCapacityShown));
    dispatch(setIsFarmLayersFiltered(!isCapacityShown));
  };

  return (
    <>
      <CapacityBlock onClick={handleCapacityClick} active={isCapacityShown}>
        <CapacityLabel>
          <Icon>
            <FontAwesomeIcon icon={faSeedling} />
          </Icon>
          <div>{t('main.info.replant_capacity')}</div>
        </CapacityLabel>
        <CapacityRow>
          <CapacityValue>{replantUtils.formatReplantNumber(capacity)}</CapacityValue>
        </CapacityRow>
      </CapacityBlock>
      {isCapacityShown && <ReplantInfo />}
    </>
  );
};

export default TreeCapacity;
