import { Map, Marker } from 'mapbox-gl';
import * as turf from '@turf/turf';

import { IGrove } from 'models/grove';
import { IFeed, HLB_INFECTED_STATUSES } from 'models/feed';
import { IMission, EMissionType } from 'models/mission';
import { IGroveSurveyStats } from 'models/stats';

import { MissionHeatmapHTMLMarker } from 'components/shared/markers/MissionHeatmapMarker';

import missionUtils from 'utils/mission';
import numberUtils from 'utils/numbers';

const HLBcircleSizeTresholds = [
  [0.05, '38px'],
  [0.1, '42px'],
  [0.15, '46px'],
  [Infinity, '50px']
] as [number, string][];

const HLBcircleColorTresholds = [
  [1, '#FFCCCC'],
  [2, '#FF9999'],
  [3, '#FF8080'],
  [Infinity, '#FF0000']
] as [number, string][];

const MARKER_CLASSNAME = 'heatmap-marker';

const getTresholdValue = (value: number, tresholds: [number, string][]): string | void => {
  for (let i = 0; i < tresholds.length; i += 1) {
    if (value < tresholds[i][0]) return tresholds[i][1];
  }
  return undefined;
};

export const addMissionsHeatmapMarkers = (
  map: Map,
  missions: IMission[],
  groves: IGrove[],
  feed: IFeed[],
  surveyStats: IGroveSurveyStats[],
  grovesSurveysStats: IGroveSurveyStats[]
) => {
  const mission = missions[0];
  if (!mission) return;

  groves.forEach((groveModel: IGrove) => {
    const groveEntries = feed.filter((entry) => entry.groveID === groveModel.id);
    let value;
    let color;
    let size;

    if (mission.type === EMissionType.FruitCount) {
      value = groveEntries.length > 0 ? Math.floor(groveEntries.reduce((acc: number, entry) => acc + (entry?.data?.fruitCountNumber || 0), 0) / groveEntries.length) : 0;
    } else if (mission.type === EMissionType.HLBScouting) {
      const infectedTrees = groveEntries.filter((entry) => entry?.data?.hlbStatus && HLB_INFECTED_STATUSES.includes(entry.data.hlbStatus));
      const totalTreesCount = mission.isPreassigned
        ? groveEntries.filter((entry) => entry.groveID === groveModel.id).length
        : missionUtils.getTreesSum([groveModel], surveyStats, grovesSurveysStats);
      const infectionRate = infectedTrees.length / totalTreesCount;
      const totalSeverity = missionUtils.getTotalSeverity(infectedTrees);
      const avgSeverity = totalSeverity / infectedTrees.length;

      size = getTresholdValue(infectionRate, HLBcircleSizeTresholds);
      color = getTresholdValue(avgSeverity, HLBcircleColorTresholds);
      const infectionPercentage = numberUtils.normalizeDecimal(numberUtils.convertToPercentage(infectionRate), 1);

      if (!infectionPercentage) return;

      value = `${infectionPercentage}%`;
    }

    if (!value) return;

    const el = document.createElement('div');
    el.innerHTML = MissionHeatmapHTMLMarker({ value, className: MARKER_CLASSNAME, color, size });
    const marker = new Marker(el);
    const feature = turf.centerOfMass(groveModel.geometry);
    marker.setLngLat(feature.geometry.coordinates as [number, number]).addTo(map);
  });
};

export const removeMissionsHeatmapMarkers = (map: Map): void => {
  const container = map.getContainer();
  const markers = container.querySelectorAll(`.${MARKER_CLASSNAME}`);

  markers.forEach((marker) => {
    marker.remove();
  });
};
