import { useCallback, useState, useEffect, useMemo } from 'react';
import * as turf from '@turf/turf';
import { useDispatch, useSelector } from 'react-redux';

import {
  useFetchFarmZones,
  useFetchFarmGroves,
  useFetchFarmSurveys,
  useFetchFarmReports,
  useFetchFarmHotspots,
  useFetchVectiles,
  useFetchGroveTrees,
  useFetchRasterTiles,
  useSubscribeToFarmMissions,
  useFetchFarmFeed,
  useFetchAllMissionsReports,
  useFetchGrovesStats,
  useFetchScoresDifference,
  useFetchReportLocationTreshold,
  IGetVectilesResult,
  useFetchPoi,
  useFetchFarm,
  useFetchBulletin
} from 'services/data/farms';
import {
  useFetchStatistic,
  useFetchGeojson,
  useFetchByParams,
  useFetchBigQueryGeojson,
  useFetchRichItemDefaultConfigs,
  IRichItemsFilters,
  useFetchRichItemsLayers
} from 'services/data/richItems';
import useFetchWeather from 'services/data/weather';
import signInHooks from 'hooks/signIn.hooks';
import {
  BIG_QUERY_STATISTIC_TYPES,
  RICH_ITEM_PRIORITY,
  NDVI_TRESHOLD_LOWEST,
  NDVI_TRESHOLD_LOW,
  NDVI_TRESHOLD_LOWER,
  NDVI_TRESHOLD_MEDIUM,
  NDVI_TRESHOLD_HIGH,
  TREE_GROWTH_FARM_ID,
  ERichItemType,
  ERichItemSubType,
  IRichItem,
  IRichItemFeature,
  INDVIRichItemGeojson,
  IRichItemDefaultConfig,
  ENdviCategory,
  RICH_ITEM_TYPES_GEO_ANALYTICS,
  IFreeAreaRichItemGeojson,
  ICIPORichItemGeojson,
  IRichItemGeojson,
  ICapacityRichItemGeojson
} from 'models/richItem';
import { EMeasurementSystem } from 'models/region';

import appConfigHooks from 'hooks/appConfig.hooks';
import surveyUtils from 'utils/survey';

import { IFarm, IFarmFeatures, IFarmName } from 'models/farm';
import { IZone } from 'models/zone';
import { IGrove } from 'models/grove';
import { IGroveSurveyStats } from 'models/stats';
import { IFeed, MIGRATED_FARMS_FEED } from 'models/feed';
import { ISurvey, ISurveyWithTimeRange } from 'models/survey';
import { EHotspotType, IUnifiedHotspot } from 'models/hotspot';
import { ICOGRaster, ICDNRaster } from 'models/rasters';
import { IWeather } from 'models/weather';
import { EReportType, TAnyReport, supportedReportsTypes } from 'models/report';
import { IMission } from 'models/mission';
import { ISimpleStatistic, TMappedStatistic, IParsedFruitYieldStatistic, IWeedsStatistic, TFruitYieldStatistic, THealthStatistic } from 'models/statistic';
import { filterFeatureCollection, parseBqToGeojson, parseLineBqToGeojson } from 'utils/helpers';
import {
  getSelectedGrove,
  getSelectedSurvey,
  getSelectedFarm,
  getSelectedZone,
  setSelectedGrove,
  setSelectedZone,
  setSelectedFarm,
  setSelectedSurvey,
  farmsSelector,
  zonesSelector,
  setZones,
  grovesSelector,
  setGroves,
  surveysSelector,
  setSurveys,
  setGrovesSurveysStats,
  grovesSurveysStatsSelector,
  allSurveysSelector,
  selectedSurveyStatsSelector,
  surveysWithDatesSelector,
  setGrovesBqStatistic,
  surveyGrovesSelector,
  getFarmBulletinRelevandMonths
} from 'redux/farm/farmSlice';
import { richItemTypeSelector } from 'redux/richItems/richItemsSlice';

import appConfig from 'config/config.json';
import { IPoi } from 'models/poi';
import { useTranslation } from 'react-i18next';
import { getMode } from 'redux/modeSlice';
import { getFarmBulletinThresholds } from 'redux/bulletin/bulletinSlice';
import dateUtils from 'utils/date';
import moment from 'moment';
import { IRichItemMeasurementRanges } from 'services/data/appConfig';
import { TREES_FACTS_SCORES } from 'models/scores';
import numberUtils from 'utils/numbers';
import replantUtils from 'utils/replant';

export const CUSTOMER_VISIBILITY_FLAGS = [2];

// prettier-ignore
export const isValidRichItemType = (type: ERichItemType, farmID?: string, features?: IFarmFeatures): boolean => BIG_QUERY_STATISTIC_TYPES.includes(type)
  && (type !== ERichItemType.Ndvi || !!features?.ndvi)
  && (type !== ERichItemType.TreeGrowth || farmID === TREE_GROWTH_FARM_ID);

const useGetFarms = (): IFarmName[] => {
  const farms = useSelector(farmsSelector);

  return farms;
};

type TGetFarmAction = (farmID: string) => void;

interface IGetFarmZonesResult {
  getZones: TGetFarmAction;
  zones: IZone[];
}

const useGetFarmZones = (): IGetFarmZonesResult => {
  const dispatch = useDispatch();
  const zones = useSelector(zonesSelector);
  const getFarmZones = useFetchFarmZones();

  const getZones = useCallback(
    async (farmID: string): Promise<void> => {
      dispatch(setZones(await getFarmZones(farmID)));
    },
    [getFarmZones, dispatch]
  );

  return {
    getZones,
    zones
  };
};

interface IRasterLayers {
  urls: string[];
  bbox: turf.BBox;
}

interface IGroveRastersTilesResult {
  COGRasters: ICOGRaster[];
  CDNRasters: ICDNRaster[];
  rasterLayers: IRasterLayers | null;
}

const useGroveRastersTiles = (selectedFarm: IFarm | null, selectedGrove: IGrove | null, selectedSurvey: ISurvey | null): IGroveRastersTilesResult => {
  const [COGRasters, setCOGRasters] = useState<ICOGRaster[]>([]);
  const [CDNRasters, setCDNRasters] = useState<ICDNRaster[]>([]);
  const [rasterLayers, setRasterLayers] = useState<IRasterLayers | null>(null);
  const defaultRasterType = 'RGB';
  const fetchRasterTiles = useFetchRasterTiles();

  const getRastersTiles = useCallback(
    async (farmID: string, surveyID: string, groveID: string): Promise<void> => {
      const rasterTilesData = await fetchRasterTiles(farmID, surveyID, groveID);
      setCOGRasters(rasterTilesData?.cogRasters || []);
      setCDNRasters(rasterTilesData?.['grove-raster-cdn'] || []);
    },
    [fetchRasterTiles]
  );

  useEffect(() => {
    if (selectedFarm?.id && selectedSurvey?.id && selectedGrove?.id) {
      getRastersTiles(selectedFarm.id, selectedSurvey.id, selectedGrove.id);
    } else {
      setCOGRasters([]);
      setCDNRasters([]);
    }
  }, [selectedFarm, selectedSurvey, selectedGrove, getRastersTiles]);

  useEffect(() => {
    if (selectedGrove?.id && selectedSurvey?.id) {
      const bbox = turf.bbox(selectedGrove.geometry);
      const surveyCdnLayers = selectedSurvey?.['grove-raster-cdn']?.[defaultRasterType]?.[selectedGrove.id];
      const { tiles } = CDNRasters.find((item) => item.type === defaultRasterType) || {};
      const { path: COGPath } = COGRasters.find((item) => item.type === 'COG') || {};
      const cdnLayers = tiles || surveyCdnLayers;

      if (COGPath) {
        setRasterLayers({
          urls: [`${appConfig.COGDomainURL}/tiles/{z}/{x}/{y}.webp?raster_type=${defaultRasterType}&raster_path=${COGPath}`],
          bbox
        });
      } else if (cdnLayers) {
        setRasterLayers({
          urls: [`https://storage.googleapis.com/${cdnLayers}/{z}/{x}/{y}.png`],
          bbox
        });
      } else {
        setRasterLayers(null);
      }
    } else {
      setRasterLayers(null);
    }
  }, [selectedGrove, selectedSurvey, CDNRasters, COGRasters]);

  return {
    COGRasters,
    CDNRasters,
    rasterLayers
  };
};

interface IGetFarmVectilesResult {
  getFarmVectiles: TGetFarmAction;
  vectiles: IGetVectilesResult | null;
  loading: boolean;
}

const useGetFarmVectiles = (): IGetFarmVectilesResult => {
  const [loading, setLoading] = useState(false);
  const [vectiles, setVectiles] = useState<IGetVectilesResult | null>(null);
  const fetchVectiles = useFetchVectiles();

  const getFarmVectiles = useCallback(
    async (farmID: string): Promise<void> => {
      setLoading(true);
      try {
        const response = await fetchVectiles(farmID);
        setVectiles(response);
      } finally {
        setLoading(false);
      }
    },
    [fetchVectiles]
  );

  return {
    getFarmVectiles,
    vectiles,
    loading
  };
};

interface IGetFarmGrovesResult {
  getFarmGroves: TGetFarmAction;
  groves: IGrove[];
}

const useGetFarmGroves = (): IGetFarmGrovesResult => {
  const dispatch = useDispatch();
  const groves = useSelector(grovesSelector);
  const fetchFarmGroves = useFetchFarmGroves();

  const getFarmGroves = useCallback(
    async (farmID: string): Promise<void> => {
      dispatch(setGroves(await fetchFarmGroves(farmID)));
    },
    [fetchFarmGroves, dispatch]
  );

  return {
    getFarmGroves,
    groves
  };
};

interface IGetFarmSurveyResult {
  getFarmSurveys: TGetFarmAction;
  surveys: ISurvey[];
}

const useGetFarmSurveys = (): IGetFarmSurveyResult => {
  const dispatch = useDispatch();
  const surveys = useSelector(surveysSelector);
  const fetchFarmSurveys = useFetchFarmSurveys();

  const getFarmSurveys = useCallback(
    async (farmID: string): Promise<void> => {
      dispatch(setSurveys(await fetchFarmSurveys(farmID)));
    },
    [fetchFarmSurveys, dispatch]
  );

  return {
    getFarmSurveys,
    surveys
  };
};

interface IGetFarmReportsResult {
  loading: boolean;
  getFarmReports: (
    report?: EReportType,
    farmID?: string,
    surveyID?: string,
    previousSurveyID?: string | null,
    fetchFullDataset?: boolean,
    nextSurveyID?: string | null
  ) => Promise<TAnyReport[]>;
}

const useFarmReports = (): IGetFarmReportsResult => {
  const [loading, setLoading] = useState(false);
  const fetchFarmReports = useFetchFarmReports();
  const fetchScoresDifference = useFetchScoresDifference();
  const fetchFarmAnomalies = useFetchFarmHotspots();
  const farmBulletinThresholds = useSelector(getFarmBulletinThresholds);
  const farmBulletinRelevandMonths = useSelector(getFarmBulletinRelevandMonths);

  const getFarmReports = useCallback(
    async (
      report?: EReportType,
      farmID?: string,
      surveyID?: string,
      previousSurveyID?: string | null,
      fetchFullDataset = false,
      nextSurveyID?: string | null
    ): Promise<TAnyReport[]> => {
      let reportsData: TAnyReport[] = [];

      if (!farmID || !surveyID || !report) return Promise.resolve(reportsData);

      const isMonthRelevant = !farmBulletinRelevandMonths[report]?.length || farmBulletinRelevandMonths[report].includes(moment().month());

      setLoading(true);
      switch (report) {
        case EReportType.DedicatedVisit: {
          const thresholdValue = farmBulletinThresholds[report]?.value ? (farmBulletinThresholds[report]?.value as number) * 100 : undefined;
          // eslint-disable-next-line max-len, prettier/prettier
          reportsData = previousSurveyID && isMonthRelevant ? await fetchScoresDifference(farmID, surveyID, previousSurveyID as string, fetchFullDataset ? 1 : thresholdValue, nextSurveyID) : [];
          break;
        }
        case EReportType.TargetedScouting:
          {
            const result = await fetchFarmAnomalies(farmID, surveyID);
            reportsData = result?.satellites || [];
          }
          break;
        default:
          if (isMonthRelevant) {
            const threshold = numberUtils.converFeetToMeter(farmBulletinThresholds[report]?.value as number, farmBulletinThresholds[report]?.measurementSystem);
            reportsData = await fetchFarmReports(farmID, surveyID, report, fetchFullDataset, threshold, nextSurveyID);
          } else {
            reportsData = [];
          }
      }
      setLoading(false);

      return reportsData;
    },
    [fetchFarmReports, fetchScoresDifference, fetchFarmAnomalies, farmBulletinThresholds, farmBulletinRelevandMonths]
  );

  return {
    getFarmReports,
    loading
  };
};

interface IGetFarmBulletinReportsResult {
  loading: boolean;
  getFarmReports: (report: EReportType, farmID: string, surveyID: string, fetchFullDataset?: boolean) => Promise<TAnyReport[]>;
}

const useFarmBulletinReports = (): IGetFarmBulletinReportsResult => {
  const [loading, setLoading] = useState(false);
  const getBulletinReports = useFetchBulletin();

  const getFarmReports = useCallback(
    async (report: EReportType, farmID: string, surveyID: string, fetchFullDataset = false): Promise<TAnyReport[]> => {
      setLoading(true);

      const reportsData = await getBulletinReports(farmID, surveyID, fetchFullDataset);

      setLoading(false);
      return reportsData[report];
    },
    [getBulletinReports]
  );

  return {
    getFarmReports,
    loading
  };
};

interface IGetFarmSatelliteHotspotsResult {
  getFarmHotspots: (farmID: string, surveyID: string) => void;
  loading: boolean;
  satelliteHotspots: IUnifiedHotspot[];
  hotspots: IUnifiedHotspot[];
  resetHotspots: () => void;
}

const useFarmHotspots = (): IGetFarmSatelliteHotspotsResult => {
  const [satelliteHotspots, setSatelliteHotspots] = useState<IUnifiedHotspot[]>([]);
  const [hotspots, setHotspots] = useState<IUnifiedHotspot[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const fetchFarmHotspots = useFetchFarmHotspots();

  useEffect(() => {
    if (loading) {
      setSatelliteHotspots([]);
      setHotspots([]);
    }
  }, [loading]);

  const getFarmHotspots = useCallback(
    async (farmID: string, surveyID: string): Promise<void> => {
      setLoading(true);
      const hotspots = await fetchFarmHotspots(farmID, surveyID);

      setSatelliteHotspots(hotspots.satellites);
      setHotspots(hotspots.anomalies.filter((anomaly) => anomaly.type !== EHotspotType.Cutline));
      setLoading(false);
    },
    [fetchFarmHotspots]
  );

  const resetHotspots = useCallback(() => setHotspots([]), []);

  return {
    getFarmHotspots,
    loading,
    satelliteHotspots,
    hotspots,
    resetHotspots
  };
};

interface IGetGroveTreesResult {
  getGroveTrees: (farmID: string, groveID: string, surveyID: string) => void;
  trees: IRichItemGeojson;
}

const useGroveTrees = (): IGetGroveTreesResult => {
  const [trees, setTrees] = useState<IRichItemGeojson>({});
  const fetchGroveTrees = useFetchGroveTrees();

  const getGroveTrees = useCallback(
    async (farmID: string, groveID: string, surveyID: string): Promise<void> => {
      setTrees(await fetchGroveTrees(farmID, groveID, surveyID));
    },
    [fetchGroveTrees]
  );

  return {
    getGroveTrees,
    trees
  };
};

export interface IGetBigQueryStatisticResult {
  statistic: TMappedStatistic | null;
  healthStatistic: TMappedStatistic | null;
  isLoading: boolean;
}

const useRichItemsStatistic = (richItemType: ERichItemType | null, farmID?: string, surveyID?: string, features?: IFarmFeatures): IGetBigQueryStatisticResult => {
  const [statistic, setStatistic] = useState<{ [richItemType: string]: TMappedStatistic | null }>();
  const [isLoading, setIsLoading] = useState(false);
  const fetchStatistic = useFetchStatistic();
  const dispatch = useDispatch();

  const getStatistic = useCallback(
    async (farmID: string, surveyID: string, richItemType: ERichItemType) => {
      setIsLoading(true);
      const data = await fetchStatistic(farmID, surveyID, richItemType);
      setIsLoading(false);

      if (!data) {
        setStatistic((prev) => ({ ...prev, [richItemType]: null }));
      } else if (richItemType === ERichItemType.Health) {
        setStatistic((prev) => ({ ...prev, [richItemType]: data as THealthStatistic }));
      } else if (richItemType === ERichItemType.FruitYield) {
        const keys = Object.keys(data as TFruitYieldStatistic);
        keys.forEach((key) => {
          const categories = data?.[key]?.statistic?.sizes?.categories || null;
          const values = data?.[key]?.statistic?.sizes?.values || null;
          const countPerTree = data?.[key]?.statistic?.countPerTree || null;
          if (categories) {
            data[key].statistic.sizes.categories = JSON.parse(categories);
          }
          if (values) {
            data[key].statistic.sizes.values = JSON.parse(values);
          }
          if (countPerTree) {
            data[key].statistic.countPerTree = Math.round(countPerTree);
          }
        });

        setStatistic((prev) => ({ ...prev, [richItemType]: data as { [key: string]: IParsedFruitYieldStatistic } }));
      } else if (richItemType === ERichItemType.Weeds) {
        setStatistic((prev) => ({ ...prev, [richItemType]: data as { [key: string]: IWeedsStatistic } }));
      } else {
        const mappedData = (data as ISimpleStatistic[]).reduce((obj, entity) => {
          const { groveID, ...rest } = entity;
          obj[groveID] = rest;
          return obj;
        }, {});
        setStatistic((prev) => ({ ...prev, [richItemType]: mappedData }));
      }
    },
    [fetchStatistic]
  );

  useEffect(() => {
    if (farmID && surveyID && richItemType && isValidRichItemType(richItemType, farmID, features)) {
      getStatistic(farmID, surveyID, richItemType);
    } else {
      if (!richItemType) {
        return;
      }

      setStatistic((prev) => ({ ...prev, [richItemType]: null }));
    }
  }, [farmID, surveyID, richItemType, getStatistic, features]);

  const richItemStatistic = useMemo(() => {
    if (richItemType && statistic) {
      return statistic[richItemType];
    }

    return null;
  }, [statistic, richItemType]);

  useEffect(() => {
    dispatch(setGrovesBqStatistic(richItemStatistic));
  }, [richItemStatistic, dispatch]);

  return {
    statistic: richItemStatistic,
    healthStatistic: statistic ? statistic[ERichItemType.Health] : null,
    isLoading
  };
};

interface IGetGroveLayersResult {
  richItems: IRichItem[];
  richItemsLoaded: boolean;
}

const useRichItems = (farmID?: string, groveID?: string, surveyID?: string, features?: IFarmFeatures): IGetGroveLayersResult => {
  const { layers: allLayers } = appConfigHooks.useGetLayers();
  const [richItems, setRichItems] = useState<IRichItem[]>([]);
  const [richItemsLoaded, setRichItemsLoaded] = useState(false);
  const fetchRichItemsLayers = useFetchRichItemsLayers();

  const fetchLayers = useCallback(
    async (params: Omit<IRichItemsFilters, 'richItemTypeName'>): Promise<void> => {
      setRichItemsLoaded(true);
      const layers = await fetchRichItemsLayers(params.farmID, params.surveyID, params.groveID);

      setRichItems(
        (
          layers.filter((layer) => {
            const type = layer?.richItemTypeName as ERichItemType;
            const isFarmLevelRichItemType = isValidRichItemType(type, params.farmID, features);
            return !!layer && (isFarmLevelRichItemType || type === ERichItemType.FruitAggregation || params.groveID);
          }) as IRichItem[]
        ).sort((a, b) => RICH_ITEM_PRIORITY.indexOf(a.richItemTypeName) - RICH_ITEM_PRIORITY.indexOf(b.richItemTypeName))
      );
      setRichItemsLoaded(false);
    },
    [fetchRichItemsLayers, features]
  );

  useEffect(() => {
    if (farmID && surveyID && allLayers.length) {
      const params = {
        internal: false,
        limit: 1,
        farmID,
        groveID,
        surveyID
      };

      fetchLayers(params);
    } else {
      setRichItemsLoaded(true);
      setRichItems([]);
    }
  }, [groveID, farmID, surveyID, allLayers, fetchLayers]);

  return {
    richItems,
    richItemsLoaded
  };
};

const useGrovesRichItems = (farmID?: string, surveyID?: string, richItemTypeName?: ERichItemType): IGetGroveLayersResult => {
  const [richItems, setRichItems] = useState<IRichItem[]>([]);
  const [richItemsLoaded, setRichItemsLoaded] = useState(false);
  const fetchByParams = useFetchByParams();

  const fetchGrovesRichItems = useCallback(
    async (params: IRichItemsFilters): Promise<void> => {
      setRichItemsLoaded(true);
      const res = await fetchByParams(params);

      if (res) {
        setRichItems(res);
      }
      setRichItemsLoaded(false);
    },
    [fetchByParams]
  );

  useEffect(() => {
    if (farmID && surveyID && richItemTypeName) {
      const params = {
        farmID,
        surveyID,
        richItemTypeName
      };

      fetchGrovesRichItems(params);
    } else {
      setRichItemsLoaded(true);
      setRichItems([]);
    }
  }, [fetchGrovesRichItems, farmID, surveyID, richItemTypeName]);

  return {
    richItems,
    richItemsLoaded
  };
};

export const useSelectedRichItem = (
  richItems: IRichItem[],
  activeRichItem: ERichItemType | null,
  activeRichItemSubType,
  richItemMapboxConfig?: IUnifiedGeojson['mapbox']
): IRichItem | null => {
  const selectedRichItem = useMemo(() => {
    if (richItems.length && activeRichItem) {
      let richItem;
      if (activeRichItemSubType) {
        const fruitRichItems = richItems.filter((item) => item.richItemTypeName === activeRichItem);
        const sortedRichItems = dateUtils.sortByDate(fruitRichItems, 'reportDate');

        richItem = fruitRichItems.length > 1 && activeRichItemSubType === ERichItemSubType.PostThinning ? sortedRichItems[1] : sortedRichItems[0];
      } else {
        richItem = richItems.find((item) => item.richItemTypeName === activeRichItem);
      }

      if (richItemMapboxConfig && richItem) {
        richItem.mapbox = JSON.stringify(richItemMapboxConfig);
      }

      return richItem || null;
    }
    return null;
  }, [richItems, activeRichItem, activeRichItemSubType, richItemMapboxConfig]);

  return selectedRichItem;
};

export interface IUnifiedGeojson {
  geojson: {
    crs?: {
      type: string;
      properties: { [key: string]: string | number };
    };
    features: IRichItemFeature[];
    type: 'FeatureCollection';
  };
  mapbox: {
    paint: {
      [key: string]: (string | number | number[])[];
    };
    type: string;
  } | null;
}

interface IGetRichItemsDefaultConfigsResult {
  defaultConfigs: IRichItemDefaultConfig[];
}

const useRichItemsDefaultConfigs = (): IGetRichItemsDefaultConfigsResult => {
  const [defaultConfigs, setDefaultConfigs] = useState<IRichItemDefaultConfig[]>([]);
  const fetchRichItemDefaultConfigs = useFetchRichItemDefaultConfigs();

  const fetchConfigs = useCallback(async (): Promise<void> => {
    setDefaultConfigs(await fetchRichItemDefaultConfigs());
  }, [fetchRichItemDefaultConfigs]);

  useEffect(() => {
    fetchConfigs();
  }, [fetchConfigs]);

  return {
    defaultConfigs
  };
};

interface IGetFarmWeatherResult {
  weather: IWeather[];
}

const useGetFarmWeather = (farmID?: string, groveID?: string): IGetFarmWeatherResult => {
  const [weather, setWeather] = useState<IWeather[]>([]);
  const fetchWeather = useFetchWeather();

  const getWeather = useCallback(
    async (farmID: string, groveID?: string) => {
      setWeather(await fetchWeather(farmID, groveID));
    },
    [fetchWeather]
  );

  useEffect(() => {
    if (farmID) {
      getWeather(farmID, groveID);
    } else {
      setWeather([]);
    }
  }, [farmID, groveID, getWeather]);

  return {
    weather
  };
};

interface IGetRichItemGeoJSONResult {
  geoJSON: IUnifiedGeojson | null;
  loading: boolean;
}

const useRichItemGeoJSON = (
  richItem: IRichItem | null,
  trees: IRichItemGeojson,
  farmID?: string,
  groveID?: string,
  surveyID?: string,
  measurementSystem = EMeasurementSystem.Metric
): IGetRichItemGeoJSONResult => {
  const [loading, setLoading] = useState(false);
  const [geoJSON, setGeoJSON] = useState<IUnifiedGeojson | null>(null);
  const { defaultConfigs } = useRichItemsDefaultConfigs();
  const fetchBigQueryGeojson = useFetchBigQueryGeojson();
  const fetchApiGeojson = useFetchGeojson();

  const formatNDVIItems = useCallback((geoData: INDVIRichItemGeojson): INDVIRichItemGeojson => {
    const result = {
      ...geoData
    };
    Object.keys(result).forEach((key: string) => {
      if (result[key].ndvi < NDVI_TRESHOLD_LOWEST) {
        result[key].ndviCategory = ENdviCategory.Lowest;
      } else if (result[key].ndvi < NDVI_TRESHOLD_LOW) {
        result[key].ndviCategory = ENdviCategory.Low;
      } else if (result[key].ndvi < NDVI_TRESHOLD_LOWER) {
        result[key].ndviCategory = ENdviCategory.Lower;
      } else if (result[key].ndvi < NDVI_TRESHOLD_MEDIUM) {
        result[key].ndviCategory = ENdviCategory.Medium;
      } else if (result[key].ndvi < NDVI_TRESHOLD_HIGH) {
        result[key].ndviCategory = ENdviCategory.High;
      } else {
        result[key].ndviCategory = ENdviCategory.Highest;
      }
    });

    return result;
  }, []);

  const fetchRichItemGeojson = useCallback(
    async (farmID: string, groveID: string, surveyID: string, richItem: IRichItem, trees: IRichItemGeojson): Promise<void> => {
      setLoading(true);
      const richItemType = richItem.richItemTypeName;
      const defaultMapboxConfig = defaultConfigs.find((config) => config.id === richItemType)?.version?.[richItem.richItemTypeVersion]?.[measurementSystem]?.en?.mapbox;
      const defaultMapbox = defaultMapboxConfig ? JSON.parse(defaultMapboxConfig) : null;

      if ([ERichItemType.Health, ERichItemType.Cipo, ...RICH_ITEM_TYPES_GEO_ANALYTICS].includes(richItemType)) {
        const filters = [ERichItemType.Cipo, ...RICH_ITEM_TYPES_GEO_ANALYTICS].includes(richItemType) ? { scores: TREES_FACTS_SCORES } : undefined;
        const parsedGeoData = filterFeatureCollection(parseBqToGeojson(trees), filters);
        setGeoJSON({ geojson: parsedGeoData, mapbox: richItem.mapbox ? JSON.parse(richItem.mapbox) : defaultMapbox });
        setLoading(false);
      } else if ([ERichItemType.Ndvi].includes(richItemType)) {
        const geoData = await fetchBigQueryGeojson(farmID, groveID, surveyID, richItemType);

        if (geoData) {
          const parsedGeoData = parseBqToGeojson(richItemType === ERichItemType.Ndvi ? formatNDVIItems(geoData as INDVIRichItemGeojson) : (geoData as ICIPORichItemGeojson));
          setGeoJSON({ geojson: parsedGeoData, mapbox: richItem.mapbox ? JSON.parse(richItem.mapbox) : defaultMapbox });
          setLoading(false);
        }
      } else if (richItemType === ERichItemType.FreeArea) {
        const geoData = await fetchBigQueryGeojson(farmID, groveID, surveyID, richItemType);

        if (geoData) {
          const parsedGeoData = parseLineBqToGeojson(geoData as IFreeAreaRichItemGeojson);
          setGeoJSON({ geojson: parsedGeoData, mapbox: richItem.mapbox ? JSON.parse(richItem.mapbox) : defaultMapbox });
          setLoading(false);
        }
      } else {
        const geoData = await fetchApiGeojson(richItem);

        if (geoData && groveID === richItem.groveID) {
          if (geoData.features.some((f) => !f.properties.id)) {
            geoData.features.forEach((g, index) => {
              g.properties.id = index.toString();
            });
          }
          setGeoJSON({ geojson: geoData, mapbox: richItem.mapbox ? JSON.parse(richItem.mapbox) : defaultMapbox });
          setLoading(false);
        }

        if (groveID === richItem.groveID) {
          setLoading(false);
        }
      }
    },
    [defaultConfigs, measurementSystem, fetchBigQueryGeojson, fetchApiGeojson, formatNDVIItems]
  );

  useEffect(() => {
    setGeoJSON(null);
    if (farmID && groveID && surveyID && richItem && trees) {
      fetchRichItemGeojson(farmID, groveID, surveyID, richItem, trees);
    }
  }, [farmID, groveID, surveyID, richItem, trees, fetchRichItemGeojson]);

  return {
    geoJSON,
    loading
  };
};

const useFetchRichItemGeoJSON = (
  activeRichItem: ERichItemType,
  farmID?: string,
  groveID?: string,
  surveyID?: string,
  richItemMeasurementRanges?: IRichItemMeasurementRanges,
  measurementSystem = EMeasurementSystem.Metric
) => {
  const [loading, setLoading] = useState(false);
  const [geoJSON, setGeoJSON] = useState<IUnifiedGeojson | null>(null);
  const fetchBigQueryGeojson = useFetchBigQueryGeojson();
  const fetchRichItemGeojson = useCallback(
    async (farmID: string, groveID: string, surveyID: string, richItemType: ERichItemType, mapbox): Promise<void> => {
      setLoading(true);
      const geoData = await fetchBigQueryGeojson(farmID, groveID, surveyID, richItemType);
      if (geoData) {
        const parsedGeoData = parseLineBqToGeojson(geoData as ICapacityRichItemGeojson);
        setGeoJSON({ geojson: parsedGeoData, mapbox });
        setLoading(false);
      }
    },
    [fetchBigQueryGeojson]
  );
  useEffect(() => {
    setGeoJSON(null);
    if (farmID && groveID && surveyID && activeRichItem && richItemMeasurementRanges) {
      const richItemMetric = richItemMeasurementRanges[measurementSystem][activeRichItem].mapbox as IUnifiedGeojson['mapbox'];
      fetchRichItemGeojson(farmID, groveID, surveyID, activeRichItem, richItemMetric);
    }
  }, [farmID, groveID, surveyID, activeRichItem, fetchRichItemGeojson, richItemMeasurementRanges, measurementSystem]);
  return {
    geoJSON,
    loading
  };
};

interface ISelectedFarmEntitiesResult {
  selectedSurvey: ISurveyWithTimeRange | null;
  selectedGrove: IGrove | null;
  selectedZone: IZone | null;
  selectedFarm: IFarm | null;
}

const useSelectedFarmEntities = (): ISelectedFarmEntitiesResult => {
  const { t } = useTranslation();
  const selectedGrove = useSelector(getSelectedGrove);
  const selectedZone = useSelector(getSelectedZone);
  const selectedFarm = useSelector(getSelectedFarm);
  const selectedSurvey = useSelector(getSelectedSurvey);
  const mode = useSelector(getMode);

  const customSelectedFarm = useMemo(() => {
    if (!selectedFarm) return null;

    const farmName = mode ? t('shared.nav_bar.demo_farm') : selectedFarm?.name;

    return {
      ...selectedFarm,
      name: farmName
    };
  }, [selectedFarm, mode, t]);

  return {
    selectedGrove,
    selectedZone,
    selectedFarm: customSelectedFarm,
    selectedSurvey
  };
};

interface IFarmEntitiesResult {
  farms: IFarmName[];
  zones: IZone[];
  groves: IGrove[];
  surveys: ISurveyWithTimeRange[];
  allSurveys: ISurveyWithTimeRange[];
  grovesSurveysStats: IGroveSurveyStats[];
  selectedSurveyStats: IGroveSurveyStats[];
  surveyGroves: IGrove[];
}

const useFarmEntities = (): IFarmEntitiesResult => {
  const farms = useSelector(farmsSelector);
  const groves = useSelector(grovesSelector);
  const zones = useSelector(zonesSelector);
  const surveys = useSelector(surveysWithDatesSelector);
  const allSurveys = useSelector(allSurveysSelector);
  const grovesSurveysStats = useSelector(grovesSurveysStatsSelector);
  const selectedSurveyStats = useSelector(selectedSurveyStatsSelector);
  const surveyGroves = useSelector(surveyGrovesSelector);

  return {
    farms,
    groves,
    surveyGroves,
    zones,
    surveys,
    allSurveys,
    grovesSurveysStats,
    selectedSurveyStats
  };
};

interface IFarmMapResult {
  selectedFarm: IFarm | null;
  selectedSurvey: ISurveyWithTimeRange | null;
  setSelectedSurvey: (survey: ISurveyWithTimeRange | null) => void;
  selectedGrove: IGrove | null;
  selectedZone: IZone | null;
  selectedAreaStats: IGroveSurveyStats[];
  selectedSurveyStats: IGroveSurveyStats[];
  farms: IFarmName[];
  zones: IZone[];
  groves: IGrove[];
  surveys: ISurveyWithTimeRange[];
  allSurveys: ISurveyWithTimeRange[];
}

const useFarmMapInfo = (farmID?: string, zoneID?: string, groveID?: string, surveyID?: string): IFarmMapResult => {
  const dispatch = useDispatch();
  const selectedSurvey = useSelector(getSelectedSurvey);
  const { selectedFarm } = useSelectedFarmEntities();
  const selectedZone = useSelector(getSelectedZone);
  const selectedGrove = useSelector(getSelectedGrove);
  const grovesSurveysStats = useSelector(grovesSurveysStatsSelector);

  const farms = useGetFarms();
  const { getZones, zones } = farmsHooks.useGetFarmZones();
  const { getFarmGroves, groves } = farmsHooks.useGetFarmGroves();
  const { getFarmSurveys, surveys } = farmsHooks.useGetFarmSurveys();
  const fetchGrovesStats = useFetchGrovesStats();
  const fetchFarm = useFetchFarm();

  const selectGrove = useCallback((grove: IGrove | null) => dispatch(setSelectedGrove(grove)), [dispatch]);
  const selectZone = useCallback((zone: IZone | null) => dispatch(setSelectedZone(zone)), [dispatch]);
  const selectFarm = useCallback((farm: IFarm | null) => dispatch(setSelectedFarm(farm)), [dispatch]);
  const selectSurvey = useCallback((survey: ISurveyWithTimeRange | null) => dispatch(setSelectedSurvey(survey)), [dispatch]);

  const selectedAreaStats = useMemo(() => {
    const stats = grovesSurveysStats.filter((stat) => {
      if (selectedGrove) {
        return stat.groveID === selectedGrove.id;
      } else if (selectedZone) {
        return stat.zoneID === selectedZone.id;
      }

      return stat;
    });
    return stats;
  }, [grovesSurveysStats, selectedGrove, selectedZone]);

  const selectedSurveyStats = useMemo(
    () => (selectedSurvey ? grovesSurveysStats.filter((stats) => stats.surveyID === selectedSurvey.id) : []),
    [grovesSurveysStats, selectedSurvey]
  );

  const surveysWithDates = useMemo((): ISurveyWithTimeRange[] => {
    const availableSurveys = surveys.filter((survey) => survey.status === 'published');

    return (availableSurveys.map((survey: ISurvey) => surveyUtils.getSurveyWithTimeRange(survey, selectedAreaStats)).filter((survey) => !!survey) as ISurveyWithTimeRange[]).sort(
      (a, b) => (b.from < a.from ? -1 : 1)
    );
  }, [surveys, selectedAreaStats]);

  /* eslint-disable */
  const allSurveys = useMemo(
    (): ISurveyWithTimeRange[] => (
      surveys
        .map((survey: ISurvey) => surveyUtils.getSurveyWithTimeRange(survey, selectedAreaStats))
        .filter((survey) => !!survey) as ISurveyWithTimeRange[]
    ).sort((a, b) => (b.from < a.from ? -1 : 1)),
    [surveys, selectedAreaStats]
  );
  /* eslint-enable */

  useEffect(() => {
    if (farmID && farms.length) {
      fetchFarm(farmID).then(selectFarm);
    } else {
      selectFarm(null);
    }
  }, [farmID, farms, selectFarm, fetchFarm]);

  useEffect(() => {
    if (zoneID && zones.length) {
      selectZone(zones.find((zone) => zone.id === zoneID) || null);
    } else {
      selectZone(null);
    }
  }, [zoneID, zones, selectZone]);

  useEffect(() => {
    if (groveID && groves.length) {
      const grove = groves.find((grove) => grove.id === groveID);
      selectGrove(grove || null);
    } else {
      selectGrove(null);
    }
  }, [groveID, groves, zones, selectGrove]);

  useEffect(() => {
    if (farmID && surveyID) {
      fetchGrovesStats(farmID, surveyID).then((items) => dispatch(setGrovesSurveysStats(items)));
    }
  }, [farmID, surveyID, fetchGrovesStats, dispatch]);

  useEffect(() => {
    if (surveysWithDates.length || allSurveys.length) {
      const surveys = surveysWithDates.length ? surveysWithDates : allSurveys;
      const currentSurveyFromList = selectedSurvey ? surveys.find((survey) => survey.id === selectedSurvey.id) : null;
      const currentSurveyFromQuery = surveyID ? surveys.find((survey) => survey.id === surveyID) : null;
      let surveyToSet = currentSurveyFromList || currentSurveyFromQuery;

      if (!surveyToSet) {
        if (surveysWithDates.length > 0) {
          [surveyToSet] = surveysWithDates;
        } else {
          [surveyToSet] = allSurveys;
        }
      }

      if (surveyToSet.id !== selectedSurvey?.id) {
        selectSurvey(surveyToSet);
      }
    } else {
      selectSurvey(null);
    }
  }, [surveysWithDates, allSurveys, selectSurvey, selectedSurvey, surveyID]);

  useEffect(() => {
    if (farmID) {
      getZones(farmID);
      getFarmGroves(farmID);
      getFarmSurveys(farmID);
    }
  }, [farmID, getZones, getFarmGroves, getFarmSurveys]);

  return {
    selectedFarm,
    selectedSurvey,
    setSelectedSurvey: selectSurvey,
    selectedZone,
    selectedGrove,
    selectedAreaStats,
    selectedSurveyStats,
    farms,
    zones,
    groves,
    surveys: surveysWithDates,
    allSurveys
  };
};

interface IBulletinsResult {
  isPending: boolean;
  reports: { [key: string]: (TAnyReport | IUnifiedHotspot)[] };
}

const useBulletins = (
  farmID: string,
  surveyID: string,
  groves: IGrove[],
  manualHotspots: IUnifiedHotspot[],
  zoneID?: string,
  previousSurveyID?: string,
  showFullStats = false,
  nextSurveyID?: string,
  replantReports?: TAnyReport[]
): IBulletinsResult => {
  const [isPending, setIsPending] = useState(false);
  const { getFarmReports } = useFarmReports();
  const [bulletinReports, setBulletinReports] = useState<{ [key: string]: TAnyReport[] }>({});

  useEffect(() => {
    let isSubscribed = true;
    setIsPending(true);
    Promise.all(
      supportedReportsTypes.map((type) => getFarmReports(type, farmID, surveyID, previousSurveyID, type !== EReportType.DedicatedVisit && showFullStats, nextSurveyID))
    ).then((reports: TAnyReport[][]) => {
      if (isSubscribed) {
        setBulletinReports(
          supportedReportsTypes.reduce((acc: { [key: string]: TAnyReport[] }, type: EReportType) => {
            acc[type] = reports[supportedReportsTypes.indexOf(type)] || [];
            return acc;
          }, {})
        );
      }

      setIsPending(false);
    });

    return () => {
      isSubscribed = false;
    };
  }, [getFarmReports, farmID, surveyID, previousSurveyID, showFullStats, nextSurveyID]);

  useEffect(() => {
    if (isPending) {
      setBulletinReports({});
    }
  }, [isPending]);

  const reports = useMemo(() => {
    const targetedScoutingItems = [...(bulletinReports[EReportType.TargetedScouting] || []), ...manualHotspots];

    const allReports = {
      ...bulletinReports,
      [EReportType.TargetedScouting]: targetedScoutingItems,
      ...(replantReports ? { [EReportType.Replant]: replantReports } : {})
    };

    if (!zoneID) return allReports;

    return Object.keys(allReports).reduce((acc: { [key: string]: IUnifiedHotspot[] }, reportType: string) => {
      acc[reportType] = allReports[reportType].filter((report) => {
        const reportZoneID = groves.find((grove) => grove.id === report.groveID)?.zoneID;
        return reportZoneID === zoneID;
      });
      return acc;
    }, {});
  }, [bulletinReports, manualHotspots, replantReports, groves, zoneID]);

  return {
    reports,
    isPending
  };
};

const useBulletinsEntity = (farmID: string, surveyID: string, groves: IGrove[], zoneID?: string, replantReports?: TAnyReport[], showFullStats = false): IBulletinsResult => {
  const [isPending, setIsPending] = useState(false);
  const getBulletinReports = useFetchBulletin();
  const [bulletinReports, setBulletinReports] = useState<{ [key: string]: TAnyReport[] }>({});
  const farmBulletinThresholds = useSelector(getFarmBulletinThresholds);

  useEffect(() => {
    let isSubscribed = true;
    setIsPending(true);

    getBulletinReports(farmID, surveyID, showFullStats)
      .then((reports) => {
        if (isSubscribed) {
          setBulletinReports(reports);
        }

        setIsPending(false);
      })
      .catch(() => setBulletinReports({}));

    return () => {
      isSubscribed = false;
    };
  }, [farmID, surveyID, showFullStats, farmBulletinThresholds, getBulletinReports]);

  useEffect(() => {
    if (isPending) {
      setBulletinReports({});
    }
  }, [isPending]);

  const reports = useMemo(() => {
    const allReports = {
      ...bulletinReports,
      ...(replantReports ? { [EReportType.Replant]: replantUtils.resolveReplantReports(replantReports, bulletinReports[EReportType.Replant]) } : {})
    };

    if (!zoneID) return allReports;

    return Object.keys(allReports).reduce((acc: { [key: string]: IUnifiedHotspot[] }, reportType: string) => {
      acc[reportType] = allReports[reportType].filter((report) => {
        const reportZoneID = groves.find((grove) => grove.id === report.groveID)?.zoneID;
        return reportZoneID === zoneID;
      });
      return acc;
    }, {});
  }, [bulletinReports, replantReports, groves, zoneID]);

  return {
    reports,
    isPending
  };
};

interface IFarmMissionsResult {
  missions: IMission[];
  loading: boolean;
}

const defaultMissions = [];
const useFarmMissions = (farmID?: string): IFarmMissionsResult => {
  const [missions, setMissions] = useState<IMission[]>(defaultMissions);
  const [loading, setLoading] = useState(true);
  const subscribeToMissions = useSubscribeToFarmMissions(setMissions);

  useEffect(() => {
    setLoading(!!farmID);
  }, [farmID]);

  useEffect(() => {
    setLoading(missions === defaultMissions);
  }, [missions]);

  useEffect(() => {
    let unsubscribe;
    if (farmID) {
      unsubscribe = subscribeToMissions(farmID);
    } else {
      setMissions([]);
    }
    return () => {
      unsubscribe && unsubscribe();
    };
  }, [farmID, subscribeToMissions]);

  return {
    missions,
    loading
  };
};

interface IFarmFeedResult {
  feed: IFeed[];
  isPending: boolean;
  reportLocationTreshold: number;
  refetch: () => void;
}

const useFarmFeed = (farmID?: string, surveyID?: string, groveID?: string): IFarmFeedResult => {
  const [feed, setFeed] = useState<IFeed[]>([]);
  const [reportLocationTreshold, setReportLocationTreshold] = useState<number>(0);
  const [isPending, setIsPending] = useState<boolean>(false);
  const fetchFarmFeed = useFetchFarmFeed();
  const fetchMissionsFeed = useFetchAllMissionsReports();
  const fetchReportLocationTreshold = useFetchReportLocationTreshold();

  const getFarmFeed = useCallback(async (): Promise<void> => {
    setFeed([]);
    if (farmID && surveyID) {
      setIsPending(true);
      const params = {
        surveyID,
        farmID,
        groveID,
        internal: false
      };

      let farmFeed = await fetchFarmFeed(params);
      if (MIGRATED_FARMS_FEED[farmID]) {
        farmFeed = farmFeed.filter((entry) => !MIGRATED_FARMS_FEED[farmID].includes(entry.type));
        const migratedReports = await Promise.all(MIGRATED_FARMS_FEED[farmID].map((type) => fetchMissionsFeed(surveyID, type)));
        migratedReports.forEach((reports: IFeed[]) => {
          Array.prototype.push.apply(farmFeed, reports);
        });
      }

      setFeed(farmFeed);
      setIsPending(false);
    }
  }, [fetchFarmFeed, fetchMissionsFeed, farmID, surveyID, groveID]);

  const getReportLocationTreshold = useCallback(async (): Promise<void> => {
    setReportLocationTreshold(await fetchReportLocationTreshold());
  }, [fetchReportLocationTreshold]);

  useEffect(() => {
    getFarmFeed();
  }, [getFarmFeed]);

  useEffect(() => {
    getReportLocationTreshold();
  }, [getReportLocationTreshold]);

  return {
    feed,
    isPending,
    reportLocationTreshold,
    refetch: getFarmFeed
  };
};

interface IFarmPoiResult {
  poi: IPoi[];
}

const useFarmPoi = (farmID?: string, groveID?: string): IFarmPoiResult => {
  const [poi, setPoi] = useState<IPoi[]>([]);
  const fetchPoi = useFetchPoi();

  const getFarmSamples = useCallback(
    async (farmID?: string, groveID?: string) => {
      if (farmID && groveID) {
        setPoi(await fetchPoi(farmID, groveID));
      } else {
        setPoi([]);
      }
    },
    [fetchPoi]
  );

  useEffect(() => {
    getFarmSamples(farmID, groveID);
  }, [farmID, groveID, getFarmSamples]);

  return {
    poi
  };
};

interface INewPublishedGrovesResult {
  groveIDs: string[];
}

const useNewPublishedGroves = (): INewPublishedGrovesResult => {
  const [groveIDs, setGroveIDs] = useState<string[]>([]);
  const selectedSurveyStats = useSelector(selectedSurveyStatsSelector);
  const activeRichItem = useSelector(richItemTypeSelector);
  const { selectedFarm } = useSelectedFarmEntities();
  const signedInUserFarms = signInHooks.useSignedInUserFarms();

  useEffect(() => {
    if (!selectedFarm || !selectedSurveyStats.length) return;

    const farmVisit = signedInUserFarms?.[selectedFarm.id];

    if (activeRichItem !== ERichItemType.Health) {
      setGroveIDs([]);
    } else if (!farmVisit?.visitedAt) {
      setGroveIDs(selectedSurveyStats.map((stat) => stat.groveID));
    } else {
      setGroveIDs(selectedSurveyStats.filter((stat) => stat.isPublished && stat.publishedAt > (farmVisit.visitedAt as number)).map((stat) => stat.groveID));
    }
  }, [selectedFarm, selectedSurveyStats, signedInUserFarms, activeRichItem]);

  return {
    groveIDs
  };
};

const farmsHooks = {
  useGetFarms,
  useGetFarmZones,
  useGetFarmGroves,
  useGetFarmVectiles,
  useGetFarmSurveys,
  useFarmReports,
  useFarmBulletinReports,
  useGroveTrees,
  useGroveRastersTiles,
  useRichItems,
  useGrovesRichItems,
  useGetFarmWeather,
  useRichItemsDefaultConfigs,
  useRichItemsStatistic,
  useRichItemGeoJSON,
  useFarmMapInfo,
  useFarmMissions,
  useFarmFeed,
  useSelectedFarmEntities,
  useFarmPoi,
  useFarmEntities,
  useBulletins,
  useSelectedRichItem,
  useNewPublishedGroves,
  useFarmHotspots,
  useFetchRichItemGeoJSON,
  useBulletinsEntity
};

export default farmsHooks;
