import React, { useState, useCallback, useMemo, useEffect } from 'react';
import styled from 'styled-components';
import { Trans, useTranslation } from 'react-i18next';
import { Line as ProgressBar } from 'rc-progress';
import { useSelector } from 'react-redux';
import { mfColors } from 'vars';

import Button from 'atomicComponents/Button';
import Input from 'atomicComponents/Input';
import Radio from 'atomicComponents/Radio';
import { ICreateMissionIncompleteParams, EBudgetType } from 'models/mission';
import { ICreateFeedIncompleteParams } from 'models/feed';
import { ISurveyWithTimeRange } from 'models/survey';
import { EMeasurementSystem } from 'models/region';
import { IUserInfo } from 'models/user';
import { IGrove } from 'models/grove';
import { IGroveSurveyStats } from 'models/stats';
import signInHooks from 'hooks/signIn.hooks';
import farmsHooks from 'hooks/farms.hooks';
import missionsHooks from 'hooks/mission.hooks';
import { regionSelector } from 'redux/region/regionSlice';
import missionUtils from 'utils/mission';
import numberUtils from 'utils/numbers';
import { useFetchGrovesStats, TGrovesParam } from 'services/data/farms';
import { IZone } from 'models/zone';

const Wrapper = styled.div`
  width: 100%;
`;

const Content = styled.div`
  font-size: 16px;
  padding: 0 4px;
  margin: 16px 0 0;
  min-height: 276px;
`;

const Title = styled.div`
  font-size: 20px;
  font-family: 'MontserratBold';
  margin: 0 0 16px;
`;

const Controls = styled.div`
  margin: 10px 0 0;
  display: flex;
  justify-content: flex-end;

  > * {
    margin: 0 0 0 12px;
  }
`;

const BudgetOptions = styled.div`
  display: flex;
  flex-direction: row;
  margin: 0 0 24px;

  .column {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    label {
      display: block;
      margin: 0 0 16px;
    }

    &:nth-child(1) {
      padding: 0 0 8px;
      border-bottom: 1px solid ${mfColors.grey};
      border-bottom-style: dotted;
    }

    &:nth-child(2) {
      align-items: flex-end;
    }
  }
`;

const BudgetStats = styled.div`
  border-radius: 24px;
  padding: 24px 24px 24px 68px;
  background: ${mfColors.superLightBlueBg};
  min-width: 450px;
  min-height: 80px;
  position: relative;

  .stat {
    font-size: 14px;
    line-height: 24px;
    color: ${mfColors.darkGrey};
  }

  &::before {
    content: 'i';
    display: block;
    position: absolute;
    top: 50%;
    left: 12px;
    margin-top: -24px;
    color: ${mfColors.white};
    width: 48px;
    height: 48px;
    text-align: center;
    line-height: 48px;
    font-size: 40px;
    font-family: Monospace;
  }
`;

const BudgetLabel = styled.div`
  margin: 0 0 0 16px;
`;

const SampleText = styled.div`
  margin: 0 0 24px;
`;

const AreaSampleText = styled(SampleText)`
  > span,
  input {
    margin-right: 4px;
  }
`;

const WarningText = styled.div`
  color: ${mfColors.red};
`;

const Progress = styled.div`
  margin: 24px 0;
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  .progress-title {
    font-size: 16px;
    margin: 0 0 24px;
    font-family: 'MontserratBold';
  }
`;

const TREE_COUNT_INPUT_STYLE = {
  width: '75px',
  padding: '0 12px',
  height: '32px',
  display: 'inline',
  textAlign: 'center'
};

export interface IPestPreselectedTree {
  // eslint-disable-next-line camelcase
  border_tree: string;
  farmID: string;
  groveID: string;
  score: string;
  zoneID: string;
  surveyID: string;
  treeID: string;
  treeGeom: string;
}

const DEFAULT_TREE_COUNT = 10;
const DEFAULT_SAMPLE_COUNT = 1;
const DEFAULT_PER_HA_TREE_COUNT = 0.1;
const MAX_TREES_COUNT = 18_000;

interface IProps {
  selectedSurvey: ISurveyWithTimeRange;
  missionParams: ICreateMissionIncompleteParams;
  farmID: string;
  zoneID: string | null;
  onSelectFeed: (feed: ICreateFeedIncompleteParams[], groves?: string[]) => void;
  zones: IZone[];
  onCancel?: () => void;
}

const GeneralSmartSampling = ({ selectedSurvey, missionParams, farmID, zoneID, zones, onSelectFeed, onCancel }: IProps) => {
  const [budgetType, setBudgetType] = useState<EBudgetType>(EBudgetType.PerBlock);
  const [treesCount, setTreesCount] = useState<number>(DEFAULT_TREE_COUNT);
  const [areaCount, setAreaCount] = useState(DEFAULT_SAMPLE_COUNT);
  const [sampleTreeCount, setSampleTreeCount] = useState(DEFAULT_SAMPLE_COUNT);
  const { surveyGroves: groves, selectedSurveyStats } = farmsHooks.useFarmEntities();
  const region = useSelector(regionSelector);
  const [surveyStats, setSurveyStats] = useState<IGroveSurveyStats[]>([]);
  const fetchGrovesStats = useFetchGrovesStats();
  const user = signInHooks.useSignedInUser();
  const { progress, getSampledTrees } = missionsHooks.useGetSampledTrees(user as IUserInfo, missionParams, farmID);
  const { t } = useTranslation();

  useEffect(() => {
    setTreesCount(budgetType === EBudgetType.PerBlock ? DEFAULT_TREE_COUNT : DEFAULT_PER_HA_TREE_COUNT);
  }, [budgetType]);

  useEffect(() => {
    fetchGrovesStats(farmID).then(setSurveyStats);
  }, [farmID, fetchGrovesStats]);

  const groveParams = useMemo(() => {
    const groveParams = groves
      .filter((grove) => (!zoneID || grove.zoneID === zoneID) && grove.active !== false)
      .map((grove) => {
        let survey = '';
        let stats = selectedSurveyStats.find((stat) => stat.groveID === grove.id && stat.isPublished) || null;
        if (stats) {
          survey = selectedSurvey.id;
        } else {
          // prettier-ignore
          stats = surveyStats.filter((stat) => stat.groveID === grove.id).reduce((acc: IGroveSurveyStats | null, entry: IGroveSurveyStats) => {
            if (entry.isPublished && (!acc || acc.publishedAt < entry.publishedAt)) {
              acc = entry;
            }
            return acc;
          }, null);
          survey = stats?.surveyID || '';
        }

        return {
          groveID: grove.id,
          farmID,
          surveyID: survey,
          total: stats ? missionUtils.getTreesSum([grove], [stats], undefined, false) : 0,
          area: numberUtils.getGrovesArea([grove], region?.measurementSystem || EMeasurementSystem.Metric)
        };
      })
      .filter((param) => !!param.surveyID && !!param.total) as TGrovesParam;
    return groveParams;
  }, [groves, region, selectedSurveyStats, surveyStats, selectedSurvey.id, farmID, zoneID]);

  const grovesArea = useMemo(() => {
    const grovesFromParams = groveParams.map((param) => groves.find((entry) => entry.id === param.groveID)) as IGrove[];

    return numberUtils.getGrovesArea(grovesFromParams, region?.measurementSystem || EMeasurementSystem.Metric);
  }, [groves, region, groveParams]);

  const maxTreesCount = useMemo(() => Math.floor(MAX_TREES_COUNT / groveParams.length), [groveParams.length]);
  const maxTreesCountPerArea = useMemo(() => Math.floor(MAX_TREES_COUNT / grovesArea), [grovesArea]);

  const handleSubmit = useCallback(async () => {
    const treesFeed = await getSampledTrees(groveParams, numberUtils.normalizeDecimal(treesCount, 3), budgetType, region?.measurementSystem);
    setTimeout(() => {
      onSelectFeed(
        treesFeed,
        groveParams.map((param) => param.groveID)
      );
    }, 600);
  }, [groveParams, getSampledTrees, onSelectFeed, treesCount, budgetType, region?.measurementSystem]);

  const treesToSample = useMemo(() => {
    if (budgetType === EBudgetType.PerBlock) {
      return treesCount * groveParams.length;
    } else {
      return Math.round(grovesArea * treesCount);
    }
  }, [budgetType, groveParams, treesCount, grovesArea]);

  const treesToSamplePercentage = useMemo(() => {
    const totalTrees = groveParams.reduce((acc: number, entry) => acc + entry.total, 0);
    return numberUtils.normalizeDecimal(numberUtils.convertToPercentage(treesToSample / totalTrees), 2);
  }, [treesToSample, groveParams]);

  return (
    <Wrapper>
      {progress !== null && (
        <Progress>
          <div className="progress-title">{`${progress}%`}</div>
          <ProgressBar percent={progress} strokeWidth={1} strokeColor="rgb(0, 94, 236)" />
        </Progress>
      )}
      {progress === null && (
        <>
          <Content>
            <BudgetOptions>
              <div className="column">
                <Title>{t('missions.tree_budget_by')}</Title>
                <div onChange={(e: React.ChangeEvent<HTMLInputElement>) => setBudgetType(e.target.value as EBudgetType)}>
                  <Radio
                    name="budget"
                    value={EBudgetType.PerBlock}
                    checked={budgetType === EBudgetType.PerBlock}
                    label={<BudgetLabel>{t('missions.trees_per_block')}</BudgetLabel>}
                  />
                  <Radio
                    name="budget"
                    value={EBudgetType.PerArea}
                    checked={budgetType === EBudgetType.PerArea}
                    label={<BudgetLabel>{t(region?.measurementSystem === EMeasurementSystem.Metric ? 'missions.trees_per_hectare' : 'missions.trees_per_acre')}</BudgetLabel>}
                  />
                </div>
              </div>
              <div className="column">
                <BudgetStats>
                  <div className="stat">{t('missions.total_of_x_trees_to_sample', { trees: treesToSample.toLocaleString() })}</div>
                  <div className="stat">{t('missions.represent_x_of_all_trees', { percent: `${treesToSamplePercentage}%` })}</div>
                </BudgetStats>
              </div>
            </BudgetOptions>
            <Title>{t('missions.how_many_trees_to_sample')}</Title>
            {budgetType === EBudgetType.PerBlock && (
              <>
                <SampleText>
                  <Trans
                    i18nKey="missions.sample_x_trees_for_each_block"
                    t={t}
                    components={[
                      <Input
                        value={treesCount.toString()}
                        type="number"
                        min={1}
                        max={maxTreesCount}
                        onChange={({ value }) => {
                          setTreesCount(+value);
                        }}
                        name="trees-count"
                        style={TREE_COUNT_INPUT_STYLE}
                      />
                    ]}
                  />
                </SampleText>
                {treesCount === maxTreesCount && (
                  <div style={{ margin: '0 0 14px', lineHeight: '20px' }}>
                    <WarningText>{t('missions.reached_max_trees_per_block')}</WarningText>
                    {zones.length > 1 && !zoneID && <WarningText>{t('missions.single_zone_higher_budget')}</WarningText>}
                  </div>
                )}
              </>
            )}
            {budgetType === EBudgetType.PerArea && (
              <>
                <AreaSampleText>
                  <span>{t('shared.for_every')}</span>
                  {sampleTreeCount === 1 && (
                    <Input
                      value={areaCount.toString()}
                      type="number"
                      min={1}
                      max={99}
                      onChange={({ value }) => {
                        setAreaCount(+value);
                        setTreesCount(1 / +value);
                      }}
                      name="trees-count"
                      style={TREE_COUNT_INPUT_STYLE}
                    />
                  )}
                  {sampleTreeCount !== 1 && <span>1</span>}
                  <span>{region?.measurementSystem === EMeasurementSystem.Metric ? `hectare${areaCount > 1 ? 's' : ''}` : `acre${areaCount > 1 ? 's' : ''}`}</span>
                  <span style={{ textTransform: 'lowercase' }}>{t('missions.sample')}</span>
                  {areaCount === 1 && (
                    <Input
                      value={sampleTreeCount.toLocaleString()}
                      type="number"
                      min={1}
                      max={maxTreesCountPerArea}
                      onChange={({ value }) => {
                        setSampleTreeCount(+value);
                        setTreesCount(+value);
                      }}
                      name="trees-count"
                      style={TREE_COUNT_INPUT_STYLE}
                    />
                  )}
                  {areaCount !== 1 && <span>1</span>}
                  <span style={{ textTransform: 'lowercase' }}>{sampleTreeCount > 1 ? t('shared.trees') : t('main.feed.tree_timeline.tree')}</span>
                </AreaSampleText>
                {sampleTreeCount === maxTreesCountPerArea && (
                  <div style={{ margin: '0 0 14px', lineHeight: '20px' }}>
                    <WarningText>{t('missions.reached_max_trees_per_block')}</WarningText>
                    {zones.length > 1 && !zoneID && <WarningText>{t('missions.single_zone_higher_budget')}</WarningText>}
                  </div>
                )}
              </>
            )}
          </Content>
          <Controls>
            {!!onCancel && <Button isSecondary onClick={onCancel} text={t('missions.manual_sampling')} />}
            <Button onClick={handleSubmit} text={t('missions.scouting_mission_select_random_trees')} />
          </Controls>
        </>
      )}
    </Wrapper>
  );
};

export default GeneralSmartSampling;
