import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { useImportScript } from 'hooks/util.hooks';

import LocalLoader from 'atomicComponents/LocalLoader';

import mfColors from 'vars/colors';

const SisenseContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const SisenseRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin: 0 0 24px 0;
  border-radius: 8px;

  > div {
    padding: 48px 0 0;
    position: relative;
  }
`;

const WidgetColumn = styled.div`
  width: 320px;
`;

const WidgetColumnLarge = styled.div`
  width: calc(100% - 320px);
`;

const WidgetColumnFull = styled.div`
  width: 100%;
`;

const WidgetTitle = styled.div`
  color: ${mfColors.grey};
  font-size: 16px;
  font-family: MontserratBold;
  position: absolute;
  top: 24px;
  left: 24px;
  z-index: 1;
`;

const membersFilter = (title: string, dim: string, filterValue: string | string[], datatype = 'text') => ({
  title,
  dim,
  datatype,
  filter: filterValue ? { members: [filterValue] } : null
});

const allIfNoLengthFilter = (title: string, dim: string, filterValue: string, datatype = 'text') => ({
  title,
  dim,
  datatype,
  filter: filterValue ? { members: [filterValue] } : { all: true }
});

const FACTS_FRUITS_CONFIG = {
  by: {
    table: 'facts_fruits',
    column: 'countPredict',
    dim: '[facts_fruits.countPredict]',
    datatype: 'numeric',
    agg: 'sum'
  },
  top: 20,
  filter: undefined
};

interface IWidget {
  title: string;
  container: HTMLElement | null;
}

interface IDashboard {
  widgets: IWidget[];
  refresh: () => void;
  filters: {
    clear: () => void;
    insert: (filter: { jaql: IJaql }, index: number) => void;
  };
}

interface ISisenseApp {
  dashboards: {
    load: (id: string) => Promise<IDashboard>;
  };
}

interface IConfigEntry {
  title: string;
  dim: string;
  level?: string;
  filter: {
    members?: any[];
    by?: {
      table: string;
      column: string;
      dim: string;
      datatype: string;
      agg: string;
    };
    top?: number;
  } | null;
  datatype?: string;
}

interface IJaql extends IConfigEntry {
  datatype: string;
}

interface IProps {
  dashboardID: string;
  filters: { [key: string]: string | string[] | null };
}

const SisenseJS = ({ dashboardID, filters }: IProps): JSX.Element => {
  const treesCountContainer = useRef(null);
  const treesPerFarmContainer = useRef(null);
  const scoresDistributionAbsoluteContainer = useRef(null);
  const scoresDistributionNormalizedContainer = useRef(null);
  const cipoContainer = useRef(null);
  const [app, setApp] = useState<ISisenseApp | null>(null);
  const [dashboard, setDashboard] = useState<IDashboard | null>(null);
  const [sisenseScriptLoaded, setSisenseScriptLoaded] = useState(false);

  useImportScript('https://seetree.sisense.com/js/sisense.js', setSisenseScriptLoaded);

  useEffect(() => {
    if (!filters) {
      return;
    }
    const dashboardFilters: { jaql: IJaql }[] = [];

    const allIfNoLength = (filter: string[]) => {
      if (!filter) {
        return null;
      }

      return filter.length ? { members: filter } : { all: true };
    };

    [
      membersFilter('customerName', '[dim_farms.customerName]', filters.customerName as string),
      membersFilter('customerSurveyName', '[dim_surveys.customerSurveyName]', filters.customerSurveyName as string),
      allIfNoLengthFilter('farmName', '[dim_farms.farmName]', filters.farmName as string),
      {
        title: 'commodity',
        dim: '[facts_rio_bravo_yield.variety1]',
        filter: allIfNoLength(filters.commodities as string[])
      },
      {
        title: 'varieties',
        dim: '[facts_rio_bravo_yield.variety2]',
        filter: allIfNoLength(filters.varieties as string[])
      },
      {
        title: 'publishedAt',
        dim: '[dim_groves_publish_status.publishedAt (Calendar)]',
        level: 'years',
        filter: filters.publishedAt ? { members: [filters.publishedAt] } : null,
        datatype: 'datetime'
      },
      {
        title: 'variety1',
        dim: '[facts_rio_bravo_yield.variety1]',
        filter: allIfNoLength(filters.variety1 as string[])
      },
      {
        title: 'groveName',
        dim: '[dim_groves.groveName]',
        filter: filters.countPredict ? FACTS_FRUITS_CONFIG : null
      }
    ].forEach((config: IConfigEntry) => {
      if (!config.filter) {
        return;
      }

      dashboardFilters.push({
        jaql: {
          datatype: config.datatype || 'text',
          ...config
        }
      });
    });
    async function updateSisenseFilters() {
      if (dashboard) {
        const filterOptions = {
          save: false,
          refresh: true,
          unionIfSameDimensionAndSameType: false
        };
        dashboardFilters.forEach((jql) => {
          (dashboard as any).$$model.filters.update(jql, filterOptions);
        });
      }
    }
    updateSisenseFilters();
  }, [filters, dashboard]);

  useEffect(() => {
    if (!dashboard) return;
    const widgets = [...dashboard.widgets];
    const refs = [
      treesCountContainer.current,
      treesPerFarmContainer.current,
      scoresDistributionAbsoluteContainer.current,
      scoresDistributionNormalizedContainer.current,
      cipoContainer.current
    ];

    widgets.forEach((widget, idx) => {
      widget.container = refs[idx];
    });

    dashboard.refresh();
  }, [dashboard]);

  useEffect(() => {
    const sisense = (window as any).Sisense;
    if (!sisenseScriptLoaded || !!app || !sisense) return;

    sisense.connect('https://seetree.sisense.com').then(setApp);
  }, [sisenseScriptLoaded, app]);

  useEffect(() => {
    if (!app) return;
    app.dashboards.load(dashboardID).then(setDashboard);
  }, [app, dashboardID]);

  return (
    <SisenseContainer id="sisenseApp">
      {dashboard ? (
        <>
          <SisenseRow>
            <WidgetColumn ref={treesCountContainer}>
              <WidgetTitle>{[...dashboard.widgets][0]?.title}</WidgetTitle>
            </WidgetColumn>
            <WidgetColumnLarge ref={treesPerFarmContainer}>
              <WidgetTitle>{[...dashboard.widgets][1]?.title}</WidgetTitle>
            </WidgetColumnLarge>
          </SisenseRow>
          <SisenseRow>
            <WidgetColumnFull ref={scoresDistributionAbsoluteContainer}>
              <WidgetTitle>{[...dashboard.widgets][2]?.title}</WidgetTitle>
            </WidgetColumnFull>
          </SisenseRow>
          <SisenseRow>
            <WidgetColumnFull ref={scoresDistributionNormalizedContainer}>
              <WidgetTitle>{[...dashboard.widgets][3]?.title}</WidgetTitle>
            </WidgetColumnFull>
          </SisenseRow>
          <SisenseRow>
            <WidgetColumnFull ref={cipoContainer}>
              <WidgetTitle>{[...dashboard.widgets][4]?.title}</WidgetTitle>
            </WidgetColumnFull>
          </SisenseRow>
        </>
      ) : (
        <LocalLoader />
      )}
    </SisenseContainer>
  );
};

export default SisenseJS;
