/* eslint-disable camelcase */
import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from 'redux/store';
import { IReplantRules } from 'models/replant';
import { ICapacityStatistic } from 'models/statistic';
import { grovesSelector, selectedSurveyStatsSelector } from 'redux/farm/farmSlice';
import replantUtils from 'utils/replant';
import { IReplantReport } from 'models/report';
import { ICapacitySegment } from 'services/data/replant';
import { ICapacity } from 'models/tree';
import numberUtils from 'utils/numbers';

interface IReplantState {
  farmReplantRules: IReplantRules;
  stateReplantRules: IReplantRules | null;
  replantsIsLoaded: boolean;
  replantCapacity: ICapacityStatistic[] | null;
  checkedReplantGroves: IReplantReport[];
  hiddenSegments: ICapacity[];
  newSegments: ICapacitySegment[];
}

const initialState = {
  stateReplantRules: null,
  farmReplantRules: {},
  replantCapacity: null,
  replantsIsLoaded: false,
  checkedReplantGroves: [] as IReplantReport[],
  hiddenSegments: [] as ICapacity[],
  newSegments: [] as ICapacitySegment[]
} as IReplantState;

const slice = createSlice({
  name: 'replant',
  initialState,
  reducers: {
    setFarmReplantRules: (state, action: PayloadAction<IReplantRules>) => ({ ...state, farmReplantRules: action.payload }),
    setStateReplantRules: (state, action: PayloadAction<IReplantRules | null>) => ({ ...state, stateReplantRules: action.payload }),
    updateStateReplantRule: (state, action: PayloadAction<{ [key: string]: string }>) => {
      const { stateReplantRules } = state;
      const newRules = {} as IReplantRules;

      if (stateReplantRules) {
        Object.assign(newRules, { ...stateReplantRules });
      }

      Object.assign(newRules, { ...action.payload });

      return { ...state, stateReplantRules: newRules };
    },
    setReplantCapacity: (state, action: PayloadAction<ICapacityStatistic[] | null>) => ({ ...state, replantCapacity: action.payload }),
    setReplantsIsLoaded: (state, action: PayloadAction<boolean>) => ({ ...state, replantsIsLoaded: action.payload }),
    setCheckedReplantGroves: (state, action: PayloadAction<IReplantReport[]>) => ({ ...state, checkedReplantGroves: action.payload }),
    addNewSegment: (state, action: PayloadAction<ICapacitySegment>) => {
      state.newSegments.push(action.payload);
    },
    resetNewSegments: (state) => {
      state.newSegments = [];
    },
    updateHiddenSegments: (state, action: PayloadAction<ICapacity>) => {
      state.hiddenSegments.push(action.payload);
    }
  }
});

export const replantReducer = slice.reducer;

export const farmReplantRulesSelector = (state: RootState) => state.replant.farmReplantRules;
export const stateReplantRulesSelector = (state: RootState) => state.replant.stateReplantRules;
export const replantCapacityStatisticSelector = (state: RootState) => state.replant.replantCapacity;
export const replantsIsLoadedSelector = (state: RootState) => state.replant.replantsIsLoaded;
export const checkedReplantGrovesSelector = (state: RootState) => state.replant.checkedReplantGroves;
export const hiddenSegmentsSelector = (state: RootState) => state.replant.hiddenSegments.map((item) => item.segmentID);
export const hiddenSegmentsFullSelector = (state: RootState) => state.replant.hiddenSegments;
export const newSegmentsSelector = (state: RootState) => state.replant.newSegments;

export const replantRulesSelector = createSelector([farmReplantRulesSelector, stateReplantRulesSelector], (farmReplantRules, stateReplantRules) => {
  const rules = stateReplantRules && Object.keys(stateReplantRules).length ? stateReplantRules : farmReplantRules;

  return rules;
});

export const capacityStatisticByRulesSelector = createSelector(
  [replantRulesSelector, replantCapacityStatisticSelector, grovesSelector, hiddenSegmentsFullSelector, newSegmentsSelector, selectedSurveyStatsSelector],
  (replantRules, replantCapacity, groves, hiddenSegments, newSegments, groveSurveyStats) => {
    if (!replantCapacity || !replantRules || !replantCapacity.length || !Object.keys(replantRules).length) return null;

    const hiddenSegmentsLength = hiddenSegments.map((item) => numberUtils.normalizeDecimal(item.length, 4));
    const hiddenSegmentsIDs = hiddenSegments.map((item) => item.segmentID);

    const statisticFromNewSegments = newSegments
      .filter((item) => !hiddenSegmentsIDs.includes(item.id as string))
      .map((item) => ({
        groveID: item.groveID,
        length: item.length as number,
        statistic: item.length as number,
        segmentID: item.id as string
      }));

    const filteredReplants = [...replantCapacity, ...statisticFromNewSegments]
      .filter((item) => !hiddenSegmentsIDs.includes(item.segmentID as string))
      .filter((item) => !hiddenSegmentsLength.includes(item.length));
    const capacity = replantUtils.calculateReplantCapacityByRules(filteredReplants, replantRules, groves, groveSurveyStats);

    return capacity;
  }
);

export const capacityReplantReportsSelector = createSelector([capacityStatisticByRulesSelector], (capacityStatistic) => {
  if (!capacityStatistic) return [];

  return Object.keys(capacityStatistic).reduce((acc, key) => {
    const { statistic } = capacityStatistic[key] as ICapacityStatistic;

    return acc.concat({ groveID: key, replants: statistic });
  }, [] as IReplantReport[]);
});

export const isReplantAvailableSelector = createSelector([farmReplantRulesSelector, replantCapacityStatisticSelector], (farmReplantRules, replantCapacity) => {
  const isActive = replantCapacity && replantCapacity.length && farmReplantRules && Object.keys(farmReplantRules).length;

  return !!isActive;
});

export const {
  setFarmReplantRules,
  setStateReplantRules,
  updateStateReplantRule,
  setReplantCapacity,
  setReplantsIsLoaded,
  setCheckedReplantGroves,
  addNewSegment,
  updateHiddenSegments,
  resetNewSegments
} = slice.actions;
