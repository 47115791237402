import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faChevronLeft } from '@fortawesome/pro-solid-svg-icons';
import { SCORE_COLORS } from 'models/colors';
import { mfColors } from 'vars';

const Wrapper = styled.div`
  display: flex;
  height: 80px;

  .chevron {
    cursor: pointer;
  }
`;

const Carousel = styled.div`
  width: 230px;
  position: relative;
  overflow: hidden;
  box-sizing: border-box;
  margin: 0 auto;
`;

const CarouselWrapper = styled.div`
  position: absolute;
  flex-direction: row;
  display: flex;
  z-index: 1;
  transition: all 0.25s ease 0s;
  transform: translate3d(-56px, 0px, 0px);
  width: 392px;
`;

const CarouselItem = styled.div`
  display: flex;
  width: 56px;
  z-index: 1000;
`;

const SurveyContainer = styled.div`
  margin: 0 auto;
  display: block;
  text-align: center;
  position: relative;
  z-index: 1;
  cursor: pointer;
`;

const SurveyCircleWrapper = styled.div`
  display: flex;
  height: 29px;
  width: 25px;
  margin: 0 auto;
  background-color: ${mfColors.sideBarBackground};
`;

const SurveyCircle = styled.div<{ isSelected: boolean }>`
  height: ${(props) => (props.isSelected ? '18px' : '13px')};
  width: ${(props) => (props.isSelected ? '18px' : '13px')};
  margin: ${(props) => (props.isSelected ? '0px auto auto' : '3px auto auto')};
  border-radius: 100%;
  border: 1px solid transparent;
  transition: all 0.2s ease-in-out;
  background-color: ${(props) => props.color};
`;

const DateTitle = styled.div`
  font-family: MontserratBold;
`;

const Line = styled.div`
  position: absolute;
  top: 8px;
  height: 2px;
  background-color: ${mfColors.grey};
  left: 0;
  right: 0;
`;

interface IProps {
  trees: {
    score: number;
    surveyID: string;
    date: string;
  }[];
  selected: string;
  handleChange: (surveyID: string) => void;
}

const NUMBER_OF_VISIBLE_ITEMS: number = 4;
const CIRCLE_WIDTH: number = 56;

const TreeCarousel = ({ trees, selected, handleChange }: IProps) => {
  const isSelected = (surveyID: string) => selected === surveyID;
  const [firstVisibleSurveyIndex, setFirstVisbileSurveyIndex] = useState(0);

  const getCarouselWrapperStyles = (offset) => {
    const style = { transform: `translate3d(${offset}px, 0px, 0px)`, width: `${trees.length * CIRCLE_WIDTH}px` };
    return style;
  };

  const selectedIndex = useMemo(() => trees.findIndex((tree) => tree.surveyID === selected), [trees, selected]);
  const carouselOffset = useMemo(() => -firstVisibleSurveyIndex * CIRCLE_WIDTH, [firstVisibleSurveyIndex]);

  useEffect(() => {
    if (selectedIndex >= 0 && selectedIndex < NUMBER_OF_VISIBLE_ITEMS) {
      setFirstVisbileSurveyIndex(0);
    }

    if (selectedIndex > trees.length - NUMBER_OF_VISIBLE_ITEMS || selectedIndex < 0) {
      setFirstVisbileSurveyIndex(trees.length - NUMBER_OF_VISIBLE_ITEMS);
    }

    if (selectedIndex >= NUMBER_OF_VISIBLE_ITEMS && selectedIndex <= trees.length - NUMBER_OF_VISIBLE_ITEMS) {
      setFirstVisbileSurveyIndex(selectedIndex - NUMBER_OF_VISIBLE_ITEMS + 1);
    }
  }, [trees, selectedIndex]);

  const handleClick = (surveyID: string) => {
    if (isSelected(surveyID)) return;

    handleChange(surveyID);
  };

  const clickDirection = (direction: string) => {
    if (selectedIndex < 0) {
      return;
    }

    if (firstVisibleSurveyIndex === -NUMBER_OF_VISIBLE_ITEMS || firstVisibleSurveyIndex === trees.length) {
      return;
    }

    const isDirectionLeft = direction === 'left';
    const nextSurveyIndex = isDirectionLeft ? selectedIndex - 1 : selectedIndex + 1;
    const tree = trees[nextSurveyIndex];

    if (tree) {
      handleChange(tree.surveyID);
    }
  };

  return (
    <Wrapper>
      <FontAwesomeIcon className="chevron" icon={faChevronLeft} size="lg" width="32px" height={32} fixedWidth onClick={() => clickDirection('left')} />
      <Carousel>
        <CarouselWrapper style={getCarouselWrapperStyles(carouselOffset)}>
          {trees.map((tree) => (
            <CarouselItem key={tree.surveyID}>
              <SurveyContainer onClick={() => handleClick(tree.surveyID)}>
                <SurveyCircleWrapper>
                  <SurveyCircle isSelected={isSelected(tree.surveyID)} color={SCORE_COLORS[tree.score].fill} />
                </SurveyCircleWrapper>
                <DateTitle>{tree.date}</DateTitle>
              </SurveyContainer>
            </CarouselItem>
          ))}
        </CarouselWrapper>
        <Line />
      </Carousel>
      <FontAwesomeIcon className="chevron" icon={faChevronRight} size="lg" width="32px" height={32} fixedWidth onClick={() => clickDirection('right')} />
    </Wrapper>
  );
};

export default TreeCarousel;
