import { IUserInfo } from 'models/user';
import { IUnifiedHotspot } from 'models/hotspot';
import { ERichItemType } from 'models/richItem';

export enum EEventType {
  HotspotClick = 'hotspotClick',
  SignIn = 'signIn',
  SignOut = 'signOut',
  SelectFarm = 'selectFarm',
  SelectGrove = 'selectGrove',
  OpenFarmBulletin = 'openFarmBulletin',
  OpenDashboard = 'openDashboard',
  SelectRichItem = 'selectRichItem',
  CreateMission = 'createMission',
  OpenSplitScreen = 'openSplitScreen',
  SelectSurvey = 'selectSurvey',
  OpenWeatherWidget = 'openWeatherWidget',
  OpenMeasurementTool = 'openMeasurementTool',
  OpenNotifications = 'openNotifications',
  SatelliteTimelineHotspotClick = 'satelliteTimelineHotspotClick',
  SatelliteEvidenceClick = 'SatelliteEvidenceClick',
  MissionEnter = 'MissionEnter',
  MissionCreateFromBulletinClicked = 'MissionCreateFromBulletinClicked',
  MissionCreateClicked = 'MissionCreateClicked',
  MissionSelectZoneClicked = 'MissionSelectZoneClicked',
  MissionSelectTypeClicked = 'MissionSelectTypeClicked',
  MissionCreate = 'MissionCreate',
  MissionCreateExit = 'MissionCreateExit',
  MissionGroveResolve = 'MissionGroveResolve',
  MissionResolve = 'MissionResolve',
  MissionDelete = 'MissionDelete',
  MissionEditClicked = 'MissionEditClicked',
  MissionEdit = 'MissionEdit',
  GoToNewDisplay = 'GoToNewDisplay'
}

export interface IHotspotEventParams {
  type: EEventType.HotspotClick;
  params: Pick<IUnifiedHotspot, 'clusterID' | 'farmID' | 'groveID'>;
}

export interface ISignInEventParams {
  type: EEventType.SignIn;
  params: Pick<IUserInfo, 'email'>;
}

export interface ISignOutEventParams {
  type: EEventType.SignOut;
  params: Pick<IUserInfo, 'email'>;
}

export interface ISelectFarmParams {
  type: EEventType.SelectFarm;
  params: {
    email: string;
    farmName: string;
  };
}

export interface IOpenFarmBulletinParams {
  type: EEventType.OpenFarmBulletin;
  params: {
    email: string;
    farmName: string;
    bulletin: string;
  };
}

export interface ISelectGroveParams {
  type: EEventType.SelectGrove;
  params: {
    email: string;
    farmName: string;
    groveName: string;
  };
}

export interface IOpenDashboardParams {
  type: EEventType.OpenDashboard;
  params: {
    email: string;
    dashboard: string;
  };
}

export interface ISelectRichItemParams {
  type: EEventType.SelectRichItem;
  params: {
    email: string;
    farmName: string;
    groveName: string;
    richItemType: ERichItemType;
  };
}

export interface ICreateMissionParams {
  type: EEventType.CreateMission;
  params: {
    email: string;
    name: string;
  };
}

export interface IOpenSplitScreenParams {
  type: EEventType.OpenSplitScreen;
  params: {
    email: string;
    farmName: string;
    groveName: string;
  };
}

export interface ISelectSurveyParams {
  type: EEventType.SelectSurvey;
  params: {
    email: string;
    farmName: string;
    groveName: string;
    surveyName: string;
  };
}

export interface IOpenWeatherWidgetParams {
  type: EEventType.OpenWeatherWidget;
  params: {
    email: string;
    farmName: string;
  };
}

export interface IOpenMeasurementToolParams {
  type: EEventType.OpenMeasurementTool;
  params: {
    email: string;
  };
}

export interface IOpenNotificationsParams {
  type: EEventType.OpenNotifications;
  params: {
    email: string;
  };
}

export interface ISatelliteTimelineHotspotClickParams {
  type: EEventType.SatelliteTimelineHotspotClick;
  params: {
    email: string;
    farmName: string;
    detectionDate: string;
  };
}

export interface ISatelliteEvidenceClickParams {
  type: EEventType.SatelliteEvidenceClick;
  params: {
    email: string;
    clusterID: string;
    farmName: string;
    detectionDate: string;
  };
}

export interface IGoToNewDisplayParams {
  type: EEventType.GoToNewDisplay;
  params: {
    email: string;
    userName: string;
    farmName: string;
    url: string;
  };
}

export type TMissionGeneralEventType =
  | EEventType.MissionEnter
  | EEventType.MissionCreate
  | EEventType.MissionResolve
  | EEventType.MissionCreateExit
  | EEventType.MissionDelete
  | EEventType.MissionEditClicked
  | EEventType.MissionEdit;

export interface IMissionEventParams {
  type: TMissionGeneralEventType;
  params: {
    email: string;
    farm: string;
    missionName: string;
    type: string;
    zone: string;
  };
}

export type TMissionCreateClickEventType = EEventType.MissionCreateFromBulletinClicked | EEventType.MissionCreateClicked;

export interface IMissionCreateClickEventParams {
  type: TMissionCreateClickEventType;
  params: {
    email: string;
    farm: string;
  };
}

export interface IMissionSelectZoneEventParams {
  type: EEventType.MissionSelectZoneClicked;
  params: {
    email: string;
    farm: string;
    zone: string;
  };
}

export interface IMissionSelectTypeEventParams {
  type: EEventType.MissionSelectTypeClicked;
  params: {
    email: string;
    farm: string;
    type: string;
  };
}

export interface IMissionGroveResolveEventParams {
  type: EEventType.MissionGroveResolve;
  params: {
    email: string;
    farm: string;
    missionName: string;
    grove: string;
  };
}

export type TEventParams =
  | IHotspotEventParams
  | ISignInEventParams
  | ISignOutEventParams
  | ISelectFarmParams
  | ISelectGroveParams
  | IOpenFarmBulletinParams
  | IOpenDashboardParams
  | ISelectRichItemParams
  | ICreateMissionParams
  | IOpenSplitScreenParams
  | ISelectSurveyParams
  | IOpenWeatherWidgetParams
  | IOpenMeasurementToolParams
  | IOpenNotificationsParams
  | ISatelliteTimelineHotspotClickParams
  | ISatelliteEvidenceClickParams
  | IMissionEventParams
  | IMissionCreateClickEventParams
  | IMissionSelectZoneEventParams
  | IMissionSelectTypeEventParams
  | IMissionGroveResolveEventParams
  | IGoToNewDisplayParams;
