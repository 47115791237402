import { createSlice } from '@reduxjs/toolkit';
import { RootState } from './store';

interface IAuthState {
  mode: boolean;
}
const initialMode = localStorage.getItem('mode');

const slice = createSlice({
  name: 'mode',
  initialState: { mode: !!initialMode } as IAuthState,
  reducers: {
    toggleMode: (state) => ({ ...state, mode: !state.mode })
  }
});

export const { toggleMode } = slice.actions;
export const getMode = (state: RootState) => state.mode.mode;
export const modeReducer = slice.reducer;
