import React, { ChangeEvent } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faPlus } from '@fortawesome/pro-solid-svg-icons';

import mfColors from 'vars/colors';

const Checkmark = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 3px;
  background: transparent;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export enum ESizes {
  small = '16px'
}

interface IWrapperProps {
  isDisabled: boolean;
  size?: ESizes;
}

const Wrapper = styled.label<IWrapperProps>`
  position: relative;
  display: block;
  width: ${({ size }: IWrapperProps) => size || '24px'};
  height: ${({ size }: IWrapperProps) => size || '24px'};

  ${Checkmark} {
    cursor: ${({ isDisabled }: IWrapperProps) => (isDisabled ? 'default' : 'pointer')};
    opacity: ${({ isDisabled }: IWrapperProps) => (isDisabled ? '0.5' : 1)};
    border: ${({ isDisabled }: IWrapperProps) => (isDisabled ? `2px solid ${mfColors.grey}` : `2px solid ${mfColors.blue}`)};
  }

  input {
    width: 0;
    height: 0;
    opacity: 0;
  }

  input:checked + span {
    background-color: ${mfColors.blue};

    &::before {
      display: block;
    }
  }
`;

const CheckIcon = styled(FontAwesomeIcon)`
  color: ${mfColors.white};
`;

interface IProps {
  checked: boolean;
  onCheckedChange: (checked: boolean) => void;
  icon?: IconProp;
  isDisabled?: boolean;
  size?: ESizes;
  style?: { [key: string]: string | number };
}

const Checkbox = ({ checked, icon, onCheckedChange, isDisabled = false, size, style = {} }: IProps): JSX.Element => (
  <Wrapper isDisabled={isDisabled} size={size} style={style}>
    <input disabled={isDisabled} checked={checked} type="checkbox" onChange={(e: ChangeEvent<HTMLInputElement>) => onCheckedChange(e.target.checked)} />
    <Checkmark>{checked && <CheckIcon icon={icon || faPlus} />}</Checkmark>
  </Wrapper>
);

export default Checkbox;
