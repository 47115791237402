import React from 'react';
import { renderToString } from 'react-dom/server';
import styled from 'styled-components';

const MarkerLabel = styled.div`
  font-weight: bold;
  padding: 0px 4px;
  border-radius: 8px;
  transition: color 0.2s ease, background-color 0.2s ease;
  cursor: pointer;
  font-size: 16px;
  background: #ffffbb;
`;

interface IProps {
  title: string;
  color: string;
}

export const GroveMarker = ({ title, color }: IProps): JSX.Element => <MarkerLabel style={{ color }}>{title}</MarkerLabel>;
export const GroveHTMLMarker = ({ title, color }: IProps) => renderToString(<GroveMarker color={color} title={title} />);

export default GroveHTMLMarker;
