import React from 'react';
import styled from 'styled-components';
import numberUtils from 'utils/numbers';
import dateUtils from 'utils/date';
import dateFormats from 'models/date';
import scoreUtils from 'utils/score';
import { ERROR, HEALTHY, NOT_BEARING, UNDER_PERFORMING } from 'models/scores';
import {
  IMission,
  ICreateMissionIncompleteParams,
  EMissionStatus,
  EMissionSubtype,
  TMissionType,
  EMissionType,
  EMissionKpi,
  IWeeklyTrapMonitoringConfig,
  IWeeklyTrapColorsConfig
} from 'models/mission';
import { IUnifiedHotspot } from 'models/hotspot';
import { IFarm } from 'models/farm';
import { IGrove } from 'models/grove';
import { IUserInfo } from 'models/user';
import { IGroveSurveyStats } from 'models/stats';
import { TAnyStatistic, ISimpleStatistic, TMappedStatistic } from 'models/statistic';
import { IScoreDifferenceReport, IWeedsReport, IHeightReport, IFreeAreaReport, EReportType, TAnyReport } from 'models/report';
import { EMeasurementSystem } from 'models/region';
import { ITreeMapboxFeature } from 'models/tree';
import { ICreateFeedIncompleteParams, EFeedStatus, EClippedStatus, missionFeedTypeRelation, IFeed, HLB_INFECTED_STATUSES, HLBSeverityMap, EFeedItemType } from 'models/feed';

import Checkbox, { ESizes } from 'atomicComponents/Checkbox';
import replantUtils from 'utils/replant';
import { BUDS, DEFAULT_PESTS_P3_SETTINGS } from 'hooks/missionsConfig.hooks';
import { IPestsP3Config } from 'models/missions-config';
import { groupBy } from 'utils/helpers';

export enum EMissionsSummaryMode {
  CurrentMission = 'CurrentMission',
  AllOfType = 'AllOfType'
}

export enum EDateRange {
  LastWeek = 'LastWeek',
  ThisWeek = 'ThisWeek',
  Last30Days = 'Last30Days',
  Last90days = 'Last 90 days',
  LastYear = 'LastYear'
}

export enum EGroveMissionStatus {
  Treated = 'Treated',
  Published = 'Published',
  Outdated = 'Outdated',
  Inactive = 'Inactive'
}

export interface IMissionGrove extends IGrove {
  listStatus: EGroveMissionStatus;
}

export interface IMetricGroveEntry extends IMissionGrove {
  kpi: (string | number)[];
}

export interface IUploadTreesRow {
  treeID: string;
  groveID: string;
  farmID: string;
}

const isExportSupported = (type: TMissionType, subType: EMissionSubtype | null): boolean => {
  if (subType === EMissionSubtype.PestsP3) {
    return true;
  }

  const exportedTypes = [EMissionType.FruitCount, EMissionType.HLBScouting, EMissionType.Clipping, EMissionType.WeeklyTrapMonitoring, EMissionType.Replant] as TMissionType[];
  return exportedTypes.includes(type);
};

const isHeatmapSupported = (type: TMissionType | EMissionSubtype): boolean => {
  const heatmapTypes = [EMissionSubtype.CountingFruits, EMissionType.HLBScouting, EMissionType.WeeklyTrapMonitoring, EMissionSubtype.PestsP3] as (TMissionType | EMissionSubtype)[];
  return heatmapTypes.includes(type);
};

const checkMissionGroveStatus = (mission: Pick<IMission, 'groves'>, groveID: string, status: EMissionStatus): boolean => mission.groves[groveID] === status;

const getCipoPercentageMetric = (grove: IGrove, statistic: ISimpleStatistic[] | null): number => {
  const groveStatistic = (statistic?.find((stat) => stat.groveID === grove.id)?.statistic || 0) as number;
  return numberUtils.normalizeDecimal(groveStatistic * 100, 2);
};

const getCipoYesCountMetric = (grove: IGrove, statistic: ISimpleStatistic[] | null, surveyStats: IGroveSurveyStats[]): number => {
  const groveStats = surveyStats.find((stat) => stat.groveID === grove.id);
  const groveStatistic = (statistic?.find((stat) => stat.groveID === grove.id)?.statistic || 0) as number;

  if (!groveStats || !groveStatistic) {
    return 0;
  }

  return Math.round(groveStats.numberOfTrees * groveStatistic);
};

const getStatisticMetric = (grove: IGrove, statistic: ISimpleStatistic[] | null): number => statistic?.find((stat) => stat.groveID === grove.id)?.statistic || 0;

const getGroveAreaMetric = (grove: IGrove, metricType: EMeasurementSystem): number => numberUtils.getGrovesArea([grove], metricType);

const getNumberOfScoresMetric = (grove: IGrove, surveyStats: IGroveSurveyStats[], kpiScores: number[]): number => {
  const groveStats = surveyStats.find((stat) => stat.groveID === grove.id);

  if (!groveStats) {
    return 0;
  }

  return kpiScores.reduce((acc: number, score: number) => acc + (groveStats?.scores?.[score] || 0), 0);
};

const getWeakScoresPercentageMetric = (grove: IGrove, surveyStats: IGroveSurveyStats[]): number => {
  const groveStats = surveyStats.find((stat) => stat.groveID === grove.id);

  if (!groveStats) {
    return 0;
  }

  const totalScoresToCount = [2, 3, 4, 5, 6];
  const weakScoresToCount = [2, 3];
  const weakTreesCount = weakScoresToCount.reduce((acc: number, score: number) => acc + (groveStats?.scores?.[score] || 0), 0);
  const totalTreesCount = totalScoresToCount.reduce((acc: number, score: number) => acc + (groveStats?.scores?.[score] || 0), 0);

  return numberUtils.normalizeDecimal((weakTreesCount / totalTreesCount) * 100, 1);
};

const getIrrigationMethodMetric = (grove: IGrove): string => (grove.attributes?.irrigation ? Object.keys(grove.attributes.irrigation).join(', ') : '');

const getHotspotsPopulationMetric = (grove: IGrove, reports: IUnifiedHotspot[] | null): number => {
  const groveReports = reports?.filter((report) => report.groveID === grove.id) || [];
  return groveReports.reduce((acc, report) => acc + (report.population || 0), 0);
};

const getHotspotsAreaMetric = (grove: IGrove, reports: IUnifiedHotspot[] | null): number => {
  const groveReports = reports?.filter((report) => report.groveID === grove.id) || [];
  return numberUtils.getTotalHostpotsArea(groveReports);
};

const getWeedsPercentageMetric = (grove: IGrove, reports: IWeedsReport[] | null): number => {
  const report = reports?.find((report) => report.groveID === grove.id);

  const groveStatistic = report
    ? +(report as IWeedsReport)._30_60cm_percentage + +(report as IWeedsReport)._60_90cm_percentage + +(report as IWeedsReport)._90cm_above_percentage
    : 0;

  return numberUtils.normalizeDecimal(groveStatistic * 100, 2);
};

const getHeightMetric = (grove: IGrove, reports: IHeightReport[] | null): number => {
  const groveReports = reports?.filter((report) => report.groveID === grove.id)?.sort((a, b) => +b.Height - +a.Height);

  return groveReports?.[0] ? +(groveReports[0] as IHeightReport).Height : 0;
};

const getFreeAreaMetric = (grove: IGrove, reports: IFreeAreaReport[] | null): number => {
  const groveReports = reports?.filter((report) => report.groveID === grove.id)?.sort((a, b) => +b.freeArea_avg - +a.freeArea_avg);
  return groveReports?.[0] ? +(groveReports[0] as IFreeAreaReport).freeArea_avg : 0;
};

const getUnderperformingDifferenceMetric = (grove: IGrove, reports: IScoreDifferenceReport[] | null): number => {
  const report = reports?.find((report) => report.groveID === grove.id);
  return numberUtils.normalizeDecimal(report?.underperforming_difference || 0, 2);
};

const getMissionPreview = (type: TMissionType, subType: EMissionSubtype | null): string => {
  switch (type) {
    case EMissionType.PestsDesease:
    case EMissionType.SmartPestScouting:
      if (subType === EMissionSubtype.Pests) {
        return '/images/missions/Pests.jpg';
      } else {
        return '/images/missions/Disease.jpg';
      }
    case EMissionType.General:
      return '/images/missions/General.png';
    case EMissionType.Clipping:
      return '/images/missions/Clipping.png';
    case EMissionType.Sampling:
      return '/images/missions/Sampling.png';
    case EMissionType.IrrigationScouting:
      return '/images/missions/Irrigation.png';
    case EMissionType.GeneralScouting:
      return '/images/missions/GeneralScouting.png';
    case EMissionType.WeeklyTrapMonitoring:
      return '/images/missions/Weekly_Trap_Monitoring.png';
    case EMissionType.FruitCount:
      if (subType === EMissionSubtype.CountingFruits) {
        return '/images/missions/CountingFruits.png';
      } else if (subType === EMissionSubtype.MarkAsCounted) {
        return '/images/missions/MarkAsCounted.jpg';
      } else {
        return '/images/missions/BloomMonth.png';
      }
    case EMissionType.HLBScouting:
      return '/images/missions/HLB.jpg';
    default:
      return '';
  }
};

const getHealthScoreMetric = (grove: IGrove, surveyStats: IGroveSurveyStats[]): number => {
  const groveStats = surveyStats.find((stat) => stat.groveID === grove.id);
  if (!groveStats) {
    return 0;
  }
  const isAlmonds = grove.attributes?.commodity === 'Almonds';

  return scoreUtils.calculateScore(groveStats, isAlmonds);
};

const getPlantYearMetric = (grove: IGrove): string => grove.attributes?.originalPlantYear || '';

const getVarietyMetric = (grove: IGrove): string => grove.attributes?.variety || '';

const getGroveCapacity = (grove: IGrove, replantStatistic: TMappedStatistic | null): any => {
  const data = replantStatistic?.[grove.id];

  return data?.statistic || 0;
};

const getGrovesTotalStats = (zoneID: string | null, groves: IGrove[], surveyStats: IGroveSurveyStats[], metricType: EMeasurementSystem): IMetricGroveEntry[] => {
  const entries = (
    groves
      .filter((grove) => !zoneID || grove.zoneID === zoneID)
      .map((grove) => {
        const groveStats = surveyStats.find((stat) => stat.groveID === grove.id);

        if (!groveStats) {
          return null;
        }

        const scoresToCount = [2, 3, 4, 5, 6, -1];
        const treesCount = scoresToCount.reduce((acc: number, score: number) => acc + (groveStats?.scores?.[score] || 0), 0);

        return {
          ...grove,
          kpi: [numberUtils.getGrovesArea([grove], metricType), treesCount]
        };
      })
      .filter((entry) => !!entry) as IMetricGroveEntry[]
  ).sort((a, b) => (b.kpi[0] < a.kpi[0] ? -1 : 1));
  return entries;
};

const getMissionGroveKpi = (
  mission: ICreateMissionIncompleteParams | IMission,
  grove: IGrove,
  surveyStats: IGroveSurveyStats[],
  kpiStatistic: (TAnyStatistic | null)[],
  reports: (TAnyReport[] | null)[],
  metricType: EMeasurementSystem,
  capacityStatisticByRules: TMappedStatistic | null
) => {
  const kpis = (mission.kpis || getDefaultKpis(mission.type)).map((kpi: EMissionKpi, index: number) => {
    switch (kpi) {
      case EMissionKpi.CipoPercentage:
        return getCipoPercentageMetric(grove, kpiStatistic[index] as ISimpleStatistic[] | null);
      case EMissionKpi.CipoYesCount:
        return getCipoYesCountMetric(grove, kpiStatistic[index] as ISimpleStatistic[] | null, surveyStats);
      case EMissionKpi.GroveArea:
      case EMissionKpi.UnderperformingGroveArea:
      case EMissionKpi.WeedsArea:
        return getGroveAreaMetric(grove, metricType);
      case EMissionKpi.CanopyArea:
      case EMissionKpi.TreeVolume:
        return getStatisticMetric(grove, kpiStatistic[index] as ISimpleStatistic[] | null);
      case EMissionKpi.WeakScores:
      case EMissionKpi.NumberOfScores:
        return getNumberOfScoresMetric(grove, surveyStats, mission.kpiScores);
      case EMissionKpi.NumberOfTrees:
        return getNumberOfScoresMetric(grove, surveyStats, [2, 3, 4, 5, 6, -1]);
      case EMissionKpi.HealthScore:
        return getHealthScoreMetric(grove, surveyStats);
      case EMissionKpi.WeakPercentage:
        return getWeakScoresPercentageMetric(grove, surveyStats);
      case EMissionKpi.IrrigationMethod:
        return getIrrigationMethodMetric(grove);
      case EMissionKpi.HotspotsArea:
        return getHotspotsAreaMetric(grove, reports[index] as IUnifiedHotspot[] | null);
      case EMissionKpi.HotspotsPopulation:
        return getHotspotsPopulationMetric(grove, reports[index] as IUnifiedHotspot[] | null);
      case EMissionKpi.WeedsPercentage:
        return getWeedsPercentageMetric(grove, reports[index] as IWeedsReport[] | null);
      case EMissionKpi.FreeArea:
        return getFreeAreaMetric(grove, reports[index] as IFreeAreaReport[] | null);
      case EMissionKpi.Height:
        return getHeightMetric(grove, reports[index] as IHeightReport[] | null);
      case EMissionKpi.UnderperformingDifference:
        return getUnderperformingDifferenceMetric(grove, reports[index] as IScoreDifferenceReport[] | null);
      case EMissionKpi.PlantYear:
        return getPlantYearMetric(grove);
      case EMissionKpi.Variety:
        return getVarietyMetric(grove);
      case EMissionKpi.GroveAge:
        return dateUtils.getYearDifference(grove?.attributes?.originalPlantYear || '');
      case EMissionKpi.TotalReplants:
        return getGroveCapacity(grove, capacityStatisticByRules);
      default:
        return 0;
    }
  });
  return kpis;
};

const getMissionTypeKey = (type: EMissionSubtype | TMissionType, subType?: EMissionSubtype | null): string => {
  if (subType === EMissionSubtype.PestsP3) {
    return 'missions.pests_scouting_p3';
  }

  switch (type) {
    case EReportType.Cipo:
      return 'missions.cipo_treatment';
    case EReportType.DedicatedVisit:
      return 'missions.grove_scouting';
    case EReportType.TargetedScouting:
      return 'missions.targeted_scouting';
    case EMissionType.GeneralScouting:
      return 'missions.general_scouting';
    case EReportType.Height:
      return 'missions.tree_topping';
    case EReportType.FreeArea:
      return 'missions.tree_hedging';
    case EReportType.Weeds:
      return 'missions.weed_mowing';
    case EMissionType.HLBScouting:
      return 'missions.hlb_scouting';
    case EMissionType.WeeklyTrapMonitoring:
      return 'missions.weekly_trap_monitoring';
    case EMissionType.IrrigationScouting:
      return 'missions.irrigation_scouting';
    case EMissionType.General:
      return 'missions.general';
    case EMissionType.FruitCount:
      return 'missions.fruit_count';
    case EMissionType.Clipping:
      return 'missions.clipping';
    case EMissionType.Sampling:
      return 'missions.sampling';
    case EMissionType.PestsDesease:
      return 'missions.pests_disease';
    case EMissionSubtype.PestsP3:
      return 'missions.pests_scouting_p3';
    case EMissionSubtype.Pests:
      return 'missions.pests_scouting';
    case EMissionSubtype.Disease:
      return 'missions.disease_scouting';
    case EMissionSubtype.CountingFruits:
      return 'missions.counting_fruits';
    case EMissionSubtype.MarkAsCounted:
      return 'missions.mark_as_counted';
    case EMissionSubtype.BloomMonth:
      return 'missions.bloom_month';
    case EMissionType.SmartPestScouting:
      return 'missions.smart_pest_scouting';
    case EMissionType.Replant:
      return 'missions.replant';
    default:
      return '';
  }
};

export const getMissionStatusKey = (status: EMissionStatus): string | null => {
  switch (status) {
    case EMissionStatus.InProgress:
      return 'missions.in_progress';
    case EMissionStatus.Done:
      return 'missions.done';
    default:
      return null;
  }
};

const getMissionMetricDescriptionKey = (kpi: EMissionKpi): string => {
  switch (kpi) {
    case EMissionKpi.GroveArea:
      return 'missions.grove_area';
    case EMissionKpi.NumberOfTrees:
      return 'missions.number_of_trees_in_the_grove';
    case EMissionKpi.HealthScore:
      return 'missions.avg_health_score';
    case EMissionKpi.NumberOfScores:
      return 'missions.number_of_total_selected_scores';
    case EMissionKpi.CanopyArea:
      return 'missions.avg_canopy_area';
    case EMissionKpi.TreeVolume:
      return 'missions.avg_tree_volume';
    case EMissionKpi.Variety:
      return 'missions.variety';
    case EMissionKpi.PlantYear:
      return 'missions.original_plant_year';
    case EMissionKpi.IrrigationMethod:
      return 'main.info.irrigation_method';
    case EMissionKpi.GroveAge:
      return 'missions.grove_age';
    default:
      return '';
  }
};

const getDefaultKpis = (type: TMissionType): EMissionKpi[] => {
  switch (type) {
    case EReportType.Cipo:
      return [EMissionKpi.CipoPercentage, EMissionKpi.CipoYesCount];
    case EReportType.Weeds:
      return [EMissionKpi.WeedsPercentage, EMissionKpi.WeedsArea];
    case EReportType.TargetedScouting:
    case EMissionType.GeneralScouting:
      return [EMissionKpi.HotspotsPopulation, EMissionKpi.HotspotsArea];
    case EReportType.Height:
      return [EMissionKpi.Height, EMissionKpi.GroveArea];
    case EReportType.FreeArea:
      return [EMissionKpi.FreeArea, EMissionKpi.GroveArea];
    case EReportType.DedicatedVisit:
      return [EMissionKpi.UnderperformingDifference, EMissionKpi.UnderperformingGroveArea];
    case EMissionType.PestsDesease:
    case EMissionType.FruitCount:
    case EMissionType.Sampling:
    case EMissionType.General:
    case EMissionType.WeeklyTrapMonitoring:
      return [EMissionKpi.GroveArea, EMissionKpi.NumberOfTrees];
    case EMissionType.HLBScouting:
    case EMissionType.Clipping:
      return [EMissionKpi.WeakPercentage, EMissionKpi.WeakScores];
    case EMissionType.SmartPestScouting:
      return [EMissionKpi.GroveArea, EMissionKpi.NumberOfTrees];
    case EMissionType.IrrigationScouting:
      return [EMissionKpi.GroveArea, EMissionKpi.IrrigationMethod];
    case EMissionType.Replant:
      return [EMissionKpi.GroveAge, EMissionKpi.TotalReplants];
    default:
      return [];
  }
};

const getDefaultKpiScores = (type: TMissionType): number[] => {
  switch (type) {
    case EMissionType.PestsDesease:
    case EMissionType.FruitCount:
    case EMissionType.Sampling:
      return [2, 3, 4, 5, 6, -1];
    case EMissionType.HLBScouting:
    case EMissionType.Clipping:
      return [2, 3];
    default:
      return [];
  }
};

const getMissionParams = (
  type: TMissionType,
  subType: EMissionSubtype | null,
  selectedFarm: IFarm | null,
  zoneID: string | null,
  user: IUserInfo,
  kpis: EMissionKpi[],
  kpiScores: number[],
  treesCount: number | null,
  isPreassigned: boolean,
  t: (key: string, params?: { [key: string]: string }) => string
): ICreateMissionIncompleteParams => ({
  title: `${t(missionUtils.getMissionTypeKey(subType || type))}, ${dateUtils.formatDate(new Date(), dateFormats.DATE_FORMAT_DD_MMMM_YYYY)}`,
  zoneID,
  farmID: selectedFarm?.id as string,
  type,
  subType,
  treesCount,
  isDeleted: false,
  kpis: kpis.length > 0 ? kpis : getDefaultKpis(type),
  kpiScores: kpiScores.length > 0 ? kpiScores : getDefaultKpiScores(type),
  timestamp: +new Date(),
  approved: false,
  isPreassigned,
  createdBy: user.displayName
});

const getMissionMetricTitle = (t: (key: string, params?: { [key: string]: any }) => string, kpi: EMissionKpi, metricType: EMeasurementSystem): string => {
  switch (kpi) {
    case EMissionKpi.CipoPercentage:
      return t('missions.cipo_percentage');
    case EMissionKpi.UnderperformingDifference:
      return t('missions.increase_in_weak_trees_percentage');
    case EMissionKpi.HotspotsPopulation:
      return t('missions.approx_affected_trees');
    case EMissionKpi.WeedsPercentage:
      return t('missions.weeds_percentage');
    case EMissionKpi.Height:
      return t('missions.avg_height', { metric: metricType === EMeasurementSystem.Metric ? 'm' : 'ft' });
    case EMissionKpi.FreeArea:
      return t('missions.avg_free_area', { metric: metricType === EMeasurementSystem.Metric ? 'm' : 'ft' });
    case EMissionKpi.WeakPercentage:
      return t('missions.percentage_weak_trees');
    case EMissionKpi.GroveArea:
    case EMissionKpi.WeedsArea:
    case EMissionKpi.UnderperformingGroveArea:
      return t('missions.grove_area');
    case EMissionKpi.CipoYesCount:
      return t('missions.affected_trees');
    case EMissionKpi.WeakScores:
      return t('missions.total_weak_trees');
    case EMissionKpi.NumberOfScores:
      return t('missions.total_scores');
    case EMissionKpi.CanopyArea:
      return t('missions.avg_canopy_area');
    case EMissionKpi.TreeVolume:
      return t('missions.avg_tree_volume');
    case EMissionKpi.NumberOfTrees:
      return t('missions.total_trees');
    case EMissionKpi.HealthScore:
      return t('missions.avg_health_score');
    case EMissionKpi.IrrigationMethod:
      return t('main.info.irrigation_method');
    case EMissionKpi.HotspotsArea:
      return t('main.bulletin.area_affected');
    case EMissionKpi.Variety:
      return t('missions.variety');
    case EMissionKpi.PlantYear:
      return t('missions.original_plant_year');
    case EMissionKpi.TotalReplants:
      return t('main.info.total_replants');
    case EMissionKpi.GroveAge:
      return t('main.info.grove_age');
    default:
      return '';
  }
};

const RowPrimaryText = styled.div`
  font-family: MontserratBold;
`;

const getMissionColumnDescriptor = (
  kpi: EMissionKpi,
  index: number,
  metricType: EMeasurementSystem,
  farmBulletinThresholds: { [key: string]: { value: number | number[] } },
  t: (key: string, params?: { [key: string]: any }) => string
) => {
  const metricTitle = getMissionMetricTitle(t, kpi, metricType);
  const columnStyles = {
    0: {
      textAlign: 'center',
      fontSize: '12px'
    },
    1: {
      justifyContent: 'flex-end',
      textAlign: 'right',
      fontSize: '12px'
    }
  };

  return {
    title: metricTitle,
    render: (grove: IMetricGroveEntry) => {
      let metric;
      if (grove.listStatus === EGroveMissionStatus.Inactive) return '';

      const nonFormattedKpis = [
        EMissionKpi.HotspotsPopulation,
        EMissionKpi.NumberOfScores,
        EMissionKpi.WeakScores,
        EMissionKpi.CipoYesCount,
        EMissionKpi.NumberOfTrees,
        EMissionKpi.HealthScore,
        EMissionKpi.PlantYear,
        EMissionKpi.Variety,
        EMissionKpi.IrrigationMethod,
        EMissionKpi.GroveAge
      ];
      const areaKpis = [EMissionKpi.GroveArea, EMissionKpi.HotspotsArea, EMissionKpi.WeedsArea, EMissionKpi.UnderperformingGroveArea];
      const squareMeasurementKpi = [EMissionKpi.CanopyArea];
      const volumeMeasurementKpi = [EMissionKpi.TreeVolume];

      if (kpi === EMissionKpi.Height || kpi === EMissionKpi.FreeArea) {
        const reportType = kpi === EMissionKpi.Height ? EReportType.Height : EReportType.FreeArea;
        metric = numberUtils.formatHeight(
          numberUtils.convertMeterToFeet(
            numberUtils.convertReportValue(+grove.kpi[index], reportType, (farmBulletinThresholds[reportType]?.value as number) || null, 1),
            metricType
          ),
          1,
          metricType
        );
      } else if (nonFormattedKpis.includes(kpi)) {
        metric = grove.kpi[index];
      } else if (areaKpis.includes(kpi)) {
        metric = numberUtils.formatArea(grove.kpi[index] as number, 1, metricType);
      } else if (squareMeasurementKpi.includes(kpi)) {
        metric = numberUtils.formatSquare(grove.kpi[index] as number, 1, metricType);
      } else if (volumeMeasurementKpi.includes(kpi)) {
        metric = numberUtils.formatVolume(grove.kpi[index] as number, 1, metricType);
      } else if (kpi === EMissionKpi.TotalReplants) {
        metric = replantUtils.formatReplantNumber(grove.kpi[index]);
      } else {
        metric = `${grove.kpi[index]}%`;
      }

      return <RowPrimaryText dangerouslySetInnerHTML={{ __html: metric }} />;
    },
    key: index,
    sortable: true,
    width: index === 0 ? '80px' : '96px',
    style: columnStyles[index]
  };
};

const getMissionReportParams = (
  user: IUserInfo,
  mission: ICreateMissionIncompleteParams,
  treeName: string,
  treeID: string,
  geometry: Pick<ITreeMapboxFeature, 'geometry'>,
  groveID: string,
  zoneID: string,
  serialNumber = ''
): ICreateFeedIncompleteParams | null => {
  const reportType = missionFeedTypeRelation.get(mission.type) || (mission.subType && missionFeedTypeRelation.get(mission.subType)) || null;

  if (!reportType) return null;

  const createdAt = +new Date();

  return {
    geoObjectType: 'tree',
    type: reportType,
    createdAt,
    updatedAt: createdAt,
    body: '',
    farmID: mission.farmID,
    zoneID,
    groveID,
    geoObjectID: treeID,
    isReport: true,
    isPreassigned: true,
    status: EFeedStatus.InProgress,
    geoObjectName: treeName,
    geometry: JSON.stringify(geometry.geometry),
    isFarFromReport: true,
    user: {
      uid: user.id,
      email: user.email,
      displayName: user.displayName
    },
    serialNumber
  };
};

const getDataByKeyFromReport = (missionReport: ICreateFeedIncompleteParams, key: string, defaultValue = '') => {
  if (!missionReport?.data?.[key]) return defaultValue;

  return missionReport.data[key];
};

const getResolvedAreaForPeriod = (mission: IMission, selectedDateRange: EDateRange | null, groves: IGrove[], metricType: EMeasurementSystem): number => {
  let groveIDs: string[] = [];
  const now = new Date();

  switch (selectedDateRange) {
    case EDateRange.LastWeek:
      groveIDs = Object.keys(mission.logs || {}).filter((groveID) => {
        const timestamp = mission.logs?.[groveID]?.timestamp;
        const missionUpdateDate = dateUtils.getStartOfWeek(now);
        return mission.groves[groveID] === EMissionStatus.Done && !!timestamp && timestamp >= dateUtils.subtractDays(missionUpdateDate, 7) && timestamp < missionUpdateDate;
      });
      break;
    case EDateRange.Last30Days:
      groveIDs = Object.keys(mission.logs || {}).filter((groveID) => {
        const timestamp = mission.logs?.[groveID]?.timestamp;
        return mission.groves[groveID] === EMissionStatus.Done && !!timestamp && timestamp >= dateUtils.subtractDays(now, 30);
      });
      break;
    case EDateRange.Last90days:
      groveIDs = Object.keys(mission.logs || {}).filter((groveID) => {
        const timestamp = mission.logs?.[groveID]?.timestamp;
        return mission.groves[groveID] === EMissionStatus.Done && !!timestamp && timestamp >= dateUtils.subtractDays(now, 90);
      });
      break;
    case EDateRange.LastYear:
      groveIDs = Object.keys(mission.logs || {}).filter((groveID) => {
        const timestamp = mission.logs?.[groveID]?.timestamp;
        return mission.groves[groveID] === EMissionStatus.Done && !!timestamp && timestamp >= dateUtils.subtractDays(now, 365);
      });
      break;
    default:
      groveIDs = Object.keys(mission.logs || {}).filter((groveID) => {
        const timestamp = mission.logs?.[groveID]?.timestamp;
        return mission.groves[groveID] === EMissionStatus.Done && !!timestamp && timestamp >= dateUtils.getStartOfWeek(now);
      });
      break;
  }

  const resolvedGroves = groveIDs.map((groveID) => groves.find((grove) => grove.id === groveID) as IGrove).filter((grove) => !!grove);
  return numberUtils.getGrovesArea(resolvedGroves, metricType);
};

// prettier-ignore
const getTreesSum = (
  groves: { id: string }[],
  surveyStats: IGroveSurveyStats[],
  grovesSurveysStats?: IGroveSurveyStats[],
  addNotBearing = true
): number => groves.reduce((acc, entry) => {
  const scores = addNotBearing ? [...NOT_BEARING, ...UNDER_PERFORMING, ...HEALTHY, ...ERROR] : [...UNDER_PERFORMING, ...HEALTHY];

  const groveStats = surveyStats.find((stat) => stat.groveID === entry.id);

  let groveTreesCount = scores.reduce((acc: number, score: number) => acc + (groveStats?.scores?.[score] || 0), 0);

  if (!groveTreesCount && grovesSurveysStats) {
    const prevGroveStats = grovesSurveysStats.filter((stat) => stat.groveID === entry.id && stat.isPublished).sort((a, b) => b.publishedAt - a.publishedAt)?.[0];
    groveTreesCount = scores.reduce((acc: number, score: number) => acc + (prevGroveStats?.scores?.[score] || 0), 0);
  }

  return acc + groveTreesCount;
}, 0);

const getTotalSeverity = (missionReports: IFeed[]) => {
  if (!missionReports) return 0;

  return missionReports.reduce((acc: number, entry) => {
    if (!entry?.data?.hlbStatus || !HLB_INFECTED_STATUSES.includes(entry.data.hlbStatus)) return acc;

    return acc + (HLBSeverityMap.get(entry.data.hlbStatus) || 0);
  }, 0);
};

const getTotalFruitCount = (missionReports: IFeed[]) => {
  if (!missionReports) return 0;

  return missionReports.reduce((acc: number, entry) => acc + (entry?.data?.fruitCountNumber || 0), 0);
};

const getGroupedWeeklyTrapFeedForPeriod = (feed: IFeed[], selectedTimestamp: number, endDate: number): IFeed[] => {
  const groupedFeed = feed
    .filter((entry) => entry.updatedAt >= selectedTimestamp && entry.updatedAt < endDate && !!entry.data?.trapType)
    .reduce((acc: IFeed[], entry: IFeed) => {
      const incrementEntry = acc.find((item) => item.geoObjectID === entry.geoObjectID && item.data?.trapType === entry.data?.trapType && entry.data?.pestsNumber !== undefined);
      if (!incrementEntry) {
        acc.push(JSON.parse(JSON.stringify(entry)));
      } else if (incrementEntry.data) {
        if (incrementEntry.updatedAt <= entry.updatedAt) {
          incrementEntry.updatedAt = entry.updatedAt;
          incrementEntry.user = entry.user;
          if (incrementEntry.data.pestsNumber) {
            incrementEntry.data.pestsNumber += entry.data?.pestsNumber || 0;
          }
        }
        if (entry.data?.changedBait) {
          incrementEntry.data.changedBait = entry.data.changedBait;
        }
      }
      return acc;
    }, []);
  return groupedFeed;
};

const filterMapFeed = (farmID: string, feed: IFeed[]): IFeed[] => {
  const filteredFeed = feed.filter((item) => {
    // prettier-ignore
    if (
      farmID === '-MXlZ7I6_d40mBQAQZck'
      && [EFeedItemType.Count, EFeedItemType.Hlb].includes(item.type)
      && ['-N67ej75Ogro3fCiqduM', '-N0V-MzJARVZTkM7xTQQ'].includes(item.surveyID)
    ) return false;

    if (farmID === '-MpGRyKZkON9IwZ963EX' && item.type === EFeedItemType.Count) return false;

    if (farmID === '-Low54XXyTXPcTTZOSJf' && item.surveyID === '-N-HftvHVkITL8NIZAKn' && item.type === EFeedItemType.Hlb) return false;

    return true;
  });

  return filteredFeed;
};

interface IColorsConfigWithID extends IWeeklyTrapColorsConfig {
  id: string;
}

const getDefaultMissionConfigs = (defaultMissionConfigs: IColorsConfigWithID[], mission: IMission): IWeeklyTrapMonitoringConfig => {
  const missionConfig = defaultMissionConfigs.find((config) => config.id === mission.type) as IWeeklyTrapColorsConfig;
  return {
    id: mission.type,
    fruit_fly: missionConfig,
    red_scale: missionConfig,
    mealy_bug: missionConfig,
    bollworm: missionConfig,
    leaf_hopper: missionConfig,
    lemon_moth: missionConfig,
    false_codling_moth_sterile: missionConfig,
    false_codling_moth_wild: missionConfig
  };
};

const getGeoObjectFeedGroup = (selectedFeedItem: IFeed | null, feed: IFeed[], mission: IMission | null) => {
  if (!selectedFeedItem) return [];

  if (mission?.subType === EMissionSubtype.BloomMonth) {
    return feed.filter((entry) => entry.geoObjectID === selectedFeedItem.geoObjectID);
  }

  if (selectedFeedItem.type !== EFeedItemType.WeeklyTrapMonitoring) return [selectedFeedItem];
  return feed.filter((entry) => entry.geoObjectID === selectedFeedItem.geoObjectID);
};

const TableValue = styled.div`
  font-size: 14px;
`;

const getContinuousMissionColumns = (
  type: TMissionType | null,
  feeds: { [key: string]: IFeed[] },
  t: (key: string) => string,
  toggleCheckedMission: (missionID: string, isChecked: boolean) => void,
  checkedMissions: string[]
) => {
  const COLUMN_STYLE = {
    padding: '12px 20px',
    justifyContent: 'flex-start'
  };
  const missionInfoColumns = [
    {
      title: '',
      render: (mission: IMission) => (
        <Checkbox checked={checkedMissions.includes(mission.id)} onCheckedChange={(isChecked: boolean) => toggleCheckedMission(mission.id, isChecked)} size={ESizes.small} />
      ),
      width: '58px',
      style: COLUMN_STYLE
    },
    {
      title: <div className="title-column">{t('shared.mission_name')}</div>,
      render: (mission: Pick<IMission, 'title'>) => <TableValue>{mission.title}</TableValue>,
      key: 'name',
      width: '200px',
      style: COLUMN_STYLE
    },
    {
      title: <div className="title-column">{t('missions.date_created')}</div>,
      render: (mission: IMission) => <TableValue>{dateUtils.formatDate(mission.timestamp, dateFormats.DATE_FORMAT_DD_MMM_YYYY)}</TableValue>,
      key: 'dateCreated',
      width: '180px',
      style: COLUMN_STYLE
    }
  ];

  const missionStatusColumn = {
    title: <div className="title-column">{t('missions.mission_status')}</div>,
    render: (mission: IMission) => {
      const status = Object.keys(mission.groves).find((groveID) => mission.groves[groveID] === EMissionStatus.InProgress) ? EMissionStatus.InProgress : EMissionStatus.Done;
      const key = missionUtils.getMissionStatusKey(status);
      return <TableValue>{key ? t(key) : ''}</TableValue>;
    },
    key: 'status',
    width: '180px',
    style: COLUMN_STYLE
  };

  switch (type) {
    case EMissionType.HLBScouting:
      return [
        ...missionInfoColumns,
        {
          title: <div className="title-column">{t('missions.number_of_total_infected_trees')}</div>,
          render: (mission: IMission) => {
            const missionFeed = feeds[mission.id]?.filter((entry) => entry?.data?.hlbStatus && HLB_INFECTED_STATUSES.includes(entry.data.hlbStatus)) || [];
            return <TableValue>{missionFeed.length.toLocaleString()}</TableValue>;
          },
          key: 'metric',
          width: '180px',
          style: COLUMN_STYLE
        },
        missionStatusColumn
      ];
    case EMissionType.Clipping:
      return [
        ...missionInfoColumns,
        {
          title: <div className="title-column">{t('missions.number_of_clipped_trees')}</div>,
          render: (mission: IMission) => {
            const missionFeed = feeds[mission.id]?.filter((entry) => entry.data?.clippingStatus === EClippedStatus.Clipped) || [];
            return <TableValue>{missionFeed.length.toLocaleString()}</TableValue>;
          },
          key: 'metric',
          width: '180px',
          style: COLUMN_STYLE
        },
        missionStatusColumn
      ];
    default:
      return [];
  }
};

const getPestsP3CSVHeaders = (t: (key: string, params?: { [key: string]: string }) => string) => {
  const items = [BUDS, ...DEFAULT_PESTS_P3_SETTINGS] as IPestsP3Config[];

  return items.map((item) => {
    if (item.subtitle) {
      return `${t(`pests_p3.${item.title}`)} ${t(`pests_p3.${item.subtitle}`)}`;
    }
    return t(`pests_p3.${item.title}`);
  });
};

const getPestsP3CSVReportData = (feed: IFeed) => {
  const items = [BUDS, ...DEFAULT_PESTS_P3_SETTINGS] as IPestsP3Config[];
  const feedData = feed?.data;

  return items.map((item) => (feedData ? feedData[item.id] || 0 : 0));
};

const getGroupedPestsP3FeedForPeriod = (feed: IFeed[], selectedTimestamp: number, endDate: number) => {
  const groupedFeed = feed.filter((entry) => entry.updatedAt >= selectedTimestamp && entry.updatedAt < endDate && !!entry.data);
  const groupedPerGrove = groupBy((item) => item.groveID, groupedFeed);

  return groupedPerGrove;
};

const missionUtils = {
  checkMissionGroveStatus,
  getMissionTypeKey,
  getMissionStatusKey,
  getMissionParams,
  getMissionMetricTitle,
  getGrovesTotalStats,
  getMissionPreview,
  getMissionColumnDescriptor,
  getMissionGroveKpi,
  getMissionReportParams,
  getMissionMetricDescriptionKey,
  getResolvedAreaForPeriod,
  isExportSupported,
  isHeatmapSupported,
  getTreesSum,
  getDataByKeyFromReport,
  getTotalSeverity,
  getTotalFruitCount,
  getGroupedWeeklyTrapFeedForPeriod,
  filterMapFeed,
  getGeoObjectFeedGroup,
  getDefaultMissionConfigs,
  getContinuousMissionColumns,
  getDefaultKpis,
  getPestsP3CSVHeaders,
  getPestsP3CSVReportData,
  getGroupedPestsP3FeedForPeriod
};

export default missionUtils;
