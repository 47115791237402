import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Map as Mapbox } from 'mapbox-gl';

import Map from 'components/shared/Map';
import mapHooks from 'hooks/map.hooks';
import signInHooks from 'hooks/signIn.hooks';
import farmsHooks from 'hooks/farms.hooks';
import dateUtils from 'utils/date';
import dateFormats from 'models/date';
import { useAnalytics } from 'hooks/analytics.hooks';
import { LAYERS_PRIORITY, reorderLayers } from 'services/util/mapHelpers';
import { drawHotspotPolygons, getHotspotTilesetUrl } from 'services/util/mapHotspot';
import { IUnifiedHotspot } from 'models/hotspot';
import { EEventType } from 'models/analytics';

import { mfColors } from 'vars';

const Title = styled.div`
  font-family: MontserratBold;
  margin: 0 0 24px;
  color: ${mfColors.darkGrey};
`;

const Content = styled.div`
  height: 320px;
  width: 100%;
  border-radius: 8px;
  overflow: hidden;
`;

interface IProps {
  hotspot: IUnifiedHotspot | null;
}

const HotspotModal = ({ hotspot }: IProps): JSX.Element => {
  const [map, setMap] = useState<Mapbox | null>(null);
  const { selectedFarm } = farmsHooks.useSelectedFarmEntities();
  const mapOptions = mapHooks.useGetMapOptions([], null, null, hotspot, 0.3);
  const { t } = useTranslation();
  const user = signInHooks.useSignedInUser();
  const { logEvent } = useAnalytics();

  useEffect(() => {
    if (map && hotspot) {
      const tilestUrl = getHotspotTilesetUrl(hotspot);
      map.addLayer({
        id: 'raster-tiles',
        type: 'raster',
        source: {
          type: 'raster',
          tiles: [tilestUrl]
        }
      });
      reorderLayers(
        map,
        ['raster-tiles', ...LAYERS_PRIORITY].map((layer, index) => ({
          zIndex: index,
          name: layer
        }))
      );
    }

    return () => {
      if (map) {
        map.removeLayer('raster-tiles');
      }
    };
  }, [map, hotspot]);

  useEffect(() => {
    if (hotspot && user && selectedFarm) {
      logEvent({
        type: EEventType.SatelliteEvidenceClick,
        params: {
          email: user.email,
          farmName: selectedFarm.name,
          clusterID: hotspot.clusterID,
          detectionDate: hotspot.date ? dateUtils.formatDate(hotspot.date, dateFormats.DATE_FORMAT_DD_MMM_YYYY) : ''
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hotspot]);

  useEffect(() => {
    if (map && hotspot) {
      drawHotspotPolygons(map, [hotspot]);

      map.scrollZoom.disable();
      map.dragPan.disable();
      map.doubleClickZoom.disable();
      map.dragRotate.disable();
    }
  }, [map, hotspot]);

  return (
    <>
      <Title>{t('main.hotspot.satellite_evidence')}</Title>
      <Content>
        <Map onMapInit={setMap} options={mapOptions} />
      </Content>
    </>
  );
};

export default HotspotModal;
