import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import GlobalLoader from 'atomicComponents/GlobalLoader';
import signInHooks from 'hooks/signIn.hooks';

const RequireAuth: React.FC<{ children: JSX.Element }> = ({ children }) => {
  const { isAuthorized, isLoading } = signInHooks.useGetSignedInStatus();
  const location = useLocation();

  if (isLoading) {
    return <GlobalLoader />;
  }

  if (!isAuthorized) {
    return <Navigate to="/sign-in" state={{ from: location }} />;
  }

  return children;
};

export default RequireAuth;
