import React, { Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { ToggleFeedIconsIcon } from 'assets/images';
import Popover from 'atomicComponents/Popover';
import { EPosition } from 'atomicComponents/Dropdown';

import { mfColors } from 'vars';

const CONTROL_BUTTON_DIMENSION = '50px';

const Wrapper = styled.div`
  cursor: pointer;
  position: relative;
`;

interface IControlProps {
  isSelected: boolean;
}

const Control = styled.div`
  display: flex;
  flex-direction: row;
  align-atems: center;
  justify-content: center;
  width: ${CONTROL_BUTTON_DIMENSION};
  height: ${CONTROL_BUTTON_DIMENSION};
  border-radius: 8px;
  opacity: ${({ isSelected }: IControlProps) => (isSelected ? 1 : 0.6)};
  background: ${mfColors.lightGrey};
  display: flex;
  align-items: center;

  &:hover {
    opacity: 1;
  }
`;

const POPOVER_OFFSET = {
  x: 0,
  y: 16
};

interface IProps {
  isSelected: boolean;
  onToggleIsSelected: Dispatch<SetStateAction<boolean>>;
}

const MissionToggleIconsControl = ({ isSelected, onToggleIsSelected }: IProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <Popover
        position={EPosition.TopLeft}
        offset={POPOVER_OFFSET}
        control={
          <Control isSelected={isSelected} onClick={() => onToggleIsSelected((prev) => !prev)}>
            <ToggleFeedIconsIcon />
          </Control>
        }
        content={<span>{t('missions.show_icons_on_map')}</span>}
      />
    </Wrapper>
  );
};

export default MissionToggleIconsControl;
