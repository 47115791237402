import React, { useEffect, useRef, useMemo, useState, useCallback } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Chart } from 'chart.js';

import missionHooks from 'hooks/mission.hooks';
import numberUtils from 'utils/numbers';
import missionUtils from 'utils/mission';
import { useFetchGrovesStats } from 'services/data/farms';
import surveyUtils from 'utils/survey';
import { hexToRgb } from 'utils/helpers';

import { ICompany } from 'models/company';
import { IFarmName } from 'models/farm';
import { EDashboardType } from 'models/dashboard';
import { IMission } from 'models/mission';
import { IGroveSurveyStats } from 'models/stats';
import { ISurvey, ISurveyWithTimeRange } from 'models/survey';
import { IFeed, EHLBStatus, severityBackgroundMap, HLB_INFECTED_STATUSES } from 'models/feed';

import LocalLoader from 'atomicComponents/LocalLoader';

import { mfColors } from 'vars';

const Wrapper = styled.div`
  width: 100%;
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`;

const Title = styled.div`
  font-size: 24px;
  margin: 16px 0;
`;

const SubTitle = styled.div`
  font-size: 18px;
  padding: 4px 0;
  margin: 0 0 8px;
  border-bottom: 2px solid ${mfColors.grey};
  width: 100%;
`;

interface IChartWrapperProps {
  isSingleColumn: boolean;
}

const ChartWrapper = styled.div`
  margin: 16px 0;
  min-height: 260px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  canvas {
    height: 100%;
  }

  .column {
    width: ${({ isSingleColumn }: IChartWrapperProps) => (isSingleColumn ? '100%' : '48%')};
  }

  .chart-wrapper {
    min-height: 260px;
  }
`;

const NON_PREASSIGNED_SEVERITIES = [EHLBStatus.F1, EHLBStatus.F2, EHLBStatus.F3, EHLBStatus.F4];
const PREASSIGNED_SEVERITIES = [EHLBStatus.NoSymptoms, EHLBStatus.Sampled, EHLBStatus.F1, EHLBStatus.F2, EHLBStatus.F3, EHLBStatus.F4];

interface IProps {
  type: EDashboardType.HLBPreassigned | EDashboardType.HLBNotPreassigned;
  missions: IMission[];
  company: ICompany;
  farms: IFarmName[];
  customerSurveys: ISurvey[][];
  selectedFarm: string | null;
  onSelectFarm: (farmID: string) => void;
}

const HlbContamination = ({ type, missions, customerSurveys, company, farms, selectedFarm, onSelectFarm }: IProps): JSX.Element => {
  const { t } = useTranslation();
  const [surveyStats, setSurveyStats] = useState<IGroveSurveyStats[]>([]);
  const [statsLoading, setStatsLoading] = useState(false);
  const [chartFarm, setChartFarm] = useState<IFarmName | null>(null);
  const fetchGrovesStats = useFetchGrovesStats();
  const severityChartCanvasRef = useRef<HTMLCanvasElement>(null);
  const infectionRateChartCanvasRef = useRef<HTMLCanvasElement>(null);
  const perFarmChartCanvasRef = useRef<HTMLCanvasElement>(null);
  const severityChartRef = useRef<Chart | null>(null);
  const infectionRateChartRef = useRef<Chart | null>(null);
  const perFarmChartRef = useRef<Chart | null>(null);
  const isPreassigned = type === EDashboardType.HLBPreassigned;
  const chartSeverities = isPreassigned ? PREASSIGNED_SEVERITIES : NON_PREASSIGNED_SEVERITIES;
  const { feed, loading: feedLoading } = missionHooks.useMissionFeed(missions);

  const loading = statsLoading || feedLoading;

  useEffect(() => {
    setChartFarm(() => {
      if (!selectedFarm) return null;
      return farms.find((farm) => farm.id === selectedFarm) || null;
    });
  }, [farms, selectedFarm]);

  useEffect(() => {
    setStatsLoading(true);
    Promise.all(
      farms.map((farm) => {
        const farmSurveys = (
          (customerSurveys.find((surveys) => surveys?.[0]?.farmID === farm.id) || [])
            .map((survey: ISurvey) => surveyUtils.getSurveyWithTimeRange(survey))
            .filter((survey) => !!survey && (survey as ISurveyWithTimeRange).status === 'published') as ISurveyWithTimeRange[]
        ).sort((a, b) => (b.from < a.from ? -1 : 1));
        if (!farmSurveys?.length) return Promise.resolve([]);
        return fetchGrovesStats(farm.id, farmSurveys[0].id);
      })
    ).then((statsGroups) => {
      setSurveyStats(statsGroups.flat());
      setStatsLoading(false);
    });
  }, [farms, customerSurveys, fetchGrovesStats]);

  const farmsFeed = useMemo(() => {
    if (!chartFarm) return feed;
    const missionFarms = missions.reduce((acc: { [key: string]: string }, mission) => {
      acc[mission.id] = mission.farmID;
      return acc;
    }, {});
    return feed.filter((entry) => chartFarm.id === missionFarms[entry.missionID]);
  }, [chartFarm, missions, feed]);

  const missionsGroves = useMemo(() => {
    const groves = missions
      .filter((mission) => !chartFarm || mission.farmID === chartFarm.id)
      .reduce((acc: { id: string; farmID: string }[], mission) => {
        const missionGroveIDs = Object.keys(mission.groves);
        return [...acc, ...missionGroveIDs.filter((groveID) => !acc.find((entry) => entry.id === groveID)).map((groveID) => ({ id: groveID, farmID: mission.farmID }))];
      }, []);
    return groves.map((grove) => {
      const groveFeed = feed.filter((entry) => entry.groveID === grove.id);
      const hlbFeed = groveFeed.filter((entry) => HLB_INFECTED_STATUSES.includes(entry?.data?.hlbStatus as EHLBStatus));

      return {
        id: grove.id,
        farmID: grove.farmID,
        feed: groveFeed,
        hlbFeed
      };
    });
  }, [chartFarm, missions, feed]);

  const missionsFarms = useMemo(() => {
    const result = farms.map((farm) => {
      const farmGroves = missionsGroves.filter((grove) => grove.farmID === farm.id);
      const farmFeed = farmGroves.reduce(
        (acc: { hlbFeed: IFeed[]; feed: IFeed[] }, grove) => {
          Array.prototype.push.apply(acc.feed, grove.feed);
          Array.prototype.push.apply(acc.hlbFeed, grove.hlbFeed);
          return acc;
        },
        { hlbFeed: [], feed: [] }
      );

      return {
        id: farm.id,
        name: farm.name,
        feed: farmFeed.feed,
        totalTrees: missionUtils.getTreesSum(farmGroves, surveyStats),
        hlbFeed: farmFeed.hlbFeed
      };
    });
    return result;
  }, [farms, missionsGroves, surveyStats]);

  const totalTreesCount = useMemo((): number => {
    const result = missionsGroves.reduce((acc: number, grove) => acc + missionUtils.getTreesSum([grove], surveyStats), 0);
    return result;
  }, [surveyStats, missionsGroves]);

  useEffect(() => {
    if (!severityChartCanvasRef.current || loading) return;
    const labels: string[] = [];
    const data: number[] = [];
    const backgrounds: string[] = [];
    const allReportedItems = farmsFeed.filter((entry) => chartSeverities.includes(entry?.data?.hlbStatus as EHLBStatus));

    chartSeverities.forEach((severityLevel) => {
      const severityCount = farmsFeed.filter((entry) => entry?.data?.hlbStatus === severityLevel).length;
      const percentage = numberUtils.normalizeDecimal(numberUtils.convertToPercentage(severityCount / allReportedItems.length), 2);
      if (severityLevel === EHLBStatus.NoSymptoms) {
        labels.push(t('missions.no_hlb_percentage', { percentage: `${percentage}%` }));
      } else if (severityLevel === EHLBStatus.Sampled) {
        labels.push(t('missions.sampled', { percentage: `${percentage}%` }));
      } else {
        labels.push(t('missions.severity_level_percentage', { level: severityLevel, percentage: `${percentage}%` }));
      }
      data.push(percentage);
      backgrounds.push(severityBackgroundMap.get(severityLevel) || '');
    });

    const config = {
      type: 'pie',
      data: {
        labels,
        datasets: [
          {
            data,
            backgroundColor: backgrounds
          }
        ]
      },
      options: {
        responsive: true,
        tooltips: {
          callbacks: {
            label: (context) => {
              const value = data[context.index];
              return ` ${value}%`;
            }
          }
        },
        plugins: {
          legend: {
            position: 'top'
          },
          title: {
            display: false
          }
        },
        maintainAspectRatio: false
      }
    };

    if (severityChartRef.current) {
      severityChartRef.current.destroy();
    }

    severityChartRef.current = new Chart(severityChartCanvasRef.current, config);
  }, [loading, missions, chartSeverities, farmsFeed, t]);

  useEffect(() => {
    if (!infectionRateChartCanvasRef.current || loading) return;
    const labels: string[] = [];
    const data: number[] = [];
    const backgrounds: string[] = [];
    const infectedReportItems = farmsFeed.filter((entry) => chartSeverities.includes(entry.data?.hlbStatus as EHLBStatus));

    const infectedPercentage = numberUtils.normalizeDecimal(numberUtils.convertToPercentage(infectedReportItems.length / totalTreesCount), 2);
    data.push(infectedPercentage);
    labels.push(t('missions.hlb_positive_percentage', { percentage: `${infectedPercentage}%` }));
    backgrounds.push(severityBackgroundMap.get(EHLBStatus.F4) || '');

    data.push(100 - infectedPercentage);
    labels.push(t('missions.hlb_negative_percentage', { percentage: `${100 - infectedPercentage}%` }));
    backgrounds.push(severityBackgroundMap.get(EHLBStatus.NoSymptoms) || '');

    const config = {
      type: 'pie',
      data: {
        labels,
        datasets: [
          {
            data,
            backgroundColor: backgrounds
          }
        ]
      },
      options: {
        responsive: true,
        tooltips: {
          callbacks: {
            label: (context) => {
              const value = data[context.index];
              return ` ${value}%`;
            }
          }
        },
        plugins: {
          legend: {
            position: 'top'
          },
          title: {
            display: false
          }
        },
        maintainAspectRatio: false
      }
    };

    if (infectionRateChartRef.current) {
      infectionRateChartRef.current.destroy();
    }

    infectionRateChartRef.current = new Chart(infectionRateChartCanvasRef.current, config);
  }, [loading, farmsFeed, chartSeverities, totalTreesCount, t]);

  const handleChartClick = useCallback(
    (chart: Chart | null, e: React.MouseEvent<any>) => {
      const activePoints = chart.getElementsAtEvent(e);
      const farmIndex = activePoints?.[0]?._index;

      if (farmIndex !== undefined) {
        onSelectFarm(missionsFarms.filter((entry) => !!entry.totalTrees)[farmIndex].id);
      }
    },
    [missionsFarms, onSelectFarm]
  );

  useEffect(() => {
    if (!perFarmChartCanvasRef.current || loading) return;
    const labels: string[] = [];
    const noHlbData: number[] = [];
    const hlbData: number[] = [];
    let chartMax = 1;

    if (!isPreassigned) {
      // prettier-ignore
      const maxPercentage = Math.ceil(missionsFarms.reduce((acc: number, farm) => {
        if (!farm.totalTrees) return acc;
        const hlbRatio = farm.hlbFeed.length / farm.totalTrees;
        return hlbRatio > acc ? hlbRatio : acc;
      }, 0) * 100) / 100;
      chartMax = maxPercentage > 0.15 ? maxPercentage : 0.15;
    }

    missionsFarms.forEach((farm) => {
      const totalTrees = isPreassigned ? farm.feed.length : farm.totalTrees;
      if (!totalTrees) return;

      const hlbRatio = farm.hlbFeed.length / totalTrees;

      labels.push(farm.name);
      noHlbData.push(numberUtils.normalizeDecimal(numberUtils.convertToPercentage(chartMax - hlbRatio), 2));
      hlbData.push(numberUtils.normalizeDecimal(numberUtils.convertToPercentage(hlbRatio), 2));
    });

    const hlbBg = severityBackgroundMap.get(EHLBStatus.F4) as string;
    const noHlbBg = severityBackgroundMap.get(EHLBStatus.NoSymptoms) as string;

    const config = {
      type: 'bar',
      data: {
        labels,
        datasets: [
          {
            label: t('missions.hlb_plus'),
            data: hlbData,
            backgroundColor: labels.map((entry) => (!chartFarm?.name || entry === chartFarm.name ? hlbBg : hexToRgb(hlbBg, 0.2))),
            stack: 'Stack 0',
            maxBarThickness: 80
          },
          {
            label: t('missions.no_hlb'),
            data: noHlbData,
            backgroundColor: labels.map((entry) => (!chartFarm?.name || entry === chartFarm.name ? noHlbBg : hexToRgb(noHlbBg, 0.2))),
            stack: 'Stack 0',
            maxBarThickness: 80
          }
        ]
      },
      options: {
        tooltips: {
          callbacks: {
            label: (context) => {
              const formattedPercentage = numberUtils.normalizeDecimal(context.datasetIndex === 1 ? context.yLabel + (100 - chartMax * 100) : context.yLabel, 2);
              return `${t(context.datasetIndex === 0 ? 'missions.hlb_plus' : 'missions.no_hlb')}: ${formattedPercentage} %`;
            }
          }
        },
        plugins: {
          title: {
            display: false
          }
        },
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          yAxes: [
            {
              stacked: true,
              ticks: {
                max: chartMax * 100,
                callback: (value: number) => `${value}%`
              }
            }
          ],
          xAxes: [
            {
              ticks: {
                autoSkip: false
              }
            }
          ]
        }
      }
    };

    if (perFarmChartRef.current) {
      perFarmChartRef.current.destroy();
    }

    perFarmChartRef.current = new Chart(perFarmChartCanvasRef.current, config);
  }, [chartFarm, loading, missionsFarms, isPreassigned, t]);

  return (
    <Wrapper>
      <Title>{t('missions.percentage_of_hlb_contamination_levels')}</Title>
      {loading && <LocalLoader />}
      {!loading && (
        <>
          <ChartWrapper isSingleColumn={isPreassigned}>
            <div className="column">
              {!isPreassigned && <SubTitle>{!isPreassigned ? t('missions.severity_distribution') : t('dashboard.customer_farms', { customer: company.name })}</SubTitle>}
              <div className="chart-wrapper">
                <canvas ref={severityChartCanvasRef} />
              </div>
            </div>
            {!isPreassigned && (
              <div className="column">
                <SubTitle>{t('missions.infection_rate')}</SubTitle>
                <div className="chart-wrapper">
                  <canvas ref={infectionRateChartCanvasRef} />
                </div>
              </div>
            )}
          </ChartWrapper>
          <ChartWrapper isSingleColumn>
            <div className="column">
              <SubTitle>{t('dashboard.per_farm')}</SubTitle>
              <div className="chart-wrapper">
                <canvas onClick={(e) => handleChartClick(perFarmChartRef.current, e)} ref={perFarmChartCanvasRef} />
              </div>
            </div>
          </ChartWrapper>
        </>
      )}
    </Wrapper>
  );
};

export default HlbContamination;
