import React, { useMemo, useCallback } from 'react';
import styled from 'styled-components';

import dateUtils from 'utils/date';
import dateFormats from 'models/date';

import { IGrove } from 'models/grove';
import { IUnifiedHotspot } from 'models/hotspot';

import SlideSwitch from 'atomicComponents/SlideSwitch';

import { SatelliteIcon } from 'assets/images';
import { mfColors } from 'vars';

const Wrapper = styled.div`
  position: absolute;
  left: 50%;
  top: 0;
  transform: translateX(-50%);
  border-radius: 8px;
  background: ${mfColors.white};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const Icon = styled.div`
  margin: 0 0 0 16px;
  line-height: 0;

  svg {
    width: 24px;
    height: 24px;
  }
`;

const HOTSPOTS_TIMELINE_STYLE = {
  background: mfColors.white,
  opacity: 1
};

interface ITimelineItem {
  id: string;
  timestamp: number;
  model: IUnifiedHotspot;
}

interface IProps {
  grove: IGrove | null;
  satelliteHotspots: IUnifiedHotspot[];
  selectedSatelliteHotspots: IUnifiedHotspot[];
  onSelectHotspots?: (hotspots: IUnifiedHotspot[]) => void;
}

const HotspotsTimeline = ({ grove, satelliteHotspots, selectedSatelliteHotspots, onSelectHotspots }: IProps): JSX.Element | null => {
  const timelineItems = useMemo(() => {
    const result: ITimelineItem[] = satelliteHotspots.reduce((acc: ITimelineItem[], hotspot) => {
      const hotspotTimestamp = hotspot.date ? dateUtils.getMilliseconds(hotspot.date as string) : 0;

      const itemModel = {
        id: hotspot.clusterID,
        timestamp: hotspotTimestamp,
        model: hotspot
      };

      return !acc.find((entry) => hotspotTimestamp && entry.timestamp === hotspotTimestamp) ? [...acc, itemModel] : acc;
    }, []);

    return result.sort((a, b) => a.timestamp - b.timestamp);
  }, [satelliteHotspots]);

  const options = useMemo(() => {
    const result = timelineItems.map((item) => ({
      value: item.timestamp,
      text: <span>{dateUtils.formatDate(item.model.date as string, dateFormats.DATE_FORMAT_DD_MMM_YYYY)}</span>
    }));
    return result;
  }, [timelineItems]);

  const handleChange = useCallback(
    (selectedTimestamp: number | null) => {
      onSelectHotspots && onSelectHotspots(satelliteHotspots.filter((hotspot) => dateUtils.getMilliseconds(hotspot.date as string) === selectedTimestamp));
    },
    [satelliteHotspots, onSelectHotspots]
  );

  const selectedTimestamp = useMemo(() => {
    if (!selectedSatelliteHotspots.length) return null;
    return dateUtils.getMilliseconds(selectedSatelliteHotspots[0].date as string);
  }, [selectedSatelliteHotspots]);

  if (grove && options.length <= 1) return null;

  if (!options.length) return null;

  return (
    <Wrapper>
      <Icon>
        <SatelliteIcon />
      </Icon>
      <SlideSwitch<number | null> height="50px" style={HOTSPOTS_TIMELINE_STYLE} value={selectedTimestamp} options={options} onChange={handleChange} />
    </Wrapper>
  );
};

export default HotspotsTimeline;
