import React, { useMemo } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import farmsHooks from 'hooks/farms.hooks';
import { IFarm } from 'models/farm';
import { IGrove } from 'models/grove';
import { ISurvey } from 'models/survey';
import { IZone } from 'models/zone';
import { EReportType, TAnyReport, IScoreDifferenceReport } from 'models/report';
import { EMeasurementSystem } from 'models/region';
import BulletinTitle from 'components/main/bulletin/BulletinTitle';
import BulletinItem from 'components/main/bulletin/BulletinItem';
import BulletinChart from 'components/main/bulletin/BulletinChart';
import LocalLoader from 'atomicComponents/LocalLoader';

import { mfColors } from 'vars';
import { IUnifiedHotspot } from 'models/hotspot';
import { EMissionStatus } from 'models/mission';
import BulletinDashboard from 'components/main/bulletin/BulletinDashboard';

const Title = styled.div`
  font-family: MontserratBold;
  font-size: 20px;
  line-height: 24px;
  color: ${mfColors.darkGrey};
`;

const Content = styled.div`
  height: 700px;
  overflow: auto;

  > div {
    &:first-child {
      > div {
        &:first-child {
          margin: 0;
        }
      }
    }
  }
`;

const SubTitle = styled.div`
  margin: 16px 0 0;
  padding: 24px 0;
  font-size: 16px;
  width: calc(100% - 24px);
  border-bottom: 1px solid ${mfColors.lightGrey};
`;

const Items = styled.div`
  margin: 16px 0 0;
`;

const ReportItem = styled.div`
  width: calc(33% - 16px);
  display: inline-block;
  border: 1px solid ${mfColors.lightGrey};
  padding: 16px;
  margin: 0 16px 16px 0;
  border-radius: 8px;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  padding-right: 25px;
  margin: 0 0 24px;
  align-items: baseline;
`;

const SettingsButton = styled.div`
  display: flex;
  color: ${mfColors.lightBlue};
  background: ${mfColors.white};
  border: none;
  textdecoration: underline;
  texttransform: none;
  font-size: 16px;
  font-family: MontserratBold;
  cursor: pointer;
`;

interface IProps {
  farm: IFarm;
  groves: IGrove[];
  survey: ISurvey;
  zone: IZone | null;
  reports: { [key: string]: (TAnyReport | IUnifiedHotspot)[] };
  onGroveClicked: (grove: IGrove, reportType: EReportType) => void;
  selectedGrove: IGrove | null;
  metricType?: EMeasurementSystem;
  showSettingsModal: any;
}

const ITEM_BLOCKS_TYPES = [EReportType.DedicatedVisit, EReportType.TargetedScouting];
const FarmBulletinModal = ({ farm, survey, zone, reports: reportsAboveTreshold, onGroveClicked, groves, selectedGrove, metricType, showSettingsModal }: IProps): JSX.Element => {
  const { t } = useTranslation();
  const { reports: rawReports, isPending } = farmsHooks.useBulletinsEntity(farm.id, survey.id, groves, zone?.id, [], true);

  const reports = useMemo(() => {
    if (!rawReports[EReportType.DedicatedVisit]?.length) return rawReports;
    return {
      ...rawReports,
      [EReportType.DedicatedVisit]: (rawReports[EReportType.DedicatedVisit] as IScoreDifferenceReport[]).sort((a, b) => b.underperforming_difference - a.underperforming_difference)
    };
  }, [rawReports]);

  const sortedReportTypes = useMemo(() => Object.keys(reports).sort((a, b) => (!reports[b].length ? -1 : 0)), [reports]);

  return (
    <>
      <Header>
        <Title>{t('main.bulletin.detailed_bulletin_info')}</Title>
        <SettingsButton onClick={() => showSettingsModal()}>{t('main.bulletin.bulletin_threshold_settings')}</SettingsButton>
      </Header>
      <Content>
        {/* prettier-ignore */}
        {!isPending ? (
          <>
            {sortedReportTypes.map((reportType) => (
              <div key={reportType}>
                <SubTitle>
                  <BulletinTitle
                    reportType={reportType as EReportType}
                    reportsCount={(reportsAboveTreshold[reportType] || []).length}
                    reports={(reportsAboveTreshold[reportType] || []).filter((item) => item.status !== EMissionStatus.Done)}
                    selectedGrove={selectedGrove}
                  />
                </SubTitle>
                {!!reports[reportType]?.length && (
                  <>
                    {ITEM_BLOCKS_TYPES.includes(reportType as EReportType) ? (
                      <Items>
                        {reports[reportType]
                          .filter((value, index, self) => self.find((entry) => entry.groveID === value.groveID) === value)
                          .filter((item) => item.status !== EMissionStatus.Done)
                          .map((report, index) => {
                            const reportGroup = reports[reportType].filter((entry) => entry.groveID === report.groveID);
                            return (
                              <ReportItem key={report.groveID}>
                                <BulletinItem
                                  onGroveClicked={onGroveClicked}
                                  key={index}
                                  reports={reportGroup as TAnyReport[]}
                                  reportType={reportType as EReportType}
                                  metricType={metricType}
                                  groves={groves}
                                />
                              </ReportItem>
                            );
                          })}
                      </Items>
                    ) : (
                      <BulletinChart reportType={reportType as EReportType} reports={reports[reportType] as TAnyReport[]} groves={groves} farm={farm} metricType={metricType} />
                    )}
                  </>
                )}
              </div>
            ))}
            <BulletinDashboard reports={reportsAboveTreshold} />
          </>
        ) : (
          <LocalLoader />
        )}
      </Content>
    </>
  );
};

export default FarmBulletinModal;
