import React, { useMemo } from 'react';

import CreateMissionTypeOption from 'containers/CreateMissionTypeOption';
import { ISurveyWithTimeRange } from 'models/survey';
import { EMissionKpi, EMissionSubtype, TMissionType, EMissionType } from 'models/mission';

interface IProps {
  types: { type: TMissionType; subType: EMissionSubtype | null }[];
  selectedSurvey: ISurveyWithTimeRange;
  onSelectType: (
    type: TMissionType,
    subType: EMissionSubtype | null,
    checkedGroves: string[],
    isConfigured: boolean,
    isPreassigned: boolean,
    kpis?: EMissionKpi[],
    kpiScores?: number[]
  ) => void;
  zoneID: string | null;
}

const MissionTypesGallery = ({ types, onSelectType, selectedSurvey, zoneID }: IProps) => {
  const sortedMissionTypes = useMemo(() => {
    const result = types.sort((a, b) => {
      if (a.type === EMissionType.General) return 1;
      if (b.type === EMissionType.General) return -1;
      return 0;
    });

    return result;
  }, [types]);

  return (
    <>
      {sortedMissionTypes.map((entry) => (
        <CreateMissionTypeOption onMissionSelected={onSelectType} selectedSurvey={selectedSurvey} key={entry.subType || entry.type} type={entry.type} subType={entry.subType} />
      ))}
    </>
  );
};

export default MissionTypesGallery;
