import React, { useMemo, useEffect, useCallback, useState } from 'react';
import i18n from 'i18next';
import styled from 'styled-components';
import { IUnifiedGeojson } from 'hooks/farms.hooks';

import { IGrove } from 'models/grove';
import { ISurvey } from 'models/survey';
import { IGroveSurveyStats } from 'models/stats';
import { EMeasurementSystem } from 'models/region';
import { ERichItemType, IRichItem, IRichItemDefaultConfig } from 'models/richItem';

import { getPaintPropertyName } from 'utils/helpers';
import RichItemsChart, { IHighlightedRichItemsEvent } from 'components/main/MapControls/RichItemsChart';
import TreeStats from 'components/main/TreeStats/TreeStats';
import LocalLoader from 'atomicComponents/LocalLoader';
import { getChartData, getChartOptions } from 'utils/charts';

import { mfColors } from 'vars';
import { useTranslation } from 'react-i18next';
import { ICommodityCombination } from 'models/commodity';
import { TMappedStatistic } from 'models/statistic';
import { IRichItemRange } from 'services/data/appConfig';

const LoadingWrapper = styled.div`
  padding: 10px;
  border-radius: 8px;
  margin: 12px 0;
  background: ${mfColors.white};
`;

interface IProps {
  selectedSurvey: ISurvey;
  selectedGrove: IGrove | null;
  onHighlightedScores: (scores: number[]) => void;
  colorBlind: boolean;
  dataCardOpened: boolean;
  scoresLoading: boolean;
  selectedSurveyStats: IGroveSurveyStats[];
  onCardOpened: (dataCardOpened: boolean) => void;
  highlightedScores: number[];
  richItemGeoJSON: IUnifiedGeojson | null;
  onHighlightedRichItemsChange?: (event: IHighlightedRichItemsEvent) => void;
  richItemsConfigs: IRichItemDefaultConfig[];
  measurementSystem?: EMeasurementSystem;
  selectedRichItem: IRichItem | null;
  selectedVarieties: ICommodityCombination[];
  statistic: TMappedStatistic | null;
  richItemsRanges?: IRichItemRange[];
  capacityGroveData: number | null;
  activeRichItem: ERichItemType;
}

const GroveViewDataCard = ({
  selectedSurvey,
  selectedGrove,
  onHighlightedScores,
  colorBlind,
  dataCardOpened,
  onCardOpened,
  scoresLoading,
  selectedSurveyStats,
  highlightedScores,
  richItemGeoJSON,
  onHighlightedRichItemsChange,
  richItemsConfigs,
  measurementSystem = EMeasurementSystem.Metric,
  selectedRichItem,
  selectedVarieties,
  statistic,
  richItemsRanges,
  capacityGroveData,
  activeRichItem
}: IProps): JSX.Element | null => {
  const languageID = i18n.language;
  const { t } = useTranslation();
  const [highLightedChartRanges, setHighLightedChartRanges] = useState<number[]>([]);

  const toggleHighlightedChartRange = useCallback((rangeValue: number) => {
    setHighLightedChartRanges((prev) => {
      if (prev.includes(rangeValue)) {
        return prev.filter((entry) => entry !== rangeValue);
      } else {
        return [...prev, rangeValue];
      }
    });
  }, []);

  const hasPublishedStats = useMemo(
    () => !!selectedSurveyStats.find((stat) => stat.surveyID === selectedSurvey.id && stat.groveID === selectedGrove?.id)?.isPublished,
    [selectedSurveyStats, selectedSurvey, selectedGrove]
  );

  const chartProperty = useMemo(() => {
    if (!richItemGeoJSON) return null;
    return getPaintPropertyName(richItemGeoJSON);
  }, [richItemGeoJSON]);

  const chartLabels = useMemo(() => {
    if (!selectedRichItem) {
      return null;
    }

    if (richItemsRanges) {
      return richItemsRanges.map((item) => ({ chart_label: item.label, mapbox_value: item.value, color: item.color }));
    }

    const richItemConfig = richItemsConfigs.find((config) => config.id === selectedRichItem.richItemTypeName)?.version?.[selectedRichItem.richItemTypeVersion]?.[
      measurementSystem
    ]?.[languageID];

    return selectedRichItem.chartLabelConfigs?.[languageID] || richItemConfig?.chartLabelConfigs || null;
  }, [selectedRichItem, richItemsConfigs, languageID, measurementSystem, richItemsRanges]);

  const chartData = useMemo(
    () => getChartData({ richItemGeoJSON, activeRichItem: selectedRichItem, chartProperty, chartLabels }),
    [richItemGeoJSON, selectedRichItem, chartProperty, chartLabels]
  );

  const chartOptions = useMemo(
    () => getChartOptions({ toggleHighlightedChartRange, activeRichItem: selectedRichItem, languageID, chartProperty, chartData }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [toggleHighlightedChartRange, selectedRichItem, languageID, chartProperty, chartData]
  );

  const filteredStatistic = useMemo(() => {
    if (!selectedGrove) {
      return null;
    }

    if (statistic && statistic[selectedGrove.id]) {
      if (selectedVarieties.length === 0) {
        const result = Object.keys(statistic[selectedGrove.id]).reduce((acc, score) => {
          const scoreStats = statistic[selectedGrove.id][score];

          if (scoreStats) {
            acc[score] = scoreStats.total;
          }

          return acc;
        }, {});
        return {
          [selectedGrove.id]: {
            ...result
          }
        };
      } else {
        const result = Object.keys(statistic[selectedGrove.id]).reduce((acc, score) => {
          acc[score] = selectedVarieties.reduce((acc, variety) => {
            if (statistic[selectedGrove.id][score][variety.id]) {
              acc += statistic[selectedGrove.id][score][variety.id];
            }

            return acc;
          }, 0);
          return acc;
        }, {});
        return {
          [selectedGrove.id]: {
            ...result
          }
        };
      }
    }

    return statistic;
  }, [statistic, selectedVarieties, selectedGrove]);

  useEffect(() => {
    setHighLightedChartRanges([]);
  }, [selectedRichItem]);

  useEffect(() => {
    if (onHighlightedRichItemsChange && richItemGeoJSON) {
      onHighlightedRichItemsChange({
        richItemGeoJSON,
        chartLabels,
        selectedRanges: highLightedChartRanges
      });
    }
  }, [onHighlightedRichItemsChange, highLightedChartRanges, richItemGeoJSON, chartLabels]);

  const chartTitle = useMemo(() => {
    if (!selectedRichItem) return '';

    return t(selectedRichItem.richItemTypeName);
  }, [selectedRichItem, t]);

  const capacity = useMemo(() => {
    if (!capacityGroveData) return null;

    return capacityGroveData;
  }, [capacityGroveData]);
  const showScoreStatistic = useMemo(() => filteredStatistic && activeRichItem === ERichItemType.Health, [filteredStatistic, activeRichItem]);
  const showRichItemStatistic = useMemo(() => richItemGeoJSON && activeRichItem !== ERichItemType.Health, [richItemGeoJSON, activeRichItem]);

  if (scoresLoading) {
    return (
      <LoadingWrapper>
        <LocalLoader />
      </LoadingWrapper>
    );
  }

  if (!hasPublishedStats) return null;

  return (
    <>
      {showRichItemStatistic && (
        <RichItemsChart
          chartTitle={chartTitle}
          chartOptions={chartOptions}
          chartData={chartData}
          toggleHighlightedChartRange={toggleHighlightedChartRange}
          highLightedChartRanges={highLightedChartRanges}
          measurementSystem={measurementSystem}
        />
      )}
      {showScoreStatistic && (
        <TreeStats
          selectedGrove={selectedGrove}
          selectedSurvey={selectedSurvey}
          statistic={filteredStatistic}
          selectedSurveyStats={selectedSurveyStats}
          onHighlightedScores={onHighlightedScores}
          colorBlind={colorBlind}
          dataCardOpened={dataCardOpened}
          onCardOpened={onCardOpened}
          highlightedScores={highlightedScores}
          capacity={capacity}
        />
      )}
    </>
  );
};

export default GroveViewDataCard;
