import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/pro-solid-svg-icons';
import { mfColors, mfSizes } from 'vars';

interface IWrapperProps {
  isExpanded?: boolean;
}

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 75px;
  height: 100%;
  z-index: 3;
  display: flex;
  justify-content: center;
  background: ${mfColors.sideBarBackground};
  transition: width 0.25s ease-in;
  width: ${({ isExpanded }: IWrapperProps) => (isExpanded ? `${mfSizes.sideBarAreaWidth}` : '0')};
  overflow: hidden;
  border-radius: 0 8px 8px 0;
`;

interface ICloseIcon {
  top?: number;
}

const CloseIcon = styled(FontAwesomeIcon)`
  position: absolute;
  top: ${({ top }: ICloseIcon) => (top ? `${top}px` : '24px')};
  right: 24px;
  font-size: 24px;
  cursor: pointer;
  z-index: 3;
`;

type TProps = PropsWithChildren<{
  isExpanded: boolean;
  onClose: () => void;
  closeTopPosition?: number;
}>;

const SideBarExpandedContent = ({ isExpanded = false, onClose, children, closeTopPosition }: TProps) => (
  <Wrapper isExpanded={isExpanded}>
    <>{children}</>
    <CloseIcon onClick={onClose} icon={faChevronLeft} top={closeTopPosition} />
  </Wrapper>
);

export default SideBarExpandedContent;
