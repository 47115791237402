import { Map as MapboxMap, GeoJSONSource } from 'mapbox-gl';
import * as turf from '@turf/turf';
import { Feature } from '@turf/turf';

import { BULLETIN_LAYER, LAYERS_PRIORITY, addLayerToMap, reorderLayers, addMapSource, loadImage } from 'services/util/mapHelpers';
import { TAnyReport, EReportType } from 'models/report';
import { IGrove } from 'models/grove';

const BULLETIN_IMAGES = [
  {
    url: '/images/bulletins/bindweeds.png',
    id: 'bindweeds'
  },
  {
    url: '/images/bulletins/bindweeds_done.png',
    id: 'bindweeds_done'
  },
  {
    url: '/images/bulletins/grove_scouting.png',
    id: 'grove_scouting'
  },
  {
    url: '/images/bulletins/grove_scouting_done.png',
    id: 'grove_scouting_done'
  },
  {
    url: '/images/bulletins/tree_hedging.png',
    id: 'tree_hedging'
  },
  {
    url: '/images/bulletins/tree_hedging_done.png',
    id: 'tree_hedging_done'
  },
  {
    url: '/images/bulletins/tree_topping.png',
    id: 'tree_topping'
  },
  {
    url: '/images/bulletins/tree_topping_done.png',
    id: 'tree_topping_done'
  },
  {
    url: '/images/bulletins/weeds_mowing.png',
    id: 'weeds_mowing'
  },
  {
    url: '/images/bulletins/weeds_mowing_done.png',
    id: 'weeds_mowing_done'
  },
  {
    url: '/images/bulletins/tree_replant.png',
    id: 'tree_replant'
  },
  {
    url: '/images/bulletins/tree_replant_done.png',
    id: 'tree_replant_done'
  },
  {
    url: '/images/bulletins/hotspot_scout.png',
    id: 'hotspot_scout'
  },
  {
    url: '/images/bulletins/hotspot_scout_done.png',
    id: 'hotspot_scout_done'
  }
];

export const addBulletinLayers = async (map: MapboxMap) => {
  await Promise.all(BULLETIN_IMAGES.map((imageConfig) => loadImage(map, imageConfig.url, imageConfig.id)));
  const iconImageStops = [
    [EReportType.Cipo, 'bindweeds'],
    [EReportType.DedicatedVisit, 'grove_scouting'],
    [EReportType.FreeArea, 'tree_hedging'],
    [EReportType.Height, 'tree_topping'],
    [EReportType.Weeds, 'weeds_mowing'],
    [EReportType.Replant, 'tree_replant'],
    [EReportType.TargetedScouting, 'hotspot_scout'],
    [`${EReportType.Cipo}_done`, 'bindweeds_done'],
    [`${EReportType.DedicatedVisit}_done`, 'grove_scouting_done'],
    [`${EReportType.FreeArea}_done`, 'tree_hedging_done'],
    [`${EReportType.Height}_done`, 'tree_topping_done'],
    [`${EReportType.Weeds}_done`, 'weeds_mowing_done'],
    [`${EReportType.Replant}_done`, 'tree_replant_done'],
    [`${EReportType.TargetedScouting}_done`, 'hotspot_scout_done']
  ];

  addMapSource(map, BULLETIN_LAYER, []);
  addLayerToMap(map, BULLETIN_LAYER, {
    id: BULLETIN_LAYER,
    type: 'symbol',
    source: BULLETIN_LAYER,
    layout: {
      'icon-image': {
        type: 'categorical',
        property: 'bulletinImage',
        stops: iconImageStops,
        default: 'bindweeds'
      },
      'icon-allow-overlap': true,
      'icon-size': {
        base: 2,
        stops: [
          [16, 0.75],
          [19, 1.5],
          [24, 3]
        ]
      },
      'icon-offset': {
        stops: [
          [16, [0, -25]],
          [17, [0, -40]],
          [20, [0, -50]]
        ]
      }
    }
  });

  reorderLayers(
    map,
    LAYERS_PRIORITY.map((layer, index) => ({
      zIndex: index,
      name: layer
    }))
  );
};

export const drawBulletins = (map: MapboxMap, type: EReportType, groves: IGrove[], bulletins: TAnyReport[]): void => {
  clearBulletins(map);
  const markers = bulletins
    .map((bulletin) => {
      const grove = groves.find((entry) => entry.id === bulletin.groveID);

      if (!grove) return null;

      const polygon = turf.polygon(grove.geometry.coordinates, { id: grove.id, bulletinType: type });
      const bulletinImage = bulletin.status === 'done' ? `${type}_done` : type;
      const center = turf.centerOfMass(polygon, { properties: { bulletinImage, groveID: grove.id, name: grove.name } });

      return center;
    })
    .filter((marker) => !!marker) as Feature[];

  (map.getSource(BULLETIN_LAYER) as GeoJSONSource)?.setData(turf.featureCollection(markers) as any);
};

export const clearBulletins = (map: MapboxMap) => {
  const source = map.getSource(BULLETIN_LAYER);
  if (source) {
    (source as GeoJSONSource).setData(turf.featureCollection([]));
  }
};

export const toggleBulletinsLayerVisibility = (map: MapboxMap, visibility: 'visible' | 'none') => {
  if (map.getLayer(BULLETIN_LAYER)) {
    map.setLayoutProperty(BULLETIN_LAYER, 'visibility', visibility);
  }
};
