import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { useImportScript } from 'hooks/util.hooks';

const SisenseContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const SisenseIframe = styled.iframe`
  width: 100%;
  height: 100%;
`;

const membersFilter = (title: string, dim: string, filterValue: string | string[] | null, datatype = 'text') => ({
  title,
  dim,
  datatype,
  filter: filterValue ? { members: [filterValue] } : null
});

const allIfNoLengthFilter = (title: string, dim: string, filterValue: string, datatype = 'text') => ({
  title,
  dim,
  datatype,
  filter: filterValue ? { members: [filterValue] } : { all: true }
});

const FACTS_FRUITS_CONFIG = {
  by: {
    table: 'facts_fruits',
    column: 'countPredict',
    dim: '[facts_fruits.countPredict]',
    datatype: 'numeric',
    agg: 'sum'
  },
  top: 20,
  filter: undefined
};

interface ISisense {
  url: string;
  dashboard: {
    getCurrent: () => Promise<{ oid: string; filters: { jaql: IJaql }[] }>;
    removeFilters: (filters: { jaql: IJaql }[]) => void;
    applyFilters: (filters: { jaql: IJaql }[]) => void;
    open: (id: string) => void;
  };
  settings: {
    showToolbar: boolean;
    showLeftPane: boolean;
    showRightPane: boolean;
    showHeader: boolean;
  };
  element: React.MutableRefObject<null>;
}

interface IConfigEntry {
  title: string;
  dim: string;
  level?: string;
  filter: {
    members?: any[];
    by?: {
      table: string;
      column: string;
      dim: string;
      datatype: string;
      agg: string;
    };
    top?: number;
  } | null;
  datatype?: string;
}

interface IJaql extends IConfigEntry {
  datatype: string;
}

interface IProps {
  dashboardID: string;
  filters: { [key: string]: string | string[] | null };
  setIsLoadUserDashboards?: (val: boolean) => void;
}

// @TODO it is nasty temporary solution, need to have an ignored list inside company object
const STATIC_DASHBOARDS = ['5f86b948922f65002dfcdf90', '62a10c9ad229ff00374374bc', '624298c007ee280036cfdb11'];

const SisenseFrame = ({ dashboardID, filters, setIsLoadUserDashboards }: IProps): JSX.Element => {
  const frame = useRef(null);
  const [sisense, setSisense] = useState<ISisense>();
  const [sisenseLoaded, setSisenseLoaded] = useState(false);
  const [sisenseScriptLoaded, setSisenseScriptLoaded] = useState(false);

  useImportScript('https://seetree.sisense.com/js/frame.js', setSisenseScriptLoaded);

  useEffect(() => {
    if (!filters) {
      return;
    }
    const dashboardFilters: { jaql: IJaql }[] = [];

    const allIfNoLength = (filter: string[]) => {
      if (!filter) {
        return null;
      }

      return filter.length ? { members: filter } : { all: true };
    };

    [
      membersFilter('customerName', '[dim_farms.customerName]', filters.customerName as string),
      membersFilter('customerSurveyName', '[dim_surveys.customerSurveyName]', filters.customerSurveyName as string),
      allIfNoLengthFilter('farmName', '[dim_farms.farmName]', filters.farmName as string),
      membersFilter('isPublished_int', '[dim_groves_publish_status.isPublished_int]', filters.isPublished_int ? ['1'] : null, 'numeric'),
      {
        title: 'commodity',
        dim: '[facts_rio_bravo_yield.variety1]',
        filter: allIfNoLength(filters.commodities as string[])
      },
      {
        title: 'varieties',
        dim: '[facts_rio_bravo_yield.variety2]',
        filter: allIfNoLength(filters.varieties as string[])
      },
      {
        title: 'publishedAt',
        dim: '[dim_groves_publish_status.publishedAt (Calendar)]',
        level: 'years',
        filter: filters.publishedAt ? { members: [filters.publishedAt] } : null,
        datatype: 'datetime'
      },
      {
        title: 'variety1',
        dim: '[facts_rio_bravo_yield.variety1]',
        filter: allIfNoLength(filters.variety1 as string[])
      },
      {
        title: 'groveName',
        dim: '[dim_groves.groveName]',
        filter: filters.countPredict ? FACTS_FRUITS_CONFIG : null
      }
    ].forEach((config: IConfigEntry) => {
      if (!config.filter) {
        return;
      }

      dashboardFilters.push({
        jaql: {
          datatype: config.datatype || 'text',
          ...config
        }
      });
    });

    async function updateSisenseFilters() {
      if (sisense && sisenseLoaded) {
        const currentDashboard = await sisense.dashboard.getCurrent();
        const oldFilters = currentDashboard.filters;

        if (STATIC_DASHBOARDS.includes(currentDashboard.oid)) return;

        sisense.dashboard.removeFilters(oldFilters);
        sisense.dashboard.applyFilters(dashboardFilters);
      }
    }

    updateSisenseFilters();
  }, [filters, sisenseLoaded, sisense]);

  useEffect(() => {
    if (!sisenseScriptLoaded) {
      return;
    }
    const { SisenseFrame, enums } = window['sisense.embed'];
    setSisenseLoaded(false);

    async function showSisenseFrame() {
      const sisenseFrame = new SisenseFrame({
        url: 'https://seetree.sisense.com',
        dashboard: dashboardID,
        settings: {
          showToolbar: false,
          showLeftPane: false,
          showRightPane: false,
          showHeader: false
        },
        element: frame.current
      });

      await sisenseFrame.render();

      setSisense(sisenseFrame);

      sisenseFrame.dashboard.on(enums.DashboardEventType.LOADED, () => {
        setSisenseLoaded(true);
        setIsLoadUserDashboards && setIsLoadUserDashboards(true);
      });
    }

    async function loadDashboard() {
      if (sisense && sisense.dashboard) {
        sisense.dashboard.open(dashboardID);
        setSisenseLoaded(true);
      }
    }

    if (sisense) {
      loadDashboard();
    } else {
      showSisenseFrame();
    }
  }, [frame, dashboardID, sisense, sisenseScriptLoaded, setIsLoadUserDashboards]);

  return (
    <SisenseContainer id="sisenseApp">
      <SisenseIframe title="sisence" ref={frame} frameBorder="0" />
    </SisenseContainer>
  );
};

export default SisenseFrame;
