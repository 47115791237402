import React, { useMemo, useState, useCallback } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { faCheck } from '@fortawesome/pro-solid-svg-icons';

import LocalLoader from 'atomicComponents/LocalLoader';
import Checkbox, { ESizes } from 'atomicComponents/Checkbox';
import Button from 'atomicComponents/Button';
import { TMissionType } from 'models/mission';
import { ICreateFeedIncompleteParams } from 'models/feed';
import missionUtils from 'utils/mission';

const Wrapper = styled.div`
  width: 100%;
`;

const Content = styled.div`
  min-height: 240px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 0 0 32px;
`;

const Title = styled.div`
  font-family: 'MontserratBold';
  margin: 0 0 16px;
`;

const CheckboxRow = styled.div`
  margin: 0 0 8px;
  display: flex;
  flex-direction: row;
  align-items: center;

  .label {
    line-height: 24px;
    margin: 0 0 0 12px;
  }
`;

const Controls = styled.div`
  margin: 10px 0 0;
  display: flex;
  justify-content: flex-end;

  > * {
    margin: 0 0 0 12px;
  }
`;

interface IProps {
  farmID: string;
  zoneID: string | null;
  type: TMissionType;
  onSelectFeed: (feed: ICreateFeedIncompleteParams[]) => void;
}

const ClippingSmartSampling = ({ farmID, zoneID, type, onSelectFeed }: IProps) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [loading, setLoading] = useState(false);
  const [selectedScores, setSelectedScores] = useState<number[]>([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [selectedTrees, setSelectedTrees] = useState<ICreateFeedIncompleteParams[]>([]);
  const { t } = useTranslation();

  const feedSourceMissionName = useMemo(() => {
    if (!type) return '';
    return t(missionUtils.getMissionTypeKey(type));
  }, [type, t]);

  const onCheckedChange = useCallback((score: number, checked: boolean) => {
    setSelectedScores((prev) => {
      if (!checked) {
        return prev.filter((entry) => entry !== score);
      } else {
        return [...prev, score];
      }
    });
  }, []);

  return (
    <Wrapper>
      {loading && <LocalLoader />}
      {!loading && (
        <>
          <Content>
            <Title>{t('missions.select_trees_by_health_score')}</Title>
            <CheckboxRow>
              <Checkbox size={ESizes.small} checked={selectedScores.includes(1)} onCheckedChange={(checked) => onCheckedChange(1, checked)} icon={faCheck} />
              <span className="label">{t('missions.1_non_producing_trees')}</span>
            </CheckboxRow>
            <CheckboxRow>
              <Checkbox size={ESizes.small} checked={selectedScores.includes(2)} onCheckedChange={(checked) => onCheckedChange(2, checked)} icon={faCheck} />
              <span className="label">{t('missions.x_underperforming', { x: 2 })}</span>
            </CheckboxRow>
            <CheckboxRow>
              <Checkbox size={ESizes.small} checked={selectedScores.includes(3)} onCheckedChange={(checked) => onCheckedChange(3, checked)} icon={faCheck} />
              <span className="label">{t('missions.x_underperforming', { x: 3 })}</span>
            </CheckboxRow>
          </Content>
          <Controls>
            <Button
              isDisabled={!selectedTrees.length}
              onClick={() => onSelectFeed(selectedTrees)}
              text={t('missions.select_trees_for_mission', { mission: feedSourceMissionName })}
            />
          </Controls>
        </>
      )}
    </Wrapper>
  );
};

export default ClippingSmartSampling;
