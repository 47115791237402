import React, { useCallback, useMemo, useEffect, useState, Dispatch, SetStateAction } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDownToLine, faList, faChartPie, faMap, faGear } from '@fortawesome/pro-solid-svg-icons';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { useModal } from 'react-modal-hook';

import { useUpdateFarmMission } from 'services/data/farms';
import signInHooks from 'hooks/signIn.hooks';
import regionsHooks from 'hooks/regions.hooks';
import missionUtils, { EDateRange, EMissionsSummaryMode } from 'utils/mission';
import missionHooks from 'hooks/mission.hooks';
import { useIsMounted } from 'hooks/util.hooks';

import InlineSelect from 'atomicComponents/InlineSelect';
import MissionDetailsTable from 'components/missions/MissionDetailsTable';
import Modal from 'atomicComponents/Modal';
import MissionModal from 'components/missions/MissionModal';
import ResolveMissionModal from 'components/missions/ResolveMissionModal';
import FruitCountHeaderDetails from 'containers/FruitCountHeaderDetails';
import HLBHeaderDetails from 'containers/HLBHeaderDetails';
import ClippingHeaderDetails from 'containers/ClippingHeaderDetails';
import WeeklyTrapMonitoringHeaderDetails from 'containers/WeeklyTrapMonitoringHeaderDetails';
import WeeklyTrapMonitoringHeaderHeatmap from 'containers/WeeklyTrapMonitoringHeaderHeatmap';
import HLBDashboard from 'containers/mission-dashboard/HLBDashboard';
import HLBDashboardNonPreassigned from 'containers/mission-dashboard/HLBDashboardNonPreassigned';
import ReplantHeaderDetails from 'containers/ReplantHeaderDetails';
import LocalLoader from 'atomicComponents/LocalLoader';
import Popover from 'atomicComponents/Popover';
import { EPosition } from 'atomicComponents/Dropdown';

import { IGrove } from 'models/grove';
import { ISurvey } from 'models/survey';
import { IUserInfo } from 'models/user';
import { IFeed } from 'models/feed';
import { IMission, EMissionStatus, EMissionSubtype, IMissionGroveLogEntry, EMissionType } from 'models/mission';
import { TAnyReport } from 'models/report';
import { IGroveSurveyStats } from 'models/stats';
import { EMeasurementSystem } from 'models/region';
import { TMappedStatistic } from 'models/statistic';
import { EEventType } from 'models/analytics';
import { mfColors, mfShadows } from 'vars';

import { DoneIcon, CongratulationsIcon } from 'assets/images';
import ThresholdSettings from 'containers/ThresholdSettings';
import PestsP3HeaderDetails from 'containers/PestsP3HeaderDetails';
import PestsP3HeaderHeatmap from 'containers/PestsP3HeaderHeatmap';

interface IHeaderProps {
  isExpanded: boolean;
}

interface IHeaderProps {
  isExpanded: boolean;
}

const Wrapper = styled.div`
  position: relative;
  padding: 24px 24px 24px 32px;
  background: ${mfColors.sideBarBackground};
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  box-shadow: ${({ isExpanded }: IHeaderProps) => (isExpanded ? mfShadows.missionBlockShadow : 'none')};
`;

const Header = styled.div`
  margin: 0 0 16px;
  border-bottom: 1px solid ${mfColors.grey};
  width: 100%;
  display: flex;
  min-height: 56px;
`;

const Filter = styled.div`
  margin: 0 48px 0 0;
`;

const Main = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Controls = styled.div`
  display: flex;
  flex-direction: row;
  position: absolute;
  right: 24px;

  .control {
    cursor: pointer;
    margin: 0 8px;
    font-size: 24px;
    width: 44px;
    height: 38px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${mfColors.darkGrey};

    &.disabled {
      opacity: 0.25;
      cursor: default;
    }

    &.active {
      border-radius: 8px;
      border: 2px solid ${mfColors.darkGrey};
    }

    > div {
      margin: 0;
      svg {
        max-width: 32px;
        max-height: 32px;
      }
    }

    &:last-child {
      margin: 0;
    }
  }
`;

const DROPDOWN_OFFSET = { x: 6, y: 16 };

enum EHeaderDetailsSection {
  Dashboard = 'Dashboard',
  Summary = 'Summary'
}

interface IProps {
  mission: IMission;
  selectedMissions: IMission[];
  groves: IGrove[];
  feed: IFeed[];
  reports: TAnyReport[];
  survey: ISurvey;
  checkedGroves: string[];
  missionsSummaryMode: EMissionsSummaryMode;
  onMissionsSummaryModeChanged: (mode: EMissionsSummaryMode) => void;
  selectedDateRange: EDateRange | null;
  onSelectDateRange: (range: EDateRange | null) => void;
  isHeatmapVisible: boolean;
  statistic: TMappedStatistic | null;
  surveyStats: IGroveSurveyStats[];
  onToggleHeatmap: Dispatch<SetStateAction<boolean>>;
}

const ResolveMissionHeader = ({
  mission,
  selectedMissions,
  survey,
  groves,
  feed,
  reports,
  statistic,
  surveyStats,
  missionsSummaryMode,
  onMissionsSummaryModeChanged,
  selectedDateRange,
  onSelectDateRange,
  checkedGroves,
  isHeatmapVisible,
  onToggleHeatmap
}: IProps) => {
  const { t } = useTranslation();
  const [expandedDetailsSection, setExpandedDetailsSection] = useState<EHeaderDetailsSection | null>(null);
  const isSummaryExpanded = expandedDetailsSection === EHeaderDetailsSection.Summary;
  const isDashboardExpanded = expandedDetailsSection === EHeaderDetailsSection.Dashboard;
  const isExpanded = isSummaryExpanded || isDashboardExpanded;
  const updateFarmMission = useUpdateFarmMission();
  const user = signInHooks.useSignedInUser();
  const metricType = regionsHooks.useGetMeasurementSystem() as EMeasurementSystem;
  const isMounted = useIsMounted();
  const { logMissionEvent } = missionHooks.useMissionLogger();
  const { exportTrees, loading: exportLoading } = missionHooks.useExportFeed();
  const { exportTrees: exportWeerlyTrapsFeed } = missionHooks.useExportWeeklyTrapFeed();
  const { exportReplants } = missionHooks.useExportReplants();

  const savedCheckedGroves = useMemo(() => Object.keys(mission.groves).filter((key: string) => missionUtils.checkMissionGroveStatus(mission, key, EMissionStatus.Done)), [mission]);

  const missionsToShow = useMemo(() => {
    if (missionsSummaryMode === EMissionsSummaryMode.CurrentMission) {
      return [mission];
    } else {
      return selectedMissions;
    }
  }, [missionsSummaryMode, mission, selectedMissions]);

  const movedToDone = useMemo(() => checkedGroves.filter((groveID) => !savedCheckedGroves.includes(groveID)), [checkedGroves, savedCheckedGroves]);
  const movedToInProgress = useMemo(() => savedCheckedGroves.filter((groveID) => !checkedGroves.includes(groveID)), [checkedGroves, savedCheckedGroves]);

  const isGroveStatusModified = useMemo(
    () => !!savedCheckedGroves.find((id) => !checkedGroves.includes(id)) || !!checkedGroves.find((id) => !savedCheckedGroves.includes(id)),
    [checkedGroves, savedCheckedGroves]
  );

  const isDone = useMemo(() => !Object.keys(mission.groves).find((groveID) => missionUtils.checkMissionGroveStatus(mission, groveID, EMissionStatus.InProgress)), [mission]);

  const updatedMissionGroves = useMemo(() => {
    const missionGroves: { [key: string]: EMissionStatus } = { ...mission.groves };

    movedToDone.forEach((groveID: string) => {
      missionGroves[groveID] = EMissionStatus.Done;
    });
    movedToInProgress.forEach((groveID: string) => {
      missionGroves[groveID] = EMissionStatus.InProgress;
    });

    return missionGroves;
  }, [mission, movedToDone, movedToInProgress]);

  const handleHeatmapClick = useCallback(() => {
    if (!missionUtils.isHeatmapSupported(mission.subType || mission.type)) return;
    onToggleHeatmap((prev) => {
      if (!prev) {
        setExpandedDetailsSection(null);
      }
      return !prev;
    });
  }, [onToggleHeatmap, mission.subType, mission.type]);

  const missionsOptions = useMemo(() => {
    const missionTypeKey = missionUtils.getMissionTypeKey(mission.type, mission.subType);
    return [
      { value: EMissionsSummaryMode.CurrentMission, key: EMissionsSummaryMode.CurrentMission, text: t('missions.current_mission') },
      { value: EMissionsSummaryMode.AllOfType, key: EMissionsSummaryMode.AllOfType, text: t('missions.all_type_missions', { type: missionTypeKey ? t(missionTypeKey) : '' }) }
    ];
  }, [t, mission]);

  const dateOptions = useMemo(
    () => [
      { value: null, key: 'all', text: t('shared.all') },
      { value: EDateRange.Last30Days, key: EDateRange.Last30Days, text: t('missions.last_month') },
      { value: EDateRange.Last90days, key: EDateRange.Last90days, text: t('missions.last_x_month', { x: 3 }) },
      { value: EDateRange.LastYear, key: EDateRange.LastYear, text: t('missions.last_year') }
    ],
    [t]
  );

  const isAllGrovesResolved = useMemo(
    () => Object.keys(mission.groves).length === Object.keys(updatedMissionGroves).filter((id: string) => updatedMissionGroves[id] === EMissionStatus.Done).length,
    [mission, updatedMissionGroves]
  );

  const updatedMissionLogs = useMemo(() => {
    if (!user) return mission.logs;
    const missionLogs: { [key: string]: IMissionGroveLogEntry } = mission.logs ? { ...mission.logs } : {};

    const payload = {
      userID: (user as IUserInfo).id,
      timestamp: +new Date(),
      user: (user as IUserInfo).displayName
    };

    movedToDone.forEach((groveID: string) => {
      missionLogs[groveID] = payload;
    });

    movedToInProgress.forEach((groveID: string) => {
      missionLogs[groveID] = payload;
    });

    return missionLogs;
  }, [mission, movedToDone, movedToInProgress, user]);

  const handleReopenMission = useCallback(async () => {
    const missionGroves = Object.keys(mission.groves).reduce((acc: { [key: string]: EMissionStatus }, groveID: string) => {
      acc[groveID] = EMissionStatus.InProgress;
      return acc;
    }, {});
    await updateFarmMission(mission.id, {
      ...mission,
      groves: missionGroves,
      approved: false
    });
  }, [mission, updateFarmMission]);

  const handleSaveMission = useCallback(
    async (saveApproval = false) => {
      await updateFarmMission(mission.id, {
        ...mission,
        groves: updatedMissionGroves,
        approved: saveApproval ? isAllGrovesResolved : false,
        logs: updatedMissionLogs
      });

      if (isAllGrovesResolved && saveApproval) {
        toast(<MissionModal icon={<CongratulationsIcon />} title={t('shared.hooray')} content={t('missions.all_groves_are_completed_and_moved_to_done')} />, { toastId: 'done' });
        logMissionEvent(mission, EEventType.MissionResolve);
      } else if (movedToDone.length) {
        toast(<MissionModal icon={<DoneIcon />} iconFirst title={t('shared.done')} content={t('missions.grove_marked_as_done')} />, { toastId: mission.id });
      }
    },
    [mission, updateFarmMission, updatedMissionGroves, isAllGrovesResolved, logMissionEvent, updatedMissionLogs, movedToDone, t]
  );

  useEffect(() => {
    if (isGroveStatusModified && !isDone && isMounted) {
      handleSaveMission();
    }
  }, [isGroveStatusModified, isDone, handleSaveMission, isMounted]);

  const summary = useMemo(() => {
    switch (mission.type) {
      case EMissionType.FruitCount:
        return <FruitCountHeaderDetails feed={feed} missions={selectedMissions} />;
      case EMissionType.HLBScouting:
        return <HLBHeaderDetails feed={feed} missions={selectedMissions} />;
      case EMissionType.Clipping:
        return <ClippingHeaderDetails feed={feed} missions={selectedMissions} />;
      case EMissionType.WeeklyTrapMonitoring:
        return <WeeklyTrapMonitoringHeaderDetails feed={feed} mission={mission} />;
      case EMissionType.Replant:
        return <ReplantHeaderDetails missions={selectedMissions} />;
      case EMissionType.PestsDesease:
        return mission.subType === EMissionSubtype.PestsP3 ? <PestsP3HeaderDetails feed={feed} mission={mission} /> : null;
      default:
        return null;
    }
  }, [feed, mission, selectedMissions]);

  const dashboard = useMemo(() => {
    switch (selectedMissions[0]?.type) {
      case EMissionType.HLBScouting:
        return mission.isPreassigned ? <HLBDashboard missions={selectedMissions} feed={feed} /> : <HLBDashboardNonPreassigned missions={selectedMissions} feed={feed} />;
      default:
        return null;
    }
  }, [feed, selectedMissions, mission.isPreassigned]);

  const heatmapConfig = useMemo(() => {
    switch (mission.type) {
      case EMissionType.WeeklyTrapMonitoring:
        return <WeeklyTrapMonitoringHeaderHeatmap feed={feed} />;
      case EMissionType.PestsDesease:
        return mission.subType === EMissionSubtype.PestsP3 ? <PestsP3HeaderHeatmap feed={feed} /> : null;
      default:
        return null;
    }
  }, [feed, mission]);

  const [showApprovalModal, hideApprovalModal] = useModal(() => {
    const handleResolve = () => {
      handleSaveMission(true);
      hideApprovalModal();
    };
    const handleReopen = () => {
      handleReopenMission();
      hideApprovalModal();
    };
    return (
      <Modal showClose={false} onClose={() => {}} width="440px">
        <ResolveMissionModal
          onOk={handleResolve}
          onCancel={handleReopen}
          icon={<DoneIcon />}
          iconFirst
          title={t('missions.done_q')}
          content={[t('missions.the_entire_mission_was_reported_as_completed'), t('missions.move_this_mission_to_done')]}
          okText={t('missions.yes_move_mission_to_done')}
          cancelText={t('missions.no_repeat_this_mission')}
        />
      </Modal>
    );
  }, [t, handleSaveMission, handleReopenMission]);

  useEffect(() => {
    const hasGroveInProgress = Object.keys(mission.groves).find((groveID) => mission.groves[groveID] === EMissionStatus.InProgress);
    if (!hasGroveInProgress && !mission.approved) {
      showApprovalModal();
    }
  }, [showApprovalModal, mission.approved, mission.groves]);

  const toggleDetails = useCallback(
    (section: EHeaderDetailsSection) => {
      if (!summary && section === EHeaderDetailsSection.Summary) return;
      onToggleHeatmap(false);
      setExpandedDetailsSection((prev) => {
        if (prev === section) return null;
        return section;
      });
    },
    [summary, onToggleHeatmap]
  );

  const exportMission = useCallback(() => {
    if (mission.type === EMissionType.WeeklyTrapMonitoring) {
      exportWeerlyTrapsFeed(groves, feed);
    } else if (mission.type === EMissionType.Replant) {
      if (statistic) {
        exportReplants(mission, groves, statistic);
      }
    } else {
      let filename = mission.title;

      if (missionsToShow.length > 1 && missionsToShow.every((m) => m.subType === EMissionSubtype.PestsP3)) {
        filename = t('missions.pests_scouting_p3');
      }

      exportTrees(mission, groves, survey, feed, filename);
    }
  }, [exportTrees, exportWeerlyTrapsFeed, exportReplants, mission, groves, survey, feed, statistic, missionsToShow, t]);

  const [showSettingsModal, hideSettingsModal] = useModal(
    () => (
      <Modal closeOnBackdrop width="900px" onClose={hideSettingsModal}>
        <ThresholdSettings />
      </Modal>
    ),
    []
  );

  return (
    <>
      <Wrapper isExpanded={isExpanded}>
        <Main>
          <Header>
            {((!isExpanded && mission.type !== EMissionType.WeeklyTrapMonitoring) || mission.subType === EMissionSubtype.PestsP3) && (
              <>
                <Filter>
                  <InlineSelect<EMissionsSummaryMode>
                    value={missionsSummaryMode}
                    background={mfColors.navBarBackground}
                    onChange={onMissionsSummaryModeChanged}
                    options={missionsOptions}
                  />
                </Filter>
                <Filter>
                  <InlineSelect<EDateRange | null>
                    isDisabled={missionsSummaryMode !== EMissionsSummaryMode.AllOfType}
                    value={selectedDateRange}
                    background={mfColors.navBarBackground}
                    onChange={onSelectDateRange}
                    options={dateOptions}
                  />
                </Filter>
              </>
            )}
          </Header>
          {!isExpanded && (
            <MissionDetailsTable
              selectedDateRange={selectedDateRange}
              feed={feed}
              surveyStats={surveyStats}
              missions={missionsToShow}
              groves={groves}
              metricType={metricType}
              statistic={statistic}
            />
          )}
          {isSummaryExpanded && summary}
          {isDashboardExpanded && dashboard}
          {isHeatmapVisible && heatmapConfig}
        </Main>
        <Controls>
          {mission.subType === EMissionSubtype.PestsP3 && (
            <Popover
              position={EPosition.BottomRight}
              offset={DROPDOWN_OFFSET}
              control={
                <div onClick={showSettingsModal} className="control">
                  <FontAwesomeIcon icon={faGear} />
                </div>
              }
              content={t('shared.settings')}
            />
          )}
          {!exportLoading ? (
            <Popover
              position={EPosition.BottomRight}
              offset={DROPDOWN_OFFSET}
              control={
                <div className={missionUtils.isExportSupported(mission.type, mission.subType) ? 'control' : 'control disabled'} onClick={exportMission}>
                  <FontAwesomeIcon icon={faArrowDownToLine} />
                </div>
              }
              content={t('missions.download_csv')}
            />
          ) : (
            <div className="control">
              <LocalLoader />
            </div>
          )}
          <Popover
            position={EPosition.BottomRight}
            offset={DROPDOWN_OFFSET}
            control={
              <div
                onClick={handleHeatmapClick}
                className={isHeatmapVisible ? 'control active' : `${missionUtils.isHeatmapSupported(mission.subType || mission.type) ? 'control' : 'control disabled'}`}
              >
                <FontAwesomeIcon icon={faMap} />
              </div>
            }
            content={t('missions.heat_map')}
          />
          <Popover
            position={EPosition.BottomRight}
            offset={DROPDOWN_OFFSET}
            control={
              <div
                className={isDashboardExpanded ? 'control active' : `${dashboard ? 'control' : 'control disabled'}`}
                onClick={() => !!dashboard && toggleDetails(EHeaderDetailsSection.Dashboard)}
              >
                <FontAwesomeIcon icon={faChartPie} />
              </div>
            }
            content={t('shared.nav_bar.dashboard')}
          />
          <Popover
            position={EPosition.BottomRight}
            offset={DROPDOWN_OFFSET}
            control={
              <div
                className={isSummaryExpanded ? 'control active' : `${summary ? 'control' : 'control disabled'}`}
                onClick={() => !!summary && toggleDetails(EHeaderDetailsSection.Summary)}
              >
                <FontAwesomeIcon icon={faList} />
              </div>
            }
            content={t('missions.mission_summary')}
          />
        </Controls>
      </Wrapper>
    </>
  );
};

export default ResolveMissionHeader;
