import React, { useMemo, useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { useModal } from 'react-modal-hook';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisVertical } from '@fortawesome/pro-solid-svg-icons';

import dateUtils from 'utils/date';
import dateFormats from 'models/date';
import missionUtils from 'utils/mission';
import farmsHooks from 'hooks/farms.hooks';

import DataTable from 'atomicComponents/DataTable';
import MissionIcon from 'components/missions/MissionIcon';
import SlideSwitch from 'atomicComponents/SlideSwitch';
import Modal from 'atomicComponents/Modal';

import { IMission } from 'models/mission';
import { IFeed, IPestsP3FeedData } from 'models/feed';

import { mfColors } from 'vars';

import missionConfigHooks from 'hooks/missionsConfig.hooks';
import PestsReport from 'components/missions/PestsReport';
import { IGrove } from 'models/grove';
import feedUtils from 'utils/feed';

const Wrapper = styled.div`
  padding: 24px 24px 24px 48px;
  border-radius: 0 0 16px 16px;
`;

const Header = styled.div`
  margin: 16px 0 32px;
  width: 916px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const Filter = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

const TableWrapper = styled.div`
  max-height: 320px;
  overflow: auto;
  width: 1056px;
`;

const TableCell = styled.div<{ color?: string }>`
  background: ${mfColors.white};
  padding: 4px;
  min-height: 32px;
  font-size: 12px;
  line-height: 24px;
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: center;

  &.left {
    text-align: left;
  }

  &.nowrap {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 195px;
    display: block;
  }

  &.pests-count {
    color: ${mfColors.white};
    font-family: MontserratBold;
  }

  &.threshold {
    color: ${({ color }) => color || mfColors.grey};
    border: 3px solid ${({ color }) => color || mfColors.grey};
    box-sizing: border-box;
    padding: 0 4px;
  }
`;

const ThresholdCell = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const ActionsCell = styled.div`
  padding: 0 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const ROW_STYLE = {
  border: 'none',
  background: 'none',
  margin: '0 0 4px'
};

const HEADER_STYLE = {
  border: 'none',
  background: 'none',
  fontSize: '12px',
  margin: '0 0 8px'
};

const HEADER_COLUMN_STYLE = {
  textTransform: 'none',
  minHeight: '0px',
  padding: '0 2px',
  whiteSpace: 'pre',
  textAlign: 'center'
};

const SLIDER_STYLE = {
  width: '320px'
};

interface ITableRow {
  id: string;
  groveID: string;
  avgBuds: number;
  percantage: number;
  highestPests: {
    title: string;
    infectedPercent: number;
    color: string;
  };
}

interface IProps {
  mission: IMission;
  feed: IFeed[];
}

const PestsP3HeaderDetails = ({ mission, feed }: IProps) => {
  const { t } = useTranslation();
  const [selectedTimestamp, setSelectedTimestamp] = useState<number | null>(null);
  const [selectedGrove, setSelectedGrove] = useState<IGrove>();
  const { surveyGroves: groves } = farmsHooks.useFarmEntities();
  const { pestsP3Settings } = missionConfigHooks.useGetPestsP3Settings(mission.farmID);

  const sortedFeed = useMemo(() => feed.slice().sort((a, b) => a.updatedAt - b.updatedAt), [feed]);

  const dateOptions = useMemo(() => {
    const sortedFeedDates = sortedFeed
      .map((entry) => dateUtils.getStartOfWeek(entry.updatedAt))
      .filter((value: number, index: number, self: number[]) => self.indexOf(value) === index);

    return sortedFeedDates.map((date) => ({
      value: date,
      text: t('missions.week_x_y', {
        x: dateUtils.formatDate(date, dateFormats.DATE_FORMAT_DD_MMM),
        y: dateUtils.formatDate(moment(date).add(7, 'days').valueOf(), dateFormats.DATE_FORMAT_DD_MMM)
      })
    }));
  }, [sortedFeed, t]);

  useEffect(() => {
    const lastFeedDate = sortedFeed.length > 0 ? sortedFeed.reverse()[0]?.updatedAt : null;
    setSelectedTimestamp(lastFeedDate ? dateUtils.getStartOfWeek(lastFeedDate) : null);
  }, [sortedFeed]);

  const groupedFeed = useMemo(() => {
    const endDate = moment(selectedTimestamp).add(7, 'days').valueOf();
    if (!selectedTimestamp) return null;

    return missionUtils.getGroupedPestsP3FeedForPeriod(sortedFeed, selectedTimestamp, endDate);
  }, [sortedFeed, selectedTimestamp]);

  const dataToShow = useMemo(() => {
    if (!groupedFeed) return [];

    return Object.keys(groupedFeed).reduce((acc, groveID: string) => {
      const groveFeed = groupedFeed[groveID];
      const pestReport = feedUtils.getPestsReport(groveFeed, pestsP3Settings);
      const highestPests = feedUtils.getHighestPestReport(pestReport);
      const avgBuds = groveFeed.reduce((acc: number, entry: IFeed) => acc + Number((entry.data as IPestsP3FeedData)?.buds_count || 0), 0) / groveFeed.length;
      const treesWithBuds = groveFeed.filter((entry: IFeed) => Number((entry.data as IPestsP3FeedData)?.buds_count || 0) > 0);
      const percantage = (treesWithBuds.length / groveFeed.length) * 100;
      const data = { id: groveID, groveID, avgBuds: Math.round(avgBuds), percantage: Math.round(percantage), highestPests };

      return acc.concat(data);
    }, [] as ITableRow[]);
  }, [groupedFeed, pestsP3Settings]);

  const reportFeed = useMemo(() => {
    if (!selectedGrove || !groupedFeed) return [];

    return groupedFeed[selectedGrove.id];
  }, [groupedFeed, selectedGrove]);

  const [showPestsReportModal, hidePestsReportModal] = useModal(
    () => (
      <Modal closeOnBackdrop width="900px" onClose={hidePestsReportModal}>
        {selectedGrove && <PestsReport pestsP3Settings={pestsP3Settings} feed={reportFeed} grove={selectedGrove} />}
      </Modal>
    ),
    [pestsP3Settings, reportFeed, selectedGrove]
  );

  const handleShowPestsReport = useCallback(
    (row) => {
      showPestsReportModal();

      const grove = groves.find((grove) => grove.id === row.groveID);
      setSelectedGrove(grove);
    },
    [showPestsReportModal, groves]
  );

  const tableConfig = useMemo(
    () => [
      {
        title: '',
        render: () => (
          <TableCell>
            <MissionIcon type={mission.type} size="24px" showBackground={false} />
          </TableCell>
        ),
        key: 'icon',
        sortable: false,
        width: '32px',
        style: {
          ...HEADER_COLUMN_STYLE,
          padding: '0px',
          margin: '0 2px 0 0'
        }
      },
      {
        title: t('missions.block'),
        render: (row: ITableRow) => {
          const grove = groves.find((grove) => grove.id === row.groveID);
          return <TableCell className="left">{grove?.name || ''}</TableCell>;
        },
        key: 'name',
        sortable: false,
        width: '120px',
        style: HEADER_COLUMN_STYLE
      },
      {
        title: t('missions.pests_p3.avg_buds_trees'),
        render: (row: ITableRow) => <TableCell className="left">{row.avgBuds}</TableCell>,
        key: 'name',
        sortable: false,
        width: '120px',
        style: HEADER_COLUMN_STYLE
      },
      {
        title: `% ${t('missions.pests_p3.trees_with_buds')}`,
        render: (row: ITableRow) => <TableCell className="left">{`${row.percantage}%`}</TableCell>,
        key: 'name',
        sortable: false,
        width: '120px',
        style: HEADER_COLUMN_STYLE
      },
      {
        title: t('missions.pests_p3.infection_threshold'),
        render: (row: ITableRow) => (
          <TableCell className="left threshold" color={row.highestPests.color}>
            <ThresholdCell>
              <div>{row.highestPests.title && t(`pests_p3.${[row.highestPests.title]}`)}</div>
              <div>{`${row.highestPests.infectedPercent}%`}</div>
            </ThresholdCell>
          </TableCell>
        ),
        key: 'name',
        sortable: false,
        width: '200px',
        style: HEADER_COLUMN_STYLE
      },
      {
        title: '',
        render: (feed: ITableRow) => (
          <ActionsCell onClick={() => handleShowPestsReport(feed)}>
            <FontAwesomeIcon icon={faEllipsisVertical} size="lg" />
          </ActionsCell>
        ),
        key: 'actions',
        sortable: false,
        width: '24px',
        style: HEADER_COLUMN_STYLE
      }
    ],
    [t, groves, mission.type, handleShowPestsReport]
  );

  const tableProps = useMemo(() => {
    const props = {
      config: tableConfig,
      data: dataToShow,
      showHeader: true,
      showFooter: false,
      rowsPerPage: 0,
      headerStyle: HEADER_STYLE,
      rowStyle: ROW_STYLE
    };
    return props;
  }, [tableConfig, dataToShow]);

  return (
    <Wrapper>
      <Header>
        <Filter>{!!selectedTimestamp && <SlideSwitch<number> style={SLIDER_STYLE} value={selectedTimestamp} options={dateOptions} onChange={setSelectedTimestamp} />}</Filter>
      </Header>
      <TableWrapper>
        <DataTable<ITableRow> {...tableProps} />
      </TableWrapper>
    </Wrapper>
  );
};

export default PestsP3HeaderDetails;
