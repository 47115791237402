import { createSelector, createSlice } from '@reduxjs/toolkit';
import type { RootState } from 'redux/store';
import { Geometry, Polygon, polygon, Position } from '@turf/turf';
import { isCapacityShownSelector } from './appStateSlice';

export interface IMeasurePoint {
  geometry: Geometry;
  properties: { [key: string]: string | number };
  type: 'Feature';
}

export enum EDistanceMeasurementType {
  Line,
  Area
}

export interface ILineString {
  geometry: Geometry;
  properties: { [key: string]: string | number };
  type: 'Feature';
}

interface IDistanceState {
  status: boolean;
  distance: number;
  type: EDistanceMeasurementType;
  measurePoints: Array<IMeasurePoint | ILineString>;
  measureArea: Polygon | null;
}

const slice = createSlice({
  name: 'distanceMeasurement',
  initialState: { status: false, distance: 0, type: EDistanceMeasurementType.Line, measurePoints: [], measureArea: null } as IDistanceState,
  reducers: {
    toggleDistanceMeasurementStatus: (state) => ({ ...state, status: !state.status }),
    setDistanceMeasurementStatus: (state, action) => ({ ...state, status: action.payload }),
    setDistanceMeasurementValue: (state, action) => ({ ...state, distance: action.payload }),
    setDistanceMeasurementType: (state, action) => ({ ...state, type: action.payload }),
    setMeasurePoints: (state, action) => ({ ...state, measurePoints: action.payload }),
    addMeasurePoint: (state, action) => ({ ...state, measurePoints: [...state.measurePoints, action.payload] }),
    removeMeasurePoint: (state, action) => {
      const measurePoints = state.measurePoints.filter((measurePoint) => measurePoint.properties.id !== action.payload);
      return { ...state, measurePoints };
    },
    setMeasureAreaBbox: (state, action) => ({ ...state, measureArea: action.payload })
  }
});

export const {
  toggleDistanceMeasurementStatus,
  setDistanceMeasurementStatus,
  setDistanceMeasurementValue,
  setDistanceMeasurementType,
  addMeasurePoint,
  removeMeasurePoint,
  setMeasurePoints,
  setMeasureAreaBbox
} = slice.actions;
export const getDistanceMeasurementStatus = (state: RootState) => state.distanceMeasurement.status;
export const getDistanceMeasurementValue = (state: RootState) => state.distanceMeasurement.distance;
export const getDistanceMeasurementType = (state: RootState) => state.distanceMeasurement.type;
export const getDistanceMeasurePoints = (state: RootState) => state.distanceMeasurement.measurePoints;
export const getMeasureAreaBbox = (state: RootState) => state.distanceMeasurement.measureArea;
export const getDistanceMeasurementPolygonSelector = createSelector([getDistanceMeasurePoints], (measurePoints) => {
  const coordinates = measurePoints.filter((measurePoint) => measurePoint.geometry.type === 'Point').map((measurePoint) => measurePoint.geometry.coordinates) as Position[];

  try {
    if (coordinates.length > 2) {
      const areaPolygon = polygon([coordinates]);

      return areaPolygon;
    }

    return null;
  } catch {
    return null;
  }
});
export const getIsPolygonSupported = createSelector([isCapacityShownSelector], (isCapacityShown) => !isCapacityShown);

export const distanceMeasurementReducer = slice.reducer;
