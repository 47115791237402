import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import { IFarm, IFarmName } from 'models/farm';
import { IGrove } from 'models/grove';
import { IZone } from 'models/zone';
import { ISurveyWithTimeRange } from 'models/survey';
import { IUnifiedHotspot } from 'models/hotspot';
import { ITreeMapboxFeature } from 'models/tree';
import { selectedSurveyStatsSelector } from 'redux/farm/farmSlice';

import FarmSearch from 'containers/FarmSearch';
import SurveyTimeline from 'components/main/Timeline/SurveyTimeline';
import StaticCard from 'atomicComponents/StaticCard';
import TreeTimeline from 'containers/TreeTimeline';

import { mfColors, mfShadows } from 'vars';

const Wrapper = styled.div`
  margin: 0 0 8px;
`;

interface IProps {
  farm: IFarm;
  zone: IZone | null;
  grove: IGrove | null;
  farms: IFarmName[];
  zones: IZone[];
  groves: IGrove[];
  surveys: ISurveyWithTimeRange[];
  selectedSurvey?: string;
  onSelectSurvey: (survey: ISurveyWithTimeRange | null) => void;
  prefix?: string;
  onSelectHotspots?: (hotspots: IUnifiedHotspot[]) => void;
  tree: ITreeMapboxFeature | null;
}

const MainPageMenu = ({ farm, farms, zones, groves, selectedSurvey, onSelectHotspots, onSelectSurvey, surveys, zone, grove, prefix = '', tree }: IProps): JSX.Element => {
  const selectedSurveyStats = useSelector(selectedSurveyStatsSelector);

  return (
    <Wrapper>
      <FarmSearch farm={farm} zone={zone} grove={grove} farms={farms} zones={zones} groves={groves} prefix={prefix} />
      {selectedSurvey && (
        <StaticCard
          padding="24px"
          width="100%"
          shadow={mfShadows.timelineShadow}
          background={mfColors.white}
          content={
            <SurveyTimeline
              onSelectSurvey={onSelectSurvey}
              surveys={surveys}
              selectedSurveyStats={selectedSurveyStats}
              onSelectHotspots={onSelectHotspots}
              selectedSurvey={selectedSurvey}
            />
          }
        />
      )}
      {tree && selectedSurvey ? <TreeTimeline surveys={surveys} selectedSurvey={selectedSurvey} onSelectSurvey={onSelectSurvey} farmID={farm.id} tree={tree} /> : null}
    </Wrapper>
  );
};

export default MainPageMenu;
