import { useCallback } from 'react';
import api from 'services/data/providers/api';
import appConfig from 'config/config.json';

import { IRichItemsGeojson } from 'models/richItem';
import useLogger, { EStorageType } from 'hooks/logger.hooks';

export type TFetchBucketGeojson = (filePath: string) => Promise<IRichItemsGeojson | null>;

export const useFetchBucketGeojson = (): TFetchBucketGeojson => {
  const { logError } = useLogger();

  return useCallback(
    async (filePath: string): Promise<IRichItemsGeojson | null> => {
      const path = `${appConfig.bucketFileProxyUrl}?token=${appConfig.bucketFileProxyToken}&link=${encodeURIComponent(filePath)}`;

      try {
        const resp = await api.get<{ data: IRichItemsGeojson }>(path, false);
        return resp.data;
      } catch (e) {
        logError(e as Error, path, EStorageType.Api);
        return Promise.resolve(null);
      }
    },
    [logError]
  );
};
