import React, { useState, useEffect, useCallback, useMemo, Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';
import Papa from 'papaparse';
import { useTranslation } from 'react-i18next';
import { useModal } from 'react-modal-hook';
import { toast } from 'react-toastify';

import signInHooks from 'hooks/signIn.hooks';
import FileUploader from 'components/shared/FileUploader';
import LocalLoader from 'atomicComponents/LocalLoader';
import Modal from 'atomicComponents/Modal';
import MissionModal from 'components/missions/MissionModal';
import CopyMissionFeed from 'containers/create-mission/CopyMissionFeed';
import ClippingSmartSampling from 'containers/create-mission/ClippingSmartSampling';
import GeneralSmartSampling from 'containers/create-mission/GeneralSmartSampling';
import { ISurveyWithTimeRange } from 'models/survey';
import { IUserInfo } from 'models/user';
import { ICreateMissionIncompleteParams, EMissionType } from 'models/mission';
import { ICreateFeedIncompleteParams } from 'models/feed';
import farmsHooks from 'hooks/farms.hooks';
import missionUtils from 'utils/mission';
import { useFetchBigQueryTreesData } from 'services/data/farms';

import { mfColors } from 'vars';

export const ALL_ZONES_PARAM = 'all';

const Wrapper = styled.div`
  width: 984px;
  max-height: 750px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Title = styled.div`
  font-family: MontserratBold;
  font-size: 20px;
  line-height: 24px;
  margin: 0 0 48px;
  text-align: left;
  color: ${mfColors.darkGrey};
  width: 100%;
`;

const Subtitle = styled.div`
  padding: 4px 0;
  margin: 0 0 24px;
  border-bottom: 2px solid ${mfColors.grey};
  font-size: 18px;
  width: 100%;
`;

const MissionsGroupWrapper = styled.div`
  width: 100%;
  margin: 0 -16px;
`;

interface IMissionsGroupEntryProps {
  isHighlighted?: boolean;
}

const MissionsGroupEntry = styled.div`
  float: left;
  margin: 0 16px 32px 16px;
  width: 306px;
  border-radius: 6px;
  background: ${({ isHighlighted }: IMissionsGroupEntryProps) => (isHighlighted ? mfColors.blue : mfColors.missionsGroupBackground)};
  opacity: ${({ isHighlighted }: IMissionsGroupEntryProps) => (isHighlighted ? 0.8 : 1)};
  color: ${({ isHighlighted }: IMissionsGroupEntryProps) => (isHighlighted ? mfColors.white : mfColors.darkGrey)};
  min-height: 102px;
  font-size: 18px;
  line-height: 24px;
  padding: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;

  &:first-child {
    margin-left: 0px;
  }

  &:last-child {
    margin-right: 0px;
  }

  &.disabled {
    cursor: default;
    opacity: 0.5;

    &:hover {
      background: ${mfColors.missionsGroupBackground};
      color: ${mfColors.darkGrey};
      opacity: 0.5;
    }
  }

  .icon {
    margin: 0 16px 0 0;
    padding: 11px 0 0 11px;
    flex-shrink: 0;
    border-radius: 50%;
    height: 50px;
    width: 50px;
    border: 2px solid ${mfColors.darkGrey};

    svg {
      width: 24px;
      height: 24px;
      fill: currentColor;

      path {
        fill: currentColor;
      }
    }
  }

  .hint {
    font-size: 14px;
    line-height: 16px;
  }

  .loader {
    margin: 0 auto;
    > div {
      margin: 0;
      color: ${mfColors.white};
      * {
        color: ${mfColors.white};
      }
    }
  }

  &:hover {
    background: ${mfColors.blue};
    color: ${mfColors.white};
    opacity: 1;

    .icon {
      border: 2px solid ${mfColors.white};
    }
  }

  &:hover {
    background: ${mfColors.blue};
    color: ${mfColors.white};

    .icon {
      border: 2px solid ${mfColors.white};
    }
  }
`;

interface IProps {
  missionParams: ICreateMissionIncompleteParams;
  onSetCheckedGroves: Dispatch<SetStateAction<string[]>>;
  selectedSurvey: ISurveyWithTimeRange;
  onUploadTrees: (trees: ICreateFeedIncompleteParams[]) => void;
  onClose: () => void;
}

enum ESelectTreesScreen {
  ContinuousMission = 'ContinuousMission',
  SmartSamplingMission = 'SmartSamplingMission'
}

const SAMPLING_MISSION_TYPES = [
  EMissionType.PestsDesease,
  EMissionType.HLBScouting,
  EMissionType.Sampling,
  EMissionType.IrrigationScouting,
  EMissionType.GeneralScouting,
  EMissionType.FruitCount
];

const CreateMissionAdvancedOptionsModal = ({ missionParams, onSetCheckedGroves, selectedSurvey, onUploadTrees, onClose }: IProps) => {
  const { t } = useTranslation();
  const { zones, getZones } = farmsHooks.useGetFarmZones();
  const [isImportLoading, setIsImportLoading] = useState(false);
  const [missionFeedParams, setMissionFeedParams] = useState<ICreateFeedIncompleteParams[]>([]);
  const [selectTreesScreen, setSelectTreesScreen] = useState<ESelectTreesScreen | null>(null);
  const { groves } = farmsHooks.useFarmEntities();
  const fetchBigQueryTreesData = useFetchBigQueryTreesData();
  const isCopyMissionFlowActive = [EMissionType.Clipping, EMissionType.GeneralScouting].includes(missionParams?.type as EMissionType);
  const isSmartSamplingMissionFlowActive = useMemo(() => SAMPLING_MISSION_TYPES.includes(missionParams?.type as EMissionType), [missionParams]);
  const user = signInHooks.useSignedInUser();
  const [showConfirmUploadModal, hideConfirmUploadModal] = useModal(() => {
    const handleOk = () => {
      hideConfirmUploadModal();
      onUploadTrees(missionFeedParams);
      onSetCheckedGroves((prev) => {
        const newGroves = missionFeedParams.reduce((acc: string[], entry: ICreateFeedIncompleteParams) => (acc.includes(entry.groveID) ? acc : [...acc, entry.groveID]), []);
        return [...prev, ...newGroves.filter((entry) => !prev.includes(entry))];
      });
      onClose();
    };
    const handleClose = () => {
      hideConfirmUploadModal();
      setMissionFeedParams([]);
      onClose();
    };
    return (
      <Modal showClose={false} width="520px" onClose={handleClose}>
        <MissionModal
          onOk={handleOk}
          onCancel={handleClose}
          title={t('missions.upload_trees')}
          content={t('missions.x_trees_was_successfully_selected_for_that_mission', { x: missionFeedParams.length })}
          okText={t('shared.ok')}
          cancelText={t('shared.cancel')}
        />
      </Modal>
    );
  }, [t, missionFeedParams, onUploadTrees, onSetCheckedGroves, onClose]);

  const addMissionWithImportedFeed = useCallback(
    (missionFeedParams: ICreateFeedIncompleteParams[], missionGroves?: string[]) => {
      onUploadTrees(missionFeedParams);

      let checkedGroves: string[] = [];
      if (missionGroves) {
        checkedGroves = missionGroves;
      } else {
        checkedGroves = missionFeedParams.reduce((acc: string[], entry: ICreateFeedIncompleteParams) => (acc.includes(entry.groveID) ? acc : [...acc, entry.groveID]), []);
      }
      onSetCheckedGroves((prev) => [...prev, ...checkedGroves.filter((entry) => !prev.includes(entry))]);
      onClose();
    },
    [onSetCheckedGroves, onUploadTrees, onClose]
  );

  useEffect(() => {
    getZones(missionParams.farmID);
  }, [missionParams.farmID, getZones]);

  useEffect(() => {
    if (missionFeedParams.length) {
      showConfirmUploadModal();
    }
  }, [missionFeedParams, showConfirmUploadModal]);

  const handleUploadTrees = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (isImportLoading) return;
      const file = event.currentTarget?.files?.[0];

      if (file) {
        setIsImportLoading(true);
        Papa.parse(file, {
          header: true,
          complete: async (content) => {
            const rows = (content.data || []) as { treeID: string; farmID?: string; ['Serial Number']: string }[];
            const treesParams = rows
              .filter((row) => !!row.treeID && (!row.farmID || row.farmID === missionParams.farmID))
              .filter((row, index, self) => self.findIndex((entry) => entry.treeID === row.treeID) === index)
              .map((row) => ({
                treeID: row.treeID.trim().replace(/"/g, ''),
                serialNumber: row['Serial Number']
              }));
            const treeIDs = treesParams.map((tree) => tree.treeID);
            const trees = await fetchBigQueryTreesData(missionParams.farmID, treeIDs);

            const reports = treesParams
              .map((param) => {
                const treeInfo = trees[param.treeID];
                const groveModel = groves.find((grove) => grove.id === treeInfo?.groveID);

                if (!treeInfo || !groveModel || (!!missionParams.zoneID && treeInfo.zoneID !== missionParams.zoneID)) return null;

                return missionUtils.getMissionReportParams(
                  user as IUserInfo,
                  missionParams,
                  treeInfo.index,
                  param.treeID,
                  { geometry: { type: 'Point', coordinates: treeInfo.coordinates } },
                  groveModel.id,
                  groveModel.zoneID,
                  param.serialNumber || ''
                );
              })
              .filter((report) => !!report) as ICreateFeedIncompleteParams[];

            setMissionFeedParams(reports);
            if (!reports.length) {
              toast(<MissionModal title={t('missions.failed_to_upload_file')} content={t('missions.failed_to_upload_file')} />);
            }

            setIsImportLoading(false);
          },
          error: () => {
            toast(<MissionModal title={t('missions.failed_to_upload_file')} content={t('missions.failed_to_upload_file')} />);
          }
        });
      }
    },
    [fetchBigQueryTreesData, missionParams, groves, isImportLoading, user, t]
  );

  return (
    <Wrapper>
      <Title>{t('missions.advanced_options')}</Title>
      {!selectTreesScreen && (
        <>
          <Subtitle>{t('missions.method_for_selecting_trees')}</Subtitle>
          <MissionsGroupWrapper>
            {isSmartSamplingMissionFlowActive && (
              <MissionsGroupEntry
                className={isSmartSamplingMissionFlowActive ? undefined : 'disabled'}
                isHighlighted={isSmartSamplingMissionFlowActive}
                onClick={() => isSmartSamplingMissionFlowActive && setSelectTreesScreen(ESelectTreesScreen.SmartSamplingMission)}
              >
                <div>
                  <div className="title">{t('missions.smart_sampling')}</div>
                  <div className="hint">{t('missions.trees_are_selected_by_tree_parameters')}</div>
                </div>
              </MissionsGroupEntry>
            )}
            {isCopyMissionFlowActive && (
              <MissionsGroupEntry isHighlighted={isCopyMissionFlowActive} onClick={() => isCopyMissionFlowActive && setSelectTreesScreen(ESelectTreesScreen.ContinuousMission)}>
                <div>
                  <div className="title">{t('missions.continuous_mission')}</div>
                  <div className="hint">{t('missions.select_trees_from_another_mission')}</div>
                </div>
              </MissionsGroupEntry>
            )}
            <FileUploader
              control={
                <MissionsGroupEntry isHighlighted>
                  {!isImportLoading ? (
                    <div>
                      <div className="title">{t('missions.upload_a_list_of_trees')}</div>
                      <div className="hint">{t('missions.upload_list_of_trees_description')}</div>
                    </div>
                  ) : (
                    <div className="loader">
                      <LocalLoader />
                    </div>
                  )}
                </MissionsGroupEntry>
              }
              onChange={handleUploadTrees}
            />
          </MissionsGroupWrapper>
        </>
      )}
      {selectTreesScreen === ESelectTreesScreen.ContinuousMission && (
        <>
          <Subtitle>{t('missions.continuous_mission_select_trees_from_another_mission')}</Subtitle>
          <CopyMissionFeed
            farmID={missionParams.farmID}
            zoneID={missionParams.zoneID}
            type={missionParams.type}
            subType={missionParams.subType}
            onSelectFeed={addMissionWithImportedFeed}
          />
        </>
      )}
      {selectTreesScreen === ESelectTreesScreen.SmartSamplingMission && (
        <>
          <Subtitle>{t('missions.sampling_random_trees_evenly_distributed')}</Subtitle>
          {missionParams.type === EMissionType.Clipping && (
            <ClippingSmartSampling farmID={missionParams.farmID} zoneID={missionParams.zoneID} type={missionParams.type} onSelectFeed={addMissionWithImportedFeed} />
          )}
          {missionParams.type !== EMissionType.Clipping && (
            <GeneralSmartSampling
              selectedSurvey={selectedSurvey}
              missionParams={missionParams}
              farmID={missionParams.farmID}
              zoneID={missionParams.zoneID}
              onSelectFeed={addMissionWithImportedFeed}
              zones={zones}
            />
          )}
        </>
      )}
    </Wrapper>
  );
};

export default CreateMissionAdvancedOptionsModal;
