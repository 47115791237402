import { UserCredential } from 'firebase/auth';
import db from 'services/data/providers/firebase';

const signIn = (email: string, password: string): Promise<UserCredential> => db.signIn(email, password);

const signOut = (): Promise<void> => db.signOut();

const authService = {
  signIn,
  signOut
};

export default authService;
