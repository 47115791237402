import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { EReportType } from 'models/report';
import { TMissionType, EMissionType, EMissionSubtype } from 'models/mission';
import { mfColors } from 'vars';

import missionUtils from 'utils/mission';
import {
  DedicatedVisitIcon,
  TargetedScoutingIcon,
  TreeToppingIcon,
  TreeHedgingIcon,
  WeedIcon,
  HLBIcon,
  TrapIcon,
  FruitIcon,
  PestsIcon,
  SamplingIcon,
  ClippingIcon,
  IrrigationIcon,
  GeneralIcon,
  BindweedsTreatmentIcon
} from 'assets/images';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSeedling } from '@fortawesome/pro-solid-svg-icons';

const Icon = styled.div`
  width: 28px;
  height: 28px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: -1px;
  top: 0;
  background: ${mfColors.missionFillColor};
  border: 1px solid ${mfColors.missionBorderColor};
  border-radius: 14px;

  svg {
    max-width: 16px;
  }
`;

interface ILabelProps {
  isDisabled: boolean;
}

export const Label = styled.div`
  border-radius: 14px;
  line-height: 28px;
  padding: 0 12px 0 28px;
  display: flex;
  flex-direction: row;
  align-items: center;
  background: ${({ isDisabled }: ILabelProps) => (isDisabled ? mfColors.lightGrey : mfColors.missionFillColor)};
  color: ${({ isDisabled }: ILabelProps) => (isDisabled ? mfColors.grey : mfColors.missionTextColor)};
  border: ${({ isDisabled }: ILabelProps) => (isDisabled ? `1px solid ${mfColors.grey}` : `1px solid ${mfColors.missionBorderColor}`)};
  border-left: 0;
  position: relative;
  font-size: 12px;

  ${Icon} {
    background: ${({ isDisabled }: ILabelProps) => (isDisabled ? mfColors.lightGrey : mfColors.missionFillColor)};
    border: ${({ isDisabled }: ILabelProps) => (isDisabled ? `1px solid ${mfColors.grey}` : `1px solid ${mfColors.missionBorderColor}`)};
  }

  span {
    margin: 0 0 0 8px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

interface IProps {
  type: EMissionSubtype | TMissionType;
  isDisabled?: boolean;
}

const MissionType = ({ type, isDisabled = false }: IProps): JSX.Element => {
  const { t } = useTranslation();
  const icon = useMemo(() => {
    switch (type) {
      case EReportType.DedicatedVisit:
        return <DedicatedVisitIcon />;
      case EReportType.TargetedScouting:
        return <TargetedScoutingIcon />;
      case EMissionType.GeneralScouting:
        return <GeneralIcon />;
      case EReportType.Height:
        return <TreeToppingIcon />;
      case EReportType.FreeArea:
        return <TreeHedgingIcon />;
      case EReportType.Weeds:
        return <WeedIcon style={{ marginTop: '-6px' }} />;
      case EReportType.Cipo:
        return <BindweedsTreatmentIcon />;
      case EMissionType.HLBScouting:
        return <HLBIcon />;
      case EMissionType.WeeklyTrapMonitoring:
        return <TrapIcon />;
      case EMissionType.PestsDesease:
      case EMissionSubtype.Pests:
      case EMissionSubtype.PestsP3:
      case EMissionSubtype.Disease:
      case EMissionType.SmartPestScouting:
        return <PestsIcon />;
      case EMissionType.FruitCount:
      case EMissionSubtype.CountingFruits:
      case EMissionSubtype.MarkAsCounted:
      case EMissionSubtype.BloomMonth:
        return <FruitIcon />;
      case EMissionType.Sampling:
        return <SamplingIcon />;
      case EMissionType.Clipping:
        return <ClippingIcon />;
      case EMissionType.IrrigationScouting:
        return <IrrigationIcon />;
      case EMissionType.Replant:
        return <FontAwesomeIcon icon={faSeedling} />;
      case EMissionType.General:
        return <GeneralIcon />;
      default:
        return <></>;
    }
  }, [type]);
  const text = useMemo(() => t(missionUtils.getMissionTypeKey(type)), [t, type]);

  return (
    <Label isDisabled={isDisabled}>
      <Icon>{icon}</Icon>
      <span>{text}</span>
    </Label>
  );
};

export default MissionType;
