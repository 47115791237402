import numeral from 'numeral';
import * as turf from '@turf/turf';
import { convertLength, convertArea } from '@turf/turf';
import { EMeasurementSystem } from 'models/region';
import { IGrove } from 'models/grove';
import { IUnifiedHotspot } from 'models/hotspot';
import { EReportType, DEFAULT_REPORT_TRESHOLDS } from 'models/report';
import { METER_TO_FEET_COEFICIENT, MILLIMETER_TO_INCH_COEFICIENT } from 'models/measurement';

const normalizeDecimal = (value: number, maxDecimals = 3): number => {
  const decimalPart = value % 1;
  const decimalsToShow = decimalPart.toFixed(maxDecimals);

  if (!decimalPart || +decimalsToShow === 0) {
    // eslint-disable-next-line no-bitwise
    return value | 0;
  }

  const roundMultiplier = 10 ** maxDecimals;

  return Math.round(value * roundMultiplier) / roundMultiplier;
};

const convertReportValue = (value: number, reportType: EReportType, reportTreshold: number | null, maxDecimals = 3): number => {
  const decimalPart = value % 1;
  const treshold = reportTreshold || DEFAULT_REPORT_TRESHOLDS[reportType];

  if (!treshold || !decimalPart) return normalizeDecimal(value, maxDecimals);

  const roundMultiplier = 10 ** maxDecimals;

  switch (reportType) {
    case EReportType.Cipo:
    case EReportType.Height:
      return (value < treshold ? Math.floor(value * roundMultiplier) : Math.round(value * roundMultiplier)) / roundMultiplier;
    case EReportType.FreeArea:
      return (value > treshold ? Math.ceil(value * roundMultiplier) : Math.round(value * roundMultiplier)) / roundMultiplier;
    default:
      return normalizeDecimal(value, maxDecimals);
  }
};

const convertToHighestOrder = (value: number): string => (value >= 1000000 ? `${normalizeDecimal(value / 1000000, 2)} M` : value.toString());

const formatHeight = (value: number, maxDecimals: number, metricType = EMeasurementSystem.Metric): string => {
  const measurement = metricType === EMeasurementSystem.Metric ? 'm' : 'ft';
  const decimal = normalizeDecimal(value, maxDecimals);
  return `${decimal} ${measurement}`;
};

const formatArea = (value: number, maxDecimals: number, metricType = EMeasurementSystem.Metric): string => {
  const measurement = metricType === EMeasurementSystem.Metric ? 'ha' : 'ac';
  const decimal = normalizeDecimal(value, maxDecimals);
  return `${decimal} ${measurement}`;
};

const formatSquare = (value: number, maxDecimals: number, metricType = EMeasurementSystem.Metric): string => {
  const measurement = metricType === EMeasurementSystem.Metric ? 'm<sup>2</sup>' : 'ft<sup>2</sup>';
  const decimal = normalizeDecimal(value, maxDecimals);
  return `${decimal} ${measurement}`;
};

const getVolumeMeasurement = (metricType: EMeasurementSystem, useMarkup: boolean): string => {
  if (metricType === EMeasurementSystem.Metric) {
    return useMarkup ? 'm<sup>3</sup>' : 'm^3';
  } else {
    return useMarkup ? 'ft<sup>3</sup>' : 'ft^3';
  }
};

const formatVolume = (value: number, maxDecimals: number, metricType = EMeasurementSystem.Metric, useMarkup = true): string => {
  const measurement = getVolumeMeasurement(metricType, useMarkup);
  const decimal = normalizeDecimal(value, maxDecimals);
  return `${decimal} ${measurement}`;
};

const formatSpeed = (value: number, metricType = EMeasurementSystem.Metric): string => {
  const measurement = metricType === EMeasurementSystem.Imperial ? 'Mph' : 'Km/h';
  return `${normalizeDecimal(convertLength(value, 'kilometers', metricType === EMeasurementSystem.Imperial ? 'miles' : 'kilometers'), 0)} ${measurement}`;
};

const getETByRegion = (et: number, metricType = EMeasurementSystem.Metric): string => {
  const metric = metricType === EMeasurementSystem.Imperial ? 'inch/day' : 'mm/day';
  const maxDecimals = metricType === EMeasurementSystem.Imperial ? 2 : 1;
  const result = normalizeDecimal(convertMillimeterToInch(et, metricType), maxDecimals);

  if (et < 0) {
    return `${parseFloat(`${result}`) === 0 ? result : `-${result}`} ${metric}`;
  }

  return `${result} ${metric}`;
};

const convertMeterToFeet = (value: number, system = EMeasurementSystem.Imperial): number => {
  if (system === EMeasurementSystem.Metric) {
    return value;
  }

  return Math.floor((value / METER_TO_FEET_COEFICIENT) * 10) / 10;
};

const converFeetToMeter = (value: number, system = EMeasurementSystem.Metric): number => {
  if (system === EMeasurementSystem.Metric || !system) {
    return value;
  }

  return Math.floor(value * METER_TO_FEET_COEFICIENT * 10) / 10;
};

const convertAreaMeterToFeet = (value: number, system = EMeasurementSystem.Imperial): number => {
  if (system === EMeasurementSystem.Metric) {
    return value;
  }

  return Math.floor(value / METER_TO_FEET_COEFICIENT ** 2);
};

const convertVolumeMeterToFeet = (value: number, system = EMeasurementSystem.Imperial): number => {
  if (system === EMeasurementSystem.Metric) {
    return value;
  }

  return Math.floor(value / METER_TO_FEET_COEFICIENT ** 3);
};

const getLocalizedNumber = (number: number, languageID: string): string => {
  const formatString = ['pt', 'es'].includes(languageID) ? '0[,]0a' : '0[.]0a';

  if (Number.isNaN(number)) {
    return number.toString();
  }

  return numeral(number).format(formatString);
};

const convertMillimeterToInch = (number: number, system = EMeasurementSystem.Imperial) => {
  if (system === EMeasurementSystem.Metric) {
    return number;
  }

  return Math.floor((number / MILLIMETER_TO_INCH_COEFICIENT) * 1000) / 1000;
};

const getGrovesArea = (groves: IGrove[], measurement: EMeasurementSystem) => {
  let result = 0;

  if (groves.length) {
    result = groves.reduce((total, grove) => total + (grove.plantedArea || grove.area || 0), 0);
  }

  if (measurement === EMeasurementSystem.Metric) {
    result = convertArea(result || 0, 'acres', 'meters') / 10000;
  }

  return result;
};

const getTotalHostpotsArea = (reports: IUnifiedHotspot[]): number => {
  const area = reports.reduce((acc, report) => acc + (report.geometry ? turf.area(turf.polygon([report.geometry])) : 0), 0) / 10000;
  return area;
};

const convertToPercentage = (number: number): number => number * 100;

const formatNumber = (number: number, format = '0,0') => numeral(number).format(format);

const convertSqMeterToSqFt = (number: number, metricType = EMeasurementSystem.Metric) => {
  if (metricType === EMeasurementSystem.Metric) {
    return number;
  }

  const FACTOR = 10.76391041671;

  return number * FACTOR;
};

const formatShortFormNumber = (value: number): string => {
  if (value >= 10 ** 6) {
    return `${normalizeDecimal(value / 10 ** 6, 2)} M`;
  } else if (value >= 10 ** 4) {
    return `${normalizeDecimal(value / 10 ** 3, 2)} K`;
  }
  return value.toLocaleString();
};

const numberUtils = {
  normalizeDecimal,
  formatHeight,
  formatArea,
  formatVolume,
  formatSquare,
  convertMeterToFeet,
  converFeetToMeter,
  convertAreaMeterToFeet,
  convertVolumeMeterToFeet,
  formatSpeed,
  getETByRegion,
  convertToHighestOrder,
  getLocalizedNumber,
  convertToPercentage,
  convertMillimeterToInch,
  getGrovesArea,
  getTotalHostpotsArea,
  convertReportValue,
  formatNumber,
  convertSqMeterToSqFt,
  formatShortFormNumber
};

export default numberUtils;
