import React, { useRef, useCallback } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUpFromBracket } from '@fortawesome/pro-solid-svg-icons';

const Wrapper = styled.div`
  input {
    display: none;
  }
`;

const UploadButton = styled.div`
  font-size: 24px;
  cursor: pointer;
`;

interface IProps {
  control?: JSX.Element;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const FileUploader = ({ control, onChange }: IProps): JSX.Element => {
  const hiddenFileInput = useRef<HTMLInputElement | null>(null);

  const handleClick = useCallback(() => {
    if (hiddenFileInput.current) {
      hiddenFileInput.current.click();
    }
  }, []);

  const handleCHange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onChange(event);
      event.target.value = '';
    },
    [onChange]
  );

  return (
    <Wrapper>
      {!control ? (
        <UploadButton onClick={handleClick}>
          <FontAwesomeIcon icon={faArrowUpFromBracket} />
        </UploadButton>
      ) : (
        <div onClick={handleClick}>{control}</div>
      )}
      <input type="file" ref={hiddenFileInput} onChange={handleCHange} />
    </Wrapper>
  );
};

export default FileUploader;
