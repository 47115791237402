import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { mfColors } from 'vars';

const Chip = styled.div`
  margin-left: '8px';
`;

const ButtonItem = styled.div<{ isActive: boolean; isSingle: boolean }>`
  min-width: 40px;
  margin-right: 8px;
  margin-bottom: 8px;
  border-radius: 5px;
  border-right: none;
  background-color: ${mfColors.superLightBlue};
  color: ${mfColors.white35};
  text-transform: capitalize;
  font-weight: 400;
  cursor: pointer;
  &:hover {
    background-color: ${({ isActive }) => (isActive ? mfColors.cardShadowGrey : mfColors.superLightBlue)};
  }
  ${({ isActive, isSingle }) =>
    isActive && // eslint-disable-line
    `color: ${isSingle ? mfColors.white : mfColors.lightBlue};
      &:hover {
        color: ${mfColors.lightBlue};
      }
  `}
`;

const Layout = styled.div`
  display: flex;
  align-items: end;
  flex-wrap: wrap;
`;

export type TToggleValueModifier = (arr: string | string[]) => void;

interface IProps {
  items: string[];
  onToggle: TToggleValueModifier;
  multiple?: boolean;
  label?: string;
  selected?: string | string[];
  chipText?: string[];
}
export default function ButtonToggleGroup({ items = [], onToggle = () => {}, multiple, label, selected, chipText = [] }: IProps) {
  const { t } = useTranslation();
  const selectedItems = (multiple ? selected : [selected]) || [];

  const handleClickButton = (item: string) => {
    if (!multiple) {
      onToggle(item);
    } else {
      const value = selected && selected.includes(item) ? (selected as string[]).filter((active) => active !== item) : (selected as string[]).concat(item);
      onToggle(value);
    }
  };

  return (
    <Layout>
      {label ? <span>{label}</span> : null}
      {items.map((item, idx) => (
        <ButtonItem key={idx} isActive={selectedItems.includes(item)} isSingle={!multiple} onClick={() => handleClickButton(item)}>
          {item}
          {chipText && chipText.includes(item) ? <Chip>{` ${t('button_toggle_group.chip')} `}</Chip> : null}
        </ButtonItem>
      ))}
    </Layout>
  );
}
