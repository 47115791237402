import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from 'redux/store';

import { TMissionType, EMissionSubtype } from 'models/mission';
import { IFeed } from 'models/feed';

interface IMissionsState {
  heatmapFeed: IFeed[];
  fieldReports: IFeed[];
  missionTypeFilter: TMissionType | EMissionSubtype | null;
}

const initialState = {
  heatmapFeed: [],
  fieldReports: [],
  missionTypeFilter: null
} as IMissionsState;

const slice = createSlice({
  name: 'missions',
  initialState,
  reducers: {
    setHeatmapFeed: (state, action: PayloadAction<IFeed[]>) => ({ ...state, heatmapFeed: action.payload }),
    setFieldReports: (state, action: PayloadAction<IFeed[]>) => ({ ...state, fieldReports: action.payload }),
    setMissionTypeFilter: (state, action: PayloadAction<TMissionType | EMissionSubtype | null>) => ({ ...state, missionTypeFilter: action.payload })
  }
});

export const missionsReducer = slice.reducer;

export const heatmapFeedSelector = (state: RootState) => state.missions.heatmapFeed;
export const fieldReportsSelector = (state: RootState) => state.missions.fieldReports;
export const isNewFieldReportsSelector = (state: RootState) => state.missions.fieldReports.some((item) => !item.data?.isDone);
export const missionTypeFilterSelector = (state: RootState) => state.missions.missionTypeFilter;

export const { setHeatmapFeed, setFieldReports, setMissionTypeFilter } = slice.actions;
