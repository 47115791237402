import { EFeedItemType, IFeed, IDecoratedFeed, IPestsP3FeedData, NOT_APPLICABLE_STATUSES } from 'models/feed';
import { IPestsP3Config } from 'models/missions-config';
import { mfColors } from 'vars';
import { sumObjectKeysBy } from 'utils/helpers';

const sumFruitCountNumber = (feed: IFeed[]) => {
  if (!feed) return 0;

  return feed.reduce((acc: number, entry: IFeed) => {
    const fruitCountNumber = entry.data?.fruitCountNumber || 0;

    if (!fruitCountNumber || fruitCountNumber < 0) return acc;

    return acc + (entry.data?.fruitCountNumber || 0);
  }, 0);
};

const decorateFeed = (feed: IFeed[]): IDecoratedFeed[] => {
  if (!feed) return [];

  return feed.map((entry: IFeed) => {
    if (entry.type !== EFeedItemType.Count) return entry;

    const fruitCountNumber = entry.data?.fruitCountNumber || 0;
    const isNotApplicable = entry.data?.isNotApplicable;

    let fruitCountStatus = '';

    // TODO: remove this when migration is done
    if (typeof isNotApplicable === 'boolean' && isNotApplicable) {
      fruitCountStatus = 'isNotApplicable';
    } else if (fruitCountNumber > 0) {
      fruitCountStatus = 'isCounted';
    } else {
      fruitCountStatus = entry.data?.fruitCountStatus || '';
    }

    return {
      ...entry,
      data: {
        ...entry.data,
        fruitCountNumber: fruitCountNumber < 0 ? 0 : fruitCountNumber,
        fruitCountStatus
      }
    } as IDecoratedFeed;
  });
};

const filterNotApplicable = (feed: IFeed[]) => {
  if (!feed) return [];

  return feed.filter((entry: IFeed) => {
    const fruitCountStatus = entry.data?.fruitCountStatus || '';

    if (NOT_APPLICABLE_STATUSES.includes(fruitCountStatus)) return false;

    return true;
  });
};

const aggregateFeedData = (feed: IFeed[]) => {
  const data = sumObjectKeysBy((item) => (item.data || {}) as object, feed) as unknown as IPestsP3FeedData;
  const diaphorinaCount = feed.filter((item) => {
    const feedData = (item.data || {}) as IPestsP3FeedData;

    return Number(feedData?.diaphorina_adult_count || 0) > 0;
  }).length;

  data.diaphorina_adult_count = diaphorinaCount;

  return data;
};

const getPestsReport = (feed: IFeed[], pestsP3Settings: IPestsP3Config[]) => {
  const aggregatedFeedData = aggregateFeedData(feed);
  const pestsReports = pestsP3Settings.map((setting) => {
    const { count, id, threshold, nonAction } = setting;
    const allTrees = feed.length * count;
    const infected = aggregatedFeedData[id];
    const infectedPercent = Math.round((infected / allTrees) * 100);
    const reachedThreshold = infectedPercent > threshold;
    let color = '';

    if (reachedThreshold) {
      if (nonAction) {
        color = mfColors.green;
      } else {
        color = mfColors.superRed;
      }
    }

    return {
      ...setting,
      infected,
      infectedPercent,
      allTrees,
      color
    };
  });

  return pestsReports;
};

const getHighestPestReport = (pestsReports: ReturnType<typeof getPestsReport>) => {
  const threshold = pestsReports.reduce(
    (acc, item) => {
      if (item.infectedPercent > acc.infectedPercent) {
        return item;
      }
      return acc;
    },
    { infectedPercent: 0, title: '', color: mfColors.grey }
  );

  return threshold;
};

const feedUtils = {
  sumFruitCountNumber,
  decorateFeed,
  filterNotApplicable,
  getPestsReport,
  aggregateFeedData,
  getHighestPestReport
};

export default feedUtils;
