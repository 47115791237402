import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getSelectedFarm } from 'redux/farm/farmSlice';
import { getFarmCompany } from 'redux/company/companySlice';
import { IThresholdConfigs, IThresholdOptions, IThresholdRanges } from 'models/thresholds';
import type { RootState } from 'redux/store';

interface IBulletinsState {
  thresholdConfigs: IThresholdConfigs;
}

const initialState = {
  thresholdConfigs: {
    options: null,
    ranges: null
  }
} as IBulletinsState;

const slice = createSlice({
  name: 'bulletin',
  initialState,
  reducers: {
    setThresholdRangesConfigs: (state, action: PayloadAction<IThresholdRanges | null>) => ({ ...state, thresholdConfigs: { ...state.thresholdConfigs, ranges: action.payload } }),
    setThresholdOptionsConfigs: (state, action: PayloadAction<IThresholdOptions | null>) => ({ ...state, thresholdConfigs: { ...state.thresholdConfigs, options: action.payload } })
  }
});

export const getThresholdRangesConfigs = (state: RootState) => state.bulletin.thresholdConfigs.ranges;
export const getThresholdOptionsConfigs = (state: RootState) => state.bulletin.thresholdConfigs.options;

export const getFarmBulletinThresholds = createSelector([getSelectedFarm, getFarmCompany], (farm, company) => {
  if (!farm || !company) {
    return {};
  }
  const farmBulletinThresholds = farm.farmBulletinThresholds || {};
  const companyFarmBulletinThresholds = company.farmBulletinThresholds || {};

  // hierarchy - company -> farm means farm thresholds overrides company
  return { ...companyFarmBulletinThresholds, ...farmBulletinThresholds };
});

export const bulletinReducer = slice.reducer;
export const { setThresholdRangesConfigs, setThresholdOptionsConfigs } = slice.actions;
