import { useCallback } from 'react';
import db from 'services/data/providers/firebase';
import { collection, doc, getDoc, getDocs, query, where } from 'firebase/firestore';
import Image from 'models/image';
import appConfig from 'config/config.json';

interface genericTreeImagesOptions {
  farmID: string;
  surveyID?: string;
  missionID?: string;
  imageID?: string;
  isAligned?: boolean;
  trees?: string[];
}

interface TreeImagesFromMultipleSourceOptions extends genericTreeImagesOptions {
  treeID: string;
}

interface GetTreeImagesOptions extends genericTreeImagesOptions {
  treeID?: string;
}

/* eslint-disable */
const getTreeImagesFromMultipleSource = async ({ farmID, treeID, surveyID, missionID }: TreeImagesFromMultipleSourceOptions) => {
  const associateImageToTree = (images) =>
    images.map((image) => {
    const distanceToCenter = image.distancesToCenter[treeID];
    const imagePosition = image.imagePositions[treeID];

    return { ...image, distanceToCenter, treeID, imagePosition };
  });
  const images = (
    await Promise.all([
      getTreeImages({ farmID, treeID, isAligned: true, surveyID }),
      getTreeImages({ farmID, trees: [treeID], isAligned: true, missionID, surveyID }).then(associateImageToTree)
    ])
  ).flat();

  const imageIds = new Set(images.map((image) => image.imageID));
  const result: Image[] = [];

  imageIds.forEach((imageID) => result.push(images.find((image) => image.imageID === imageID)));

  return result;
};
/* eslint-enable */

const getTreeImages = async ({ farmID, treeID, surveyID, missionID, imageID, isAligned, trees }: GetTreeImagesOptions) => {
  const imageCollection = collection(db.firestore, `farms/${farmID}/tree-images`);
  const queryConditions = [
    ...(trees ? [where('trees', 'array-contains-any', trees)] : []),
    ...(treeID ? [where('treeID', '==', treeID)] : []),
    ...(surveyID ? [where('surveyID', '==', surveyID)] : []),
    ...(missionID ? [where('missionID', '==', missionID)] : []),
    ...(imageID ? [where('imageID', '==', imageID)] : []),
    ...(isAligned ? [where('aligned_orb', '==', true)] : [])
  ];

  const q = query(imageCollection, ...queryConditions);
  const querySnapshot = await getDocs(q);
  const result: Image[] = [];
  querySnapshot.forEach((doc) => {
    result.push({
      ...doc.data(),
      id: doc.id
    } as Image);
  });

  return result;
};

const _getAerialImgs = async ({ farmID, images }) => {
  const promises = images.map((image) => _getAerialImg({ farmID, imageID: image.imageID }));
  const hasValidProperty = (img) => typeof img.isValid !== 'boolean' || img.isValid;

  return (await Promise.all(promises)).filter((img) => img && hasValidProperty(img));
};

const _getAerialImg = ({ farmID, imageID }) => {
  const imageCollection = collection(db.firestore, `farms/${farmID}/aerial-images`);
  const imageRef = doc(imageCollection, imageID);

  return getDoc(imageRef).then((doc) => {
    if (!doc.exists()) {
      return null;
    }
    const image = doc.data() as Image;

    image.center = image.center ? JSON.parse(image.center) : null;
    image.polygon = image.polygon ? JSON.parse(image.polygon) : null;
    image.id = imageID;
    return image;
  });
};
/* eslint-disable */
const useGetTreeAerialImages = () =>
  useCallback(async (farmID: string, treeID: string) => {
  const treeImages = await getTreeImagesFromMultipleSource({ farmID, treeID });
  if (!treeImages.length) return Promise.resolve(null);

  return _getAerialImgs({ farmID, images: treeImages.sort((a, b) => a.distanceToCenter - b.distanceToCenter) });
}, []);
/* eslint-enable */

const getImageCoveredTrees = async ({ farmID, missionID, imageID }) => {
  const images = await getTreeImages({ farmID, missionID, imageID });
  if (!images.length) return Promise.resolve();

  const result = {} as any;
  images.forEach((image) => {
    const { imagePositions, trees } = image;

    if (trees) {
      trees.forEach((treeID) => {
        result[treeID] = { imagePosition: imagePositions[treeID] };
      });
    } else {
      result[image.treeID] = image;
    }
  });
  return result;
};

const generateFilenameProperties = (fileOptions): string => {
  const options = {
    width: 'w',
    height: 'h',
    angle: 'a'
  };

  return Object.keys(options).reduce((str, key) => (fileOptions[key] ? `${str}_${options[key]}_${fileOptions[key]}` : str), '');
};

const getImagePreview = (image: string, imageOptions, extension = 'jpg'): string => {
  const fileProperties = generateFilenameProperties(imageOptions);

  return `${image}${fileProperties}.${extension}`;
};

const getImagePreviewURLFromBucket = (farmID: string, feedItemID: string, imageName: string): string => {
  const bucketName = `${appConfig.name}-feed-image-previews`;
  const storageURL = 'https://storage.googleapis.com';
  const imagePath = `${farmID}/${feedItemID}/${imageName}`;

  return `${storageURL}/${bucketName}/${imagePath}`;
};

const getImageURLFromBucket = (farmID: string, feedItemID: string, imageName: string): string => {
  const bucketName = `${appConfig.name}-feed-images`;
  const storageURL = 'https://storage.cloud.google.com';
  const imagePath = `${farmID}/${feedItemID}/${imageName}`;

  return `${storageURL}/${bucketName}/${imagePath}`;
};

export default {
  useGetTreeAerialImages,
  getImageCoveredTrees,
  getImagePreview,
  getImagePreviewURLFromBucket,
  getImageURLFromBucket
};
