import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { mfColors } from 'vars';

const ShowMore = styled.div`
  cursor: pointer;
  font-size: 12px;
  line-height: 15px;
  color: ${mfColors.lightBlue};
  text-align: center;
  padding: 0 0 24px;
`;

interface IProps {
  onToggle: (e?: React.MouseEvent) => void;
  isShowMore: boolean;
  itemsRemaining?: number;
  showMoreKey?: string;
  showLessKey?: string;
}

const SidebarBlockShowMore = ({ onToggle, isShowMore, itemsRemaining, showMoreKey, showLessKey }: IProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <ShowMore onClick={onToggle}>{isShowMore ? t(showLessKey || 'main.bulletin.show_less') : t(showMoreKey || 'main.bulletin.show_more', { count: itemsRemaining })}</ShowMore>
  );
};

export default SidebarBlockShowMore;
