import React, { Fragment, useMemo, useState } from 'react';
import styled from 'styled-components';
import { StaticCard } from 'atomicComponents';
import { useTranslation } from 'react-i18next';
import { mfColors, mfShadows } from 'vars';
import dateUtils from 'utils/date';
import dateFormats from 'models/date';
import { indexBy } from 'utils/helpers';
import Checkbox, { ESizes } from 'atomicComponents/Checkbox';
import { faCheck } from '@fortawesome/pro-solid-svg-icons';
import { IFeed, EFeedItemType } from 'models/feed';
import { useDispatch, useSelector } from 'react-redux';
import { fieldReportsSelector, setFieldReports } from 'redux/missions/missionsSlice';
import images from 'services/data/images';
import { useUpdateMissionReport } from 'services/data/farms';
import BulletinGrove from 'components/main/bulletin/BulletinGrove';
import { IGrove } from 'models/grove';

const Wrapper = styled.div`
  margin-top: 16px;
  overflow: auto;
  padding: 0 8px;
  height: 1000%;
`;
const Title = styled.div`
  font-family: MontserratBold;
  font-size: 16px;
  margin-left: 12px;
`;
const NoReportBlock = styled.div`
  text-align: center;
  margin-top: 30px;
`;
const DateSeparator = styled.div`
  color: ${mfColors.lightBlue};
  padding: 20px 0px 0px 8px;
`;
const ReportTitle = styled.div`
  font-family: MontserratBold;
  cursor: pointer;
  text-transform: capitalize;
`;
const ReportDetails = styled.div`
  margin-top: 8px;
`;
const ReportText = styled.div`
  text-decoration: underline;
  margin-top: 6px;
`;
const ReportTextBlock = styled.div`
  margin: 8px 0;
  font-size: 12px;
  word-break: break-word;
`;

const ReportWrapper = styled.div`
  display: flex;
`;

const ReportHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ImagesWrapper = styled.div`
  display: flex;
  margin: 8px 8px 8px 0px;
`;

const Image = styled.img`
  height: 60px;
  margin-right: 8px;
  border-radius: 8px;

  &:last-child {
    margin: 0;
  }
`;

const translations = {
  [EFeedItemType.General]: 'missions.general',
  [EFeedItemType.Pests]: 'missions.report_types.pests_and_disease',
  [EFeedItemType.PestAndDisease]: 'missions.report_types.pests_and_disease',
  [EFeedItemType.Irrigation]: 'missions.report_types.irrigation'
};

interface IFieldProps {
  report: IFeed;
  onChange: (report: IFeed, isChecked: boolean) => void;
  grove?: IGrove;
  onGroveClicked: (grove: IGrove) => void;
}

const FieldReport = ({ report, onChange, grove, onGroveClicked }: IFieldProps) => {
  const { t } = useTranslation();
  const [isExpanded, setIsExpanded] = useState(false);

  const imageUrls = useMemo(() => {
    if (!report.images) return [];

    return Object.keys(report.images || {}).map((imageKey) => {
      const fullImageName = images.getImagePreview(imageKey, {});
      return {
        url: images.getImageURLFromBucket(report.farmID, report.id, fullImageName)
      };
    });
  }, [report]);

  const reportType = useMemo(() => (translations[report.type] ? t(translations[report.type]) : report.type), [report, t]);

  return (
    <ReportWrapper>
      <Checkbox
        style={{ marginRight: 8, marginTop: 4 }}
        size={ESizes.small}
        checked={!!report.data?.isDone}
        onCheckedChange={(isChecked: boolean) => onChange(report, isChecked)}
        icon={faCheck}
      />
      <div style={{ width: '90%' }}>
        <ReportHeader>
          <div>
            <ReportTitle onClick={() => setIsExpanded(!isExpanded)}>{reportType}</ReportTitle>
            <div style={{ marginTop: 4, wordBreak: 'break-word' }}>{report.data?.title}</div>
          </div>
          {grove && <BulletinGrove grove={grove} onGroveClicked={() => onGroveClicked(grove)} isClickable />}
        </ReportHeader>
        {isExpanded && (
          <ReportDetails>
            {report.data?.body && (
              <>
                <ReportText>{`${t('missions.field_reports.report_text')}:`}</ReportText>
                <ReportTextBlock>{report.data.body}</ReportTextBlock>
              </>
            )}
            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
              {imageUrls.map((item) => (
                <ImagesWrapper key={item.url}>
                  <Image src={item.url} alt="Field report" />
                </ImagesWrapper>
              ))}
            </div>
            <div style={{ marginBottom: 8 }}>
              {`${t('missions.field_reports.reported_on')}: `}
              {dateUtils.formatDate(report.createdAt, dateFormats.DATE_FORMAT_DD_MMMM_YYYY)}
            </div>
            <div>
              {`${t('missions.reported_by')}: `}
              {report.user.displayName}
            </div>
          </ReportDetails>
        )}
      </div>
    </ReportWrapper>
  );
};

interface IProps {
  groves: IGrove[];
  onGroveClicked: (grove: IGrove) => void;
}

const FieldReports = ({ groves, onGroveClicked }: IProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const fieldReports = useSelector(fieldReportsSelector);
  const updateMissionReport = useUpdateMissionReport();

  const groupedByDate = useMemo(() => {
    const indexedReports = indexBy(
      'reportDate',
      fieldReports.map((item) => ({ ...item, reportDate: dateUtils.formatDate(item.updatedAt, dateFormats.DATE_FORMAT_DD_MMMM_YYYY) }))
    );

    return indexedReports;
  }, [fieldReports]);

  const onDoneReport = async (report: IFeed, isDone: boolean) => {
    const changedReports = fieldReports.map((item) => {
      if (item.id === report.id) {
        const { data } = item || {};
        const changedData = { ...data, isDone };

        return { ...item, data: changedData };
      }

      return item;
    });

    await updateMissionReport(report.id, { 'data.isDone': isDone });

    dispatch(setFieldReports(changedReports));
  };

  return (
    <Wrapper>
      <Title>{t('missions.field_reports.title')}</Title>
      {!fieldReports.length && <NoReportBlock>{t('missions.field_reports.no_reports')}</NoReportBlock>}
      {!!fieldReports.length && (
        <>
          {Object.keys(groupedByDate).map((date) => (
            <Fragment key={date}>
              <DateSeparator>{date}</DateSeparator>
              <>
                {groupedByDate[date].map((item) => (
                  <StaticCard
                    key={item.id}
                    shadow={mfShadows.missionBlockShadow}
                    padding="16px"
                    width="100%"
                    background={mfColors.white}
                    content={<FieldReport report={item} onChange={onDoneReport} onGroveClicked={onGroveClicked} grove={groves.find((grove) => item.groveID === grove.id)} />}
                  />
                ))}
              </>
            </Fragment>
          ))}
        </>
      )}
    </Wrapper>
  );
};

export default FieldReports;
