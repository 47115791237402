import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { mfColors } from 'vars';

interface IWrapperProps {
  multiline?: boolean;
}

const Wrapper = styled.div`
  font-size: ${({ multiline }: IWrapperProps) => (multiline ? '12px' : '14px')};
  color: ${mfColors.grey};

  .separator {
    margin: 0 16px;
  }

  > .separator {
    &:nth-child(2) {
      display: ${({ multiline }: IWrapperProps) => (multiline ? 'none' : 'inline')};
    }
  }

  .links {
    margin: 8px 0 0;
    display: ${({ multiline }: IWrapperProps) => (multiline ? 'block' : 'inline')};
  }

  a {
    color: ${mfColors.grey};
    text-decoration: none;
  }
`;

interface IProps {
  multiline?: boolean;
}

const Terms = ({ multiline = false }: IProps): JSX.Element => {
  const { t } = useTranslation();
  return (
    <Wrapper multiline={multiline}>
      <span>{t('shared.seetree_systems_all_rights_reserved', { year: new Date().getFullYear() })}</span>
      <span className="separator">|</span>
      <span className="links">
        <span>
          <a href="/YA_17727795_6_SeeTree - Privacy Notice (Services) 01b (YA 10.01.19)cl.DOC">{t('shared.privacy')}</a>
        </span>
        <span className="separator">|</span>
        <span>
          <a href="/YA_17708193_7_SeeTree - Terms of Service (Services) 01b (YA 10.01.19)cl.DOC">{t('shared.terms_of_service')}</a>
        </span>
      </span>
    </Wrapper>
  );
};

export default Terms;
