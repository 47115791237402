import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import numberUtils from 'utils/numbers';

import { IZone } from 'models/zone';
import { IGrove } from 'models/grove';
import { ISurvey } from 'models/survey';
import { IGroveSurveyStats } from 'models/stats';
import { ICommodityCombination } from 'models/commodity';
import { EMeasurementSystem } from 'models/region';
import { AreaIcon, TreeIcon, CapacityIcon, ProductivityIcon } from 'assets/images';
import { mfColors } from 'vars';
import { TMappedStatistic } from 'models/statistic';
import { CUSTOMER_SCORES, ERROR, HEALTHY, NOT_BEARING, UNDER_PERFORMING } from 'models/scores';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons';
import Dropdown, { EToggleType, EPosition } from 'atomicComponents/Dropdown';
import { indexBy } from 'utils/helpers';
import scoreUtils from 'utils/score';

const Wrapper = styled.div`
  margin: 0 12px;
  padding: 12px 0 12px 16px;
  max-height: 280px;
  overflow: auto;
  position: relative;
`;

interface IInfoProps {
  isExpanded: boolean;
  isGrove: boolean;
  commoditiesCount: number;
}

const getMaxHeight = ({ isExpanded, isGrove, commoditiesCount }: IInfoProps) => {
  if (!isExpanded) return '67px';
  return isGrove ? `${255 + 144 * commoditiesCount}px` : '173px';
};

const Info = styled.div<IInfoProps>`
  max-height: ${getMaxHeight};
  transition: max-height 0.5s;
  overflow: hidden;
  padding-right: 16px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 12px;
  position: relative;
`;

const GrowInfoRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 36px;
  padding: 0 4px;
  border-radius: 4px;
  font-size: 12px;

  &:last-child {
    margin: 0 0 16px;
  }

  &:nth-child(odd) {
    background-color: ${mfColors.lightGreyBlue50};
  }
`;

const Label = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Icon = styled.div`
  margin: 0 8px 0 0;
  width: 26px;
  display: flex;
  justify-content: center;

  &.capacity-icon > * {
    width: 16px;
  }

  &.productivity-icon > * {
    width: 16px;
  }
`;

const CombinedIcons = styled.div`
  position: relative;
  width: 24px;
  height: 24px;

  .tree,
  .area {
    position: absolute;
    width: 16px;
    height: 16px;
  }

  .tree {
    top: 1px;
    left: 1px;
    z-index: 1;
  }

  .area {
    bottom: 1px;
    right: 1px;
    z-index: 0;
  }
`;

const Value = styled.div`
  font-family: MontserratBold;
`;

const ShowMore = styled.div`
  cursor: pointer;
  font-size: 12px;
  line-height: 15px;
  color: ${mfColors.lightBlue};
  text-align: right;
  padding-right: 16px;
`;

const InfoIcon = styled.div`
  position: absolute;
  top: 2px;
  right: -15px;
  cursor: pointer;
  color: ${mfColors.grey50};
`;

const CapacityInfoIcon = styled.div`
  position: absolute;
  top: 2px;
  right: -15px;
  cursor: pointer;
  color: ${mfColors.grey50};
`;

const ProductivityInfoIcon = styled.div`
  position: absolute;
  top: 1px;
  right: -15px;
  cursor: pointer;
  color: ${mfColors.grey50};
`;

const InfoDescription = styled.div`
  font-size: 14px;
  white-space: pre-line;
  color: ${mfColors.grey};
`;

const Arrow = styled.div`
  position: absolute;
  bottom: -24px;
  left: 12px;
  width: 0;
  height: 0;
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
  border-bottom: 12px solid transparent;
  border-top: 12px solid ${mfColors.white};
`;

const DROPDOWN_OFFSET = { x: -32, y: 16 };
const DROPDOWN_STYLE = {
  background: mfColors.white,
  boxShadow: `-5px -5px 10px ${mfColors.black40}, 5px 5px 10px ${mfColors.black40}`
};

interface ICommoditiesInfoProps {
  commodityCombinations: ICommodityCombination[];
  grove: IGrove;
}

const CommodityCombinationsInfo = ({ grove, commodityCombinations }: ICommoditiesInfoProps): JSX.Element | null => {
  const { t } = useTranslation();
  const commoditiesData = useMemo(() => {
    if (!grove.attributes.commodityCombinations?.length) return [];

    return grove.attributes.commodityCombinations
      .map((id: string) => commodityCombinations.find((combination) => combination.id === id))
      .filter((combination) => !!combination) as ICommodityCombination[];
  }, [grove.attributes.commodityCombinations, commodityCombinations]);

  if (!commoditiesData) return null;

  return (
    <>
      {commoditiesData.map((combination, index) => (
        <React.Fragment key={index}>
          <GrowInfoRow>
            <Label>{t('main.info.commodity_x', { x: index + 1 })}</Label>
            <Value>{combination.commodity}</Value>
          </GrowInfoRow>
          <GrowInfoRow>
            <Label>{t('main.info.sub_commodity_x', { x: index + 1 })}</Label>
            <Value>{combination.subCommodity}</Value>
          </GrowInfoRow>
          <GrowInfoRow>
            <Label>{t('main.info.varienty_or_scion_x', { x: index + 1 })}</Label>
            <Value>{combination.variety}</Value>
          </GrowInfoRow>
          <GrowInfoRow>
            <Label>{t('main.info.rootstock_x', { x: index + 1 })}</Label>
            <Value>{combination.rootStock}</Value>
          </GrowInfoRow>
        </React.Fragment>
      ))}
    </>
  );
};

interface IProps {
  groves: IGrove[];
  selectedSurvey: ISurvey;
  selectedGrove: IGrove | null;
  selectedZone: IZone | null;
  selectedSurveyStats: IGroveSurveyStats[];
  commodityCombinations: ICommodityCombination[];
  metricType?: EMeasurementSystem;
  statistic: TMappedStatistic | null;
}

const FarmInfo = ({
  groves,
  selectedSurvey,
  selectedGrove,
  selectedSurveyStats,
  selectedZone,
  commodityCombinations,
  metricType = EMeasurementSystem.Metric,
  statistic
}: IProps): JSX.Element => {
  const { t } = useTranslation();
  const [isExpanded, setIsExpanded] = useState(false);
  const areaStats = useMemo(() => {
    const scores = [...NOT_BEARING, ...UNDER_PERFORMING, ...HEALTHY, ...ERROR];
    let selectedAreaGroves: IGrove[] = [];

    if (selectedGrove) {
      selectedAreaGroves = [selectedGrove];
    } else if (selectedZone) {
      selectedAreaGroves = groves.filter((grove) => grove.zoneID === selectedZone.id);
    } else {
      selectedAreaGroves = groves;
    }

    if (!statistic) {
      return null;
    }

    const indexedGroves = indexBy('id', selectedAreaGroves);
    const trees = Object.keys(statistic).reduce((acc: number, groveID) => {
      if (!indexedGroves[groveID]) return acc;
      const stat = selectedSurveyStats.find((entry) => entry.groveID === groveID);
      if (!stat?.isPublished) return acc;

      const total = scores.reduce((sum: number, score: number) => {
        if (!statistic[groveID][score]) {
          return sum;
        }
        return sum + statistic[groveID][score].total;
      }, 0);

      return acc + total;
    }, 0);

    const plantedArea = numberUtils.getGrovesArea(
      selectedAreaGroves.filter((grove) => !!selectedSurveyStats.find((entry) => entry.groveID === grove.id)?.isPublished),
      metricType
    );

    return {
      trees,
      plantedArea,
      avgTrees: Math.round(trees / plantedArea)
    };
  }, [statistic, selectedSurveyStats, groves, selectedGrove, selectedZone, metricType]);

  const capacity = useMemo(() => {
    const scores = [...NOT_BEARING, ...UNDER_PERFORMING, ...HEALTHY];
    let selectedAreaGroves: IGrove[] = [];

    if (selectedGrove) {
      selectedAreaGroves = [selectedGrove];
    } else if (selectedZone) {
      selectedAreaGroves = groves.filter((grove) => grove.zoneID === selectedZone.id);
    } else {
      selectedAreaGroves = groves;
    }

    if (!statistic) {
      return null;
    }

    const indexedGroves = indexBy('id', selectedAreaGroves);
    const plantedTrees = Object.keys(statistic).reduce((acc: number, groveID) => {
      if (!indexedGroves[groveID]) return acc;
      const stat = selectedSurveyStats.find((entry) => entry.groveID === groveID);
      if (!stat?.isPublished) return acc;

      const total = scores.reduce((sum: number, score: number) => {
        if (!statistic[groveID][score]) {
          return sum;
        }
        return sum + statistic[groveID][score].total;
      }, 0);

      return acc + total;
    }, 0);

    const totalTrees = Object.keys(statistic).reduce((acc: number, groveID) => {
      if (!indexedGroves[groveID]) return acc;
      const stat = selectedSurveyStats.find((entry) => entry.groveID === groveID);
      if (!stat?.isPublished) return acc;

      const total = CUSTOMER_SCORES.reduce((sum: number, score: number) => {
        if (!statistic[groveID][score]) {
          return sum;
        }
        return sum + statistic[groveID][score].total;
      }, 0);

      return acc + total;
    }, 0);

    return (plantedTrees / totalTrees) * 100;
  }, [statistic, selectedSurveyStats, groves, selectedGrove, selectedZone]);

  const productivity = useMemo(
    () => scoreUtils.getProductivity({ statistic, selectedSurveyStats, groves, selectedGrove, selectedZone }),
    [statistic, selectedSurveyStats, groves, selectedGrove, selectedZone]
  );

  const spacing = useMemo(() => {
    if (!selectedGrove?.attributes) return null;
    if (typeof selectedGrove.attributes.spacing !== 'object') return selectedGrove.attributes.spacing;

    return metricType === EMeasurementSystem.Metric
      ? `${selectedGrove.attributes.spacing.m.row}x${selectedGrove.attributes.spacing.m.tree}`
      : `${selectedGrove.attributes.spacing.ft.row}x${selectedGrove.attributes.spacing.ft.tree}`;
  }, [selectedGrove, metricType]);

  if (!areaStats) {
    return <></>;
  }

  return (
    <Wrapper>
      <Info isGrove={!!selectedGrove} isExpanded={isExpanded} commoditiesCount={selectedGrove?.attributes?.commodityCombinations?.length || 0}>
        <Row>
          <Label>
            <Icon>
              <TreeIcon />
            </Icon>
            {t('shared.trees')}
          </Label>
          <Value>{areaStats.trees.toLocaleString()}</Value>

          <InfoIcon>
            <Dropdown
              arrow={<Arrow />}
              style={DROPDOWN_STYLE}
              toggleType={EToggleType.Mouseover}
              control={<FontAwesomeIcon icon={faInfoCircle} size="xs" />}
              offset={DROPDOWN_OFFSET}
              content={<InfoDescription>{t('main.info.total_trees_description')}</InfoDescription>}
              position={EPosition.TopRight}
            />
          </InfoIcon>
        </Row>
        <Row>
          <Label>
            <Icon>
              <AreaIcon />
            </Icon>
            {t('main.info.area')}
          </Label>
          <Value>{numberUtils.formatArea(areaStats.plantedArea, 1, metricType)}</Value>
          <InfoIcon>
            <Dropdown
              arrow={<Arrow />}
              style={DROPDOWN_STYLE}
              toggleType={EToggleType.Mouseover}
              control={<FontAwesomeIcon icon={faInfoCircle} size="xs" />}
              offset={DROPDOWN_OFFSET}
              content={<InfoDescription>{t('main.info.total_area_description')}</InfoDescription>}
              position={EPosition.TopRight}
            />
          </InfoIcon>
        </Row>
        {!selectedGrove && (
          <Row>
            <Label>
              <Icon>
                <CombinedIcons>
                  <TreeIcon className="tree" />
                  <AreaIcon className="area" />
                </CombinedIcons>
              </Icon>
              {t(metricType === EMeasurementSystem.Metric ? 'main.info.avg_trees_hectare' : 'main.info.avg_trees_acre')}
            </Label>
            <Value>{areaStats.avgTrees.toLocaleString()}</Value>
          </Row>
        )}
        <Row>
          <Label>
            <Icon className="capacity-icon">
              <CapacityIcon />
            </Icon>
            {t('main.info.capacity')}
          </Label>
          <Value>{capacity ? `${numberUtils.normalizeDecimal(capacity, 1)}%` : 'NaN'}</Value>
          <CapacityInfoIcon>
            <Dropdown
              arrow={<Arrow />}
              style={DROPDOWN_STYLE}
              toggleType={EToggleType.Mouseover}
              control={<FontAwesomeIcon icon={faInfoCircle} size="xs" />}
              offset={DROPDOWN_OFFSET}
              content={<InfoDescription>{t('main.info.capacity_description')}</InfoDescription>}
              position={EPosition.TopRight}
            />
          </CapacityInfoIcon>
        </Row>
        <Row>
          <Label>
            <Icon className="productivity-icon">
              <ProductivityIcon />
            </Icon>
            {t('main.info.productivity')}
          </Label>
          <Value>{productivity ? `${numberUtils.normalizeDecimal(productivity, 1)}%` : 'NaN'}</Value>
          <ProductivityInfoIcon>
            <Dropdown
              arrow={<Arrow />}
              style={DROPDOWN_STYLE}
              toggleType={EToggleType.Mouseover}
              control={<FontAwesomeIcon icon={faInfoCircle} size="xs" />}
              offset={DROPDOWN_OFFSET}
              content={<InfoDescription>{t('main.info.productivity_description')}</InfoDescription>}
              position={EPosition.TopRight}
            />
          </ProductivityInfoIcon>
        </Row>
        {selectedGrove && selectedGrove.attributes && (
          <>
            {selectedGrove.attributes.spacing && (
              <GrowInfoRow>
                <Label>{t('main.info.spacing')}</Label>
                <Value>{spacing}</Value>
              </GrowInfoRow>
            )}
            {selectedGrove.attributes.irrigation && (
              <GrowInfoRow>
                <Label>{t('main.info.irrigation_method')}</Label>
                <Value>{Object.keys(selectedGrove.attributes.irrigation).join(', ')}</Value>
              </GrowInfoRow>
            )}
            <GrowInfoRow>
              <Label>{t('main.info.original_plant_year')}</Label>
              <Value>{selectedGrove.attributes.originalPlantYear}</Value>
            </GrowInfoRow>
            {!selectedGrove.attributes.commodityCombinations?.length ? (
              <>
                <GrowInfoRow>
                  <Label>{t('main.info.commodity')}</Label>
                  <Value>{selectedGrove.attributes.commodity}</Value>
                </GrowInfoRow>
                {selectedGrove.attributes.subCommodity && (
                  <GrowInfoRow>
                    <Label>{t('main.info.sub_commodity')}</Label>
                    <Value>{selectedGrove.attributes.subCommodity}</Value>
                  </GrowInfoRow>
                )}
                {selectedGrove.attributes.variety && (
                  <GrowInfoRow>
                    <Label>{t('main.info.varienty_or_scion')}</Label>
                    <Value>{selectedGrove.attributes.variety}</Value>
                  </GrowInfoRow>
                )}
                {selectedGrove.attributes.rootStock && (
                  <GrowInfoRow>
                    <Label>{t('main.info.rootstock')}</Label>
                    <Value>{selectedGrove.attributes.rootStock}</Value>
                  </GrowInfoRow>
                )}
              </>
            ) : (
              <CommodityCombinationsInfo grove={selectedGrove} commodityCombinations={commodityCombinations} />
            )}
          </>
        )}
      </Info>
      <ShowMore onClick={() => setIsExpanded((prev) => !prev)}>{t(isExpanded ? 'shared.less_information' : 'shared.more_information')}</ShowMore>
    </Wrapper>
  );
};

export default FarmInfo;
