import React, { useMemo } from 'react';

import numberUtils from 'utils/numbers';
import missionUtils from 'utils/mission';

import { IGrove } from 'models/grove';
import { IMission, EMissionStatus } from 'models/mission';
import { EMeasurementSystem } from 'models/region';
import { IGroveSurveyStats } from 'models/stats';
import MissionStats from 'components/missions/MissionStats';

interface IProps {
  surveyStats: IGroveSurveyStats[];
  groves: IGrove[];
  mission?: Pick<IMission, 'type' | 'groves' | 'timestamp' | 'createdBy'>;
  metricType?: EMeasurementSystem;
  isCreated?: boolean;
  showBackground?: boolean;
}

const AbsoluteMetricMissionStats = ({ surveyStats, groves, mission, metricType = EMeasurementSystem.Metric, isCreated = true, showBackground = false }: IProps): JSX.Element => {
  const resolvedGroves = useMemo(() => {
    if (!mission?.groves) return [];
    return groves.filter((grove) => mission.groves[grove.id] === EMissionStatus.Done);
  }, [groves, mission?.groves]);
  const affectedGroves = useMemo(() => (mission?.groves ? groves.filter((grove) => mission.groves[grove.id]) : groves), [groves, mission?.groves]);

  const resolvedTreesCount = useMemo(() => missionUtils.getTreesSum(resolvedGroves, surveyStats), [resolvedGroves, surveyStats]);

  const affectedTreesCount = useMemo(() => missionUtils.getTreesSum(affectedGroves, surveyStats), [affectedGroves, surveyStats]);

  const resolvedArea = useMemo(() => numberUtils.formatArea(numberUtils.getGrovesArea(resolvedGroves, metricType), 1, metricType), [resolvedGroves, metricType]);

  const affectedArea = useMemo(() => numberUtils.formatArea(numberUtils.getGrovesArea(affectedGroves, metricType), 1, metricType), [metricType, affectedGroves]);

  return (
    <MissionStats
      groves={groves}
      mission={mission}
      isCreated={isCreated}
      showBackground={showBackground}
      resolvedArea={resolvedArea}
      affectedArea={affectedArea}
      resolvedTreesCount={resolvedTreesCount}
      affectedTreesCount={affectedTreesCount}
    />
  );
};

export default AbsoluteMetricMissionStats;
