import { IThresholdOptions, IThresholdRanges } from 'models/thresholds';
import { useEffect, useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setThresholdOptionsConfigs, setThresholdRangesConfigs } from 'redux/bulletin/bulletinSlice';

import { useFetchLayers, useFetchWhatsNewTimestamp, useFetchRichItemMeasurementRanges, IRichItemMeasurementRanges, useFetchThresholdConfigs } from 'services/data/appConfig';

interface IGetLayersResult {
  layers: string[];
}

const useGetLayers = (): IGetLayersResult => {
  const [layers, setLayers] = useState<string[]>([]);
  const fetchLayers = useFetchLayers();

  const getLayers = useCallback(async () => {
    setLayers(await fetchLayers());
  }, [fetchLayers]);

  useEffect(() => {
    getLayers();
  }, [getLayers]);

  return {
    layers
  };
};

const useGetWhatsNewTimestamp = (): { timestamp: number } => {
  const [timestamp, setTimestamp] = useState<number>(0);
  const fetchWhatsNew = useFetchWhatsNewTimestamp();

  useEffect(() => {
    fetchWhatsNew().then(setTimestamp);
  }, [fetchWhatsNew]);

  return {
    timestamp
  };
};

const useGetRichItemRanges = (): IRichItemMeasurementRanges | undefined => {
  const [richItemRanges, setRichItemRanges] = useState<IRichItemMeasurementRanges>();
  const fetchRanges = useFetchRichItemMeasurementRanges();

  const getRichItemMetrics = useCallback(async () => {
    setRichItemRanges(await fetchRanges());
  }, [fetchRanges]);

  useEffect(() => {
    getRichItemMetrics();
  }, [getRichItemMetrics]);

  return richItemRanges;
};

const useGetBulletinConfigs = (): { thresholdRanges: IThresholdRanges | null; thresholdOptions: IThresholdOptions | null } => {
  const [thresholdRanges, setThresholdRanges] = useState<IThresholdRanges | null>(null);
  const [thresholdOptions, setThresholdOptions] = useState<IThresholdOptions | null>(null);
  const fetchConfigs = useFetchThresholdConfigs();
  const dispatch = useDispatch();

  const getRichItemMetrics = useCallback(async () => {
    const configs = await fetchConfigs();
    setThresholdRanges(configs ? (configs.ranges as IThresholdRanges) : null);
    setThresholdOptions(configs ? (configs.options as IThresholdOptions) : null);
  }, [fetchConfigs]);

  useEffect(() => {
    getRichItemMetrics();
  }, [getRichItemMetrics]);

  useEffect(() => {
    if (thresholdRanges) {
      dispatch(setThresholdRangesConfigs(thresholdRanges));
    }
    if (thresholdOptions) {
      dispatch(setThresholdOptionsConfigs(thresholdOptions));
    }
  }, [dispatch, thresholdRanges, thresholdOptions]);

  return {
    thresholdRanges,
    thresholdOptions
  };
};

const appConfigHooks = {
  useGetLayers,
  useGetRichItemRanges,
  useGetBulletinConfigs,
  useGetWhatsNewTimestamp
};

export default appConfigHooks;
