import React from 'react';
import { renderToString } from 'react-dom/server';
import styled from 'styled-components';

const MarkerLabel = styled.div`
  font-size: 25px;
  line-height: 25px;
  font-weight: bold;
  padding: 0px 4px;
  border-radius: 4px;
  color: #fff;
  transition: color 0.2s ease, background-color 0.2s ease;
  cursor: pointer;
  &:hover {
    color: #6c93ff;
    background-color: #fff;
  }
`;

interface IProps {
  title: string;
  className: string;
}

export const ZoneMarker = ({ title, className }: IProps): JSX.Element => <MarkerLabel className={className}>{title}</MarkerLabel>;
export const ZoneHTMLMarker = ({ title, className }: IProps) => renderToString(<ZoneMarker title={title} className={className} />);

export default ZoneHTMLMarker;
