import React, { Dispatch, SetStateAction, useCallback } from 'react';
import styled from 'styled-components';
import { EReportType } from 'models/report';
import { useSelector } from 'react-redux';
import { getSelectedFarm } from 'redux/farm/farmSlice';
import { mfColors } from 'vars';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faSeedling } from '@fortawesome/pro-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import { BindweedsTreatmentIcon, DedicatedVisitIcon, TreeHedgingIcon, TreeToppingIcon, WeedIcon, TargetedScoutingIcon } from 'assets/images';

export const THRESHOLD_TYPES = [
  EReportType.DedicatedVisit,
  EReportType.TargetedScouting,
  EReportType.Cipo,
  EReportType.FreeArea,
  EReportType.Height,
  EReportType.Weeds,
  EReportType.Replant
];
export const TYPE_ICON = new Map<EReportType, JSX.Element>()
  .set(EReportType.DedicatedVisit, <DedicatedVisitIcon />)
  .set(EReportType.TargetedScouting, <TargetedScoutingIcon />)
  .set(EReportType.Height, <TreeToppingIcon />)
  .set(EReportType.FreeArea, <TreeHedgingIcon />)
  .set(EReportType.Weeds, <WeedIcon style={{ marginTop: '-6px' }} />)
  .set(EReportType.Cipo, <BindweedsTreatmentIcon />)
  .set(EReportType.Replant, <FontAwesomeIcon icon={faSeedling} />);
export const TYPE_TITLE = new Map<EReportType, string>()
  .set(EReportType.DedicatedVisit, 'missions.grove_scouting')
  .set(EReportType.TargetedScouting, 'missions.targeted_scouting')
  .set(EReportType.Height, 'missions.tree_topping')
  .set(EReportType.FreeArea, 'missions.tree_hedging')
  .set(EReportType.Weeds, 'missions.weed_mowing')
  .set(EReportType.Cipo, 'missions.cipo_treatment')
  .set(EReportType.Replant, 'main.bulletin.tree_planting');

const Header = styled.div`
  display: flex;
  margin-bottom: 54px;
`;

const CloseButton = styled(FontAwesomeIcon)`
  cursor: pointer;
`;

const HeaderText = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  font-family: MontserratBold;
  align-self: center;
  font-size: 20px;
`;

const Body = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 54px;
`;

const BodyText = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 32px;
  font-family: Montserrat;
  font-size: 20px;
  border-bottom: 1px solid ${mfColors.black40};
  padding-bottom: 8px;
`;

const TypesList = styled.div``;

export const TypeItem = styled.div`
  display: flex;
  flex: row;
  margin-bottom: 18px;
  border-radius: 22px;

  &:hover {
    background: #f5f5f5;
  }
`;

export const TypeIcon = styled.div`
  display: flex;
  width: 44px;
  height: 44px;
  justify-content: center;
  align-items: center;
  background: ${mfColors.missionFillColor};
  border: 1px solid ${mfColors.missionBorderColor};
  color: ${mfColors.missionTextColor};
  border-radius: 50%;
  padding: 0 8px;
`;

export const TypeTitle = styled.div`
  font-family: MontserratBold;
  align-self: center;
  font-size: 20px;
  margin-left: 12px;
  text-transform: capitalize;
`;

interface IBulletinModalProps {
  onTypeChange: Dispatch<SetStateAction<EReportType>>;
  onTypeSelectorOpenChange: (isOpen: boolean) => void;
  onBack?: () => void;
}

const Wrapper = styled.div``;

const BulletinModalTypeSelector = ({ onTypeSelectorOpenChange, onTypeChange, onBack }: IBulletinModalProps): JSX.Element => {
  const { t } = useTranslation();
  const farm = useSelector(getSelectedFarm);
  const onTypeClick = useCallback(
    (type) => {
      onTypeChange(type);
      onTypeSelectorOpenChange(false);
    },
    [onTypeChange, onTypeSelectorOpenChange]
  );
  const onBackClick = useCallback(() => {
    if (onBack) {
      onBack();
    } else {
      onTypeSelectorOpenChange(false);
    }
  }, [onBack, onTypeSelectorOpenChange]);

  return (
    <Wrapper>
      <Header>
        <CloseButton icon={faChevronLeft} size="lg" onClick={onBackClick} />
        <HeaderText>{t('main.bulletin.modal.settings_for_farm', { name: farm?.name })}</HeaderText>
      </Header>
      <Body>
        <BodyText>{t('main.bulletin.modal.setup_the_threshold_for')}</BodyText>
        <TypesList>
          {THRESHOLD_TYPES.map((type, index) => (
            <TypeItem key={index} onClick={() => onTypeClick(type)}>
              <TypeIcon>{TYPE_ICON.get(type)}</TypeIcon>
              <TypeTitle>{t(`${TYPE_TITLE.get(type)}`)}</TypeTitle>
            </TypeItem>
          ))}
        </TypesList>
      </Body>
    </Wrapper>
  );
};

export default BulletinModalTypeSelector;
