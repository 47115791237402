import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { EReportType, TAnyReport, IHeightReport, IFreeAreaReport, ICipoReport, IWeedsReport, IScoreDifferenceReport, IReplantReport } from 'models/report';
import { EMeasurementSystem } from 'models/region';

import numberUtils from 'utils/numbers';
import replantUtils from 'utils/replant';
import { getFarmBulletinThresholds } from 'redux/bulletin/bulletinSlice';
import { IUnifiedHotspot } from 'models/hotspot';

interface IReportTitleProps {
  report: TAnyReport | IUnifiedHotspot;
  reportType: EReportType;
  metricType?: EMeasurementSystem;
}

const BulletinInfo = ({ reportType, report, metricType }: IReportTitleProps): JSX.Element | null => {
  const { t } = useTranslation();
  const farmBulletinThresholds = useSelector(getFarmBulletinThresholds);

  const infoTitle = useMemo((): string => {
    let title = '';
    switch (reportType) {
      case EReportType.DedicatedVisit:
        title = t('main.bulletin.increase_in_weak_trees');
        break;
      case EReportType.Height:
        title = t('main.bulletin.height');
        break;
      case EReportType.Cipo:
        title = t('main.bulletin.CIPO_rate');
        break;
      case EReportType.FreeArea:
        title = t('main.bulletin.free_area');
        break;
      case EReportType.Weeds:
        title = t('main.bulletin.weeds');
        break;
      case EReportType.Replant:
        title = t('main.bulletin.number_of_replants');
        break;
      default:
        break;
    }

    return title;
  }, [reportType, t]);

  const infoValue = useMemo((): string => {
    let value = '';

    switch (reportType) {
      case EReportType.DedicatedVisit:
        value = `${numberUtils.normalizeDecimal((report as IScoreDifferenceReport).underperforming_difference, 2)} %`;
        break;
      case EReportType.Height:
      case EReportType.FreeArea:
        value = numberUtils.formatHeight(
          numberUtils.convertMeterToFeet(
            numberUtils.convertReportValue(
              reportType === EReportType.Height ? +(report as IHeightReport).Height : +(report as IFreeAreaReport).freeArea_avg,
              reportType,
              (farmBulletinThresholds[reportType]?.value as number) || null,
              1
            ),
            metricType
          ),
          1,
          metricType
        );
        break;
      case EReportType.Cipo:
        value = `${numberUtils.normalizeDecimal((report as ICipoReport).cipo_rate * 100, 2)} %`;
        break;
      case EReportType.Weeds:
        value = `${numberUtils.normalizeDecimal(
          (+(report as IWeedsReport)._30_60cm_percentage + +(report as IWeedsReport)._60_90cm_percentage + +(report as IWeedsReport)._90cm_above_percentage) * 100
        )} %`;
        break;
      case EReportType.Replant:
        value = replantUtils.formatReplantNumber((report as IReplantReport).replants);
        break;
      default:
        break;
    }

    return value;
  }, [reportType, report, metricType, farmBulletinThresholds]);

  return infoTitle ? (
    <>
      <span>{infoTitle}</span>
      <b>{infoValue}</b>
    </>
  ) : null;
};

export default BulletinInfo;
